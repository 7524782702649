import { postEntityNew } from '../../../../../../services/axiosFiles/genericCrud';
import pricehubbleGenericParser from '../../../../../../shared/parsers/pricehubbleGenericParser';
import { socioEconomicParser } from '../../../../landPDF/orpiLandDataParser';

interface IProps extends IPHTokenAndPHId {
  index: number;
  insee: string;
  dataType: string;
  callback: (value: string) => void;
}

async function fetchSocioEconomicDatas({
  index,
  entityId,
  insee,
  dataType,
  phAccessToken,
  callback,
}: IProps) {
  try {
    if (index && entityId && insee && dataType) {
      const body = {
        dossierId: entityId,
        insee: insee,
        data_type: dataType,
        ph_access_token: phAccessToken,
      };

      const result: any = await postEntityNew({
        endpoint: '/pdf_urbanease/pricehubble_get_socio_economics_datas',
        body,
      });

      if (result) {
        const phPreParser = pricehubbleGenericParser(result);
        const parsed = socioEconomicParser(phPreParser, dataType);
        callback(`Les données de socio-économiques ${index} sont chargées`);

        return parsed;
      } else {
        throw new Error('no results');
      }
    } else {
      throw new Error('missing fetchDossierDatas params');
    }
  } catch (error) {
    return { items: [], subcategory: dataType, year: 0 };
  }
}

export default fetchSocioEconomicDatas;
