import styles from './builtCaracteristics.module.scss';

interface ISectionTitleProps {
  title: string;
  className?: string;
}

function SectionTitle({ className, title }: ISectionTitleProps) {
  return <h4 className={styles.sectionTitle}>{title}</h4>;
}

export default SectionTitle;
