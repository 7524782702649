import { useFormContext } from 'react-hook-form';
import styles from '../../styles/rightPanelModalExternalPdfErrial.module.scss';
import TextInputFormPdfErrialCustom from './TextInputFormPdfErrialCustom';

function ProInfosSection() {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <h3>Informations sur le professionnel</h3>
      <div className={styles.formSection}>
        <div className={styles.rowInput}>
          <p>Nom</p>
          <TextInputFormPdfErrialCustom
            name="lastName"
            error={Boolean(errors.lastName)}
            errorMsg={errors.lastName?.message as string}
          />
        </div>
        <div className={styles.rowInput}>
          <p>Prénom</p>
          <TextInputFormPdfErrialCustom
            name="firstName"
            error={Boolean(errors.firstName)}
            errorMsg={errors.firstName?.message as string}
          />
        </div>
        <div className={styles.rowInput}>
          <p>Adresse mail</p>
          <TextInputFormPdfErrialCustom
            name="email"
            error={Boolean(errors.email)}
            errorMsg={errors.email?.message as string}
          />
        </div>
        <div className={styles.rowInput}>
          <p>Téléphone</p>
          <TextInputFormPdfErrialCustom
            name="phone"
            error={Boolean(errors.phone)}
            errorMsg={errors.phone?.message as string}
          />
        </div>
        <div className={styles.rowInput}>
          <p>Nom de la structure</p>
          <TextInputFormPdfErrialCustom
            name="companyName"
            error={Boolean(errors.companyName)}
            errorMsg={errors.companyName?.message as string}
          />
        </div>
      </div>
    </div>
  );
}

export default ProInfosSection;
