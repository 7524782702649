import { nanoid } from '@reduxjs/toolkit';
import styles from './legend.module.scss';
import LegendRow from './LegendRow';

interface ILegendContainerProps {
  legends: Legends;
}
function LegendContainer({ legends }: ILegendContainerProps) {
  return (
    <div className={styles.legendContainer}>
      {legends.map((l) => (
        <LegendRow key={nanoid()} legend={l} />
      ))}
    </div>
  );
}

export default LegendContainer;
