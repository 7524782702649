import useCityDatas from '../../../../../../../features/plot/activePlotFeature/hooks/useCityDatas';
import styles from './fiscalZone.module.scss';

interface IFiscalZoneProps {
  zone: nullable<IFiscalLaw>;
}
export default function FiscalZone({ zone }: IFiscalZoneProps) {
  const { socialCriteria } = useCityDatas();
  return (
    <div className={styles.fiscalZone}>
      <p>
        Zone Pinel : <span>{zone ? zone.zone : 'indéfinie'}</span>
      </p>
      <p>
        Zone logement social{' '}
        <span className={styles.zoneInfo}>(définie par arrêté du 17/03/1978) </span>:{' '}
        <span>{socialCriteria.result ?? 'Indéfinie'}</span>
      </p>
    </div>
  );
}
