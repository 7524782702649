export function sortableEntitiesParser<T>(
  entities: T[],
  sortValues: SortValueType
): ISort<T>[] {
  return entities.map((e) => sortableEntityParser<T>(e, sortValues));
}

export function sortableEntityParser<T>(
  entity: T,
  sortValues: SortValueType
): ISort<T> {
  return { sortValues, entity };
}
