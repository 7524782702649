import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
} from '../../../../services/common/parserCommon';

const matrixMapping = {
  '@id': 'idIri',
  areaField: 'areaField',
  builtFootprint: 'builtFootprint',
  builtFreeOnGround: 'builtFreeOnGround',
  floors: 'floors',
  avgAreaHousing: 'avgAreaHousing',
  socialHousing: 'socialHousing',
  spShab: 'spShab',
  buildingFootprint: 'buildingFootprint',
  freeBuiltAreaMin: 'freeBuiltAreaMin',
  floorAreaMax: 'floorAreaMax',
  housingAreaMax: 'housingAreaMax',
  socialHousingAreaMax: 'socialHousingAreaMax',
  housingQty: 'housingQty',
  socialHousingQty: 'socialHousingQty',
  parkingQty: 'parkingQty',
  estimatedParkingArea: 'estimatedParkingArea',
  equivalentUnderGroundArea: 'equivalentUnderGroundArea',
  plotStudy: 'plotStudy',
  folder: 'folder',
};

export const studyMatrixFormToApiParser = (
  data: Omit<IFeasibilityMatrix, 'idIri'>
) => {
  const parse = changeStoreKeyForApiKey<any>(data, matrixMapping);

  delete parse['@id'];

  return parse;
};

export const studyMatrixApiToStoreParser = (data: any) => {
  const fd = { ...data };
  const keys = Object.keys(data);

  keys.forEach((k) => {
    fd[k] = fd[k] === null ? 0 : fd[k];
  });
  const parsedData = changeApiKeyForStoreKey<IFeasibilityMatrix>(fd, matrixMapping);

  return parsedData;
};
