import { delay } from 'lodash';
import { memo, useEffect } from 'react';
import useQuery from '../../../App/hooks/useQuery';
import { useAppDispatch, useAppSelector } from '../../../App/store';

import IndividualCircularLoader from '../../loaders/individualCircularLoader';

import LocalStorageService from '../../../services/LocalStorageService';
import { externalPdfErrialActions } from '../../externalPdf/externalPdfSlice';
import { modalsActions } from '../../modals/modalsSlice';
import useUrbaGpt from '../../urbaGpt/useUrbaGpt';
import { authActions, getAuthState } from '../authSlice';
import { logoutThunk } from '../authThunks';
import { decodeToken } from '../utils';
import AnnouncementProcess from './AnnouncementProcess';
import AuthError from './AuthError';
import CompanyProcess from './CompanyProcess';
import { useConnectionProcessContext } from './ConnextionProcessContext';
import FetchPdfDataProcess from './FetchPdfDataProcess';
import FetchPlotProcess from './FetchPlotProcess';
import UserProcess from './UserProcess';
import styles from './connectionProcess.module.scss';
import { ConnectionWorkFlowEnum } from './utils/connexionProcessEnums';
import queriesParser from './utils/queriesParser';

export const CONNEXION_PROCESS_DELAY = 300;

const processVisible = (
  step: ConnectionWorkFlowEnum,
  workflowState: ConnectionWorkFlowEnum
) => {
  const s = Object.values(ConnectionWorkFlowEnum).indexOf(step);
  const w = Object.values(ConnectionWorkFlowEnum).indexOf(workflowState);

  return s <= w;
};

function ConnectionProcessContent() {
  const {
    navigate,
    workflowState,
    setWorkflowState,
    processDatas,
    setProcessDatas,
    setDecodedToken,
    handleErrorClick,
  } = useConnectionProcessContext();

  const { queries, location } = useQuery();
  const { token, refreshTokenFail } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();
  const isError = workflowState === ConnectionWorkFlowEnum.ERROR_REFRESH_TOKEN_FAIL;

  useUrbaGpt();

  useEffect(() => {
    setProcessDatas(queriesParser(queries));
  }, []);

  useEffect(() => {
    if (refreshTokenFail) {
      setWorkflowState(ConnectionWorkFlowEnum.ERROR_REFRESH_TOKEN_FAIL);
    }
  }, [refreshTokenFail]);

  // set tokens in store if no exists
  useEffect(() => {
    try {
      switch (workflowState) {
        // if token into queries (external connexion)
        case ConnectionWorkFlowEnum.IDLE: {
          if (queries.token && queries.refresh_token) {
            dispatch(logoutThunk());
          } else if (location.pathname === '/external_connection') {
            // if external connection but no token redirect to home
            navigate('/');
          }
          setWorkflowState(ConnectionWorkFlowEnum.INIT_CONNECTION);
          break;
        }
        case ConnectionWorkFlowEnum.INIT_CONNECTION: {
          // if external connection
          if (queries.token && queries.refresh_token) {
            dispatch(
              externalPdfErrialActions.setConnectionId({
                phId: processDatas?.phId ?? null,
                sbId: processDatas?.sbId ?? null,
                token: processDatas.phAccessToken,
              })
            );
            delay(() => {
              LocalStorageService.setToken(queries.token);
              LocalStorageService.setRefreshToken(queries.refresh_token);
            }, 100);
            setWorkflowState(ConnectionWorkFlowEnum.SET_TOKEN_IN_STORE);
          } else {
            // go to classic connexion
            if (token) {
              setDecodedToken(decodeToken(token));
              setWorkflowState(ConnectionWorkFlowEnum.START_COMPANY_PROCESS);
            } else {
              setWorkflowState(ConnectionWorkFlowEnum.SET_TOKEN_IN_STORE);
            }
          }

          break;
        }
        case ConnectionWorkFlowEnum.SET_TOKEN_IN_STORE: {
          delay(() => {
            const lsToken = LocalStorageService.getToken();
            const lsRefreshToken = LocalStorageService.getRefreshToken();

            if (lsToken && lsRefreshToken) {
              setDecodedToken(decodeToken(lsToken));
              delay(
                () => {
                  dispatch(
                    authActions.setTokens({
                      token: lsToken,
                      refreshToken: lsRefreshToken,
                    })
                  );
                  setWorkflowState(ConnectionWorkFlowEnum.START_COMPANY_PROCESS);
                },
                CONNEXION_PROCESS_DELAY,
                [lsToken, lsRefreshToken]
              );
            }
          }, 100);
          break;
        }
        case ConnectionWorkFlowEnum.START_FETCH_PLOT_PROCESS: {
          break;
        }
        case ConnectionWorkFlowEnum.CLOSE_CONNECTION_MODAL: {
          dispatch(modalsActions.connectionProcess(false));
          break;
        }
        case ConnectionWorkFlowEnum.REDIRECT_TO_HOME: {
          if (processDatas?.sbId || processDatas?.phId) {
            navigate(
              processDatas.urlType === 'orpi-land'
                ? '/pdf-orpi-terrain'
                : '/pdf-orpi'
            );
          } else {
            navigate('/');
          }
          break;
        }
        default:
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }, [workflowState]);

  return (
    <div className={styles.connectionProcess}>
      {workflowState === ConnectionWorkFlowEnum.SET_TOKEN_IN_STORE && (
        <>
          <h3>Initialisation de la connexion</h3>
          <IndividualCircularLoader size={100} />
        </>
      )}
      {isError && (
        <AuthError
          title="Erreur de reconnexion automatique"
          lines={[
            'La reconnection automatique a échoué',
            'Vous devez vous reconnecter manuellement',
            "l'application va etre réinitialisée",
          ]}
          buttonContent="OK"
          onClick={handleErrorClick}
        />
      )}
      {/* company process */}
      {processVisible(ConnectionWorkFlowEnum.START_COMPANY_PROCESS, workflowState) &&
        !isError && <CompanyProcess />}
      {/* user process */}
      {processVisible(ConnectionWorkFlowEnum.START_USER_PROCESS, workflowState) &&
        !isError && <UserProcess />}
      {/* announcement process */}
      {processVisible(
        ConnectionWorkFlowEnum.START_FETCH_ANNOUNCEMENT_PROCESS,
        workflowState
      ) &&
        !isError && <AnnouncementProcess />}
      {/* fetch plot process */}
      {processVisible(
        ConnectionWorkFlowEnum.START_FETCH_PLOT_PROCESS,
        workflowState
      ) &&
        !isError && <FetchPlotProcess />}
      {/* pdf orpi and orpiLand process */}
      {processVisible(
        ConnectionWorkFlowEnum.START_FETCH_PDF_DATA_PROCESS,
        workflowState
      ) &&
        !isError && <FetchPdfDataProcess />}
    </div>
  );
}

export default memo(ConnectionProcessContent);
