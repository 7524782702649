import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Dialog, DialogContent } from '@mui/material';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import Xion from '../../../assets/images/xicon-grey.png';
import { getCompanyState } from '../../../features/company/companySlice';
import { modalsActions } from '../../../features/modals/modalsSlice';
import { getUsersState, userActions } from '../../../features/users/usersSlice';
import {
  userCreateThunk,
  userUpdateThunk,
} from '../../../features/users/usersThunks';
import FieldInputPwdFormCustom from '../../Common/FormComponents/FieldInputPwdFormCustom';
import TextFieldFormCustom from '../../Common/FormComponents/TextFieldFormCustom';
import './AddUserModal.scss';
import {
  IAddUserForm,
  initialForm,
  validationAddUserSchema,
  validationEditUserSchema,
} from './addUserModalValidation';

export default function AddUserModal({ open }: { open: boolean }): JSX.Element {
  const dispatch = useAppDispatch();
  const { selectedUser, isEditingUser } = useAppSelector(getUsersState);
  const { companyIdIri } = useAppSelector(getCompanyState);

  const formOptions = useForm<IAddUserForm>({
    defaultValues:
      isEditingUser && selectedUser
        ? {
            firstName: selectedUser.firstName,
            lastName: selectedUser.lastName,
            email: selectedUser.email,
          }
        : initialForm,
    resolver: yupResolver(
      selectedUser ? validationEditUserSchema : validationAddUserSchema
    ),
  });

  const {
    getValues,
    setValue,
    handleSubmit,

    reset,
    formState: { errors },
  } = formOptions;

  useEffect(() => {
    if (selectedUser) {
      setValue('firstName', selectedUser.firstName);
      setValue('lastName', selectedUser.lastName);
      setValue('email', selectedUser.email);
    }
  }, [selectedUser]);

  const onCloseModal = () => {
    reset();
    dispatch(modalsActions.addUser(false));
    dispatch(userActions.resetSelectedUser());
    dispatch(userActions.setEditingUser(false));
  };

  const handleFormSubmit = () => {
    if (isEmpty(errors)) {
      if (selectedUser) {
        dispatch(
          userUpdateThunk({
            userIdIri: selectedUser.idIri,
            user: {
              firstName: getValues('firstName'),
              lastName: getValues('lastName'),
              email: getValues('email'),
            },
          })
        );
      } else {
        dispatch(
          userCreateThunk({
            user: {
              firstName: getValues('firstName'),
              lastName: getValues('lastName'),
              email: getValues('email'),
              plainPassword: getValues('password'),
              roles: ['ROLE_USER'],
            },
            companyIdIri,
          })
        );
      }
      onCloseModal();
    }
  };

  return (
    <div>
      <Dialog
        className="add-user-modal"
        open={open}
        onClose={onCloseModal}
        fullScreen={false}
      >
        <DialogContent className="add-user-modal-container">
          <div className="xicon-wrapper" onClick={onCloseModal}>
            <img src={Xion} alt="xion" />
          </div>
          <div className="title">
            {isEditingUser ? "Modifier l'utilisateur" : 'Ajouter un utilisateur'}
          </div>

          <FormProvider {...formOptions}>
            <form autoComplete="off" onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="text-field">
                <TextFieldFormCustom name="lastName" label="Nom" />
              </div>
              <div className="text-field">
                <TextFieldFormCustom name="firstName" label="Prénom" />
              </div>
              <div className="text-field">
                <TextFieldFormCustom type="email" name="email" label="Email" />
              </div>
              {!isEditingUser && (
                <>
                  <div className="text-field">
                    <FieldInputPwdFormCustom name="password" label="Mot de passe" />
                  </div>
                  <div className="text-field">
                    <FieldInputPwdFormCustom
                      name="verifyPassword"
                      label="Confirmation du mot de passe"
                    />
                  </div>
                </>
              )}
              <Button type="submit" className="btn">
                {selectedUser ? "Modifier l'utilisateur" : 'Créer l’utilisateur'}
              </Button>
            </form>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
}
