import L from 'leaflet';
import calc from './calc';
import { popupContentParser } from './popupContentParser';
import Symbology from './symbology';
import units from './units';

L.Control.Measure = L.Control.extend({
  options: {
    units: {},
    position: 'topright',
    primaryLengthUnit: 'feet',
    secondaryLengthUnit: 'miles',
    primaryAreaUnit: 'acres',
    activeColor: '#ABE67E', // base color for map features while actively measuring
    completedColor: '#C8F2BE', // base color for permenant features generated from completed measure
    captureZIndex: 10000, // z-index of the marker used to capture measure events
    popupOptions: {
      // standard leaflet popup options http://leafletjs.com/reference-1.3.0.html#popup-option
      className: 'leaflet-measure-resultpopup',
      autoPanPadding: [10, 10],
    },
  },
  initialize: function (options) {
    L.setOptions(this, options);
    const { activeColor, completedColor } = this.options;

    this._symbols = new Symbology({ activeColor, completedColor });
    this.options.units = L.extend({}, units, this.options.units);
  },
  onAdd: function (map) {
    this._map = map;
    this._latlngs = [];
    this._calced = null;
    this._container = L.DomUtil.create('div', `leaflet-measure`);
    this._layer = L.layerGroup().addTo(map);

    return this._container;
  },
  onRemove: function (map) {
    map.removeLayer(this._layer);
  },

  getCalced: function () {
    return this._calced;
  },
  _centerCaptureMarker: function () {
    this._captureMarker.setLatLng(this._map.getCenter());
  },
  // set icon on the capture marker
  _setCaptureMarkerIcon: function () {
    this._captureMarker.setIcon(
      L.divIcon({
        iconSize: this._map.getSize().multiplyBy(2),
      })
    );
  },
  // handle map mouse out during ongoing measure
  // remove floating cursor vertex from map
  _handleMapMouseOut: function () {
    if (this._measureDrag) {
      this._layer.removeLayer(this._measureDrag);
      this._measureDrag = null;
    }
  },
  // add various measure graphics to map - vertex, area, boundary
  _addNewVertex: function (latlng) {
    L.circleMarker(latlng, this._symbols.getSymbol('measureVertexActive')).addTo(
      this._measureVertexes
    );
  }, // update results area of dom with calced measure from `this._latlngs`
  _updateResults: function () {
    this._calced = calc(this._latlngs);
  },

  _addMeasureArea: function (latlngs) {
    if (latlngs.length < 3) {
      if (this._measureArea) {
        this._layer.removeLayer(this._measureArea);
        this._measureArea = null;
      }
      return;
    }
    if (!this._measureArea) {
      this._measureArea = L.polygon(
        latlngs,
        this._symbols.getSymbol('measureArea')
      ).addTo(this._layer);
    } else {
      this._measureArea.setLatLngs(latlngs);
    }
  },
  _addMeasureBoundary: function (latlngs) {
    if (latlngs.length < 2) {
      if (this._measureBoundary) {
        this._layer.removeLayer(this._measureBoundary);
        this._measureBoundary = null;
      }
      return;
    }
    if (!this._measureBoundary) {
      this._measureBoundary = L.polyline(
        latlngs,
        this._symbols.getSymbol('measureBoundary')
      ).addTo(this._layer);
    } else {
      this._measureBoundary.setLatLngs(latlngs);
    }
  },
  // mouse move handler while measure in progress
  // adds floating measure marker under cursor
  _handleMeasureMove: function (evt) {
    if (!this._measureDrag) {
      this._measureDrag = L.circleMarker(
        evt.latlng,
        this._symbols.getSymbol('measureDrag')
      ).addTo(this._layer);
    } else {
      this._measureDrag.setLatLng(evt.latlng);
    }
    this._measureDrag.bringToFront();
  },
  // handle map click during ongoing measurement
  // add new clicked point, update measure layers and results ui
  _handleMeasureClick: function (evt) {
    const latlng = this._map.mouseEventToLatLng(evt.originalEvent), // get actual latlng instead of the marker's latlng from originalEvent
      lastClick = this._latlngs[this._latlngs.length - 1],
      vertexSymbol = this._symbols.getSymbol('measureVertex');

    if (!lastClick || !latlng.equals(lastClick)) {
      // skip if same point as last click, happens on `dblclick`
      this._latlngs.push(latlng);
      this._addMeasureArea(this._latlngs);
      this._addMeasureBoundary(this._latlngs);

      this._measureVertexes.eachLayer(function (layer) {
        layer.setStyle(vertexSymbol);
        // reset all vertexes to non-active class - only last vertex is active
        // `layer.setStyle({ className: 'layer-measurevertex'})` doesn't work. https://github.com/leaflet/leaflet/issues/2662
        // set attribute on path directly
        if (layer._path) {
          layer._path.setAttribute('class', vertexSymbol.className);
        }
      });

      this._addNewVertex(latlng);

      if (this._measureBoundary) {
        this._measureBoundary.bringToFront();
      }
      this._measureVertexes.bringToFront();
    }

    this._updateResults();
    this._map.fire('click', this.getCalced(), true);
  },
  _singleFitbound: function (resultFeature) {
    if (resultFeature.getBounds) {
      this._map.fitBounds(resultFeature.getBounds(), {
        padding: [20, 20],
        maxZoom: 17,
      });
    } else if (resultFeature.getLatLng) {
      this._map.panTo(resultFeature.getLatLng());
    }
  },
  // handler for both double click and clicking finish button
  // do final calc and finish out current measure, clear dom and internal state, add permanent map features
  _handleMeasureDoubleClick: function () {
    const latlngs = this._latlngs;
    let resultFeature;

    this._finishMeasure();

    if (!latlngs.length) {
      return;
    }

    if (latlngs.length > 2) {
      latlngs.push(latlngs[0]); // close path to get full perimeter measurement for areas
    }

    const calced = calc(latlngs);

    this._calced = calced;

    if (latlngs.length === 1) {
      resultFeature = L.circleMarker(
        latlngs[0],
        this._symbols.getSymbol('resultPoint')
      );
    } else if (latlngs.length === 2) {
      resultFeature = L.polyline(latlngs, this._symbols.getSymbol('resultLine'));
    } else {
      resultFeature = L.polygon(latlngs, this._symbols.getSymbol('resultArea'));
    }

    const popupContainer = L.DomUtil.create('div', 'area-measure-popup-container');
    const popupContent = popupContentParser(
      this._calced,
      latlngs.length,
      () => this._singleFitbound(resultFeature),
      () => {
        this._layer.removeLayer(resultFeature);
      }
    );
    popupContainer.appendChild(popupContent);

    resultFeature.addTo(this._layer);
    resultFeature.bindPopup(popupContainer, this.options.popupOptions);
    if (resultFeature.getBounds) {
      resultFeature.openPopup(resultFeature.getBounds().getCenter());
    } else if (resultFeature.getLatLng) {
      resultFeature.openPopup(resultFeature.getLatLng());
    }
  },
  // get state vars and interface ready for measure
  _startMeasure: function () {
    this._locked = true;
    this._measureVertexes = L.featureGroup().addTo(this._layer);
    this._captureMarker = L.marker(this._map.getCenter(), {
      clickable: true,
      zIndexOffset: this.options.captureZIndex,
      opacity: 0,
      autoPanOnFocus: false,
      icon: L.divIcon({
        iconSize: this._map.getSize().multiplyBy(2),
      }),
    }).addTo(this._layer);

    this._captureMarker
      .on('mouseout', this._handleMapMouseOut, this)
      .on('click', this._handleMeasureClick, this);

    this._map
      .on('click', () => this._calced)
      .on('mousemove', this._handleMeasureMove, this)
      .on('mouseout', this._handleMapMouseOut, this)
      .on('move', this._centerCaptureMarker, this)
      .on('resize', this._setCaptureMarkerIcon, this);

    L.DomEvent.on(this._container, 'mouseenter', this._handleMapMouseOut, this);

    this._map.fire('measurestart', null, false);
  },
  _finishMeasure: function () {
    const model = L.extend({}, this._resultsModel, { points: this._latlngs });

    this._locked = false;

    L.DomEvent.off(this._container, 'mouseover', this._handleMapMouseOut, this);

    this._clearMeasure();

    this._captureMarker
      .off('mouseout', this._handleMapMouseOut, this)
      .off('click', this._handleMeasureClick, this);

    this._map
      .off('click', () => this._calced)
      .off('mousemove', this._handleMeasureMove, this)
      .off('mouseout', this._handleMapMouseOut, this)
      .off('move', this._centerCaptureMarker, this)
      .off('resize', this._setCaptureMarkerIcon, this);

    this._layer.removeLayer(this._measureVertexes).removeLayer(this._captureMarker);
    this._measureVertexes = null;

    this._map.fire('measurefinish', model, false);
  },
  _clearMeasure: function () {
    this._latlngs = [];
    this._resultsModel = null;
    this._measureVertexes.clearLayers();
    if (this._measureDrag) {
      this._layer.removeLayer(this._measureDrag);
    }
    if (this._measureArea) {
      this._layer.removeLayer(this._measureArea);
    }
    if (this._measureBoundary) {
      this._layer.removeLayer(this._measureBoundary);
    }
    this._measureDrag = null;
    this._measureArea = null;
    this._measureBoundary = null;
  },
});

L.Map.mergeOptions({
  measureControl: false,
});

L.Map.addInitHook(function () {
  if (this.options.measureControl) {
    this.measureControl = new L.Control.Measure().addTo(this);
  }
});

L.control.measure = function (options) {
  return new L.Control.Measure(options);
};
