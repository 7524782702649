import { paramsNotFound } from '../../services/axiosFiles/axiosUtils';
import { fetchDatasNew } from '../../services/axiosFiles/genericCrud';

type FetchInseeCodeResult = {
  department: string;
  inseeCode: string;
  lat: number;
  lng: number;
};
export async function fetchInseeFromLatLngDep(
  lat: number,
  lon: number,
  department: string
): Promise<FetchInseeCodeResult> {
  try {
    if (lat && lon && department) {
      const result = await fetchDatasNew({
        endpoint: '/get_municipality_code_from_coordinates/',
        config: { params: { lon, lat, department } },
        hasPythonProxy: true,
      });

      const parsedResult = {
        department: result.department,
        lat: result.lat,
        lng: result.lon,
        inseeCode: result.insee,
      };

      return parsedResult;
    } else {
      return Promise.reject(paramsNotFound());
    }
  } catch (error: any) {
    return Promise.reject(error);
  }
}
