import { isEmpty } from 'lodash';
import React from 'react';
import createCtx from '../../App/contexts/GenenricStateContext';
import IndividualCircularLoader from '../../features/loaders/individualCircularLoader';
import ErrorNoDatas from '../errors/NoDatasError';
import MarkerPopupGenericContent from './MarkerPopupGenericContents';
import MarkerPopupGenericTabs from './MarkerPopupGenericTabs';
import styles from './markerPopupGeneric.module.scss';

interface IMarkerPopupGenericProps<T> {
  title?: string;
  tabs: string[] | null;
  contents: T[] | null;
  error?: boolean;
  children: React.ReactNode;
}

const [ctx, GenericPopupProvider] = createCtx<number>(0);
export const GenericPopupContext = ctx;

function MarkerPopupGeneric<T>({
  title,
  tabs,
  contents,
  children,
  error,
}: IMarkerPopupGenericProps<T>) {
  if (!error && (isEmpty(tabs) || isEmpty(contents))) {
    return <IndividualCircularLoader size={150} />;
  }

  if (error) {
    return <ErrorNoDatas />;
  }

  return (
    <>
      {!isEmpty(tabs) && (
        <GenericPopupProvider>
          <div className={styles.genericPopup}>
            {title && <h2>{title}</h2>}
            {tabs && contents && (
              <>
                <MarkerPopupGenericTabs tabs={tabs} />
                <MarkerPopupGenericContent contents={contents}>
                  {children}
                </MarkerPopupGenericContent>
              </>
            )}
          </div>
        </GenericPopupProvider>
      )}
    </>
  );
}

export default MarkerPopupGeneric;
