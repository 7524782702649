import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';

const RestaurantSvg = ({ color }: { color?: string }) => (
  <Svg
    height="24"
    width="24"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M17.4862 21.5885C17.2922 21.5885 17.1268 21.52 16.9899 21.3829C16.8531 21.2459 16.7846 21.0805 16.7846 20.8866V13.6692H15.8414C15.3712 13.6692 14.9692 13.5023 14.6354 13.1685C14.3016 12.8347 14.1347 12.4327 14.1347 11.9625V7.06058C14.1347 5.85227 14.5154 4.79875 15.2769 3.90003C16.0385 3.00132 17.0074 2.51157 18.1836 2.43078V20.8866C18.1836 21.0805 18.1151 21.2459 17.978 21.3829C17.8409 21.5199 17.677 21.5884 17.4862 21.5884V21.5885ZM8.05257 21.5885C7.85857 21.5885 7.69314 21.52 7.55627 21.3829C7.41942 21.2459 7.351 21.0805 7.351 20.8866V12.6173C6.55805 12.4551 5.88577 12.0559 5.33417 11.4197C4.78257 10.7835 4.50677 10.0289 4.50677 9.15578V3.08846C4.50677 2.89776 4.57532 2.7339 4.7124 2.59688C4.84948 2.45987 5.01502 2.39136 5.20902 2.39136C5.39982 2.39136 5.56365 2.45987 5.7005 2.59688C5.83735 2.7339 5.90577 2.89776 5.90577 3.08846V9.15578H7.351V3.08846C7.351 2.89776 7.41954 2.7339 7.55663 2.59688C7.69371 2.45987 7.85925 2.39136 8.05325 2.39136C8.24405 2.39136 8.40787 2.45987 8.54472 2.59688C8.68157 2.7339 8.75 2.89776 8.75 3.08846V9.15578H10.175V3.08846C10.175 2.89776 10.2436 2.7339 10.3807 2.59688C10.5177 2.45987 10.6833 2.39136 10.8773 2.39136C11.0681 2.39136 11.2319 2.45987 11.3688 2.59688C11.5056 2.7339 11.574 2.89776 11.574 3.08846V9.15578C11.574 10.0289 11.3016 10.7835 10.7567 11.4197C10.2119 12.0559 9.54295 12.4551 8.75 12.6173V20.8866C8.75 21.0805 8.68146 21.2459 8.54437 21.3829C8.40731 21.5199 8.24337 21.5884 8.05257 21.5884V21.5885Z"
      fill={color ?? 'currentColor'}
    />
  </Svg>
);

export default RestaurantSvg;
