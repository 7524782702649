function getBiggestPropertyObjectFromArray<T>(array: T[], property: keyof T) {
  return array.reduce((acc, curr) => {
    if (curr[property] > acc[property]) {
      return curr;
    } else {
      return acc;
    }
  });
}

export default getBiggestPropertyObjectFromArray;
