import { Path, Svg } from '@react-pdf/renderer';

const CarSvg = ({
  color = 'currentColor',
  width = '24px',
  height = '24px',
}: {
  color?: string;
  height?: string;
  width?: string;
}) => (
  <Svg
    height={height}
    width={width}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M5.63554 18.3586V19.3663C5.63554 19.6269 5.54279 19.8507 5.35729 20.0377C5.17177 20.2247 4.93715 20.3182 4.65344 20.3182C4.38064 20.3182 4.15049 20.2232 3.96299 20.0333C3.7755 19.8433 3.68176 19.6127 3.68176 19.3413V12.0994L5.70386 6.37116C5.77821 6.16348 5.90682 5.99665 6.08969 5.87069C6.27254 5.74474 6.47469 5.68176 6.69614 5.68176H17.3423C17.5558 5.68176 17.7497 5.74506 17.9239 5.87166C18.0982 5.99825 18.2222 6.16475 18.2961 6.37116L20.3182 12.0995V19.3663C20.3182 19.6269 20.2255 19.8507 20.04 20.0377C19.8545 20.2247 19.6199 20.3182 19.3361 20.3182C19.0633 20.3182 18.8332 20.2232 18.6457 20.0333C18.4582 19.8433 18.3644 19.6127 18.3644 19.3413V18.3586H5.63554ZM5.64804 10.6981H18.3519L17.0702 7.08076H6.92979L5.64804 10.6981ZM7.50179 15.7856C7.85379 15.7856 8.15302 15.6624 8.39949 15.416C8.64597 15.1696 8.76921 14.8704 8.76921 14.5184C8.76921 14.1664 8.64601 13.8671 8.39961 13.6207C8.15321 13.3742 7.85401 13.251 7.50201 13.251C7.15003 13.251 6.8508 13.3742 6.60431 13.6206C6.35785 13.867 6.23461 14.1662 6.23461 14.5181C6.23461 14.8701 6.35781 15.1694 6.60421 15.4158C6.85061 15.6623 7.1498 15.7856 7.50179 15.7856ZM16.498 15.7856C16.8499 15.7856 17.1492 15.6624 17.3957 15.416C17.6421 15.1696 17.7654 14.8704 17.7654 14.5184C17.7654 14.1664 17.6422 13.8671 17.3958 13.6207C17.1494 13.3742 16.8502 13.251 16.4982 13.251C16.1462 13.251 15.847 13.3742 15.6005 13.6206C15.354 13.867 15.2308 14.1662 15.2308 14.5181C15.2308 14.8701 15.354 15.1694 15.6004 15.4158C15.8468 15.6623 16.146 15.7856 16.498 15.7856ZM5.08076 16.9596H18.9192V12.0971H5.08076V16.9596Z"
      fill={color}
    />
  </Svg>
);

export default CarSvg;
