import { nanoid } from '@reduxjs/toolkit';
import ReportingTableRow from './ReportingTableRow';

interface IUserContainerProps {
  displayedUsers: ReportingUsersStats;
  indexPage: number;
  itemsPerPage: number;
}

function UserContainer({
  displayedUsers,
  indexPage,
  itemsPerPage,
}: IUserContainerProps) {
  return (
    <>
      {displayedUsers
        ?.filter(
          (f: ReportingUserStats, i: number) =>
            i >= indexPage && i < indexPage + itemsPerPage
        )
        .map((u: ReportingUserStats) => (
          <ReportingTableRow key={nanoid()} row={u} />
        ))}
    </>
  );
}

export default UserContainer;
