import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TableCell, TableRow } from '@mui/material';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import '../../../components/Dashboard/DirectoryTab/DirectoryTab.scss';

import { getModalsState, modalsActions } from '../../modals/modalsSlice';
import ContactPlotStudiesContent from '../../plotStudies/contactPlotStudies/dashboard/ContactPlotStudiesContent';
import { contactsActions, getContactsState } from '../contactsSlice';

interface IDirectoryTableRowProps {
  contact: Contact;
  selectedContactIdIri?: string;
  isRightPanelTab?: boolean;
  onClick?: (contact: Contact) => void;
}

function DirectoryTableRow({
  contact,
  isRightPanelTab,
  onClick,
}: IDirectoryTableRowProps): JSX.Element {
  const { contactForAction } = useAppSelector(getContactsState);
  const { readMore } = useAppSelector(getModalsState);
  const dispatch = useAppDispatch();
  const isSelected = contact.idIri === contactForAction?.idIri;

  const handleContentDisplay = (contact: Contact) => {
    if (!isSelected) {
      dispatch(contactsActions.contactForActionSet(contact));
    } else {
      dispatch(contactsActions.contactForActionReset());
    }
  };

  const handleclick = (contact: Contact) => {
    if (onClick) {
      onClick(contact);
    }
  };

  const onClickReadMoreModal = (type: ReadMoreModalType) => {
    dispatch(contactsActions.contactForActionSet(contact));
    dispatch(modalsActions.readMore({ status: true, type }));
  };

  return (
    <>
      <TableRow className="row">
        <TableCell onClick={() => handleclick(contact)}>
          {contact.lastName && contact.firstName
            ? `${contact.lastName ?? ''} ${contact.firstName ?? ''}`
            : contact.name}
        </TableCell>

        <TableCell onClick={() => handleclick(contact)}>
          {contact.phone ?? '-'}
        </TableCell>
        <TableCell onClick={() => handleclick(contact)}>
          {contact.email ? (
            <a href={`mailto:${contact.email}`} target="_blank" rel="noreferrer">
              {contact.email}
            </a>
          ) : (
            '-'
          )}
        </TableCell>
        <TableCell onClick={() => handleclick(contact)}>
          {contact.addressOld ?? ' - '}
        </TableCell>
        <TableCell onClick={() => handleclick(contact)}>
          {contact.description ? (
            <span className="read-more">
              <p onClick={() => onClickReadMoreModal('description')}>...</p>
            </span>
          ) : (
            '-'
          )}
        </TableCell>
        {!isRightPanelTab && (
          <TableCell onClick={() => handleclick(contact)}>
            <ExpandMoreIcon
              className={`icon ${isSelected && !readMore.status ? 'down' : ''}`}
              onClick={() => handleContentDisplay(contact)}
            />
          </TableCell>
        )}
      </TableRow>
      {!isRightPanelTab && !readMore.status && isSelected && (
        <ContactPlotStudiesContent contactIdIri={contactForAction.idIri} />
      )}
    </>
  );
}

const propsAreEqual = (
  p: Readonly<IDirectoryTableRowProps>,
  n: Readonly<IDirectoryTableRowProps>
): boolean => {
  let result = true;
  if (
    p.isRightPanelTab !== n.isRightPanelTab ||
    p.selectedContactIdIri !== n.selectedContactIdIri
  ) {
    result = false;
  } else if (!isEqual(p.contact, n.contact)) {
    result = false;
  }

  return result;
};

export default memo(DirectoryTableRow, propsAreEqual);
