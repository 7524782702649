import * as Yup from 'yup';
import { StudyMatrixFormValues } from '.';
import { transformNumber } from '../../../../../shared/utils/yupSchema';

export const matrixInitialValues: StudyMatrixFormValues = {
  areaField: 0,
  builtFootprint: 0,
  builtFreeOnGround: 0,
  floors: 0,
  avgAreaHousing: 0,
  socialHousing: 0,
  spShab: 0,
  buildingFootprint: 0,
  freeBuiltAreaMin: 0,
  floorAreaMax: 0,
  housingAreaMax: 0,
  socialHousingAreaMax: 0,
  housingQty: 0,
  socialHousingQty: 0,
  parkingQty: 0,
  estimatedParkingArea: 0,
  equivalentUnderGroundArea: 0,
};

export const matriceValidationSchema = Yup.object({
  areaField: Yup.number().transform(transformNumber),
  builtFootprint: Yup.number().transform(transformNumber),
  builtFreeOnGround: Yup.number().transform(transformNumber),
  floors: Yup.number().transform(transformNumber),
  avgAreaHousing: Yup.number().transform(transformNumber),
  socialHousing: Yup.number().transform(transformNumber),
  spShab: Yup.number().transform(transformNumber),
  buildingFootprint: Yup.number().transform(transformNumber),
  freeBuiltAreaMin: Yup.number().transform(transformNumber),
  floorAreaMax: Yup.number().transform(transformNumber),
  housingAreaMax: Yup.number().transform(transformNumber),
  socialHousingAreaMax: Yup.number().transform(transformNumber),
  housingQty: Yup.number().transform(transformNumber),
  socialHousingQty: Yup.number().transform(transformNumber),
  parkingQty: Yup.number().transform(transformNumber),
  estimatedParkingArea: Yup.number().transform(transformNumber),
  equivalentUnderGroundArea: Yup.number().transform(transformNumber),
});
