import { isEmpty } from 'lodash';
import imageNotAvailable from '../../../../assets/images/orpiPdf/imageNotAvailable.png';

export const geolocDataCity = (
  geolocDatas: { city: string; postalCode: string } | null
) => `${geolocDatas?.city ?? ''} ${geolocDatas?.postalCode ?? ''}`;

export const geolocDataStreet = (
  geolocDatas: { houseNumber: string | null; streetName: string | null } | null
) =>
  geolocDatas?.houseNumber || geolocDatas?.streetName
    ? `${geolocDatas?.houseNumber ?? ''} ${
        (geolocDatas?.streetName?.charAt(0).toLowerCase() ?? '') +
          geolocDatas?.streetName?.slice(1) ?? ''
      }`
    : '';

export function orpipdfDatasReduce(
  plotsPdf: MultiplotsPdfs | null,
  key: keyof IMultiPlotsPdf
) {
  const value = plotsPdf?.reduce(
    (accumulator, elt) => accumulator + (elt[key] as number),
    0
  );
  return Math.round(value as number);
}

export function getFullPlotsIdArray(
  isMulti: boolean,
  plotsPdfs: MultiplotsPdfs | null,
  plot?: IPlot | null
) {
  let plots: string[] = [];
  if (isMulti) {
    if (plotsPdfs && !isEmpty(plotsPdfs)) {
      plots = plotsPdfs.map((m) => m.fullPlotId);
    }
  } else if (plot) {
    plots = [plot.parcelleId];
  }

  return plots;
}

export function convertDistance(distanceMeters: number) {
  if (distanceMeters < 1000) {
    return distanceMeters.toFixed(0) + ' m';
  } else {
    const distanceKilometers = distanceMeters / 1000;
    if (distanceKilometers < 10) {
      // Show one decimal place for distances less than 10 km
      return distanceKilometers.toFixed(1) + ' km';
    } else {
      // Show an integer for distances of 10 km or more
      return Math.round(distanceKilometers) + ' km';
    }
  }
}
export const distanceParser = (d: number) =>
  d < 1000 ? `${d.toFixed(0)} m` : `${(d / 1000).toFixed(2)} km`;
export const moreOrLessDistance = (d: number) =>
  d < 1000 ? `${d.toFixed(0)} m` : '> 1km';
export const getImgSrcByServer = (data: string | null) => {
  if (!data) {
    return imageNotAvailable;
  }

  // const isPortrait = isPortraitView(data);
  return `https://img.kadastre.fr/index.old.php?url=${Buffer.from(data).toString(
    'base64'
  )}`;
};

type FindUserDefinedFieldsValueProps = Array<{
  label: string;
  value: string;
}>;
export function findUserDefinedFieldsValue(
  values: FindUserDefinedFieldsValueProps,
  key: string
) {
  const res = values.find((f) => f.label === key) ?? null;

  return res;
}

export function findUserDefinedFieldsBooleanValue(
  values: FindUserDefinedFieldsValueProps,
  key: string
) {
  const res = findUserDefinedFieldsValue(values, key);
  const isTrue = ['True', 'Oui'];

  return res && isTrue.includes(res.value) ? true : false;
}

export function findUserDefinedFieldsNumberValue(
  values: FindUserDefinedFieldsValueProps,
  key: string
) {
  try {
    const res = findUserDefinedFieldsValue(values, key);
    //if no values return null
    if (!res) return null;

    // number filter
    const numbers = res.value.match(/\d+/g);
    //if no numbers found, return null
    if (!numbers || isEmpty(numbers)) return null;

    const number = numbers[0];
    // return first number
    return parseInt(number, 10) as number;
  } catch (error) {
    return null;
  }
}
