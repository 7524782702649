export const URBA_COLOR_PRIMARY = '#4e55a2';

// ROUTER
export const NO_REDIRECT_TO_HOME_AFTER_LOGGED = ['/external_connection'];
export const NO_REDIRECT_TO_HOME_FOR_PUBLIC_ROUTES = [
  '/external_connection',
  '/reset_password',
  '/init_first_password',
];

// SECTOR COLOR ON MAP
export const SECTOR_OWNER_COLOR = '#FFA500';
export const SUBSECTOR_OWNER_COLOR = '#d58300';
export const SECTOR_DEFAULT_COLOR = '#F80000';
export const SUBSECTOR_DEFAULT_COLOR = '#a01a0a';
export const SECTOR_DRAW_COLOR = '#800080';
export const SECTOR_EDIT_COLOR = '#1E90FF';

// DISPLAY
export const MAX_PLOT_DISPLAYED_BEFORE_ONLY_MARKER = 50;

export const LEFT_PANEL_PROSPECTION_ENTITY_NAME_LENGTH = 25;
export const DASHBOARD_PROSPECTION_ENTITY_NAME_LENGTH = 30;

// PDF
export const PDF_ORPI_URLS = ['/pdf-orpi', '/pdf-orpi-terrain'];
export const PDF_URLS = PDF_ORPI_URLS.concat('/pdf-urbanease');

export const PDF_MAX_COMMENT_CHARACTERS = 440;
export const PDF_LAND_MAX_COMMENT_CHARACTERS = 360;
export const PDF_LAND_MAX_PRICE_COMMENT_CHARACTERS = 400;

export const ORPI_PAGE_FIRM_NAME_ACCESS = 'Orpi';

// App Layout
export const DEFAULT_META_DESCRIPTION = '';
export const DEFAULT_META_KEYWORDS = '';

// ERRORS
export const ERROR_NO_DOM_AND_CORSE_DATAS = 'no datas for theses countries';

// used before orpi-land merge branch
export const PDF_ACCESS_FIRM_LIST = ['Orpi', 'Urbanease'];

// DPE
export const DPE_ITEM_PER_PAGE_REQUEST = 50000;

// study propriety list
export const PROSPECTION_PRIORITY_LIST = [
  { value: 0, display: 'Aucune priorité' },
  { value: 1, display: 'Basse' },
  { value: 2, display: 'Moyenne' },
  { value: 3, display: 'Haute' },
];

// CESIUM
export const CESIUM_CAMERA_DEFAULT_HEIGTH = 600;
export const CESIUM_DEFAULT_CAMERA_SHIFT = 0.005;

// MAILSHOTS
export const MAILSHOT_MAX_MODEL_AUTHORIZED = 3;
export const MAILSHOT_MAX_PLOT_EDITION_COUNT = 10;
export const MAILSHOT_MAX_CONTACT_EDITION_COUNT = 10;
export const MAILSHOT_DEFAULT_MODELS = [
  { name: 'Propriétaire parcelle unique', type: 'plot' },
  { name: 'Propriétaire multi parcelle', type: 'subfolder' },
];

// UPLOAD IMAGE
export const UPLOAD_IMAGE_AUTHORIZED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'webp'];
export const UPLOAD_IMAGE_MAX_WIDTH = 30000;
export const UPLOAD_IMAGE_MAX_HEIGHT = 30000;
export const UPLOAD_IMAGE_MAX_SIZE = 10 * 1024 * 1024; // 10Mo
export const LOGO_DISPLAY_WIDTH = 70;
