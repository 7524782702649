import * as Yup from 'yup';
import { validationMessages } from '../../../constants/validations';

export const initialLoginForm: ILoginParams = {
  email: '',
  password: '',
};

export const initialForgotPasswordForm: { email: string } = {
  email: '',
};

export const validationSchemaForgotPasswordForm = Yup.object({
  email: Yup.string()
    .email(validationMessages.email.error)
    .required(validationMessages.email.requis),
});

export const validationSchemaLoginForm = validationSchemaForgotPasswordForm.shape({
  password: Yup.string().required('Mot de passe requis'),
});
