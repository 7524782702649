import { Document } from '@react-pdf/renderer';
import PageOne from './pdfFiles/Page1';
import PageOneOrpi from './pdfFiles/Page1Orpi';
import PageTwo from './pdfFiles/Page2';
import PageThree from './pdfFiles/Page3';
import PageFour from './pdfFiles/Page4';
import PageFive from './pdfFiles/Page5';
import PageSix from './pdfFiles/Page6';

interface IOrpiPdfContainerProps {
  datas: PDFDatasType | null;
  isOrpi: boolean;
}

function OrpiErrialPdfContainer({ datas, isOrpi }: IOrpiPdfContainerProps) {
  if (datas) {
    return (
      <Document>
        {isOrpi ? <PageOneOrpi {...datas} /> : <PageOne {...datas} />}
        <PageTwo {...datas} isOrpi={isOrpi} />
        <PageThree {...datas} isOrpi={isOrpi} />
        <PageFour {...datas} isOrpi={isOrpi} />
        {isOrpi && <PageFive {...datas} isOrpi={isOrpi} />}
        {isOrpi && <PageSix {...datas} isOrpi={isOrpi} />}
      </Document>
    );
  } else {
    return <Document />;
  }
}

export default OrpiErrialPdfContainer;
