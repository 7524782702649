import { Circle, G, Path, Rect, Svg } from '@react-pdf/renderer';

function OrpiLogo({
  red,
  width,
  height,
}: {
  red?: boolean;
  width: string;
  height: string;
}) {
  const color = red ? '#E30513' : '#FFFFFF';

  return (
    <Svg
      id="orpiLogo"
      width={width}
      height={height}
      viewBox="0 0 120.85 63.49"
      preserveAspectRatio="xMidYMid meet"
    >
      <G>
        <Path
          d="M52.8,41.61h7.06V29.5c0,0,0-4.55,6.64-4.55V18.6c0,0-5.36-0.99-9.46,3.11s-4.24,6.95-4.24,8.35
		C52.8,31.44,52.8,41.61,52.8,41.61z"
          fill={color}
        />
        <Path
          d="M68.05,25.37h5.65c0,0,1.27,0.56,1.27,7.62c0,0,1.41,2.96,5.51,2.96s5.05-2.75,5.05-5.05s-0.6-5.08-5.08-5.08
		c-4.48,0-4.76-2.01-4.76-2.01v-5.93h7.2c0,0,8.75,1.02,8.75,12.16s-8.61,12.41-10.45,12.41s-4.8-0.71-6.49-1.84V52.2h-6.64V25.37z"
          fill={color}
        />
        <Rect x="94.87" y="18.49" width="7.2" height="23.12" fill={color} />
        <Circle cx="98.47" cy="11.11" r="4.24" fill={color} />
        <Path
          d="M32.61,9c-10.29,0-18.63,8.34-18.63,18.63s8.34,18.63,18.63,18.63s18.63-8.34,18.63-18.63S42.9,9,32.61,9z
		 M32.61,38.92c-6.24,0-11.29-5.06-11.29-11.29s5.06-11.29,11.29-11.29s11.29,5.06,11.29,11.29S38.85,38.92,32.61,38.92z"
          fill={color}
        />
      </G>
    </Svg>
  );
}

export default OrpiLogo;
