import { FormControl, TextareaAutosize } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

interface ITextAreaFormCustomProps {
  name: string;
  className?: string;
  onFocus?: () => void;
  rowsMax?: number;
  rowsMin?: number;
  placeholder?: string;
}

export default function TextAreaFormCustom({
  name,
  onFocus,
  className,
  rowsMax = 8,
  rowsMin = 8,
  placeholder = 'Message ...',
}: ITextAreaFormCustomProps) {
  const { control } = useFormContext();
  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl variant="outlined">
          <TextareaAutosize
            {...field}
            placeholder={placeholder}
            maxRows={rowsMax}
            minRows={rowsMin}
            className={className}
            onFocus={handleFocus}
          />
        </FormControl>
      )}
    />
  );
}
