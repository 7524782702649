type ErrorMessageType = {
  [x: string]: { errMessage: string; displayMessage: string };
};

export const authErrorMessages: ErrorMessageType = {
  badCredentials: {
    errMessage: 'Invalid credentials.',
    displayMessage: 'Votre identifiant ou mot de passe est incorrect',
  },
  expiredJwtToken: {
    errMessage: 'Expired JWT Token',
    displayMessage: "Token d'authentification expiré",
  },
  invalidJwtToken: {
    errMessage: 'Invalid JWT Token',
    displayMessage: "Token d'authentification invalide",
  },
  invalidJwtRefreshToken: {
    errMessage: 'An authentication exception occured',
    displayMessage: "Token de regénération d'autentification invalide ou expiré",
  },
  companyNotFound: {
    errMessage: 'User has no company',
    displayMessage:
      'Nous ne trouvons aucun compte utilisateur avec vos identifiants',
  },
  expiredSubscription: {
    errMessage: 'One or all subscriptions have expired',
    displayMessage: 'Souscription expirée',
  },
  subscriptionNotFound: {
    errMessage: 'User has no subscription',
    displayMessage:
      'Nous ne trouvons aucun compte utilisateur avec vos identifiants',
  },
};

export const classicErrorMessage = {
  noCompanyId: 'Companie non chargée',
  noUserId: 'Utilisateur non chargée',
};
