import { nanoid } from '@reduxjs/toolkit';
import IndividualCircularLoader from '../../../../../../../features/loaders/individualCircularLoader';
import useCityDatas from '../../../../../../../features/plot/activePlotFeature/hooks/useCityDatas';
import { APIStatus } from '../../../../../../../services/axiosFiles/apiTypes';
import ParkingPackage from './ParkingPackage';
import styles from './parkingPackage.module.scss';
function ParkingPackages() {
  const { cityDatasApiStatus, cityDatasError, parkingPackages } = useCityDatas();

  if (parkingPackages.apiStatus === APIStatus.PENDING) {
    return <IndividualCircularLoader size={50} />;
  }

  return (
    <div className={styles.parkingPackages}>
      <ul>
        {parkingPackages.result?.map((m) => (
          <ParkingPackage data={m} key={nanoid()} />
        ))}
      </ul>
    </div>
  );
}

export default ParkingPackages;
