import { TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import React, { MouseEvent } from 'react';

interface IPermisTableHeaderProps {
  orderBy: string;
  order: Order;
  onRequestSort: (event: MouseEvent<unknown>, column: string) => void;
}

function TableHeader({
  orderBy,
  order,
  onRequestSort,
}: IPermisTableHeaderProps): JSX.Element {
  const createSortHandler =
    (column: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, column);
    };
  return (
    <TableHead className="table-head">
      <TableRow>
        <TableCell rowSpan={2}>Adresse</TableCell>
        <TableCell rowSpan={2}>Parcelle</TableCell>
        <TableCell colSpan={3}>Autorisation</TableCell>
        <TableCell rowSpan={2}>Etat</TableCell>
        <TableCell rowSpan={2}>Date achèvement</TableCell>
        <TableCell colSpan={2}>Demandeur</TableCell>
        <TableCell rowSpan={2}>
          <TableSortLabel
            active={orderBy === 'typology'}
            direction={orderBy === 'typology' ? order : 'asc'}
            onClick={createSortHandler('typology')}
          >
            Typologie
          </TableSortLabel>
        </TableCell>
        <TableCell rowSpan={2}>
          <TableSortLabel
            active={orderBy === 'surface'}
            direction={orderBy === 'surface' ? order : 'asc'}
            onClick={createSortHandler('surface')}
          >
            Surface Projet total
          </TableSortLabel>
        </TableCell>
        <TableCell colSpan={2}>Lots</TableCell>
        <TableCell rowSpan={2}>Hauteur</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sortDirection={'asc'}>
          <TableSortLabel
            active={orderBy === 'autorisationType'}
            direction={orderBy === 'autorisationType' ? order : 'asc'}
            onClick={createSortHandler('autorisationType')}
          >
            Type
          </TableSortLabel>
        </TableCell>
        <TableCell>Numéro</TableCell>
        <TableCell>
          <TableSortLabel
            active={orderBy === 'autorisationDateSort'}
            direction={orderBy === 'autorisationDateSort' ? order : 'asc'}
            onClick={createSortHandler('autorisationDateSort')}
          >
            Date
          </TableSortLabel>
        </TableCell>
        <TableCell>Dénomination</TableCell>
        <TableCell>SIREN</TableCell>
        <TableCell>Total</TableCell>
        <TableCell>Sociaux</TableCell>
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
