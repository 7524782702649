import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
// import { CHANGE_COMPANY } from '../auth/actions';
import { SET, SUCCESS } from '../common/actions';
import {
  ADD_FAVORITE_SEARCH,
  DELETE_FAVORITE_SEARCH,
  FAVORITE_WINDOW_DISPLAY,
  FETCH_FAVORITE_SEARCH,
} from './actions';

export interface FavoriteSearch {
  idIri: string;
  predictionId: string;
  address: string;
  city: string;
  department: string;
  inseeCode: string;
  lat: number;
  lng: number;
  postalCode: string;
  isCitySearch: boolean;
  ownerId: string;
}
interface FavoritePlacesState {
  favoritePlaces: FavoriteSearch[];
  windowDisplay: boolean;
  apiStatus: import('../../services/axiosFiles/apiTypes').APIStatus;
}

const initialState: FavoritePlacesState = {
  favoritePlaces: [],
  windowDisplay: false,
  apiStatus: APIStatus.IDLE,
};

const favoritePlacesReducer = (
  state: FavoritePlacesState = initialState,
  {
    type,
    payload,
  }: IAction<
    string,
    Partial<FavoritePlacesState> | string | FavoriteSearch | boolean
  >
): FavoritePlacesState => {
  switch (type) {
    case FETCH_FAVORITE_SEARCH[SUCCESS]:
      return {
        ...state,
        favoritePlaces:
          (payload as Partial<FavoritePlacesState>).favoritePlaces || [],
      };
    case FAVORITE_WINDOW_DISPLAY[SET]:
      return {
        ...state,
        windowDisplay: payload as boolean,
      };
    case ADD_FAVORITE_SEARCH[SUCCESS]:
      return {
        ...state,
        favoritePlaces: state.favoritePlaces.concat(payload as FavoriteSearch),
      };
    case DELETE_FAVORITE_SEARCH[SUCCESS]:
      return {
        ...state,
        favoritePlaces: state.favoritePlaces.filter(
          (f) => f.idIri !== (payload as string)
        ),
      };
    // case CHANGE_COMPANY[SUCCESS]:
    //   return { ...initialState };
    default:
      return state;
  }
};

export default favoritePlacesReducer;
export const getFavoritePlacesState = (state: RootState) =>
  state.favoritePlacesReducer;
