import * as Cesium from 'cesium';
import {
  CESIUM_CAMERA_DEFAULT_HEIGTH,
  CESIUM_DEFAULT_CAMERA_SHIFT,
} from '../../../../shared/constants';

async function initialCesiumFlyTo(
  viewer: Cesium.Viewer,
  position: StreetViewLatLngType,
  delay: number
) {
  const destination = Cesium.Cartesian3.fromDegrees(
    position.lng,

    position.lat - CESIUM_DEFAULT_CAMERA_SHIFT,
    position.height
      ? position.height + CESIUM_CAMERA_DEFAULT_HEIGTH
      : CESIUM_CAMERA_DEFAULT_HEIGTH
  );
  viewer.camera.flyTo({
    destination,
    orientation: {
      heading: 0, // Direction de la caméra en radians
      pitch: Cesium.Math.toRadians(-40), // Inclinaison de la caméra en radians
      roll: 0, // Rotation de la caméra
    },
    duration: delay, // Durée du vol en secondes
  });
}

export default initialCesiumFlyTo;
