import styles from '../styles/prospecForm.module.scss';
import PriceStatSection from './PriceStatSection';

interface IProps {
  error: 'min' | 'max' | 'fork' | null;
}

function ProspecPriceEstimateField({ error }: IProps) {
  return (
    <>
      <PriceStatSection
        title="Prix estimé retenu"
        name="estimatedPrice"
        error={error !== null && error !== 'fork'}
        hasCalculatedSqm
      />{' '}
      {error === 'min' && (
        <p className={styles.errorMessage}>
          La fourchette minimum est supérieure à votre valeur estimée
          <br />
          Modifiez la fourchette basse.
        </p>
      )}{' '}
      {error === 'max' && (
        <p className={styles.errorMessage}>
          La fourchette maximum est inférieure à votre valeur estimée.
          <br />
          Modifiez la fourchette haute.
        </p>
      )}
    </>
  );
}

export default ProspecPriceEstimateField;
