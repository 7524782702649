import { updateEntity } from '../../../services/axiosFiles/genericCrud';
import { contactApiToStoreParser, contactStoreToApiParser } from '../contactParser';

/**
 * Update Company contact
 * @param {string|null} contactIdIri - contact id to update
 * @param {Partial<ContactFormType>} body - values to update
 * @returns {Contact} - contact updated
 */
async function updateContact(
  contactIdIri: string | null,
  body: Partial<ContactFormType>
) {
  try {
    if (!contactIdIri) throw new Error('no contact idIri found');
    if (!body) throw new Error('no contact idIri found');

    // parse front data properties for api request
    const parseForApi = contactStoreToApiParser(body);

    // update request
    const result = await updateEntity({
      idIri: contactIdIri,
      body: parseForApi,
    });

    // parse result
    const parsedResponse = contactApiToStoreParser(result);

    // return parsed result
    return parsedResponse;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default updateContact;
