import { isEmpty } from 'lodash';
import store from '../../../App/store';
import { InternalError } from '../../../services/axiosFiles/axiosUtils';
import { MAX_PLOT_DISPLAYED_BEFORE_ONLY_MARKER } from '../../../shared/constants';
import { fetchFolderPlotStudies } from '../../folders/services/fetchFolderPlotStudies';
import fetchFolderFilterSortQuery from '../../folders/utils/fetchFolderFilterSortQuery';
import { displayManagerActions } from '../displayManagerSlice';
import { getEntityAndSubsUniqueTab } from '../utils';
import { plotStudiesToEntitiesDisplay } from '../utils/entitiesDisplayParsers';

const fetchFolderAndSubsPlotStudiesForDisplay = async (
  folder: IFolder,
  users: Users,
  plotStudyStatuses: StudyStatuses,
  companyId: number | null
) => {
  try {
    // concat parent with subs
    const folders = getEntityAndSubsUniqueTab(folder) as Folders;
    const { customQuery, query } = fetchFolderFilterSortQuery(companyId as number);

    const psCount = folders.reduce((prev, cur) => {
      return prev + cur.plotStudiesCount;
    }, 0);

    const requests = folders.map((f) =>
      fetchFolderPlotStudies({
        folder: f as IFolder,
        config: {
          params: {
            hasGeom: psCount <= MAX_PLOT_DISPLAYED_BEFORE_ONLY_MARKER,
            ...query,
          },
        },
        users: users ?? [],
        statuses: plotStudyStatuses ?? [],
        customQuery: customQuery,
      }).then(
        (res) => {
          // result must have at least one ps for display
          if (!isEmpty(res)) {
            const entities = plotStudiesToEntitiesDisplay(res);
            const folderIdIri = entities[0].parent!;

            // display markers only
            store.dispatch(
              displayManagerActions.folderEntitiesAdd({
                entities,
                folderIdIris: [folderIdIri],
              })
            );
          }
        },
        (err) => {}
      )
    );

    // launch all plot studies requests
    await Promise.all(requests).then(
      (res) => {
        store.dispatch(displayManagerActions.setIsFoldersPlotGeomLoading(false));
      },
      (err: any) => {
        store.dispatch(displayManagerActions.setIsFoldersPlotGeomLoading(false));
      }
    );
  } catch (error) {
    store.dispatch(displayManagerActions.setIsFoldersPlotGeomLoading(false));
    return Promise.reject(InternalError(error));
  }
};

export default fetchFolderAndSubsPlotStudiesForDisplay;
