import IconContainer from './IconContainer';

function MailshotIcon(props: ISharedIconsProps) {
  const { tooltipText } = props;

  return (
    <IconContainer {...props} tooltipText={tooltipText}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path d="M30,23H14a1,1,0,0,1-1-1V10a1,1,0,0,1,1-1H30a1,1,0,0,1,1,1V22A1,1,0,0,1,30,23ZM15,21H29V11H15Z" />
        <path d="M22,19a.9967.9967,0,0,1-.707-.293l-8-8A1,1,0,0,1,14,9H30a1,1,0,0,1,.707,1.707l-8,8A.9967.9967,0,0,1,22,19Zm-5.5859-8L22,16.5859,27.5859,11Z" />
        <path d="M10,23H6a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z" />
        <path d="M10,19H2a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z" />
        <path d="M10,15H6a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z" />
        <path d="M10,11H2A1,1,0,0,1,2,9h8a1,1,0,0,1,0,2Z" />
      </svg>
    </IconContainer>
  );
}

export default MailshotIcon;
