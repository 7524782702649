import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';

const HouseSvg = ({ color }: { color?: string }) => (
  <Svg
    height="14"
    width="14"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M 21 20.04 c 0 0.53 -0.448 0.96 -1 0.96 h -9.8 v -8.1 H 6.6 V 21 H 4 c -0.552 0 -1 -0.43 -1 -0.96 V 9.94 c 0 -0.297 0.142 -0.577 0.386 -0.759 l 8 -5.979 c 0.361 -0.27 0.867 -0.27 1.228 0 l 8 5.98 A 0.948 0.948 0 0 1 21 9.94 v 10.1 Z m -8.1 -7.14 h 1.8 v 1.8 h -1.8 v -1.8 Z m 4.5 0 h -1.8 v 1.8 h 1.8 v -1.8 Z m -4.5 2.7 h 1.8 v 1.8 h -1.8 v -1.8 Z m 4.5 0 h -1.8 v 1.8 h 1.8 v -1.8 Z"
      fill={color ? color : 'currentColor'}
    />
  </Svg>
);

export default HouseSvg;
