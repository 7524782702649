import { Image, View } from '@react-pdf/renderer';
import ImageNotAvailable from '../../../../../assets/images/orpiPdf/imageNotAvailable.png';

interface IProps {
  localImg?: string | null;
  height?: string;
  width: string;
  marginTop?: string;
  borderRadius?: string;
  border?: string;
  objectFit?: string;

  debug?: boolean;
}

function ImageContainer({
  localImg,
  width,
  height,
  marginTop,
  borderRadius,
  border,
  objectFit = 'cover',
  debug,
}: IProps) {
  const url = !localImg || localImg === '' ? ImageNotAvailable : localImg;

  return (
    <View
      style={{
        width,
        height,
        marginTop,
        borderRadius,
        border,
      }}
    >
      <Image
        debug={debug}
        src={url}
        style={{
          width,
          height,
          borderRadius,
          objectFit,
        }}
      />
    </View>
  );
}

export default ImageContainer;
