import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { memo } from 'react';
import SelectFolderItemCustom from './SelectFolderItemCustom/Index';

interface ISelectCustom {
  items: ISelectItem[];
  onChange: ChangeEventCustom;
  disabled?: boolean;
  label?: nullable<string>;
  name?: string;
  noValue?: string;
  value: string | number;
  displayEmpty?: boolean;
  className?: string;
  isFolders?: boolean;
  dataCy?: string;
}
function SelectCustom({
  items,
  onChange,
  value = '',
  disabled,
  label,
  name,
  noValue,
  displayEmpty,
  className,
  isFolders = false,
  dataCy,
}: ISelectCustom) {
  return (
    <FormControl variant="outlined" disabled={disabled} className={className}>
      {label ? <InputLabel id={name}>{label}</InputLabel> : null}
      <Select
        onChange={onChange}
        value={isEmpty(items) ? '' : value ?? ''}
        labelId={name}
        displayEmpty={displayEmpty}
        disabled={disabled}
        data-cy={dataCy}
      >
        {noValue && (
          <MenuItem
            key={nanoid()}
            value=""
            disabled
            data-cy={`${dataCy}-option-noValue`}
          >
            <div>{noValue}</div>
          </MenuItem>
        )}

        {items.map((elt) => (
          <MenuItem
            key={nanoid()}
            value={elt.value}
            data-cy={`${dataCy}-option-${elt.value.toString()}`}
          >
            {isFolders ? (
              <SelectFolderItemCustom
                value={elt.display as string}
                isSubfolder={elt.isSubfolder}
              />
            ) : (
              elt.display
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default memo(SelectCustom);
