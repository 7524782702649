import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import { transformNumber } from '../../../shared/utils/yupSchema';
import {
  MAX_FORM_TEXT_FIELD,
  MAX_FORM_TEXT_FIELD_MESSAGE,
} from '../shared/constants';
import { geolocDataCity, geolocDataStreet } from '../shared/utils/utils';

export const initialPdfErrialForm = (
  getCompanyName: () => TokenCompany | null,
  user: User | null
): IExternalPdfErrialForm => {
  return {
    description: '',
    addressCity: '',
    addressStreet: '',
    propertyType: '',
    livingArea: 0,
    landArea: 0,
    numberOfRooms: 0,
    floorNumber: 0,
    IdentityOwnersOnDocument: '',
    lastName: (user?.lastName as string) ?? '',
    firstName: (user?.firstName as string) ?? '',
    email: (user?.email as string) ?? '',
    phone: '',
    companyName: (getCompanyName()?.name as string) ?? '',
  };
};

export const classicFormSbDatasParser = (
  sbDatas: ISweepbrightData | null,
  geolocDatas: GeolocPointInfo | null,
  hookFormMethods: UseFormReturn<IExternalPdf, any, IExternalPdf>
) => {
  const { setValue, getValues, trigger } = hookFormMethods;

  if (sbDatas) {
    const addr = sbDatas.property?.location?.address;
    if (addr) {
      if (!getValues().addressCity) {
        setValue(
          'addressCity',
          addr.city || addr.postalCode
            ? geolocDataCity(addr)
            : geolocDataCity(geolocDatas)
        );
      }
      if (!getValues().addressStreet) {
        setValue(
          'addressStreet',
          addr.streetName || addr.houseNumber
            ? geolocDataStreet(addr)
            : geolocDataStreet(geolocDatas)
        );
      }
    } else {
      setValue(
        'addressCity',
        geolocDatas?.city ? geolocDataCity(geolocDatas) : geolocDataCity(addr)
      );
      setValue(
        'addressStreet',
        geolocDatas?.streetName
          ? geolocDataStreet(geolocDatas) ?? ''
          : geolocDataStreet(geolocDatas)
      );
    }
    const property = sbDatas.property;
    setValue('description', sbDatas.description);
    setValue('propertyType', property.propertyType ?? '');
    setValue('livingArea', property.livingArea ?? 0);
    setValue('landArea', property.landArea ?? 0);
    setValue('numberOfRooms', property.numberOfRooms ?? 0);
    setValue('floorNumber', property.floorNumbers ?? 0);
    trigger('description');
  } else {
    setValue('addressCity', geolocDataCity(geolocDatas));
    setValue('addressStreet', geolocDataStreet(geolocDatas) ?? '');
  }
};

export const validationSchemaPdfErrialForm = Yup.object({
  description: Yup.string()
    .max(
      440,
      (value) => `Le commentaire ne doit pas dépasser ${value.max} caractères`
    )
    .default(''),
  addressCity: Yup.string().default(''),
  addressStreet: Yup.string().default(''),
  propertyType: Yup.string().default(''),
  livingArea: Yup.number().default(0).transform(transformNumber),
  landArea: Yup.number().default(0).transform(transformNumber),
  numberOfRooms: Yup.number().default(0).transform(transformNumber),
  floorNumber: Yup.number().default(0).transform(transformNumber),
  IdentityOwnersOnDocument: Yup.string().default(''),
  lastName: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, (MAX_FORM_TEXT_FIELD) => MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
  firstName: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, () => MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
  email: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, () => MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
  phone: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, () => MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
  companyName: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, () => MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
});
