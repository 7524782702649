import { memo } from 'react';

function ControlTooltips() {
  const addTooltips = ({
    className,
    title,
  }: {
    className: string;
    title: string;
  }) => {
    const elementsArray = document.getElementsByClassName(className);
    const elt = elementsArray[0];
    const tooltip = document.createElement('div');
    tooltip.id = className;
    tooltip.className = className;
    tooltip.innerHTML = `<p>${title}</p>`;
    tooltip.setAttribute(
      'style',
      `visibility:hidden;
    color:#4e55a2;
    position:absolute;
    background-image: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align:center;
    border-radius:6px;
    font-size: 12px;
    line-height: 15px;
    margin-top: 62px;
    width: 150px;
    height: 40px;
    max-height: 40px;
    background: #ffffff;
    `
    );
    elt?.appendChild(tooltip);
    elt?.addEventListener('mouseover', function () {
      const id = document.getElementById(className);
      id ? (id.style.visibility = 'visible') : '';
    });
    elt?.addEventListener('mouseout', function () {
      const id = document.getElementById(className);
      id ? (id.style.visibility = 'hidden') : '';
    });
  };

  const addTooltipsMultiPlotSelection = () => {
    const eltClassName = 'radius-button';
    const eltId = 'radius-button-tooltip';
    const elementsArray = document.getElementsByClassName(eltClassName);
    const elt = elementsArray[0];

    elt?.addEventListener('mouseover', function () {
      const id = document.getElementById(eltId);
      id ? (id.style.visibility = 'visible') : '';
    });
    elt?.addEventListener('mouseout', function () {
      const id = document.getElementById(eltId);
      id ? (id.style.visibility = 'hidden') : '';
    });
  };

  const arrayTooltips = [
    { className: 'leaflet-draw-draw-polygon', title: 'dessiner secteur' },
    { className: 'leaflet-draw-edit-edit', title: 'modifier secteur dessiné' },
    { className: 'leaflet-draw-edit-remove', title: 'supprimer secteur dessiné' },
    { className: 'GPshowAdvancedToolOpen', title: 'mesure topographique' },
  ];

  const observer = new MutationObserver(() => {
    const elementsArray = document.getElementsByClassName('GPshowAdvancedToolOpen');
    const elt = elementsArray[0];
    if (elt) {
      observer.disconnect();
      arrayTooltips.map((elt) => addTooltips(elt));
      addTooltipsMultiPlotSelection();
    }
  });
  observer.observe(document, {
    attributes: true,
    subtree: true,
    childList: true,
  });

  return null;
}

export default memo(ControlTooltips);
