import { exportExcel } from '../../../utils/exportExcel';

export const handleExportExcelUsers = (
  membersList: ReportingUsersStats | null
): void => {
  if (membersList) {
    const worksheetColumns = [
      { header: "Membre de l'équipe", key: 'name' },
      { header: 'Connexions', key: 'authenticationCounter' },
      { header: 'Opportunités étudiées', key: 'plotStudiesCounter' },
      { header: 'Opportunités signées', key: 'plotStudiesValidCounter' },
      { header: 'Actions prospection', key: 'proceduresCounter' },
      { header: 'Taux de succès', key: 'plotStudiesValidRate' },
    ];
    const worksheetRow = membersList.map((elt: ReportingUserStats) => ({
      name: elt.user.fullName ?? '-',
      authenticationCounter: elt.authenticationCounter ?? 0,
      plotStudiesValidCounter: elt.plotStudiesValidCounter ?? 0,
      plotStudiesCounter: elt.plotStudiesCounter ?? 0,
      proceduresCounter: elt.proceduresCounter ?? 0,
      plotStudiesValidRate: `${Math.round(elt.plotStudiesValidRate)} %`,
    }));

    exportExcel('rapport', worksheetColumns, worksheetRow);
  }
};
