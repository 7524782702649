export const tabs = [
  { id: 0, code: 'Geographie', name: 'Geographie', usedFor: ['plotStudy'] },
  { id: 0, code: 'synthese', name: 'Synthèse Parcelles', usedFor: ['folder'] },
  {
    id: 1,
    code: 'Urbanismeplot',
    name: 'Urbanisme',
    usedFor: ['plotStudy'],
  },
  {
    id: 1,
    code: 'Urbanismefolder',
    name: 'Urbanisme',
    usedFor: ['folder'],
  },
  {
    id: 4,
    code: 'Servitudeplot',
    name: 'Servitudes',
    usedFor: ['plotStudy'],
  },
  { id: 2, code: 'infosplot', name: 'Info Parcelle', usedFor: ['plotStudy'] },
  { id: 2, code: 'infosfolder', name: 'Info Parcelles', usedFor: ['folder'] },
  {
    id: 3,
    code: 'Prospection',
    name: 'Prospection',
    usedFor: ['plotStudy', 'folder', 'sector'],
  },
];
