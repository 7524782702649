import { isEmpty } from 'lodash';
import getEntityFromIdIri from '../../lib/parsersTools/getEntityFromIdIri';
import getIdFromIdIri from '../../lib/parsersTools/getIdFromIdIri';
import { documentsBuilder } from '../../lib/plotDataFormating';
import { changeApiKeyForStoreKey } from '../../services/common/parserCommon';
import {
  ownersListParser,
  parcelEnvBuilder,
  taxesParser,
  townHallParser,
  transactionsParser,
} from '../../services/parserDatas/parcelleParser';
import getCookie from '../../shared/utils/getCookie';
import genericSort from '../../utils/genericSort';
import { firstNameAndInitialParser } from '../../utils/jsFunctions';

interface PlotStudyFolderResponse {
  '@id': string;
  '@type': string;
  id: number;
  createdAt: string;
  updatedAt: string;
  responsable: string;
  status: string;
  inseeCode: string;
  priority: number;
}

export const folderMapping = {
  '@id': 'idIri',
  responsable: 'responsable',
  status: 'status',
  comment: 'comment',
  feasibilityMatrix: 'feasibilityMatrix',
  name: 'name',
  markerColor: 'markerColor',
  parentFolder: 'parent',
};

function filterAndCountPlotStudies(
  studies: PlotStudyFolderResponse[],
  companyId: number
): number | undefined {
  const cookieName = `inputValueFilterSortFolder${companyId}`;
  const cookies = getCookie(cookieName);

  if (!cookies) {
    return studies.length;
  }

  const filterStudy = (study: PlotStudyFolderResponse): boolean => {
    const { user, city, status, priority } = cookies;

    const isUserMatch = !isEmpty(user)
      ? user?.some((elt: ISelectItem) => elt.value === study.responsable)
      : true;
    const isCityMatch = !isEmpty(city)
      ? city?.some((elt: AutocompleteCity) => elt.inseeCode === study.inseeCode)
      : true;
    const isStatusMatch = !isEmpty(status)
      ? status?.some((elt: ISelectItem) => elt.value === study.status)
      : true;
    const isPriorityMatch = priority !== 'none' ? study.priority === priority : true;

    return isUserMatch && isCityMatch && isStatusMatch && isPriorityMatch;
  };

  try {
    return studies.filter(filterStudy).length;
  } catch (error) {
    throw new Error("The folder plot studies can't be filtered with cookies");
  }
}

export const folderParser = (
  data: any,
  users: Users,
  companyId: number,
  isFilter?: Boolean
): IFolder => {
  try {
    const parsedData: IFolder = changeApiKeyForStoreKey(data, folderMapping);

    parsedData.id = getIdFromIdIri(data['@id']);
    parsedData.sortValues = { name: data.name, id: parsedData.id };

    // serialise responsable from user
    parsedData.responsable = getEntityFromIdIri(data.responsable, users) as User;

    // get only folder idIri
    parsedData.parent = data.parentFolder
      ? typeof data.parentFolder === 'string'
        ? data.parentFolder
        : data.parentFolder['@id']
      : null;

    parsedData.plotStudies = [];
    parsedData.plotStudiesCount =
      (isFilter
        ? filterAndCountPlotStudies(data.plotStudies, companyId)
        : data.plotStudies?.length) ?? 0;

    parsedData.subs = [];
    parsedData.subCount = 0;
    return parsedData;
  } catch (error) {
    throw new Error("the folder can't be parsed");
  }
};
export const foldersParser = (
  data: any,
  users: Users,
  companyId: number,
  isFilter?: boolean
): Folders => {
  try {
    const tempFolders: Folders = data.map((f: any) =>
      folderParser(f, users, companyId, isFilter)
    );

    const masterFolders = tempFolders
      .filter((f) => !f.parent)
      .map((f, i) => ({
        ...f,
        indexId: i + 1,
        subs: [],
        plotStudies: f.plotStudies.map((ps, psi) => ({
          ...ps,
          indexId: psi + 1,
        })),
      }));

    masterFolders.forEach((folder) => {
      const subs = tempFolders
        .filter((f) => f.parent === folder.idIri)
        .map((f, i) => ({
          ...f,
          indexId: i + 1,
          plotStudies: f.plotStudies.map((ps, psi) => ({
            ...ps,
            indexId: psi + 1,
          })),
        }));

      folder.subs = (subs as any) ?? [];
      folder.subCount = subs?.length ?? 0;
    });

    return masterFolders;
  } catch (error) {
    return [];
  }
};

export const folderSyntheseParser = (
  data: any,
  plotStudies: PlotStudies
): ISubFolderDataResults | null => {
  try {
    const areas = data.total_parcels_areas;

    const keys = Object.keys(data);
    const model = data[keys[0]];
    keys.pop();
    const table: ISyntheseTableRow[] = [];
    const folderPlotZones: { plotId: string; zone: IZone }[] = [];
    const ownersPlots: IOwnersPlots[] = [];
    const envRiskDatas: IEnvRiskDatas[] = [];
    const plotCount: number = keys.length;

    const notFoundPlot: string[] = [];
    // for each plot
    keys.forEach((k) => {
      const el = data[k];

      const plotStudy = plotStudies.find((ps) => ps.fullPlotId === k) as IPlotStudy;
      folderPlotZones.push({
        plotId: k.substring(5),
        zone: { zone: el.zone.zone, zoneChild: el.zone.zone_child },
      });

      ownersPlots.push({
        plotId: k.substring(5),
        address: plotStudy?.address || '',
        city: plotStudy?.city || '',
        postalCode: plotStudy?.postalCode || '',
        ownerList: ownersListParser(el.owners_list.datas),
        impotsMails: model.contact_impots,
      });

      envRiskDatas.push({
        plotId: k.substring(5),
        section: el.section,
        prefix: el.prefixe,
        number: el.numero,
        city: el.city_name,
        postalCode: el.zipcode,
        inseeCode: el.insee_code,
        latLng: [el.latitude, el.longitude],
        envs: parcelEnvBuilder(el.environmental_risks),
      });

      const kAreas = el.parcel_areas;
      const userData = plotStudy?.responsable;

      if (!kAreas.theoric_capacity) {
        notFoundPlot.push(k.substring(5));
      }

      const temp: ISyntheseTableRow = {
        city: el.city_name,
        address: el.mapAddress,
        plotId: k.substring(5),
        usedGroundArea: kAreas.area_built ?? 0,
        plotArea: kAreas.theoric_capacity ?? 0,
        zone: el.zone.zone_child
          ? `${el.zone.zone}-${el.zone.zone_child}`
          : el.zone.zone,
        responsable: userData
          ? firstNameAndInitialParser(userData?.firstName, userData?.lastName)
          : '-',
        state: plotStudy?.status,
      };

      table.push(temp);
    });

    const syntheseTab: ISyntheseTab = {
      notFoundPlot,
      totalFreeGroundArea: areas?.total_areas_free ?? 0,
      totalPlotArea: areas?.total_theoric_capacities ?? 0,
      totalUsedGroundArea: areas?.total_areas_built ?? 0,
      plotsData: table,
    };

    const documents = model.documents ? documentsBuilder(model.documents) : [];

    const urbanismeTab = {
      plot: {
        documents,
        zone: <IZone>model.zone,
      },
      folderPlotZones,
    };

    const folderInfosTab = {
      plot: {
        transactions: transactionsParser(model.transactions),
        ownerList: {
          list: model.owners_list?.datas
            ? ownersListParser(model.owners_list.datas)
            : [],
        },

        envRisks: model.environmental_risks
          ? parcelEnvBuilder(model.environmental_risks)
          : [],
        fiscalZone: {
          eligibility: model.fiscal_law?.eligibility ?? ' - ',
          zone: model.fiscal_law?.zone ?? ' - ',
        },
        fiscalLaw: model.fiscal_law,
        taxes: model.taxes ? taxesParser(model.taxes) : [],
        townHall: model.town_hall_contact
          ? townHallParser(model.town_hall_contact)
          : null,
      },
      ownersPlots,
      envRiskDatas,
    };
    const cadastralLayer = model.mapbox_cadastral_url;

    return {
      syntheseTab,
      urbanismeTab,
      folderInfosTab,
      cadastralLayer,
      plotCount,
    };
  } catch (error) {
    return null;
  }
};
