export const ORPI_PDF_COLOR_PRIMARY = '#514E9A';
export const ORPI_RED_COLOR = '#E30513';

export const MAX_FORM_TEXT_FIELD = 50;
export const MAX_FORM_TEXT_FIELD_MESSAGE = '50 caractères maximum';

export enum EnvIconTypeEnum {
  SEISMIC = 'SEISMIC',
  CLAY = 'CLAY',
  RADON = 'RADON',
  EROSION = 'EROSION',
}

export const GradualColors: {
  [key in EnvIconTypeEnum]: { index: number; label: string; color: string }[];
} = {
  [EnvIconTypeEnum.SEISMIC]: [
    { index: 1, label: '1 - trés faible', color: '#D9D9D9' },
    { index: 2, label: '2 - faible', color: '#FBEC65' },
    { index: 3, label: '3 - modéré', color: '#FEB700' },
    { index: 4, label: '4 - moyen', color: '#D11325' },
    { index: 5, label: '5 - fort', color: '#94040C' },
  ],
  [EnvIconTypeEnum.CLAY]: [
    { index: 1, label: '1 - exposition faible', color: '#FBEC65' },
    { index: 2, label: '2 - exposition moyenne', color: '#FEB700' },
    { index: 3, label: '3 - exposition forte', color: '#94040C' },
  ],
  [EnvIconTypeEnum.RADON]: [
    { index: 1, label: '1 - potentiel radon faible', color: '#FBEC65' },
    { index: 2, label: '2 - potentiel radon moyenne', color: '#FEB700' },
    { index: 3, label: '3 - potentiel radon significative', color: '#94040C' },
  ],
  [EnvIconTypeEnum.EROSION]: [],
};
