import styles from './priorityIcon.module.scss';
import { getColorClassName } from './utils';

function PriorityIcon({ level, hasContent, className }: IPriorityIconProps) {
  const lvl = typeof level === 'string' ? parseInt(level, 10) : level;

  const content = () => {
    let c = '';
    for (let i = 0; i < lvl; i++) {
      c += '!';
    }

    return c;
  };

  return (
    <div
      className={[
        styles.priorityIcon,
        getColorClassName(lvl),
        hasContent ? styles.bigger : '',
        className ?? '',
      ].join(' ')}
    >
      {hasContent && <div className={styles.content}>{content()}</div>}
    </div>
  );
}

export default PriorityIcon;
