import { delay, isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import LocalStorageService from '../../../services/LocalStorageService';
import { getAnnouncementState } from '../../announcement/announcementSlice';
import { fetchAnnouncementThunk } from '../../announcement/announcementThunks';
import { CONNEXION_PROCESS_DELAY } from './ConnectionProcessContent';
import { useConnectionProcessContext } from './ConnextionProcessContext';
import {
  AnnouncementProcessStepEnum,
  ConnectionWorkFlowEnum,
} from './utils/connexionProcessEnums';

function AnnouncementProcess() {
  const { decodedToken, setWorkflowState, processDatas } =
    useConnectionProcessContext();
  const { announcement } = useAppSelector(getAnnouncementState);
  const [internalStep, setInternalStep] = useState<AnnouncementProcessStepEnum>(
    AnnouncementProcessStepEnum.IDLE
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (announcement) {
      setInternalStep(AnnouncementProcessStepEnum.DATAS_FULFILLED);
    }
  }, [announcement]);

  useEffect(() => {
    switch (internalStep) {
      case AnnouncementProcessStepEnum.IDLE: {
        setInternalStep(AnnouncementProcessStepEnum.PROCESS_INIT);
        break;
      }
      case AnnouncementProcessStepEnum.PROCESS_INIT: {
        delay(() => {
          dispatch(
            fetchAnnouncementThunk({
              lastAuthAt: isEmpty(LocalStorageService.getLastLoginDate())
                ? (decodedToken?.lastAuthAt as string)
                : LocalStorageService.getLastLoginDate(),
            })
          );
        }, CONNEXION_PROCESS_DELAY);
        setInternalStep(AnnouncementProcessStepEnum.DATAS_PENDING);
        break;
      }
      case AnnouncementProcessStepEnum.DATAS_PENDING:
        break;
      case AnnouncementProcessStepEnum.DATAS_FULFILLED:
        setInternalStep(AnnouncementProcessStepEnum.END_PROCESS);
        break;
      case AnnouncementProcessStepEnum.END_PROCESS:
        if (processDatas?.urlType === 'orpi-land') {
          // orpi-land-terrain process
          setWorkflowState(ConnectionWorkFlowEnum.START_FETCH_PDF_DATA_PROCESS);
        } else if (processDatas?.canLoadPlot) {
          // load plot process
          setWorkflowState(ConnectionWorkFlowEnum.START_FETCH_PLOT_PROCESS);
        } else {
          // classic end
          setWorkflowState(ConnectionWorkFlowEnum.CLOSE_CONNECTION_MODAL);
        }
        break;
      default:
        break;
    }
  }, [internalStep]);

  return (
    <div>
      {internalStep === AnnouncementProcessStepEnum.DATAS_PENDING && (
        <p>Chargement des annonces ...</p>
      )}

      {internalStep === AnnouncementProcessStepEnum.DATAS_FULFILLED && (
        <p>Annonces chargée</p>
      )}
    </div>
  );
}

export default memo(AnnouncementProcess);
