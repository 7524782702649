import IconContainer from './IconContainer';
import styles from './icons.module.scss';
interface IExpandIconProps extends ISharedIconsProps {
  isOpen?: boolean;
}
function ExpandIcon(props: IExpandIconProps) {
  const { tooltipText } = props;

  return (
    <IconContainer
      {...props}
      bgColor="TRANSPARENT"
      color="PRIMARY"
      tooltipText={tooltipText}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className={props.isOpen ? styles.isOpen : ''}
      >
        <path d="M16.59 8.59 12 13.17 7.41 8.59 6 10l6 6 6-6z" />
      </svg>
    </IconContainer>
  );
}

export default ExpandIcon;
