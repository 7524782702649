import { createAsyncThunk } from '@reduxjs/toolkit';
import { postEntityNew } from '../../../services/axiosFiles/genericCrud';
import { DPE_ITEM_PER_PAGE_REQUEST } from '../../../shared/constants';
import { dpePopupContentParser, dpesParser } from './dpeParser';
import { fetchDpePointsRequest } from './dpeRequests';
import { dpeRequestParamBuilder } from './utils';

interface IFetchDpePointsThunkProps {
  insee: string | null;
  dpeAdvancedSearch: IDpeAdvancedSearchForm | null;
}
export const fetchDpePointsThunk = createAsyncThunk(
  'dpe/fetchDpePointsThunk',
  async ({ insee, dpeAdvancedSearch }: IFetchDpePointsThunkProps, { dispatch }) => {
    try {
      const body = dpeRequestParamBuilder({ insee, dpeAdvancedSearch });

      // create points requests and add then in a promise
      let page = 1;
      let doRequest = true;
      const dpePoints: DpePoints = [];

      // do request each time result is equal to maxDpePerPage
      while (doRequest) {
        const result = await fetchDpePointsRequest({
          ...body,
          page,
          dpe_per_page: DPE_ITEM_PER_PAGE_REQUEST,
        });

        dpePoints.push(...result);
        page++;

        // stop while if result is not "full"
        if (result.length < DPE_ITEM_PER_PAGE_REQUEST) {
          doRequest = false;
        }
      }

      return dpePoints;
    } catch (error: any) {
      return Promise.reject(error);
    }
  }
);

export const fetchDpePopupContentThunk = createAsyncThunk(
  'dpe/fetchDpePopup',
  async (params: { address: string }) => {
    const { address } = params;
    const response = postEntityNew({
      endpoint: '/get_dpe_datas_from_geo_adresse/',
      body: {
        geo_adresse: address,
      },
      hasPythonProxy: true,
    });

    return response
      .then((response) => {
        const parsedResponse = dpesParser(response as any);

        const popup = dpePopupContentParser(parsedResponse);

        return popup;
      })
      .catch((err) => {
        return undefined as never;
      });
  }
);
