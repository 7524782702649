import { nanoid } from '@reduxjs/toolkit';
import FolderRow from './FolderRow';
import styles from './multiselectPlotModal.module.scss';

interface IFolderContainerProps {
  folders: Folders | null;
  isSub?: boolean;
}

function FolderContainer({ folders, isSub }: IFolderContainerProps) {
  return (
    <div className={`${styles.folderContainer} ${isSub ? styles.isSub : ''}`}>
      {folders?.map((folder) => (
        <FolderRow key={nanoid()} folder={folder} />
      ))}
    </div>
  );
}

export default FolderContainer;
