import * as Yup from 'yup';
import { validationMessages } from '../../../../../../constants/validations';

export const initalProcedureValues: ProcedureFormValues = {
  responsable: '',
  type: '',
  studyContactIdIri: '',
  eventDate: new Date().toISOString(),
  comment: '',
  studyIdIri: '',
};

export const procedureValidationSchema = Yup.object({
  responsable: Yup.string().required(validationMessages.interlocutor.requis),
  type: Yup.string().required(validationMessages.procedureType.requis),
  studyContactIdIri: Yup.string(),
  eventDate: Yup.string().required(),
  comment: Yup.string(),
  studyIdIri: Yup.string().required(),
});
