import { StyleSheet, View } from '@react-pdf/renderer';
import ComparativeStudiesTableCell from './ComparativeStudiesTableCell';

function ComparativeStudiesTableRowHeader() {
  return (
    <View style={styles.tableRow}>
      <View style={styles.tableCol} />
      <ComparativeStudiesTableCell header item={'S. terrain'} />
      <ComparativeStudiesTableCell header item={'Ville'} flex />
      <ComparativeStudiesTableCell header item={'Prix'} />
      <ComparativeStudiesTableCell header item={'Origine'} />
      <ComparativeStudiesTableCell header item={'Date publication'} />
    </View>
  );
}

export default ComparativeStudiesTableRowHeader;

const styles = StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  tableCol: {
    width: '50px',
  },
});
