import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import LocalServitudes from '../../errialPDF/pdfFiles/components/LocalServitudes';
import EnvConstraintsLand from './components/EnvConstraintsLand';
import Footer from './components/Footer';
import GeneralInformations from './components/GeneralInformations';
import Header from './components/Header';
import LocationInformationPdf from './components/LocationInformationPdf';
import PdfSeparateLine from './components/PdfSeparateLine';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  formDatas: IExternalPdfLandForm;
  pythonDatas: DatasForPdfGeneration;
  servitudes: OrpiServitudes;
}

function YourGoodEnvironmentPage({
  agency,
  pythonDatas,
  servitudes,
  formDatas,
}: IProps) {
  return (
    <Page style={pageStyles.page}>
      <Header lines={['Votre bien', 'Parlons peu, parlons bien']} fixed />

      <View>
        <View style={styles.flexRow}>
          <View style={styles.rowBlocSize}>
            <GeneralInformations formDatas={formDatas} />
          </View>

          <View style={[styles.flexColumn, styles.rowBlocSize]}>
            <LocationInformationPdf formDatas={formDatas} />
          </View>
        </View>
      </View>
      <PdfSeparateLine marginBottom="0px" marginTop="20px" />
      <View style={styles.titleContainer}>
        <Text style={[fontStyles.b700, fontStyles.fs20, fontStyles.cOrpi]}>
          L&#039;urbanisme de votre terrain
        </Text>
      </View>

      <EnvConstraintsLand {...pythonDatas.parcelsEnvironmentDatas.envConstraints} />

      <View wrap={false}>
        <View style={styles.titleContainer}>
          <Text style={[fontStyles.b700, fontStyles.fs20, fontStyles.cOrpi]}>
            Servitudes locales présentes sur le Géoportail de l’urbanisme
          </Text>
        </View>

        <LocalServitudes {...servitudes} />
      </View>

      <Footer agency={agency} formDatas={formDatas} />
    </Page>
  );
}

export default YourGoodEnvironmentPage;

const styles = StyleSheet.create({
  flexRow: { display: 'flex', flexDirection: 'row' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  rowBlocSize: { width: '50%' },
  globalInfoContainer: {
    marginVertical: '10px',
  },
  globalInfoText: {
    fontSize: '14px',
    paddingBottom: '3px',
  },
  titleContainer: {
    marginVertical: '10px',
  },
  title: {
    fontSize: '18px',
    fontWeight: 700,
  },
  address: { paddingTop: '20px' },
});
