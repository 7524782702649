import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';

const FloorNumberSvg = ({ color }: { color?: string }) => (
  <Svg
    height="14"
    width="14"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M6.06818 4.18746H5.75678C5.63501 4.18746 5.5415 4.07955 5.55881 3.95902L5.85527 1.8951C5.87258 1.77457 5.77907 1.66666 5.6573 1.66666H4.3559C4.25643 1.66666 4.17207 1.73977 4.15793 1.83823L3.84514 4.01589C3.83099 4.11435 3.74664 4.18746 3.64717 4.18746H2.49954C2.38908 4.18746 2.29954 4.277 2.29954 4.38746V5.61571C2.29954 5.72617 2.38908 5.81571 2.49954 5.81571H3.34704C3.46874 5.81571 3.56223 5.9235 3.54503 6.04398L3.45791 6.65413C3.44384 6.75266 3.35945 6.82586 3.25992 6.82586H2.2C2.08954 6.82586 2 6.9154 2 7.02586V8.25411C2 8.36457 2.08954 8.45411 2.2 8.45411H2.96784C3.08988 8.45411 3.18347 8.56248 3.16571 8.68322L2.85858 10.7709C2.84081 10.8916 2.9344 11 3.05645 11H4.35811C4.45733 11 4.54154 10.9273 4.55598 10.8291L4.88014 8.62501C4.89457 8.52685 4.97879 8.45411 5.07801 8.45411H5.39051C5.51262 8.45411 5.60623 8.5626 5.58835 8.6834L5.27934 10.7707C5.26146 10.8915 5.35507 11 5.47719 11H6.77987C6.87906 11 6.96327 10.9273 6.97773 10.8292L7.30262 8.62495C7.31709 8.52682 7.40129 8.45411 7.50048 8.45411H8.47413C8.58458 8.45411 8.67413 8.36457 8.67413 8.25411V7.02586C8.67413 6.9154 8.58458 6.82586 8.47413 6.82586H7.7902C7.66843 6.82586 7.57492 6.71796 7.59224 6.59742L7.67988 5.98728C7.69402 5.88881 7.77838 5.81571 7.87785 5.81571H8.8C8.91046 5.81571 9 5.72617 9 5.61571V4.38746C9 4.277 8.91046 4.18746 8.8 4.18746H8.17779C8.05602 4.18746 7.96251 4.07955 7.97982 3.95902L8.27628 1.8951C8.2936 1.77457 8.20009 1.66666 8.07831 1.66666H6.77691C6.67744 1.66666 6.59308 1.73977 6.57894 1.83823L6.26615 4.01589C6.252 4.11435 6.16765 4.18746 6.06818 4.18746ZM5.15147 6.68358L5.28008 5.91691C5.29624 5.82057 5.37963 5.75 5.47732 5.75H6.04269C6.16637 5.75 6.2604 5.86112 6.23994 5.98308L6.11133 6.74975C6.09517 6.84609 6.01178 6.91666 5.91409 6.91666H5.34872C5.22504 6.91666 5.13101 6.80555 5.15147 6.68358Z"
      fill={color ? color : 'currentColor'}
    />
    <Path
      d="M17.0062 3.83119C17.0062 3.35962 17.3519 3 17.7535 3H21.2519C21.6504 3 22 3.35448 22 3.82946C22 4.30104 21.6543 4.66065 21.2527 4.66065H18.5L18.5008 7.83119C18.5008 8.30106 18.1567 8.66238 17.7551 8.66238L14.9946 8.66152L14.9938 11.8295C14.9938 12.301 14.6481 12.6607 14.2465 12.6607L11 12.6615V15.8303C11 16.3019 10.6528 16.6598 10.2527 16.6598H7V19.8312C7 20.3028 6.65435 20.6624 6.2527 20.6624H2.7473C2.34565 20.6624 2 20.3028 2 19.8312C2 19.3596 2.34565 19 2.7473 19H5.50541V15.8312C5.50541 15.3596 5.85106 15 6.2527 15H9.50541V11.8312C9.50541 11.3596 9.85106 11 10.2527 11L13.5 10.9991L13.5008 7.83119C13.5008 7.36132 13.8449 7 14.2465 7L17.007 7.00086L17.0062 3.83119Z"
      fill={color ? color : 'currentColor'}
    />
  </Svg>
);

export default FloorNumberSvg;
