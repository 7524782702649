import { MouseEvent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppSelector } from '../../../../App/store';
import { getPlotState } from '../../../../redux/plot/reducer';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { getExternalPdfErrialState } from '../../externalPdfSlice';
import styles from '../../shared/styles/rightPanelModalExternalPdfErrial.module.scss';
import OrpiLandButton from '../OrpiLandButton';

interface IProps {
  pageDisplay: DisplayFormType;
  setPageDisplay: React.Dispatch<React.SetStateAction<DisplayFormType>>;
}
function ButtonContainer({ pageDisplay, setPageDisplay }: IProps) {
  const [canNavigate, setCanNavigate] = useState<boolean>(false);
  const { multiPlotsPdf } = useAppSelector(getExternalPdfErrialState);
  const { parcelle, isActivePlotLoading } = useAppSelector(getPlotState);

  const {
    watch,
    trigger,
    formState: { isValid },
  } = useFormContext();

  const handleCanNavigate = async () => {
    const landAreaOk = await trigger('landArea');
    const descriptionOk = await trigger('description');

    if (
      (parcelle || multiPlotsPdf.result) &&
      landAreaOk &&
      descriptionOk &&
      !isActivePlotLoading &&
      multiPlotsPdf.apiStatus !== APIStatus.PENDING
    ) {
      setCanNavigate(true);
    } else {
      setCanNavigate(false);
    }
  };

  useEffect(() => {
    handleCanNavigate();
  }, [
    watch('landArea'),
    watch('description'),
    isActivePlotLoading,
    multiPlotsPdf.apiStatus,
  ]);

  // change form page display
  const handleChangeDisplay = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (canNavigate) {
      switch (pageDisplay) {
        case 'plot':
          setPageDisplay('prospec');
          break;
        case 'prospec':
          setPageDisplay('plot');
          break;
        default:
          break;
      }
    }
  };
  return (
    <div className={styles.buttonContainer}>
      <button
        className={[
          styles.button,
          canNavigate ? '' : styles.disabled,
          pageDisplay === 'plot' ? styles.red : '',
        ].join(' ')}
        onClick={handleChangeDisplay}
        disabled={!canNavigate && pageDisplay === 'plot' && !isValid}
      >
        {pageDisplay === 'plot' ? 'Etape suivante' : 'Etape précèdente'}
      </button>

      {pageDisplay === 'prospec' && <OrpiLandButton disabled={!isValid} />}
    </div>
  );
}

export default ButtonContainer;
