import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../../App/store';
import { contactsActions } from '../contactsSlice';
import ContactRow from './ContactRow';
import { contactHeaderRowModal } from './constants';

interface IContactTableProps {
  onCloseModal: () => void;
  contacts: Contacts;
}
function ContactTable({ onCloseModal, contacts }: IContactTableProps) {
  const dispatch = useAppDispatch();
  const handleRowClick = (contact: Contact) => {
    dispatch(contactsActions.contactForActionSet(contact));
    onCloseModal();
  };

  return (
    <table>
      <thead>
        <tr>
          {contactHeaderRowModal?.map((elt) => {
            return <th key={nanoid()}>{elt.title}</th>;
          })}
        </tr>
      </thead>

      <tbody>
        {contacts?.map((contact: any) => (
          <ContactRow key={nanoid()} contact={contact} onClick={handleRowClick} />
        ))}
      </tbody>
    </table>
  );
}

export default ContactTable;
