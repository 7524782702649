import { Chart } from 'chart.js';
import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import ImageNotAvailable from '../../../../../assets/images/orpiPdf/imageNotAvailable.png';

function LineChart({
  data,
  labels,
  handleGraph,
  valueIsPercentage = false,
}: {
  data: number[];
  labels: string[];
  handleGraph: (...args: any[]) => any;
  valueIsPercentage?: boolean;
}) {
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = (chartRef as any)?.current.getContext('2d');

    const chartInstance = new Chart(ctx, {
      type: 'line',
      data: {
        labels: labels,
        datasets: [
          {
            data: data,
            borderColor: 'rgb(6, 106, 190)',
            borderWidth: 2,
            fill: false,
          },
        ],
      },

      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: false,
            grid: {
              display: false,
            },
            ticks: {
              // index = tab value index
              callback: function (value, index) {
                // need to optimize code
                // if (
                //   data.length > index + 1 &&
                //   index > 0 &&
                //   data[index - 1] &&
                //   data[index] === data[index - 1]
                // )
                //   return undefined;
                return valueIsPercentage
                  ? (value as number).toFixed(2) + '%'
                  : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
              },
            },
          },
          x: {
            grid: {
              display: false,
            },
            offset: true,
          },
        },
        elements: {
          point: {
            radius: 4,
            backgroundColor: 'rgb(6, 106, 190)',
          },
        },
        animation: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    // Convert the chart to a base64 image
    dispatch(
      handleGraph(isEmpty(data) ? ImageNotAvailable : chartInstance.toBase64Image())
    );
  }, []);

  return (
    <div style={{ position: 'absolute', top: -1000 }}>
      <canvas id="myChart" width={514} height={260} ref={chartRef} />
    </div>
  );
}
export default LineChart;
