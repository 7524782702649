import React from 'react';
import { Helmet } from 'react-helmet';
import {
  DEFAULT_META_DESCRIPTION,
  DEFAULT_META_KEYWORDS,
} from '../shared/constants';
import createInputTabsCtx from './contexts/InputTabsContext';

interface ILayoutProps {
  children: React.ReactNode;
}

const [ctx, InputTabsProvider] = createInputTabsCtx();
export const InputTabsContext = ctx;

const Layout = ({ children }: ILayoutProps) => {
  return (
    <div>
      <Helmet>
        <title>Urbanease, la recherche foncière</title>
        <meta name="description" content={DEFAULT_META_DESCRIPTION} />
        <meta name="keywords" content={DEFAULT_META_KEYWORDS} />
      </Helmet>

      <InputTabsProvider>
        <main>{children}</main>
      </InputTabsProvider>
    </div>
  );
};

export default Layout;
