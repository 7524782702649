import { RootState } from '../../App/store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAnnouncementThunk } from './announcementThunks';
import { APIStatus } from '../../services/axiosFiles/apiTypes';

const initialState: AnnouncementState = {
  announcement: { apiStatus: APIStatus.IDLE, result: [] },
};

const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    reset: (state) => {
      state.announcement = { apiStatus: APIStatus.IDLE, result: [] };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAnnouncementThunk.pending, (state) => {
        state.announcement.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchAnnouncementThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.announcement.apiStatus = APIStatus.FULFILLED;
          state.announcement.result = action.payload ?? [];
        }
      )
      .addCase(
        fetchAnnouncementThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.announcement.apiStatus = APIStatus.REJECTED;
          state.announcement.error = action.payload;
        }
      );
  },
});

export default announcementSlice.reducer;
export const announcementActions = announcementSlice.actions;
export const getAnnouncementState = (state: RootState) => state.announcement;
