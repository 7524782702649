import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { getAuthState } from '../../../../features/auth/authSlice';
import { modalsActions } from '../../../../features/modals/modalsSlice';
import { userActions } from '../../../../features/users/usersSlice';
import { userUpdateThunk } from '../../../../features/users/usersThunks';
import FieldInputPwdFormCustom from '../../../Common/FormComponents/FieldInputPwdFormCustom';
import {
  IPasswordTabForm,
  initialPasswordForm,
  passwordValidationSchema,
} from '../../passwordValidation';
import Footer from './Footer';

interface IUserCardProps {
  user: User;
  canUpdate?: boolean;
  authIdIri?: string;
}

function UserCard({ user }: IUserCardProps) {
  const authState = useAppSelector(getAuthState);

  const dispatch = useAppDispatch();
  const useFormOptions = useForm<IPasswordTabForm>({
    defaultValues: initialPasswordForm,
    resolver: yupResolver(passwordValidationSchema),
  });
  const {
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = useFormOptions;
  const handleFormSubmit = () => {
    if (isEmpty(errors)) {
      dispatch(
        userUpdateThunk({
          userIdIri: user.idIri,
          user: { plainPassword: getValues('password') },
        })
      );
      if (user.id !== authState.user?.id) {
        // display message without logout
        dispatch(userActions.setEditingUser(true));
      } else {
        dispatch(modalsActions.passwordChangeResult(true));
      }

      reset();
    }
  };

  return (
    <div key={user.idIri} className="card">
      <div className="card-header">
        <h3>{`${user.firstName} ${user.lastName}`}</h3>
        <p className="header-email">{user.email}</p>
        <p>
          {user.isAdmin
            ? 'Administrateur'
            : user?.isManager
              ? 'Manager'
              : 'Prospecteur'}
        </p>
      </div>
      <p>
        <span>{user.plotStudyCount}</span> parcelles étudiées
        <br />
        <span>{user.procedureCount}</span> actions de prospection
      </p>
      <FormProvider {...useFormOptions}>
        <form className="card-body" onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="field">
            <FieldInputPwdFormCustom
              name="password"
              label="Nouveau mot de passe"
              disabled={!authState.isManager}
            />
          </div>
          <div className="field">
            <FieldInputPwdFormCustom
              name="verifyPassword"
              label="Confirmation du mot de passe"
              disabled={!authState.isManager}
            />
          </div>
          <button
            type="submit"
            className="submit-btn"
            disabled={!authState.isManager}
          >
            VALIDER
          </button>
        </form>
      </FormProvider>
      {authState.isManager && <Footer user={user} />}
    </div>
  );
}

export default UserCard;
