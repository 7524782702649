import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import {
  pluOthersApiToStoreParser,
  zonesAndZoneChildsApiToStore,
} from './pluParser';
import fetchPluUrl from './services/fetchPluUrl';

export const fetchZonesAndZoneChildsThunk = createAsyncThunk(
  'plu/fetchZonesThunk',
  async (params: {
    city: string | null;
    postalCode: string | null;
    inseeCode: string | null;
  }) => {
    const { city, postalCode, inseeCode } = params;
    try {
      if (city && postalCode && inseeCode) {
        const promise = fetchDatas(
          '/result_get_plu_zone_and_zone_child_list_from_city',
          {
            city_name: city,
            city_zipcode: postalCode,
            insee: inseeCode,
          }
        );

        return promise.then(
          (response) => {
            const parsedDatas = zonesAndZoneChildsApiToStore(response);

            return parsedDatas;
          },
          (err) => {
            return Promise.reject(err);
          }
        );
      } else {
        return Promise.reject({
          status: 0,
          message: 'city or postalCode param missing',
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const fetchPluOthersThunk = createAsyncThunk(
  'plu/fetchPluOthersThunk',
  async (params: {
    city: string | null;
    postalCode: string | null;
    inseeCode: string | null;
  }) => {
    const { city, postalCode, inseeCode } = params;
    try {
      if (city && postalCode && inseeCode) {
        const promise = fetchDatas('/get_plu_others_list_from_city', {
          city_name: city,
          city_zipcode: postalCode,
          insee: inseeCode,
        });

        return promise.then(
          (response) => {
            const parsedDatas = pluOthersApiToStoreParser(response.plu_others_list);

            return { pluOthers: parsedDatas, pmtilesUrl: response.pmtiles_file_url };
          },
          (err) => {
            return Promise.reject(err);
          }
        );
      } else {
        return Promise.reject({
          status: 0,
          message: 'city or postalCode param missing',
        });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const fetchPluUrlsThunk = createAsyncThunk(
  'plu/fetchPluUrlsThunk',
  async (params: { department?: string; lat?: number; lng?: number }) => {
    try {
      // params are checked on fetch request
      const result = await fetchPluUrl({ ...params });

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
