import { Switch } from '@mui/material';
import { appActions, getAppState, GoogleSearchContryEnum } from '../../App/appSlice';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { makeStyles } from 'tss-react/mui';

import styles from './switchDomTom.module.scss';

const useStyles = makeStyles()((theme) => ({
  root: {
    padding: 7,
  },
  thumb: {
    width: 24,
    height: 24,
    backgroundColor: '#fff',
    boxShadow:
      '0 0 12px 0 rgba(0,0,0,0.08), 0 0 8px 0 rgba(0,0,0,0.12), 0 0 4px 0 rgba(0,0,0,0.38)',
  },
  switchBase: {
    color: 'rgba(0,0,0,0.38)',
    padding: 7,
  },
  track: {
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
    opacity: '0.5',
  },
  checked: {
    '& $thumb': {
      backgroundColor: '#fff',
    },
    '& + $track': {
      opacity: '0.5 !important',
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
  },
  // focusVisible: {},
}));

function SwitchDomTom() {
  const { classes } = useStyles();
  const { googleSearchType } = useAppSelector(getAppState);

  const dispatch = useAppDispatch();

  const handleSwitchChange = () => {
    dispatch(
      appActions.setGoogleCountryType(
        googleSearchType === GoogleSearchContryEnum.FRANCE
          ? GoogleSearchContryEnum.DOM_TOM
          : GoogleSearchContryEnum.FRANCE
      )
    );
  };

  return (
    <div className={styles.switchDomTom}>
      <h3>Choix de recherche</h3>
      <div className={styles.switchDomTomContent}>
        <span
          className={`${styles.label} ${
            googleSearchType === GoogleSearchContryEnum.FRANCE ? styles.selected : ''
          }`}
        >
          France métropolitaine + Corse
        </span>
        <Switch
          classes={classes}
          checked={googleSearchType === GoogleSearchContryEnum.DOM_TOM}
          onChange={handleSwitchChange}
        />
        <span
          className={`${styles.label} ${
            googleSearchType === GoogleSearchContryEnum.DOM_TOM
              ? styles.selected
              : ''
          }`}
        >
          Départements d&#039;outre-mer
        </span>
      </div>
    </div>
  );
}

export default SwitchDomTom;
