import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { getAnnouncementState } from '../features/announcement/announcementSlice';
import { getAuthState } from '../features/auth/authSlice';
import { modalsActions } from '../features/modals/modalsSlice';
import { appActions, getAppState } from './appSlice';
import {
  fetchContactRolesThunk,
  fetchPlotStudyStatusesThunk,
  fetchProcedureTypesThunk,
} from './appThunks';
import { useAppDispatch, useAppSelector } from './store';

export default function useApp() {
  const {
    plotStudyStatuses,
    procedureTypes,
    contactRoles,
    accountTab,
    dashboardTab,
  } = useAppSelector(getAppState);
  const { isLogged } = useAppSelector(getAuthState);
  const { announcement } = useAppSelector(getAnnouncementState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLogged) {
      dispatch(appActions.init());

      if (!plotStudyStatuses.result) {
        dispatch(fetchPlotStudyStatusesThunk());
      }
      if (!procedureTypes.result) {
        dispatch(fetchProcedureTypesThunk());
      }
      if (!contactRoles.result) {
        dispatch(fetchContactRolesThunk());
      }

      dispatch(modalsActions.closeAllAuthModal());
    }
  }, [isLogged]);

  useEffect(() => {
    if (isLogged) {
      if (!isEmpty(announcement.result)) {
        dispatch(modalsActions.announcement(true));
      }
    }
  }, [isLogged, announcement]);

  return {
    plotStudyStatuses,
    procedureTypes,
    contactRoles,
    accountTab,
    dashboardTab,
  };
}
