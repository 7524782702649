import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { ReactElement } from 'react';
import { imageDataParse } from '../../../../../utils/jsFunctions';
import { TransportModeEnum } from '../../../shared/types/enums';
import ImageContainer from './ImageContainer';
import TransportCard from './TransportCard';
import EducationSvg from './svg/EducationSvg';
import HealthSvg from './svg/HealthSvg';
import LeisureSvg from './svg/LeisureSvg';
import RestaurantSvg from './svg/RestaurantSvg';
import ShoppingSvg from './svg/ShoppingSvg';

interface IAccessibilityCardProps {
  title: string;
  titleSvg: ReactElement<any, any>;
  data: PDFLandAccessibility | null;
  transports: (PDFLandTransport | null)[];
}

function AccessibilityCard({
  title,
  titleSvg,
  data,
  transports,
}: IAccessibilityCardProps) {
  return (
    <View style={[styles.card]}>
      <View>
        <View style={styles.row}>
          {titleSvg}
          <Text style={styles.title}>{title}</Text>
        </View>

        <ImageContainer
          localImg={
            data?.image
              ? `data:image/jpeg;base64,${imageDataParse(data?.image ?? '')}`
              : ''
          }
          width="100%"
          height="172px"
          objectFit="cover"
          borderRadius="15px"
        />

        {title === 'Voiture' && (
          <TransportCard
            data={
              transports?.find(
                (f) => f?.subcategory === TransportModeEnum.highway_ramp
              ) ?? null
            }
          />
        )}

        {title === 'Transport public' && (
          <View style={styles.transportCardContainer}>
            {transports
              .filter((f) => f?.subcategory !== TransportModeEnum.highway_ramp)
              .map((m) => {
                return <TransportCard key={nanoid()} data={m as PDFLandTransport} />;
              })}
          </View>
        )}
      </View>

      <View style={styles.serviceContainer}>
        <Text style={styles.servicesTitle}>Services à moins de 15 minutes</Text>
        <View style={styles.services}>
          <View>
            <View style={styles.row}>
              <View style={{ ...styles.serviceSvg, backgroundColor: 'pink' }}>
                <ShoppingSvg color="#fff" />
              </View>
              <Text>{data?.services.shopping.totalItems} Commerces</Text>
            </View>
            <View style={styles.row}>
              <View style={{ ...styles.serviceSvg, backgroundColor: 'orange' }}>
                <RestaurantSvg color="#fff" />
              </View>
              <Text>{data?.services.restaurant.totalItems} Restauration</Text>
            </View>
            <View style={styles.row}>
              <View style={{ ...styles.serviceSvg, backgroundColor: 'green' }}>
                <LeisureSvg color="#fff" />
              </View>
              <Text>{data?.services.leisure.totalItems} Loisirs</Text>
            </View>
          </View>
          <View>
            <View style={styles.row}>
              <View style={{ ...styles.serviceSvg, backgroundColor: 'purple' }}>
                <EducationSvg color="#fff" />
              </View>
              <Text>{data?.services.education.totalItems} Éducation</Text>
            </View>
            <View style={styles.row}>
              <View style={{ ...styles.serviceSvg, backgroundColor: 'red' }}>
                <HealthSvg color="#fff" />
              </View>
              <Text>{data?.services.health.totalItems} Santé</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
}

export default AccessibilityCard;

const styles = StyleSheet.create({
  card: {
    width: '273px',
    minHeight: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
  transportCardContainer: {},
  title: { fontSize: '14px', fontWeight: 700 },
  image: { height: '172px' },
  serviceContainer: { marginTop: '10px' },
  services: { display: 'flex', flexDirection: 'row' },
  servicesTitle: { fontSize: '12px', marginBottom: '5px', fontWeight: 700 },
  serviceSvg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '25px',
    padding: '2px',
    width: '14px',
    height: '14px',
    marginRight: '5px',
  },
  closestSvg: {
    width: '14px',
    height: '14px',
    marginRight: '5px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2px',
    marginRight: '5px',
    fontSize: '10px',
  },
});
