import L from 'leaflet';
import { memo, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Marker, useMap } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import rouge from '../../../assets/images/pin-urbanease.svg';
import { getMapState } from '../../../features/map/mapSlice';
import { setStudyParamsThunk } from '../../../features/study/services/thunks/studyParamsThunks';
import { favoritePlacesActions } from '../../../redux/favoritePlaces/actions';

import { useLocation } from 'react-router';
import { getPanelState, panelsActions } from '../../../features/panels/panelsSlice';
import { customIcon } from '../../../shared/components/map/customIcon';
import { PDF_URLS } from '../../../shared/constants';
import MarkerDivIcon from './MarkerDivIcon';
import styles from './activePlot.module.scss';

const ActivePlotMarker = memo((): JSX.Element => {
  const [firstDisplay, setFirstDisplay] = useState(true);
  const { plotDatas } = useAppSelector(getMapState);
  const { rightPanelDisplay } = useAppSelector(getPanelState);
  const map = useMap();
  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);

  useEffect(() => {
    if (!rightPanelDisplay) {
      setFirstDisplay(true);
    }
    map.getContainer().classList.remove('loading-plot');
  }, [plotDatas?.markerLatLng]);

  const dispatch = useAppDispatch();

  const handleRightPanelParcelleOpen = () => {
    if (firstDisplay) {
      setFirstDisplay(false);
    }

    dispatch(
      setStudyParamsThunk({
        type: 'plotStudy',
        noCheckExisting: false,
        idIri: null,
        managmentLoader: false,
      })
    );
    dispatch(favoritePlacesActions.windowDisplay.set(false));
    dispatch(panelsActions.studyContentShow('geographie'));
  };

  useEffect(() => {
    if (rightPanelDisplay) {
      setFirstDisplay(false);
    }
  }, [rightPanelDisplay]);

  return (
    <div>
      {plotDatas?.markerLatLng && (
        <Marker
          zIndexOffset={1000}
          eventHandlers={{
            click: () => {
              if (!pdfPath) handleRightPanelParcelleOpen();
            },
            add: () => {
              // openPopup();
            },
          }}
          position={plotDatas.markerLatLng}
          icon={
            firstDisplay && !pdfPath
              ? L.divIcon({
                  className: styles.icon,
                  html: ReactDOMServer.renderToString(<MarkerDivIcon />),
                })
              : customIcon(rouge)
          }
          data-cy="active-plot-marker"
        ></Marker>
      )}
    </div>
  );
});

ActivePlotMarker.displayName = 'ActivePlotMarker';
export default ActivePlotMarker;
