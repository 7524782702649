import { nanoid } from '@reduxjs/toolkit';
import { Feature } from 'geojson';
import L, { LatLngBoundsExpression, LatLngExpression } from 'leaflet';
import { memo } from 'react';
import { GeoJSON, Marker, Rectangle } from 'react-leaflet';
import { useAppSelector } from '../../App/store';
import styles from './plotServitudesContent/plotServitudesContent.module.scss';
import { getPlotServitudesState } from './plotServitudesSlice';

const jsonStyle = {
  fillColor: 'yellow',
  weight: 1,
  color: 'yellow',
  fillOpacity: 0.2,
};

// https://apicarto.ign.fr/api/doc/gpu#/Documents d'urbanisme (PLU%2C POS%2C CC%2C PSMV)/get_gpu_info_surf

function PlotServitudesLayers() {
  const {
    prescriptionPct,
    displayedLayers,
    prescriptionLin,
    infoSurf,
    infoPct,
    assietteSupS,
    scot,
  } = useAppSelector(getPlotServitudesState);

  const displayLayer = (features: Feature[]) => {
    return features
      .filter((d) => displayedLayers.includes(d.id as string))
      .map((m) => {
        if (m.geometry?.type) {
          if (m.geometry.type === 'Point') {
            return (
              <Marker
                position={
                  [
                    m.geometry.coordinates[1],
                    m.geometry.coordinates[0],
                  ] as LatLngExpression
                }
                icon={L.divIcon({
                  className: styles.pointMarker,
                })}
                key={nanoid()}
              />
            );
          } else {
            return <GeoJSON key={nanoid()} data={m} style={jsonStyle} />;
          }
        } else {
          if (m.properties?.bbox) {
            const b = m.properties?.bbox;
            const bounds: LatLngBoundsExpression = [
              [b[1], b[0]],
              [b[3], b[2]],
            ];

            return <Rectangle bounds={bounds} key={nanoid()} />;
          } else return null;
        }
      });
  };

  return (
    <>
      {prescriptionPct.result && displayLayer(prescriptionPct.result.features)}
      {prescriptionLin.result && displayLayer(prescriptionLin.result.features)}
      {infoSurf.result && displayLayer(infoSurf.result.features)}
      {infoPct.result && displayLayer(infoPct.result.features)}
      {assietteSupS.result && displayLayer(assietteSupS.result.features)}
      {scot.result && displayLayer(scot.result.features)}
    </>
  );
}

export default memo(PlotServitudesLayers);
