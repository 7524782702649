import { TextField } from '@mui/material';
import { FocusEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import styles from '../../styles/rightPanelModalExternalPdfErrial.module.scss';
import TickSquare from '../tickSquare/TickSquare';

interface ITextInputFormPdfErrialCustom {
  name: string;
  onChange?: ChangeEventCustom;
  label?: nullable<string>;
  placeholder?: string;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  InputProps?: any;
  hasTickSquare?: boolean;
  hasValueZeroNotNull?: boolean;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
}
function NumberInputFormPdfErrialCustom({
  name,
  label,
  disabled,
  error,
  errorMsg,
  className,
  readOnly = false,
  onFocus,
  onBlur,
  required,
  placeholder,
  InputProps,
  hasValueZeroNotNull = false,
  hasTickSquare = true,
}: ITextInputFormPdfErrialCustom) {
  const { control, trigger, setValue } = useFormContext();

  const handleChange = (value?: number) => {
    setValue(name, value);
    trigger(name);
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    e.target.select();
    if (onFocus) {
      onFocus(e);
    }
  };

  const handleBlur = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (onBlur) {
      onBlur(e);
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { value } }) => (
        <div className={styles.inputForm}>
          <NumericFormat
            variant="outlined"
            disabled={disabled}
            label={label}
            error={error}
            value={value ?? undefined}
            helperText={errorMsg}
            placeholder={placeholder}
            className={className}
            onFocus={handleFocus}
            onBlur={handleBlur}
            InputProps={{ readOnly, ...InputProps }}
            onValueChange={(value, sourceInfo) => handleChange(value.floatValue)}
            required={required}
            thousandSeparator=" "
            customInput={TextField}
            autoComplete="off"
          />
          {hasTickSquare && (
            <TickSquare
              color={
                value || (hasValueZeroNotNull && value === 0) ? 'Green' : 'Grey'
              }
            />
          )}
        </div>
      )}
    />
  );
}

export default NumberInputFormPdfErrialCustom;
