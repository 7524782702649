import * as Yup from 'yup';

export const constructFormInitialState: ConstructFormType = {
  comment: '',
  askingPrice: undefined,
  buildableArea: undefined,
  expectedRevenue: undefined,
};

export const constructFormValidationSchema = Yup.object({
  comment: Yup.string().default(''),
  askingPrice: Yup.number().default(undefined),
  buildableArea: Yup.number().default(undefined),
  expectedRevenue: Yup.number().default(undefined),
});
