import { G, Path, Svg } from '@react-pdf/renderer';

interface IRedPolygonCommonPage1Props {
  style: any;
  width: number;
  height: number;
}
function RedPolygonCommonPage1({
  style,
  width,
  height,
}: IRedPolygonCommonPage1Props) {
  return (
    <Svg width={width} height={height} viewBox="0 0 345 195" style={style}>
      <G>
        <Path
          d="M341,146.5c-2.49,0.78-5.07,1.38-7.47,2.38c-23.91,10.03-47.59,20.64-71.72,30.1
      c-41.2,16.14-78.8,9.6-112.62-19.21c-34.84-29.68-69.91-59.08-104.93-88.54C24.71,54.78,10.67,34.95,5.72,9.43
      C5.4,7.8,5.24,6.14,5,4.5c111.88,0,223.77,0,336,0C341,51.83,341,99.17,341,146.5z"
          fill="#FE0000"
        />
      </G>
    </Svg>
  );
}

export default RedPolygonCommonPage1;
