const LocalStorageService = (function () {
  let _service;

  function _getService() {
    if (!_service) {
      _service = this;
      return _service;
    }
    return _service;
  }

  function _setToken(token) {
    if (token) {
      localStorage.setItem('urbanease_token', token);
    }
  }

  function _setRefreshToken(refresh_token) {
    if (refresh_token) {
      localStorage.setItem('urbanease_refresh_token', refresh_token);
    }
  }

  function _setId(id) {
    try {
      localStorage.setItem('urbanease_user_id', id);
    } catch (error) {
      localStorage.setItem('urbanease_user_id', null);
    }
  }

  function _setLastLoginDate(lastLoginDate) {
    try {
      localStorage.setItem('urbanease_last_login_date', lastLoginDate);
    } catch (error) {
      localStorage.setItem('urbanease_last_login_date', '');
    }
  }

  function _setCompanyIdIri(companyIdIri) {
    try {
      localStorage.setItem('urbanease_company_id_iri', companyIdIri);
    } catch (error) {
      localStorage.setItem('urbanease_company_id_iri', null);
    }
  }

  function _getToken() {
    try {
      const token = localStorage.getItem('urbanease_token');
      if (!token || token === 'null') {
        return null;
      }
      return token;
    } catch (err) {
      return null;
    }
  }

  function _getRefreshToken() {
    try {
      const refreshToken = localStorage.getItem('urbanease_refresh_token');
      if (!refreshToken || refreshToken === null) {
        return null;
      }
      return refreshToken;
    } catch (err) {
      return null;
    }
  }

  function _getId() {
    try {
      const id = localStorage.getItem('urbanease_user_id');
      if (id === null || id === 'undefined' || id === 'null' || id === 'null') {
        return null;
      }
      return id;
    } catch (err) {
      return null;
    }
  }

  function _getLastLoginDate() {
    try {
      const lastLoginDate = localStorage.getItem('urbanease_last_login_date');
      if (!lastLoginDate || lastLoginDate === '') {
        return '';
      }
      return lastLoginDate;
    } catch (err) {
      return '';
    }
  }

  function _getCompanyId() {
    try {
      const companyId = localStorage.getItem('urbanease_company_id');
      if (!companyId || companyId === null) {
        return null;
      }
      return companyId;
    } catch (err) {
      return null;
    }
  }

  function _clear() {
    localStorage.removeItem('urbanease_token');
    localStorage.removeItem('urbanease_refresh_token');
    localStorage.removeItem('urbanease_user_id');
    localStorage.removeItem('urbanease_company_id');
    localStorage.removeItem('urbanease_company_id_iri');
  }

  return {
    getService: _getService,
    setToken: _setToken,
    setRefreshToken: _setRefreshToken,
    setId: _setId,
    setLastLoginDate: _setLastLoginDate,
    // setCompanyId: _setCompanyId,
    setCompanyIdIri: _setCompanyIdIri,
    getToken: _getToken,
    getRefreshToken: _getRefreshToken,
    getId: _getId,
    getLastLoginDate: _getLastLoginDate,
    getCompanyId: _getCompanyId,
    clear: _clear,
  };
})();

export default LocalStorageService;
