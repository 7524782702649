import { isEmpty } from 'lodash';

// check for existing key and define existing or default key
function validKeyObject(obj: BuildPermit, key: string) {
  // generate key's array
  const keys = Object.keys(obj);

  // if key don't exist or key is not in key array, then set default key value
  if (!key || !keys.includes(key)) return keys[0];
  return key;
}

export function genericSortBuildPermis(
  tab: BuildPermits,
  order = 'asc',
  key: string
) {
  if (isEmpty(tab)) {
    return tab;
  }

  const objectKey = validKeyObject(tab[0], key);

  // when object, sorting by selected key
  if (order !== 'asc') {
    return tab.slice().sort((elmtA: BuildPermit, elmtB: BuildPermit) =>
      (elmtB[objectKey as keyof BuildPermit] as any).localeCompare(
        elmtA[objectKey as keyof BuildPermit] as any,
        undefined,
        {
          numeric: true,
        }
      )
    );
  } else {
    return tab.slice().sort((elmtA: BuildPermit, elmtB: BuildPermit) =>
      (elmtA[objectKey as keyof BuildPermit] as any).localeCompare(
        elmtB[objectKey as keyof BuildPermit] as any,
        undefined,
        {
          numeric: true,
        }
      )
    );
  }
}
