interface IRowContentTagProps {
  value: number | string | null;
  label: string;
  type: 'li' | 'p';
  unit?: string;
}

function RowContentTag({ label, value, type, unit = '' }: IRowContentTagProps) {
  const parsedValue = typeof value === 'number' && isNaN(value) ? null : value;
  return (
    <>
      {type === 'p' ? (
        <p>
          {label}:&nbsp;
          <span>{` ${parsedValue ? parsedValue + ' ' + unit : 'Indéfini'}`}</span>
        </p>
      ) : (
        <li>
          {label}:&nbsp;
          <span>{` ${parsedValue ? parsedValue + ' ' + unit : 'Indéfini'}`}</span>
        </li>
      )}
    </>
  );
}

export default RowContentTag;
