import TrashIcon from '../../components/icons/TrashIcon';
import UpdateIcon from '../../components/icons/UpdateIcon';
import styles from './genericTab.module.scss';

interface IGenericTabProps {
  tab: IGenericTabsLabelType;
  index: number;
  values: CurrentValuesType;
  onSelect: () => void;
  bigger?: boolean;
  dataCy?: string;
  needSeparation?: boolean;
  onDelete?: () => void;
  onUpdate?: () => void;
}
function GenericTab({
  tab,
  index,
  values,
  onSelect,
  bigger,
  needSeparation,
  dataCy,
  onDelete,
  onUpdate,
}: IGenericTabProps) {
  const selected = index === values.curr;

  return (
    <>
      <div
        className={[
          styles.genericTab,
          values.prev === index ? styles.prev : '',
          selected ? styles.selected : '',
          values.next === index ? styles.next : '',
          bigger ? styles.bigger : '',
        ].join(' ')}
        data-cy={dataCy}
      >
        <div className={styles.genericTabContent}>
          <div onClick={() => onSelect()} className={styles.label}>
            {tab.name as string}
          </div>
          <div className={styles.tabIconContainer}>
            {onUpdate && tab.canUpdate && selected && (
              <UpdateIcon
                color="PRIMARY"
                bgColor="WHITE"
                tooltipBgColor="PRIMARY"
                width={33}
                height={33}
                onClick={onUpdate}
                className={styles.icon}
                tooltipText="Renommer ou paramétrer le modèle"
              />
            )}
            {onDelete && tab.canDelete && selected && (
              <TrashIcon
                color="RED"
                bgColor="TRANSPARENT"
                tooltipBgColor="RED"
                width={33}
                height={33}
                onClick={onDelete}
                tooltipText="Supprimer le modèle"
                className={styles.icon}
              />
            )}
          </div>
        </div>
      </div>
      {needSeparation && !selected && <div className={styles.addSeparation}></div>}
    </>
  );
}

export default GenericTab;
