import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import {
  checkForDuplicatePlotThunk,
  fetchPlotForMultiPlotThunk,
} from './multiPlotSelectThunk';

const initialState: MultiPlotSelectState = {
  isMultiPlotSelector: false,
  plots: { apiStatus: APIStatus.IDLE, result: null },
  ownerPlots: null,
  duplicatePlots: { apiStatus: APIStatus.IDLE, result: null },
  plotCount: 0,
  duplicatePlotCount: 0,
};

const multiPlotSelectSlice = createSlice({
  name: 'multiPlotSelect',
  initialState,
  reducers: {
    setIsMultiPlotSelector: (state, action: PayloadAction<boolean>) => {
      if (action.payload === false) {
        state.plotCount = 0;
        state.plots = { apiStatus: APIStatus.IDLE, result: [] };
      }
      state.isMultiPlotSelector = action.payload;
    },
    resetDuplicatePlots: (state) => {
      state.duplicatePlots = { apiStatus: APIStatus.IDLE, result: null };
      state.duplicatePlotCount = 0;
    },
    removePlotFromSelection: (state, action: PayloadAction<number>) => {
      state.plots.result =
        state.plots.result
          ?.filter((f) => f.index !== action.payload)
          .map((m, i) => ({ ...m, index: i })) ?? null;
      state.plotCount = state.plotCount - 1;
    },
    plotsReset: (state) => {
      state.plots = { apiStatus: APIStatus.IDLE, result: null };
      state.plotCount = 0;
    },
    ownerPlotsSet: (state, action: PayloadAction<OwnerDataPlot>) => {
      const ap = action.payload;

      if (!state.ownerPlots) {
        state.ownerPlots = [action.payload];
      } else {
        const exists = state.ownerPlots?.find((f) => {
          return ap.fullPlotId === f.fullPlotId;
        });

        if (exists) {
          state.ownerPlots =
            state.ownerPlots.filter((f) => f.fullPlotId !== ap.fullPlotId) ?? [];
        } else {
          state.ownerPlots = state.ownerPlots.concat(ap);
        }
      }
    },
    ownerPlotsReset: (state) => {
      state.ownerPlots = null;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlotForMultiPlotThunk.pending, (state) => {
        state.plots.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchPlotForMultiPlotThunk.fulfilled,
        (state, action: PayloadAction<PlotMultiPlot>) => {
          const isPlotExists = state.plots.result?.find(
            (f) => f.fullPlotId === action.payload.fullPlotId
          );

          if (!isPlotExists) {
            state.plots.result = state.plots.result
              ? state.plots.result.concat({
                  ...action.payload,
                  index: state.plots.result.length,
                })
              : [
                  {
                    ...action.payload,
                    index: 0,
                  },
                ];

            state.plotCount = state.plotCount + 1;
          }

          state.plots.apiStatus = APIStatus.IDLE;
          state.plots.error = undefined;
        }
      )
      .addCase(
        fetchPlotForMultiPlotThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.plots.apiStatus = APIStatus.REJECTED;
          state.plots.error = action.payload;
        }
      )
      .addCase(checkForDuplicatePlotThunk.pending, (state) => {
        state.duplicatePlots.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        checkForDuplicatePlotThunk.fulfilled,
        (state, action: PayloadAction<PlotStudies | null>) => {
          state.duplicatePlots.apiStatus = APIStatus.IDLE;
          state.duplicatePlots.result = action.payload;
          state.duplicatePlotCount = action.payload?.length ?? 0;
          state.duplicatePlots.error = undefined;
        }
      )
      .addCase(
        checkForDuplicatePlotThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.duplicatePlots.apiStatus = APIStatus.REJECTED;
          state.duplicatePlots.error = action.payload;
        }
      );
  },
});

export default multiPlotSelectSlice.reducer;

export const multiPlotSelectActions = multiPlotSelectSlice.actions;
export const getMultiPlotSelectState = (state: RootState) => state.multiPlotSelect;
