import { useLocation } from 'react-router';
import { useAppSelector } from '../../App/store';
import Alert from '../../components/Modals/Alert';
import AnnouncementModal from '../../components/Modals/AnnouncementModal/AnnouncementModal';
import DeleteStudyModal from '../../components/Modals/DeleteModal/DeleteStudyModal';
import RecursiveFolderStatusModal from '../../components/Modals/RecursiveSubFolderModal';
import MultiPlotSelectSynthesis from '../../shared/components/multiPlotSelectSynthesis/MultiPlotSelectSynthesis';
import UploadImageModal from '../../shared/modals/uploadModal/UploadImageModal';
import AdvancedSearchModal from '../advancedPlotSearch/advancedPlotSearchModal';
import BodaccModal from '../advancedPlotSearch/bodaccModal';
import AuthErrorModal from '../auth/authErrorModal/AuthErrorModal';
import ConnexionProcessModal from '../auth/connectionProcess/ConnexionProcessModal';
import ExpiredSubscriptionModal from '../auth/expiredSubscriptionModal/ExpiredSubscriptionModal';
import ChooseCompanyModal from '../company/ChooseCompanyModal';
import ReadMoreModal from '../company/subscription/ReadMoreModal/ReadMoreModal';
import SearchCompanyNameModal from '../dashboardOwnerDatas/searchCompanyNameModal';
import AreaChangeConfirmationModal from '../externalPdf/landPDF/components/AreaChangeConfirmationModal';
import EditFolderModal from '../folders/EditFolderModal';
import DeleteFolderModal from '../folders/components/DeleteFolderModal';
import CesiumHelperModal from '../map/cesium/components/CesiumHelperModal';
import AltimetryHelperModal from '../map/leaflet/plugins/altimetryPlugin/AltimetryHelperModal';
import DrawHelperModal from '../map/leaflet/plugins/drawPlugin/DrawHelperModal';
import MeasureHelperModal from '../map/leaflet/plugins/measurePlugin/components/MeasureHelperModal';
import { getMultiPlotSelectState } from '../plot/multiPlotSelectFeature/multiPlotSelectSlice';
import MultiselectPlotModal from '../plot/multiPlotSelectFeature/multiselectPlotModal';
import AskPLUModal from '../plu/askPluModal';
import DeleteProcedureModal from '../procedures/DeleteProcedureModal';
import DeleteSectorModal from '../sectors/components/DeleteSectorModal';
import EditSectorModal from '../sectors/components/editSectorModal/EditSectorModal';
import StudyContactModal from '../study/components/studyContact/rightPanelContactBloc/StudyContactModal';
import ProcedureDeleteConfirmationModal from '../study/components/studyProcedure/rightPanelProcedureBloc/ProcedureDeleteConfirmationModal';
import DeleteUrbaGptModal from '../urbaGpt/DeleteUrbaGptModal/DeleteUrbaGptModal';
import ErrorTokenUrbaGptModal from '../urbaGpt/ErrorTokenUrbaGptModal/ErrorTokenUrbaGptModal';
import UrbaGpt from '../urbaGpt/UrbaGpt';
import { getModalsState } from './modalsSlice';

export interface IModalProps {
  open: boolean;
  value?: string;
  callback?: nullable<(sien: string) => void>;
}

const ModalContainer = (): JSX.Element => {
  const {
    sectorEdit,
    sectorDelete,
    folderDelete,
    folderEdit,
    companyChange,
    procedureDelete,
    connectionProcess,
    advancedSearchResult,
    companySearch,
    multiPlotAdd,
    recusiveAssignForSubfolder,
    pluDemand,
    plotDelete,
    readMore,
    alert,
    measureHelperModal,
    announcement,
    urbaGptDelete,
    drawHelperModal,
    altimetryHelperModal,
    errorTokenUrbaGptModal,
    loadProspecDataConfirm,
    expiredSubscriptionModal,
    authErrorModal,
    bodaccModal,
    studyContactModal,
    cesiumHelperModal,
    uploadImageModal,
    deleteStudyProcedureConfirmationModal,
  } = useAppSelector(getModalsState);
  const { isMultiPlotSelector } = useAppSelector(getMultiPlotSelectState);

  const { pathname } = useLocation();

  return (
    <>
      {alert.status && <Alert context={alert.context} modalType={alert.modalType} />}
      {procedureDelete && <DeleteProcedureModal open={procedureDelete} />}
      {folderDelete && <DeleteFolderModal />}
      {sectorDelete && <DeleteSectorModal />}
      {folderEdit && <EditFolderModal open={folderEdit} />}
      {sectorEdit && <EditSectorModal />}
      {plotDelete && <DeleteStudyModal open={plotDelete} />}
      {advancedSearchResult.status && (
        <AdvancedSearchModal {...advancedSearchResult} />
      )}
      {bodaccModal && <BodaccModal />}
      {companySearch && <SearchCompanyNameModal open={companySearch} />}
      {multiPlotAdd.status && (
        <>
          <MultiselectPlotModal plots={multiPlotAdd.plots} />
        </>
      )}
      {readMore.status && <ReadMoreModal type={readMore.type} />}
      {companyChange && <ChooseCompanyModal />}
      {recusiveAssignForSubfolder && <RecursiveFolderStatusModal />}
      {pluDemand && <AskPLUModal />}
      {connectionProcess && <ConnexionProcessModal />}
      {announcement && <AnnouncementModal open={announcement} />}
      {urbaGptDelete && <DeleteUrbaGptModal open={urbaGptDelete} />}
      {errorTokenUrbaGptModal && <ErrorTokenUrbaGptModal />}
      {drawHelperModal && <DrawHelperModal />}
      {measureHelperModal && <MeasureHelperModal />}
      {altimetryHelperModal && <AltimetryHelperModal />}
      {loadProspecDataConfirm.display && <AreaChangeConfirmationModal />}
      {expiredSubscriptionModal && <ExpiredSubscriptionModal />}
      {authErrorModal && <AuthErrorModal />}
      {isMultiPlotSelector && <MultiPlotSelectSynthesis />}
      {studyContactModal && <StudyContactModal />}
      {cesiumHelperModal && pathname === '/3d-view' && <CesiumHelperModal />}
      {uploadImageModal.isOpen && <UploadImageModal />}
      {deleteStudyProcedureConfirmationModal.display && (
        <ProcedureDeleteConfirmationModal />
      )}
      <UrbaGpt />
    </>
  );
};

export default ModalContainer;
