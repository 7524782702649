const polylineOptions = {
  position: 'topright', // Position to show the control. Values: 'topright', 'topleft', 'bottomright', 'bottomleft'
  unit: 'kilometres', // Default unit the distances are displayed in. Values: 'kilometres', 'landmiles', 'nauticalmiles'
  useSubunits: true, // Use subunits (metres/feet) in tooltips if distances are less than 1 kilometre/landmile
  clearMeasurementsOnStop: true, // Clear all measurements when Measure Control is switched off
  showBearings: false, // Whether bearings are displayed within the tooltips
  bearingTextIn: 'Dedans', // language dependend label for inbound bearings
  bearingTextOut: 'Dehors', // language dependend label for outbound bearings
  tooltipTextFinish: 'Cliquez pour <b>terminer la ligne</b><br>',
  tooltipTextDelete:
    'Appuyez sur la touche MAJ et cliquez pour <b>supprimer le point</b>',
  tooltipTextMove: 'Cliquez et faites glisser pour <b>déplacer le point</b><br>',
  tooltipTextResume:
    '<br>Appuyez sur la touche CTRL et cliquez pour <b>reprendre la ligne</b>',
  tooltipTextAdd:
    'Appuyez sur la touche CTRL et cliquez pour <b>ajouter un point</b>',
  // language dependend labels for point's tooltips
  measureControlTitleOn: 'Activer la mesure de distance', // Title for the Measure Control going to be switched on
  measureControlTitleOff: 'Désactiver la mesure de distance', // Title for the Measure Control going to be switched off
  measureControlLabel: '&#8614;', // Label of the Measure Control (Unicode symbols are possible)
  measureControlClasses: [], // Classes to apply to the Measure Control
  showClearControl: true, // Show a control to clear all the measurements
  clearControlTitle: 'Effacer les mesures', // Title text to show on the Clear Control
  clearControlLabel: '&times', // Label of the Clear Control (Unicode symbols are possible)
  clearControlClasses: [], // Classes to apply to Clear Control
  showUnitControl: false, // Show a control to change the units of measurements
  unitControlUnits: ['kilometres', 'landmiles', 'nauticalmiles'],
  // measurement units being cycled through by using the Unit Control
  unitControlTitle: {
    // Title texts to show on the Unit Control
    text: "Changer d'unités",
    kilometres: 'kilometres',
    landmiles: 'land miles',
    nauticalmiles: 'nautical miles',
  },
  unitControlLabel: {
    // Unit symbols to show in the Unit Control and measurement labels
    metres: 'm',
    kilometres: 'km',
    feet: 'ft',
    landmiles: 'mi',
    nauticalmiles: 'nm',
  },
  unitControlClasses: [], // Classes to apply to the Unit Control
  tempLine: {
    // Styling settings for the temporary dashed line
    color: '#db4a29', // Dashed line color
    weight: 3, // Dashed line weight
  },
  fixedLine: {
    // Styling for the solid line
    color: '#9b2d14', // Solid line color
    weight: 3, // Solid line weight
  },
  startCircle: {
    // Style settings for circle marker indicating the starting point of the polyline
    color: '#9b2d14', // Color of the border of the circle
    weight: 2, // Weight of the circle
    fillColor: '#9b2d14', // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 3, // Radius of the circle
  },
  intermedCircle: {
    // Style settings for all circle markers between startCircle and endCircle
    color: '#9b2d14', // Color of the border of the circle
    weight: 2, // Weight of the circle
    fillColor: '#9b2d14', // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 3, // Radius of the circle
  },
  currentCircle: {
    // Style settings for circle marker indicating the latest point of the polyline during drawing a line
    color: '#9b2d14', // Color of the border of the circle
    weight: 2, // Weight of the circle
    fillColor: '#db4a29', // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 3, // Radius of the circle
  },
  endCircle: {
    // Style settings for circle marker indicating the last point of the polyline
    color: '#9b2d14', // Color of the border of the circle
    weight: 2, // Weight of the circle
    fillColor: '#9b2d14', // Fill color of the circle
    fillOpacity: 1, // Fill opacity of the circle
    radius: 3, // Radius of the circle
  },
};

export default polylineOptions;
