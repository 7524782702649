import * as yup from 'yup';
import { transformNumber } from '../../../shared/utils/yupSchema';

export const initialValues: AdvancedPlotSearchForm = {
  zone: '',
  zoneChild: '',
  isDrawingZone: false,
  isBodacc: false,
  plotType: 'all',
  polygon: undefined,
  minPlot: undefined,
  maxPlot: undefined,
  minGround: undefined,
  maxGround: undefined,
  minBuiltPercent: undefined,
  maxBuiltPercent: undefined,
  ownerName: '',
  sirenNumber: '',
  types: '',
  empriseBatiMin: undefined,
  empriseBatiMax: undefined,
  buildingHeightMin: undefined,
  buildingHeightMax: undefined,
  copro: 'null',
  ownerDatas: 'null',
};

export const buildingTypes = [
  'Religieux',
  'Commercial et services',
  'Industriel',
  'Indifférencié',
  'Agricole',
  'Sportif',
  'Résidentiel',
  'Annexe',
];
export const radiobuttonsPlotType = [
  {
    value: 'all',
    label: 'Toutes',
  },
  {
    value: 'construct_ground',
    label: 'Bâties',
  },
  {
    value: 'excludebati',
    label: 'Non bâties',
  },
];

const generic = () =>
  yup.number().transform(transformNumber).min(0, 'Nombre négatif interdit');

export const formSchema = yup.object({
  zone: yup.string().default(''),
  zoneChild: yup.string().default(''),
  isDrawingZone: yup.bool().default(false),
  isBodacc: yup.bool().default(false),
  plotType: yup
    .string()
    .oneOf(['all', 'construct_ground', 'excludebati'] as const)
    .default('all'),
  polygon: yup.string(),
  minPlot: generic()
    .default(undefined)
    .min(0, 'Nombre négatif interdit')
    .when('maxPlot', {
      is: (val: string | number | undefined) =>
        val !== undefined && val !== 0 && val !== '',
      then: () =>
        generic().max(yup.ref('maxPlot'), 'Mini doit être inférieur à maxi'),
    }),
  maxPlot: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0: {
        return generic();
      }
      default:
        return generic().when('minPlot', {
          is: (val: number | undefined) => val !== undefined && val !== 0,
          then: () =>
            generic().min(yup.ref('minPlot'), 'Maxi doit être supérieur à mini'),
        });
    }
  }),
  minGround: generic().when('maxGround', {
    is: (val: number | undefined) => val !== undefined && val !== 0,
    then: () =>
      generic().max(yup.ref('maxGround'), 'Mini doit être inférieur à maxi'),
  }),
  maxGround: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return generic();
      default:
        return generic().when('minGround', {
          is: (val: number | undefined) => val !== undefined && val !== 0,
          then: () =>
            generic().min(yup.ref('minGround'), 'Maxi doit être supérieur à mini'),
        });
    }
  }),
  minBuiltPercent: generic()
    .max(100, '100% maximum')
    .when('maxBuiltPercent', {
      is: (val: number | undefined) => val !== undefined && val !== 0,
      then: () =>
        generic().max(yup.ref('maxBuiltPercent'), 'Mini doit être inférieur à maxi'),
    }),
  maxBuiltPercent: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return generic();
      default:
        return generic()
          .max(100, '100% maximum')
          .when('minBuiltPercent', {
            is: (val: number | undefined) => val !== undefined && val !== 0,
            then: () =>
              yup
                .number()
                .min(yup.ref('minBuiltPercent'), 'Maxi doit être supérieur à mini'),
          });
    }
  }),
  ownerName: yup.string().transform((v) => v ?? null),
  sirenNumber: yup.lazy((value) => {
    const checkedValue = value ? value.length : 0;
    switch (checkedValue) {
      case 0:
        return yup.string();
      default:
        return yup
          .string()
          .length(9, 'le n° SIREN doit contenir 9 caractères')
          .matches(/[a-zA-Z0-9]{9}/, 'Lettres et chiffres uniquement');
    }
  }),
  types: yup.string().oneOf(buildingTypes.concat('')).default(''),
  empriseBatiMin: generic()
    .min(0, 'Nombre négatif interdit')
    .when('empriseBatiMax', {
      is: (val: number | undefined) => val !== undefined && val !== 0,
      then: () =>
        generic().max(yup.ref('empriseBatiMax'), 'Mini doit être inférieur à maxi'),
    }),
  empriseBatiMax: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return generic();
      default:
        return generic()
          .min(0, 'Nombre négatif interdit')
          .when('empriseBatiMin', {
            is: (val: number | undefined) => val !== undefined && val !== 0,
            then: () =>
              generic().min(
                yup.ref('empriseBatiMin'),
                'Maxi doit être supérieur à mini'
              ),
          });
    }
  }),
  buildingHeightMin: generic()
    .min(0, 'Nombre négatif interdit')
    .when('buildingHeightMax', {
      is: (val: number | undefined) => val !== undefined && val !== 0,
      then: () =>
        generic().max(
          yup.ref('buildingHeightMax'),
          'Mini doit être inférieur à maxi'
        ),
    }),
  buildingHeightMax: yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return generic();
      default:
        return generic().when('buildingHeightMin', {
          is: (val: number | undefined) => val !== undefined && val !== 0,
          then: () =>
            generic().min(
              yup.ref('buildingHeightMin'),
              'Maxi doit être supérieur a mini'
            ),
        });
    }
  }),
  copro: yup
    .string()
    .oneOf(['null', 'true', 'false'] as const)
    .default('null'),
  ownerDatas: yup
    .string()
    .oneOf(['null', 'true', 'false'] as const)
    .default('null'),
});
