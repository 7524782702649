import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';

interface IContaminatedAreasProps {
  areas: ContaminatedAreas;
}
function ContaminatedAreas({ areas }: IContaminatedAreasProps) {
  return (
    <View>
      <View style={styles.areaContainer}>
        {areas.map((m) => {
          return (
            <View key={nanoid()} style={styles.areaRow}>
              <Text style={styles.areaDescription}>
                <Text style={styles.puce}>•&nbsp;&nbsp;</Text>
                <Text style={styles.areaName}>{`${m.count} `}</Text>
                {/* first letter to lower case */}
                {m.libelle?.charAt(0).toLowerCase() + m.libelle.slice(1)}
              </Text>
            </View>
          );
        })}
      </View>
    </View>
  );
}

export default ContaminatedAreas;

const styles = StyleSheet.create({
  areaContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    fontWeight: 300,
    fontSize: '12px',
    marginTop: '10px',
  },
  areaRow: {
    width: '95%',
    display: 'flex',
    flexDirection: 'row',
  },
  areaDescription: { flexGrow: 1, lineHeight: 1.4, fontSize: '10px' },
  puce: { fontWeight: 400, fontSize: '12px' },
  areaName: { fontWeight: 400, color: '#f00' },
});
