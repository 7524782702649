import { useEffect } from 'react';
import { getCompanyState } from '../company/companySlice';
import { getUsersState } from './usersSlice';
import { fetchUsersThunk } from './usersThunks';

import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { firstNameAndInitialParser } from '../../utils/jsFunctions';
import { getAuthState } from '../auth/authSlice';
interface IUseUserProps {
  load?: boolean;
}
export default function useUsers(params: IUseUserProps) {
  const { load } = params;
  const { users, userCount, apiStatus, activeUsers } = useAppSelector(getUsersState);
  const { companyIdIri } = useAppSelector(getCompanyState);
  const { isLogged } = useAppSelector(getAuthState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      load &&
      isLogged &&
      isEmpty(users) &&
      companyIdIri &&
      (apiStatus === APIStatus.IDLE || apiStatus === APIStatus.REJECTED)
    ) {
      dispatch(fetchUsersThunk({ companyIdIri }));
    }
  }, [companyIdIri, isLogged]);

  const parseUsers = (data: Users | null): SelectItems => {
    if (data) {
      return data.map((u) => {
        return {
          value: u.idIri,
          display: firstNameAndInitialParser(u.firstName, u.lastName),
        };
      });
    } else {
      return [];
    }
  };

  const getUserById = (idIri: string): User | null => {
    return users?.find((u) => u.idIri === idIri) ?? null;
  };

  return {
    users,
    activeUsers,
    userCount,
    usersForSelect: parseUsers(activeUsers),
    getUserById,
    usersForAutocomplete: parseUsers(users),
  };
}
