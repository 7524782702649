import { createAsyncThunk } from '@reduxjs/toolkit';
import { postEntity } from '../../../../../services/axiosFiles/genericCrud';
import { loadersActions } from '../../../../loaders/loaderSlice';
import { externalPdfErrialActions } from '../../../externalPdfSlice';
import { erialDatasForPdfGenerationParser } from '../../../shared/utils/externalPdfParser';

export const fetchDatasForPdfGenerationThunk = createAsyncThunk(
  'externalPdfErrial/fetchDatasForPdfGenerationThunk',
  async ({ plots }: { plots: string[] }, { dispatch }) => {
    try {
      const response: any = await postEntity({
        endpoint: `/pdf_urbanease/get_datas_for_pdf_generation/`,
        body: plots,
      });

      const parsedResponse = erialDatasForPdfGenerationParser(response);
      dispatch(loadersActions.currentStepSet('Données parcelles chargées'));

      return parsedResponse;
    } catch (error: any) {
      dispatch(
        externalPdfErrialActions.setInfoModalDisplay({
          display: true,
          type: 'errorFetchDatasForPdfGeneration',
          plotIds: null,
          errorMessage: null,
        })
      );
      dispatch(externalPdfErrialActions.setClicked(false));
      return Promise.reject(error);
    }
  }
);
