import commonFetch from './commonFetch';

/**
 * Fetch Linear Prescription datas
 * @param {string}}plotGeometry stringified geometry
 * @returns {object} servitudes
 */
async function fetchPrescriptionLIN({ plotGeometry }: { plotGeometry: string }) {
  try {
    // force stringify value
    let pg: string =
      typeof plotGeometry === 'string' ? plotGeometry : JSON.stringify(plotGeometry);

    const result = await commonFetch({
      url: 'https://apicarto.ign.fr/api/gpu/prescription-lin?',
      plotGeometry: pg,
    });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchPrescriptionLIN;
