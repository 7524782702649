import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import { useContext, useState } from 'react';
import { getAppState } from '../../../../App/appSlice';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { getAuthState } from '../../../auth/authSlice';
import FolderAutocomplete from '../../../folders/components/FolderAutocomplete';
import { foldersActions } from '../../../folders/foldersSlice';
import useFolder from '../../../folders/useFolder';
import IndividualCircularLoader from '../../../loaders/individualCircularLoader';
import { mapPluginsActions } from '../../../map/leaflet/plugins/mapPluginSlice';
import createMultiPlotStudy from '../../../study/services/createMultiPlotStudy';
import { getUsersState } from '../../../users/usersSlice';
import { multiPlotSelectActions } from '../multiPlotSelectSlice';
import FolderContainer from './FolderContainer';
import ModalBtnContainer from './ModalBtnContainer';
import { MultiselectModalContext } from './MultiSelectPlotModalContent';
import styles from './multiselectPlotModal.module.scss';

export interface ISelectFolderContentProps {
  onCancel: () => void;
  onClose: () => void;
  plotCount: number;
  plots: Omit<ICreatePlotStudy, 'folder' | 'responsable'>[];
}

const Container = ({ children }: IChildren) => {
  return <div className={styles.multiselectPlotModalContent}>{children}</div>;
};

function SelectFolderContent({
  plotCount,
  onCancel,
  onClose,
  plots,
}: ISelectFolderContentProps) {
  const { state, update } = useContext(MultiselectModalContext);
  const { userIdIri } = useAppSelector(getAuthState);
  const { users } = useAppSelector(getUsersState);
  const { plotStudyStatuses } = useAppSelector(getAppState);
  const [apiState, setApiState] = useState<APIStatus>(APIStatus.IDLE);
  const { folders, getFolderByIdIriFromList } = useFolder({});

  const dispatch = useAppDispatch();

  const handleReset = () => {
    dispatch(multiPlotSelectActions.reset());
    dispatch(mapPluginsActions.reset());
    onClose();
  };

  const handleErrorClick = () => {
    setApiState(APIStatus.IDLE);
  };
  const handleValid = async () => {
    if (state) {
      try {
        setApiState(APIStatus.PENDING);
        dispatch(foldersActions.allDeployedFoldersReset());
        const f = getFolderByIdIriFromList(state.idIri);
        const plotsWithFolderAndUser: ICreatePlotStudies = plots.map((m) => ({
          ...m,
          folder: f,
          responsable: userIdIri as string,
        }));
        await createMultiPlotStudy(
          plotsWithFolderAndUser,
          users,
          plotStudyStatuses.result
        );

        dispatch(multiPlotSelectActions.plotsReset());
        dispatch(
          foldersActions.updatePlotStudyCount({
            folderIdIri: f.idIri,
            count: plotsWithFolderAndUser.length,
          })
        );
        setApiState(APIStatus.FULFILLED);
      } catch (error) {
        // TODO use error return
        setApiState(APIStatus.REJECTED);
      }
    }
  };

  if (apiState === APIStatus.PENDING) {
    return (
      <Container>
        <IndividualCircularLoader size={100} />
      </Container>
    );
  }
  if (apiState === APIStatus.REJECTED) {
    return (
      <div className={styles.multiselectPlotModalError}>
        <p>
          Une erreur est survenue lors de l&#39;enregistrement des parcelles. <br />
          Veuillez renouveler l&#39;opération ou contacter le support d&#39;urbanease{' '}
          <br />
        </p>
        <a href="mailto:support@urbanease.io">Je contacte le support</a>
        <button onClick={handleErrorClick} className={styles.buttonError}>
          OK
        </button>
      </div>
    );
  }

  if (apiState === APIStatus.IDLE) {
    return (
      <>
        <div className={styles.title}>
          <p>
            Rangez les <span>{plotCount}</span> parcelles sélectionnées dans un
            dossier :
          </p>
        </div>

        <Container>
          {!folders ? (
            <IndividualCircularLoader size={100} />
          ) : isEmpty(folders) ? (
            <p>
              Aucun dossier trouvé, <br />
              veuillez en créer un.
            </p>
          ) : (
            <>
              <div className={styles.autocomplete}>
                <FolderAutocomplete
                  isPlotStudy={true}
                  parent={state}
                  onUpdateMultiPlot={
                    update as React.Dispatch<React.SetStateAction<IFolder | null>>
                  }
                />
              </div>
              <FolderContainer folders={folders} />
            </>
          )}
        </Container>
        <ModalBtnContainer onCancel={onCancel} onValid={handleValid} />
      </>
    );
  }

  if (apiState === APIStatus.FULFILLED) {
    return (
      <div className={styles.submittedResult}>
        <CheckIcon className={styles.tickIcon} />
        <p className={styles.resultTitle}>Parfait !</p>
        <button className={styles.greenSubmitBtn} onClick={handleReset}>
          OK
        </button>
      </div>
    );
  }

  return null;
}

export default SelectFolderContent;
