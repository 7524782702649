import { useNavigate } from 'react-router';
import { appActions } from '../../../App/appSlice';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getAuthState } from '../../auth/authSlice';
import styles from '../styles/mailshots.module.scss';

function LauncherTooltipText() {
  const navigate = useNavigate();
  const { isManager } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  const handleTooltipClick = () => {
    dispatch(appActions.setAccountTab('mailshots'));
    navigate('/account');
  };

  return (
    <>
      {isManager ? (
        <p
          className={[styles.launcherTooltip, styles.clickable].join(' ')}
          onClick={handleTooltipClick}
        >
          Aucun modèle d&lsquo;édition <br />
          est créé
          <br />
          Crééz un modèle en cliquant ici
        </p>
      ) : (
        <p className={styles.launcherTooltip}>
          Aucun modèle d&lsquo;édition <br />
          est créé
          <br />
          Crééz un modèle <br />
          ou contactez votre manager
        </p>
      )}
    </>
  );
}

export default LauncherTooltipText;
