import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { fontStyles } from '../styles/pdfStyles';

interface IPlotDetailInfoBlocProps {
  data: string | string[];
  label: string[];
  isMultiPlot?: boolean;
}
function PlotDetailInfoBlocLand({
  data,
  label,
  isMultiPlot,
}: IPlotDetailInfoBlocProps) {
  return (
    <View style={[style.blocBase, isMultiPlot ? {} : style.bloc]}>
      <View>
        {isMultiPlot ? (
          (data as string[]).map((m) => (
            <Text
              key={m}
              style={[fontStyles.fs15, fontStyles.b700, style.dataMulti]}
            >
              {m}
            </Text>
          ))
        ) : (
          <Text style={[fontStyles.fs15, fontStyles.b700]}>{data}</Text>
        )}
      </View>
      <View>
        {label.map((l) => {
          return (
            <Text key={nanoid()} style={fontStyles.fs12}>
              {l}
            </Text>
          );
        })}
      </View>
    </View>
  );
}

export default PlotDetailInfoBlocLand;

const style = StyleSheet.create({
  bloc: {
    paddingTop: '10px',
  },
  blocBase: {
    border: '1px solid #000',
    width: '102px',
    height: '88px',
    margin: '4px 0 4px 4px',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  dataMulti: {
    margin: 0,
    padding: 0,
  },
});
