import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { fetchStudyContacts } from '../fetchStudyContacts';

const fetchStudyContactsThunk = createAsyncThunk(
  'study/fetchStudyContactsThunk',
  async (params: void, thunkOptions) => {
    try {
      // state datas
      const state = thunkOptions.getState() as RootState;
      const { study } = state.study;
      const { companyId } = state.company;
      const { contactRoles } = state.app;

      const result = await fetchStudyContacts(
        study.result?.idIri ?? null,
        companyId,
        contactRoles.result
      );

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchStudyContactsThunk;
