import { PROSPECTION_PRIORITY_LIST } from '../../../constants';
import GenericTooltip from '../../tooltips/GenericTooltip';
import PriorityIcon from './PriorityIcon';
import styles from './priorityIcon.module.scss';

type ColorLevelType =
  | {
      bgColor: ColorType;
      textColor: ColorType;
    }
  | undefined;

function getColorLevel(level: number | string): ColorLevelType {
  const lvl = typeof level === 'string' ? parseInt(level, 10) : level;

  switch (lvl) {
    case 1:
      return { bgColor: 'SUCCESS', textColor: 'BLACK' };
    case 2:
      return { bgColor: 'ORANGE', textColor: 'WHITE' };
    case 3:
      return { bgColor: 'ERROR', textColor: 'WHITE' };
    default:
      return undefined;
  }
}

function PriorityIconWithTooltip(props: IPriorityIconProps) {
  const levelColor = getColorLevel(props.level);

  if (props.level === null || props.level === 0) return null;

  const label = PROSPECTION_PRIORITY_LIST.find(
    (f) => f.value === props.level
  )?.display;

  return (
    <GenericTooltip
      text={`Priorité ${label}`}
      bgColor={levelColor?.bgColor}
      color={levelColor?.textColor}
    >
      <div className={styles.priorityIconWithTooltip}>
        <PriorityIcon {...props} />
      </div>
    </GenericTooltip>
  );
}

export default PriorityIconWithTooltip;
