import { memo } from 'react';
import { dateFormat } from '../../../../lib/formats/dataFormat';
import { addSpaceToNumber } from '../../../../utils/jsFunctions';
import ImageCustom from '../../shared/components/ImageCustom';
import styles from '../styles/prospecForm.module.scss';
import SimilaryAdCheckbox from './SimilaryAdCheckbox';
interface IProps {
  row: SimilarType;
  adType: SimilarAdsType;
}
function SimilaryAdRow({ row, adType }: IProps) {
  return (
    <>
      <div className={styles.similaryAdRow}>
        <SimilaryAdCheckbox id={row.id} adType={adType} />
        <ImageCustom className={styles.imageContainer} source={row.picture} />

        <div className={styles.rowInfos}>
          <div>
            <span>
              <span className={styles.label}>Ville:</span> {row.city}
            </span>
            <span>
              <span className={styles.label}>Prix:</span>{' '}
              {addSpaceToNumber(row.price)} €
            </span>
          </div>
          <div>
            <span>
              <span className={styles.label}>Surface:</span> {row.landArea} m²
            </span>
            <span>
              <span className={styles.label}>Origine:</span> {row.label}
            </span>
            <span>
              <span className={styles.label}>Publication:</span>{' '}
              {dateFormat(row.publishedAt)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(SimilaryAdRow);
