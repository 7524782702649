import ExcelJS from 'exceljs';

export async function exportExcel(
  name: string,
  worksheetColumns: Object[],
  worksheetRow?: any
) {
  try {
    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('sheet1');
    const worksheet = workbook.getWorksheet('sheet1');
    if (worksheet) {
      worksheet.columns = worksheetColumns;
      worksheet.addRows(worksheetRow ?? []);
      const uint8Array = await workbook.xlsx.writeBuffer();
      const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name + '.xlsx';
      a.click();
      a.remove();
    }
  } catch (error) {
    console.log(error);
  }
}
