import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';

const initialState: IPanelState = {
  leftPanelDisplay: true,
  rightButtons: true,
  rightPanelDisplay: false,
  rightPanelContent: {
    builtPermisTable: false,
    prospectionContent: false,
    selectedTab: '',
  },
};

const panelsSlice = createSlice({
  name: 'panels',
  initialState,
  reducers: {
    reset: () => initialState,
    rightPanelCloseOnDashboard: () => initialState,
    leftPanelUnCollapse: () => initialState,
    leftPanelCollapse: (state) => {
      state.leftPanelDisplay = false;
    },
    rightPanelOpen: (state) => {
      state.rightPanelDisplay = true;
      state.rightButtons = false;
    },
    rightPanelClose: (state) => {
      state.rightPanelDisplay = false;
      state.rightButtons = true;
    },
    builtPermitContentShow: (state) => {
      state.leftPanelDisplay = false;
      state.rightButtons = false;
      state.rightPanelDisplay = true;
      state.rightPanelContent = {
        builtPermisTable: true,
        prospectionContent: false,
        selectedTab: '',
      };
    },
    builtPermitContentHide: (state) => {
      state.leftPanelDisplay = false;
      state.rightButtons = true;
      state.rightPanelDisplay = false;
      state.rightPanelContent = {
        builtPermisTable: false,
        prospectionContent: false,
        selectedTab: '',
      };
    },
    studyContentShow: (state, action: PayloadAction<string>) => {
      state.leftPanelDisplay = false;
      state.rightButtons = false;
      state.rightPanelDisplay = true;
      state.rightPanelContent = {
        builtPermisTable: false,
        prospectionContent: true,
        selectedTab: action.payload,
      };
    },
    studyContentHide: (state) => {
      state.leftPanelDisplay = false;
      state.rightButtons = true;
      state.rightPanelDisplay = false;
      state.rightPanelContent = {
        builtPermisTable: false,
        prospectionContent: false,
        selectedTab: '',
      };
    },
  },
});

export default panelsSlice.reducer;
export const panelsActions = panelsSlice.actions;
export const getPanelState = (state: RootState): IPanelState => state.panels;
export const getRightPanelContentState = (
  state: RootState
): IRightPanelContentState => state.panels.rightPanelContent;
