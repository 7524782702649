import { StyleSheet } from '@react-pdf/renderer';
import '../../../shared/styles/fonts';

const COLOR_PRIMARY = '#4e55a2';
export const pdfStyles = StyleSheet.create({
  date: { fontSize: '12px' },
  pageHome: {
    position: 'relative',
    padding: '0 15px',
    fontFamily: 'MontserratFamily',
    fontSize: '12px',
  },
  homeLogoAndTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logoImage: {
    marginLeft: '15px',
    width: '20%',
  },
  homeTopTitleSection: {
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'center',
    color: '#fff',
  },
  homeTopTitleSectionImage: {
    position: 'absolute',
    top: -15,
    right: -45,
  },
  homeTopTitle: { fontSize: '24px', fontWeight: 700, zIndex: 10 },
  homeTopSubTitle: { fontSize: '14px', fontWeight: 700, zIndex: 10 },

  // PLOT ID SECTION
  homePlotIdsSection: { marginTop: '60px', fontSize: '12px' },
  homePlotIdsTitle: { fontWeight: 700, color: '#FE0000' },
  homePlotIdsText: { maxWidth: '250px', color: COLOR_PRIMARY },

  // MAP IMAGE
  homeMapImage: {
    position: 'absolute',
    left: 15,
    top: '230px',
    width: '100%',
    height: '320px',
    zIndex: -1,
    borderRadius: '15px',
  },

  homeOwnerAndAddressBloc: {
    marginTop: '360px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: COLOR_PRIMARY,
  },
  homeOwnerSection: {},
  homeOwnerLabel: {},
  homeOwnerData: { fontSize: '14px', fontWeight: 700 },
  homeAddressSection: { fontWeight: 700, color: '#FE0000', fontSize: '14px' },

  homeTitleBloc: { marginTop: '45px', color: COLOR_PRIMARY },
  homeTitle: { marginTopfontSize: '20px', fontWeight: 700 },
  byUrbanease: { fontSize: '12px' },

  // FOOTER
  homeFooter: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#000',
  },
  footerBgImg: {
    position: 'absolute',
    bottom: 0,
    width: '642px',
  },
  footerLeftBgImg: {
    position: 'absolute',
    left: '0',
  },
  footerRightBgImg: {
    position: 'absolute',
    right: 0,
    height: '600px',
    width: '400px',
  },
  homeFooterImageSection: {
    width: '45%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  homeFooterRightContainer: {},
  homeFooterImage: { width: '40%' },
  urbaLogo: {
    height: '75px',
    margin: '0 10px',
  },
  homeFooterImageTextSection: { fontSize: '9px' },
  homeFooterLine: { height: '80%', borderLeft: '1px solid #fff' },
  homeFooterTextSection: {
    fontFamily: 'OrpiFamily',
    fontStyle: 'normal',
    paddingLeft: '40px',
    flexGrow: 1,
    color: '#fff',
    fontSize: '12px',
  },
  homeFooterNameText: { padding: '2px 0 2px 5px', fontWeight: 700 },
  homeFooterNameSection: { display: 'flex', flexDirection: 'row' },
  homeFooterText: { padding: '2px 0 2px 5px' },
});
