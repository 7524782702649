export const radiobuttonsCopro = [
  {
    value: 'null',
    label: 'Tous biens',
  },
  {
    value: 'true',
    label: 'Copropriétés',
  },
  {
    value: 'false',
    label: 'Biens hors copropriété',
  },
];
export const radiobuttonsOwner = [
  {
    value: 'null',
    label: 'Tous biens',
  },
  {
    value: 'true',
    label: 'Avec propriétaires personnes morales',
  },
  {
    value: 'false',
    label: 'Sans propriétaires personnes morales',
  },
];
