import rouge from '../../../assets/images/pin-urbanease.svg';
import styles from './activePlot.module.scss';
function MarkerDivIcon() {
  return (
    <div className={styles.markerDivIcon}>
      <p className={styles.content}>
        Cliquez sur le marker <br />
        pour plus d&#039;infos
      </p>
      <div className={styles.triangle}></div>
      <img src={rouge} alt="marker" />
    </div>
  );
}

export default MarkerDivIcon;
