import { Text } from '@react-pdf/renderer';
import { addSpaceToNumber } from '../../../../../utils/jsFunctions';
import { fontStyles } from '../styles/pdfStyles';

interface IProps {
  label?: string;
  price: number;
  sqrPrice: number;
}

function EstimationPriceDisplay({ label, price, sqrPrice }: IProps) {
  return (
    <Text>
      {label}{' '}
      <Text style={[fontStyles.b700, { color: '#000' }]}>{`${addSpaceToNumber(
        price
      )} € `}</Text>
      ({addSpaceToNumber(sqrPrice)} €/m²)
    </Text>
  );
}

export default EstimationPriceDisplay;
