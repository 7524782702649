import { useEffect } from 'react';
import ButtonGeneric from '../../components/buttons/buttonGeneric/ButtonGeneric';
import useLoadImage from '../../hooks/useLoadImage';
import styles from './uploadModal.module.scss';
interface IExistingImageSectionProps {
  url: string | null;
  isVisible: boolean;
  type: ImageUploadType;
  height?: number;
}
function ExistingImageSection({
  isVisible,
  url,
  type,
  height,
}: IExistingImageSectionProps) {
  const { getImage, loadedImage, deleteImage } = useLoadImage();

  if (!url) return null;

  useEffect(() => {
    if (url) {
      getImage(url, height);
    }
  }, [url]);

  if (!isVisible) return null;
  if (!loadedImage) return null;

  return (
    <>
      <h4>Image existante</h4>
      <img src={loadedImage.image} height={height} />
      {type === 'companyLogo' && (
        <ButtonGeneric
          content="Supprimer"
          bgColor="RED"
          onClick={deleteImage}
          containerClassName={styles.deleteButton}
        />
      )}
    </>
  );
}

export default ExistingImageSection;
