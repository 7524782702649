import { pluGenericRules, pluRules } from './constants';

// Convert the color to RGBA format and set the desired opacity
function hexToRGBA(hex: string, alpha: number) {
  // Convert hex to RGB
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  // Calculate the RGBA values
  const result = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return result;
}

export function getColorCodePmTilesPlu({
  zone,
  opacity,
}: {
  zone: string;
  opacity: number;
}): string {
  const plu = pluRules.find((elt) => elt.zone === zone);
  if (!plu) {
    // Creating a regular expression to search for the zone
    const regex = new RegExp(`^${zone}$`, 'i');

    // Finding the corresponding zone
    const matchingZone = pluGenericRules.find((elt) => regex.test(elt.zone));

    // Checks if the zone was found
    if (matchingZone) {
      const rgbaColor = hexToRGBA(matchingZone.color, opacity);
      return rgbaColor;
    } else {
      // default color if no zone found
      return hexToRGBA('#000000', opacity);
    }
  } else {
    const rgbaColor = hexToRGBA(plu.color, opacity);
    return rgbaColor;
  }
}

export function pmTilesColorParser({
  color,
  opacity,
}: {
  color: string;
  opacity: number;
}): string {
  if (!color) {
    // default color
    return hexToRGBA('#000000', opacity);
  } else {
    const rgbaColor = hexToRGBA(color ?? '', opacity);
    return rgbaColor;
  }
}
