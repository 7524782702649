import { dateFormat } from '../../lib/formats/dataFormat';
import { isNumber } from '../../shared/utils/numberFunctions';

const pluCategories: IPluCategories[] = [
  { cat: 1, name: 'Données sismiques' },
  { cat: 2, name: 'Quartiers prioritaires' },
  { cat: 3, name: 'Monuments historiques' },
  { cat: 4, name: 'PPRI - Zones inondables' },
  { cat: 5, name: 'Argiles Datas' },
  { cat: 6, name: 'PPRN' },
  { cat: 7, name: 'Exposition au bruit' },
];

// gap marker to see all markers feature on a single plot
const updateLatForSelectedFeature = (lat: number | string, gap?: CentroidGap) => {
  const parsedLat = typeof lat === 'number' ? lat : parseFloat(lat);
  let updatedLat = 0;

  switch (gap) {
    case 'multiselect':
      updatedLat = parsedLat + 0.000015;

      break;
    case 'advancedSearch':
      updatedLat = parsedLat + 0.00003;
      break;
    default:
      parsedLat;
      break;
  }

  return updatedLat;
};

const balanceAccountParser = (data: any) => {
  try {
    const parsedBalance: BalanceBankAccount = {
      totalBankAccounts: data.bank_accounts_total ?? 0,
      totalCredit: data.credit_total ?? 0,
      totalDebit: data.debit_total ?? 0,
      result: data.result ?? ' - ',
      resultat: data.resultat ?? 0,
    };

    return parsedBalance as BalanceBankAccount;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot balance account parser error',
      error,
    });
  }
};

const documentsParser = (data: any) => {
  try {
    const parsedDocuments = Object.entries(data).map((e) => {
      const type = e[0] as string;
      const files = e[1];
      return { type, files };
    });

    return parsedDocuments as PlotDocuments;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot document parser error',
      error,
    });
  }
};

const ownerListParser = (data: any) => {
  try {
    const parsedOwnerList = {
      state: data.state,
      owners: data.datas.map((m: any) => {
        const area = m.area.split(' ')[0] ? 0 : parseInt(m.area);

        return {
          address: m.address ?? ' - ',
          area,
          bodaccSituation: m.bodacc_situation ?? ' - ',
          bodaccTerminationDate: m.bodacc_termination_date ?? ' - ',
          bodaccDetails: m.bodaccDetails ?? ' - ',
          corporation: m.corporation ?? ' - ',
          legalStatus: m.legal_status ?? ' - ',
          moreInformations: m.more_informations ?? ' - ',
          natureCulture: m.nature_culture ?? ' - ',
          owner: m.owner ?? ' - ',
          ownerName: m.ownerName ?? ' - ',
          siren: m.siren ?? ' - ',
          type: m.type ?? ' - ',
        };
      }),
    };

    return parsedOwnerList as OwnerList;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot ownerList parser error',
      error,
    });
  }
};

const plotDatasParser = (data: any) => {
  try {
    const parsedBuilding = data.buildings.map((m: any) => {
      return {
        area: isNumber(m.area, 'float'),
        createdAt: data.batiment_created ?? ' - ',
        name: data.batiment_name ?? ' - ',
        type: data.batiment_type ?? ' - ',
        updatedAt: data.batiment_updated ?? ' - ',
        height: isNumber(m.area, 'float'),
        houseCount: isNumber(m.area, 'int'),
        stageCount: isNumber(m.area, 'int'),
        buildingType: data.building_type ?? ' - ',
        use1: data.batiment_use_1 ?? ' - ',
        use2: data.batiment_use_2 ?? ' - ',
        groundElevationMax: isNumber(m.maximum_ground_elevation, 'float'),
        roofElevationMax: isNumber(m.maximum_roof_elevation, 'float'),
        groundElevationMin: isNumber(m.minimum_ground_elevation, 'float'),
        roofElevationMin: isNumber(m.minimum_roof_elevation, 'float'),
      };
    });

    const parsedPlotDatas = {
      prefix: data.prefix,
      section: data.section,
      number: data.number,
      buildingsInfos: parsedBuilding,
      areaBuilt: isNumber(data.area_built, 'float'),
      areaFree: isNumber(data.area_free, 'float'),
      dateCreated: data.date_created ?? ' - ',
      dateUpdated: data.date_updated ?? ' - ',
      realCapacity: isNumber(data.real_capacity, 'float'),
      theoricCapacity: isNumber(data.real_capacity, 'int'),
      type: data.type ?? ' - ',
    };

    return parsedPlotDatas as PlotDatas;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot plot datas parser error',
      error,
    });
  }
};

const fiscalLawParser = (data: any) => {
  try {
    const parsedFiscalLaw = {
      eligibility: data.eligibility ?? ' - ',
      zone: data.zone ?? ' - ',
    };

    return parsedFiscalLaw as FiscalLaw;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot fiscal law parser error',
      error,
    });
  }
};

const plotDetailsParser = (data: any) => {
  try {
    const parsedPlotDetails = data.map((d: any) => {
      return {
        ...pluCategories[d.cat - 1],
        state: d.datas.map((m: { [x: string]: string | number }) => m.libelle),
      };
    });

    return parsedPlotDetails as IPlotDetails;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot plot details parser error',
      error,
    });
  }
};

const pluStatusParser = (data: any) => {
  try {
    const parsedPluStatus = {
      city: data.city_name,
      insee: data.insee,
      status: data.status === 'CC' ? 'Carte Communale' : data.status,
    };

    return parsedPluStatus;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot plu status parser error',
      error,
    });
  }
};

const taxesParser = (data: any) => {
  try {
    const parsedTaxes = {
      city: data.concatener ?? ' - ',
      departmentCity: data.concatener_2 ?? ' - ',
      communeQty: data.nb_commune ?? ' - ',
      numero: data.numero ?? ' - ',
      secteur: data.secteur ?? ' - ',
      taux: isNumber(data.taux, 'int'),
    };

    return parsedTaxes;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot taxes parser error',
      error,
    });
  }
};

const townHallParser = (data: any) => {
  try {
    const parsedTownHall = {
      contact: data.contact,
      coordinates: [isNumber(data.lat, 'float'), isNumber(data.lon, 'float')],
      mayorFirstName: data.mayor_first_name ?? ' - ',
      mayorLastName: data.mayor_name ?? ' - ',
      mayorMandatStartDate: data.mayor_mendate_start_date ?? ' - ',
      phone: data.phone ?? ' - ',
      website: data.website ?? ' - ',
      population: parseInt(data.population, 10),
      cityArea: isNumber(data.city_area_in_km2, 'float'),
      plotCount: isNumber(data.total_parcels_city, 'int'),
      buildingCount: isNumber(data.total_building_city, 'int'),
    };

    return parsedTownHall as TownHall;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot town hall parser error',
      error,
    });
  }
};

const zoneParser = (data: any) => {
  try {
    const parsedZone = {
      fichier: data.zone.fichier ?? null,
      libelle: data.zone.libelle ?? null,
      mapboxUrl: data.zone.mapbox_url,
      pmtilesUrl: data.zone.pmtiles_url,
      zone: data.zone,
      zoneChild: data.zone_child ?? null,
    };

    return parsedZone as PlotZone;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot zone parser error',
      error,
    });
  }
};

const transactionsParser = (data: any) => {
  try {
    const parsedTransactions = data.map((m: any) => {
      const lots = [];

      for (let i = 1; i <= 5; i++) {
        if (m[`lot${i}_numero`] !== '') {
          lots.push(m[`lot${i}_numero`]);
        }
      }

      return {
        addressNumber: m.address_number,
        addressRoadName: m.address_road_name,
        area: isNaN(parseInt(m.area, 10)) ? 0 : parseInt(m.area, 10),
        cityName: m.city_name ?? ' - ',
        landArea: isNaN(parseInt(m.land_area, 10)) ? 0 : parseInt(m.land_area, 10),
        landValue: isNaN(parseInt(m.land_value, 10))
          ? 0
          : parseInt(m.land_value, 10),
        localType: m.local_type ?? ' - ',
        mutationDate: dateFormat(m.mutation_date),
        plotId: m.parcel_id ?? ' - ',
        postalCode: m.postal_code ?? ' - ',
        cntRoom: isNaN(parseInt(m.rooms_count, 10)) ? 0 : parseInt(m.rooms_count),
        selectedPlot: m.selected_parcel,
        sqmPrice: isNaN(m.square_meter_price) ? 0 : parseFloat(m.square_meter_price),
        coordinates: m.lat && m.lon ? [parseFloat(m.lat), parseFloat(m.lon)] : null,
        lots,
      };
    });

    return parsedTransactions as Transactions;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'plot transactions parser error',
      error,
    });
  }
};

export const plotDataFromCoordinatesParser = (datas: any, gap?: CentroidGap) => {
  const r = datas.result;

  const parsedPlot: unknown = {
    founded: r.parcel_founded,
    plotId: datas.result.parcel_datas.parcel_id,
    department: datas.department,
    inseeCode: datas.insee,
    cadastralUrl: datas.mapbox_cadastral_url,
    contactsImpots: datas.contact_impots,
    plotCentroid: [
      updateLatForSelectedFeature(r.parcel_datas.parcel_centroid_lat, gap),
      parseFloat(r.parcel_datas.parcel_centroid_lon),
    ],
    plotGeometry: JSON.parse(r.parcel_datas.parcel_geometry),
    buildingsGeometry:
      r.parcel_datas.buildings &&
      r.parcel_datas.buildings[0] &&
      r.parcel_datas.buildings
        .filter((f: any) => f.batiment_wkb_geometry !== null)
        .map((m: any) => JSON.parse(m.batiment_wkb_geometry)),
    balanceBlankAccount: r.balance_banks_account
      ? balanceAccountParser(r.balance_banks_account)
      : null,
    documents: r.documents ? documentsParser(r.documents) : null,
    ownerList: r.owners_list ? ownerListParser(r.owners_list) : null,
    plotDatas: r.parcel_datas ? plotDatasParser(r.parcel_datas) : null,
    fiscalLaw: r.fiscal_law ? fiscalLawParser(r.fiscal_law) : null,
    plotDetails: r.parcel_details ? plotDetailsParser(r.parcel_details) : null,
    pluStatus: r.plu_status ? pluStatusParser(r.plu_status) : null,
    taxes: r.taxes ? taxesParser(r.taxes) : null,
    townHall: r.town_hall_contact ? townHallParser(r.town_hall_contact) : null,
    zone: r.zone ? zoneParser(r.zone) : null,
    transactions: r.transactions ? transactionsParser(r.transactions) : null,
  };

  return parsedPlot as Plot;
};
