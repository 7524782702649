import { deleteEntity } from '../../../services/axiosFiles/genericCrud';

async function deleteStudyContact(idIri: string | null) {
  try {
    // check params
    if (!idIri) throw new Error('StudyContact id not found');

    // request
    await deleteEntity(idIri);

    // return id to valid the thunk
    return idIri;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default deleteStudyContact;
