import { postEntityNew } from '../../../../../../services/axiosFiles/genericCrud';
import pricehubbleGenericParser from '../../../../../../shared/parsers/pricehubbleGenericParser';
import { phAgencyAndAgentDatasParser } from '../../../../landPDF/orpiLandDataParser';

// a7e8d553-cee7-4748-aafb-704a0d8574bb
async function fetchAgencyAndAgentDatas({
  entityId,
  phAccessToken,
  callback,
}: IPHTokenAndPHId & { callback: (value: string) => void }) {
  try {
    if (entityId) {
      const result: any = await postEntityNew({
        endpoint: '/pdf_urbanease/pricehubble_get_agent_datas',
        body: { dossierId: entityId, ph_access_token: phAccessToken },
      });

      if (result) {
        const phPreParser = pricehubbleGenericParser(result);
        const parsed = phAgencyAndAgentDatasParser(phPreParser);
        callback("Données d'agence chargées");

        return parsed;
      } else {
        throw new Error('no results');
      }
    } else {
      throw new Error('missing fetchDossierDatas params');
    }
  } catch (error) {
    return null;
    // return Promise.reject(error);
  }
}

export default fetchAgencyAndAgentDatas;
