import createCtx from '../../../App/contexts/GenenricStateContext';
import '../../../components/RightPanelModal/rightPanelModal.scss';
import BuiltPermisTableBody from './BuiltPermisTableBody';
import BuiltPermisTableHeader from './BuiltPermisTableHeader';

const [ctx, SearchInputProvider] = createCtx<{
  inputSearch: string;
  inputAutorisationType: string;
}>({
  inputSearch: '',
  inputAutorisationType: '',
});
export const PermitsTableContext = ctx;

function BuildPermitsTable() {
  return (
    <SearchInputProvider>
      <BuiltPermisTableHeader />

      <div className="right-panel-modal-body">
        <BuiltPermisTableBody />
      </div>
    </SearchInputProvider>
  );
}

export default BuildPermitsTable;
