import { postEntityNew } from '../../../../../../services/axiosFiles/genericCrud';
import pricehubbleGenericParser from '../../../../../../shared/parsers/pricehubbleGenericParser';
import { builtPermitsParser } from '../../../../landPDF/orpiLandDataParser';

interface IProps extends IPHTokenAndPHId {
  lat: number | string;
  lng: number | string;
  department: string;
  callback: (value: string) => void;
}

async function fetchBuiltPermisDatas({
  entityId,
  lat,
  lng,
  department,
  phAccessToken,
  callback,
}: IProps) {
  try {
    if (entityId && lat && lng) {
      const body = {
        dossierId: entityId,
        lon: lng.toString(),
        lat: lat.toString(),
        department,
        radius: 5000,
        limit: 8,
        ph_access_token: phAccessToken,
      };

      const result: any = await postEntityNew({
        endpoint: '/pdf_urbanease/pricehubble_get_building_projects_datas',
        body,
      });
      if (result) {
        const phPreParser = pricehubbleGenericParser(result);
        const parsed = builtPermitsParser(phPreParser);
        callback('Permis de construire chargés');

        return parsed;
      } else {
        throw new Error('no results');
      }
    } else {
      throw new Error('missing fetchDossierDatas params');
    }
  } catch (error) {
    return null;
  }
}

export default fetchBuiltPermisDatas;
