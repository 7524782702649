import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../App/store';
import { addrServProcessAsync } from '../../../../../utils/addrServProcessAsync';
import { getMapState } from '../../../mapSlice';
import styles from './cesiumFooter.module.scss';

interface IProps {
  displayedErrors: string[];
}
const DEFAULT_ADDRESS = ' - ';

function CesiumFooter({ displayedErrors }: IProps) {
  const { geolocDatas, clicked3dPoint } = useAppSelector(getMapState);
  const [clickedAddress, setClickedAddress] = useState<string | null>(
    DEFAULT_ADDRESS
  );
  const [activeDisplay, setActiveDisplay] = useState<string | null>();

  const hasError = !isEmpty(displayedErrors);
  useEffect(() => {
    if (clicked3dPoint) {
      addrServProcessAsync(clicked3dPoint.lat, clicked3dPoint.lng).then(
        (res) => {
          setClickedAddress(res?.address ?? DEFAULT_ADDRESS);
        },
        (err) => {
          setClickedAddress(DEFAULT_ADDRESS);
        }
      );
    }
  }, [clicked3dPoint]);

  useEffect(() => {
    if (!isEmpty(displayedErrors)) {
      let c = 0;
      const interval = setInterval(() => {
        if (c >= displayedErrors.length) c = 0;

        // Action to execute every 3 secondes
        setActiveDisplay(displayedErrors[c]);
        c++;
      }, 3000);

      // destroy setInterval on unmont
      return () => clearInterval(interval);
    }
  }, [displayedErrors]);

  return (
    <div
      className={[styles.cesiumStatusbar, hasError ? styles.hasError : ''].join(' ')}
    >
      <p className={styles.plotAddress}>
        <span>Parcelle : </span>
        {geolocDatas?.address ?? DEFAULT_ADDRESS}
      </p>
      {hasError && <p className={styles.error}>{activeDisplay}</p>}
      <p className={styles.svAddress}>
        <span>Street View : </span>
        {clickedAddress ?? DEFAULT_ADDRESS}
      </p>
    </div>
  );
}

export default CesiumFooter;
