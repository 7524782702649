export enum ToolbarBtnEnum {
  CREATE_POLYGON = 'CREATE_POLYGON',
  CREATE_STOP_POLYGON = 'CREATE_STOP_POLYGON',
  EDIT_POLYGON = 'EDIT_POLYGON',
  DELETE_POLYGON = 'DELETE_POLYGON',
  MEASURE = 'MEASURE',
}

export enum ToolbarTypeEnum {
  DRAW = 'DRAW',
  MEASURE = 'MEASURE',
  ALTIMETRY = 'ALTIMETRY',
  MULTISELECT = 'MULTISELECT',
}
