import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getCompanyState } from '../company/companySlice';
import { getContactsState } from './contactsSlice';
import fetchContactsThunk from './services/thunks/fetchContactsThunk';

export default function useContacts() {
  const { contacts, totalContacts, contactForAction } =
    useAppSelector(getContactsState);
  const { companyIdIri } = useAppSelector(getCompanyState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (companyIdIri) {
      dispatch(fetchContactsThunk({ companyIdIri }));
    }
  }, [companyIdIri]);

  const getContactById = (idIri: string): nullable<Contact> => {
    return contacts.result?.find((c) => c.idIri === idIri);
  };

  const contactsForSelect = () => {
    return contacts.result?.map((c) => ({
      value: c.idIri,
      display: `${c.firstName} ${c.lastName}`,
    }));
  };

  return {
    contacts: contacts.result,
    getContactById,
    contactsForSelect,
    totalContacts,
    contactForAction,
  };
}
