import { nanoid } from '@reduxjs/toolkit';
import { ChangeEvent, useState } from 'react';
import AccordionBase from '../AccordionBase';
import OwnerInfosAccordion from './OwnerInfosAccordion';

interface IMultiPlotOwnerAccordionProps {
  ownersPlots: nullable<IOwnersPlots[]>;
}

export default function MultiPlotOwnerAccordion({
  ownersPlots,
}: IMultiPlotOwnerAccordionProps) {
  const [expanded, setExpanded] = useState<string>('');
  const handleChange = (panel: string) => (
    event: ChangeEvent,
    expanded: boolean
  ) => {
    setExpanded(expanded ? panel : '');
  };

  return (
    <>
      {ownersPlots?.map((op, i) => (
        <AccordionBase
          key={nanoid()}
          className="parcelle-infos"
          id={i}
          expanded={expanded}
          title={op.plotId}
          onChange={handleChange}
        >
          <OwnerInfosAccordion ownersPlot={op} />
        </AccordionBase>
      ))}
    </>
  );
}
