export const advancedPlotSearchFormToApiParser = async (
  data: AdvancedPlotSearchForm,
  city: string,
  postalCode: string,
  inseeCode: string
) => {
  try {
    const d = data;

    // can be an any type
    const body: AdvancedSearchPostKeys = {
      city_name: city,
      city_zipcode: postalCode,
      insee_code: inseeCode,
      zone: d.zone,
      zone_child: d.zoneChild,
      surface_total_min: d.minPlot,
      surface_total_max: d.maxPlot,
      surface_free_min: d.minGround,
      surface_free_max: d.maxGround,
      surface_built_percent_max: d.maxBuiltPercent,
      surface_built_percent_min: d.minBuiltPercent,
      polygon: d.polygon,
      plot_type: d.plotType,
      owner_name: d.ownerName,
      siren: d.sirenNumber,
      is_bodacc: d.isBodacc,
      usage: d.types,
      emprise_bati_min: d.empriseBatiMin,
      emprise_bati_max: d.empriseBatiMax,
      building_height_min: d.buildingHeightMin,
      building_height_max: d.buildingHeightMax,
      copro: d.copro,
      owner_datas: d.ownerDatas,
    };

    const cleanedBody: any = {};
    Object.entries(body).forEach((e) => {
      if (e[1] !== '' && e[1] !== 0) cleanedBody[e[0]] = e[1];
    });

    return cleanedBody;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'advancedPlotSearchFormToApiParser error',
      error,
    });
  }
};

export const advancedPlotSearchApiToStoreParser = (
  data: any,
  zone: string | null,
  zoneChild: string | null
): Promise<AdvancedSearchPlots> => {
  try {
    const parsed = data.map((m: any) => {
      return {
        area: m.area,
        buildArea: Math.ceil(m.build_area),
        fullPlotId: m.id,
        lat: m.lat,
        lng: m.lon,
        noBuildArea: Math.ceil(m.no_build_area),
        realCapacity: m.real_capacity,
        zone,
        zoneChild,
      };
    });

    return parsed;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'advancedPlotSearchApiToStoreParser error',
      error,
    });
  }
};
