import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { fontStyles } from '../styles/pdfStyles';

interface IEnvConstraintProps {
  isActive: boolean;
  value?: string;
  title: string;
  text: string;
}
function EnvConstraintLand(props: IEnvConstraintProps) {
  return (
    <View style={[styles.envConstraint, props.isActive ? styles.active : {}]}>
      <View style={styles.valueContainer}>
        <Text>{props.isActive ? 'OUI' : 'NON'}</Text>
        {props.value && <Text>Plot</Text>}
      </View>

      <View style={styles.descriptionContainer}>
        <Text style={[props.isActive ? fontStyles.b700 : {}, fontStyles.fs12]}>
          {props.title}
        </Text>
        <Text style={styles.text}>{props.text}</Text>
      </View>
    </View>
  );
}

export default EnvConstraintLand;

const styles = StyleSheet.create({
  envConstraint: {
    display: 'flex',
    flexDirection: 'row',
  },
  envConstraintActive: {
    display: 'flex',
    flexDirection: 'row',
  },
  active: {
    backgroundColor: '#fbe8ea',
  },
  valueContainer: {
    fontSize: '16px',
    width: '15%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    margin: '0 10px',
    height: '100%',
    border: '1px solid #000',
  },
  descriptionContainer: {
    width: '75%',
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '1px solid #000',
  },

  text: { fontSize: '10px', fontWeight: 300 },
});
