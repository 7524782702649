import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import { getMapState, mapActions } from '../../../../../../features/map/mapSlice';
import { formatedNumber } from '../../../../../../utils/jsFunctions';

interface ITownHallAccordion {
  datas: nullable<ITownHall>;
}

export default function TownHallAccordion({
  datas,
}: ITownHallAccordion): JSX.Element {
  const { townHall } = useAppSelector(getMapState);
  const dispatch = useAppDispatch();

  const handleDisplayTownHall = () => {
    if (townHall.latLng) {
      dispatch(
        mapActions.townHallDisplaySet({
          displayed: !townHall.displayed,
          latLng: townHall.latLng,
        })
      );
    }
  };

  const mayor =
    datas?.mayorFirstName &&
    datas?.mayorLastName &&
    ` ${datas?.mayorFirstName} ${datas?.mayorLastName}`;
  const mandat = datas?.mayorMandatStartDate ?? ' -';
  const cityArea = formatedNumber(datas?.cityArea, 'km²', true);
  const plotCount = formatedNumber(datas?.plotCount, '');
  const buildingCount = formatedNumber(datas?.buildingCount, '');
  const population = formatedNumber(datas?.population, '');

  const contactRegex = (str: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;

    if (emailRegex.test(str)) {
      return `mailto:${str}`;
    } else {
      return str;
    }
  };

  return (
    <div className="town-hall">
      {!isEmpty(datas) ? (
        <>
          <p>
            Maire :
            {datas?.mayorFirstName && datas?.mayorLastName ? (
              <span>{mayor}</span>
            ) : (
              <span>{` -`}</span>
            )}
          </p>
          <p>
            Début de mandat :
            {datas?.mayorMandatStartDate ? (
              <span>{` ${mandat}`}</span>
            ) : (
              <span>{` -`}</span>
            )}
          </p>
          <p>
            Population :{` `}
            <span>{population}</span>
          </p>
          <p>
            Surface :
            <span>
              {` `}
              {cityArea}
            </span>
          </p>
          <p>
            Nombre de parcelles :{` `}
            <span>{plotCount}</span>
          </p>
          <p>
            Nombre de batiments :
            <span>
              {` `}
              {buildingCount}
            </span>
          </p>
          <p>
            Contact :
            {datas?.contact && datas?.contact !== 'Non renseigné' ? (
              <a
                href={contactRegex(datas?.contact)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {` ${datas?.contact}`}
              </a>
            ) : (
              <span>{` -`}</span>
            )}
          </p>
          <p>
            Téléphone :
            {datas?.phone ? <span> {' ' + datas?.phone}</span> : <span>{` -`}</span>}
          </p>
          <p>
            Site internet :
            {datas?.website ? (
              <a href={datas?.website} target="_blank" rel="noopener noreferrer">
                {' ' + datas?.website}
              </a>
            ) : (
              <span>{` -`}</span>
            )}
          </p>
          <Button
            className="form-btn"
            onClick={handleDisplayTownHall}
            disabled={!townHall.latLng}
          >
            {`${
              townHall.displayed ? 'Cacher' : 'Afficher'
            } l'emplacement de la mairie sur la carte`}
          </Button>
        </>
      ) : (
        <p>Aucune information trouvée</p>
      )}
    </div>
  );
}
