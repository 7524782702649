import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import PaginationCustom from '../../../components/Common/PaginationCustom/PaginationCustom';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { getDashboardOwnerDatasState } from '../dashboardOwnerDatasSlice';
import { fetchOwnersFromInseeCodeThunk } from '../dashboardOwnerDatasThunk';
import { cityOwnerTableHeader } from '../data';
import CityOwnerTableRow from './CityOwnerTableRow';

export default function CityOwnerTable() {
  const { city, ownersFromInseeCode } = useAppSelector(getDashboardOwnerDatasState);
  const dispatch = useAppDispatch();

  const ownerResult = ownersFromInseeCode.result;

  const ownersFromInseeCodeRequest = (
    p: number,
    ipp: number,
    sort: string | null,
    order: OwnerOrder
  ) => {
    if ((p !== ownerResult?.page || ipp !== ownerResult?.itemsPerPage) && city) {
      const query: DataFromInseeBodyRequest = {
        page: p,
        itemsPerPage: ipp,
        insee: city?.inseeCode,
        sort,
        order,
      };

      dispatch(fetchOwnersFromInseeCodeThunk(query));
    }
  };

  // ***** Changing items per page action *****
  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    const value: number = e.target.value;
    let p = ownerResult?.page ?? 1;
    const newPageQty = Math.floor((ownerResult?.companiesCount ?? 0) / value) + 1;
    if (newPageQty < (ownerResult?.page ?? 1)) {
      p = newPageQty;
    }

    ownersFromInseeCodeRequest(
      p,
      value,
      ownerResult?.sort ?? null,
      ownerResult?.order ?? null
    );
  };

  // ***** Changing page action *****
  const handleChangeIndex = (index: number): void => {
    const ipp = ownerResult?.itemsPerPage || 15;
    const p = Math.floor(index / ipp) + 1;

    if (ownerResult?.page && p !== ownerResult?.page) {
      ownersFromInseeCodeRequest(
        p,
        ipp,
        ownerResult?.sort ?? null,
        ownerResult?.order ?? null
      );
    }
  };

  if (ownersFromInseeCode.apiStatus === APIStatus.PENDING) {
    return <IndividualCircularLoader size={200} />;
  }

  return (
    <div className="tab-body">
      <TableContainer className="owner-table">
        <Table>
          <TableHead>
            <TableRow>
              {cityOwnerTableHeader?.map((elt) => (
                <TableCell key={nanoid()}>{elt.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ownersFromInseeCode.apiStatus === APIStatus.FULFILLED &&
              ownerResult?.companies?.map((company) => (
                <CityOwnerTableRow key={nanoid()} company={company} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationCustom
        itemPerPageLabel="Companies par page"
        page={ownerResult?.page ?? 1}
        elementsQty={ownerResult?.companiesCount ?? 0}
        itemsPerPage={ownerResult?.itemsPerPage ?? 15}
        onItemsPerPageChange={(e: ChangeEventCustom) => handleItemsPerPageChange(e)}
        onMinIndexTab={(index) => handleChangeIndex(index)}
      />
    </div>
  );
}
