import FormButtonContainer from '../../components/buttons/formButtonContainer/FormButtonContainer';
import CrossIcon from '../../components/icons/CrossIcon';
import { UploadResultType } from './UploadImageModal';
import styles from './uploadModal.module.scss';

interface IUploadFailContentProps {
  result: UploadResultType;
  callback: () => void;
}

function UploadFailContent({ result, callback }: IUploadFailContentProps) {
  return (
    <>
      <div className={styles.failBody}>
        <CrossIcon
          isRoundIcon
          noTooltip
          className={styles.icon}
          width={50}
          height={50}
          bgColor="ERROR"
          color="WHITE"
        />
        <h4>Erreur de choix d&lsquo;image</h4>
        <p>{result.message}</p>
      </div>
      <FormButtonContainer
        validContent="Ok"
        onValid={callback}
        className={styles.flexEnd}
      />
    </>
  );
}

export default UploadFailContent;
