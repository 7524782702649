import { StyleSheet, Text, View } from '@react-pdf/renderer';
import GoodDetail, { IconTypeEnum } from './GoodDetail';
import { addSpaceToNumber } from '../../../../../utils/jsFunctions';

interface IGoodDetailsProps {
  roomCount: number;
  floorCount: number;
  goodTypology: string;
  homeArea: number;
  landArea: number;
  comment: string;
}

function GoodDetails(props: IGoodDetailsProps) {
  let goodType = 'Indéfini';
  if (props.goodTypology === 'apartment') goodType = 'Appartement';
  if (props.goodTypology === 'house') goodType = 'Maison';
  if (props.goodTypology === 'building') goodType = 'Immeuble';
  if (props.goodTypology === 'field') goodType = 'Terrain';
  if (props.goodTypology === 'others') goodType = 'Autres';

  return (
    <View style={styles.goodDetailsBloc}>
      <View style={styles.goodDetails}>
        <View style={styles.details}>
          <GoodDetail
            icon={IconTypeEnum.ROOM}
            label="Nbr de pièces"
            value={addSpaceToNumber(props.roomCount)}
          />
          <GoodDetail
            icon={IconTypeEnum.FLOOR}
            label="Nbr de niveaux"
            value={addSpaceToNumber(props.floorCount)}
          />
          <GoodDetail
            icon={IconTypeEnum.HOUSE}
            label="Typologie du bien"
            value={goodType}
          />
          <GoodDetail
            icon={IconTypeEnum.AREA}
            label="Surface habitable"
            value={`${addSpaceToNumber(props.homeArea)} m²`}
          />
          {props.goodTypology !== 'apartment' && (
            <GoodDetail
              icon={IconTypeEnum.LANDAREA}
              label="Surface du terrain"
              value={`${addSpaceToNumber(props.landArea)} m²`}
            />
          )}
        </View>
      </View>
      <View style={styles.commentBloc}>
        <Text style={styles.bold}>Commentaires sur le bien :</Text>
        <Text style={styles.comment}>{props.comment}</Text>
      </View>
    </View>
  );
}

export default GoodDetails;

const styles = StyleSheet.create({
  goodDetailsBloc: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  goodDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '50%',
  },
  details: { width: '100%' },
  commentBloc: {
    marginTop: '-30px',
    padding: '0 10px',
    width: '50%',
    height: '181px',
    fontSize: '12px',
    backgroundColor: '#f1f1f7',
  },
  comment: {
    overflow: 'hidden',
  },
  bold: {
    fontWeight: 700,
    marginBottom: '5px',
  },
});
