import styles from '../geography.module.scss';

interface IBuildingInfoProps {
  building: IBuilding;
  index: number;
}

export default function BuildingRow({ building, index }: IBuildingInfoProps) {
  return (
    <div className={styles.buildingRow}>
      <div className={styles.rowIndex}>
        <span className={index > 9 ? styles.maxRound : ''}>{index}</span>
      </div>
      <ul className="building">
        {building.use1 && (
          <li>
            {`Usage du bâti : `}
            <span>{building.use1}</span>
          </li>
        )}
        {building.height && building.height !== 0 ? (
          <li>
            {`Hauteur estimée du bâti : `}
            <span>
              {`${building.height} m `}
              <span className="height-error-info">
                (avec une marge d’erreur estimée à 1,50m au maximum)
              </span>
            </span>
          </li>
        ) : (
          ''
        )}
        {building.stageCount && building.stageCount > 0 ? (
          <li>
            {`Nombre d'étages estimés : `}
            <span>{building.stageCount}</span>
          </li>
        ) : (
          ''
        )}
        {building.houseCount && building.houseCount > 0 && !building.nbLogements ? (
          <li>
            {`Nombre de logements estimés : `}
            <span>{building.houseCount}</span>
          </li>
        ) : (
          ''
        )}
        {building.anneeConstructionBatiment &&
        building.anneeConstructionBatiment > 0 ? (
          <li>
            {`Année construction bâtiment : `}
            <span>{building.anneeConstructionBatiment}</span>
          </li>
        ) : (
          ''
        )}
        {building.nbLotGarpark && building.nbLotGarpark > 0 ? (
          <li>
            {`Nombre de lots garage/parking : `}
            <span>{building.nbLotGarpark}</span>
          </li>
        ) : (
          ''
        )}
        {building.nbLotTotal && building.nbLotTotal > 0 ? (
          <li>
            {`Nombre de lots total : `}
            <span>{building.nbLotTotal}</span>
          </li>
        ) : (
          ''
        )}
        {building.nbLotTertiaire && building.nbLotTertiaire > 0 ? (
          <li>
            {`Nombre de lots tertiaire : `}
            <span>{building.nbLotTertiaire}</span>
          </li>
        ) : (
          ''
        )}
        {building.nbLogements && building.nbLogements > 0 ? (
          <li>
            {`Nombre de logements : `}
            <span>{building.nbLogements}</span>
          </li>
        ) : (
          ''
        )}
        {building.nomCopro && (
          <li>
            {`Nom de copropriété : `}
            <span>{building.nomCopro}</span>
          </li>
        )}
      </ul>
    </div>
  );
}
