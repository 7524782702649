import LineGraph from './Graphs/LineGraph';
import { labelsLine } from './utils';
interface ILineGraphBlocProps {
  performanceActivity: ReportingIndividualUserPerformanceActivity | null;
}

function LineGraphBloc({ performanceActivity }: ILineGraphBlocProps): JSX.Element {
  const datasetsLine = [
    {
      label: 'Actions de prospection',
      fill: false,
      backgroundColor: '#d29c11',
      borderColor: '#d29c11',
      borderDash: [5, 5],
      data: performanceActivity?.proceduresCounter,
    },
    {
      label: "Opportunités à l'étude",
      fill: false,
      backgroundColor: '#4e55a2',
      borderColor: '#4e55a2',
      data: performanceActivity?.plotStudiesCounter,
    },
  ];
  return (
    <div className="line-graph">
      <h4>Évolution performance activité prospection:</h4>
      {performanceActivity && labelsLine && (
        <LineGraph labels={labelsLine} datasets={datasetsLine} />
      )}
    </div>
  );
}

export default LineGraphBloc;
