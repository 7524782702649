import * as Yup from 'yup';
import { transformNumber } from '../../../shared/utils/yupSchema';

export const initialForm: IBuildPermitsSearchForm = {
  houseMin: 0,
  houseMax: 0,
};

export const validationSchemaHouseForm = Yup.object({
  houseMin: Yup.number()
    .transform(transformNumber)
    .min(0, 'Nombre négatif interdit')
    .when('houseMax', {
      is: (val: number | undefined) => val !== undefined && val !== 0,
      then: () =>
        Yup.number()
          .transform(transformNumber)
          .min(0, 'Nombre négatif interdit')
          .max(Yup.ref('houseMax'), 'Min doit être inférieur à max'),
    }),
  houseMax: Yup.lazy((value) => {
    const checkedValue = value ? value : 0;
    switch (checkedValue) {
      case 0:
        return Yup.number()
          .transform(transformNumber)
          .min(0, 'Nombre négatif interdit');

      default:
        return Yup.number()
          .transform(transformNumber)
          .min(0, 'Nombre négatif interdit')
          .when('houseMin', {
            is: (val: number | undefined) => val !== undefined && val !== 0,
            then: () =>
              Yup.number()
                .transform(transformNumber)
                .min(0, 'Nombre négatif interdit')
                .min(Yup.ref('houseMin'), 'Max doit être supérieur à min'),
          });
    }
  }),
});
