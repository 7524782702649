import { StyleSheet, View } from '@react-pdf/renderer';
import { dateFormat } from '../../../../../lib/formats/dataFormat';
import { addSpaceToNumber } from '../../../../../utils/jsFunctions';
import ComparativeStudiesTableCell from './ComparativeStudiesTableCell';
import ImageContainer from './ImageContainer';

interface IComparativeStudiesTableRowProps {
  row: FormatedProspecDataAd;
}

function ComparativeStudiesTableRow({ row }: IComparativeStudiesTableRowProps) {
  return (
    <View style={styles.tableRow}>
      <ImageContainer height="30px" width="50px" localImg={row.picture ?? null} />
      <ComparativeStudiesTableCell item={`${row.area} m²`} />
      <ComparativeStudiesTableCell item={row.city} flex />
      <ComparativeStudiesTableCell item={`${addSpaceToNumber(row.price)} €`} />
      <ComparativeStudiesTableCell item={row.origin} />
      <ComparativeStudiesTableCell item={dateFormat(row.publishedAt)} />
    </View>
  );
}

export default ComparativeStudiesTableRow;

const styles = StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: '3px',
  },
});
