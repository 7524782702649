import { convertImageToBase64 } from '../../../shared/utils/imageTools';
import { pdfPlotBuilder } from '../shared/utils/dataBuilders';
import { getLandAreaTypeFromNumber } from './orpiLandDataParser';

const prospecAdsParse = async (
  tab: SimilarType[] | null,
  selected: string[]
): Promise<FormatedProspecDataAd[] | null> => {
  if (!tab) return null;
  const selecteds = tab.filter((f) => selected.includes(f.id.toString()));

  const parsed = await Promise.all(
    selecteds.map(async (m) => {
      const img = await convertImageToBase64(m.picture);
      return {
        area: m.landArea,
        city: m.city,
        origin: m.label,
        price: m.price,
        publishedAt: m.publishedAt,
        picture: img,
      } as FormatedProspecDataAd;
    })
  );

  return parsed;
};
const formatedProspecDatas = async (
  comparableAds: ProspecGetSimilars | null,
  adsStats: ProspecGetAdsStats | null,
  landType: LandAreaType | null,
  formDatas: IExternalPdfLandForm
): Promise<FormatedProspecDatasType> => {
  const cheaperAds = await prospecAdsParse(
    comparableAds?.cheaper?.list ?? null,
    formDatas.cheaperAds
  );
  const equalAds = await prospecAdsParse(
    comparableAds?.equal?.list ?? null,
    formDatas.equalAds
  );
  const higherAds = await prospecAdsParse(
    comparableAds?.higher?.list ?? null,
    formDatas.higherAds
  );
  return {
    type: landType ?? getLandAreaTypeFromNumber(formDatas.landArea ?? 0),
    adsStats: adsStats,
    similariesError: Boolean(!cheaperAds && !equalAds && !higherAds),
    cheaperAds,
    equalAds,
    higherAds,
  };
};

interface IOrpiLandPdfBuilderProps {
  plot: IPlot;
  plotsPdfs: MultiplotsPdfs | null;
  pythonDatas: DatasForPdfGeneration;
  servitudes: OrpiServitudes;
  formDatas: IExternalPdfLandForm;
  phFolder: PDFLandDeal | null;
  phDatas: PDFLandPricehubbleDataType | null;
  unemploymentRateChart: string | null;
  activePopulationChart: string | null;
  prospecComparableAds: ProspecGetSimilars | null;
  prospecAdsStats: ProspecGetAdsStats | null;
  prospecLandType: LandAreaType | null;
  avgPriceChart: string | null;
  avgPriceAreaChart: string | null;
}

async function orpiLandPdfBuilder({
  plot,
  plotsPdfs,
  pythonDatas,
  servitudes,
  formDatas,
  phFolder,
  phDatas,
  unemploymentRateChart,
  activePopulationChart,
  prospecComparableAds,
  prospecAdsStats,
  prospecLandType,
  avgPriceChart,
  avgPriceAreaChart,
}: IOrpiLandPdfBuilderProps): Promise<PDFLandDataType | null> {
  try {
    const pDatas = await formatedProspecDatas(
      prospecComparableAds,
      prospecAdsStats,
      prospecLandType,
      formDatas
    );

    const build: PDFLandDataType = {
      pricehubbleDatas: phFolder && phDatas ? { ...phDatas, deal: phFolder } : null,
      formDatas,
      servitudes,
      pythonDatas,
      unemploymentRateChart,
      activePopulationChart,
      ...pdfPlotBuilder(
        {
          homePagePicture: pythonDatas?.pictures?.homePagePicture,
          parcelsOnCadastrePicture: pythonDatas?.pictures?.parcelsOnCadastrePicture,
          parcelsOnZonePicture: pythonDatas?.pictures?.parcelsOnZonePicture,
          builtHeight: pythonDatas.parcelsMaxBuildingsHeight,
        },
        plot,
        plotsPdfs
      ),
      prospecDatas: pDatas,
      avgPriceChart,
      avgPriceAreaChart,
    };

    return build;
  } catch (error) {
    return null;
  }
}

export default orpiLandPdfBuilder;
