import { TableBody } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import PaginationCustom from '../../components/Common/PaginationCustom/PaginationCustom';
import TableRowFullColumn from '../../components/Common/TableRowFullColumn';
import { genericEntitySort } from '../../shared/utils/sortTools';
import { procedureEntityNameDisplay } from '../procedures/utils';
import AgendaTableBodyRow from './AgendaTableBodyRow';
interface IAgendaTableBodyProps {
  procedures: Procedure[];
  order: Order;
  sortKey: string;
  pattern: string;
}

function AgendaTableBody({
  procedures,
  order,
  sortKey,
  pattern,
}: IAgendaTableBodyProps): JSX.Element {
  const [indexPage, setIndexPage] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const [displayedContent, setDisplayedContent] = useState<Procedure[]>();

  const displayPagination = (procedures?.length || 0) > itemsPerPage;

  useEffect(() => {
    const temp = genericEntitySort<Procedure>(procedures, {
      key: sortKey as keyof Procedure,
      noPriorityValues: ['-', ' - '],
      order,
    })?.filter((f: Procedure) => {
      const p = pattern.toLowerCase();

      return (
        f.eventDate?.includes(p) ||
        procedureEntityNameDisplay(f)?.toLowerCase().includes(p) ||
        f.entity?.city?.toLowerCase().includes(p) ||
        f.contact?.firstName?.toLowerCase().includes(p) ||
        f.contact?.lastName?.toLowerCase().includes(p) ||
        f.entity.status?.label.toLowerCase().includes(p) ||
        f.type?.label.toLowerCase().includes(p)
      );
    });

    setDisplayedContent(temp);
  }, [order, sortKey, pattern, procedures]);

  const handleChangeIndex = (index: number): void => {
    setIndexPage(index);
  };

  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    setItemsPerPage(e.target.value);
  };

  return (
    <TableBody>
      {procedures &&
        displayedContent
          ?.filter(
            (f: Procedure, i: number) =>
              i >= indexPage && i < indexPage + itemsPerPage
          )
          .map((procedure: Procedure) => (
            <AgendaTableBodyRow key={nanoid()} procedure={procedure} />
          ))}
      {displayPagination && (
        <TableRowFullColumn colSpan={6}>
          <PaginationCustom
            itemPerPageLabel="Rendez-vous par page"
            elementsQty={procedures?.length || 0}
            itemsPerPage={itemsPerPage}
            onItemsPerPageChange={handleItemsPerPageChange}
            onMinIndexTab={handleChangeIndex}
          />
        </TableRowFullColumn>
      )}
    </TableBody>
  );
}

export default AgendaTableBody;
