import { splitStrings, startNewParagraph } from './tools';

// Functions
let activeNumberedList = false;
/**
 * inserts simple string with attributes
 * @param {string} text
 * @param {ParsedQuillDelta} parsed
 * @param {RunAttributes} attributes
 */
function insertSimpleString(
  text: string,
  parsed: ParsedLetter,
  attributes?: RunAttributes
) {
  if (attributes) {
    parsed.paragraphs[parsed.paragraphs.length - 1].textRuns?.push({
      text: text,
      attributes: attributes,
    });
  } else {
    parsed.paragraphs[parsed.paragraphs.length - 1].textRuns?.push({
      text: text,
    });
  }
}

/**
 * inserts a new paragraph and applies line formatting
 * @param {LetterOp} op - model data image row
 * @param {ParsedLetter} parsed - parsed model datas
 */
function insertNewline(op: LetterOp, parsed: ParsedLetter) {
  // if line attributes, apply those to the previous paragraph
  if (op.attributes) {
    parsed.paragraphs[parsed.paragraphs.length - 1].attributes = op.attributes;
    if (op.attributes.list === 'ordered') {
      // if already an active numbered list
      if (activeNumberedList) {
        // do not increment
        // leave active list true
      } else {
        // incrememnt
        // set active to true
        parsed.numberedLists++;
        activeNumberedList = true;
      }
    } else {
      activeNumberedList = false;
    }
  }
  startNewParagraph(parsed);
}

/**
 * inserts text with intermixed newlines and run attributes
 * @param {LetterOp} op - model data image row
 * @param {ParsedLetter} parsed - parsed model datas
 */
function insertText(op: LetterOp, parsed: ParsedLetter) {
  if (parsed.paragraphs.length === 0) {
    startNewParagraph(parsed);
  }
  // if it contains newline characters
  if ((op.insert as string).match(/\n/)) {
    const strings = splitStrings(op.insert as string);
    for (const text of strings) {
      if (text === '\n') {
        startNewParagraph(parsed);
        activeNumberedList = false;
      } else {
        insertSimpleString(text, parsed);
      }
    }
  } else {
    insertSimpleString(op.insert as string, parsed, op.attributes);
  }
}

/**
 * image delta row parser
 * @param {LetterOp} op - model data image row
 * @param {ParsedLetter} parsed - parsed model datas
 */
function insertEmbedParagraph(op: LetterOp, parsed: ParsedLetter) {
  parsed.paragraphs.push({
    embed: op.insert as InsertEmbed,
  });
  activeNumberedList = false;
  startNewParagraph(parsed);
}

/**
 * Quill deltas parser
 * @param {LetterOp} op - model data row
 * @param {ParsedLetter} parsed - parsed model datas
 */
function parseOp(op: LetterOp, parsed: ParsedLetter) {
  // handle images
  if ((op.insert as InsertEmbed).image) {
    insertEmbedParagraph(op, parsed);
    // handle exclusive newlines
  } else if (op.insert === '\n') {
    insertNewline(op, parsed);
    // handle text and text with newlines intermixed
  } else {
    insertText(op, parsed);
  }
}

/**
 * Letter parser
 * @param {Letter} quill - model datas to parse
 * @returns {ParsedQuillDelta} - parsed quills
 */
function parseLetter(letter: Letter): ParsedLetter {
  activeNumberedList = false;
  const parsed: ParsedLetter = {
    paragraphs: [],
    numberedLists: 0,
  };

  // transform ops in paragraphs
  for (const op of letter.ops) {
    parseOp(op, parsed);
  }
  return parsed;
}

function parseLetters(letters: Letters) {
  let numberedLists = 0;
  const parsedLetters: LetterParagraphs[] = [];

  letters.forEach((e) => {
    const temp = parseLetter(e);
    numberedLists += temp.numberedLists;
    parsedLetters.push(temp.paragraphs);
  });

  return { letters: parsedLetters, numberedLists } as ParsedLetters;
}

export default parseLetters;
