import createCtx from '../../../../../App/contexts/GenenricStateContext';
import AddFormDisplay from '../../../../../components/RightPanelModal/TabsContent/bodys/components/prospection/AddFormDisplay';
import StudyProcedureForm from './StudyProcedureForm';
import StudyProcedureList from './StudyProcedureList';
import './procedure.scss';

interface IProcedureContentProps {
  studyIdIri: string | null;
  updateAllowed: boolean;
}

const [ctx, OpenFormProvider] = createCtx(false);
export const ForceOpenContext = ctx;

export default function ProcedureContent({
  studyIdIri,
  updateAllowed,
}: IProcedureContentProps): JSX.Element {
  return (
    <div className="procedure-content">
      <OpenFormProvider>
        <StudyProcedureList updateAllowed={updateAllowed} />

        {updateAllowed && (
          <AddFormDisplay
            labelOpen="Ajouter une démarche"
            labelClose="Masquer le formulaire"
            ForceOpenContext={ForceOpenContext}
          >
            <StudyProcedureForm />
          </AddFormDisplay>
        )}
      </OpenFormProvider>
    </div>
  );
}
