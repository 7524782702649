import { isEmpty } from 'lodash';

export const questions = (
  type: 'agent' | 'prospector',
  studyContact: IStudyContact,
  address: string,
  parcelle: IPlot,
  user: User,
  company: Company,
  companyName: string
) => {
  const contactFullName = `${studyContact.contact.firstName ?? ''} ${
    studyContact.contact.lastName
  }`;
  switch (type) {
    case 'agent':
      return `Pourrais-tu m'écrire un courrier de prospection destiné à ${contactFullName}${
        address ? `, qui habite au ${address}` : ''
      }, dans un bien situé sur la parcelle ${parcelle?.parcelleId.substring(
        5
      )}? Il faut que le courrier précise que je m'appelle ${
        user?.fullName
      }, et que je souhaite le contacter au nom de ma société ${companyName}${
        isEmpty(company?.firm) ? '' : ` du réseau ${company?.firm}`
      } afin d'évaluer son bien et de lui apporter mon expertise en tant qu’agent immobilier pour l’accompagner dans la valorisation de son bien. Il faut préciser que nombreux propriétaires ont fait confiance à notre agence, et que des clients nous ont fait part de leur intérêt pour acheter un bien dans leur quartier. Le ton du courrier doit être professionnel.`;
    case 'prospector':
    default:
      return `Pourrais-tu m'écrire un courrier de prospection destiné à ${contactFullName}${
        address ? `, qui habite au ${address}` : ''
      }, sur la parcelle ${parcelle?.parcelleId.substring(5)} d’une surface de ${
        parcelle?.theoricCapacity
      } m²? Il faut que le courrier précise que je m'appelle ${
        user?.fullName
      }, et que je souhaite le contacter pour le compte de ma société ${companyName}${
        isEmpty(company?.firm) ? '' : ` du réseau ${company?.firm}`
      } afin d'évaluer son bien et de lui apporter mon expertise en tant que prospecteur foncier pour l’accompagner dans la valorisation de son terrain. Il faut préciser que les projets immobiliers permettent généralement une meilleure valorisation des biens. Le ton du courrier doit être professionnel.`;
  }
};
