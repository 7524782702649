import MobileAppDownload from '../components/MobileAppDownload';
import PanelContainer from '../components/PanelContainer';
import useFolder from '../features/folders/useFolder';
import FullScreenLoader from '../features/loaders/fullScreenLoader';
import ModalContainer from '../features/modals/ModalContainer';
import useUsers from '../features/users/useUsers';
import useInseeChangeHandle from '../shared/hooks/useInseeChangeHandle';
import Layout from './Layout';
import RouteManager from './routes/RouteManager';

const App = () => {
  useInseeChangeHandle();
  useUsers({ load: true });
  useFolder({ noLoadFiltered: true });
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    window.location = 'urbanease://' as any;
  }

  return (
    <div className="app">
      <Layout>
        <MobileAppDownload />

        <RouteManager />

        <FullScreenLoader />
        <ModalContainer />
        <PanelContainer />
      </Layout>
    </div>
  );
};

export default App;
