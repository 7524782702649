import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { studyContactsApiToStoreParser } from '../utils/parsers/studyContactParser';

/**
 * Fetch study contacts
 * @param {string|null} studyIdIri - identifier of the study, to which the procedures are attached
 * @param {string|null} companyId - company id of the user
 * @param {ContactRoles|null} contactRoles - contactRole list
 * @returns {Promise<StudyContacts>} list of parsed StudyContacts
 */
export const fetchStudyContacts = async (
  studyIdIri: string | null,
  companyId: number | null,
  contactRoles: ContactRoles | null
) => {
  try {
    // check params
    if (!studyIdIri) throw new Error('Study id not found');
    if (!companyId) throw new Error('Company id not found');
    if (!contactRoles) throw new Error('ContactRoles not found');

    //request
    const studyContacts = await fetchDatas(`${studyIdIri}/contacts`, {
      company_id: companyId,
    });

    // parse
    const parsedResult = studyContactsApiToStoreParser(
      studyContacts['hydra:member'] ?? [],
      contactRoles
    );

    return parsedResult;
  } catch (error) {
    return Promise.reject(error);
  }
};
