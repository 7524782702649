import ButtonGeneric from '../../../../../shared/components/buttons/buttonGeneric/ButtonGeneric';
import styles from '../../../styles/mailshotAccountTabContent.module.scss';
import { MailshotTagEnum } from '../../../types/mailshotsEnums';
import { ITagBtnClickProps } from './TagButtonContainer';

function ContactContent({ onBtnClick }: ITagBtnClickProps) {
  const color: ColorType = 'GREEN';
  const handleClick = (tag: MailshotTagEnum) => {
    onBtnClick(tag, color);
  };

  return (
    <div className={styles.btnContainer}>
      <ButtonGeneric
        content="Nom contact"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.NOM_CONTACT)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Prénom contact"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.PRENOM_CONTACT)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Numéro de rue"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.CONTACT_ADRESSE_1)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Entrée/Bâtiment"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.CONTACT_ADRESSE_2)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Rue de l'adresse"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.CONTACT_ADRESSE_3)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="BP ou lieu-dit"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.CONTACT_ADRESSE_4)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Code postal"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.CONTACT_CODE_POSTAL)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Ville"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.CONTACT_VILLE)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Pays"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.CONTACT_PAYS)}
        containerClassName={styles.button}
      />
    </div>
  );
}

export default ContactContent;
