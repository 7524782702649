import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import SyntheseFolderRow from './SyntheseFolderRow';

interface ISyntheseFolderTableProps {
  plotsData: ISyntheseTableRow[] | undefined;
}

export default function SyntheseFolderTable({
  plotsData,
}: ISyntheseFolderTableProps) {
  return (
    <TableContainer component={Paper} className="plot-synthese-table">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Ville</TableCell>
            <TableCell>Adresse</TableCell>
            <TableCell>N° de parcelle</TableCell>
            <TableCell>Taille parcelle</TableCell>
            <TableCell>Emprise au sol du bâti</TableCell>
            <TableCell>Zonage PLU</TableCell>
            <TableCell>Responsable</TableCell>
            <TableCell>Etat</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plotsData?.map((st: ISyntheseTableRow) => (
            <SyntheseFolderRow key={uuidv4()} row={st} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
