import { useAppDispatch, useAppSelector } from '../../../../App/store';
import DragWindow from '../../../../shared/components/dragWindow/DragWindow';
import { getMailshotsState, mailshotsActions } from '../../mailshotsSlice';
import styles from '../../styles/mailshots.module.scss';
import MailshotDragWindowSelectContent from './MailshotDragWindowSelectContent';

function MailshotDragWindow() {
  const { selectStaged } = useAppSelector(getMailshotsState);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(mailshotsActions.resetWithoutModels());
  };
  return (
    <DragWindow
      title={`Publipostage - ${!selectStaged ? 'sélection' : 'édition'}`}
      onClose={handleClose}
    >
      <div className={styles.mailShotDragWindow}>
        <MailshotDragWindowSelectContent />
      </div>
    </DragWindow>
  );
}

export default MailshotDragWindow;
