import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { fetchAllFolderPlotStudiesForDisplayThunk } from './services/thunks/fetchAllFolderAndSubsPlotStudiesForDisplayThunk';
import { fetchFolderAndSubsPlotStudiesForDisplayThunk } from './services/thunks/fetchFolderAndSubsPlotStudiesForDisplayThunk';

const initialState: EntitiesDisplayState = {
  entities: { apiStatus: APIStatus.IDLE, result: [] },
  displayedFolders: [],
  entitiesBackup: [],
  foldersBackup: [],
  allFoldersDisplayed: false,
  allSectorsDisplayed: false,
  isFoldersPlotGeomLoading: false,
};

const displayManagerSlice = createSlice({
  name: 'entitiesDisplay',
  initialState,
  reducers: {
    reset: () => initialState,
    // sectors and subs or folders and subs (plotStudies)
    entitiesAdd: (state, action: PayloadAction<EntitiesDisplay>) => {
      state.entities.result = state.entities.result.concat(action.payload);
    },
    // when update sector géometry
    entityUpdate: (state, action: PayloadAction<EntityDisplay>) => {
      const replace = (t: EntitiesDisplay, s: EntityDisplay) =>
        t.map((m) => (m.idIri === s.idIri ? s : m));
      if (isEmpty(state.entitiesBackup)) {
        state.entities.result = replace(state.entities.result, action.payload);
      } else {
        state.entitiesBackup = replace(state.entitiesBackup, action.payload);
      }
    },
    entityResponsableColorUpdate: (
      state,
      action: PayloadAction<{ idIri: string; color: string }>
    ) => {
      const ap = action.payload;
      state.entities.result = state.entities.result?.map((m) =>
        m.idIri === ap.idIri ? { ...m, color: ap.color } : m
      );
      state.entitiesBackup = state.entitiesBackup?.map((m) =>
        m.idIri === ap.idIri ? { ...m, color: ap.color } : m
      );
    },
    folderEntitiesAdd: (
      state,
      action: PayloadAction<{
        entities: EntitiesDisplay;
        folderIdIris: string[];
      }>
    ) => {
      state.entities.result = state.entities.result.concat(action.payload.entities);
      state.displayedFolders = state.displayedFolders.concat(
        action.payload.folderIdIris
      );
    },
    folderEntitiesGeomAdd: (
      state,
      action: PayloadAction<{
        entities: EntitiesDisplay;
        folderIdIris: string[];
      }>
    ) => {
      // remove entities with no geom
      let entities = state.entities.result.filter(
        (f) => !action.payload.folderIdIris.includes(f.idIri)
      );
      // add entities with geom
      entities = entities.concat(action.payload.entities);
      state.entities.result = entities;
      state.displayedFolders = state.displayedFolders.concat(
        action.payload.folderIdIris
      );
    },
    // in right panel display case (for the moment)
    entitiesBackupAndReplace: (state, action: PayloadAction<EntitiesDisplay>) => {
      state.entitiesBackup = [...state.entities.result];
      state.foldersBackup = state.displayedFolders;
      state.entities.result = action.payload;
      state.displayedFolders = [];
      state.entities.error = undefined;
    },
    backupReplace: (state) => {
      state.entities.result = state.entitiesBackup;
      state.displayedFolders = state.foldersBackup;
      state.entitiesBackup = [];
      state.foldersBackup = [];
    },
    // for sector array (parent + subs)
    entitiesRemoveByIdIri: (state, action: PayloadAction<string[]>) => {
      state.entities.result = state.entities.result.filter(
        (f) => !action.payload.includes(f.idIri)
      );
    },
    // for folder or folder + subs (group of plot studies)
    entitiesRemoveByParent: (state, action: PayloadAction<string[]>) => {
      state.entities.result = state.entities.result.filter((f) =>
        f.parent ? !action.payload.includes(f.parent) : true
      );
      state.displayedFolders = state.displayedFolders.filter(
        (f) => !action.payload.includes(f)
      );
    },
    // all sectors or all folders
    entitiesRemoveByType: (state, action: PayloadAction<StudyParamsType>) => {
      switch (action.payload) {
        case 'sector': {
          state.allSectorsDisplayed = false;
          state.entities.result = state.entities.result.filter(
            (f) => f.type !== 'sector'
          );
          break;
        }
        case 'folder':
        default: {
          state.allFoldersDisplayed = false;
          state.entities.result = state.entities.result.filter(
            (f) => f.type !== 'folder'
          );
          state.displayedFolders = [];
          break;
        }
      }
    },
    allSectorsAdd: (state, action: PayloadAction<EntitiesDisplay>) => {
      const temp = state.entities.result.filter((f) => f.type !== 'sector');
      state.entities.result = temp.concat(action.payload);
      state.allSectorsDisplayed = true;
    },
    setIsFoldersPlotGeomLoading: (state, action: PayloadAction<boolean>) => {
      state.isFoldersPlotGeomLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFolderAndSubsPlotStudiesForDisplayThunk.pending, (state) => {
        state.entities.apiStatus = APIStatus.PENDING;
      })
      .addCase(fetchFolderAndSubsPlotStudiesForDisplayThunk.fulfilled, (state) => {
        state.entities.apiStatus = APIStatus.IDLE;

        state.entities.error = undefined;
      })
      .addCase(
        fetchFolderAndSubsPlotStudiesForDisplayThunk.rejected,
        (state, action) => {
          state.entities.apiStatus = APIStatus.REJECTED;
          state.entities.error = action.error;
        }
      );
    builder
      .addCase(fetchAllFolderPlotStudiesForDisplayThunk.pending, (state) => {
        state.allFoldersDisplayed = true;
        state.isFoldersPlotGeomLoading = true;
        // remove folder plotStudies :
        const temp = state.entities.result.filter((f) => f.type !== 'folder');
        state.entities.result = temp;
        state.entities.apiStatus = APIStatus.PENDING;
      })
      .addCase(fetchAllFolderPlotStudiesForDisplayThunk.fulfilled, (state) => {
        state.entities.apiStatus = APIStatus.IDLE;
        state.entities.error = undefined;
      })
      .addCase(
        fetchAllFolderPlotStudiesForDisplayThunk.rejected,
        (state, action) => {
          state.entities.apiStatus = APIStatus.REJECTED;
          state.entities.error = action.error;
          state.isFoldersPlotGeomLoading = false;
        }
      );
  },
});

export default displayManagerSlice.reducer;
export const displayManagerActions = displayManagerSlice.actions;
export const getDisplayManagerState = (state: RootState) => state.displayManager;
