import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { studyProceduresApiToStoreParser } from '../utils/parsers/studyProcedureParser';

/**
 * Fetch study procedures
 * @param {string|null} studyIdIri - identifier of the study, to which the procedures are attached
 * @param {string|null} companyId - company id of the user
 * @param {Users|null} users - company users
 * @param {ContactRoles|null} contactRoles - contactRole list
 * @param {ProcedureTypes|null} procedureTypes - procedureType list
 * @returns {Promise<StudyProcedures>} list of parsed studyProcedures
 */
export const fetchStudyProcedures = async (
  studyIdIri: string | null,
  companyId: number | null,
  users: Users | null,
  contactRoles: ContactRoles | null,
  procedureTypes: ProcedureTypes | null
): Promise<IStudyProcedure[]> => {
  try {
    // check params
    if (!studyIdIri) throw new Error('Study id not found');
    if (!companyId) throw new Error('Company id not found');
    if (!users) throw new Error('Users not found');
    if (!contactRoles) throw new Error('ContactRoles not found');
    if (!procedureTypes) throw new Error('ProcedureTypes not found');

    // request
    const result = await fetchDatas(`${studyIdIri}/procedures`, {
      company_id: companyId,
    });

    // parse
    const parsedResult = studyProceduresApiToStoreParser(
      result?.['hydra:member'] ?? [],
      users,
      contactRoles,
      procedureTypes
    );

    return parsedResult;
  } catch (error) {
    return Promise.reject(error);
  }
};
