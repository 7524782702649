import { Image, Text, View } from '@react-pdf/renderer';
import FooterBg from '../../../../../assets/images/orpiPdf/homeFooterBg.png';
import { pdfStyles } from '../styles/pdfStylesCommon';

interface IHomeFooterProps {
  user: {
    lastName: string;
    firstName: string;
    phone: string;
    email: string;
  };
  companyName: string;
}

function HomeFooterCommon({ user, companyName }: IHomeFooterProps) {
  return (
    <View style={pdfStyles.homeFooter}>
      <Image src={FooterBg} style={pdfStyles.footerBgImg} />
      <View style={pdfStyles.homeFooterImageSection} />

      <View style={pdfStyles.homeFooterLine} />
      <View style={pdfStyles.homeFooterTextSection}>
        <Text style={pdfStyles.homeFooterText}>Dossier suivi par :</Text>
        <View style={pdfStyles.homeFooterNameSection}>
          <Text
            style={pdfStyles.homeFooterNameText}
          >{`${user.lastName} ${user.firstName}`}</Text>
          <Text style={pdfStyles.homeFooterText}>{`  -  ${companyName}`}</Text>
        </View>
        <Text style={pdfStyles.homeFooterText}>{user.phone}</Text>
        <Text style={pdfStyles.homeFooterText}>{user.email}</Text>
      </View>
    </View>
  );
}

export default HomeFooterCommon;
