import IconContainer from './IconContainer';

function CrossIcon(props: ISharedIconsProps) {
  const { tooltipText } = props;

  return (
    <IconContainer {...props} tooltipText={tooltipText}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="4 4 16 16">
        <path d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
      </svg>
    </IconContainer>
  );
}

export default CrossIcon;
