import FormButtonContainer from '../../../../shared/components/buttons/formButtonContainer/FormButtonContainer';
import useMailshotsSelection from '../../hooks/useMailshotsSelection';
import styles from '../../styles/mailshots.module.scss';
import { MailshotsSelectionTypeEnum } from '../../types/mailshotsEnums';

function MailshotDragWindowSelectContent() {
  const { selectType, selectedListCount, selectionValidation, selectionCancel } =
    useMailshotsSelection();

  return (
    <div className={styles.selectContent}>
      <div className={styles.body}>
        {selectType === MailshotsSelectionTypeEnum.DASHBOARD_FOLDER && (
          <>
            <p className={styles.help}>
              Déroulez les dossiers et sélectionnez les parcelles de votre choix en
              cochant la case à gauche de la parcelle. Si une parcelle n&apos;est pas
              sélectionnable, c&apos;est parce qu&apos;elle ne dispose pas de contact
              permettant de générer un courrier.
            </p>
            {selectedListCount < 10 && (
              <p>Selctionnez jusqu&rsquo; à 10 parcelles maximum.</p>
            )}
            <p
              className={styles.selectPlotCount}
            >{`${selectedListCount} parcelles selectionnées.`}</p>
          </>
        )}
      </div>

      <FormButtonContainer
        validContent="Terminer"
        onValid={() => selectionValidation(true)}
        canCancel
        onCancel={selectionCancel}
        disableValid={selectedListCount === 0}
      />
    </div>
  );
}

export default MailshotDragWindowSelectContent;
