import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { usersApiToStoreParser } from '../usersParser';

/**
 * fetch all users of one company
 * @param {string} companyIdIri - Company id
 * @returns {Users} array of users
 */
async function usersFetch(companyIdIri: string) {
  try {
    // fetch users
    const result = await fetchDatas(`${companyIdIri}/users`);

    // parse users
    const parsedUsers = usersApiToStoreParser(result['hydra:member']);

    return parsedUsers;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default usersFetch;
