import { postEntityNew } from '../../../../../../services/axiosFiles/genericCrud';
import pricehubbleGenericParser from '../../../../../../shared/parsers/pricehubbleGenericParser';
import { transportsParser } from '../../../../landPDF/orpiLandDataParser';

interface IProps extends IPHTokenAndPHId {
  index: number;
  lat: string | number;
  lng: string | number;
  subcategory: string;
  callback: (value: string) => void;
}

async function fetchTransportsDatas({
  index,
  entityId,
  lat,
  lng,
  subcategory,
  phAccessToken,
  callback,
}: IProps) {
  try {
    if (index && entityId && lat && lng && subcategory) {
      // **** REAL BODY
      const body = {
        dossierId: entityId,
        lon: lng.toString(),
        lat: lat.toString(),
        category: 'transport',
        subcategory,
        radius: 20000,
        limit: 1,
        ph_access_token: phAccessToken,
      };

      const result: any = await postEntityNew({
        endpoint: '/pdf_urbanease/pricehubble_get_pois_datas',
        body,
      });
      const phPreParser = pricehubbleGenericParser(result);
      const parsed = transportsParser(phPreParser);
      callback(`Les données de transport ${index} sont chargées`);
      return parsed;
    } else {
      throw new Error('fetchTransportsDatas error');
    }
  } catch (error) {
    return null;
  }
}

export default fetchTransportsDatas;
