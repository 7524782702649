import CancelIcon from '../../../../../../assets/images/toolbar/cancel.svg';
import StartIcon from '../../../../../../assets/images/toolbar/start.svg';
import styles from '../measureToolbar.module.scss';

interface IStartSectionRowProps {
  text: string;
  onClick: () => void;
  isRed?: boolean;
}
function StartSectionRow({ text, onClick, isRed }: IStartSectionRowProps) {
  return (
    <li onClick={onClick}>
      <img src={text === 'Annuler' ? CancelIcon : StartIcon} />
      <p className={isRed ? styles.isRed : ''}>{text}</p>
    </li>
  );
}

export default StartSectionRow;
