import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import { fetchBrownfieldsThunk } from '../../features/brownfields/brownFieldsThunk';
import { getCompanyState } from '../../features/company/companySlice';
import { getMapState } from '../../features/map/mapSlice';
import fetchCityDatasLaucherThunk from '../../features/plot/activePlotFeature/services/thunks/fetchCityDatasLaucherThunk';
import { fetchPluOthersThunk, fetchPluUrlsThunk } from '../../features/plu/pluThunk';

/**
 * Monitor INSEE activity and initiate the loading of data related
 * to the change in INSEE.
 */
function useInseeChangeHandle() {
  // ***** local states *****
  const [activeInsee, setActiveInsee] = useState<string | null>(null);

  // ***** store selectors *****
  const { geolocDatas } = useAppSelector(getMapState);
  const { deniedZone } = useAppSelector(getAuthState);
  const { companyIdIri } = useAppSelector(getCompanyState);

  // ***** constants *****
  const dispatch = useAppDispatch();

  // ***** Side effects *****
  useEffect(() => {
    if (
      !deniedZone &&
      geolocDatas &&
      geolocDatas.inseeCode &&
      (!activeInsee || activeInsee !== geolocDatas?.inseeCode)
    ) {
      // save new insee
      setActiveInsee(geolocDatas.inseeCode);

      // load urls for leaflet plu layers
      dispatch(
        fetchPluUrlsThunk({
          department: geolocDatas.department,
          lat: geolocDatas.coordinates[0],
          lng: geolocDatas.coordinates[1],
        })
      );

      // load plu others mapbox url for leaflet
      dispatch(
        fetchPluOthersThunk({
          city: geolocDatas.city,
          postalCode: geolocDatas.postalCode,
          inseeCode: geolocDatas.inseeCode,
        })
      );

      // Load brownfields layers for leaflet
      dispatch(fetchBrownfieldsThunk({ insee: geolocDatas?.inseeCode }));

      // Load city datas
      dispatch(fetchCityDatasLaucherThunk({ inseeCode: geolocDatas?.inseeCode }));
    }

    // if no geoloc datas or no sincee code in map, reset active insee
    if (!geolocDatas || !geolocDatas?.inseeCode === null) {
      setActiveInsee(null);
    }
  }, [geolocDatas, deniedZone]);

  useEffect(() => {
    // if company changeApiKeyForStoreKey, reset active insee
    setActiveInsee(null);
  }, [companyIdIri]);
}

export default useInseeChangeHandle;
