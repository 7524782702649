import { TextField } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import PaginationCustom from '../../../components/Common/PaginationCustom/PaginationCustom';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { getCompanyState } from '../../company/companySlice';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { getContactsState } from '../contactsSlice';
import fetchContactsThunk from '../services/thunks/fetchContactsThunk';
import ContactTable from './ContactTable';
import styles from './contactSearchModal.module.scss';

interface IContactModalContentProps {
  onCloseModal: () => void;
}

function ContactModalContent({ onCloseModal }: IContactModalContentProps) {
  const [pattern, setPattern] = useState<string>('');
  const [datas, setDatas] = useState<{
    contacts: Contacts;
    pattern: string;
  }>({ contacts: [], pattern: '' });
  const [page, setPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const { companyIdIri } = useAppSelector(getCompanyState);
  const { contacts } = useAppSelector(getContactsState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchContactsThunk({ companyIdIri }));
  }, []);

  if (contacts.apiStatus === APIStatus.PENDING) {
    return (
      <div className={styles.loaderContent}>
        <h4>Chargement des contacts</h4>
        <IndividualCircularLoader size={150} />
      </div>
    );
  }
  const handlePatternChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // if (contacts.result) {
    if (value === '' || !contacts.result) {
      console.log('first');
      setDatas({ pattern: value, contacts: [] });
    } else {
      const fContacts = contacts.result.filter(
        (f) =>
          f.lastName?.toLowerCase().includes(value?.toLowerCase()) ||
          f.firstName?.toLowerCase().includes(value?.toLowerCase()) ||
          f.email?.toLowerCase().includes(value?.toLowerCase())
      );
      setDatas({ pattern: value, contacts: fContacts });
    }
    // }
  };

  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    const value = e.target.value as number;
    setItemsPerPage(value);
  };

  const handleIndexChange = (index: number): void => {
    const value = (Math.floor(index / itemsPerPage) + 1) as number;
    setPage(value);
  };

  return (
    <div className={styles.contactModalContent}>
      <TextField
        className={styles.patternField}
        placeholder="Rechercher"
        type="text"
        variant="outlined"
        fullWidth={true}
        value={datas.pattern ?? ''}
        onChange={handlePatternChange}
        required
      />
      <div className={styles.tableContainer}>
        <ContactTable
          contacts={
            datas.contacts?.filter(
              (c, i) => i >= (page - 1) * itemsPerPage && i < page * itemsPerPage
            ) ?? []
          }
          onCloseModal={onCloseModal}
        />
      </div>

      {(datas.contacts?.length ?? 0) > 0 && (
        <PaginationCustom
          elementsQty={datas.contacts?.length ?? 0}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemsPerPageChange}
          onMinIndexTab={handleIndexChange}
        />
      )}
    </div>
  );
}

export default ContactModalContent;
