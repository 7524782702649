import { useAppDispatch, useAppSelector } from '../../../App/store';
import {
  MAILSHOT_MAX_CONTACT_EDITION_COUNT,
  MAILSHOT_MAX_PLOT_EDITION_COUNT,
} from '../../../shared/constants';
import { getDuplicatesFromArray } from '../../../shared/utils/arrayTools';
import { getStudyState, studyActions } from '../../study/slices/studySlice';
import { getMailshotsState, mailshotsActions } from '../mailshotsSlice';
import fetchMailshotPlotsContactsThunk from '../services/thunks/fetchMailshotPlotsContactsThunk';
import { MailshotsSelectionTypeEnum } from '../types/mailshotsEnums';

function useMailshotsSelection() {
  const {
    selectType,
    selectStaged,
    selectedList,
    apiActionStatus,
    folderContacts,
    dragWindowDisplay,
    selectedModel,
  } = useAppSelector(getMailshotsState);
  const { studyPlotStudies, study, studyContacts } = useAppSelector(getStudyState);
  const dispatch = useAppDispatch();

  const initListAndContacts = (
    type: MailshotsSelectionTypeEnum,
    studyContacts?: StudyContacts | null
  ) => {
    dispatch(mailshotsActions.setSelectType(type));

    switch (type) {
      case MailshotsSelectionTypeEnum.DASHBOARD_FOLDER:
        dispatch(mailshotsActions.setDragWindowDisplay(true));
        break;
      case MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_PLOT:
        {
          const mailshotPlot: MailshotPlot = {
            ...(study.result as IPlotStudy),
            contacts: {
              list: studyContacts ?? [],
              duplicateContacts: studyContacts
                ? getDuplicatesFromArray(studyContacts.map((m) => m.contact.idIri))
                : [],
            },
          };
          dispatch(mailshotsActions.setPlotList([mailshotPlot]));
          dispatch(mailshotsActions.setSelectStaged(true));
        }
        break;
      case MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER:
        {
          const plotList: MailshotPlots =
            studyPlotStudies.result?.map((m) => ({
              ...m,
              contacts: { list: [], duplicateContacts: [] },
            })) ?? [];

          dispatch(
            mailshotsActions.initFolderMailshot({
              studyContacts: studyContacts ?? [],
              mailshotList: plotList,
            })
          );
        }
        break;
      default:
        break;
    }
  };
  const checkExistingContacts = () => {
    let allContacts: StudyContacts = [];
    if (
      selectType === MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER
    ) {
      // allContacts = (folderContacts ?? []).map((m) => m.contact);
      allContacts = folderContacts?.list ?? [];
    } else {
      allContacts = selectedList.map((m) => m.contacts.list).flat(1);
    }

    return allContacts;
  };
  // close drag windows and load plot contacts
  const selectionValidation = (value: boolean) => {
    dispatch(mailshotsActions.setSelectStaged(value));

    if (selectType === MailshotsSelectionTypeEnum.DASHBOARD_FOLDER) {
      if (value) {
        dispatch(fetchMailshotPlotsContactsThunk());
      } else {
        dispatch(mailshotsActions.setDragWindowDisplay(true));
      }
    } else {
      dispatch(mailshotsActions.setSelectType(MailshotsSelectionTypeEnum.NONE));
    }
  };

  const selectionCancel = () => {
    dispatch(mailshotsActions.resetWithoutModels());
  };

  const updateContactInActiveList = (contact: IStudyContact) => {
    dispatch(mailshotsActions.updateContactInActiveList(contact));
    if (studyContacts.result) {
      dispatch(studyActions.updateContactInStudyContacts(contact));
    }
  };

  const removePlotFromList = (mailshot: MailshotPlot) => {
    dispatch(mailshotsActions.removePlotStudyFromList(mailshot));
  };
  const resetSelection = () => {
    dispatch(mailshotsActions.resetWithoutModels());
  };

  return {
    study,
    selectType,
    selectStaged,
    selectedList,
    selectedListCount: selectedList.length,
    selectionValidation,
    selectionCancel,
    updateContactInActiveList,
    removePlotFromList,
    apiActionStatus,
    initListAndContacts,
    folderContacts,
    folderContactsCount: folderContacts?.list?.length ?? null,
    dragWindowDisplay,
    outofRangePlotEdit:
      (selectedList?.length ?? 0) > MAILSHOT_MAX_PLOT_EDITION_COUNT,
    outofRangeContactEdit:
      (folderContacts?.list?.length ?? 0) > MAILSHOT_MAX_CONTACT_EDITION_COUNT,
    resetSelection,
    selectedModel,
    checkExistingContacts,
  };
}

export default useMailshotsSelection;
