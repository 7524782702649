import styles from '../toolbars.module.scss';
import AccountMapBtn from './AccountMapBtn';

function TopRightToolbar() {
  return (
    <div className={[styles.rightToolbar, styles.top].join(' ')}>
      <AccountMapBtn />
    </div>
  );
}

export default TopRightToolbar;
