import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import fetchProspecAdsStatsThunk from './services/thunks/landPDF/fetchProspecAdsStatsThunk';
import fetchProspecCityIdThunk from './services/thunks/landPDF/fetchProspecCityIdThunk';
import fetchProspecSimilariesThunk from './services/thunks/landPDF/fetchProspecSimilariesThunk';

function extraReducerProspec(
  builder: ActionReducerMapBuilder<externalPdfErrialState>
) {
  builder
    .addCase(fetchProspecCityIdThunk.pending, (state) => {
      const reducer = state.prospecDatas.prospecCityId;
      reducer.apiStatus = APIStatus.PENDING;
      reducer.result = null;
      reducer.error = undefined;

      // state.prospecDatas.prospecCityId = reducer;
    })
    .addCase(
      fetchProspecCityIdThunk.fulfilled,
      (state, action: PayloadAction<string | null>) => {
        const reducer = state.prospecDatas.prospecCityId;
        reducer.apiStatus = APIStatus.IDLE;
        reducer.result = action.payload;
        reducer.error = undefined;
      }
    )
    .addCase(fetchProspecCityIdThunk.rejected, (state, action) => {
      const reducer = state.prospecDatas.prospecCityId;
      reducer.apiStatus = APIStatus.REJECTED;
      reducer.result = null;
      reducer.error = action.error;
    });
  builder
    .addCase(fetchProspecAdsStatsThunk.pending, (state) => {
      const reducer = state.prospecDatas.adsStats;
      reducer.apiStatus = APIStatus.PENDING;
      reducer.result = null;
      reducer.error = undefined;
    })
    .addCase(
      fetchProspecAdsStatsThunk.fulfilled,
      (state, action: PayloadAction<ProspecGetAdsStats | null>) => {
        const reducer = state.prospecDatas.adsStats;
        reducer.apiStatus = APIStatus.IDLE;
        reducer.result = action.payload;
        state.prospecDatas.landType = action.payload?.propertyCategory ?? null;
        reducer.error = undefined;
      }
    )
    .addCase(fetchProspecAdsStatsThunk.rejected, (state, action) => {
      const reducer = state.prospecDatas.adsStats;
      reducer.apiStatus = APIStatus.REJECTED;
      reducer.result = null;
      reducer.error = action.error;
    });
  builder
    .addCase(fetchProspecSimilariesThunk.pending, (state) => {
      const reducer = state.prospecDatas.comparableAds;
      reducer.apiStatus = APIStatus.PENDING;
      reducer.result = null;
      reducer.error = undefined;
    })
    .addCase(
      fetchProspecSimilariesThunk.fulfilled,
      (state, action: PayloadAction<ProspecGetSimilars | null>) => {
        const reducer = state.prospecDatas.comparableAds;
        reducer.apiStatus = APIStatus.IDLE;
        reducer.result = action.payload;
        reducer.error = undefined;
      }
    )
    .addCase(fetchProspecSimilariesThunk.rejected, (state, action) => {
      const reducer = state.prospecDatas.comparableAds;
      reducer.apiStatus = APIStatus.REJECTED;
      reducer.result = null;
      reducer.error = action.error;
    });
}

export default extraReducerProspec;
