import * as Cesium from 'cesium';

/**
 * Create Terrain provider for init Cesium.Viewer entity
 * @returns {Cesium.CesiumTerrainProvider} - default Cesium terrain provider
 */
export async function createCesiumTerrainProvider() {
  try {
    return await Cesium.createWorldTerrainAsync().then((terrainProvider) => {
      return terrainProvider;
    });
  } catch (error) {
    throw new Error('createCesiumTerrainProvider error');
  }
}

/**
 * Get height for a simple latitude / longitude position
 * @param {Cesium.CesiumTerrainProvider} terrain - Terrain provier fr Cesium Viewer
 * @param {number} lat - latitude
 * @param {number} lng - longitude
 * @returns {StreetViewLatLngType} - full position datas
 */
export async function getCesiumTerrainInfosFromLatLng(
  terrain: Cesium.TerrainProvider,
  lat: number,
  lng: number
) {
  try {
    const positions = [Cesium.Cartographic.fromDegrees(lng, lat)];

    // Query the terrain height at the given positions
    const height = await Cesium.sampleTerrainMostDetailed(terrain, positions).then(
      (result) => {
        return result[0].height;
      }
    );

    return { lat, lng, height };
  } catch (error) {
    throw new Error('getCesiumTerrainInfosFromLatLng error');
  }
}
