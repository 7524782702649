import * as G from 'geojson';
import L, { LatLng, LatLngTuple } from 'leaflet';
import { cloneDeep, isArray } from 'lodash';
import { getEntityAndSubsUniqueTab, sectorColorDisplay } from '.';
import { SECTOR_OWNER_COLOR } from '../../../shared/constants';

export const sectorsToEntitiesDisplay = (
  sectors: Sectors | ISector,
  userIdIri: string | null
) => {
  try {
    let entities: EntitiesDisplay = [];
    const sectorTab = isArray(sectors) ? sectors : [sectors];
    sectorTab.forEach((s) => {
      const sTab = getEntityAndSubsUniqueTab(s) as Sectors;
      const filteredSectors = sTab.filter((f) => f.wkb);
      const ent = filteredSectors.map((m) =>
        sectorToEntityDisplay(m as ISector, userIdIri)
      );
      entities = entities.concat(ent);
    });

    return entities;
  } catch (error) {
    throw new Error('sectorsToEntitiesDisplay error');
  }
};

export const sectorToEntityDisplay = (sector: ISector, userIdIri: string | null) => {
  try {
    const polygonBounds: LatLngTuple[] = (
      sector.wkb.features[0].geometry as G.Polygon
    ).coordinates[0].map((t: number[]) => [t[1], t[0]]);
    // c is not serialisable
    const c = L.polygon(polygonBounds).getBounds().getCenter();
    // need to parse c for store
    const center = { lat: c.lat, lng: c.lng };

    const isSub = Boolean(sector.parent);
    const parsed: EntityDisplay = {
      type: 'sector',
      idIri: sector.idIri,
      isSub,
      color: sectorColorDisplay(sector, userIdIri),
      index: sector.indexId as number,
      coordinates: center,
      polygon: cloneDeep(polygonBounds),
    };

    return parsed;
  } catch (error) {
    throw new Error('sectorToEntityDisplay error');
  }
};

export const SectorOwnerUpdateParser = (ss: EntitiesDisplay, sectorIdIi: string) =>
  ss.map((m) => (m.idIri === sectorIdIi ? { ...m, color: SECTOR_OWNER_COLOR } : m));
export const plotStudiesToEntitiesDisplay = (
  plotStudies: PlotStudies,
  color?: string
) => {
  try {
    const entities: EntitiesDisplay = plotStudies.map((ps, index) => {
      return plotStudyToEntityDisplay(ps, index + 1, color);
    });

    return entities;
  } catch (error) {
    return [];
  }
};

/**
 * Parse a plotStudy to an EntityDisplay
 * @param {IPlotStudy} ps PlotStudy
 * @param {number} index Index to display in  marker
 * @param {string?} color Optional color
 * @returns {EntityDisplay} Entity can be displayed by displayMannager
 */
export const plotStudyToEntityDisplay = (
  ps: IPlotStudy,
  index: number,
  color?: string
) => {
  try {
    let polygonBounds: LatLngTuple[] | null = null;

    // parse ps geometry to number[]
    if (ps.plotGeometry) {
      const geom = JSON.parse(ps.plotGeometry);
      polygonBounds = geom.coordinates[0][0].map((m: number[]) => [m[1], m[0]]);
    }

    // construct the entity
    const parsed: EntityDisplay = {
      type: 'folder',
      idIri: ps.idIri,
      color: color ?? ps.markerColor ?? '',
      parent: ps.parent.idIri,
      index: index,
      coordinates: { lat: ps.lat, lng: ps.lng } as LatLng,
      polygon: polygonBounds ?? undefined,
    };

    return parsed;
  } catch (error) {
    throw new Error('plotStudyToEntityDisplay error');
  }
};
