import { orpipdfDatasReduce } from './utils';

// build pdf part from landing page form
function formBuilder(form: IExternalPdfErrialForm) {
  const f = {
    address: {
      addressLine1: form.addressCity,
      addressLine2: form.addressStreet,
    },
    ownerName: form.IdentityOwnersOnDocument,
    user: {
      lastName: form.lastName,
      firstName: form.firstName,
      phone: form.phone,
      email: form.email,
    },
    companyName: form.companyName,
    goodDetails: {
      roomCount: form.numberOfRooms,
      floorCount: form.floorNumber,
      goodTypology: form.propertyType,
      homeArea: form.livingArea,
      landArea: form.landArea,
      comment: form.description,
    },
  };
  return f;
}
// build pdf part from python request
function pdfGenBuilder(pdfGen: DatasForPdfGeneration) {
  const p = {
    homePagePicture: pdfGen.pictures.homePagePicture,
    catnat: pdfGen?.cityEnvironmentDatas.catnat,
    contaminatedAreas: pdfGen?.contaminatedAreas,
    seismic: pdfGen.parcelsEnvironmentDatas.seismic,
    clay: pdfGen.parcelsEnvironmentDatas.clay,
    radon: pdfGen.parcelsEnvironmentDatas.radon,
    coastalErosion: pdfGen.parcelsEnvironmentDatas.coastalErosion,
    envConstraints: pdfGen.parcelsEnvironmentDatas.envConstraints,
    zonePlu: pdfGen.zonePlu,
    plotDatas: {
      parcelsOnCadastrePicture: pdfGen?.pictures?.parcelsOnCadastrePicture,
      parcelsOnZonePicture: pdfGen?.pictures?.parcelsOnZonePicture,
      builtHeight: pdfGen.parcelsMaxBuildingsHeight,
    },
    environmentRisksDetails: {
      pprm: pdfGen.cityEnvironmentDatas.pprm,
      pprn: pdfGen.cityEnvironmentDatas.pprn,
      pprt: pdfGen.cityEnvironmentDatas.pprt,
      azi: pdfGen.cityEnvironmentDatas.azi,
    },
  };
  return p;
}
// build pdf part from plot and multiplots
// this last builder return the global pdf value
export function pdfPlotBuilder(
  temp: {
    homePagePicture: string;
    parcelsOnCadastrePicture: string;
    parcelsOnZonePicture: string;
    builtHeight: number;
  },
  plot: IPlot,
  plotsPdfs: MultiplotsPdfs | null
) {
  let p;
  if (!plotsPdfs) {
    p = {
      plotIds: [plot.parcelleId.substring(5)],
      plotDatas: {
        ...temp,
        area: plot?.theoricCapacity as any,
        freeGroundArea: plot?.areaFree as any,
        builtArea: plot?.areaBuilt as any,
        recalculatedArea: plot?.realCapacity as any,
      },
    };
  } else {
    p = {
      plotIds: plotsPdfs?.map((item) => item.plotId),
      plotDatas: {
        ...temp,
        area: orpipdfDatasReduce(plotsPdfs, 'theoricCapacity'),
        freeGroundArea: orpipdfDatasReduce(plotsPdfs, 'areaFree'),
        builtArea: orpipdfDatasReduce(plotsPdfs, 'areaBuilt'),
        recalculatedArea: orpipdfDatasReduce(plotsPdfs, 'realCapacity'),
      },
    };
  }

  return p as { plotIds: string[]; plotDatas: PDFPlotDatas };
}

export function errialPDFDataBuilder(
  type: PdfType,
  form: IExternalPdfErrialForm,
  plot: IPlot,
  pdfGen: DatasForPdfGeneration,
  plotsPdfs: MultiplotsPdfs | null,
  servitudes: OrpiServitudes
): PDFDatasType {
  const temp = {
    homePagePicture: pdfGen.pictures.homePagePicture,
    parcelsOnCadastrePicture: pdfGen.pictures.parcelsOnCadastrePicture,
    parcelsOnZonePicture: pdfGen.pictures.parcelsOnZonePicture,
    builtHeight: pdfGen.parcelsMaxBuildingsHeight,
  };

  const final: PDFDatasType = {
    pdfType: type,
    date: new Date().toLocaleDateString(),
    localServitudes: servitudes,
    ...formBuilder(form),
    ...pdfGenBuilder(pdfGen),
    ...pdfPlotBuilder(temp, plot, plotsPdfs),
  };

  return final as PDFDatasType;
}
