import PriceStatInput from '../../shared/components/form/PriceStatInput';
import styles from '../styles/prospecForm.module.scss';
import ProspecPriceSqmDisplay from './ProspecPriceSqmDisplay';

interface IProps {
  title: string;
  name: string;
  // prospec value for sqm
  displaySqm?: number;
  // if sqm must be calculated from price and area
  hasCalculatedSqm?: boolean;
  disabled?: boolean;
  error?: boolean;
}
function PriceStatSection({
  title,
  name,
  displaySqm,
  disabled,
  error,
  hasCalculatedSqm,
}: IProps) {
  return (
    <section className={[styles.priceStat, error ? styles.error : ''].join(' ')}>
      <p className={styles.title}>{title}</p>
      <PriceStatInput name={name} disabled={disabled} />

      {(displaySqm || hasCalculatedSqm) && (
        <ProspecPriceSqmDisplay
          inputName={name}
          isCalculated={hasCalculatedSqm}
          value={displaySqm}
        />
      )}
    </section>
  );
}

export default PriceStatSection;
