import { fetchDataById } from '../../../services/axiosFiles/genericCrud';
import { authUserApiToStoreParser } from '../usersParser';

async function userFetch(userIdIri?: string) {
  try {
    if (!userIdIri) throw new Error('user id maram is missing');

    const response = await fetchDataById(userIdIri);

    const parsed = authUserApiToStoreParser(response);

    return parsed;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default userFetch;
