function ErrorNoAdjacentPlots({
  infoModalDisplay,
  selectionHasTwoPlots,
  noPlotSelected,
}: {
  infoModalDisplay: ErrialInfoModalType;
  selectionHasTwoPlots: boolean;
  noPlotSelected: boolean;
}) {
  const oneNoAdjacent =
    infoModalDisplay.plotIds?.filter((s) => s.length === 1) ?? [];
  const hasOnlyOneNoAdjacent = oneNoAdjacent.length === 1;
  const oneNoAdjacentToString = oneNoAdjacent.flat(1).join(', ');
  const hasOneOrMoreUniqueNoAdjacent = oneNoAdjacent.length > 1;

  const plotsIdsToString = infoModalDisplay.plotIds?.map(
    (t) => t.map((m) => m).join(', ') ?? ''
  );

  return (
    <>
      {!noPlotSelected ? (
        <>
          <p>
            {selectionHasTwoPlots
              ? 'Les deux parcelles sélectionnées ne sont pas adjacentes.'
              : hasOnlyOneNoAdjacent
              ? `La parcelle ${oneNoAdjacentToString} n'est pas adjacente aux autres.`
              : hasOneOrMoreUniqueNoAdjacent
              ? `Les parcelles ${oneNoAdjacentToString} ne sont pas ajacentes au reste de la selection.`
              : `Les groupes de parcelles (${plotsIdsToString?.join(
                  ') et ('
                )}) ne sont pas adjacents entre eux.`}
          </p>
          <p>Voulez vous quand même lancer la génération du PDF?</p>
        </>
      ) : (
        <p>
          Aucune parcelle est sélectionnée. <br />
          Veuiller en sélectionner au moins une.
        </p>
      )}
    </>
  );
}

export default ErrorNoAdjacentPlots;
