import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { GenericModalEnum } from '../../../../components/Modals/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import { companyUserApiToStoreParser } from '../../usersParser';
import updateCompanyUser, { UserUpdateResponse } from '../companyUserUpdate';

const companyUserUpdateThunk = createAsyncThunk(
  'company/fetchCompanyUser',
  async (params: Omit<IUpdateCompanyUserProps, 'companyIdIri'>, thunkOptions) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { companyIdIri } = state.company;

      const response: UserUpdateResponse = await updateCompanyUser({
        ...params,
        companyIdIri,
      });

      const parsed = companyUserApiToStoreParser(response);

      return parsed;
    } catch (error) {
      thunkOptions.dispatch(
        modalsActions.alert({
          status: true,
          context: 'updateCompanyUserError',
          modalType: GenericModalEnum.ERROR,
        })
      );
      return Promise.reject(error);
    }
  }
);

export default companyUserUpdateThunk;
