import {
  DateView,
  MobileDatePicker,
  PickersActionBarAction,
  PickersLocaleText,
  frFR,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import frLocale from 'date-fns/locale/fr';
import './datePickerCustom.scss';

interface IDatePickerCustom {
  value: Date | null | undefined;
  onChange: (e: Date | null) => void;
  views?: readonly DateView[] | undefined;
  format: string;
  setError?: boolean;
  minDate?: Date;
  maxDate?: Date;
  actions?: PickersActionBarAction[];
  hasDay?: boolean;
}

const customFrFRLocaleText: Partial<PickersLocaleText<Date>> = {
  ...frFR.components.MuiLocalizationProvider.defaultProps.localeText,
  okButtonLabel: 'OK',
  cancelButtonLabel: 'Annuler',
  clearButtonLabel: 'Effacer',
};

function DatePickerCustom({
  value,
  onChange,
  views,
  format,
  setError,
  minDate,
  maxDate,
  hasDay,
  actions = ['cancel', 'clear', 'accept'],
}: IDatePickerCustom): JSX.Element {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={frLocale}
      localeText={customFrFRLocaleText}
    >
      <MobileDatePicker
        defaultValue={null}
        value={value}
        views={hasDay ? ['month', 'year', 'day'] : ['month', 'year']}
        minDate={minDate}
        maxDate={maxDate}
        onChange={(date: Date | null) => {
          onChange(date);
        }}
        format={format}
        slotProps={{
          toolbar: {
            toolbarFormat: format,
            hidden: false,
            toolbarPlaceholder: 'Date',
          },
          actionBar: { actions: actions },
          textField: {
            error: setError,
            placeholder: 'Date',
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default DatePickerCustom;
