import { useAppSelector } from '../../App/store';
import AddUserModal from '../../components/Modals/AddUserModal/AddUserModal';
import AddUserResultModal from '../../components/Modals/AddUserModal/AddUserResultModal';
import SubscriptionLimitModal from '../company/subscription/SubscriptionLimitModal';
import PasswordChangeResultModal from '../users/PasswordChangeResultModal';
import { getModalsState } from './modalsSlice';

function MyAccountModalContainer() {
  const {
    addUser,
    addUserResult,
    passwordChangeResult,
    subscriptionLimit,
  } = useAppSelector(getModalsState);

  return (
    <>
      {addUser && <AddUserModal open={addUser} />}
      {addUserResult.isOpen && <AddUserResultModal />}
      {passwordChangeResult && <PasswordChangeResultModal />}
      {subscriptionLimit && <SubscriptionLimitModal />}
    </>
  );
}

export default MyAccountModalContainer;
