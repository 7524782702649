import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import FormButtonContainer from '../../../shared/components/buttons/formButtonContainer/FormButtonContainer';
import updateContact from '../services/updateContact';
import ContactFormComponents from './ContactFormComponents';
import {
  contactFormHydratation,
  contactValidationSchema,
  initialContactForm,
} from './contactFormValidation';

interface IProps {
  contact: Contact | null;
  validContent: string;
  onValid?: (contact: Contact) => void;
  onCancel?: () => void;
}

function ContactForm({ contact, validContent, onValid, onCancel }: IProps) {
  const formOptions = useForm<ContactFormType>({
    defaultValues: initialContactForm,
    resolver: yupResolver(contactValidationSchema),
  });

  const {
    setValue,
    reset,
    handleSubmit,
    formState: { isValid, errors },
  } = formOptions;

  const handleCancel = () => {
    reset();
    onCancel?.();
  };

  const onSubmit = async (values: ContactFormType) => {
    if (isValid) {
      try {
        if (contact) {
          const updatedContact = await updateContact(contact.idIri, values);
          onValid?.(updatedContact);
        } else {
          throw new Error('Not implemented');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (contact) {
      contactFormHydratation(contact, setValue);
    }
  }, [contact]);

  return (
    <FormProvider {...formOptions}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <ContactFormComponents addressOld={contact?.addressOld ?? ''} />

        <FormButtonContainer
          canCancel
          onCancel={handleCancel}
          validContent={validContent}
        />
      </form>
    </FormProvider>
  );
}

export default ContactForm;
