import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  SET,
  SUCCESS,
} from '../common/actions';
import { FavoriteSearch } from './reducer';

export const FETCH_FAVORITE_SEARCH = createRequestTypes('FETCH_FAVORITE_SEARCH');
export const ADD_FAVORITE_SEARCH = createRequestTypes('ADD_FAVORITE_SEARCH');
export const DELETE_FAVORITE_SEARCH = createRequestTypes('DELETE_FAVORITE_SEARCH');
export const FAVORITE_WINDOW_DISPLAY = createRequestTypes('FAVORITE_WINDOW_DISPLAY');

export const favoritePlacesActions = {
  fetchFavoriteSearches: {
    request: (userId: string) =>
      action(FETCH_FAVORITE_SEARCH[REQUEST], { payload: userId }),
    success: (data: FavoriteSearch[]) => {
      return action(FETCH_FAVORITE_SEARCH[SUCCESS], {
        payload: { favoritePlaces: data },
      });
    },
    failure: () => {
      return action(FETCH_FAVORITE_SEARCH[FAILURE], {});
    },
  },
  addFavoriteSearch: {
    request: (data: Partial<FavoriteSearch>) =>
      action(ADD_FAVORITE_SEARCH[REQUEST], { payload: data }),
    success: (datas: FavoriteSearch) => {
      return action(ADD_FAVORITE_SEARCH[SUCCESS], {
        payload: datas,
      });
    },
    failure: () => {
      return action(ADD_FAVORITE_SEARCH[FAILURE], {});
    },
  },
  deleteFavoriteSearch: {
    request: (favId: string) =>
      action(DELETE_FAVORITE_SEARCH[REQUEST], { payload: favId }),
    success: (favId: string) => {
      return action(DELETE_FAVORITE_SEARCH[SUCCESS], {
        payload: favId,
      });
    },
    failure: () => {
      return action(DELETE_FAVORITE_SEARCH[FAILURE], {});
    },
  },
  windowDisplay: {
    set: (data: boolean) => action(FAVORITE_WINDOW_DISPLAY[SET], { payload: data }),
  },
};
