import { Path, Svg } from '@react-pdf/renderer';
import { pdfStyles } from '../../../../shared/styles/pdfStyle';

function BigO({ width }: { width: number }) {
  const height = Math.floor(width * 0.89);

  return (
    <Svg
      width={width}
      height={height}
      viewBox={`"0 0 ${width} ${height}`}
      style={pdfStyles.letterO}
    >
      <Path
        d="M527 77.3877C527.288 221.666 408.192 342.008 259.995 339.975C119.545 338.053 -1.26332 222.419 1.02575 72.3281C3.15475 -67.7236 117.144 -185.792 263.837 -186C410.177 -186.208 527.432 -67.1311 527 77.3877ZM263.613 236.525C351.894 236.557 423.448 165.402 423.528 77.4678C423.624 -10.707 352.566 -82.8063 265.486 -82.9024C175.604 -82.9984 104.162 -12.4682 104.082 76.459C104.018 165.05 175.219 236.493 263.613 236.525Z"
        fill="#FE0000"
      />
    </Svg>
  );
}

export default BigO;
