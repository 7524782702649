import { LatLngTuple } from 'leaflet';

interface cadastreLinkProps {
  latlng: LatLngTuple;
  parcelleId?: string;
}

const CadastreLink = ({ latlng, parcelleId }: cadastreLinkProps): JSX.Element => {
  return (
    <div className="cadastre-link">
      {parcelleId && latlng && (
        <>
          <a
            href={` https://cadastre.data.gouv.fr/map?style=ortho&parcelleId=${parcelleId}#18.3/${latlng[0]}/${latlng[1]}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Consulter la parcelle sur le site officiel du cadastre
          </a>
          <span className="cadastre-link-span">
            (le plan cadastral est susceptible de subir des modifications ponctuelles
            imprévues)
          </span>
        </>
      )}
    </div>
  );
};

export default CadastreLink;
