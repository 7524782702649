import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useAppSelector } from '../../../../../../App/store';
import IndividualCircularLoader from '../../../../../../features/loaders/individualCircularLoader';
import { getPluState } from '../../../../../../features/plu/pluSlice';
import { getPlotState } from '../../../../../../redux/plot/reducer';
import usePLU from '../../../../../../shared/hooks/usePLU';
import UrbanismeFolderPlotList from './UrbanismeFolderPlotList';

interface IUrbanismeContentProps {
  plot: nullable<Partial<IPlot>>;
  folderPlotZones?: nullable<{ plotId: string; zone: IZone }[]>;
}

export default function UrbanismeContent({
  plot,
  folderPlotZones,
}: IUrbanismeContentProps) {
  const { getPluStatusText } = usePLU();
  const [expanded, setExpanded] = useState<string>('');
  const { pluUrls } = useAppSelector(getPluState);
  const { parcelle } = useAppSelector(getPlotState);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<unknown>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : '');
    };

  return (
    <>
      {!plot ? (
        <IndividualCircularLoader size={200} />
      ) : (
        <>
          {pluUrls.result?.pluStatus ? (
            <div className="no-zone">
              <p>Aucun plan de zone disponible</p>
              <p>
                Document d’urbanisme : <span>{getPluStatusText()}</span>
              </p>
            </div>
          ) : (
            <UrbanismeFolderPlotList
              zone={plot.zone}
              folderPlotZones={folderPlotZones}
            />
          )}
          {plot?.documents?.map(
            (d: { type: string; files: string[] }, i: number) => {
              if (isEmpty(d.files)) {
                return null;
              } else {
                return (
                  <Accordion
                    key={nanoid()}
                    className="urbanisme-accordion"
                    expanded={expanded === `panel${i}`}
                    onChange={handleChange(`panel${i}`)}
                  >
                    <AccordionSummary
                      className="accordion-summary"
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      {d.type}
                    </AccordionSummary>
                    <AccordionDetails className="accordion-detail">
                      {d.files.map((f: string) => (
                        <a
                          key={nanoid()}
                          href={f}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {decodeURI(f.split(/[/ ]+/).pop() || '')}
                        </a>
                      ))}
                    </AccordionDetails>
                  </Accordion>
                );
              }
            }
          )}
        </>
      )}
    </>
  );
}
