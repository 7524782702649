import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getCompanyState } from '../../features/company/companySlice';
import { authActions, getAuthState } from '../auth/authSlice';
import companyUserFetch from '../users/services/companyUserFetch';
import { fetchCompanyThunk } from './companyThunks';

interface IUseCompanyProps {
  loadCompany: boolean;
}
export default function useCompany(params?: IUseCompanyProps) {
  const dispatch = useAppDispatch();
  const { isLogged, userId } = useAppSelector(getAuthState);
  const { company, companyIdIri, companyId, companies, firm } =
    useAppSelector(getCompanyState);

  const updateRights = async (userID: number, companyID: number) => {
    const result = await companyUserFetch(userID, companyID);
    dispatch(authActions.updateRightAccess(result));
  };
  useEffect(() => {
    // load company
    if (
      isLogged &&
      userId &&
      companyIdIri &&
      companyId &&
      params?.loadCompany &&
      !company.result
    ) {
      dispatch(fetchCompanyThunk({ companyIdIri }));
      updateRights(userId, companyId);
    }
  }, [companyIdIri, isLogged, params?.loadCompany]);

  const getCompanyName = () => {
    const co = companies.find((c) => c.idIri === companyIdIri);
    if (co) {
      return co;
    } else {
      return null;
    }
  };

  return { company, companyIdIri, companyId, companies, firm, getCompanyName };
}
