import IconContainer from './IconContainer';

function FolderIcon(props: ISharedIconsProps) {
  const { tooltipText } = props;

  return (
    <IconContainer {...props} tooltipText={tooltipText}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-1 -3 20 20">
        <path d="M18 1.75H9L7.2 0H0V14H18V1.75ZM16.2 12.25H1.8V3.5H16.2V12.25Z" />
      </svg>
    </IconContainer>
  );
}

export default FolderIcon;
