import { createAsyncThunk } from '@reduxjs/toolkit';
import { studyActions } from '../../slices/studySlice';

export const setStudyParamsThunk = createAsyncThunk(
  'studyParams/setStudyParamsThunk',
  async (params: StudyParamsState, thunkOptions) => {
    thunkOptions.dispatch(studyActions.reset());
    return params;
  }
);
