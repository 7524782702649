import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { getPlotState } from '../../../../redux/plot/reducer';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { loadersActions } from '../../../loaders/loaderSlice';
import {
  externalPdfErrialActions,
  getExternalPdfErrialState,
} from '../../externalPdfSlice';
import parcelsVerification from '../services/parcelsVerification';
import { getFullPlotsIdArray } from '../utils/utils';

function useRightPanelPDF() {
  const {
    isMultiPlotSelectorPdf,
    externalPdfErrialFormData,
    multiPlotsPdf,
    datasForPdfGeneration,
    servitudes,
    forceGenerate,
    pricehubbleFolderDatas,
    pricehubbleDatas,
    unemploymentRateChart,
    activePopulationChart,
    prospecDatas,
    avgPriceChart,
    avgPriceAreaChart,
    clicked,
    sweepbrightData,
    phAccessToken,
  } = useAppSelector(getExternalPdfErrialState);
  const { parcelle, isActivePlotLoading } = useAppSelector(getPlotState);

  const [click, setClick] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(false);
  const dispatch = useAppDispatch();

  // **** constants ****
  const plotsPdfs = multiPlotsPdf.result;

  // start
  const performStep1 = (performStep2: () => void) => {
    setClick(true);
    dispatch(externalPdfErrialActions.setClicked(true));
    dispatch(loadersActions.currentStepSet('Chargement des données'));
    performStep2();
  };

  // when orpi button click
  const adjacentPlotVerification = async (performStep2: () => void) => {
    try {
      // adjacent plot verification
      const response: PlotVerification = await parcelsVerification(
        getFullPlotsIdArray(isMultiPlotSelectorPdf, plotsPdfs, parcelle)
      );

      if (!response.hasNoAdjacentPlot) {
        performStep1(performStep2);
      } else {
        dispatch(
          externalPdfErrialActions.setInfoModalDisplay({
            display: true,
            type: 'forceGenerate',
            plotIds: response.plotIds,
            errorMessage: response.errorMessage,
          })
        );
      }
    } catch (error) {
      dispatch(
        externalPdfErrialActions.setInfoModalDisplay({
          display: true,
          type: 'errorAdjacent',
          plotIds: null,
          errorMessage: null,
        })
      );
    }
  };

  useEffect(() => {
    // conditions to disable button for generation pdf
    setDisabledBtn(
      isActivePlotLoading ||
        multiPlotsPdf.apiStatus === APIStatus.PENDING ||
        (isEmpty(multiPlotsPdf) && isEmpty(parcelle)) ||
        (isEmpty(multiPlotsPdf) && Boolean(parcelle) && isMultiPlotSelectorPdf)
    );
  }, [isMultiPlotSelectorPdf, multiPlotsPdf, parcelle, isActivePlotLoading]);

  return {
    // store
    parcelle,
    phAccessToken,
    isMultiPlotSelectorPdf,
    externalPdfErrialFormData,
    multiPlotsPdf: multiPlotsPdf.result,
    datasForPdfGeneration,
    servitudes,
    forceGenerate,
    noPHDatas: pricehubbleFolderDatas.noData,
    pricehubbleFolderDatas: pricehubbleFolderDatas.result,
    pricehubbleDatas,
    unemploymentRateChart,
    activePopulationChart,
    prospecDatas,
    avgPriceChart,
    avgPriceAreaChart,
    clicked,
    sweepbrightData: sweepbrightData.result,
    //state and functions
    adjacentPlotVerification,
    click,
    setClick,
    performStep1,
    disabledBtn,
    isActivePlotLoading,
  };
}

export default useRightPanelPDF;
