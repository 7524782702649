import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../App/store';
import TextFieldFormCustom from '../../../components/Common/FormComponents/TextFieldFormCustom';
import { forgotPasswordThunk } from '../authThunks';
import {
  initialForgotPasswordForm,
  validationSchemaForgotPasswordForm,
} from './formValidation';
import styles from './loginModal.module.scss';
interface IForgotPasswordFormProps {
  setShowForgotPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

type FormType = { email: string };

export default function ForgotPasswordForm({
  setShowForgotPassword,
}: IForgotPasswordFormProps): JSX.Element {
  const dispatch = useAppDispatch();

  const formOptions = useForm<FormType>({
    defaultValues: initialForgotPasswordForm,
    resolver: yupResolver(validationSchemaForgotPasswordForm),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = formOptions;
  const submitForm = (data: { email: string }): void => {
    dispatch(forgotPasswordThunk(data));
    setShowForgotPassword(false);
  };

  return (
    <FormProvider {...formOptions}>
      <form
        className={styles.loginForm}
        onSubmit={handleSubmit(submitForm)}
        autoComplete="off"
      >
        <TextFieldFormCustom
          type="email"
          name="email"
          placeholder="Email"
          required
        />
        <button type="submit" className={styles.submitButton}>
          ENVOYER
        </button>
      </form>
    </FormProvider>
  );
}
