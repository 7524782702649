export const dataOwnerPlotStudiesHeaderCell = [
  { title: '' },
  { title: 'Commune', rowSpan: 2, sortName: 'name' },
  { title: 'Id Parcelle', rowSpan: 2 },
  { title: 'Surface parcelle', rowSpan: 2 },
  { title: 'Nature parcelle', rowSpan: 2 },
];

export const cityOwnerTableHeader = [
  { title: 'Nom' },
  { title: 'Siren' },
  { title: 'Nombre propriétés' },
  { title: 'BODACC' },
];

export const companySortKeys = [
  {
    value: 'company_name',
    display: 'Nom',
  },
  {
    value: 'parcels_count_per_city',
    display: 'Nombre de parcelles',
  },
  {
    value: 'is_bodacc',
    display: 'Bodacc',
  },
];

export const companyPlotsSortKeys = [
  {
    value: 'city_name',
    display: 'Ville',
  },
  {
    value: 'theoric_capacity',
    display: 'Surface',
  },
  {
    value: 'area_built',
    display: 'Nature',
  },
];
