import { memo, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../App/store';
import DashboardFolderContainer from '../../../features/folders/dashboard/DashboardFolderContainer';
import { foldersActions } from '../../../features/folders/foldersSlice';
import useFolder from '../../../features/folders/useFolder';
import FilterSortBloc from '../../filterSortBloc';

import './foldersTab.scss';

function FoldersTab(): JSX.Element {
  const [searchPattern, setSearchPattern] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>('id');
  const [order, setOrder] = useState<Order>('asc');
  const { folders } = useFolder({ forceLoad: true });

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(foldersActions.folderForActionReset());
      dispatch(foldersActions.deployedSubFolderReset());
      dispatch(foldersActions.deployedFolderReset());
    };
  }, []);

  return (
    <div className="tab-folders">
      <div className="tab-inner">
        <h1>Dossiers</h1>
      </div>
      <FilterSortBloc
        setSearchPattern={setSearchPattern}
        selectItems={[{ value: 'name', display: 'Nom' }]}
        inputPlaceHolder="Recherche nom de dossier"
        setSortKey={setSortKey}
        setOrder={setOrder}
        selectPlaceHolder="Trier dossiers par"
        hasMailShots
      />

      <DashboardFolderContainer
        folders={folders ? [...folders] : null}
        searchPattern={searchPattern}
        sortKey={sortKey}
        order={order}
      />
    </div>
  );
}

export default memo(FoldersTab);
