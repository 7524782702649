import styles from './cesiumFatalError.module.scss';

function CesiumFatalError() {
  const handleClick = () => {
    window.open(
      `mailto:support@urbanease.io?subject=Erreur de chargement page 3D`,
      '_blank'
    );
  };
  return (
    <div className={styles.cesiumFatalError}>
      <div id="cesiumViewerContainer" className={styles.cesiumViewerContainer}></div>
      <p>
        Une erreur est survenue durant l&apos;initialisation <br />
        de l&apos;affichage de la 3D
      </p>
      <p>Veuillez contacter Urbanease</p>
      <button onClick={handleClick}>Contacter urbanease</button>
    </div>
  );
}

export default CesiumFatalError;
