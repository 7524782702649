import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { modalsActions } from '../../../modals/modalsSlice';
import postCompanyLogo from '../postCompanyLogo';

const postCompanyLogoThunk = createAsyncThunk(
  'company/postCompanyLogoThunk',
  async (
    params: { file: File; callback: (isGood: boolean) => void },
    thunkOptions
  ) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { uploadImageModal } = state.modals;
      const { companyIdIri, company } = state.company;

      if (!company.result) throw new Error('no company loaded');

      let response: { status: string; url: string } = { status: '', url: '' };

      response = await postCompanyLogo({ file: params.file, companyIdIri });

      if (uploadImageModal.isOpen) {
        thunkOptions.dispatch(modalsActions.uploadModalUpdateUrl(response.url));
      }
      params.callback(true);
      return response.url;
    } catch (error) {
      params.callback(false);
      return Promise.reject(error);
    }
  }
);

export default postCompanyLogoThunk;
