import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';

const HighwaySvg = ({ color }: { color?: string }) => (
  <Svg
    height="24"
    width="24"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M14.0657 4.06055C14.1183 4.1013 14.1517 4.1573 14.1581 4.1933L15 8H12V4.00006H13.9139C13.9512 3.99881 14.0142 4.0188 14.0657 4.06055ZM8.84182 4.1933C8.84845 4.1573 8.88162 4.1013 8.93419 4.06055C8.98599 4.0188 9.04876 3.99881 9.08627 4.00006H11V8H8.00002L8.84182 4.1933ZM6.00097 19.995L6.00146 19.9953L6.02934 20H11V12H7.51909L6.00582 19.9465L6.00194 19.9655L6 19.9857L6.00097 19.995ZM16.9973 19.9622L17 19.9859L16.999 19.995L16.9987 19.9952C16.9963 19.9964 16.9899 19.9997 16.9709 20H12V12H15.4811L16.9973 19.9622ZM22.8675 10.7647C22.9035 10.7647 22.9348 10.7491 22.9608 10.7212C22.986 10.6924 23 10.6576 23 10.6176V9.14706C23 9.10706 22.986 9.07236 22.9608 9.04354C22.9348 9.01559 22.9035 9.00001 22.8675 9.00001H1.13253C1.09622 9.00001 1.0652 9.01559 1.03923 9.04354C1.01405 9.07236 1 9.10706 1 9.14706V10.6176C1 10.6576 1.01405 10.6924 1.03923 10.7212C1.0652 10.7491 1.09622 10.7647 1.13253 10.7647H1.92771C2.1827 10.7653 2.41224 10.8782 2.58426 11.0521C2.75602 11.2262 2.87795 11.4697 2.90154 11.7515L3.07092 13.8153C3.07357 13.8559 3.09477 13.905 3.13267 13.9432C3.17031 13.9818 3.21643 14.0006 3.25301 14H4.31325C4.34957 14.0006 4.39569 13.9818 4.43333 13.9432C4.47096 13.905 4.49217 13.8559 4.49508 13.8153L4.66446 11.7518C4.68805 11.47 4.81024 11.2265 4.982 11.0524C5.15376 10.8782 5.3833 10.7653 5.63855 10.7647H18.3614C18.6164 10.7653 18.846 10.8782 19.018 11.0521C19.1898 11.2262 19.3117 11.4697 19.3353 11.7515L19.5047 13.8153C19.5073 13.8559 19.5285 13.905 19.5664 13.9432C19.604 13.9818 19.6502 14.0006 19.6867 14H20.747C20.7833 14.0006 20.8294 13.9818 20.8671 13.9432C20.9047 13.905 20.9259 13.8559 20.9288 13.8153L21.0982 11.7518C21.1218 11.47 21.244 11.2265 21.4157 11.0524C21.5875 10.8782 21.817 10.7653 22.0723 10.7647H22.8675Z"
      fill={color ?? 'currentColor'}
    />
  </Svg>
);

export default HighwaySvg;
