import { StyleSheet, Text, View } from '@react-pdf/renderer';
interface IProps {
  item: string | number;
  header?: boolean;
  flex?: boolean;
}

const ComparativeStudiesTableCell = ({ item, header, flex }: IProps) => {
  const tableCellStyle = header ? styles.tableCellHeader : styles.tableCell;

  return (
    <View style={[styles.tableCol, { flex: flex ? 1 : undefined }]}>
      <Text style={tableCellStyle}>{item}</Text>
    </View>
  );
};

export default ComparativeStudiesTableCell;

const styles = StyleSheet.create({
  tableCol: {
    width: '15%',
  },
  tableCell: {
    textAlign: 'center',
    fontSize: 12,
  },
  tableCellHeader: {
    textAlign: 'center',
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 700,
  },
});
