import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { fetchStudyProcedures } from '../fetchStudyProcedures';

const fetchStudyProceduresThunk = createAsyncThunk(
  'study/studyProcedureThunk',
  async (params: void, thunkOptions) => {
    try {
      // state datas
      const state = thunkOptions.getState() as RootState;
      const { companyId } = state.company;
      const { study } = state.study;
      const { users } = state.users;
      const { contactRoles } = state.app;
      const { procedureTypes } = state.app;

      // request
      const result = await fetchStudyProcedures(
        study.result?.idIri ?? null,
        companyId,
        users,
        contactRoles.result,
        procedureTypes.result
      );

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchStudyProceduresThunk;
