import { useAppSelector } from '../../../../App/store';
import { getMapState } from '../../../../features/map/mapSlice';
import { getPlotState } from '../../../../redux/plot/reducer';
import ERRIALButton from './ErrialButton';
import PDFButton from './pdfButton/PDFButton';

export default function PlotHeader() {
  const { parcelle } = useAppSelector(getPlotState);
  const { geolocDatas } = useAppSelector(getMapState);

  return (
    <div className="parcelle-details">
      <div className="header-left">
        <p className="title">{geolocDatas?.address}</p>
        <p className="title-indication">
          adresse indicative, sous réserve de vérification
        </p>
      </div>
      <div className="header-right">
        <div className="header-right-buttons">
          <PDFButton />
          <ERRIALButton />
        </div>
        <p className="parcel-number">
          {`N° Parcelle: 
    ${parcelle?.parcelleId.substring(5)}
`}
        </p>
      </div>
    </div>
  );
}
