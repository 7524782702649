export const getWkbGeoJson = (parcel) => {
  try {
    let wkbGeoJson = [];
    let wkbGeo = JSON.parse(parcel) || [];

    if (
      wkbGeo &&
      wkbGeo.coordinates.length > 0 &&
      wkbGeo.coordinates[0].length > 0
    ) {
      wkbGeo.coordinates[0].forEach((wkb) => {
        let reverseArr = [];
        wkb.forEach((item) => {
          reverseArr.push([item[1], item[0]]);
        });
        wkbGeoJson.push(reverseArr);
      });
    }

    return wkbGeoJson;
  } catch (error) {
    throw new Error('error parsing parcel.building_wkb_geometry');
  }
};
