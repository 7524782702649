import { axiosTokenInstance } from '../../../services/AxiosProtectedInstance';

/**
 * @param company_name Company name
 * @param company_zipcode PostalCode of company
 * @param company_id Company id
 * @param insee company insee code
 * @param commentaire  comment ()
 * @returns ask status
 * code 0 : error
 * code 1 : ask allready send for thsis city
 * code 2 : ask is registered
 */
async function postAskForPlu(
  company_name: string,
  company_zipcode: string,
  company_id: number,
  insee: string,
  user_firstname: string | null,
  user_lastname: string | null,
  commentaire: string
) {
  try {
    const result = await axiosTokenInstance().post(
      '/data_urbanease/plu_update_request/',
      {
        company_name,
        company_id,
        insee,
        user_lastname,
        user_firstname,
        commentaire,
        company_zipcode: company_zipcode !== '' ? company_zipcode : undefined,
      }
    );

    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default postAskForPlu;
