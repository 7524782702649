import { useAppDispatch } from '../../../App/store';
import UnLockIcon from '../../../assets/images/lockopen.png';
import { modalsActions } from '../../modals/modalsSlice';
import LoginContent from './LoginContent';
import styles from './loginModal.module.scss';

function FormContainer() {
  const dispatch = useAppDispatch();

  const handleOpenSignupModal = () => {
    dispatch(modalsActions.signup(true));
  };

  return (
    <div className={styles.loginModalContent}>
      <img src={UnLockIcon} alt="img" className={styles.padlock} />

      <LoginContent />

      <div className={styles.footerWrapper}>
        <span className={styles.leftText}>Vous êtes nouveau sur URBANEASE ?</span>
        &nbsp;
        <span className={styles.rightText} onClick={handleOpenSignupModal}>
          Inscrivez-vous
        </span>
      </div>
    </div>
  );
}

export default FormContainer;
