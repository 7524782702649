import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import fetchContactPlotStudiesThunk from './services/thunks/fetchContactPlotStudiesThunk';
import fetchContactsThunk from './services/thunks/fetchContactsThunk';

const initialState: ContactState = {
  contacts: { apiStatus: APIStatus.IDLE, result: null },
  totalContacts: 0,
  contactType: null,
  contactForAction: null,
  contactPlotStudies: { apiStatus: APIStatus.IDLE, result: null },
  totalPlotStudies: 0,
};
const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    reset: () => initialState,
    contactsReset: (state) => {
      state.contacts = { apiStatus: APIStatus.IDLE, result: null };
      state.totalContacts = 0;
    },
    contactForActionSet: (state, action: PayloadAction<Contact | null>) => {
      state.contactForAction = action.payload;
    },
    contactForActionReset: (state) => {
      state.contactForAction = null;
      state.contactPlotStudies = { apiStatus: APIStatus.IDLE, result: null };
      state.totalPlotStudies = 0;
    },
    plotStudiesContactSet: (
      state,
      action: PayloadAction<SortablePlotStudies | null>
    ) => {
      state.contactPlotStudies.result = action.payload;
      state.totalPlotStudies = action.payload?.length ?? 0;
    },
    plotStudiesContactReset: (state) => {
      state.contactPlotStudies.result = null;
      state.totalPlotStudies = 0;
    },
  },
  extraReducers(builder) {
    // FETCH CONTACTS
    builder
      .addCase(fetchContactsThunk.pending, (state) => {
        state.contacts.apiStatus = APIStatus.PENDING;
        state.contacts.error = undefined;
      })
      .addCase(
        fetchContactsThunk.fulfilled,
        (state, action: PayloadAction<Contact[] | null>) => {
          state.contacts.apiStatus = APIStatus.IDLE;
          state.contacts.result = action.payload;
          state.totalContacts = action.payload?.length ?? 0;
          state.contacts.error = undefined;
        }
      )
      .addCase(fetchContactsThunk.rejected, (state, action) => {
        state.contacts.apiStatus = APIStatus.REJECTED;
        state.contacts.result = null;
        state.totalContacts = 0;
        state.contacts.error = action.error;
      });
    // FETCH CONTACT PLOTSTUDIES
    builder
      .addCase(fetchContactPlotStudiesThunk.pending, (state) => {
        state.contactPlotStudies.apiStatus = APIStatus.PENDING;
        state.contactPlotStudies.result = null;
        state.totalPlotStudies = 0;
        state.contactPlotStudies.error = undefined;
      })
      .addCase(
        fetchContactPlotStudiesThunk.fulfilled,
        (state, action: PayloadAction<SortablePlotStudies | null>) => {
          state.contactPlotStudies.apiStatus = APIStatus.IDLE;
          state.contactPlotStudies.result = action.payload;
          state.totalPlotStudies = action.payload?.length ?? 0;
          state.contactPlotStudies.error = undefined;
        }
      )
      .addCase(fetchContactPlotStudiesThunk.rejected, (state, action) => {
        state.contactPlotStudies.apiStatus = APIStatus.REJECTED;
        state.contactPlotStudies.result = null;
        state.totalPlotStudies = 0;
        state.contactPlotStudies.error = action.error;
      });
  },
});

export default contactsSlice.reducer;
export const contactsActions = contactsSlice.actions;
export const getContactsState = (state: RootState) => state.contacts;
