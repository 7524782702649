import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from '../../App/store';
import styles_scss from '../../components/LeftPanelModal/InputTabs/inputTabs.module.scss';
import { favoritePlacesActions } from '../../redux/favoritePlaces/actions';
import { getFavoritePlacesState } from '../../redux/favoritePlaces/reducer';

interface FavoriteBtnProps {
  className: string;
}

function FavoriteBtn({ className }: FavoriteBtnProps) {
  const { windowDisplay } = useAppSelector(getFavoritePlacesState);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(favoritePlacesActions.windowDisplay.set(!windowDisplay));
  };

  return (
    <div
      className={`${className} ${windowDisplay ? styles_scss.openned : ''}`}
      onClick={handleClick}
    >
      {/* <FontAwesomeIcon icon={faBookmark} /> */}
      <FontAwesomeIcon icon={faClockRotateLeft} />
    </div>
  );
}

export default FavoriteBtn;
