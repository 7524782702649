import { nanoid } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';

import { getMapState } from '../../../features/map/mapSlice';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import BrownFieldLegend from '../../brownfields/components/BrownFieldLegend';
import { getLayerEnvState } from '../envSlice';
import { fetchMapLayerEnvThunk } from '../mapLayerEnvThunk';
import styles from './envLegend.module.scss';
import EnvLegendRow from './EnvLegendRow';

const EnvLegend = () => {
  const { envLayers, city, postalCode } = useAppSelector(getLayerEnvState);

  const map = useAppSelector(getMapState);

  const goodUrlsEnvLayers =
    envLayers.result?.filter((f) =>
      f.url ? true : false || f.pmtilesFileUrl ? true : false
    ) ?? [];

  const envSectionFilter = goodUrlsEnvLayers.filter((f) =>
    [1, 4, 5, 6, 10].includes(f.cat)
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      map.geolocDatas?.city &&
      map.geolocDatas?.postalCode &&
      map.geolocDatas?.city !== city &&
      map.geolocDatas?.postalCode !== postalCode &&
      map.geolocDatas?.inseeCode
    ) {
      dispatch(
        fetchMapLayerEnvThunk({
          city: map.geolocDatas?.city ?? null,
          postalCode: map.geolocDatas?.postalCode ?? null,
          inseeCode: map.geolocDatas?.inseeCode,
        })
      );
    }
  }, [map.geolocDatas?.city, map.geolocDatas?.postalCode]);

  const findEnvRow = (cat: number) => {
    return envSectionFilter.find((f) => f.cat === cat) ?? null;
  };

  if (envLayers.apiStatus === APIStatus.PENDING) {
    return <IndividualCircularLoader size={150} />;
  }

  if (envLayers.apiStatus === APIStatus.REJECTED) {
    return <p className={styles.error}>Erreur de chargement</p>;
  }

  return (
    <div
      className={`${styles.environmentContainer} ${
        goodUrlsEnvLayers && styles.data
      }`}
    >
      {postalCode && city ? (
        <>
          <div className={styles.envSection}>
            <h3>Environnement</h3>
            <EnvLegendRow row={findEnvRow(4)} />
            <EnvLegendRow row={findEnvRow(6)} />
            <EnvLegendRow row={findEnvRow(1)} />
            <EnvLegendRow row={findEnvRow(5)} />
            <EnvLegendRow row={findEnvRow(10)} />
          </div>
          <div className={styles.envSection}>
            <h3>Patrimoine</h3>
            {goodUrlsEnvLayers
              .filter((f) => [3, 8, 9].includes(f.cat))
              .map((item) => (
                <EnvLegendRow key={nanoid()} row={item} />
              ))}
          </div>
          <div className={styles.envSection}>
            <h3>Divers</h3>
            {goodUrlsEnvLayers
              .filter((f) => [2, 7].includes(f.cat))
              .map((item) => (
                <EnvLegendRow key={nanoid()} row={item} />
              ))}
            <BrownFieldLegend />
          </div>
        </>
      ) : (
        <p className={styles.noCity}>
          Choisissez une parcelle <br /> ou saisissez une ville
        </p>
      )}
    </div>
  );
};

export default EnvLegend;
