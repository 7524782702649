/**
 *
 * @param data must be an idIri or an object with ['@id'] param
 * @param entities entities list
 * @returns entity with data id
 */
function getEntityFromIdIri<T extends IIdIri>(data: any, entities: T[]): T | null {
  if (!data || !entities) {
    return null;
  } else {
    const entity = entities.find(
      (f) => f.idIri === (typeof data === 'string' ? data : data['@id'])
    );

    return entity ?? null;
  }
}

export default getEntityFromIdIri;
