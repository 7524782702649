import { isEmpty } from 'lodash';

/**
 * Single Parking package parser
 * @param {any} data - single parking package data to parse
 * @returns {ParkingPackage} parsed parking package object
 */
function parkingPackageParser(data: any): ParkingPackage | null {
  if (isEmpty(data)) return null;
  try {
    const parsed: ParkingPackage = {
      number: data.number,
      label: data.libelle ?? 'Non défini',
      cost: data.cout ?? 0,
    };

    return parsed;
  } catch (error: any) {
    throw new Error('parkingPackageParser' + error.message);
  }
}

/**
 * Array Parking package parser
 * @param {any} data - array parking package data to parse
 * @returns {ParkingPackages} parsed parking package array
 */
export function parkingPackagesParser(datas: any[]): ParkingPackages {
  try {
    // if no data
    if (!datas) return [];
    // if empty array
    if (isEmpty(datas)) return [];

    const parsed = datas.map((m) => parkingPackageParser(m));

    // return only no null datas
    return parsed.filter((m) => m !== null) as ParkingPackages;
  } catch (error: any) {
    throw new Error(error.message);
  }
}
