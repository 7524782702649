import { nanoid } from '@reduxjs/toolkit';
import { WMSTileLayer } from 'react-leaflet';
import { useSelector } from 'react-redux';
import { useAppSelector } from '../../App/store';
import { getMapState } from '../../features/map/mapSlice';
import { getGeorisqueState } from './georisqueSlice';

function GeorisqueLayer() {
  const { geolocDatas } = useSelector(getMapState);
  const { displayedLayers } = useAppSelector(getGeorisqueState);
  return (
    <>
      {geolocDatas?.inseeCode &&
        displayedLayers.map((l) => {
          const split = l.split('/');
          const maxNativeZoom = parseInt(split[0], 10);

          return (
            <WMSTileLayer
              key={nanoid()}
              format="image/png"
              layers={split[1]}
              transparent={true}
              version="1.3.0"
              url="https://mapsref.brgm.fr/wxs/georisques/risques?"
              maxNativeZoom={maxNativeZoom}
              maxZoom={22}
              zIndex={5}
              opacity={0.8}
            />
          );
        })}
    </>
  );
}

export default GeorisqueLayer;
