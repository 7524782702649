import {
  changeApiKeyForStoreKey,
  mapObjectParser,
} from '../../services/common/parserCommon';

const ownerInfosfromSirenMapping = {
  siren: 'siren',
  forme_juridique_abregee: 'lawForm',
  company_name: 'companyName',
  is_bodacc: 'isBodacc',
  parcels_count: 'plotCount',
  // parcels_count_per_city: 'plotCountPerCity',
  insee_list: 'inseeList',
  owner_informations: 'ownerInformations',
};

const ownerInfosMapping = {
  corporation: 'corporation',
  legal_status: 'legalStatus',
  more_informations: 'moreInfos',
  bodacc_situation: 'bodaccSituation',
  bodacc_details: 'bodaccDetails',
  bodacc_termination_date: 'bodaccTerminationDate',
};

const ownerDataPlotMapping = {
  parcel_id: 'fullPlotId',
  prefixe: 'prefix',
  section: 'section',
  numero: 'number',
  department: 'department',
  insee: 'insee',
  city_name: 'city',
  city_zipcode: 'postalCode',
  theoric_capacity: 'area',
  real_capacity: 'realCapacity',
  area_built: 'areaBuilt',
  area_free: 'areaFree',
  nature_propriete: 'propertyType',
  longitude: 'lng',
  latitude: 'lat',
};

export const multiPlotDetailParser = (data: any): OwnerDataPlots => {
  const parsedData: OwnerDataPlots = [];
  for (const [key, value] of Object.entries(data || {})) {
    const v = value as any;
    const parsed: OwnerDataPlot = {
      fullPlotId: v.parcel_id,
      city: v.city_name,
      department: v.department,
      inseeCode: v.insee,
      lat: Number(v.latitude),
      lng: Number(v.longitude),
      areaBuilt: Number(v.area_built),
      areaFree: Number(v.area_free),
      propertyType: v.nature_propriete,
      realCapacity: v.real_capacity,
      theoricCapacity: v.theoric_capacity,
    };

    parsedData.push(parsed);
  }

  return parsedData;
};

export const ownerInfosfromSirenParser = (data: any) => {
  const parsedDatas = changeApiKeyForStoreKey<OwnerInfos>(
    data,
    ownerInfosfromSirenMapping
  );

  parsedDatas.ownerInformations = changeApiKeyForStoreKey(
    data.owner_informations,
    ownerInfosMapping
  );

  return parsedDatas;
};

export const ownerListFromInseeCodeParser = (
  data: any,
  insee: string,
  page: number,
  itemsPerPage: number,
  sort?: string | null,
  order?: OwnerOrder
): OwnersDataFromInseeCode => {
  const ownerFromInseeCodeListParser = (dataSingle: any) => {
    const parsedData = changeApiKeyForStoreKey<OwnerFromInseeCode>(
      dataSingle,
      ownerInfosfromSirenMapping
    );

    const plotCountPerCity = JSON.parse(dataSingle.parcels_count_per_city);
    parsedData.plotCountPerCity = plotCountPerCity;
    parsedData.plotCount = plotCountPerCity[insee];

    return parsedData;
  };

  const companies = mapObjectParser<OwnerFromInseeCode>(
    data.companies,
    ownerFromInseeCodeListParser
  );

  const parsedDatas: OwnersDataFromInseeCode = {
    companiesCount: data.count_companies as number,
    companies,
    page,
    itemsPerPage,
    sort: sort ?? null,
    order: order ?? null,
  };

  return parsedDatas;
};
