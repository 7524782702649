import { useAppDispatch } from '../../App/store';
import { fetchFolderPlotStudiesForExcel } from '../../features/folders/services/thunks/foldersThunk';

import GraphIcon from '../../shared/components/icons/GraphIcon';
import { IIconActionProps } from './ItemActions';
import styles from './itemActions.module.scss';

function ExportIcon<T>({ item }: IIconActionProps<T>) {
  const dispatch = useAppDispatch();

  const handleEditCsv = () => {
    dispatch(fetchFolderPlotStudiesForExcel({ folder: item as IFolder }));
  };

  return (
    <GraphIcon
      tooltipText={'Editer tableau excel'}
      onClick={handleEditCsv}
      width={37}
      height={37}
      isRoundIcon
      className={[styles.icon, styles.blueIcon].join(' ')}
    />
  );
}

export default ExportIcon;
