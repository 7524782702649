import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: { margin: '10px 0 0 0' },

  subtitle: {
    padding: '0 15px 0 0',
  },
});
interface ISubTitleGenericBlocProps extends IChildren {
  subtitle: string;
  wrap?: boolean;
}
function SubTitleGenericBloc({
  subtitle,
  children,
  wrap,
}: ISubTitleGenericBlocProps) {
  return (
    <View style={styles.container} wrap={!wrap}>
      <Text style={styles.subtitle}>{subtitle}</Text>
      <View>{children}</View>
    </View>
  );
}

export default SubTitleGenericBloc;
