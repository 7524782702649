import { Accordion, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails } from '@mui/material';
import React, { ReactNode } from 'react';

interface AccordionBaseProps {
  className: string;
  id: number;
  expanded: string;
  title: string;
  children: ReactNode;
  onChange: any;
}

export default function AccordionBase({
  className,
  id,
  expanded,
  title,
  children,
  onChange,
}: AccordionBaseProps) {
  return (
    <Accordion
      className={`${className}-accordion`}
      expanded={expanded === `panel${id}`}
      onChange={onChange(`panel${id}`)}
    >
      <AccordionSummary
        className="accordion-summary"
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${id}bh-content`}
        id={`panel${id}bh-header`}
      >
        {title}
      </AccordionSummary>
      <AccordionDetails className="accordion-detail">{children}</AccordionDetails>
    </Accordion>
  );
}
