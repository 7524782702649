import { createAsyncThunk } from '@reduxjs/toolkit';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { deleteEntity } from '../../../../services/axiosFiles/genericCrud';
import { displayManagerActions } from '../../../displayManager/displayManagerSlice';
import { getParentAndChildIdIriTab } from '../../../displayManager/utils';
import { modalsActions } from '../../../modals/modalsSlice';
import { getStudyTypeFromIdIri } from '../../../study/utils/getStudyType';
import { loadersActions } from '../../../loaders/loaderSlice';

const sectorDeleteThunk = createAsyncThunk(
  'sectors/sectorDelete',
  async (params: { sector: ISector | null }, { rejectWithValue, dispatch }) => {
    try {
      if (
        params.sector?.idIri &&
        getStudyTypeFromIdIri(params.sector?.idIri) === 'sector'
      ) {
        dispatch(loadersActions.loaderShow());
        const result: any = await deleteEntity(params.sector.idIri);

        if (result === 204) {
          dispatch(modalsActions.sectorDelete(false));
          //remove the sector of display Manager if exists
          dispatch(
            displayManagerActions.entitiesRemoveByIdIri(
              getParentAndChildIdIriTab(params.sector)
            )
          );
          dispatch(loadersActions.loaderHide());
          return params.sector;
        } else {
          dispatch(loadersActions.loaderHide());
          return rejectWithValue({
            status: 400,
            message: 'bad response',
            error: result,
          });
        }
      } else {
        dispatch(loadersActions.loaderHide());
        return rejectWithValue(paramsNotFound('sectorDeleteThunk'));
      }
    } catch (error) {
      dispatch(loadersActions.loaderHide());
      return rejectWithValue(error);
    }
  }
);

export default sectorDeleteThunk;
