import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { studyProcedureApiToStoreParser } from '../utils/parsers/studyProcedureParser';

interface ICreateStudyProcedureProps {
  body: StudyProcedureExtended;
  users: Users | null;
  contactRoles: ContactRoles | null;
  procedureTypes: ProcedureTypes | null;
}
async function createStudyProcedure({
  body,
  users,
  contactRoles,
  procedureTypes,
}: ICreateStudyProcedureProps) {
  try {
    if (!body) throw new Error('No body not found');
    if (!users) throw new Error('Users not found');
    if (!contactRoles) throw new Error('ContactRoles not found');
    if (!procedureTypes) throw new Error('ProcedureTypes not found');

    const result = await postEntity({
      endpoint: '/procedures',
      body,
    });

    const parsedResult = studyProcedureApiToStoreParser(
      result,
      users,
      contactRoles,
      procedureTypes
    );

    return parsedResult;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default createStudyProcedure;
