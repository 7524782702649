import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';

const WalkSvg = () => (
  <Svg
    height="24"
    width="24"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M13.5 5.40675C12.9968 5.40675 12.57 5.23159 12.2197 4.88128C11.8694 4.53096 11.6942 4.10419 11.6942 3.60098C11.6942 3.09778 11.8694 2.67102 12.2197 2.3207C12.57 1.97039 12.9968 1.79523 13.5 1.79523C14.0032 1.79523 14.4299 1.97039 14.7803 2.3207C15.1306 2.67102 15.3057 3.09778 15.3057 3.60098C15.3057 4.10419 15.1306 4.53096 14.7803 4.88128C14.4299 5.23159 14.0032 5.40675 13.5 5.40675ZM8.31825 22.5885C8.07467 22.5885 7.88189 22.5047 7.7399 22.337C7.59792 22.1694 7.55481 21.9689 7.61058 21.7356L10.2404 8.42403L7.96248 9.35963C7.90478 9.38526 7.8583 9.42212 7.82303 9.4702C7.78778 9.51829 7.77015 9.57439 7.77015 9.6385V12.0077C7.77015 12.2019 7.70173 12.3675 7.56488 12.5044C7.42801 12.6412 7.26407 12.7096 7.07305 12.7096C6.87882 12.7096 6.71328 12.6412 6.57643 12.5044C6.43958 12.3675 6.37115 12.2019 6.37115 12.0077V9.65388C6.37115 9.30579 6.46522 8.98993 6.65336 8.70628C6.84151 8.42263 7.09711 8.21222 7.42018 8.07505L11.0317 6.57023C11.5029 6.37151 11.9553 6.32199 12.3889 6.42168C12.8226 6.52136 13.1756 6.78594 13.448 7.21543L14.4384 8.7856C14.8115 9.37792 15.3043 9.89443 15.9168 10.3351C16.5293 10.7758 17.2298 11.0661 18.0183 11.2058C18.1958 11.2327 18.3453 11.315 18.4668 11.4525C18.5883 11.5899 18.649 11.7449 18.649 11.9173C18.649 12.1321 18.5721 12.3076 18.4182 12.4438C18.2644 12.58 18.092 12.6337 17.901 12.6048C17.0471 12.4747 16.2259 12.1718 15.4375 11.6962C14.649 11.2205 13.9615 10.5564 13.375 9.70383L12.5904 13.577L14.1644 15.0808C14.3368 15.2565 14.4674 15.4484 14.5562 15.6568C14.645 15.8651 14.6894 16.0856 14.6894 16.3183V21.8866C14.6894 22.0808 14.621 22.2463 14.4841 22.3832C14.3473 22.52 14.1833 22.5885 13.9923 22.5885C13.7981 22.5885 13.6325 22.52 13.4957 22.3832C13.3588 22.2463 13.2904 22.0808 13.2904 21.8866V16.8404L10.7461 14.4183L9.02785 22.0289C8.99067 22.184 8.90493 22.316 8.77063 22.425C8.63635 22.534 8.48555 22.5885 8.31825 22.5885Z"
      fill="currentColor"
    />
  </Svg>
);

export default WalkSvg;
