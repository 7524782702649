import { AlignmentType } from 'docx';

export const mailShotQuillToolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

  [{ color: [] }], // dropdown with defaults from theme
  [{ align: [] }],

  ['clean'], // remove formatting button
];

export const defaultStyles = [
  {
    id: 'normal',
    name: 'Normal',
    next: 'normal',
    basedOn: 'normal',
    quickFormat: true,
    run: {
      size: 24,
    },
    paragraph: {
      spacing: {
        line: 300,
        before: 0,
        after: 0,
      },
    },
  },
  {
    id: 'header_1',
    name: 'Heading 1',
    next: 'normal',
    quickFormat: true,
    run: {
      font: 'Calibri',
      size: 30,
      bold: true,
    },
    paragraph: {
      spacing: {
        before: 300,
        after: 200,
      },
    },
  },
  {
    id: 'header_2',
    name: 'Heading 2',
    basedOn: 'normal',
    next: 'normal',
    quickFormat: true,
    run: {
      font: 'Calibri',
      size: 26,
      bold: true,
    },
    paragraph: {
      spacing: {
        before: 200,
        after: 100,
      },
    },
  },
  {
    id: 'list_paragraph',
    name: 'List Paragraph',
    basedOn: 'normal',
    quickFormat: true,
    run: {
      size: 24,
    },
  },
  {
    id: 'code_block',
    name: 'Code Block',
    basedOn: 'normal',
    quickFormat: true,
    run: {
      size: 24,
      font: 'Courier New',
    },
    paragraph: {
      indent: { left: 720, right: 720 },
    },
  },
  {
    id: 'block_quote',
    name: 'Block Quote',
    basedOn: 'normal',
    quickFormat: true,
    run: {
      italics: true,
    },
    paragraph: {
      indent: { left: 540 },
      border: {
        left: {
          size: 12,
          space: 28,
          color: '333333',
          value: 'single',
        },
      },
    },
  },
  {
    id: 'citation',
    name: 'Citation',
    basedOn: 'normal',
    quickFormat: true,
    run: {
      size: 24,
    },
    paragraph: {
      indent: {
        left: 0,
        hanging: 320,
      },
      spacing: {
        line: 480,
      },
    },
  },
];

export const customNumberedLevels: CustomLevels[] = [
  {
    level: 0,
    format: 'decimal',
    text: '%1.',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 720, hanging: 360 },
      },
    },
  },
  {
    level: 1,
    format: 'lowerLetter',
    text: '%2.',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 1440, hanging: 360 },
      },
    },
  },
  {
    level: 2,
    format: 'lowerRoman',
    text: '%3.',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 2160, hanging: 360 },
      },
    },
  },
  {
    level: 3,
    format: 'decimal',
    text: '%4.',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 2880, hanging: 360 },
      },
    },
  },
  {
    level: 4,
    format: 'lowerLetter',
    text: '%5.',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 3600, hanging: 360 },
      },
    },
  },
  {
    level: 3,
    format: 'lowerRoman',
    text: '%5.',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 4320, hanging: 360 },
      },
    },
  },
];

export const customBulletLevels: CustomLevels[] = [
  {
    level: 0,
    format: 'bullet',
    text: '\u2022',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 720, hanging: 360 },
      },
    },
  },
  {
    level: 1,
    format: 'bullet',
    text: '\u2022',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 1440, hanging: 360 },
      },
    },
  },
  {
    level: 2,
    format: 'bullet',
    text: '\u2022',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 2160, hanging: 360 },
      },
    },
  },
  {
    level: 3,
    format: 'bullet',
    text: '\u2022',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 2880, hanging: 360 },
      },
    },
  },
  {
    level: 4,
    format: 'bullet',
    text: '\u2022',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 3600, hanging: 360 },
      },
    },
  },
  {
    level: 3,
    format: 'bullet',
    text: '\u2022',
    alignment: AlignmentType.LEFT,
    style: {
      paragraph: {
        indent: { left: 4320, hanging: 360 },
      },
    },
  },
];

export const defaultNumbering = {
  config: [
    {
      reference: 'default-numbering',
      levels: customNumberedLevels,
    },
  ],
};

export const initialMultiPlotContent = {
  ops: [
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'PRENOM_CONTACT',
          key: 'PRENOM_CONTACT',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'NOM_CONTACT',
          key: 'NOM_CONTACT',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_ENTREE_BATIMENT',
          key: 'CONTACT_ADRESSE_2',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_NUMERO_DE_RUE',
          key: 'CONTACT_ADRESSE_1',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_RUE_DE_L_ADRESSE',
          key: 'CONTACT_ADRESSE_3',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_BP_OU_LIEU_DIT',
          key: 'CONTACT_ADRESSE_4',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_CODE_POSTAL',
          key: 'CONTACT_CODE_POSTAL',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_VILLE',
          key: 'CONTACT_VILLE',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_PAYS',
          key: 'CONTACT_PAYS',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: '\n\nBonjour ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'NOM_CONTACT',
          key: 'NOM_CONTACT',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'PRENOM_CONTACT',
          key: 'PRENOM_CONTACT',
          color: 'Green',
        },
      },
    },
    {
      insert:
        ' ,\n\nJe me permets de vous contacter concernant les parcelles suivantes, dont vous êtes propriétaire:\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'LISTE_DES_PARCELLES',
          key: 'MULTIPLOT_TO_STRING',
          color: 'Red',
        },
      },
    },
    {
      insert: " \n\nEn tant qu'agent ",
    },
    {
      attributes: {
        bold: true,
      },
      insert: 'immobilier/prospecteur foncier,',
    },
    {
      insert:
        " j'ai récemment identifié un potentiel de valorisation intéressant pour l'ensemble de ces biens. Je serais ravi de vous fournir une analyse détaillée et des informations complémentaires à ce sujet.\n\nSi vous êtes ",
    },
    {
      attributes: {
        bold: true,
      },
      insert: 'intéressé(e)',
    },
    {
      insert:
        ", nous pourrions convenir d'un rendez-vous afin de discuter des différentes options de valorisation de votre bien.\n\nN'hésitez pas à me contacter afin de convenir d'un point qui me permettra de répondre à vos interrogations.\n\nDans l'attente de votre retour, je vous prie d'agréer, l'expression de mes salutations distinguées.\n\nBien cordialement,\n\n",
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'NOM_UTILISATEUR',
          key: 'NOM_PROSPECTEUR',
          color: 'Primary',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'PRENOM_UTILISATEUR',
          key: 'PRENOM_PROSPECTEUR',
          color: 'Primary',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      insert: 'Mail: ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'EMAIL_UTILISATEUR',
          key: 'EMAIL_PROSPECTEUR',
          color: 'Primary',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      attributes: {
        bold: true,
      },
      insert: 'Tel: 06.00.00.00.00',
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'NOM_DE_LA_SOCIETE',
          key: 'NOM_COMPAGNIE',
          color: 'DarkPrimary',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      attributes: {
        bold: true,
      },
      insert: '24 RUE DES ALLOUETTES',
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      attributes: {
        bold: true,
      },
      insert: 'XXXXX MAVILLE',
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
  ],
};

export const initialSinglePlotContent = {
  ops: [
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'PRENOM_CONTACT',
          key: 'PRENOM_CONTACT',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'NOM_CONTACT',
          key: 'NOM_CONTACT',
          color: 'Green',
        },
      },
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_ENTREE_BATIMENT',
          key: 'CONTACT_ADRESSE_2',
          color: 'Green',
        },
      },
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_NUMERO_DE_RUE',
          key: 'CONTACT_ADRESSE_1',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_RUE_DE_L_ADRESSE',
          key: 'CONTACT_ADRESSE_3',
          color: 'Green',
        },
      },
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_BP_OU_LIEU_DIT',
          key: 'CONTACT_ADRESSE_4',
          color: 'Green',
        },
      },
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_CODE_POSTAL',
          key: 'CONTACT_CODE_POSTAL',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_VILLE',
          key: 'CONTACT_VILLE',
          color: 'Green',
        },
      },
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'CONTACT_PAYS',
          key: 'CONTACT_PAYS',
          color: 'Green',
        },
      },
    },
    {
      attributes: {
        align: 'right',
      },
      insert: '\n',
    },
    {
      insert: '\n\nBonjour ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'NOM_CONTACT',
          key: 'NOM_CONTACT',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'PRENOM_CONTACT',
          key: 'PRENOM_CONTACT',
          color: 'Green',
        },
      },
    },
    {
      insert: ' ,\nJe me permets de vous contacter concernant la parcelle de ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'SURFACE_PARCELLE',
          key: 'SURFACE_PARCELLE',
          color: 'Orange',
        },
      },
    },
    {
      insert: ' m',
    },
    {
      attributes: {
        script: 'super',
      },
      insert: '2 ',
    },
    {
      insert: 'en zonage ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'ZONE_PLU_PARCELLE',
          key: 'ZONE_PARCELLE',
          color: 'Orange',
        },
      },
    },
    {
      insert: ' du PLU de la commune, que vous détenez à ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'ADRESSE_COMPLETE_PARCELLE',
          key: 'ADRESSE_PARCELLE',
          color: 'Orange',
        },
      },
    },
    {
      insert: ".\n\nEn tant qu'agent ",
    },
    {
      attributes: {
        bold: true,
      },
      insert: 'immobilier/prospecteur foncier,',
    },
    {
      insert:
        " j'ai récemment identifié un potentiel de valorisation intéressant pour l'ensemble de ces biens. Je serais ravi de vous fournir une analyse détaillée et des informations complémentaires à ce sujet.\n\nSi vous êtes ",
    },
    {
      attributes: {
        bold: true,
      },
      insert: 'intéressé(e)',
    },
    {
      insert:
        ", nous pourrions convenir d'un rendez-vous afin de discuter des différentes options de valorisation de votre bien.\n\nN'hésitez pas à me contacter afin de convenir d'un point qui me permettra de répondre à vos interrogations.\n\nDans l'attente de votre retour, je vous prie d'agréer, l'expression de mes salutations distinguées.\n\nBien cordialement,\n\n",
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'NOM_UTILISATEUR',
          key: 'NOM_PROSPECTEUR',
          color: 'Primary',
        },
      },
    },
    {
      insert: ' ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'PRENOM_UTILISATEUR',
          key: 'PRENOM_PROSPECTEUR',
          color: 'Primary',
        },
      },
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      insert: 'Mail: ',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'EMAIL_UTILISATEUR',
          key: 'EMAIL_PROSPECTEUR',
          color: 'Primary',
        },
      },
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      attributes: {
        bold: true,
      },
      insert: 'Tel: 06.00.00.00.00',
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      insert: {
        TagBadge: {
          marker: '',
          title: 'NOM_DE_LA_SOCIETE',
          key: 'NOM_COMPAGNIE',
          color: 'DarkPrimary',
        },
      },
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      attributes: {
        bold: true,
      },
      insert: '24 RUE DES ALLOUETTES',
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      attributes: {
        bold: true,
      },
      insert: 'XXXXX MAVILLE',
    },
    {
      attributes: {
        align: 'center',
      },
      insert: '\n',
    },
    {
      insert: '\n',
    },
  ],
};
