import { useAppSelector } from '../../../../App/store';
import { getExternalPdfErrialState } from '../../externalPdfSlice';
import styles from '../styles/prospecForm.module.scss';
import ProspecPriceStats from './ProspecPriceStats';
import ProspecSimilarAdsContainer from './ProspecSimilarAdsContainer';

function ProspecForm() {
  const { prospecDatas } = useAppSelector(getExternalPdfErrialState);
  const landType = prospecDatas.landType?.title ?? null;

  return (
    <div
      className={[
        styles.prospecForm,
        prospecDatas.comparableAds.result ? '' : styles.noOverflow,
      ].join(' ')}
    >
      <p className={styles.landType}>{landType ?? 'Terrain'}</p>

      <ProspecPriceStats />

      <ProspecSimilarAdsContainer />
    </div>
  );
}
export default ProspecForm;
