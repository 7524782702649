import { endOfDay, endOfMonth, startOfDay, startOfMonth } from 'date-fns';

import {
  checkValidStartEnd,
  createDateFromString,
  noFutureDate,
} from '../../shared/utils/dateFunctions';
import DatePickerCustom from '../Common/DatePickerCustom';
import styles from './dateFilter.module.scss';
interface IDateFilterProps {
  label: string;
  onChange: (value: DateFilterType) => void;
  dates: DateFilterType;
  minDate: string;
  hasDay?: boolean;
}

function DateFilter({ label, onChange, dates, minDate, hasDay }: IDateFilterProps) {
  const handleChange = (e: Date | null, key: keyof DateFilterType) => {
    if (e) {
      let date = e;
      if (!hasDay) {
        date = key === 'start' ? startOfMonth(e) : endOfMonth(e);
      } else {
        date = key === 'start' ? startOfDay(e) : endOfDay(e);
      }
      // can't get futur date
      const validDate = noFutureDate(date);

      let value = { ...dates, [key]: validDate.toISOString() };
      // check if end > start
      value = checkValidStartEnd(value);

      onChange(value);
    } else {
      // if e === null
      onChange({ ...dates, [key]: null });
    }
  };

  return (
    <div className={styles.dateFilterContainer}>
      <p>{label}</p>
      <div className={styles.dateFilter}>
        <DatePickerCustom
          value={createDateFromString(dates.start)}
          onChange={(e: Date | null) => handleChange(e, 'start')}
          format="dd-MM-yyyy"
          minDate={new Date(minDate)}
          maxDate={new Date()}
          hasDay={hasDay}
        />

        <div className={styles.endSpace}>et</div>

        <DatePickerCustom
          value={createDateFromString(dates.end)}
          onChange={(e: Date | null) => handleChange(e, 'end')}
          format="dd-MM-yyyy"
          minDate={new Date(minDate)}
          hasDay={hasDay}
        />
      </div>
    </div>
  );
}

export default DateFilter;
