import SupportEmailLink from '../../../../../components/Common/SupportEmailLink';

function EmailLinkMessage() {
  return (
    <p>
      Veuillez réessayer ultérieurement ou contacter notre support.
      <SupportEmailLink />
    </p>
  );
}

export default EmailLinkMessage;
