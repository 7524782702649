import { Button } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import usePlotStudyStatus from '../../../App/hooks/usePlotStudyStatus';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import AutocompleteCustom from '../../../components/Common/FormComponents/AutocompleteCustom';
import FilterSortBloc from '../../../components/filterSortBloc';
import SectionHeader from '../../../components/LeftPanelModal/MenuTabs/Prospection/sectionHeader';
import PrioritySelect from '../../../components/RightPanelModal/TabsContent/bodys/components/prospection/Management/prioritySelect/PrioritySelect';
import ProspectionContainer from '../../../components/RightPanelModal/TabsContent/bodys/components/prospection/ProspectionContainer';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { getCompanyState } from '../../company/companySlice';
import AutocompleteInputCities from '../../company/subscription/AutocompleteInputCities';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../displayManager/displayManagerSlice';
import { fetchAllFolderPlotStudiesForDisplayThunk } from '../../displayManager/services/thunks/fetchAllFolderAndSubsPlotStudiesForDisplayThunk';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { modalsActions } from '../../modals/modalsSlice';
import useUsers from '../../users/useUsers';
import Folder from '../folder';
import { foldersActions } from '../foldersSlice';
import { fetchFoldersFilteredThunk } from '../services/thunks/foldersThunk';
import useFolder from '../useFolder';
import styles from './folderContainer.module.scss';

const FolderContainer = () => {
  const dispatch = useAppDispatch();
  usePlotStudyStatus();
  const { usersForAutocomplete } = useUsers({});
  const { statusForAutocomplete } = usePlotStudyStatus();
  const { filteredFolders, filteredFoldersApiStatus } = useFolder({
    forceLoadFiltered: true,
  });
  const { allFoldersDisplayed, isFoldersPlotGeomLoading } = useAppSelector(
    getDisplayManagerState
  );
  const { entities } = useAppSelector(getDisplayManagerState);
  const { companyIdIri, companyId } = useAppSelector(getCompanyState);
  const cookieName = 'inputValueFilterSortFolder' + companyId;
  const [cookies, setCookie, removeCookie] = useCookies([cookieName]);
  const [sortKey, setSortKey] = useState<string>(cookies[cookieName]?.sortKey ?? '');
  const [order, setOrder] = useState<Order>(cookies[cookieName]?.order ?? 'asc');
  const [filter, setFilter] = useState<IFilterFolder>({
    user: cookies[cookieName]?.user ?? [],
    status: cookies[cookieName]?.status ?? [],
    priority: cookies[cookieName]?.priority ?? 'none',
    city: cookies[cookieName]?.city ?? [],
  });

  useEffect(() => {
    return () => {
      //close all deployerd folders on close panel
      dispatch(foldersActions.allDeployedFoldersReset());
    };
  }, []);

  const handleChange = (data: IFilterFolder) => {
    setFilter(data);
  };

  const handleFilterSortSearch = () => {
    setCookie(cookieName, { ...filter, sortKey, order });
    dispatch(fetchFoldersFilteredThunk({ companyIdIri }));
  };

  const handleClearSearch = () => {
    setFilter({
      user: [],
      status: [],
      priority: 'none',
      city: [],
    });
    setSortKey('');
    setOrder('asc');
    removeCookie(cookieName);
    dispatch(fetchFoldersFilteredThunk({ companyIdIri }));
  };

  const handleDisplayAllFolders = (e: ChangeEventCustom) => {
    if (e.target.checked) {
      dispatch(
        fetchAllFolderPlotStudiesForDisplayThunk({ folders: filteredFolders })
      );
    } else {
      dispatch(displayManagerActions.entitiesRemoveByType('folder'));
    }
  };

  const handleFolderModal = () => {
    dispatch(modalsActions.folderEdit(true));
  };

  return (
    <div className={styles.folderSection}>
      <SectionHeader
        checkAll={allFoldersDisplayed}
        handleCheckAllChange={handleDisplayAllFolders}
        handleAdd={handleFolderModal}
        disabled={
          entities.apiStatus === APIStatus.PENDING || isFoldersPlotGeomLoading
        }
        type="dossier"
      />

      <ProspectionContainer title="Filtre Dossiers">
        <div className={styles.filter}>
          <AutocompleteCustom
            options={usersForAutocomplete}
            value={filter.user}
            placeholder="Filtrer par responsable"
            multiple
            onChange={(value: any) => {
              handleChange({ ...filter, user: value as ISelectItem[] });
            }}
          />
          <AutocompleteCustom
            options={statusForAutocomplete()}
            value={filter.status}
            placeholder="Filtrer par statut"
            multiple
            onChange={(value: any) => {
              handleChange({ ...filter, status: value as any[] });
            }}
          />
          <AutocompleteInputCities
            multiple
            selectedCity={filter.city}
            onChange={(value: AutocompleteCity | AutocompleteCity[] | null) => {
              handleChange({ ...filter, city: value as AutocompleteCity[] | null });
            }}
          />
          <PrioritySelect
            value={filter.priority}
            onUpdate={(value: UpdateStudyManagmentProps) => {
              handleChange({ ...filter, ...value } as IFilterFolder);
            }}
            className={styles.prioritySelect}
            displayLabel={false}
            noValuePriority
          />
          <FilterSortBloc
            setSortKey={setSortKey}
            setOrder={setOrder}
            selectItems={[
              { value: '', display: 'Tri initial' },
              { value: 'name', display: 'Nom' },
              { value: 'responsable', display: 'Responsable' },
              { value: 'status', display: 'Statut' },
              { value: 'priority', display: 'Priorité' },
              { value: 'inseeCode', display: 'Commune' },
            ]}
            valueOrder={order}
            valueSortKey={sortKey}
            hasSortPlaceholder={false}
            fullWidth
          />
          <div className={styles.buttons}>
            <Button onClick={handleFilterSortSearch}>Appliquer</Button>
            <Button onClick={handleClearSearch}>Effacer</Button>
          </div>
        </div>
      </ProspectionContainer>

      <div className={styles.folderContainer}>
        {filteredFoldersApiStatus === APIStatus.PENDING ? (
          <IndividualCircularLoader size={100} />
        ) : isEmpty(filteredFolders) ? (
          <p className={styles.noElement}>Aucun dossier</p>
        ) : (
          filteredFolders
            ?.filter((f) => !f.parent)
            .map((folder) => (
              <Folder key={nanoid()} folder={folder} isSubfolder={false} />
            ))
        )}
      </div>
    </div>
  );
};

export default FolderContainer;
