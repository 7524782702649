import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './filterSortBloc.module.scss';

interface ISortOrderOwnerDatas {
  order: OwnerOrder;
  onOrderChange: (value: OwnerOrder, type: string) => void;
  disabled?: boolean;
}

export default function SortOrderOwnerDatas({
  order,
  onOrderChange,
  disabled,
}: ISortOrderOwnerDatas) {
  const handleOrderChange = (order: OwnerOrder): void => {
    onOrderChange(order, 'order');
  };

  return (
    <div className={styles.sortButtons}>
      <button onClick={() => handleOrderChange('desc')} disabled={disabled}>
        <ExpandMoreIcon
          className={`${styles.icon} ${styles.desc}  ${
            order === 'desc' ? styles.selected : ''
          }
           ${disabled ? styles.disabled : ''}`}
        />
      </button>
      <button onClick={() => handleOrderChange('asc')} disabled={disabled}>
        <ExpandMoreIcon
          className={`${styles.icon} ${styles.asc}  ${
            order === 'asc' ? styles.selected : ''
          }
           ${disabled ? styles.disabled : ''}`}
        />
      </button>
    </div>
  );
}
