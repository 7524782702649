import axios, { AxiosInstance, AxiosResponse } from 'axios';

/**
 * Error parser for Generic crud catch error
 * @param {any} error - catch error
 * @param {string} defaultMessage - default message if error message is empty
 * @returns parsed error object
 */
export const errorParser = (error: APIError, defaultMessage: string) => {
  return {
    status: error.status ?? 500,
    message: error.message ?? defaultMessage,
  };
};
export const InternalError = (error: any): APIError => {
  return {
    message: 'Erreur durant le requetage',
    status: 500,
    error: { ...error },
  };
};

export const timeoutError = (error: any): APIError => {
  return { message: 'timeout', status: 500, error: { ...error } };
};

export const paramsNotFound = (info?: string): APIError => {
  return {
    message: `one or more param are missing ${info ?? ''}`,
    status: 422,
  };
};
export const paramsNotFoundText = (info?: string): string => {
  return `one or more param are missing ${info ?? ''}`;
};

export const error504Traitment = async (config: any) => {
  return await new Promise((resolve, reject) => {
    axios(config)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};

export const onFullfilledRequest = (response: AxiosResponse) => {
  return response;
};

export const onRejectedPublicResponse = (error: any) => {
  const status = error?.response?.status ?? error.response.data?.code;
  const message =
    error?.response?.data?.message ??
    error?.response?.data?.detail ??
    error?.response?.data?.title;

  if (status) {
    switch (status) {
      case 401:
        return Promise.reject({ status, message });
      case 504:
        return error504Traitment(error.config)
          .then((response) => response)
          .catch((err) => err);
      default:
        // return only status and message
        return Promise.reject({
          status: status ?? 500,
          message: message ?? 'Inconnu',
        });
    }
  } else {
    return Promise.reject<APIError>(InternalError);
  }
};

export const setPublicResponseInterceptor = (request: AxiosInstance) => {
  request.interceptors.response.use(onFullfilledRequest, onRejectedPublicResponse);
};
