import { axiosTokenInstance } from '../../services/AxiosProtectedInstance';

const SPLICE_CONSTANT = 20;

const parseParams = (params: { fullPlotId: string; lat: number; lng: number }[]) => {
  return params.reduce((p, c) => {
    p[c.fullPlotId] = {
      full_plot_id: c.fullPlotId,
      lon: c.lng,
      lat: c.lat,
    };
    return p;
  }, {} as PluZoneRequestParams);
};

export async function fetchPluZoneFromPlotList(params: PluZoneRequestParams) {
  try {
    const response = await axiosTokenInstance().post<PlotIdsPluZone>(
      `/data_urbanease/get_plu_zone_from_parcels_list/`,
      params
    );
    const d = { ...response.data };
    type Key = keyof typeof d;
    const keys = Object.keys(d) as Key[];

    for (let i = 0; i < keys.length; i++) {
      const k = keys[i];
      if (d[k].plu_zone.zone === 'Information manquante') {
        d[k].plu_zone.zone = null;
        d[k].plu_zone.zone_child = null;
      } else if (d[k].plu_zone.zone_child === '') {
        d[k].plu_zone.zone_child = null;
      }
    }

    return d;
  } catch (error) {
    return {};
    // return Promise.reject(error);
  }
}
export async function fetchPluZoneFromPlotListSlice(
  params: { fullPlotId: string; lat: number; lng: number }[]
) {
  try {
    const plots = [...params];
    const requests: Promise<PlotIdsPluZone>[] = [];

    while (plots.length) {
      const splicedValues = plots.splice(0, SPLICE_CONSTANT);

      const params = parseParams(splicedValues);

      requests.push(fetchPluZoneFromPlotList(params));
    }

    const result = await Promise.all(requests);

    let responsePluZones: PlotIdsPluZone = {};

    // regroup all spliced requests
    result.forEach((element) => {
      responsePluZones = { ...responsePluZones, ...element };
    });

    return responsePluZones;
  } catch (error) {
    return Promise.reject(error);
  }
}
