import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalError } from '../../services/axiosFiles/axiosUtils';
import { postEntity } from '../../services/axiosFiles/genericCrud';
import { classicErrorMessage } from '../../shared/errorMessages';
import { loadersActions } from '../loaders/loaderSlice';
import { modalsActions } from '../modals/modalsSlice';
import usersFetch from './services/usersFetch';
import { userApiToStoreParser } from './usersParser';
import { userUpdate } from './usersRequest';

export const fetchUsersThunk = createAsyncThunk(
  'users/fetchUsersThunk',
  async (params: { companyIdIri: string | null }) => {
    try {
      if (!params.companyIdIri) throw new Error(classicErrorMessage.noCompanyId);

      const result = await usersFetch(params.companyIdIri);

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const userCreateThunk = createAsyncThunk(
  'users/createUserThunk',
  async (
    params: {
      user: Pick<
        User,
        'firstName' | 'lastName' | 'email' | 'plainPassword' | 'roles'
      > | null;
      companyIdIri: string | null;
    },
    { dispatch }
  ) => {
    const { user, companyIdIri } = params;

    if (user && companyIdIri) {
      try {
        dispatch(loadersActions.loaderShow());

        // create user without company
        const result = await postEntity({
          endpoint: `/users`,
          body: { ...user, isActive: true },
        });
        const parsedUser = await userApiToStoreParser(result);

        // add user to selected company
        await postEntity({
          endpoint: `/company_users`,
          body: {
            company: companyIdIri,
            user: parsedUser.idIri,
            roles: parsedUser.roles,
          },
        });

        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: false,
            isOpen: true,
          })
        );
        return parsedUser;
      } catch (error) {
        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: false,
            isOpen: true,
          })
        );
        return Promise.reject(InternalError(error));
      }
    } else {
      dispatch(loadersActions.loaderHide());
      return Promise.reject({
        status: 422,
        message: 'comanyIdIri param missing or user value missing',
      });
    }
  }
);
export const userUpdateThunk = createAsyncThunk(
  'users/updateUserThunk',
  async (
    params: {
      userIdIri: string;
      user:
        | Pick<User, 'firstName' | 'lastName' | 'email'>
        | Pick<User, 'plainPassword'>;
    },
    { dispatch }
  ) => {
    const { userIdIri, user } = params;

    if (user && userIdIri) {
      try {
        dispatch(loadersActions.loaderShow());
        const result = await userUpdate(userIdIri, user);

        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: true,
            isOpen: true,
          })
        );
        return result;
      } catch (error) {
        dispatch(loadersActions.loaderHide());
        dispatch(
          modalsActions.addUserResult({
            isEdit: false,
            isOpen: true,
          })
        );
        return Promise.reject(InternalError(error));
      }
    } else {
      dispatch(loadersActions.loaderHide());
      return Promise.reject({
        status: 422,
        message: 'user value missing',
      });
    }
  }
);
export const userPasswordUpdateThunk = createAsyncThunk(
  'users/userPasswordUpdateThunk',
  async (params: { userIdIri: string; user: Partial<User> }) => {
    const { userIdIri, user } = params;

    if (user && userIdIri) {
      try {
        const result = userUpdate(userIdIri, user);

        return result.then(
          (response) => {
            return response;
          },
          (err) => {
            return Promise.reject(err);
          }
        );
      } catch (error) {
        return Promise.reject(InternalError(error));
      }
    } else {
      return Promise.reject({
        status: 422,
        message: 'user value missing',
      });
    }
  }
);
