import { Button } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { modalsActions } from '../../modals/modalsSlice';
import { getStudyState } from '../slices/studySlice';

export default function DeleteStudyButton() {
  const { study } = useAppSelector(getStudyState);

  const dispatch = useAppDispatch();

  const handleClick = (): void => {
    if (study.result) {
      dispatch(modalsActions.plotDelete(true));
    }
  };
  return (
    <Button className="delete-button" onClick={handleClick}>
      SUPPRIMER CETTE PARCELLE
    </Button>
  );
}
