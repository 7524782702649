const monthsFr = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre',
];
const currentMonth = new Date().getMonth();
export const labelsLine = monthsFr
  .slice(currentMonth + 1)
  .concat(monthsFr.slice(0, currentMonth + 1));
