import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { deleteProcedureThunk, fetchProceduresThunk } from './proceduresThunk';

const initialState: ProceduresState = {
  procedures: { apiStatus: APIStatus.IDLE, result: null },
  procedureForAction: null,
  proceduresCount: 0,
};

const proceduresSlice = createSlice({
  name: 'procedures',
  initialState,
  reducers: {
    procedureForActionSet: (state, action: PayloadAction<Procedure>) => {
      state.procedureForAction = action.payload;
    },
    procedureForActionReset: (state) => {
      state.procedureForAction = null;
    },
    ApiStatusReset: (state) => {
      state.procedures.apiStatus = APIStatus.IDLE;
      state.procedures.error = undefined;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchProceduresThunk.pending, (state) => {
        state.procedures.apiStatus = APIStatus.PENDING;
        state.procedures.error = undefined;
      })
      .addCase(
        fetchProceduresThunk.fulfilled,
        (state, action: PayloadAction<Procedures>) => {
          state.procedures.apiStatus = APIStatus.IDLE;
          state.procedures.error = undefined;
          state.procedures.result = action.payload;
          state.proceduresCount = action.payload.length;
        }
      )
      .addCase(
        fetchProceduresThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.procedures.apiStatus = APIStatus.PENDING;
          state.procedures.error = action.payload;
        }
      )
      .addCase(deleteProcedureThunk.pending, (state) => {
        state.procedures.apiStatus = APIStatus.PENDING;
        state.procedures.error = undefined;
      })
      .addCase(deleteProcedureThunk.fulfilled, (state) => {
        state.procedures.apiStatus = APIStatus.FULFILLED;
        state.procedures.error = undefined;
        state.procedureForAction = null;
      })
      .addCase(
        deleteProcedureThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.procedures.apiStatus = APIStatus.REJECTED;
          state.procedures.error = action.payload;
        }
      ),
});

export default proceduresSlice.reducer;
export const procedureActions = proceduresSlice.actions;
export const getProceduresState = (state: RootState) => state.procedures;
