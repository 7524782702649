import { isEmpty } from 'lodash';
import { updateEntity } from '../../../services/axiosFiles/genericCrud';
import { companyApiToStoreParser } from '../companyParser';

async function updateCompany(
  idIri: string | null,
  body: Partial<Omit<Company, 'idIri'>>
) {
  try {
    if (!idIri) throw new Error('No company idIri found');
    if (isEmpty(body)) throw new Error('No body or empty body found');

    const reqBody: any = {
      name: body.name,
      address: body.address,
      zipCode: body.postalCode,
      city: body.city,
      country: body.country,
      phone: body.phone,
      logoUrl: body.logoUrl,
    };

    const result = await updateEntity({ idIri, body: reqBody });

    const parsed = companyApiToStoreParser(result);

    return parsed;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default updateCompany;
