import { memo } from 'react';
import { useFormContext } from 'react-hook-form';
import SelectFormCustom from '../../../../components/Common/FormComponents/SelectFormCustom';
import styles from '../advancedPlotSearchContainer.module.scss';
import { buildingTypes } from '../formSchema';
import PlotCalculationInputGroup from '../plotCalculation/PlotCalculationInputGroup';
import BuildingTextField from './BuildingTextField';

const Building = (): React.ReactElement => {
  const { control } = useFormContext();

  return (
    <div className={styles.buildingContainer}>
      <div className={styles.buildingContent}>
        <div className={styles.selectField}>
          <SelectFormCustom
            items={buildingTypes?.map((e) => ({ value: e, display: e })) ?? []}
            name="types"
            displayEmpty
            noValue="Toutes Typologies"
            noChoiceDisabled={false}
          />
        </div>

        <div className={styles.inputContainer}>
          <BuildingTextField
            name="empriseBatiMin"
            label="Emprise au sol bâtie mini"
            placeHolder="Surface en m²"
            keysToTrigger={['empriseBatiMin', 'empriseBatiMax']}
          />

          <BuildingTextField
            name="empriseBatiMax"
            label="Emprise au sol bâtie max"
            placeHolder="Surface en m²"
            keysToTrigger={['empriseBatiMin', 'empriseBatiMax']}
          />
        </div>

        <PlotCalculationInputGroup
          min={'minBuiltPercent'}
          max={'maxBuiltPercent'}
          placeHolder="Surface en %"
        />

        <div className={styles.inputContainer}>
          <BuildingTextField
            name="buildingHeightMin"
            label="Hauteur du bâti mini"
            placeHolder="Surface en m²"
            isLabelOther
            keysToTrigger={['buildingHeightMin', 'buildingHeightMax']}
          />
          <BuildingTextField
            name="buildingHeightMax"
            label="Hauteur du bâti max"
            placeHolder="Surface en m²"
            isLabelOther
            keysToTrigger={['buildingHeightMin', 'buildingHeightMax']}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Building);
