export const emailContent = (
  parcelleId: string,
  city: string,
  address: string,
  firstname = '',
  lastname = ''
): string => {
  return `?subject=Demande coordonnées propriétaires – Parcelle ${parcelleId}&body=
    Madame, Monsieur,%0D%0A
    Pourriez-vous s’il vous plaît me communiquer le nom et les coordonnées du propriétaire de la parcelle portant la référence cadastrale ${parcelleId},
    située sur la commune de ${city} à l’ adresse suivante : ${address}?

    Je vous remercie par avance et me tiens à votre disposition pour tout complément d'information
    %0D%0A
    %0D%0A
    %0D%0A
    Bien cordialement.
    %0D%0A
    %0D%0A
    ${firstname} ${lastname}
    %0D%0A
    %0D%0A`;
};

export const emailContact = (
  user: User | null,
  parcelleId: string,
  city: string,
  address: string
): string => {
  return `?subject=Demandes concernant la Parcelle ${parcelleId}&body=
    Madame, Monsieur,%0D%0A
    Je vous contacte concernant la parcelle portant la référence cadastrale ${parcelleId}, située sur la commune de ${city} à l' adresse suivante : ${address}?
    %0D%0A
    %0D%0A
    Je vous remercie par avance et me tiens à votre disposition pour tout complément d'information.
    %0D%0A
    %0D%0A
    %0D%0A
    Bien cordialement.
    %0D%0A
    %0D%0A
    ${user?.firstName ?? ''} ${user?.lastName ?? ''}
    %0D%0A
    %0D%0A`;
};
