import { nanoid } from '@reduxjs/toolkit';
import EmailLinkMessage from './EmailLinkMessage';

function GenericErrorMessage({
  texts,
  noEmail,
}: {
  texts: string[];
  noEmail?: boolean;
}) {
  return (
    <>
      {texts.map((m) => (
        <p key={nanoid()}>{m}</p>
      ))}
      {!noEmail && <EmailLinkMessage />}
    </>
  );
}

export default GenericErrorMessage;
