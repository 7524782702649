import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';

const ArrowGreenSvg = () => (
  <Svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 -3.91654e-07 10 -8.74228e-07C4.48 -1.3568e-06 1.3568e-06 4.48 8.74228e-07 10C3.91654e-07 15.52 4.48 20 10 20ZM10 7L14 11L6 11L10 7Z"
      fill="#73CF77"
    />
  </Svg>
);

export default ArrowGreenSvg;
