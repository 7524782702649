import { Circle, Svg } from '@react-pdf/renderer';

function SelectedEnvSvg() {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16">
      <Circle cx="8" cy="8" r="7" fill="white" stroke="#5555A0" strokeWidth="2" />
    </Svg>
  );
}

export default SelectedEnvSvg;
