import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { fontStyles } from '../styles/pdfStyles';
import ComparativeStudiesTableRow from './ComparativeStudiesTableRow';
import ComparativeStudiesTableRowHeader from './ComparativeStudiesTableRowHeader';
import ArrowGreenSvg from './svg/ArrowGreenSvg';
import ArrowRedSvg from './svg/ArrowRedSvg';

interface IComparativeStudiesTableProps {
  title: string;
  ads: FormatedProspecDataAd[];
  high?: boolean;
  cheap?: boolean;
}

function ComparativeStudiesTable({
  title,
  ads,
  high,
  cheap,
}: IComparativeStudiesTableProps) {
  return (
    <View style={styles.section}>
      <View style={styles.titleContainer}>
        {high && (
          <View style={styles.svg}>
            <ArrowRedSvg />
          </View>
        )}
        {cheap && (
          <View style={styles.svg}>
            <ArrowGreenSvg />
          </View>
        )}
        <Text style={styles.tableTitle}>{` ${title}`}</Text>
      </View>
      {!isEmpty(ads) ? (
        <View style={styles.table}>
          <ComparativeStudiesTableRowHeader />
          {ads &&
            ads.map((elt) => (
              <ComparativeStudiesTableRow row={elt} key={nanoid()} />
            ))}
        </View>
      ) : (
        <Text style={styles.emptySelection}>Aucun bien selectionné</Text>
      )}
    </View>
  );
}

export default ComparativeStudiesTable;

const styles = StyleSheet.create({
  section: {
    padding: 10,
    // flexGrow: 1,
  },
  titleContainer: { display: 'flex', flexDirection: 'row', alignItems: 'center' },

  emptySelection: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: '20px',
  },
  tableTitle: {
    ...fontStyles.cOrpi,
    ...fontStyles.b700,
    marginBottom: 5,
    fontSize: 15,
  },
  table: {
    display: 'flex',
    width: 'auto',
  },
  svg: {
    marginLeft: '5px',
  },
});
