export const wrapingColorValues = [
  'PRIMARY',
  'DARK_PRIMARY',
  'RED',
  'ERROR',
  'DARK_ERROR',
  'GREEN',
  'SUCCESS',
  'ORANGE',
  'WARNING',
  'BLACK',
  'GREY',
  'WHITE',
  'TRANSPARENT',
] as const;
