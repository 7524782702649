import polygonCentroid from './centroidCalcul';

export class MyPaintSymbolizer {
  draw(context: any, geom: any, properties: any, attr: any) {
    const stroke = '#000';
    let width = 2;

    // if want to change buiding with another color or size
    if (attr.props.is_parcel !== true) {
      width = 1;
    }

    context.fillStyle = 'transparent';
    context.strokeStyle = stroke;
    context.lineWidth = width;
    context.beginPath();

    for (const poly of geom) {
      for (let p = 0; p < poly.length; p++) {
        const pt = poly[p];
        if (p === 0) context.moveTo(pt.x, pt.y);
        else context.lineTo(pt.x, pt.y);
      }
    }

    context.stroke();
    context.fill();
  }
}

export class MyLabelSymbolizer {
  place(layout: any, geom: any, feature: any) {
    const pt = geom[0][0];

    const name = feature.props.parcel_id;
    let font = '';

    if (name) {
      const center = polygonCentroid(geom[0]);

      switch (layout.zoom) {
        // 500 for normal, 600 for bold => '500 10px sans-serif'
        case 19:
          font = '11px sans-serif';
          break;
        case 20:
          font = '12px sans-serif';
          break;
        case 21:
          font = '14px sans-serif';
          break;
        case 22:
          font = '16px sans-serif';
          break;
        case 18:
        default:
          font = '10px sans-serif';
          break;
      }
      layout.scratch.font = font;

      const metrics = layout.scratch.measureText(name);
      const width = metrics.width;
      const ascent = metrics.actualBoundingBoxAscent;
      const descent = metrics.actualBoundingBoxDescent;

      const bbox = {
        minX: center.x - width,
        minY: center.y - ascent,
        maxX: center.x + width,
        maxY: center.y + descent,
      };

      // params and amplacement for text
      const draw = (ctx: any) => {
        ctx.font = font;
        ctx.fillStyle = 'black';
        //display position fot text start
        ctx.fillText(name, -width / 2, 0);
      };

      return [{ anchor: center, bboxes: [bbox], draw: draw }];
    }
  }
}

export const pmtilesUrl4 = (inseeCode: string) =>
  `https://tilesets.urbanease.io/2024_01/${inseeCode.substring(
    0,
    2
  )}/${inseeCode}/${inseeCode}.pmtiles`;
