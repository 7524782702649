import { nanoid } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import createCtx from '../../../App/contexts/GenenricStateContext';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getMapState } from '../../../features/map/mapSlice';
import { getPlotState } from '../../../redux/plot/reducer';
import {
  getPlotServitudesState,
  plotServitudesActions,
} from '../plotServitudesSlice';
import {
  fetchAssietteSupSThunk,
  fetchInfoPctThunk,
  fetchInfoSurfThunk,
  fetchPrescriptionLinThunk,
  fetchPrescriptionPctThunk,
  fetchScotThunk,
} from '../plotServitudesThunks';
import PlotServitudesSection from './PlotServitudesSection';
import styles from './plotServitudesContent.module.scss';

const [ctx, GroupCheckedProvider] = createCtx<string[]>([]);
export const GroupCheckedContext = ctx;
function PlotServitudesContent() {
  const { plotDatas, mapCenter } = useAppSelector(getMapState);
  const { parcelle } = useAppSelector(getPlotState);
  const {
    prescriptionPct,
    prescriptionLin,
    infoSurf,
    infoPct,
    assietteSupS,
    scot,
    activePlotId,
  } = useAppSelector(getPlotServitudesState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      plotDatas?.parcelleLayer?.parcelle &&
      parcelle?.parcelleId &&
      activePlotId !== parcelle?.parcelleId
    ) {
      const pl = plotDatas?.parcelleLayer?.parcelle;

      dispatch(plotServitudesActions.setActivePlotId(parcelle.parcelleId));
      dispatch(
        fetchPrescriptionPctThunk({
          geo: pl,
        })
      );
      dispatch(
        fetchPrescriptionLinThunk({
          geo: pl,
        })
      );
      dispatch(fetchInfoSurfThunk({ geo: pl }));
      dispatch(fetchInfoPctThunk({ geo: pl }));
      dispatch(
        fetchAssietteSupSThunk({
          geo: pl,
        })
      );
      dispatch(fetchScotThunk({ lat: mapCenter[0], lng: mapCenter[1] }));
    }
  }, [plotDatas?.parcelleLayer?.parcelle, parcelle]);

  return (
    <GroupCheckedProvider>
      <div className={styles.plotServitudesLegend}>
        <h2 className={styles.title}>Servitudes impactant la parcelle</h2>
        <p>
          Liste non exhaustive sous réserve de vérification dans les documents
          d&#0039;urbanisme opposables
        </p>
        <h3>Prescriptions Ponctuelles</h3>
        <PlotServitudesSection
          key={nanoid()}
          group="libelle"
          data={prescriptionPct}
          type="ppct"
        />

        <h3>Prescriptions Linéaires</h3>
        <PlotServitudesSection
          key={nanoid()}
          group="libelle"
          data={prescriptionLin}
          type="plin"
        />

        <h3>Informations Surfaciques</h3>
        <PlotServitudesSection
          key={nanoid()}
          group="libelle"
          data={infoSurf}
          type="isurf"
        />

        <h3>Informations Ponctuelles</h3>
        <PlotServitudesSection
          key={nanoid()}
          group="libelle"
          data={infoPct}
          type="ipct"
        />

        <h3>Autres Servitudes</h3>

        <PlotServitudesSection
          key={nanoid()}
          group="typeass"
          data={assietteSupS}
          type="sups"
        />

        <h3>Schema de Cohérence territoriale</h3>
        <PlotServitudesSection
          key={nanoid()}
          clickable={false}
          data={scot}
          type="scot"
        />
      </div>
    </GroupCheckedProvider>
  );
}

export default PlotServitudesContent;
