import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';

const styles = StyleSheet.create({
  bloc: { marginTop: '20px' },
  cont: { marginLeft: 0, width: '100%', display: 'flex', justifyContent: 'center' },
  title: { fontWeight: 400 },
  tabTitles: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabTitle: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
  },
  tabTitleLibelle: {
    width: '60%',
    display: 'flex',
    alignItems: 'center',
  },
  tabRows: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    fontWeight: 300,
    fontSize: '10px',
  },
  rowCase: {
    width: '40%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rowCaseLabel: {
    width: '60%',
  },
  rowCaseNoData: {
    width: '100%',
    paddingTop: '5px',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 300,
    fontSize: '10px',
  },
  endLine: {
    margin: '10px 0',
    width: '100%',
  },
});
interface IAtlasListProps {
  title: string;
  datas: EnvironmentAziTypes;
  borderColor?: string;
  wrap?: boolean;
}

function AtlasAziList(props: IAtlasListProps) {
  const hasData = props.datas.length > 0;
  return (
    <View style={styles.bloc} wrap={!props.wrap}>
      <Text style={styles.title}>{props.title}</Text>
      <View style={styles.cont}>
        <View
          style={[
            styles.tabTitles,
            {
              borderLeft: `1px solid ${
                props.borderColor ? props.borderColor : '#679CC6'
              }`,
              borderTop: `1px solid ${
                props.borderColor ? props.borderColor : '#679CC6'
              }`,
              borderBottom: `1px solid ${
                props.borderColor ? props.borderColor : '#679CC6'
              }`,
            },
          ]}
        >
          <View
            key={nanoid()}
            style={[
              styles.tabTitle,
              {
                borderRight: `1px solid ${
                  props.borderColor ? props.borderColor : '#679CC6'
                }`,
              },
            ]}
          >
            <Text>Code</Text>
          </View>
          <View
            key={nanoid()}
            style={[
              styles.tabTitleLibelle,
              {
                borderRight: `1px solid ${
                  props.borderColor ? props.borderColor : '#679CC6'
                }`,
              },
            ]}
          >
            <Text>Libellé</Text>
          </View>
        </View>
        {hasData ? (
          <View style={styles.tabRows}>
            {props.datas?.map((m) => (
              <View key={nanoid()} style={styles.row}>
                <View style={styles.rowCase}>
                  <Text>{m.code}</Text>
                </View>
                <View style={styles.rowCaseLabel}>
                  <Text>{m.label}</Text>
                </View>
              </View>
            ))}
          </View>
        ) : (
          <View style={styles.rowCaseNoData}>
            <Text>Aucune donnée recensée</Text>
          </View>
        )}
      </View>
      <View
        style={[
          styles.endLine,
          {
            borderTop: `1px solid ${
              props.borderColor ? props.borderColor : '#679CC6'
            }`,
          },
        ]}
      ></View>
    </View>
  );
}

export default AtlasAziList;
