import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../App/store';
import { setStudyParamsThunk } from '../services/thunks/studyParamsThunks';
const initialState: StudyParamsState = {
  type: null,
  idIri: null,
  noCheckExisting: false,
  managmentLoader: false,
};
const studyParamsSlice = createSlice({
  name: 'studyParams',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    setParams: (state, action: PayloadAction<StudyParamsState>) => action.payload,
    setIdIri: (state, action: PayloadAction<string | null>) => {
      state.idIri = action.payload;
    },
    setType: (state, action: PayloadAction<StudyParamsType | null>) => {
      state.type = action.payload;
    },
    setFromFolder: (state, action: PayloadAction<boolean>) => {
      state.noCheckExisting = action.payload;
    },
    setManagmentLoader: (state, action: PayloadAction<boolean>) => {
      state.managmentLoader = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      setStudyParamsThunk.fulfilled,
      (state, action: PayloadAction<StudyParamsState>) => action.payload
    );
  },
});

export default studyParamsSlice.reducer;
export const studyParamsActions = studyParamsSlice.actions;
export const getStudyParamsState = (state: RootState) => state.studyParams;
