import { Checkbox } from '@mui/material';
import React from 'react';
import { useAppDispatch } from '../../../App/store';
import { multiPlotSelectActions } from '../../plot/multiPlotSelectFeature/multiPlotSelectSlice';

interface ICheckboxForAddProps {
  plot: OwnerDataPlot;
  checked: boolean;
}
function CheckboxForAdd({
  plot,
  checked,
}: ICheckboxForAddProps): React.ReactElement {
  const dispatch = useAppDispatch();
  const handleCheckChange = async () => {
    dispatch(multiPlotSelectActions.ownerPlotsSet(plot));
  };

  return (
    <Checkbox
      name={plot.fullPlotId}
      checked={checked}
      onChange={handleCheckChange}
    />
  );
}

export default CheckboxForAdd;
