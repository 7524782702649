import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { addSpaceToNumber } from '../../../../utils/jsFunctions';
import Footer from './components/Footer';
import Header from './components/Header';
import PdfSeparateLine from './components/PdfSeparateLine';
import PlotDetailsLand from './components/PlotDetailsLand';
import UrbanismeInfosLand from './components/UrbanismeInfosLand';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  formDatas: IExternalPdfLandForm;
  agency: PdfLandOrpiAgency | null;
  plotIds: string[];
  pluZone: string;
  plotDatas: {
    parcelsOnCadastrePicture: string;
    parcelsOnZonePicture: string;
    area: number;
    freeGroundArea: number;
    builtArea: number;
    recalculatedArea: number;
    builtHeight: number;
  };
}

function YourGoodPage({ plotDatas, plotIds, pluZone, agency, formDatas }: IProps) {
  return (
    <Page size="A4" style={pageStyles.page}>
      <Header lines={['Votre bien', 'Parlons peu, parlons bien']} />

      <View style={styles.titleContainer}>
        <Text style={[fontStyles.cOrpi, fontStyles.b700, fontStyles.fs20]}>
          Le cadastre de votre terrain
        </Text>
        <Text style={[styles.address, fontStyles.fs14]}>
          Adresse: {`${formDatas.addressStreet} ${formDatas.addressCity}`}
        </Text>
      </View>

      <PlotDetailsLand {...plotDatas} plotIds={plotIds} />

      <View style={styles.plotInfosBloc}>
        <View style={styles.textDatas}>
          <Text style={styles.textDatasLine}>
            Surface du terrain :{' '}
            <Text style={fontStyles.b700}>{`${addSpaceToNumber(
              formDatas.landArea
            )} m²`}</Text>
          </Text>
          <Text style={styles.textDatasLine}>
            Terrain viabilisé:{' '}
            <Text style={fontStyles.b700}>
              {formDatas.servicedLand ? 'Oui' : 'Non'}
            </Text>
          </Text>
        </View>

        <View style={styles.descriptionBloc}>
          <Text style={styles.descriptionTitle}>Description du terrain:</Text>
          <Text style={fontStyles.fs11}>{formDatas.description}</Text>
        </View>
      </View>

      <PdfSeparateLine marginBottom="10px" marginTop="10px" />

      <View style={styles.titleContainer}>
        <Text style={[fontStyles.cOrpi, fontStyles.b700, fontStyles.fs20]}>
          L&#039;urbanisme de votre terrain
        </Text>
      </View>

      <UrbanismeInfosLand
        zone={pluZone}
        parcelsOnZonePicture={plotDatas.parcelsOnZonePicture}
      />

      <Footer agency={agency} formDatas={formDatas} />
    </Page>
  );
}

export default YourGoodPage;

const styles = StyleSheet.create({
  titleContainer: {
    marginBottom: '10px',
  },
  plotInfosBloc: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '10px',
  },
  textDatas: {
    width: '220px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  textDatasLine: { paddingVertical: '3px' },
  descriptionBloc: {
    flex: 1,
    padding: '5px',
    backgroundColor: '#f1f1f7',
    minHeight: '100px',
  },
  descriptionTitle: { fontWeight: 700 },
  address: { paddingTop: '10px' },
});
