import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../App/store';
import dashboardFrame from '../../../assets/images/dashboard-frame.svg';
import { favoritePlacesActions } from '../../../redux/favoritePlaces/actions';
import styles from './dashboardButton.module.scss';
export default function DashboarButton() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(favoritePlacesActions.windowDisplay.set(false));
    navigate('/dashboard');
  };

  return (
    <div className={styles.dashboardButton} onClick={handleClick}>
      <img src={dashboardFrame} alt="dashboard-frame" />
      <span>Dashboard</span>
    </div>
  );
}
