import './statusElement.scss';

interface IStatusElementProps {
  status: StudyStatus;
}
export default function StatusElement({ status }: IStatusElementProps) {
  return (
    <div className="status-element-container">
      <div className={`colored-bullet ${status.class}`}></div>
      <div className="status-label">{status.label}</div>
    </div>
  );
}
