import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import fetchParkingPackageThunk from './services/thunks/fetchParkingPackageThunk';
import fetchSocialCriteriaThunk from './services/thunks/fetchSocialCriteriaThunk';

function individualCityDatasExtraReducer(
  builder: ActionReducerMapBuilder<ActivePlotState>
) {
  return (
    builder
      // Parking packages
      .addCase(fetchParkingPackageThunk.pending, (state) => {
        const cityData = state.cityDatas.result;
        cityData.parkingPackages.apiStatus = APIStatus.PENDING;
        cityData.parkingPackages.result = null;
        cityData.parkingPackages.error = undefined;
      })
      .addCase(
        fetchParkingPackageThunk.fulfilled,
        (state, action: PayloadAction<ParkingPackages>) => {
          const cityData = state.cityDatas.result;
          cityData.parkingPackages.apiStatus = APIStatus.IDLE;
          cityData.parkingPackages.result = action.payload;
          cityData.parkingPackages.error = undefined;
        }
      )
      .addCase(fetchParkingPackageThunk.rejected, (state, action) => {
        const cityData = state.cityDatas.result;
        cityData.parkingPackages.apiStatus = APIStatus.REJECTED;
        cityData.parkingPackages.result = null;
        cityData.parkingPackages.error = action.payload;
      })
      // Social criteria
      .addCase(fetchSocialCriteriaThunk.pending, (state) => {
        const cityData = state.cityDatas.result;
        cityData.socialCriteria.apiStatus = APIStatus.PENDING;
        cityData.socialCriteria.result = null;
        cityData.socialCriteria.error = undefined;
      })
      .addCase(
        fetchSocialCriteriaThunk.fulfilled,
        (state, action: PayloadAction<string | null>) => {
          const cityData = state.cityDatas.result;
          cityData.socialCriteria.apiStatus = APIStatus.IDLE;
          cityData.socialCriteria.result = action.payload;
          cityData.socialCriteria.error = undefined;
        }
      )
      .addCase(fetchSocialCriteriaThunk.rejected, (state, action) => {
        const cityData = state.cityDatas.result;
        cityData.socialCriteria.apiStatus = APIStatus.REJECTED;
        cityData.socialCriteria.result = null;
        cityData.socialCriteria.error = action.payload;
      })
  );
}

export default individualCityDatasExtraReducer;
