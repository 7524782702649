import { useAppDispatch } from '../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../components/Modals/GenericModal';

import { modalsActions } from '../../../modals/modalsSlice';
import styles from './subscriptionLimitModal.module.scss';

const SubscriptionLimitModal = () => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(modalsActions.subscriptionLimit(false));
  };

  return (
    <GenericModal
      modalType={GenericModalEnum.WARNING}
      actionType={GenericModalActionEnum.OK}
      okButtonContent="OK"
      title="Limite d'utilisateurs atteinte"
      okCallback={handleClose}
      closeModal={handleClose}
    >
      <div className={styles.subscriptionLimitModal}>
        <p>
          Afin d&#0039;ajouter de nouveaux utilisateurs, veuillez faire une demande
          auprès de notre service commercial{' '}
        </p>
        <p className={styles.supportEmail}>
          <a href="mailto:support@ubanease.io">support@urbanease.io</a>
        </p>
      </div>
    </GenericModal>
  );
};

export default SubscriptionLimitModal;
