import getIdFromIdIri from '../../../lib/parsersTools/getIdFromIdIri';
import { mapObjectParser } from '../../../services/common/parserCommon';
import genericObjectSort from '../../../utils/genericObjectSort';

export const autocompleteCitiesApiToStoreParser = (datas: any[]) => {
  const parsedCities = datas.map((m) => {
    const city = {
      id: m.id,
      inseeCode: m.municipalityCode,
      name: m.name,
      postalCode: m.zipCode,
    };

    return city;
  });

  return parsedCities;
};

export const citiesApiToStoreParser = async (datas: any[]) => {
  try {
    const parsedCities = mapObjectParser<City>(datas, cityApiToStoreParser);

    return parsedCities;
  } catch (error) {
    return Promise.reject({
      status: 0,
      message: 'city parser filed',
      error,
    });
  }
};

export const cityApiToStoreParser = (data: any) => {
  const cities = {
    idIri: data['@id'],
    id: getIdFromIdIri(data['@id']),
    inseeCode: data.municipalityCode,
    name: data.name,
    postalCode: data.zipCode,
  };

  return cities;
};

export const departmentsApiToStoreParser = (datas: any[]) => {
  try {
    const departments: Departments = datas.map((dep) =>
      departmentApiToStoreParser(dep)
    );
    const sortedDepartments = genericObjectSort<Department>(
      departments,
      'departmentCode'
    );

    return sortedDepartments;
  } catch (error) {
    throw new Error('departments parser filed');
  }
};

export const departmentApiToStoreParser = (data: any) => {
  const { code, customCode, isHidden, name, region, regionCode, regionName } = data;

  const department: Department = {
    idIri: data['@id'],
    id: data['@id'].split('/')[2],
    departmentCode: code,
    customCode,
    isHidden,
    name,
    regionIdIri: region,
    regionCode,
    regionName,
  };

  return department;
};
