import { useEffect } from 'react';
import { getAppState } from '../../App/appSlice';
import { useAppDispatch, useAppSelector } from '../../App/store';

import { getAuthState } from '../auth/authSlice';
import { getCompanyState } from '../company/companySlice';
import { getProceduresState } from './proceduresSlice';
import { fetchProceduresThunk } from './proceduresThunk';
import { getStudyState } from '../study/slices/studySlice';

export default function useProcedures() {
  const dispatch = useAppDispatch();
  const { isLogged, userIdIri } = useAppSelector(getAuthState);
  const { companyId } = useAppSelector(getCompanyState);
  const { plotStudyStatuses, procedureTypes, contactRoles } =
    useAppSelector(getAppState);
  const { procedures, proceduresCount, procedureForAction } =
    useAppSelector(getProceduresState);
  const { updateFolder } = useAppSelector(getStudyState);

  useEffect(() => {
    if (
      isLogged &&
      plotStudyStatuses.result &&
      procedureTypes.result &&
      contactRoles.result
    ) {
      dispatch(fetchProceduresThunk());
    }
  }, [
    isLogged,
    plotStudyStatuses.result,
    procedureTypes.result,
    contactRoles.result,
    updateFolder,
  ]);

  const reloadProcedures = () => {
    dispatch(fetchProceduresThunk());
  };

  return { procedures, proceduresCount, procedureForAction, reloadProcedures };
}
