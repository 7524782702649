export default function arrayFilter<T>(tab: T[], value: T) {
  let tabResult: T[] = [];
  if (tab.includes(value)) {
    tabResult = tab.filter((f) => f !== value);
  } else {
    tabResult = tab.concat(value);
  }

  return tabResult;
}

export function arrayFilterByReference<T>(tab: T[], value: T) {
  const index = tab.indexOf(value);
  if (index >= 0) {
    tab.splice(index, 1);
  } else {
    tab.push(value);
  }
}

export async function asyncMap<T, U>(
  arr: T[],
  asyncFn: (val: T) => Promise<U>,
  counter?: (count: number) => void
): Promise<U[]> {
  const results: U[] = [];
  let count = 0;
  for (const item of arr) {
    const result = await asyncFn(item);
    results.push(result);
    count++;
    counter && counter(count);
  }
  return results;
}
