import SectionTitle from '../../SectionTitle';

interface IDpeLegendContainerProps extends IChildren {
  className: string;
}

function DpeLegendContainer({ children, className }: IDpeLegendContainerProps) {
  return (
    <div className={className}>
      <SectionTitle title="DPE" />
      {children}
    </div>
  );
}

export default DpeLegendContainer;
