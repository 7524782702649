import { Navigate, Outlet, useLocation } from 'react-router';
import checkOrpiPdfAllow from '../../features/auth/utils/checkOrpiPdfAllow';
import { getCompanyState } from '../../features/company/companySlice';
import { useAppSelector } from '../store';

function OrpiRoute(props: any) {
  const location = useLocation();
  const { firm } = useAppSelector(getCompanyState);

  const authorizedFirms = checkOrpiPdfAllow(firm ?? '');
  return authorizedFirms ? (
    <Outlet />
  ) : (
    <Navigate
      to={'/'}
      replace
      state={{
        from: location,
      }}
    />
  );
}

export default OrpiRoute;
