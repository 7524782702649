import { Checkbox, FormControlLabel } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { brownfieldsActions, getBrownfieldsState } from '../brownfieldsSlice';
import styles from './brownfields.module.scss';

const useStyles = makeStyles()({
  root: {
    padding: '1px 1px',
  },
  label: {
    fontSize: '13px',
    paddingLeft: '2px',
  },
});

function BrownFieldLegend() {
  const { brownfields, brownfieldsVisible } = useAppSelector(getBrownfieldsState);
  const dispatch = useAppDispatch();
  const { classes } = useStyles();

  const handleBrownfieldsClick = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    dispatch(brownfieldsActions.setDisplayBrownfields(value));
  };

  if (brownfields.apiStatus === APIStatus.PENDING) {
    return <IndividualCircularLoader size={50} />;
  }

  if (brownfields.apiStatus === APIStatus.REJECTED) {
    return <p className={styles.loadError}>Erreur lors du chargement des Friches</p>;
  }

  return (
    <>
      <FormControlLabel
        className={styles.environmentRow}
        classes={classes}
        label="Friches industrielles"
        control={
          <Checkbox
            classes={classes}
            color="primary"
            name="brownfields"
            size={'small'}
            onChange={handleBrownfieldsClick}
            checked={brownfieldsVisible}
          />
        }
      />
    </>
  );
}

export default BrownFieldLegend;
