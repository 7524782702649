import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  urbaGptDeleteThunk,
  urbaGptHistoryThunk,
  urbaGptQueryThunk,
} from './urbaGptThunk';

const initialState: UrbaGptState = {
  conversation: {
    apiStatus: APIStatus.IDLE,
    result: [],
  },
  currentConversationUuid: '',
  showTutorial: true,
  urbaGptIsVisible: false,
  urbaGptInitOpen: false,
};

const urbaGptSlice = createSlice({
  name: 'urbaGpt',
  initialState,
  reducers: {
    setShowTutorial: (state, action: PayloadAction<boolean>) => {
      state.showTutorial = action.payload;
    },
    setUrbaGptIsVisible: (state, action: PayloadAction<boolean>) => {
      state.urbaGptIsVisible = action.payload;
      if (!state.urbaGptInitOpen) state.urbaGptInitOpen = true;
    },
    setConversation: (state, action: PayloadAction<UrbaGptHistory>) => {
      state.conversation.result.push(action.payload);
    },
    setConversationIsAnimFalse: (state) => {
      state.conversation.result.forEach((elt) => (elt.isAnim = false));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(urbaGptQueryThunk.pending, (state) => {
        state.conversation.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        urbaGptQueryThunk.fulfilled,
        (state, action: PayloadAction<UrbaGptHistory>) => {
          state.conversation.apiStatus = APIStatus.FULFILLED;
          state.conversation.result[state.conversation.result.length - 1] =
            action.payload;
          state.currentConversationUuid = action.payload.conversationUuid;
        }
      )
      .addCase(urbaGptQueryThunk.rejected, (state, action: PayloadAction<any>) => {
        state.conversation.apiStatus = APIStatus.REJECTED;
        state.conversation.result[state.conversation.result.length - 1] = {
          conversationUuid: state.currentConversationUuid ?? '',
          datelog: new Date().toLocaleDateString(),
          id: null,
          question: action.payload.question,
          response: `Une erreur est survenue, peut-être liée à la limite de tokens. Pour résoudre, démarrez une nouvelle conversation ou contactez Urbanease si le problème persiste.`,
          isAnim: false,
        };
        state.conversation.error = action.payload.error;
      })
      .addCase(urbaGptHistoryThunk.pending, (state) => {
        state.conversation.apiStatus = APIStatus.PENDING;
      })
      .addCase(urbaGptHistoryThunk.fulfilled, (state, action) => {
        state.conversation = {
          apiStatus: APIStatus.FULFILLED,
          result: action.payload.reverse(),
        };
        state.currentConversationUuid = action.payload[0]?.conversationUuid ?? '';
      })
      .addCase(urbaGptHistoryThunk.rejected, (state) => {
        state.conversation = {
          apiStatus: APIStatus.REJECTED,
          result: [],
        };
        state.currentConversationUuid = '';
      })
      .addCase(urbaGptDeleteThunk.fulfilled, (state) => {
        state.conversation = { apiStatus: APIStatus.IDLE, result: [] };
        state.currentConversationUuid = '';
      })
      .addCase(urbaGptDeleteThunk.rejected, (state) => {
        state.conversation.apiStatus = APIStatus.REJECTED;
      });
  },
});

export default urbaGptSlice.reducer;
export const urbaGptActions = urbaGptSlice.actions;
export const getUrbaGptState = (state: RootState) => state.urbaGpt;
