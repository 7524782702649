import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalError } from '../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import LocalStorageService from '../../services/LocalStorageService';
import { announcementParser } from './announcementParser';

export const fetchAnnouncementThunk = createAsyncThunk(
  'announcement/fetchAnnouncementThunk',
  async ({ lastAuthAt }: { lastAuthAt: string }, { rejectWithValue }) => {
    try {
      let params: {
        'order[updatedAt]': string;
        'updatedAt[before]'?: string;
        'updatedAt[after]'?: string;
        isActive: boolean;
      } = {
        'order[updatedAt]': 'DESC',
        isActive: true,
      };

      if (lastAuthAt) {
        params = {
          ...params,
          'updatedAt[after]': new Date(lastAuthAt).toISOString(),
        };
      }

      const response = await fetchDatas('/announcements', params);
      const parsedResponse = announcementParser(response['hydra:member']);

      LocalStorageService.setLastLoginDate(new Date().toISOString());

      return parsedResponse;
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);
