import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { fontStyles } from '../styles/pdfStyles';
import ImageContainer from './ImageContainer';
interface IUrbanismeInfosProps {
  zone: string;
  parcelsOnZonePicture: string;
}

const styles = StyleSheet.create({
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    marginBottom: '10px',
  },
  smallText: { fontSize: '12px' },
  zone: { color: '#D82B4D' },
  imgContainer: {
    height: '244px',
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '500px',
  },
});
function UrbanismeInfosLand({ zone, parcelsOnZonePicture }: IUrbanismeInfosProps) {
  return (
    <>
      <View style={styles.textContainer}>
        {zone ? (
          <>
            <Text style={fontStyles.fs14}>
              Précision du plan de zonage : la parcelle se trouve en zone
              <Text style={styles.zone}>{` ${zone} `}</Text>
              du PLU
            </Text>
          </>
        ) : (
          <Text style={fontStyles.fs14}>
            Précision du plan de zonage : la parcelle se trouve dans une commune non
            couverte par un PLU ou un PLUI
          </Text>
        )}
      </View>
      <ImageContainer
        localImg={`data:image/jpeg;base64,${parcelsOnZonePicture}`}
        width="100%"
        height="244px"
        borderRadius="15px"
      />
    </>
  );
}

export default UrbanismeInfosLand;
