import { sortableEntityParser } from '../../../utils/sortableEntitiesParser';

export function sortableFolderPlotStudies(plotStudies: PlotStudies) {
  const parse: SortablePlotStudies = plotStudies.map((ps) =>
    sortableEntityParser(ps, {
      city: ps.city,
      status: ps.status?.label ?? '',
      responsable: ps.responsable?.lastName ?? null,
      area: ps.area,
      priority: ps.priority,
    })
  );

  return parse;
}
