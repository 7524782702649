import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { fetchMapLayerEnvThunk } from './mapLayerEnvThunk';

const initialState: MapLayerEnvState = {
  city: null,
  postalCode: null,
  envLayers: { apiStatus: APIStatus.IDLE, result: [] },
};

const mapLayerEnvSlice = createSlice({
  name: 'layerEnv',
  initialState,
  reducers: {
    displayEnv: (state, action: PayloadAction<LayerEnv>) => {
      const ap = action.payload;
      state.envLayers.result = state.envLayers.result.map((e) => {
        return e.cat === ap.cat ? { ...e, displayed: !e.displayed } : e;
      });
    },
    hideAll: (state) => {
      state.envLayers.result = state.envLayers.result.map((e) => {
        return { ...e, displayed: false };
      });
    },
    cityDatasSet: (
      state,
      action: PayloadAction<{ city: string; postalCode: string }>
    ) => {
      const ap = action.payload;
      state.city = ap.city;
      state.postalCode = ap.postalCode;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMapLayerEnvThunk.pending, (state) => {
        state.envLayers.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchMapLayerEnvThunk.fulfilled,
        (
          state,
          action: PayloadAction<{
            city: string;
            postalCode: string;
            envLayers: LayerEnv[];
          }>
        ) => {
          const ap = action.payload;
          state.envLayers.apiStatus = APIStatus.IDLE;
          state.envLayers.result = ap.envLayers;
          state.envLayers.error = undefined;
          state.city = ap.city;
          state.postalCode = ap.postalCode;
        }
      )
      .addCase(
        fetchMapLayerEnvThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.envLayers.apiStatus = APIStatus.REJECTED;
          state.envLayers.error = action.payload;
        }
      );
  },
});

export default mapLayerEnvSlice.reducer;
export const envsActions = mapLayerEnvSlice.actions;
export const getLayerEnvState = (state: RootState) => state.envs;
