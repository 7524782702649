import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import ErrorNoDatas from '../../../components/errors/NoDatasError';
import NoCity from '../../../components/noRights/NoCity';
import { getMapState } from '../../../features/map/mapSlice';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { panelsActions } from '../../panels/panelsSlice';
import { buildPermitsActions, getBuildPermitsState } from '../buildPermitsSlice';
import { fetchBuildPermitsThunk } from '../buildPermitsThunk';
import MarkerLegend from './MarkerLegend';
import styles from './buildPermitsLegend.module.scss';

const BuildPermitsLegend = (): JSX.Element => {
  const { geolocDatas } = useAppSelector(getMapState);
  const buildPermitsState = useAppSelector(getBuildPermitsState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      geolocDatas?.postalCode &&
      geolocDatas?.city &&
      geolocDatas?.city !== buildPermitsState.city &&
      geolocDatas?.inseeCode
    ) {
      dispatch(
        fetchBuildPermitsThunk({
          postalCode: geolocDatas?.postalCode,
          city: geolocDatas?.city,
          inseeCode: geolocDatas?.inseeCode,
        })
      );
    }
  }, [geolocDatas?.inseeCode, geolocDatas?.city, geolocDatas?.postalCode]);

  const changeCheckValue = (value: boolean): void => {
    if (value) {
      dispatch(buildPermitsActions.showBuildPermits());
    } else {
      dispatch(buildPermitsActions.hideBuildPermits());
    }
  };

  const handleRightPanelOpen = () => {
    changeCheckValue(true);
    dispatch(panelsActions.builtPermitContentShow());
  };

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    changeCheckValue(value);
  };

  if (buildPermitsState.buildPermits.apiStatus === APIStatus.PENDING) {
    return <IndividualCircularLoader size={150} />;
  }
  if (buildPermitsState.buildPermits.apiStatus === APIStatus.REJECTED) {
    return <ErrorNoDatas />;
  }

  if (!geolocDatas?.inseeCode) {
    <NoCity />;
  }

  return (
    <>
      <div className={styles.buildPermitsLegend}>
        <FormControlLabel
          label="Afficher les autorisations délivrées"
          className={styles.displayPermits}
          control={
            <Checkbox
              color="primary"
              size={'small'}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={handleCheckbox}
              checked={buildPermitsState.buildPermitsVisible}
            />
          }
        />

        <MarkerLegend />

        <Button className={styles.tableButton} onClick={handleRightPanelOpen}>
          Tableau des autorisations délivrées
        </Button>
      </div>
    </>
  );
};

export default BuildPermitsLegend;
