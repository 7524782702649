import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import {
  displayManagerActions,
  getDisplayManagerState,
} from '../../features/displayManager/displayManagerSlice';
import { dvfActions, getDvfState } from '../../features/dvf/dvfSlice';
import { foldersActions } from '../../features/folders/foldersSlice';
import { mailshotsActions } from '../../features/mailshots/mailshotsSlice';
import { getMapState, mapActions } from '../../features/map/mapSlice';
import { getPanelState, panelsActions } from '../../features/panels/panelsSlice';
import { studyParamsActions } from '../../features/study/slices/studyParamsSlice';
import { studyActions } from '../../features/study/slices/studySlice';
import plotActions from '../../redux/plot/actions';
import { getPlotState } from '../../redux/plot/reducer';
import { useAppDispatch, useAppSelector } from '../store';

function useRightPanel() {
  const dispatch = useAppDispatch();
  const path = useLocation();
  const { dvfVisible } = useAppSelector(getDvfState);
  const { displayBuildingCentroid } = useAppSelector(getPlotState);
  const { townHall } = useAppSelector(getMapState);
  const { rightPanelDisplay } = useAppSelector(getPanelState);
  const { entitiesBackup } = useAppSelector(getDisplayManagerState);

  function closeRightPanel() {
    if (!isEmpty(entitiesBackup)) {
      dispatch(displayManagerActions.backupReplace());
    } else {
      dispatch(displayManagerActions.reset());
    }
    if (path.pathname === '/') {
      dispatch(panelsActions.rightPanelClose());
    } else {
      dispatch(panelsActions.rightPanelCloseOnDashboard());
    }

    dispatch(studyActions.reset());
    dispatch(studyParamsActions.reset());
    dispatch(mailshotsActions.resetWithoutModels());

    dispatch(foldersActions.folderRightPanelDatasReset());
    if (dvfVisible) dispatch(dvfActions.setDvfOnMap(false));
    if (displayBuildingCentroid) dispatch(plotActions.buildingCentroid.set(false));
    if (townHall.displayed)
      dispatch(mapActions.townHallDisplaySet({ displayed: false }));
  }

  return { closeRightPanel, rightPanelDisplay };
}

export default useRightPanel;
