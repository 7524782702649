import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import updateCompany from '../updateCompany';

const updateCompanyThunk = createAsyncThunk(
  'company/updateCompanyThunk',
  async (
    params: { body: Partial<Omit<Company, 'idIri'>>; callback?: () => void },
    thunkOptions
  ) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { companyIdIri } = state.company;

      const result = await updateCompany(companyIdIri, params.body);

      params.callback?.();
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default updateCompanyThunk;
