import { useAppDispatch, useAppSelector } from '../../../App/store';
import { modalsActions } from '../../modals/modalsSlice';
import { getPluState } from '../pluSlice';

function PLUButton() {
  const { pluUrls } = useAppSelector(getPluState);

  const dispatch = useAppDispatch();
  const hasPluLayer = pluUrls.result?.mapboxUrl || pluUrls.result?.pmtilesUrl;

  const handlePluClick = () => {
    dispatch(modalsActions.pluDemand(true));
  };

  return (
    <button className="button-base" onClick={handlePluClick}>
      {hasPluLayer ? 'modifier ou mettre à jour PLU' : 'Demande du PLU'}
    </button>
  );
}

export default PLUButton;
