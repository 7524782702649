import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { useContext } from 'react';
import { useAppSelector } from '../../../../../App/store';
import { getMapState } from '../../../../../features/map/mapSlice';
import { getPlotState } from '../../../../../redux/plot/reducer';
import MailshotLauncherBtn from '../../../../mailshots/components/MailshotLauncherBtn';
import { MailshotsSelectionTypeEnum } from '../../../../mailshots/types/mailshotsEnums';
import { getStudyParamsState } from '../../../slices/studyParamsSlice';
import StudyContactCard from './StudyContactCard';
import { ForceOpenContext } from './StudyContactContent';
import styles from './contact.module.scss';

interface IStudyContactList {
  studyContactList: StudyContacts | null;
  updateAllowed: boolean;
}

function StudyContactList({ studyContactList, updateAllowed }: IStudyContactList) {
  const { state } = useContext(ForceOpenContext);
  const { parcelle } = useAppSelector(getPlotState);
  const { geolocDatas } = useAppSelector(getMapState);
  const { type } = useAppSelector(getStudyParamsState);

  return (
    <div className={styles.contactListContainer}>
      {!isEmpty(studyContactList) && updateAllowed && (
        <MailshotLauncherBtn
          type={
            type === 'plotStudy'
              ? MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_PLOT
              : MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER
          }
          isFlexEnd
          studyContacts={studyContactList}
        />
      )}
      <div className={styles.contactList}>
        {studyContactList?.map((c) => (
          <StudyContactCard
            key={nanoid()}
            studyContact={c}
            parcelle={parcelle ?? null}
            address={geolocDatas?.address ?? null}
            city={geolocDatas?.city ?? null}
            updateAllowed={updateAllowed && !state}
          />
        ))}
      </div>
    </div>
  );
}

export default StudyContactList;
