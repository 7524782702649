import { nanoid } from '@reduxjs/toolkit';
import { LatLngTuple } from 'leaflet';
import { ChangeEvent, useState } from 'react';
import AccordionBase from '../AccordionBase';
import EnvRiskContent from './EnvRiskContent';

interface IMultiEnvRisksProps {
  envRiskDatas: nullable<IEnvRiskDatas[]>;
}
export default function MultiEnvRisks({ envRiskDatas }: IMultiEnvRisksProps) {
  const [expanded, setExpanded] = useState<string>('');

  const handleChange =
    (panel: string) => (event: ChangeEvent, expanded: boolean) => {
      setExpanded(expanded ? panel : '');
    };

  return (
    <>
      {envRiskDatas?.map((linkData, i) => (
        <AccordionBase
          key={nanoid()}
          className="parcelle-infos"
          id={i}
          expanded={expanded}
          title={linkData.plotId}
          onChange={handleChange}
        >
          <EnvRiskContent
            city={linkData?.city}
            postalCode={linkData?.postalCode}
            inseeCode={linkData?.inseeCode}
            number={linkData?.number}
            prefix={linkData?.prefix}
            section={linkData?.section}
            latlng={linkData?.latLng as LatLngTuple}
            envs={linkData?.envs}
            id={'panel' + i}
            loadAddress={expanded}
          />
        </AccordionBase>
      ))}
    </>
  );
}
