import { useState } from 'react';
import NoAvailableImage from '../../../../assets/images/orpiPdf/imageNotAvailable.png';

interface IImageProps {
  className: string;
  source: string | null;
}
function ImageCustom({ className, source }: IImageProps) {
  const [imgSrc, setImgSrc] = useState<string | null>(source);

  return (
    <img
      className={className}
      src={imgSrc ?? NoAvailableImage}
      onError={() => setImgSrc(NoAvailableImage)}
    />
  );
}

export default ImageCustom;
