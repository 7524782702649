import { Checkbox } from '@mui/material';
import { ChangeEventHandler, MouseEventHandler } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '../../../../../App/store';
import { getAuthState } from '../../../../../features/auth/authSlice';
import { getMapPluginsUrbaToolbarState } from '../../../../../features/map/leaflet/plugins/mapPluginSlice';
import { ToolbarTypeEnum } from '../../../../../features/map/leaflet/plugins/toolbars/urbaToolbar/types/ToolbarBtnEnums';
import TooltipDisableMapToolbar from '../../../../Common/TooltipDisableMapToolbar';
import AddButton from './AddButton';
import styles from './sectionHeader.module.scss';

interface ISectionHeaderProps {
  checkAll: boolean;
  handleCheckAllChange: ChangeEventHandler<HTMLInputElement>;
  handleAdd: MouseEventHandler<Element>;
  disabled?: boolean;
  type: string;
}

const useStyles = makeStyles()({
  root: {
    padding: '9px 3px',
  },
});

function SectionHeader({
  checkAll,
  handleAdd,
  handleCheckAllChange,
  disabled,
  type,
}: ISectionHeaderProps) {
  const { classes } = useStyles();
  const { isAdmin, isManager } = useAppSelector(getAuthState);
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const mapToolbar =
    activeToolbar !== ToolbarTypeEnum.DRAW && activeToolbar !== null;

  const isNotFolder = type !== 'dossier';

  return (
    <div className={styles.sectionHeader}>
      {isNotFolder && (
        <Checkbox
          color="primary"
          classes={classes}
          size="small"
          checked={checkAll}
          disabled={disabled}
          onChange={handleCheckAllChange}
        />
      )}

      <p
        className={[
          styles.containerLabel,
          isNotFolder ? '' : styles.noCheckboxMargin,
        ].join(' ')}
      >{`Mes ${type}s de prospection`}</p>
      {type === 'dossier' && (
        <AddButton onClick={handleAdd}>+ {`${type}`}</AddButton>
      )}
      {(isManager || isAdmin) && type === 'secteur' && (
        <TooltipDisableMapToolbar>
          <div>
            <AddButton onClick={handleAdd} activeToolbar={mapToolbar}>
              + {`${type}`}
            </AddButton>
          </div>
        </TooltipDisableMapToolbar>
      )}
    </div>
  );
}

export default SectionHeader;
