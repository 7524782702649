import OwnerLinks from './OwnerLinks';
import OwnerList from './OwnerList';

interface IOwnerInfosAccordionProps {
  ownersPlot?: IOwnersPlots;
  owners?: nullable<IOwner[]>;
}

export default function OwnerInfosAccordion({
  ownersPlot,
  owners,
}: IOwnerInfosAccordionProps): JSX.Element {
  return (
    <div className="owner-informations">
      <OwnerLinks ownersPlot={ownersPlot} />
      <OwnerList owners={owners ?? ownersPlot?.ownerList} />
    </div>
  );
}
