import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  fetchPluOthersThunk,
  fetchPluUrlsThunk,
  fetchZonesAndZoneChildsThunk,
} from './pluThunk';

interface IUpdateDisplayRecursiveProps {
  items: PluOther[] | PluOtherSubCategory[] | PluOtherChild[];
  id: string;
  displayed: boolean;
  recursiveChild?: boolean;
}

const initialPluUrlState: PluUrls = {
  mapboxUrl: null,
  pmtilesUrl: null,
  noUrl: false,
  lastHumanCheck: null,
  botIntegrationDate: null,
  displayed: false,
  pluStatus: null,
};

const initialState: PluState = {
  // zone and zoneChild are used for advancedSearch only
  zones: null,
  zoneChilds: null,
  apiError: null,
  apiStatus: APIStatus.IDLE,
  pluUrls: {
    apiStatus: APIStatus.IDLE,
    result: initialPluUrlState,
  },
  pluOthers: {
    apiStatus: APIStatus.IDLE,
    result: null,
  },
  pluOthersPmtilesUrl: null,
};

const pluSlice = createSlice({
  name: 'plu',
  initialState,
  reducers: {
    pluLayerShow: (state) => {
      state.pluUrls.result.displayed = true;
    },
    pluLayerHide: (state) => {
      state.pluUrls.result.displayed = false;
    },
    setPluOtherDisplay: (
      state,
      action: PayloadAction<{ id: string; displayed: boolean }>
    ) => {
      const { id, displayed } = action.payload;

      const updateDisplayRecursive = ({
        items,
        id,
        displayed,
        recursiveChild = false,
      }: IUpdateDisplayRecursiveProps): any[] => {
        return items?.map((item) => {
          if (item.id === id || recursiveChild) {
            const updatedItem =
              item.id === id && 'isOpen' in item
                ? {
                    ...item,
                    displayed,
                    isOpen: !item.isOpen && !displayed ? false : true,
                  }
                : {
                    ...item,
                    displayed,
                  };

            if (
              'childs' in item &&
              item.childs &&
              'childs' in updatedItem &&
              updatedItem.childs
            ) {
              updatedItem.childs = updateDisplayRecursive({
                items: item.childs,
                id,
                displayed,
                recursiveChild: true,
              });
            }

            if ('childs' in updatedItem) {
              const allChildUnchecked = updatedItem.childs
                ? updatedItem.childs?.every((e) => e.displayed === false)
                : false;

              if (updatedItem.displayed && allChildUnchecked)
                updatedItem.displayed = false;
            }

            return updatedItem;
          }

          if ('childs' in item && item.childs) {
            const updatedItem = {
              ...item,
              childs: updateDisplayRecursive({
                items: item.childs,
                id,
                displayed,
              }),
            };

            if ('childs' in updatedItem && 'displayed' in updatedItem) {
              const allChildUnchecked = updatedItem.childs
                ? updatedItem.childs?.every(
                    (e: PluOtherSubCategory) => e.displayed === false
                  )
                : false;
              const allChildDisplayed = updatedItem.childs
                ? updatedItem.childs?.every((e) => e.displayed === true)
                : false;

              if (updatedItem.displayed && allChildUnchecked)
                updatedItem.displayed = false;
              if (allChildDisplayed) updatedItem.displayed = true;
            }

            return updatedItem;
          }
          return item;
        });
      };

      if (state.pluOthers.result) {
        state.pluOthers.result = updateDisplayRecursive({
          items: state.pluOthers.result,
          id: id,
          displayed: displayed,
        });
      }
    },
    setPluOtherOpen: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      const updateDisplay = (
        items: PluOther[] | PluOtherSubCategory[] | PluOtherChild[]
      ): any[] => {
        return items?.map((item) => {
          if (item.id === id && 'isOpen' in item) {
            const updatedItem = {
              ...item,
              isOpen: !item.isOpen,
            };
            if (item.childs) {
              updatedItem.childs = updateDisplay(item.childs);
            }
            return updatedItem;
          }
          if ('childs' in item && item.childs) {
            return {
              ...item,
              childs: updateDisplay(item.childs),
            };
          }
          return item;
        });
      };

      if (state.pluOthers.result) {
        state.pluOthers.result = updateDisplay(state.pluOthers.result);
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchZonesAndZoneChildsThunk.pending, (state) => {
        state.zones = null;
        state.zoneChilds = null;
        state.apiStatus = APIStatus.PENDING;
        state.apiError = null;
      })
      .addCase(
        fetchZonesAndZoneChildsThunk.fulfilled,
        (state, action: PayloadAction<Pick<PluState, 'zones' | 'zoneChilds'>>) => {
          const ap = action.payload;
          state.zones = ap.zones;
          state.zoneChilds = ap.zoneChilds;
          state.apiStatus = APIStatus.IDLE;
          state.apiError = null;
        }
      )
      .addCase(fetchZonesAndZoneChildsThunk.rejected, (state, action) => {
        state.zones = null;
        state.zoneChilds = null;
        state.apiStatus = APIStatus.REJECTED;
        state.apiError = action.error;
      });
    builder
      .addCase(fetchPluOthersThunk.pending, (state) => {
        state.pluOthers.apiStatus = APIStatus.PENDING;
        state.pluOthers.result = null;
        state.pluOthers.error = undefined;
      })
      .addCase(
        fetchPluOthersThunk.fulfilled,
        (
          state,
          action: PayloadAction<{
            pluOthers: PluOthers;
            pmtilesUrl: string;
          }>
        ) => {
          state.pluOthers.apiStatus = APIStatus.IDLE;
          state.pluOthers.result = action.payload.pluOthers;
          state.pluOthersPmtilesUrl = action.payload.pmtilesUrl;
          state.pluOthers.error = undefined;
        }
      )
      .addCase(fetchPluOthersThunk.rejected, (state, action) => {
        state.pluOthers.apiStatus = APIStatus.REJECTED;
        state.pluOthers.result = null;
        state.pluOthers.error = action.error;
      });
    builder
      .addCase(fetchPluUrlsThunk.pending, (state) => {
        state.pluUrls.apiStatus = APIStatus.PENDING;
        state.pluUrls.result = initialPluUrlState;
        state.pluUrls.error = undefined;
      })
      .addCase(
        fetchPluUrlsThunk.fulfilled,
        (state, action: PayloadAction<PluUrls>) => {
          state.pluUrls.apiStatus = APIStatus.IDLE;
          const ap = action.payload;

          const noPlu = !ap?.mapboxUrl && !ap?.pmtilesUrl;

          state.pluUrls.result = {
            ...state.pluUrls.result,
            ...ap,
          };
          if (noPlu) {
            state.pluUrls.result.noUrl = true;
          }
          state.pluUrls.error = undefined;
        }
      )
      .addCase(fetchPluUrlsThunk.rejected, (state, action: PayloadAction<any>) => {
        state.pluUrls.apiStatus = APIStatus.REJECTED;
        state.pluUrls.error = action.payload;
      });
  },
});

export default pluSlice.reducer;
export const pluActions = pluSlice.actions;
export const getPluState = (state: RootState) => state.plu;
