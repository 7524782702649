import TrashIcon from '../../../../../shared/components/icons/TrashIcon';
import UpdateIcon from '../../../../../shared/components/icons/UpdateIcon';
import WarningIcon from '../../../../../shared/components/icons/WarningIcon';
import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';
import { useMailshotResumeContext } from '../../../contexts/mailshotResumeContext';
import styles from '../../../styles/mailshotsModal.module.scss';

interface IMailshotContactRowProps {
  studyContact: IStudyContact;
  isSubfolder?: boolean;
  isDuplicate: boolean;
  index: number;
}

function MailshotContactRow({
  studyContact,
  isDuplicate,
  index,
}: IMailshotContactRowProps) {
  const { handleRemoveContact, setContactToUpdate, invalidContactAddress } =
    useMailshotResumeContext();

  return (
    <tr>
      <td>
        {isDuplicate ? (
          <WarningIcon
            bgColor="ORANGE"
            color="WHITE"
            tooltipBgColor="ORANGE"
            tooltipColor="WHITE"
            isRoundIcon
            width={16}
            height={16}
            className={styles.warningIcon}
            tooltipText={
              <p className={styles.warningIconTooltip}>
                Ce contact est aussi utilisé sur
                <br />
                une autre parcelle de la selection
              </p>
            }
          />
        ) : (
          ''
        )}
      </td>
      <td>
        <span className={styles.index}>{index + 1}</span>
      </td>
      <td>{studyContact.contact.firstName}</td>
      <td>{studyContact.contact.lastName}</td>
      <td>{studyContact.role.label}</td>
      <td
        className={[
          styles.validContactColumn,
          invalidContactAddress(studyContact) ? styles.notValid : '',
        ].join(' ')}
      >
        <GenericTooltip
          text={
            invalidContactAddress(studyContact) ? (
              <div className={styles.centerTooltipContent}>
                Adresse invalide.
                <br />
                Corrigez l&lsquo;adresse <br />
                ou retirez
                <br />
                le contact de la liste
              </div>
            ) : (
              ''
            )
          }
        >
          <div>{invalidContactAddress(studyContact) ? 'Non' : 'Oui'}</div>
        </GenericTooltip>
      </td>
      <td
        onClick={() => setContactToUpdate(studyContact)}
        className={styles.iconCell}
      >
        <UpdateIcon
          width={18}
          height={18}
          tooltipText={"Modifier l'adresse de ce contact"}
          color="PRIMARY"
          bgColor="WHITE"
          className={styles.iconInCell}
        />
      </td>
      <td
        onClick={() => handleRemoveContact(studyContact, isDuplicate)}
        className={styles.iconCell}
      >
        <TrashIcon
          width={18}
          height={18}
          tooltipText={"Retirer ce contact de l'édition"}
          color="RED"
          bgColor="WHITE"
          tooltipBgColor="RED"
          className={styles.iconInCell}
        />
      </td>
    </tr>
  );
}

export default MailshotContactRow;
