import * as protomaps from 'protomaps';
import { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getMapState, mapActions } from '../../../features/map/mapSlice';
import { MyLabelSymbolizer, MyPaintSymbolizer, pmtilesUrl4 } from './methods';

function PmTilesCadastre() {
  const map = useMap();
  const dispatch = useAppDispatch();
  const { geolocDatas, cadastreDisplay } = useAppSelector(getMapState);

  const resetLayer = () => {
    map.eachLayer((l: any) => {
      if (l.options.id === 'cadastral') {
        map.removeLayer(l);
      }
    });
  };

  useEffect(() => {
    if (!cadastreDisplay) {
      resetLayer();
    }
  }, [cadastreDisplay]);

  useEffect(() => {
    if (geolocDatas?.inseeCode && !cadastreDisplay) {
      const inseeCode = geolocDatas.inseeCode;
      // reset layer if an city allready displayed
      resetLayer();
      const p = new protomaps.PMTiles(pmtilesUrl4(inseeCode));

      dispatch(mapActions.isPmTilesLoadingSet(true));
      p.getMetadata()
        .then(
          (m: any) => {
            const layer = protomaps.leafletLayer({
              paint_rules: [
                {
                  dataLayer: inseeCode,
                  symbolizer: new MyPaintSymbolizer(),
                  minzoom: 0,
                  maxzoom: 22,
                },
              ],
              label_rules: [
                {
                  dataLayer: inseeCode,
                  symbolizer: new MyLabelSymbolizer(),
                  minzoom: 18,
                  maxzoom: 22,
                },
              ],
              attribution: '',
              url: p.source.getKey(),
            });

            layer.options.id = 'cadastral';
            layer.options.zIndex = 2;

            layer.addTo(map);
            dispatch(mapActions.isPmTilesLoadingSet(false));
          },
          (err) => console.log('err', err)
        )
        .catch((err: any) => {
          dispatch(mapActions.isPmTilesLoadingSet(false));
        });
    } else {
      // reset layer if no insee code
      resetLayer();
    }
  }, [geolocDatas?.inseeCode, cadastreDisplay]);

  return null;
}

export default memo(PmTilesCadastre);
