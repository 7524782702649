import { AxiosRequestConfig } from 'axios';
import { axiosTokenInstance } from '../AxiosProtectedInstance';
import axiosInstance from '../AxiosPublicInstance';
import { errorParser } from './axiosUtils';

const PYTHON_URL_PREFIX = process.env.REACT_APP_API_URL_DATAS;

/**
 * Generic Protected GET request
 * @param {string} idIri - endpoint request
 * @param {QueryParams?} params - queries requests
 * @returns {any} request response (entity array)
 */
export async function fetchDatas(endpoint: string, params?: QueryParams) {
  try {
    const response = await axiosTokenInstance().get(`${endpoint}`, { params });
    return response.data ?? response;
  } catch (e: any) {
    return Promise.reject(errorParser(e, 'fetchDatas error'));
  }
}
/**
 * Generic Protected GET request
 * @param {string} idIri - endpoint request
 * @param {AxiosRequestConfig?} configOptions - general request options
 * @param {boolean?} hasPythonProxy - If true, add proxy url on endpoint
 * @returns {any} request response (entity array)
 */
export async function fetchDatasNew({
  endpoint,
  config,
  hasPythonProxy,
}: {
  endpoint: string;
  config?: AxiosRequestConfig;
  hasPythonProxy?: boolean;
}) {
  try {
    const url = hasPythonProxy ? PYTHON_URL_PREFIX + endpoint : endpoint;
    const result = await axiosTokenInstance().get(url, config);

    return result.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
/**
 * Generic Protected GET by id request
 * @param {string} idIri - endpoint request
 * @param {any} params - datas for POST
 * @returns {any} request response (1 entity)
 */
export async function fetchDataById(idIri: string, params?: QueryParams) {
  try {
    const result = await axiosTokenInstance().get(idIri, { params });
    return result.data;
  } catch (e: any) {
    return Promise.reject(errorParser(e, 'fetchDatasById error'));
  }
}
/**
 * Generic Protected post request
 * @param {string} endpoint - endpoint request
 * @param {BodyParams} body - body request
 * @param {QueryParams} params - url queries
 * @param {number} timeout - timeout in ms
 * @returns {number} created entity
 */
export async function postEntity({
  endpoint,
  body,
  params = null,
  timeout = 60000,
}: IPostEntity) {
  try {
    const response = await axiosTokenInstance().post(endpoint, body, {
      timeout,
      params,
    });
    return response.data;
  } catch (e: any) {
    return Promise.reject(errorParser(e, 'postEntity error'));
  }
}

interface IPostEntityNewProps {
  endpoint: string;
  body: any;
  hasPythonProxy?: boolean;
  config?: AxiosRequestConfig;
}
export async function postEntityNew({
  hasPythonProxy,
  endpoint,
  body,
  config,
}: IPostEntityNewProps) {
  const url = hasPythonProxy ? PYTHON_URL_PREFIX + endpoint : endpoint;
  try {
    const result = await axiosTokenInstance().post(url, body, config);

    return result.data;
  } catch (error: any) {
    return Promise.reject(error);
  }
}
/**
 * Public post request
 * @param {string} endpoint - endpoint request
 * @param {BodyParams} body - datas for POST
 * @returns {any} request response
 */
export async function postEntityNoToken({ endpoint, body }: IPostEntity) {
  try {
    const result = await axiosInstance().post(endpoint, body);
    return result.data;
  } catch (e: any) {
    return Promise.reject(errorParser(e, 'postEntityNoToken error'));
  }
}
/**
 * Generic Protected update request
 * @param {string} idIri - endpoint request
 * @param {BodyParams} body - body request
 * @param {QueryParams} params - url queries
 * @returns {number} updated entity
 */
export async function updateEntity({ idIri, body, params }: IUpdateEntity) {
  try {
    const response = await axiosTokenInstance().put(idIri, body, { params });

    return response?.data;
  } catch (e: any) {
    return Promise.reject(errorParser(e, 'updateEntity error'));
  }
}
/**
 * Generic Protected delete request
 * @param {string} idIri - endpoint request
 * @returns {number} request status
 */
export async function deleteEntity(idIri: string) {
  try {
    const deleted = await axiosTokenInstance().delete(idIri);
    return deleted.status;
  } catch (e: any) {
    return Promise.reject(errorParser(e, 'deleteEntity error'));
  }
}
