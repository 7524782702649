import { isEmpty } from 'lodash';

const checkUserCanDelete = (
  entities: { name: string; responsable: User }[] | null,
  authUser: User
) => {
  //if no subs
  if (!entities || isEmpty(entities)) return [];
  // check rights of authenticated user
  // can be updated by valentin
  if (authUser.isManager || authUser.isAdmin) return [];

  const result = entities
    .filter(
      (s) =>
        s.responsable?.idIri !== authUser.idIri ||
        s.responsable.isAdmin ||
        s.responsable.isManager
    )
    .map((m) => m.name);

  return result;
};

export default checkUserCanDelete;
