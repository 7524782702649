import { TableCell, TableRow } from '@mui/material';

interface ISyntheseFolderRowProps {
  row: ISyntheseTableRow;
}
export default function SyntheseFolderRow({ row }: ISyntheseFolderRowProps) {
  return (
    <TableRow>
      <TableCell className="city-cell">{row.city}</TableCell>
      <TableCell>{row.address}</TableCell>
      <TableCell>{row.plotId}</TableCell>
      <TableCell>{row.plotArea}</TableCell>
      <TableCell>{row.usedGroundArea}</TableCell>
      <TableCell>{row.zone}</TableCell>

      <TableCell>{row.responsable}</TableCell>
      <TableCell>{row.state?.label}</TableCell>
    </TableRow>
  );
}
