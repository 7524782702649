import styles from '../dpe.module.scss';

interface IDpePopupTabContentProps {
  content: string;
  parsedTab: string[];
  state: number;
  update: (value: number) => void;
}

function DpePopupTabContent({
  content,
  parsedTab,
  state,
  update,
}: IDpePopupTabContentProps) {
  const handleClick = (value: string) => {
    update(parsedTab.indexOf(value));
  };
  const values = content.split('_');
  const dpeClass = values[2]?.toLowerCase() + 'Class';
  return (
    <div
      className={`${styles.tabContent} ${
        content === parsedTab[state] ? styles.selected : ''
      }`}
      onClick={() => handleClick(content)}
    >
      <div className={`${styles.dpeClass} ${styles[dpeClass]}`}>
        <p>{values[2]}</p>
      </div>
      <p>{values[1]}</p>
    </div>
  );
}

export default DpePopupTabContent;
