import styles from './advancedPlotSearchModal.module.scss';
function ErrorContent() {
  return (
    <div className={styles.errorContent}>
      <h3 className={styles.title}>Chargement des parcelles interrompu</h3>
      <p>
        Une erreur s&#0039;est produite lors du chargement des parcelles. <br /> Si
        le problême persiste, veuillez contacter le support.
      </p>
      <p className={styles.supportEmail}>
        <a href="mailto:support@urbanease.io">support@urbanease.io</a>
      </p>
    </div>
  );
}

export default ErrorContent;
