import TextField from '@mui/material/TextField';
import { ChangeEvent, FormEvent, useContext, useState } from 'react';
import { PermitsTableContext } from '.';
import { useAppSelector } from '../../../App/store';
import SelectCustom from '../../../components/Common/FormComponents/SelectCustom';
import GlassButton from '../../../components/Common/glassButton';
import { getBuildPermitsState } from '../../../features/buildPermits/buildPermitsSlice';
import styles from '../buildPermits.module.scss';
import DownloadBuildPermitsButton from './DownloadDataButton';

const BuiltPermisTableHeader = () => {
  const { state, update } = useContext(PermitsTableContext);
  const { buildPermits } = useAppSelector(getBuildPermitsState);
  const [searchField, setSearchField] = useState<string>('');
  const handleChange = (e: ChangeEventCustom) => {
    const value = e.target.value;
    update({ ...state, inputAutorisationType: value });
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchField(e.target.value);
  };

  const handleSearchValid = (e?: FormEvent<HTMLFormElement>): void => {
    e && e.preventDefault();
    update({ ...state, inputSearch: searchField });
  };

  return (
    <div className={styles.buildPermitsTableHeader}>
      <DownloadBuildPermitsButton
        text="Extraire tableau"
        className="download-btn"
        data={buildPermits.result ?? []}
      />
      <h1 className="title">Autorisations d’Urbanisme</h1>
      <div className={styles.form}>
        <form onSubmit={handleSearchValid}>
          <div className={styles.search}>
            <p className={styles.label}>Rechercher</p>
            <TextField
              className={styles.inputField}
              type="text"
              variant="outlined"
              value={searchField}
              onChange={handleSearchChange}
            />
            <GlassButton onClick={handleSearchValid} />
          </div>
        </form>
        <h3>Filtrer l&apos;affichage</h3>
        <div className={styles.filter}>
          <p className={styles.label}>Typologie d’autorisation:</p>
          <div className={styles.selectField}>
            <SelectCustom
              items={[
                { value: '', display: 'Tout' },
                { value: 'PC', display: 'PC' },
                { value: 'DP', display: 'DP' },
                { value: 'PA', display: 'PA' },
              ]}
              value={state.inputAutorisationType}
              noValue="Trier autorisation type par"
              displayEmpty
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuiltPermisTableHeader;
