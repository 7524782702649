import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { fontStyles } from '../styles/pdfStyles';

interface IProps {
  label: string;
  value: boolean | string;
}
const getStringValue = (value: boolean) => {
  return value ? 'Oui' : 'Non';
};

function InformationLine({ label, value }: IProps) {
  return (
    <View style={styles.infoLine}>
      <Text>{label} :</Text>
      <View style={styles.badge}>
        <Text>{typeof value === 'boolean' ? getStringValue(value) : value}</Text>
      </View>
    </View>
  );
}

export default InformationLine;

const styles = StyleSheet.create({
  infoLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: '10px',
    marginBottom: '3px',
  },
  badge: {
    paddingHorizontal: '7px',
    height: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px',
    color: '#fff',
    ...fontStyles.bcOrpi,
  },
});
