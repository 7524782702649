import { Path, Svg } from '@react-pdf/renderer';

function AirportSvg() {
  return (
    <Svg
      viewBox="0 0 24 24"
      height="24"
      width="24"
      preserveAspectRatio="xMidYMid meet"
    >
      <Path
        d="M9.53944 21.2942C9.34201 21.3532 9.17663 21.3296 9.04329 21.2236C8.90996 21.1175 8.84329 20.9647 8.84329 20.7654C8.84329 20.6729 8.86894 20.5745 8.92022 20.4702C8.9715 20.3659 9.03433 20.2832 9.10869 20.2221L10.8029 18.9587V13.0923L3.34039 15.2913C3.07886 15.3689 2.85739 15.3392 2.67599 15.2023C2.49458 15.0655 2.40387 14.8579 2.40387 14.5798C2.40387 14.4599 2.43991 14.3351 2.51199 14.2055C2.58406 14.0759 2.67462 13.9789 2.78367 13.9145L10.8029 9.19521V3.60866C10.8029 3.26934 10.917 2.985 11.1451 2.75564C11.3732 2.52625 11.6588 2.41156 12.0018 2.41156C12.3449 2.41156 12.6306 2.52625 12.8591 2.75564C13.0877 2.985 13.2019 3.26934 13.2019 3.60866V9.19521L21.2212 13.9145C21.327 13.9779 21.4168 14.0731 21.4904 14.2C21.5641 14.3269 21.601 14.4534 21.601 14.5795C21.601 14.8547 21.5095 15.0615 21.3264 15.2C21.1434 15.3385 20.9228 15.3689 20.6644 15.2913L13.2019 13.0923V18.9192L14.9115 20.2221C14.9859 20.2832 15.0495 20.3646 15.1024 20.4664C15.1553 20.5681 15.1817 20.6677 15.1817 20.7654C15.1817 20.9647 15.1143 21.1175 14.9793 21.2236C14.8444 21.3296 14.6782 21.3532 14.4808 21.2942L12.0125 20.5692L9.53944 21.2942Z"
        fill="currentColor"
      ></Path>
    </Svg>
  );
}

export default AirportSvg;
