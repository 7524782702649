import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import useFolder from '../../../folders/useFolder';
import IndividualCircularLoader from '../../../loaders/individualCircularLoader';
import styles from './multiselectPlotModal.module.scss';

interface IDuplicatePlotsRowProps {
  plot: IPlotStudy;
}
function DuplicatePlotsRow({ plot }: IDuplicatePlotsRowProps) {
  const { folderList } = useFolder({});
  const [names, setNames] = useState<{
    parent: string | null;
    folder: string;
  } | null>(null);

  useEffect(() => {
    if (folderList) {
      let parent: IFolder | null = null;
      if (plot.parent) {
        parent = folderList?.find((f) => f.idIri === plot.parent.parent) ?? null;
        setNames({
          folder: plot.parent.name,
          parent: parent?.name ?? null,
        });
      } else {
        setNames({
          folder: 'Indéfini',
          parent: 'Indéfini',
        });
      }
    }
  }, [folderList]);

  if (isEmpty(folderList)) {
    return <IndividualCircularLoader size={100} />;
  }

  return (
    <li className={styles.duplicatePlotsRow}>
      <span className={styles.plotName}>{plot.fullPlotId.substring(5)}</span> :{' '}
      {names?.parent && <span>{`Dossier ${names.parent} / `}</span>}
      {names?.folder && (
        <span>
          {names?.parent ? 'Sous-dossier ' : 'Dossier '} {names.folder}
        </span>
      )}
    </li>
  );
}

export default DuplicatePlotsRow;
