import { createAsyncThunk } from '@reduxjs/toolkit';
import { GenericModalEnum } from '../../../../components/Modals/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';
import updateMailshotModel from '../updateMailshotModel';

const updateMailshotsModelsThunk = createAsyncThunk(
  'mailshots/updateMailshotsmodelsThunk',
  async (params: { model: UpdateMailshotModelType }, { dispatch }) => {
    try {
      const result = await updateMailshotModel(params.model);

      return result;
    } catch (error) {
      dispatch(
        modalsActions.alert({
          status: true,
          context: 'updateMailshotModelError',
          modalType: GenericModalEnum.ERROR,
        })
      );
      return Promise.reject(error);
    }
  }
);

export default updateMailshotsModelsThunk;
