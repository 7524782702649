import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { questions } from './init';
import './UrbaneaseAcademy.scss';
import UrbaneaseAcademySlider from './UrbaneaseAcademySlider';

function UrbaneaseAcademyTab(): JSX.Element {
  return (
    <div className="tab-urbanease-academy">
      <div className="tab-inner">
        <h1>Urbanease Academy</h1>
      </div>
      <div className="tab-4-body">
        <UrbaneaseAcademySlider />
        <h2>FAQ</h2>
        {questions.map((e) => (
          <Accordion key={uuidv4()} className="accordian">
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{e.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>{e.response}</AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}

export default UrbaneaseAcademyTab;
