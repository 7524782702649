import { useFormContext } from 'react-hook-form';
import { addSpaceToNumber } from '../../../../utils/jsFunctions';
import styles from '../styles/prospecForm.module.scss';
interface IProspecPriceSqmDisplayProps {
  inputName: string;
  isCalculated?: boolean;
  value?: number;
}
function ProspecPriceSqmDisplay({
  inputName,
  isCalculated,
  value,
}: IProspecPriceSqmDisplayProps) {
  const { watch } = useFormContext();
  const values = watch([inputName, 'landArea']);

  const sqmPrice = value
    ? value
    : isCalculated && values[0]
      ? Math.round(values[0] / values[1])
      : undefined;

  return (
    <>
      {Boolean(sqmPrice) && (
        <p
          className={styles.sqmPrice}
        >{`${addSpaceToNumber(sqmPrice ?? 0)} €/m²`}</p>
      )}
    </>
  );
}

export default ProspecPriceSqmDisplay;
