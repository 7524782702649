import React from 'react';
import { Path, Svg } from '@react-pdf/renderer';

const LeisureSvg = ({ color }: { color?: string }) => (
  <Svg
    height="24"
    width="24"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M12.0033 21.3182C11.6242 21.3182 11.3008 21.1852 11.0332 20.9191C10.7656 20.6531 10.6318 20.33 10.6318 19.95V17.3586H5.76834C5.43213 17.3586 5.18204 17.2097 5.01809 16.912C4.85416 16.6142 4.86995 16.324 5.06547 16.0413L8.14432 11.4394H7.80489C7.46768 11.4394 7.21862 11.2879 7.05774 10.9851C6.89686 10.6822 6.91674 10.3894 7.11739 10.1067L11.3048 4.11446C11.3872 3.99639 11.4917 3.90582 11.6184 3.84273C11.7451 3.77965 11.8723 3.74811 12 3.74811C12.1277 3.74811 12.2549 3.77965 12.3816 3.84273C12.5083 3.90582 12.6145 3.99639 12.7 4.11446L16.8826 10.1067C17.0833 10.3894 17.1032 10.6822 16.9423 10.9851C16.7814 11.2879 16.5324 11.4394 16.1951 11.4394H15.8557L18.9394 16.0413C19.1317 16.324 19.1463 16.6142 18.9831 16.912C18.82 17.2097 18.5711 17.3586 18.2365 17.3586H13.3682V19.95C13.3682 20.33 13.2355 20.6531 12.9701 20.9191C12.7047 21.1852 12.3825 21.3182 12.0033 21.3182ZM6.81059 15.9596H10.7716H8.89137H15.1087H13.2284H17.1894H6.81059ZM6.81059 15.9596H17.1894L13.2284 10.0404H15.1087L12 5.60093L8.89137 10.0404H10.7716L6.81059 15.9596Z"
      fill={color ?? 'currentColor'}
    />
  </Svg>
);

export default LeisureSvg;
