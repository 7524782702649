import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import {
  citiesApiToStoreParser,
  departmentsApiToStoreParser,
} from './subscriptionParser';

// export const fetchSubscriptionThunk = createAsyncThunk(
//   'subscription/fetchSubscriptionThunk',
//   async (params: { subscriptionIdIri: string }) => {
//     return new Promise<{
//       subscription: Subscription;
//       departments: Departments;
//       cities: Cities;
//     }>((resolve, reject) => {
//       if (params.subscriptionIdIri) {
//         try {
//           const promise = fetchDataById(params.subscriptionIdIri);

//           promise.then(
//             (response) => {
//               const parsedSubscription = subscriptionApiToStoreParser(response);

//               if (parsedSubscription) {
//                 resolve(parsedSubscription);
//               } else {
//                 reject({ status: 0, message: 'subscription parsing error' });
//               }
//             },
//             (err) => {
//               reject(err);
//             }
//           );
//         } catch (error) {
//           reject(error);
//         }
//       } else {
//         reject(new Error('susbscriptionIdIri parameter not found'));
//       }
//     });
//   }
// );

export const fetchSubscriptionDepartmentsThunk = createAsyncThunk(
  'subscription/fetchSubscriptionDepartmentsThunk',
  async (params: { subscriptionIdIri: string | null }, { rejectWithValue }) => {
    if (params.subscriptionIdIri) {
      try {
        const result = await fetchDatas(`${params.subscriptionIdIri}/departments`, {
          itemsPerPage: 100,
        });

        try {
          const parsedDepartments = departmentsApiToStoreParser(
            result['hydra:member']
          );

          return parsedDepartments;
        } catch (error) {
          return rejectWithValue({
            status: 0,
            message: 'department parser filed',
            error,
          });
        }
      } catch (error) {
        return rejectWithValue({
          status: 500,
          message: 'server error',
          error: error,
        });
      }
    } else {
      return rejectWithValue({
        status: 0,
        message: 'susbscriptionIdIri parameter not found',
      });
    }
  }
);

export const fetchSubscriptionCitiesThunk = createAsyncThunk(
  'subscription/fetchSubscriptionCitiesThunk',
  async (
    params: { subscriptionIdIri: string | null; itemsPerPage: number; page: number },
    { rejectWithValue }
  ) => {
    if (params.subscriptionIdIri) {
      try {
        const result = await fetchDatas(`${params.subscriptionIdIri}/cities`, {
          itemsPerPage: params.itemsPerPage ?? 10,
          page: params.page ?? 1,
        });

        const parsedCities = await citiesApiToStoreParser(result['hydra:member']);
        const totalCities = result['hydra:totalItems'];

        return { cities: parsedCities, total: totalCities };
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      return rejectWithValue({
        status: 0,
        message: 'susbscriptionIdIri parameter not found',
      });
    }
  }
);
