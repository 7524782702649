import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import FormButtonContainer from '../../../../shared/components/buttons/formButtonContainer/FormButtonContainer';
import { snakeToPascal } from '../../../../shared/utils/stringFunctions';
import useMailshotsModels from '../../hooks/useMailshotsModels';
import { getMailshotsState, mailshotsActions } from '../../mailshotsSlice';
import styles from '../../styles/mailshotAccountTabContent.module.scss';
import '../../styles/quillCSS.scss';
import '../../utils/quill/BadgeClass';
import { mailShotQuillToolbarOptions } from '../../utils/quill/constants';
import TagButtonContainer from './tagButtons/TagButtonContainer';

function QuillJsContainer({ model }: { model: MailshotModelType | null }) {
  const { updatingQuill, quillDatasBeforeNavigation } =
    useAppSelector(getMailshotsState);

  const { updateModel } = useMailshotsModels({});
  const [quill, setQuill] = useState<Quill | null>(null);

  const dispatch = useAppDispatch();

  // update model content only
  const handleValid = () => {
    if (quill && model) {
      const content = quill.getContents();

      const updatedModel = { ...model, content };
      updateModel(updatedModel);
    }
  };

  const handleQuillTextChangeOnce = useCallback(
    (event: any) => {
      if (quill) {
        dispatch(mailshotsActions.setUpdatingQuill(true));
        quill?.off('text-change', handleQuillTextChangeOnce);
      }
    },
    [quill]
  );

  // set content to store if navigation is activated
  useEffect(() => {
    if (
      quill &&
      quillDatasBeforeNavigation &&
      quillDatasBeforeNavigation?.content === null
    ) {
      const content = { ...quill.getContents() };

      dispatch(
        mailshotsActions.setQuillDatasBeforeNavigation({
          ...quillDatasBeforeNavigation,
          content: content.ops,
        })
      );
    }
  }, [quillDatasBeforeNavigation, quill]);

  useEffect(() => {
    // when quill is created, add the selected model to to qill content
    handleInit();
  }, [model, quill]);
  // init quill editor with toolbae, placeholder, theme,.. at first render
  useEffect(() => {
    const options = {
      modules: {
        toolbar: mailShotQuillToolbarOptions,
      },
      placeholder: 'Composez votre modèle...',
      theme: 'snow',
    };
    const q = new Quill('#editor', options);
    // q.on('text-change', handleQuillTextChangeOnce);
    // TO DO add on change text if delta is a tag, remove all tag
    setQuill(q);
  }, []);

  useEffect(() => {
    const hasTextChangeEvent = quill?.emitter.listeners('text-change');

    if (!updatingQuill && (hasTextChangeEvent?.length ?? 0) === 0) {
      quill?.on('text-change', handleQuillTextChangeOnce);
    }
    if (updatingQuill) {
      quill?.off('text-change', handleQuillTextChangeOnce);
    }
  }, [updatingQuill, quill]);

  // when click on a tag button, insert corresponding tag name
  //  into editor at cursor location
  const handleTagBtnClick = (value: MailshotBadgeValue) => {
    const parsedValue = { ...value, color: snakeToPascal(value.color) };

    if (quill) {
      const range = quill.getSelection(true);

      quill.insertEmbed(range.index, 'TagBadge', parsedValue);

      quill.insertText(range.index + 1, ' ', Quill.sources.USER);
      quill.setSelection(range.index + 2, Quill.sources.USER);
    }
  };

  //if quill is created, add content into it
  const handleInit = () => {
    if (quill && model) {
      try {
        quill.setContents(model.content.ops, 'silent');
        dispatch(mailshotsActions.setUpdatingQuill(false));
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className={styles.quillContainer}>
      <div className={styles.quillContent}>
        <div className={styles.quillEditor}>
          <div id="editor"></div>
        </div>

        <FormButtonContainer
          canCancel
          validContent="Enregistrer"
          cancelContent="Réinitialiser"
          onValid={handleValid}
          onCancel={handleInit}
          className={styles.formBtnContainer}
        />
      </div>
      <TagButtonContainer onTagBtnClick={handleTagBtnClick} />
    </div>
  );
}

export default memo(QuillJsContainer);
