import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import NoImage from '../../../../../assets/images/orpiPdf/imageNotAvailable.png';
interface IUrbanismeInfosProps {
  zone: string;
  parcelsOnZonePicture: string;
}

const styles = StyleSheet.create({
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
  },
  smallText: { fontSize: '10px' },
  zone: { color: '#D82B4D' },
  imgContainer: {
    height: '186px',
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '381px',
  },
});
function UrbanismeInfos({ zone, parcelsOnZonePicture }: IUrbanismeInfosProps) {
  return (
    <>
      <View style={styles.textContainer}>
        {zone ? (
          <>
            <Text>
              Précision du plan de zonage : la parcelle se trouve en &nbsp;
            </Text>
            <Text style={styles.zone}>{` ${zone} `}</Text>
            <Text>du PLU</Text>
          </>
        ) : (
          <Text style={styles.smallText}>
            Précision du plan de zonage : la parcelle se trouve dans une commune non
            couverte par un PLU ou un PLUI
          </Text>
        )}
      </View>
      <View style={styles.imgContainer}>
        {parcelsOnZonePicture ? (
          <Image
            src={`data:image/jpeg;base64,${parcelsOnZonePicture}`}
            style={styles.img}
          />
        ) : (
          <Image src={NoImage} style={styles.img} />
        )}
      </View>
    </>
  );
}

export default UrbanismeInfos;
