import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { ORPI_RED_COLOR } from '../../../shared/constants';
import { fontStyles } from '../styles/pdfStyles';

interface IProps {
  lines: string[];
  fixed?: boolean;
}
function Header({ lines, fixed }: IProps) {
  return (
    <View style={styles.headerContainer} fixed={fixed}>
      <Text style={[fontStyles.b700, fontStyles.fs24]}>{lines[0]}</Text>
      <Text style={fontStyles.fs18}>{lines[1]}</Text>
    </View>
  );
}

export default Header;

const styles = StyleSheet.create({
  headerContainer: {
    color: ORPI_RED_COLOR,
    paddingBottom: '5px',
    borderBottomColor: '#D0D0D0',
    borderBottomWidth: 1,
    marginTop: '10px',
    marginBottom: '10px',
  },
});
