import { createAsyncThunk } from '@reduxjs/toolkit';
import { modalsActions } from '../../../modals/modalsSlice';
import { fetchProceduresThunk } from '../../../procedures/proceduresThunk';
import deleteStudyProcedure from '../deleteStudyProcedure';

const deleteStudyProcedureThunk = createAsyncThunk(
  'study/deleteStudyProcedureThunk',
  async (params: { procedureIdIri: string | null }, thunkOptions) => {
    try {
      const result = await deleteStudyProcedure(params.procedureIdIri);

      // close confirmation modal
      thunkOptions.dispatch(
        modalsActions.deleteStudyProcedureConfirmationModal({
          display: false,
          idIri: null,
        })
      );

      // load incoming procedures
      thunkOptions.dispatch(fetchProceduresThunk());
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default deleteStudyProcedureThunk;
