import saveAs from 'file-saver';
import { Op } from 'quill/core';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { GenericModalEnum } from '../../../components/Modals/GenericModal';
import { dateFormat } from '../../../lib/formats/dataFormat';
import { getZoneAndZoneChildString } from '../../../lib/formats/zoneAndZoneChildFormat';
import { getAuthState } from '../../auth/authSlice';
import { getCompanyState } from '../../company/companySlice';
import { loadersActions } from '../../loaders/loaderSlice';
import { modalsActions } from '../../modals/modalsSlice';
import { getMailshotsState } from '../mailshotsSlice';
import { MailshotTagEnum } from '../types/mailshotsEnums';
import parseLetters from '../utils/quill/quillDeltaParser';
import { generateWord } from '../utils/quill/quillToDocx';
import useMailshotsSelection from './useMailshotsSelection';

type Key = keyof typeof MailshotTagEnum;
type TagValueType = { [x in Key]?: string };
type TagValuesType = TagValueType[];

type hydratedDataType = {
  contact: IStudyContact;
  plot?: IPlotStudy;
  subfolder?: IFolderStudy;
};
export type HydratedDatasType = hydratedDataType[];

function useMailshotHydrate() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(getAuthState);
  const { company } = useAppSelector(getCompanyState);
  const { selectedModel } = useAppSelector(getMailshotsState);
  const { folderContacts, selectedList, study } = useMailshotsSelection();

  // create an object key/value with contact and plot real values
  const getHydratedObjectParser = (
    contact: IStudyContact,
    plot: MailshotPlot | null,
    multiplot: MailshotPlots | null
  ) => {
    const c = contact.contact;
    const a = c.address;

    let hydrated: TagValueType = {
      NOM_CONTACT: c.lastName,
      PRENOM_CONTACT: c.firstName,
      CONTACT_ADRESSE_1: a.addressFloor || 'empty',
      CONTACT_ADRESSE_2: a.addressBuilding || 'empty',
      CONTACT_ADRESSE_3: a.addressStreet,
      CONTACT_ADRESSE_4: a.addressExtra || 'empty',
      CONTACT_CODE_POSTAL: a.postalCode || 'empty',
      CONTACT_VILLE: a.city,
      CONTACT_PAYS: a.country || 'empty',

      NOM_PROSPECTEUR: user?.lastName,
      PRENOM_PROSPECTEUR: user?.firstName,
      EMAIL_PROSPECTEUR: user?.email,

      NOM_COMPAGNIE: company.result?.name,
      ADRESSE_COMPAGNIE: company.result?.address,
      TELEPHONE_COMPAGNIE: company.result?.phone ?? '',
    };

    if (plot) {
      // if not a subfolder
      hydrated = {
        ...hydrated,
        NUMERO_PARCELLE: plot.plotId,
        SURFACE_PARCELLE: plot.area.toString(),
        ADRESSE_PARCELLE: plot.address ?? '',
        ZONE_PARCELLE: getZoneAndZoneChildString({
          zone: plot.zone,
          zoneChild: plot.zoneChild,
        }),
      };
    }

    if (multiplot) {
      hydrated = {
        ...hydrated,
        MULTIPLOT_TO_STRING: multiplot.map((m) => m.plotId).join(', '),
      };
    }

    return hydrated;
  };

  // create array of values to hydrate for each docx (10 max)
  const getHydratedObjectsParser = () => {
    let hydratedValues: TagValuesType = [];
    const datasValuesTab: HydratedDatasType = [];
    if (folderContacts) {
      // if folderContacts, loop on contact.
      // 1 hydratation by contact with all plots in it
      folderContacts.list.forEach((e) => {
        const result = getHydratedObjectParser(e, null, selectedList);
        if (result) {
          hydratedValues.push(result);
          datasValuesTab.push({
            contact: e,
            subfolder: study.result as IFolderStudy,
          });
        }
      });
    } else {
      // if plots loop on plot
      // & 1 hydratation for each contact
      selectedList.forEach((plot) => {
        plot.contacts.list.forEach((contact) => {
          const result = getHydratedObjectParser(contact, plot, null);
          if (result) {
            hydratedValues.push(result);
            datasValuesTab.push({
              contact,
              plot: plot as IPlotStudy,
            });
          }
        });
      });
    }
    return { hydratedValues, datasValuesTab };
  };

  // hydrate model with
  const hydrateModel = async () => {
    try {
      if (selectedModel) {
        dispatch(loadersActions.loaderShow());

        let logoUrl: string | null = null;
        const valuesToHydrate = getHydratedObjectsParser();

        // model ops (array of lines with params for each one)
        const ops = selectedModel.content.ops;

        const parsedMultiDocOps: Op[][] = [];

        // replace tags with string value for each doc
        valuesToHydrate.hydratedValues.forEach((e) => {
          const parsedOps: Op[] = [];
          let c = 0;

          while (c < ops.length) {
            const op = ops[c];
            const insert = op.insert as Record<string, TagBadgeType>;

            if (insert.TagBadge) {
              // if logo tag into model, add url for display
              // it in docx header
              const val = e[insert.TagBadge.key as Key];

              // remove all empty tags (optional address lines)
              if (val === 'empty') {
                if (ops[c + 3] && ops[c + 2].insert === '\n') {
                  c += 3;
                } else {
                  c += 2;
                }
              } else {
                // replace tag with value and add op
                parsedOps.push({
                  ...op,
                  insert: e[insert.TagBadge.key as Key] ?? '',
                });
                c++;
              }
            } else {
              // no tag => add op
              parsedOps.push(op);
              c++;
            }
          }
          parsedMultiDocOps.push(parsedOps);
        });

        // parse ops arrays as deltas RawQuillDeltas
        const letters: Letters = parsedMultiDocOps.map((m) => ({
          ops: m,
        }));
        const parsedLettersForDocxFinalParse = parseLetters(letters);

        const doc = await generateWord(
          parsedLettersForDocxFinalParse,
          company.result?.logoUrl ?? null
        );

        saveAs(
          doc as Blob,
          `courrier_urbanease_${dateFormat(new Date().toISOString())}.docx`
        );
        dispatch(loadersActions.loaderHide());

        return valuesToHydrate.datasValuesTab;
      }
    } catch (error) {
      dispatch(loadersActions.loaderHide());
      dispatch(
        modalsActions.alert({
          status: true,
          context: 'hydrateMailshotModelError',
          modalType: GenericModalEnum.ERROR,
        })
      );
      return null;
    }
  };

  return { hydrateModel };
}

export default useMailshotHydrate;
