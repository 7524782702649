import { useLocation } from 'react-router';
import { useAppSelector } from '../../../App/store';
import { getAuthState } from '../../../features/auth/authSlice';
import { getExternalPdfErrialState } from '../../../features/externalPdf/externalPdfSlice';
import { getPanelState } from '../../../features/panels/panelsSlice';
import UrbaGptMapButton from '../../../features/urbaGpt/UrbaGptMapButton';
import { PDF_URLS } from '../../../shared/constants';
import CadastreButton from './CadastreButton';
import SatelliteViewButtons from './SatelliteViewButtons';
import ZoomButtons from './ZoomButtons';
import './rightBottom.scss';

export default function RightBottomButtons() {
  const { externalPdfErrialRightPanelDisplay } = useAppSelector(
    getExternalPdfErrialState
  );
  const { isLogged } = useAppSelector(getAuthState);
  const { rightButtons } = useAppSelector(getPanelState);
  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);

  return (
    <div
      className={`bottom-right-buttons ${
        pdfPath && externalPdfErrialRightPanelDisplay && 'external-pdf-errial'
      }`}
    >
      {path === '/' && rightButtons && isLogged && (
        <>
          <UrbaGptMapButton />
          <CadastreButton />
        </>
      )}
      <div>
        <ZoomButtons />
        <SatelliteViewButtons />
      </div>
    </div>
  );
}
