import useColorWrapper from '../colorWrapper/useColorWrapper';
import GenericTooltip from '../tooltips/GenericTooltip';
import styles from './icons.module.scss';

export interface IIconContainerProps extends ISharedIconsProps, IChildren {}
function IconContainer(props: IIconContainerProps) {
  const {
    children,
    height = 32,
    width = 32,
    className,
    dataCy,
    tooltipDisabled,
    tooltipText,
    tooltipBgColor = 'DARK_PRIMARY',
    bgColor = 'PRIMARY',
    color = 'WHITE',
    isRoundIcon,
    noTooltip,
    onClick,
    noPadding,
  } = props;
  const classesCustom = useColorWrapper({
    colorWrapperType: 'icon',
    bgColor,
    color,
    className,
  });

  return (
    <>
      {noTooltip ? (
        <div
          style={{
            height: `${height}px`,
            width: `${width}px`,
          }}
          onClick={onClick}
          data-cy={dataCy}
          className={[
            styles.iconContainer,
            classesCustom,
            isRoundIcon ? styles.roundIcon : '',
          ].join(' ')}
        >
          {children}
        </div>
      ) : (
        <GenericTooltip
          text={tooltipText}
          disabled={tooltipDisabled}
          bgColor={tooltipBgColor}
        >
          <div
            style={{
              height: `${height}px`,
              width: `${width}px`,
            }}
            onClick={onClick}
            data-cy={dataCy}
            className={[
              styles.iconContainer,
              classesCustom,
              isRoundIcon ? styles.roundIcon : '',
              noPadding ? styles.noPadding : '',
            ].join(' ')}
          >
            {children}
          </div>
        </GenericTooltip>
      )}
    </>
  );
}

export default IconContainer;
