import { pointConnectedParser } from './parserDatas/parcelleParser';

import { pluOthersApiToStoreParser } from './parserDatas/pluParser';

import {
  contactRoleParser,
  contactRolesParser,
  plotStudyStatusParser,
  plotStudyStatusesParser,
  procedureTypeParser,
  procedureTypesParser,
} from './parserDatas/staticListsParser';
import { transactionToDvfPopupParser } from './parserDatas/transactionParser';
import zoneAndZoneChildParser from './parserDatas/zoneAndZoneChildParser';

export const parserService = {
  parcelle: {
    pointConnectedApiToStore: (data: any) => pointConnectedParser(data),
    fetchByPlotIdApiToStore: (data: any) => pointConnectedParser(data),
  },
  dvf: {
    popupMarkerDisplay: (data: ITransaction[]) => transactionToDvfPopupParser(data),
  },
  zone: {
    zoneAndZoneChild: (data: any) => zoneAndZoneChildParser(data),
  },
  plotStudyStatus: {
    statusesApiToStore: (data: any) => plotStudyStatusesParser(data),
    statusApiToStore: (data: any) => plotStudyStatusParser(data),
  },

  contactRoles: {
    contactRolesApiToStore: (data: any) => contactRolesParser(data),
    contactRoleApiToStore: (data: any) => contactRoleParser(data),
  },
  procedureTypes: {
    procedureTypesApiToStore: (data: any) => procedureTypesParser(data),
    procedureTypeApiToStore: (data: any) => procedureTypeParser(data),
  },
  sectorActions: {
    sectorActionsApiToStore: (data: any) => procedureTypesParser(data),
    sectorActionApiToStore: (data: any) => procedureTypeParser(data),
  },
  pluOthers: {
    ApiToStore: (data: any) => pluOthersApiToStoreParser(data),
  },
};
