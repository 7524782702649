import { createTheme, adaptV4Theme } from '@mui/material/styles';

const colorPrimary = '#4E55A2';
const colorSecondary = '#879EBE';
const colorError = '#ff0000';
const formTextColor = '#354052';
export const colorDanger = '#D20000';
export const prospectionIconPrimaryHovered = '#292d55';
export const prospectionIconDangerHovered = '#980808';
export const white = '#fff';

const muiTheme = createTheme(
  adaptV4Theme({
    palette: {
      primary: {
        main: colorPrimary,
      },
      secondary: {
        main: colorSecondary,
      },
      error: {
        main: colorError,
      },
    },
    typography: {
      fontFamily: 'Lato, sans-serif',
      fontSize: 14,
      color: colorPrimary,
    },
    overrides: {
      MuiInputBase: {
        input: {
          backgroundColor: '#fff',
          fontSize: 14,
          fontWeight: 300,
          color: formTextColor,
        },
      },
      MuiSelect: {
        selectMenu: {
          backgroundColor: '#fff',
          width: '100%',
        },
      },
      MuiOutlinedInput: {
        input: {
          padding: '5px 0 5px 5px',
        },
      },
      MuiFormControl: {
        root: {
          boxSizing: 'border-box',
          borderStyle: 'solid',
          borderColor: '#e4e6e8',
          backgroundColor: '#f1f4f8',
          borderWidth: 1,
          boxShadow: '0 0 3px 1px rgb(0 0 0 / 6%)',
          padding: 4,
          width: '100%',
        },
      },
      MuiButton: {
        root: {
          textTransform: 'none',
          color: '#fff',
          backgroundColor: colorPrimary,
          fontSize: '14px',
          fontWeight: 500,
          cursor: 'pointer',
          borderRadius: 2,
          borderColor: colorPrimary,
          height: 40,
          width: '30%',
          boxShadow: '0 2px 4px 0 rgb(0 0 0 / 50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '&.Mui-disabled': {
            color: '#fff',
          },
          '&:hover': {
            backgroundColor: colorPrimary,
          },
        },
      },
    },
  })
);

export default muiTheme;
