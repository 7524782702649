import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { addSpaceToNumber } from '../../../../utils/jsFunctions';
import EstimationPriceGraduation from './components/EstimationPriceGraduation';
import Footer from './components/Footer';
import Header from './components/Header';
import ImageContainer from './components/ImageContainer';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  formDatas: IExternalPdfLandForm;
  plotDatas: {
    parcelsOnCadastrePicture: string;
    parcelsOnZonePicture: string;
    area: number;
    freeGroundArea: number;
    builtArea: number;
    recalculatedArea: number;
    builtHeight: number;
  };
  landType: string;
}

function EstimatePage({ formDatas, plotDatas, agency, landType }: IProps) {
  const joinedCustomers = formDatas.owners.map((m) => m.name).join(', ');

  return (
    <Page size="A4" style={pageStyles.page}>
      <Header
        lines={['Bien estimer votre bien', `Etude préparée pour ${joinedCustomers}`]}
      />

      <View style={styles.analysisBloc}>
        <Text style={[fontStyles.b700, fontStyles.fs14]}>
          L’analyse des différents paramètres comme :
        </Text>
        <View style={styles.ulPadding}>
          <Text style={styles.analysisText}>
            <Text>-</Text> Les caractéristiques spécifiques de votre bien et de son
            environnement
          </Text>
          <Text style={styles.analysisText}>
            <Text>-</Text> L&#39;évaluation des tendances du marché
          </Text>
          <Text style={styles.analysisText}>
            <Text>-</Text> L&#39;analyses de la base de données Orpi et des biens
            vendus dans le secteur
          </Text>
        </View>
      </View>

      <Text style={styles.addressBloc}>
        nous amènent à vous présenter aujourd’hui une estimation de la valeur de
        votre terrain, situé :{' '}
        <Text
          style={fontStyles.b700}
        >{`${formDatas.addressStreet} ${formDatas.addressCity}`}</Text>
      </Text>

      <View style={styles.imageBloc}>
        <View style={styles.imageContainer}>
          <ImageContainer
            width="280px"
            height="200px"
            borderRadius="15px"
            localImg={`data:image/jpeg;base64,${plotDatas.parcelsOnCadastrePicture}`}
          />
        </View>
        <View>
          <Text>Type - {landType}</Text>
          <Text>Surface de la parcelle: {addSpaceToNumber(plotDatas.area)} m²</Text>
        </View>
      </View>

      <EstimationPriceGraduation
        maxPrice={formDatas.maxSellPrice}
        minPrice={formDatas.minSellPrice}
        price={formDatas.estimatedPrice}
        area={formDatas.landArea}
      />

      <View style={styles.observationBloc}>
        <Text style={[fontStyles.b700, fontStyles.fs14, { marginBottom: '5px' }]}>
          Observations
        </Text>
        <Text style={styles.description}>{formDatas.priceComment}</Text>
        <Text style={{}}>
          Cette estimation, non contractuelle, est un avis de valeur qui ne peut se
          substituer à une expertise immobilière.Elle reflète un état du marché à
          date, et a une durée de validité limitée dans le temps.
        </Text>
      </View>

      <Footer agency={agency} formDatas={formDatas} />
    </Page>
  );
}

export default EstimatePage;

const styles = StyleSheet.create({
  ulPadding: { paddingLeft: '20px' },
  analysisBloc: { fontSize: '14px', marginTop: '10px' },
  analysisText: { paddingVertical: '3px' },
  addressBloc: {
    marginTop: '20px',
    fontSize: '14px',
  },
  imageBloc: {
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '20px',
  },
  description: { paddingBottom: '10px' },
  imageContainer: { marginRight: '20px' },
  observationBloc: { fontSize: '14px', marginTop: '20px' },
});
