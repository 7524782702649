import { useAppSelector } from '../../App/store';
import crossIcon from '../../assets/images/crossicon.png';
import BuildPermitsTable from '../../features/buildPermits/buildPermisTable';

import { useLocation } from 'react-router';
import useRightPanel from '../../App/hooks/useRightPanel';
import { getPanelState } from '../../features/panels/panelsSlice';
import { PDF_URLS } from '../../shared/constants';
import TabContentContainer from './TabsContent/TabContentContainer';
import './rightPanelModal.scss';

const RightPanelModal = () => {
  const {
    rightPanelDisplay,
    rightPanelContent: { builtPermisTable, prospectionContent },
  } = useAppSelector(getPanelState);
  const { closeRightPanel } = useRightPanel();
  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);

  if (!rightPanelDisplay) {
    return null;
  }

  const handleClose = (): void => {
    closeRightPanel();
  };

  return (
    <>
      {!pdfPath && (
        <div className={`right-panel-modal ${rightPanelDisplay ? 'displayed' : ''}`}>
          <div className="arrow-wrapper " onClick={handleClose}>
            <img src={crossIcon} alt="close" />
          </div>

          {rightPanelDisplay && (
            <>
              {builtPermisTable && <BuildPermitsTable />}
              {prospectionContent && <TabContentContainer />}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default RightPanelModal;
