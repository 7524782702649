import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import appStore from '../../assets/images/app-store-icon.png';
import googlePlay from '../../assets/images/google-play-icon.png';
import { getMobileOperatingSystem } from '../../utils/jsFunctions';
import styles from './mobileAppDownload.module.scss';

function MobileAppDownload() {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && getMobileOperatingSystem() && (
        <div className={styles.mobileAppContainer}>
          <div className={styles.mobileAppDownload}>
            <FontAwesomeIcon icon={faXmark} onClick={handleClose} />
            <div className={styles.mobileAppDownloadContent}>
              <p>Voulez vous continuer sur notre application mobile?</p>
              <div className={styles.imageContainer}>
                <a
                  href="https://apps.apple.com/us/app/urbanease/id1568673749"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={appStore} alt="lien apple store" />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=io.urbanease.www&hl=fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={googlePlay} alt="lien Google play" />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MobileAppDownload;
