import IconContainer from './IconContainer';

/**
 * UpdateIcon Component
 * @param {number?} width - default 32
 * @param {number?} height - default 32
 * @param {ColorType?} color - svg color default WHITE
 * @param {ColorType?} bgColor - bacground color default PRIMARY
 * @param {()=>void?} onClick - default undefined
 * @param {string?} className - default undefined
 * @param {ColorType?} tooltipBgColor - tooltip background colordefault DARK_PRIMARY
 * @param {ColorType?} tooltipColor - tooltip color default WHITE
 * @param {string?} tooltipText - tooltip txt default undefined
 * @param {boolean?} tooltipDisabled - tooltip disabled default undefined/false
 * @param {boolean?} isRoundIcon - circle iconContainer default undefined/false
 * @param {string?} dataCy - cypress name default undefined
 * @returns UpdateIcon react component
 */
function UpdateIcon(props: ISharedIconsProps) {
  const { tooltipText } = props;

  return (
    <IconContainer {...props} tooltipText={tooltipText ?? 'Modifier'}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <path
          // fill={color}
          d="M2.5 14.375V17.5H5.625L14.8417 8.28333L11.7167 5.15833L2.5 14.375ZM17.8417 5.28333L14.7167 2.15833L12.6083 4.27499L15.7333 7.39999L17.8417 5.28333Z"
        />
      </svg>
    </IconContainer>
  );
}

export default UpdateIcon;
