export const folderMarkersColor = [
  '#10B2F7',
  '#1664DB',
  '#798E8A',
  '#E2DB13',
  '#E38F14',
  '#ED24F1',
  '#E21313',
  '#50D313',
  '#13E2BD',
  '#944BF2',
];
