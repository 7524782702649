import { Button } from '@mui/material';

export default function SubmitButton({
  label,
  onClick,
  noSubmit = false,
  disabled,
  dataCy,
}: {
  label: string;
  onClick?: () => void;
  noSubmit?: boolean;
  disabled?: boolean;
  dataCy?: string;
}) {
  return (
    <div className="submit-block">
      <Button
        type={noSubmit ? 'button' : 'submit'}
        onClick={onClick}
        disabled={disabled}
        data-cy={dataCy}
      >
        {label}
      </Button>
    </div>
  );
}
