import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch } from '../../../../App/store';
import { PDF_LAND_MAX_PRICE_COMMENT_CHARACTERS } from '../../../../shared/constants';
import fetchProspecAdsStatsThunk from '../../services/thunks/landPDF/fetchProspecAdsStatsThunk';
import TextAreaInputFormPdfErrial from '../../shared/components/rightPanelModalExternalPdfErrial/TextAreaInputFormPdfErrial';
import styles from '../styles/prospecForm.module.scss';
import useProspecDatas from '../useProspecDatas';
import ProspecPriceEstimateField from './ProspecPriceEstimateField';
import ProspecPriceStatsForks from './ProspecPriceStatsForks';

export interface IProspecPriceStatsProps {
  stats: ProspecGetAdsStats | null;
}
function ProspecPriceStats() {
  const {
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { prospecCityId, adsStats } = useProspecDatas();
  const [error, setError] = useState<'min' | 'max' | 'fork' | null>(null);

  const watchedValues = watch(['minSellPrice', 'maxSellPrice', 'estimatedPrice']);
  const dispatch = useAppDispatch();

  const getErrors = () => {
    // errors checks
    if ((watchedValues[0] !== 0 && watchedValues[1] !== 0) || watchedValues[2] > 0)
      if (watchedValues[0] > watchedValues[1]) {
        setError('fork');
      } else if (watchedValues[0] > watchedValues[2]) {
        setError('min');
      } else if (watchedValues[1] < watchedValues[2]) {
        setError('max');
      } else {
        setError(null);
      }
  };

  useEffect(() => {
    const checkInitValue = (name: string) => {
      const v = watch(name);
      // if value not exist or exists but equal to 0
      return !v || v === 0;
    };

    // check errors
    if (adsStats.result) {
      if (checkInitValue('minSellPrice'))
        setValue('minSellPrice', adsStats.result?.sellMinPrice ?? 0);
      if (checkInitValue('avgSellPrice'))
        setValue('avgSellPrice', adsStats.result?.sellAveragePrice ?? 0);
      if (checkInitValue('avgSellPrice'))
        setValue('avgSellPrice', adsStats.result?.sellAveragePrice ?? 0);
      if (checkInitValue('maxSellPrice'))
        setValue('maxSellPrice', adsStats.result?.sellMaxPrice ?? 0);
    }

    getErrors();
  }, [adsStats.result]);
  useEffect(() => {
    // display errors whel values are updated
    getErrors();
  }, [watchedValues]);
  useEffect(() => {
    // load datas if no exists
    if (prospecCityId && !adsStats.result) {
      dispatch(
        fetchProspecAdsStatsThunk({
          cityId: prospecCityId.result,
          area: watch('landArea'),
        })
      );
    }
  }, []);

  return (
    <div className={styles.prospecPriceStats}>
      <ProspecPriceStatsForks stats={adsStats.result} error={error} />

      <hr />

      <div className={styles.estimationPriceContainer}>
        <ProspecPriceEstimateField error={error} />

        <div
          className={`${styles.commentContainer} ${
            errors.priceComment?.message ? styles.error : ''
          }`}
        >
          <p>
            <span>
              Commentaire sur la stratégie d&apos;estimation (
              {PDF_LAND_MAX_PRICE_COMMENT_CHARACTERS} caractères max)
            </span>
          </p>
          <TextAreaInputFormPdfErrial
            name="priceComment"
            rowsMax={6}
            rowsMin={3}
            placeholder="Commentaire ..."
            className={errors.priceComment?.message ? styles.error : ''}
          />
        </div>
      </div>
      <hr />
    </div>
  );
}

export default memo(ProspecPriceStats);
