import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../../../../App/store';
import { getStudyParamsState } from '../../../../../../features/study/slices/studyParamsSlice';
import { getZoneAndZoneChildString } from '../../../../../../lib/formats/zoneAndZoneChildFormat';

interface IUrbanismeFolderPlotListProps {
  zone: nullable<IZone>;
  folderPlotZones: nullable<{ plotId: string; zone: IZone }[]>;
}
export default function UrbanismeFolderPlotList({
  zone,
  folderPlotZones,
}: IUrbanismeFolderPlotListProps) {
  const { type } = useAppSelector(getStudyParamsState);
  return (
    <div className="plot-list">
      {type === 'plotStudy' ? (
        <p className="urbanisme-zone">
          Zonage:{' '}
          {getZoneAndZoneChildString({
            zone: zone?.zone ?? null,
            zoneChild: zone?.zoneChild ?? null,
          })}
        </p>
      ) : (
        folderPlotZones?.map((fp) => {
          return (
            <p key={nanoid()}>
              {`Parcelle ${fp.plotId} : ${getZoneAndZoneChildString(fp.zone)}`}
            </p>
          );
        })
      )}
    </div>
  );
}
