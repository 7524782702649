import styles from '../toolbars.module.scss';
import CreateToolbarBtn from '../urbaToolbar/CreateToolbarBtn';
import useMapCenterBtn from './hooks/useMapCenterBtn';

function CesiumMapBtn() {
  const { handleClick } = useMapCenterBtn();

  return (
    <CreateToolbarBtn
      icon="CESIUM_MAP"
      onClick={() => handleClick('/3d-view')}
      isFontAwesomeIcon
      tooltip={
        <p className={[styles.btnTooltip, styles.left].join(' ')}>Afficher map 3D</p>
      }
    />
  );
}

export default CesiumMapBtn;
