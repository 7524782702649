interface IPricehubbleGenericParserProps {
  ph_status_code: number;
  response: any;
}
function parseErrorResponse(datas: IPricehubbleGenericParserProps) {
  switch (datas.ph_status_code) {
    case 404:
    case 400:
    case 401:
    case 422:
    case 500:
    default:
      throw new Error(datas.response);
  }
}

function pricehubbleGenericParser(datas: IPricehubbleGenericParserProps) {
  if (datas.ph_status_code < 300) {
    return datas.response;
  } else {
    parseErrorResponse(datas);
  }
}

export default pricehubbleGenericParser;
