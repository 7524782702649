import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';
import styles from './constructComment.module.scss';

interface IProps {
  label: string;
  name: 'comment' | 'askingPrice' | 'expectedRevenue' | 'buildableArea';
  updateAllowed: boolean;
  dataCy: string;
}

function ConstructInputAndLabel({ name, updateAllowed, dataCy, label }: IProps) {
  const { control } = useFormContext<ConstructFormType>();

  return (
    <div className={styles.fieldContainer}>
      <label htmlFor={name}>{label}</label>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <NumericFormat
            value={value}
            disabled={!updateAllowed}
            onFocus={(e) => e.target.select()}
            data-cy={dataCy}
            onValueChange={(values) => onChange(values.floatValue)}
            customInput={TextField}
            thousandSeparator=" "
            autoComplete="off"
          />
        )}
      />
    </div>
  );
}

export default ConstructInputAndLabel;
