import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { studyMatrixApiToStoreParser } from '../utils/parsers/studyMatrixParser';

async function createFeasibilityMatrix(datas: Omit<IFeasibilityMatrix, 'idIri'>) {
  try {
    if (datas && (datas.folder || datas.plotStudy)) {
      const result = await postEntity({
        endpoint: '/feasibility_matrices',
        body: datas,
      });

      const parsed = studyMatrixApiToStoreParser(result);

      return parsed;
    } else {
      return Promise.reject(paramsNotFound('createFeasibilityMatrix'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default createFeasibilityMatrix;
