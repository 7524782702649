import { useAppDispatch } from '../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../components/Modals/GenericModal';
import { modalsActions } from '../../modals/modalsSlice';
import { authActions } from '../authSlice';
import FormContainer from './FormContainer';

const LoginModal = (): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleOpenSignupModal = () => {
    dispatch(authActions.resetError());
    dispatch(modalsActions.signup(true));
  };

  return (
    <GenericModal
      title={''}
      closeModal={handleOpenSignupModal}
      modalType={GenericModalEnum.INFO}
      actionType={GenericModalActionEnum.NONE}
      hasIcon={false}
    >
      <FormContainer />
    </GenericModal>
  );
};

export default LoginModal;
