import { Path, Svg } from '@react-pdf/renderer';

const ShoppingSvg = ({ color }: { color?: string }) => (
  <Svg
    height="24"
    width="24"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M7.23544 21.3558C6.76644 21.3558 6.36749 21.1916 6.03859 20.8632C5.70969 20.5348 5.54524 20.1362 5.54524 19.6672C5.54524 19.1981 5.70941 18.7992 6.03777 18.4703C6.36613 18.1414 6.76482 17.9769 7.23384 17.9769C7.70284 17.9769 8.10179 18.1411 8.43069 18.4695C8.75959 18.7978 8.92404 19.1965 8.92404 19.6655C8.92404 20.1346 8.75987 20.5335 8.43152 20.8624C8.10315 21.1913 7.70446 21.3558 7.23544 21.3558ZM16.746 21.3558C16.277 21.3558 15.878 21.1916 15.5491 20.8632C15.2202 20.5348 15.0558 20.1362 15.0558 19.6672C15.0558 19.1981 15.22 18.7992 15.5483 18.4703C15.8767 18.1414 16.2754 17.9769 16.7444 17.9769C17.2134 17.9769 17.6124 18.1411 17.9413 18.4695C18.2702 18.7978 18.4346 19.1965 18.4346 19.6655C18.4346 20.1346 18.2704 20.5335 17.9421 20.8624C17.6137 21.1913 17.215 21.3558 16.746 21.3558ZM6.05577 5.77019L8.59039 11.0779H15.4894L18.2587 6.03944C18.2971 5.96892 18.3003 5.90641 18.2683 5.85191C18.2362 5.79743 18.1817 5.77019 18.1048 5.77019H6.05577ZM7.23294 16.4163C6.58789 16.4163 6.10143 16.1407 5.77357 15.5895C5.4457 15.0384 5.43337 14.4861 5.73659 13.9327L7.16352 11.3919L3.56542 3.81056H2.37887C2.18787 3.81056 2.0273 3.74179 1.89717 3.60424C1.76703 3.46667 1.70197 3.30228 1.70197 3.11106C1.70197 2.91984 1.7704 2.75546 1.90727 2.61791C2.0441 2.48034 2.20963 2.41156 2.40387 2.41156H3.91184C4.07447 2.41156 4.22358 2.45339 4.35917 2.53704C4.49473 2.6207 4.59937 2.73785 4.67309 2.88849L5.37694 4.37119H18.9913C19.4509 4.37119 19.774 4.53433 19.9605 4.86061C20.1471 5.18689 20.1365 5.53978 19.9288 5.91926L16.8058 11.5817C16.6551 11.8631 16.4485 12.0827 16.186 12.2404C15.9235 12.3981 15.6333 12.4769 15.3154 12.4769H8.14039L6.96252 14.6327C6.91123 14.7096 6.90962 14.7929 6.95769 14.8827C7.00577 14.9725 7.0779 15.0173 7.17407 15.0173H17.7625C17.9503 15.0173 18.1093 15.0861 18.2394 15.2237C18.3695 15.3612 18.4346 15.5256 18.4346 15.7168C18.4346 15.9081 18.3662 16.0724 18.2293 16.21C18.0925 16.3476 17.9285 16.4163 17.7375 16.4163H7.23294Z"
      fill={color ?? 'currentColor'}
    />
  </Svg>
);

export default ShoppingSvg;
