import { Button, TextField } from '@mui/material';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getDashboardOwnerDatasState } from '../../../features/dashboardOwnerDatas/dashboardOwnerDatasSlice';
import {
  fetchOwnerInfosFromSirenThunk,
  fetchOwnerPlotFromSirenThunk,
} from '../../../features/dashboardOwnerDatas/dashboardOwnerDatasThunk';
import styles from './filterSortBloc.module.scss';

interface IGenericInputResearchownerDatasProps {
  placeHolder?: string;
}
function GenericInputResearchOwnerDatas({
  placeHolder = '',
}: IGenericInputResearchownerDatasProps) {
  const { siren, plotsFromSiren } = useAppSelector(getDashboardOwnerDatasState);
  const [sirenInputField, setSirenInputField] = useState<string | null>(siren);
  const dispatch = useAppDispatch();

  // SIREN RESEARCH BUTTON CLICK
  const handleClick = (sirenParam: string | null) => {
    if (siren !== sirenParam) {
      const query = {
        siren: sirenParam,
        page: plotsFromSiren?.result?.page ?? 1,
        itemsPerPage: plotsFromSiren?.result?.itemsPerPage ?? 15,
      };
      // launch owner infos
      dispatch(fetchOwnerInfosFromSirenThunk({ siren: sirenParam }));

      // launch owner plots
      dispatch(
        fetchOwnerPlotFromSirenThunk({
          ...query,
          sort: plotsFromSiren?.result?.sort ?? 'city_name',
          order: plotsFromSiren?.result?.order ?? 'asc',
        })
      );
    }
  };

  useEffect(() => {
    setSirenInputField(siren);
  }, [siren]);

  return (
    <div className={styles.genericSearch}>
      <TextField
        variant="outlined"
        className={styles.inputResearch}
        placeholder={placeHolder}
        value={sirenInputField ?? ''}
        onChange={(e) => setSirenInputField(e.target.value)}
      />
      <Button
        className={`${styles.researchBtn} ${styles.buttonBase}`}
        onClick={() => handleClick(sirenInputField)}
      >
        Rechercher
      </Button>
    </div>
  );
}

export default memo(GenericInputResearchOwnerDatas);
