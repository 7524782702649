import { createPattern } from 'protomaps';
import {
  getColorCodePmTilesPlu,
  pmTilesColorParser,
} from './getColorCodePmTilesPlu';
import { categoriesPluEnv, pluOtherRules } from './constants';

// This function creates a grid pattern with vertical and horizontal lines on an 8x8 canvas, using the specified color.
function gridPattern(color: string) {
  return createPattern(8, 8, (c) => {
    const ctx = c.getContext('2d');
    if (ctx) {
      // Clear previous drawings if any
      ctx.clearRect(0, 0, c.width, c.height);

      // Calculate the spacing between lines
      const spacingX = c.width / 30; // Adjusted spacing between vertical lines
      const spacingY = c.height / 30; // Adjusted spacing between horizontal lines
      const lineThickness = 1;

      // Set the stroke color and line width
      ctx.strokeStyle = color;
      ctx.lineWidth = lineThickness;

      // Draw vertical lines
      for (let i = 1; i < 5; i++) {
        const x = i * spacingX - lineThickness / 2; // Adjust position to center lines
        ctx.beginPath();
        ctx.moveTo(x, 0);
        ctx.lineTo(x, c.height);
        ctx.stroke();
      }

      // Draw horizontal lines
      for (let j = 1; j < 5; j++) {
        const y = j * spacingY - lineThickness / 2; // Adjust position to center lines
        ctx.beginPath();
        ctx.moveTo(0, y);
        ctx.lineTo(c.width, y);
        ctx.stroke();
      }
    }
  });
}

// This function creates a pattern with horizontal grooves on a 4x4 canvas, using the specified color.
function groovesPattern(color: string) {
  return createPattern(4, 4, (c) => {
    const ctx = c.getContext('2d');
    if (ctx) {
      // Clear the canvas before drawing
      ctx.clearRect(0, 0, c.width, c.height);

      // Begin drawing path for grooves
      ctx.beginPath();
      // Draw the first groove as a rectangle at the top of the canvas
      ctx.rect(0, 0, 4, 1);
      // Draw the second groove as a rectangle near the bottom of the canvas
      ctx.rect(0, 5, 4, 1);
      // Set the fill color to the specified color
      ctx.fillStyle = color;

      // Fill the drawn rectangles with the specified color
      ctx.fill();
    }
  });
}

// This function creates a pattern with larger dots at two specific positions on an 8x8 canvas, using the specified color.
function dotPattern(color: string) {
  return createPattern(8, 8, (c) => {
    // Increase the canvas size to allow more space
    const ctx = c.getContext('2d');
    if (ctx) {
      // Clear the canvas before drawing
      ctx.clearRect(0, 0, c.width, c.height);

      // Draw larger dots with more space between them
      ctx.beginPath();
      ctx.arc(2, 2, 2, 0, 2 * Math.PI); // Dot at (2, 2) with radius 2
      ctx.arc(6, 6, 2, 0, 2 * Math.PI); // Dot at (6, 6) with radius 2
      ctx.fillStyle = color;
      ctx.fill();
    }
  });
}

export class MyPaintSymbolizerPlu {
  draw(context: any, geom: any, properties: any, attr: any) {
    // border for zone
    context.strokeStyle = 'red';
    context.lineWidth = 4;
    context.beginPath();
    // background color for zone
    context.fillStyle = getColorCodePmTilesPlu({
      zone: attr.props.zone ?? attr.props.typezone,
      opacity: 0.6,
    });

    for (const polygon of geom) {
      for (let p = 0; p < polygon.length; p++) {
        const pt = polygon[p];
        if (p === 0) context.moveTo(pt.x, pt.y);
        else context.lineTo(pt.x, pt.y);
      }
    }

    context.stroke();
    context.fill();
  }
}

export class MyPaintSymbolizerPluOther {
  draw(context: any, geom: any, properties: any, attr: any) {
    // search the rules to apply for the plu
    const pluOther = pluOtherRules.find(
      (elt) =>
        elt.cat === attr.props.cat &&
        (!('cat_child' in attr.props) || elt.catChild === attr.props.cat_child)
    );

    if (pluOther) {
      // border
      context.strokeStyle = 'red';
      context.lineWidth = 4;
      // background color
      context.fillStyle = pmTilesColorParser({
        color: pluOther.mainColor,
        opacity: 1,
      });
      // border dash
      if (pluOther?.borderDash === 'Tirets') {
        context.lineWidth = 8;
        context.setLineDash([5, 6]);
        context.beginPath();
      }
      // background pattern
      if (pluOther?.pattern) {
        let pattern;

        if (pluOther.pattern === 'Rayures')
          pattern = groovesPattern(pluOther.mainColor);
        if (pluOther.pattern === 'quadrillage')
          pattern = gridPattern(pluOther.mainColor);
        if (pluOther.pattern === 'Petits Points')
          pattern = dotPattern(pluOther.mainColor);

        context.pattern = pattern;
        const patten = context.createPattern(pattern, 'repeat');
        if (patten) context.fillStyle = patten;
      }

      // geom
      for (const polygon of geom) {
        for (let p = 0; p < polygon.length; p++) {
          const pt = polygon[p];
          if (p === 0) context.moveTo(pt.x, pt.y);
          else context.lineTo(pt.x, pt.y);
        }
      }

      context.stroke();
      context.fill();
    }
  }
}

export class MyPaintSymbolizerPluEnv {
  draw(context: any, geom: any, properties: any, attr: any) {
    // search the rules to apply for the plu env
    const pluEnv = categoriesPluEnv.find((elt) => elt.cat === attr.props.cat);

    if (pluEnv) {
      // background color "Quartiers prioritaires"
      context.fillStyle = pmTilesColorParser({
        color: pluEnv.mainColor,
        opacity: 1,
      });
      if (pluEnv.cat === '2') {
        if (attr.props.cat_child === '500m')
          context.fillStyle = pmTilesColorParser({ color: '#35EDA7', opacity: 1 });
        if (attr.props.cat_child === '300m')
          context.fillStyle = pmTilesColorParser({ color: '#B035ED', opacity: 1 });
        if (attr.props.cat_child === 'QPV-2015')
          context.fillStyle = pmTilesColorParser({ color: '#BF0303', opacity: 1 });
        if (attr.props.cat_child === 'QPV-2015-modif')
          context.fillStyle = pmTilesColorParser({ color: '#BF0303', opacity: 1 });
        if (attr.props.cat_child === 'QPV-2024')
          context.fillStyle = pmTilesColorParser({ color: '#1B82E4', opacity: 1 });
      }
    } else {
      context.fillStyle = pmTilesColorParser({ color: '#A0A0A0', opacity: 1 });
    }

    // geom
    for (const polygon of geom) {
      for (let p = 0; p < polygon.length; p++) {
        const pt = polygon[p];
        if (p === 0) context.moveTo(pt.x, pt.y);
        else context.lineTo(pt.x, pt.y);
      }
    }

    context.stroke();
    context.fill();
  }
}
