import {
  faCheck,
  faExclamation,
  faInfo,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { GenericModalEnum } from '.';
import styles from './genericModal.module.scss';

export const getCssTheme = (
  type: GenericModalEnum
): { styles: string; icon: IconDefinition } => {
  switch (type) {
    case GenericModalEnum.CONFIRMATION:
      return { styles: styles.confirmationTheme, icon: faCheck };
    case GenericModalEnum.WARNING:
      return { styles: styles.warningTheme, icon: faExclamation };
    case GenericModalEnum.ERROR:
      return { styles: styles.errorTheme, icon: faExclamation };
    case GenericModalEnum.INFO:
    default:
      return { styles: styles.infoTheme, icon: faInfo };
  }
};
