import {
  predictionOriginEnum,
  PredictionTypeEnum,
} from '../../App/contexts/InputTabsContext';
import { createRequestQuery } from '../../services/axiosFiles/sagaCommon';
import { axiosTokenInstance } from '../../services/AxiosProtectedInstance';

const BASE_URL = process.env.REACT_APP_GOOGLE_FROM_PHP_ENDPOINT;

export default (() => {
  const extractDataFromAddressArray = (
    components: ExtractDataFromAddress[],
    type: string
  ): ExtractDataFromAddress | undefined => {
    return components.find(
      (c: ExtractDataFromAddress) => c.types.indexOf(type) !== -1
    );
  };

  // *********************************************
  // ***** getLocationByLatLng result parser *****
  // *************** Not exported ****************
  // *********************************************
  const goodCountries = [
    'France',
    'Réunion',
    'Martinique',
    'Guadeloupe',
    'French Guiana',
    'Mayotte',
    'Guyane Française',
  ];
  const googleLocationByLatlngParser = (
    lat: number,
    lng: number,
    data: any
  ): GeolocPointInfo | null => {
    try {
      let goodDatas = data.find((f: any) => f.types?.includes('street_address'));

      if (!goodDatas) {
        goodDatas = data.find((f: any) => f.types?.includes('route'));
      }

      if (!goodDatas) {
        goodDatas = data.find((f: any) => f.types?.includes('locality'));
        const postalCodeContainer = data.find((f: any) =>
          f.types?.includes('postal_code')
        );
        if (!postalCodeContainer) {
          throw new Error('postal_code not found');
        }
        goodDatas.postalCode =
          (goodDatas.postalCode = extractDataFromAddressArray(
            postalCodeContainer.address_components,
            'postal_code'
          )?.long_name) ?? null;
      }

      if (goodDatas) {
        const country = extractDataFromAddressArray(
          goodDatas.address_components,
          'country'
        )?.long_name;

        if (country && !goodCountries.includes(country)) {
          throw new Error('no-fr');
        }

        const city =
          extractDataFromAddressArray(goodDatas.address_components, 'locality')
            ?.short_name ?? null;

        const addressName =
          extractDataFromAddressArray(goodDatas.address_components, 'route')
            ?.short_name ?? null;
        const address =
          addressName === 'Route sans nom'
            ? goodDatas.formatted_address.substring(16)
            : goodDatas.formatted_address;

        const postalCode =
          goodDatas.postalCode ??
          (goodDatas.postalCode = extractDataFromAddressArray(
            goodDatas.address_components,
            'postal_code'
          )?.long_name) ??
          null;

        const department = postalCode?.substring(0, 2) ?? null;

        const parsedDatas = {
          city,
          postalCode,
          houseNumber: null,
          streetName: null,
          address,
          department,
          coordinates: [lat, lng],
        };

        return parsedDatas as GeolocPointInfo;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  // *********************************************
  // ***** getPredictionDetails result parser ****
  // *************** Not exported ****************
  // *********************************************
  const predictionDetailsParser = (result: any) => {
    const city =
      extractDataFromAddressArray(result.address_components, 'locality')
        ?.long_name ?? null;

    const departmentName =
      extractDataFromAddressArray(
        result.address_components,
        'administrative_area_level_2'
      )?.long_name ?? null;

    const regionName =
      extractDataFromAddressArray(
        result.address_components,
        'administrative_area_level_1'
      )?.long_name ?? null;

    const country =
      extractDataFromAddressArray(result.address_components, 'country')?.long_name ??
      null;

    const postalCode =
      extractDataFromAddressArray(result.address_components, 'postal_code')
        ?.long_name ?? null;

    const parsedResult: PredictionDetail = {
      city,
      departmentName,
      regionName,
      country,
      postalCode,
      department: postalCode?.substring(0, 2) ?? null,
      geometry: [
        parseFloat(result.geometry.location.lat),
        parseFloat(result.geometry.location.lng),
      ],
    };

    return parsedResult;
  };

  const getLocationByLatLngAsync = async (
    lat: number,
    lng: number
  ): Promise<GeolocPointInfo | null> => {
    try {
      const params = {
        latlng: `${lat},${lng}`,
      };

      const result = await axiosTokenInstance().get(`${BASE_URL}/geocode/json`, {
        params,
      });

      const parsedData = googleLocationByLatlngParser(lat, lng, result.data.results);

      return parsedData;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  // *********************************************
  // ** Get predictions from Google autocomplete *
  // ***************** Exported ******************
  // *********************************************
  const getPredictions = (search: string, options: GoogleAutocompleteOptions) => {
    const queries = createRequestQuery({ ...options, input: search });

    return new Promise<PredictionBase>((resolve, reject) => {
      const predictionBase: PredictionBase = {
        origin: predictionOriginEnum.GOOGLE,
        data: [],
      };
      return axiosTokenInstance()
        .get(`${BASE_URL}/place/autocomplete/json${queries}`)
        .then((response) => {
          const predictions: Prediction[] = response.data.predictions.map(
            (p: GoogleApiPrediction) => {
              let type: PredictionTypeEnum;

              switch (p.types[0]) {
                case 'street_address':
                case 'route':
                  type = PredictionTypeEnum.STREET;
                  break;
                case 'tourist_attraction':
                  type = PredictionTypeEnum.TOURIST_ATTRACTION;
                  break;
                case 'locality':
                default:
                  type = PredictionTypeEnum.MUNICIPALITY;
                  break;
              }
              return {
                id: p.place_id,
                label: p.description,
                type,
              };
            }
          );
          predictionBase.data = predictions;

          return resolve(predictionBase);
        })
        .catch((error) => {
          return reject(error);
        });
    });
  };

  // *********************************************
  // * Get prediction details for selected place *
  // ***************** Exported ******************
  // *********************************************
  const getPredictionDetails = (id: string, token: string | null) => {
    const queries = createRequestQuery({
      place_id: id,
      fields: 'address_component,geometry',
      language: 'fr',
      sessiontoken: token,
    });

    return new Promise<PredictionDetail>((resolve, reject) => {
      return axiosTokenInstance()
        .get(`${BASE_URL}/place/details/json${queries}`)
        .then((response) => {
          const result = response.data.result;

          const parsedResult = predictionDetailsParser(result);

          return resolve(parsedResult);
        })
        .catch((error) => {
          return reject({ status: '000', message: 'request error' });
        });
    });
  };

  return {
    getLocationByLatLngAsync,
    getPredictions,
    getPredictionDetails,
  };
})();
