import { memo } from 'react';

import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../../../App/store';
import checkOrpiPdfAllow from '../../../../../features/auth/utils/checkOrpiPdfAllow';
import { getCompanyState } from '../../../../../features/company/companySlice';
import { getPlotState } from '../../../../../redux/plot/reducer';
import RedDownloadButton from '../../../../../shared/components/buttons/redDownloadButton';
import styles from './pdfButton.module.scss';
const PDFButton = () => {
  const { company } = useAppSelector(getCompanyState);
  const { parcelle } = useAppSelector(getPlotState);
  const navigate = useNavigate();

  const isOrpi = checkOrpiPdfAllow(company.result?.firm ?? '');

  const handleClick = (url: string) => {
    navigate(url);
  };

  return (
    <div
      className={[styles.pdfButtonContainer, isOrpi && styles.pdfButtonDouble].join(
        ' '
      )}
    >
      <RedDownloadButton
        className={styles.orpiOnly}
        handleClick={() => handleClick('/pdf-orpi-terrain')}
        alt="download data button"
      >
        PDF Orpi terrain
      </RedDownloadButton>

      <RedDownloadButton
        handleClick={() => handleClick(isOrpi ? '/pdf-orpi' : '/pdf-urbanease')}
        alt="download data button"
      >
        {isOrpi ? 'PDF Orpi' : 'Editer PDF'}
      </RedDownloadButton>
    </div>
  );
};

export default memo(PDFButton);
