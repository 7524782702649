import { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

interface ILineGraphProps {
  labels: any[];
  datasets: any;
}

function LineGraph({ labels, datasets }: ILineGraphProps): JSX.Element {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChartRef = (chartRef as any)?.current.getContext('2d');

    const chart = new Chart(myChartRef, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        plugins: {
          legend: {
            position: 'right',
          },
        },
        animation: false,
      },
    });
    return () => chart.destroy();
  }, [labels, datasets]);

  return (
    <div className="graph-container">
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
}

export default LineGraph;
