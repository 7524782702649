import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ClipboardEvent, FocusEvent, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

interface IFieldInputPwdFormCustomProps {
  name: string;
  onChange?: ChangeEventCustom;
  label?: nullable<string>;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  InputProps?: any;
  isLoginForm?: boolean;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  errorTooltip?: FieldError;
  dataCy?: string;
}
export default function FieldInputPwdFormCustom({
  name,
  label,
  disabled,
  className,
  onFocus,
  required,
  placeholder,
  InputProps,
  readOnly = false,
  isLoginForm,
  dataCy,
}: IFieldInputPwdFormCustomProps) {
  // **** local states ****
  const [showPassword, setShowPassword] = useState(false);

  // **** context ****
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    if (onFocus) {
      onFocus(e);
    }
  };

  // **** handlers ****
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleCopyCutPast = (e: ClipboardEvent<HTMLDivElement>) => {
    if (!isLoginForm) {
      e.preventDefault();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          {...field}
          type={showPassword ? 'text' : 'password'}
          disabled={disabled}
          variant="outlined"
          label={label}
          error={Boolean(errors[name])}
          helperText={errors[name]?.message as string}
          placeholder={placeholder}
          className={className}
          onFocus={handleFocus}
          onCut={handleCopyCutPast}
          onCopy={handleCopyCutPast}
          onPaste={handleCopyCutPast}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            readOnly,
            ...InputProps,
          }}
          required={required}
          data-cy={dataCy}
        />
      )}
    />
  );
}
