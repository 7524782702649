export enum DrawActionEnum {
  DRAW_ADVANCED_SEARCH = 'DRAW_ADVANCED_SEARCH',
  DRAW_ADVANCED_SEARCH_START = 'DRAW_ADVANCED_SEARCH_START',
  DRAW_ADVANCED_SEARCH_CANCEL = 'DRAW_ADVANCED_SEARCH_CANCEL',
  DRAW_ADVANCED_SEARCH_SAVE_GEO = 'DRAW_ADVANCED_SEARCH_SAVE_GEO',
  DRAW = 'DRAW',
  DRAW_SECTOR = 'DRAW_SECTOR',
  DRAW_STOP = 'DRAW_STOP',
  DRAW_SAVE = 'DRAW_SAVE',
  EDIT = 'EDIT',
  EDIT_SAVE = 'EDIT_SAVE',
  EDIT_CANCEL = 'EDIT_CANCEL',
  DELETE = 'DELETE',
}

export enum DrawIconEnum {
  DRAW_ICON_START = 'DRAW_ICON_START',
  DRAW_ICON_STOP = 'DRAW_ICON_STOP',
  DRAW_ICON_EDIT_SAVE = 'DRAW_ICON_EDIT_SAVE',
  DRAW_ICON_EDIT_CANCEL = 'DRAW_ICON_EDIT_CANCEL',
  DRAW_ICON_DELETE = 'DRAW_ICON_DELETE',
}
