import { postEntity } from '../../../../../../services/axiosFiles/genericCrud';

interface IProps {
  insee: string | null;
  postalCode: string;
  city: string;
}

async function fetchProspecGetProspecCityId({ city, insee, postalCode }: IProps) {
  try {
    if (city && insee && postalCode) {
      const body = {
        city,
        insee,
        zipcode: postalCode,
      };

      const result: any = await postEntity({
        endpoint: '/pdf_urbanease/prospec_get_prospec_city_id',
        body,
      });

      if (result.status === 'OK') {
        return result.prospec_city_id;
      } else {
        throw new Error(result.status);
      }
    }
  } catch (error) {
    return null;
  }
}

export default fetchProspecGetProspecCityId;
