import { isEmpty } from 'lodash';
import { dateFormat } from '../../../lib/formats/dataFormat';
import { convertImageToBase64 } from '../../../shared/utils/imageTools';
import { imageDataParse } from '../../../utils/jsFunctions';
import {
  GroundTypeEnum,
  SocioEconomicModeEnum,
  TransportModeEnum,
} from '../shared/types/enums';
import { convertDistance } from '../shared/utils/utils';

// pricehubble datas
async function agencyParser(data: any) {
  if (!data) return null;
  const img = data.photo ? await convertImageToBase64(data.photo) : null;
  const parsed = {
    name: data.name,
    mail: data.email,
    photo: img,
    awards: data.awards,
    address: data.address,
    legalNotice: data.legalNotice,
    phone: data.phoneNumber,
    ORPIProfessions: data.ORPIProfessions,
  };

  return parsed as PdfLandOrpiAgency;
}
async function agentParser(data: any) {
  if (!data) return null;
  const img = await convertImageToBase64(data.photo);

  const parsed = {
    fullName: data.name,
    photo: img,
    phone: data.phoneNumber,
    mail: data.email,
    role: data.position,
  };

  return parsed as PdfLandAgent;
}
export async function phAgencyAndAgentDatasParser(datas: any) {
  if (!datas || isEmpty(datas)) return null;

  const agency = await agencyParser(datas.agency);
  const agent = await agentParser(datas.agent);

  const parsed: PHAgencyAndAgentDatas = {
    agency,
    agent,
  };

  return parsed;
}
export const phDealParser = async (data: any) => {
  const loc = data.property.location;

  const getUserField = (value: string) =>
    data.userDefinedFields.find((f: any) => f.label === value);

  const urls: string[] = [];
  if (typeof data.images === 'string') {
    urls.push(data.images);
  } else {
    urls.push(...data.images.map((m: any) => m.url));
  }

  const images = await Promise.all(urls.map((m: any) => convertImageToBase64(m)));
  const NC = 'Non communiqué';
  const propertyTax = (getUserField('Taxe foncière')?.value ?? '').match(/\d+/g);
  const annualCharges = (getUserField('Charges annuelles')?.value ?? '').match(
    /\d+/g
  );
  const rent = (getUserField('Valeur locative')?.value ?? '').match(/\d+/g);

  const parsed = {
    images,
    askingSalePrice: data.askingSalePrice,
    property: {
      landArea: data.property.landArea ?? 0,
      location: {
        coordinates: [loc.coordinates.latitude, loc.coordinates.longitude],
        address: {
          city: loc.address.city,
          houseNumber: loc.address.houseNumber,
          postalCode: loc.address.postCode,
          streetName: loc.address.street,
        },
      },
    },
    description: data.description ?? '',
    priceComment: data.priceStrategyDescription ?? 3,
    userDefinedFields: {
      propertyTax: propertyTax ? propertyTax[0] : undefined,
      annualCharges: annualCharges ? annualCharges[0] : undefined,
      rent: rent ? rent[0] : undefined,
      currentLease: getUserField('Bail en cours')?.value === 'Oui' ? true : false,
    },
  };

  return parsed as PDFLandDeal;
};
export const accessibilityParser = (datas: any) => {
  const properties15 = datas.geojson.features.find(
    (f: any) => f.properties.cutoff === 15
  ).properties;
  const body = {
    image: datas.picture,
    services: {
      shopping: {
        totalItems: properties15.densities.shopping,
      },
      restaurant: {
        totalItems: properties15.densities.catering,
      },
      leisure: {
        totalItems: properties15.densities.leisure,
      },
      education: {
        totalItems: properties15.densities.education,
      },
      health: {
        totalItems: properties15.densities.health,
      },
    },
  };

  return body as PDFLandAccessibility;
};
export const transportsParser = (datas: any) => {
  const d = datas.items[0];
  if (d) {
    const distance = convertDistance(d.distance);
    const key = d.subcategory as keyof typeof TransportModeEnum;
    const body = {
      distance,
      location: [d.location.coordinates.latitude, d.location.coordinates.longitude],
      name: d.name,
      subcategory: TransportModeEnum[key],
    };

    return body as PDFLandTransport;
  } else {
    return null;
  }
};
export const noiseParser = (datas: any) => {
  const s = datas.sources;
  const sourceKeys = Object.keys(s);

  const parsed = {
    level: datas.level,
    picture: imageDataParse(datas.picture),
    sources: sourceKeys.map((m: any) => {
      return {
        category: m,
        subcategory: s[m].subcategory as string,
        coordinates: [
          s[m].coordinates.latitude as number,
          s[m].coordinates.longitude as number,
        ],
        distance: s[m].distance,
      };
    }),
  };

  return parsed as PDFLandNoise;
};
export const socioEconomicParser = (
  datas: any,
  dataType: string
): PDFLandSocioEconomic => {
  const professionsLabelDict: {
    [key: string]: string;
  } = {
    agriculteurs_exploitants: 'Agriculteurs exploitants',
    artisans_commercants_chefs_dentreprise:
      "Artisans, Commerçants, Chefs d'entreprise",
    professions_intellectuelles_superieures:
      'Cadres et Professions intellectuelles supérieures',
    professions_intermediaires: 'Professions intermédiaires',
    employes: 'Employés',
    ouvriers: 'Ouvriers',
    retraites: 'Retraités',
    autres_sans_activite_professionnelle: 'Autres sans activité professionnelle',
  };

  const barChartParsed = ({
    items,
    age,
  }: {
    items: {
      absoluteValue: number;
      labelValue: string;
      relativeValue: number;
    }[];
    age: boolean;
  }) => {
    return items.map((elt) => {
      return {
        label: age ? elt.labelValue : professionsLabelDict[elt.labelValue],
        percentage: Number((elt.relativeValue * 100).toFixed(1)),
      };
    });
  };

  const lineChartParsed = ({
    items,
    valueIsPercentage,
  }: {
    items: {
      absoluteValue: number;
      labelValue: string;
      relativeValue: number;
    }[];
    valueIsPercentage: boolean;
  }) => {
    const data = items.map((elt) =>
      valueIsPercentage
        ? Number((elt.relativeValue * 100).toFixed(1))
        : Number(elt.absoluteValue.toFixed(0))
    );
    const labels = items.map((elt) =>
      new Date(elt.labelValue).getFullYear().toString()
    );
    return { data, labels };
  };

  if (dataType === SocioEconomicModeEnum['AGE']) {
    return {
      items: barChartParsed({ items: datas.items, age: true }),
      subcategory: dataType,
      year: datas.source.year,
    };
  }
  if (dataType === SocioEconomicModeEnum['PROFESSIONS']) {
    return {
      items: barChartParsed({ items: datas.items, age: false }),
      subcategory: dataType,
      year: datas.source.year,
    };
  }
  if (dataType === SocioEconomicModeEnum['POPULATION']) {
    return {
      items: lineChartParsed({ items: datas.items, valueIsPercentage: false }),
      subcategory: dataType,
      year: datas.source.year,
    };
  }

  return {
    items: lineChartParsed({ items: datas.items, valueIsPercentage: true }),
    subcategory: dataType,
    year: datas.source.year,
  };
};
export const builtPermitsParser = (datas: any) => {
  const getPermitStatusParse = (data: string) => {
    switch (data) {
      case 'construction_started':
        return 'Travaux commencés';
      case 'construction_ended':
        return 'Travaux terminés';
      case 'building_permit_issued':
        return 'Permis autorisé';
      case 'abandoned':
        return 'Abandonné';
      default:
        return 'Inconnu';
    }
  };

  const permits = datas.items.map((m: any, i: number) => {
    return {
      index: i + 1,
      buildingPermitIssuedDate: dateFormat(m.buildingPermitIssuedDate),
      constructionEndDate: dateFormat(m.buildingPermitIssuedDate),
      distance: m.distance,
      fullDescription: m.fullDescription,
      isCommercial: m.isCommercial,
      isPreciselyLocated: m.isPreciselyLocated,
      isResidential: m.isResidential,
      location: {
        address: m.location.address,
        coordinates: [
          m.location.coordinates.latitude,
          m.location.coordinates.longitude,
        ],
      },
      numberOfFloorsInBuilding: m.numberOfFloorsInBuilding,
      provider: m.provider,
      status: getPermitStatusParse(m.status),
      type: m.title,
    };
  });

  const parsed = { picture: imageDataParse(datas.picture), permits, count: 0 };

  return parsed as PDFLandBuildPermits;
};
const getLandAreaType = (index: number | null) => {
  switch (index) {
    case 0:
      return {
        type: GroundTypeEnum.GROUND_600,
        text: 'De 0 à 600 m²',
        title: 'Terrains de 0 à 600 m²',
      };
    case 1:
      return {
        type: GroundTypeEnum.GROUND_1000,
        text: 'De 600 à 1 000 m²',
        title: 'Terrains de 600 à 1 000 m²',
      };
    case 2:
      return {
        type: GroundTypeEnum.GROUND_MORE,
        text: 'Supérieur à 1 000 m²',
        title: 'Terrains supérieurs à 1 000 m²',
      };
    default:
      return {
        type: GroundTypeEnum.GROUND_UNKNOWN,
        text: 'Non renseigné',
        title: 'Dimensions de terrain non renseignées',
      };
  }
};

export const getLandAreaTypeFromString = (propertyCategory: string) => {
  switch (propertyCategory) {
    case 'ground600':
      return getLandAreaType(0);
    case 'ground1000':
      return getLandAreaType(1);
    case 'ground_more':
      return getLandAreaType(2);
    default:
      return getLandAreaType(null);
  }
};

export const getLandAreaTypeFromNumber = (propertyCategory: number | null) => {
  if (!propertyCategory && propertyCategory !== 0) {
    return getLandAreaType(null);
  }
  return propertyCategory < 600
    ? getLandAreaType(0)
    : propertyCategory < 1000
      ? getLandAreaType(1)
      : getLandAreaType(2);
};
// Prospec parsers
export const prospecGetAdsStatsParser = (datas: any) => {
  const parsed = {
    propertyCategory: getLandAreaTypeFromString(datas?.property_category),
    sellAveragePrice: datas?.a_la_vente_avg_price ?? null,
    sellAveragePriceArea: datas?.a_la_vente_avg_price_area ?? null,
    sellAverageArea: datas?.a_la_vente_avg_area ?? null,
    sellExplotableStock: datas?.a_la_vente_exploitable_stock ?? null,
    sellMaxPrice: datas?.a_la_vente_max_price ?? null,
    sellMinPrice: datas?.a_la_vente_min_price ?? null,
    catMaxLandArea: datas?.cat_max_land_area ?? null,
    catMinLandArea: datas?.cat_min_land_area ?? null,
    soldAveragePrice: datas?.vendu_avg_price ?? null,
    soldAverageArea: datas?.vendu_avg_area ?? null,
    soldAveragePriceArea: datas?.vendu_avg_price_area ?? null,
    soldExplotableStock: datas?.vendu_exploitable_stock ?? null,
    soldMaxPrice: datas?.vendu_max_price ?? null,
    soldMinPrice: datas?.vendu_min_price ?? null,
  };

  return parsed as ProspecGetAdsStats;
};
export const prospecGetSimilarAdsParser = (datas: any) => {
  try {
    const adParser = (ad: any) => {
      return {
        id: ad.id,
        label: ad.source_label,
        city: ad.city_name,
        price: ad.price,
        sellerType: ad.seller_type,
        builtArea: ad.built_area,
        landArea: ad.land_area,
        totalRoom: ad.total_room,
        picture: ad.picture ?? null,
        latitude: ad.latitude,
        longitude: ad.longitude,
        sqmPrice: ad.sqm_price,
        createdAt: ad.created_at,
        publishedAt: ad.published_at,
        isExclusive: ad.is_exclusive,
        isBargain: ad.is_bargain,
        ghgRank: ad.ghg_rank,
        energyRank: ad.energy_rank,
      };
    };
    const parsed = {
      cheaper: {
        list: datas.cheaper.similar_ads.map((m: any) => adParser(m)),
        total: datas.cheaper.similar_ads.length,
      },
      equal: {
        list: datas.equal.similar_ads.map((m: any) => adParser(m)),
        total: datas.equal.similar_ads.length,
      },
      higher: {
        list: datas.higher.similar_ads.map((m: any) => adParser(m)),
        total: datas.higher.similar_ads.length,
      },
    };

    return parsed as ProspecGetSimilars;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
