import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { loadersActions } from '../../../loaders/loaderSlice';
import fetchFolderAndSubsPlotStudiesForDisplay from '../fetchFolderAndSubsPlotStudiesForDisplay';

export const fetchAllFolderPlotStudiesForDisplayThunk = createAsyncThunk(
  'displayManager/fetchAllFolderPlotStudiesForDisplayThunk',
  async (params: { folders: Folders | null }, { getState, dispatch }) => {
    try {
      // get datas from store
      const state = getState() as RootState;
      const { users } = state.users;
      const { plotStudyStatuses } = state.app;
      const { companyId } = state.company;

      if (params.folders) {
        // launch all plot studies fetchr equest
        dispatch(loadersActions.loaderShow());
        await Promise.all(
          params.folders.map((m) =>
            fetchFolderAndSubsPlotStudiesForDisplay(
              m,
              users ?? [],
              plotStudyStatuses.result ?? [],
              companyId
            )
          )
        ).then((res) => dispatch(loadersActions.loaderHide()));
      } else {
        dispatch(loadersActions.loaderHide());
        return Promise.reject(paramsNotFound());
      }
    } catch (error) {
      dispatch(loadersActions.loaderHide());
      return Promise.reject(error);
    }
  }
);
