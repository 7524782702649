import { nanoid } from '@reduxjs/toolkit';
import { memo, useEffect, useState } from 'react';
import AddTab from './AddTab';
import GenericTab from './GenericTab';
import styles from './genericTab.module.scss';

interface IGenericTabContainerProps extends ITabActions {
  tabs: GenericTabsLabelsType;
  selectedDefault?: IGenericTabsLabelType | null;
  onSelect: (selected: IGenericTabsLabelType) => void;
  bigger?: boolean;
  isNavigationLocked?: boolean;
  canAddTab?: boolean;
  onAddTab?: () => void;
  externalIndex?: number;
  externalDispatch?: (values: number) => void;
}

export const currentDefaultValues = ({
  tabs,
  selectedDefault,
}: Pick<IGenericTabContainerProps, 'tabs' | 'selectedDefault'>) => {
  const index = Math.max(
    tabs.findIndex((tab) => tab.id === selectedDefault?.id),
    0
  );

  return {
    prev: null,
    curr: index,
    next: index < tabs.length - 1 ? index + 1 : null,
  };
};

function GenericTabContainer({
  tabs,
  selectedDefault,
  onSelect,
  bigger,
  canAddTab,
  onDelete,
  onUpdate,
  onAddTab,
  isNavigationLocked = false,
  externalIndex,
  externalDispatch,
}: IGenericTabContainerProps) {
  const [currentValues, setCurrentValues] = useState<CurrentValuesType>(
    currentDefaultValues({ tabs, selectedDefault })
  );
  const tabCount: number = tabs.length;
  const setSeletedIndex = (index: number) => {
    if (index >= 0 && index < tabCount) {
      const values: CurrentValuesType = {
        prev: index > 0 ? index - 1 : null,
        curr: index,
        next: index < tabCount - 1 ? index + 1 : null,
      };
      // if conditions are ok, it is an external choice and we need to send model to onSelect
      if (index === externalIndex && index !== currentValues.curr) {
        onSelect(tabs[index]);
      }
      externalDispatch?.(values.curr);
      setCurrentValues(values);
    } else {
      const values = currentDefaultValues({ tabs, selectedDefault });
      externalDispatch && externalDispatch(values.curr);
    }
  };

  useEffect(() => {
    if (typeof externalIndex === 'number' && externalIndex !== currentValues.curr) {
      setSeletedIndex(externalIndex);
    }
  }, [tabs.length, externalIndex]);

  const handleSelect = (value: IGenericTabsLabelType, index: number) => {
    // lock internal navigation but execute external handler for custom logic
    if (!isNavigationLocked) {
      setSeletedIndex(index);
      externalDispatch?.(index);
    }
    onSelect(value);
  };

  return (
    <div className={styles.genericTabContainer}>
      {tabs.map((m, i) => (
        <GenericTab
          dataCy="generic-tab"
          key={nanoid()}
          index={i}
          tab={m}
          values={currentValues}
          onSelect={() => handleSelect(m, i)}
          bigger={bigger}
          needSeparation={Boolean(canAddTab && tabs.length === i + 1)}
          onDelete={onDelete ? () => onDelete?.(m) : undefined}
          onUpdate={onUpdate ? () => onUpdate?.(m) : undefined}
        />
      ))}
      {canAddTab && <AddTab onAddTab={onAddTab} />}
    </div>
  );
}

export default memo(GenericTabContainer);
