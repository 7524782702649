import { Navigate, Outlet, useLocation } from 'react-router';
import { getAuthState } from '../../features/auth/authSlice';
import { useAppSelector } from '../store';

const AuthenticatedRoute = (props: any) => {
  const location = useLocation();
  const { isLogged } = useAppSelector(getAuthState);

  if (!isLogged === undefined) return null;

  return isLogged ? (
    <Outlet />
  ) : (
    <Navigate
      to={'/'}
      replace
      state={{
        from: location,
      }}
    />
  );
};

export default AuthenticatedRoute;
