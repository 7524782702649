import { LOGO_DISPLAY_WIDTH } from '../../../shared/constants';
import { convertImageToBase64 } from '../../../shared/utils/imageTools';

interface IFetchCompanyLogoFromS3Props {
  url: string | null;
  height?: number;
}

async function fetchCompanyLogoFromS3({
  url,
  height = LOGO_DISPLAY_WIDTH,
}: IFetchCompanyLogoFromS3Props) {
  const promise = new Promise<LoadedImageType | null>((resolve, reject) => {
    try {
      if (!url) return resolve(null);

      let specifiedHeight = height;
      let calculatedWidth = 0;

      convertImageToBase64(url as string).then(
        (img) => {
          if (img) {
            const image = new Image();
            image.src = img;

            image.onload = () => {
              if (specifiedHeight) {
                const aspectRatio = image.naturalWidth / image.naturalHeight;
                calculatedWidth = specifiedHeight * aspectRatio;
              } else {
                specifiedHeight = image.height;
                calculatedWidth = image.width;
              }

              return resolve({ image: img, height, width: calculatedWidth });
            };
          } else {
            throw new Error('No image found');
          }
        },
        (err: any) => {
          throw new Error(err.message);
        }
      );
    } catch (error) {
      return reject(error);
    }
  });

  return promise;
}

export default fetchCompanyLogoFromS3;
