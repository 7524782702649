import { memo, useEffect, useState } from 'react';
import { useAppSelector } from '../../../App/store';
import { getLoadersState } from '../loaderSlice';
import styles from './progressBar.module.scss';

interface IProgressBarCustomProps {
  totalStep: number;
  height: number;
  widthPercent: number;
  onTest?: boolean;
}

function ProgressBarCustom({
  height,
  widthPercent,
  totalStep,
  onTest,
}: IProgressBarCustomProps) {
  const { currentStep } = useAppSelector(getLoadersState);
  const [count, setCount] = useState(0);
  const stepValue = 100 / totalStep;
  const currentwidth = stepValue * (onTest ? count : currentStep.step);

  useEffect(() => {
    setCount(currentStep.step);
  }, [currentStep.step]);

  return (
    <div className={styles.progressBar}>
      <div
        className={styles.progressBarContainer}
        style={{ width: `${widthPercent}%` }}
      >
        {currentStep.legend && (
          <p className={styles.currentStepLabel}>{currentStep.legend}</p>
        )}

        <div className={styles.progressBarContent} style={{ height: `${height}px` }}>
          <div
            className={styles.stepInProgress}
            style={{ width: `${currentwidth}%` }}
          />
          <div
            className={styles.loadingAnimationContainer}
            style={{ width: `${stepValue}%` }}
          >
            <div className={styles.loadingAnimation}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(ProgressBarCustom);
