import React, { RefObject, useEffect, useState } from 'react';
import styles from './urbaGpt.module.scss';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

interface ScrollButtonProps {
  messagesEndRef: RefObject<HTMLDivElement>;
}

function ScrollButton({ messagesEndRef }: ScrollButtonProps) {
  const [isVisible, setIsVisible] = useState(true);

  const handleClick = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsVisible(entry.isIntersecting);
      });
    });

    if (messagesEndRef.current) {
      observer.observe(messagesEndRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [messagesEndRef]);

  return (
    <button
      onClick={handleClick}
      className={`${styles.scrollButton} ${isVisible ? styles.hide : ''}`}
    >
      <ArrowDownwardIcon />
    </button>
  );
}

export default ScrollButton;
