import suitCase from '../../assets/images/brief.svg';
import doorClosedSolid from '../../assets/images/door-closed-solid.svg';
import mail from '../../assets/images/mail.svg';
import mapLocationDotSolid from '../../assets/images/map-location-dot-solid.svg';
import memoCircleCheck from '../../assets/images/memo-circle-check.svg';
import memoCircleInfo from '../../assets/images/memo-circle-info.svg';
import octagonMinus from '../../assets/images/octagon-minus.svg';
import penToSquareSolid from '../../assets/images/pen-to-square-solid.svg';
import peopleGroupSolid from '../../assets/images/people-group-solid.svg';
import personWalkingArrowLoopLeftSolid from '../../assets/images/person-walking-arrow-loop-left-solid.svg';
import Phone from '../../assets/images/phone.svg';
import pinLogo from '../../assets/images/pin.svg';
import euro from '../../assets/images/pound.svg';
import clock from '../../assets/images/timer.svg';
import { getStudyParamsState } from '../../features/study/slices/studyParamsSlice';
import { getAppState } from '../appSlice';
import { useAppSelector } from '../store';

export default function useProcedureTypes() {
  const { procedureTypes } = useAppSelector(getAppState);
  const { type } = useAppSelector(getStudyParamsState);

  const getIcon = (idIri?: string): string => {
    const procedureType = procedureTypes.result?.find((pt) => pt.idIri === idIri);

    switch (procedureType?.icon) {
      case 'mail':
        return mail;
      case 'phone':
        return Phone;
      case 'visit':
        return pinLogo;
      case 'rdv':
        return clock;
      case 'offer':
        return euro;
      case 'sign':
        return suitCase;
      case 'pen':
        return penToSquareSolid;
      case 'octagon-minus':
        return octagonMinus;
      case 'memo-check':
        return memoCircleCheck;
      case 'memo-info':
        return memoCircleInfo;
      case 'person-walking-arrow-loop-left':
        return personWalkingArrowLoopLeftSolid;
      case 'door-closed':
        return doorClosedSolid;
      case 'map-location-dot':
        return mapLocationDotSolid;
      case 'people-group':
        return peopleGroupSolid;
      default:
        return mail;
    }
  };

  const proceduresTypesForSelect = () => {
    if (!procedureTypes.result) {
      return [];
    }

    if (type === 'sector') {
      return procedureTypes.result
        ?.filter((elt) => elt.isSectorType === true)
        .map((pt) => ({ value: pt.idIri, display: pt.label }));
    } else {
      return procedureTypes.result
        ?.filter((elt) => elt.isSectorType === false)
        .map((pt) => ({ value: pt.idIri, display: pt.label }));
    }
  };

  const findProcedureType = (idIri: string) => {
    return procedureTypes.result?.find((elt) => elt.idIri === idIri);
  };

  return {
    procedureTypes: procedureTypes.result,
    getIcon,
    proceduresTypesForSelect,
    findProcedureType,
  };
}
