import { memo } from 'react';
import { useAppSelector } from '../../App/store';
import { getMapState } from '../../features/map/mapSlice';
import MarkerBase from './Common/MarkerBase';

const TownHallMarker = memo(
  (): JSX.Element => {
    const { townHall } = useAppSelector(getMapState);

    return (
      <>
        {townHall.displayed && townHall.latLng && (
          <MarkerBase markerLatLng={townHall.latLng} markerUrl="vert" />
        )}
      </>
    );
  }
);

TownHallMarker.displayName = 'TownHallMarker';
export default TownHallMarker;
