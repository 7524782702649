import { useLocation, useNavigate } from 'react-router';
import useRightPanel from '../../../App/hooks/useRightPanel';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import Logo from '../../../assets/images/logo_urbanease_horizontal.svg';
import { ReactComponent as RightArrowSmall } from '../../../assets/images/right-arrow-small.svg';
import { externalPdfErrialActions } from '../../../features/externalPdf/externalPdfSlice';
import use3DMapCenter from '../../../features/map/hooks/use3DMapContainer';
import { mapActions } from '../../../features/map/mapSlice';
import { getPanelState } from '../../../features/panels/panelsSlice';
import styles from './navigationBanner.module.scss';

const NavigationBanner = (): JSX.Element => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { rightPanelDisplay } = useAppSelector(getPanelState);
  const { center } = use3DMapCenter();
  const { closeRightPanel } = useRightPanel();
  const dispatch = useAppDispatch();

  const handleHomeClick = () => {
    dispatch(mapActions.setClicked3dPoint(null));
    dispatch(externalPdfErrialActions.reset());
    if (rightPanelDisplay) closeRightPanel();
    navigate('/');
  };

  const handleStreetviewClick = () => center && navigate('/street-view');
  const handle3dViewClick = () => center && navigate('/3d-view');

  return (
    <div className={styles.header}>
      <img className={styles.logo} src={Logo} alt="logo" onClick={handleHomeClick} />

      <div className={styles.btnContainer}>
        {['/3d-view', '/street-view'].includes(pathname) && (
          <>
            <button
              onClick={handleStreetviewClick}
              className={[styles.viewBtn].join(' ')}
              disabled={pathname === '/street-view'}
            >
              Vue Street View
            </button>

            <button
              onClick={handle3dViewClick}
              className={[styles.viewBtn].join(' ')}
              disabled={pathname === '/3d-view'}
            >
              Vue map 3D
            </button>
          </>
        )}
        <button onClick={handleHomeClick}>
          Retour sur Urbanease <RightArrowSmall />
        </button>
      </div>
    </div>
  );
};

export default NavigationBanner;
