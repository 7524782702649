import { snakeToPascal } from '../../utils/stringFunctions';
import styles from './colorWrapper.module.scss';
import { wrapingColorValues } from './constants';

interface IUseColorWrapperProps {
  className?: string;
  colorWrapperType: ColorWrapperType;
  color?: ColorType;
  bgColor?: ColorType;
}

function useColorWrapper({
  className,
  colorWrapperType,
  color,
  bgColor,
}: IUseColorWrapperProps) {
  const classNames = [
    className,
    styles.colorWrapper,
    colorWrapperType === 'button' ? styles.btn : '',
    colorWrapperType === 'tooltip' ? styles.tooltip : '',
    colorWrapperType === 'icon' ? styles.icon : '',
  ];

  wrapingColorValues.forEach((e) => {
    const col = snakeToPascal(e);

    if (color === e) classNames.push(styles['c' + col]);
    if (bgColor === e) classNames.push(styles['bg' + col]);
  });

  return classNames.join(' ');
}

export default useColorWrapper;
