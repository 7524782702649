import { nanoid } from '@reduxjs/toolkit';
import { useMailshotResumeContext } from '../../../contexts/mailshotResumeContext';
import useMailshotsSelection from '../../../hooks/useMailshotsSelection';
import styles from '../../../styles/mailshotsModal.module.scss';
import { MailshotsSelectionTypeEnum } from '../../../types/mailshotsEnums';
import MailshotContactRow from './MailshotContactRow';

function MailshotContactTable() {
  const { openedPlot } = useMailshotResumeContext();
  const { folderContacts, selectType } = useMailshotsSelection();

  const contacts =
    selectType === MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER
      ? folderContacts ?? null
      : openedPlot?.contacts ?? null;

  return (
    <div>
      <h4 className={styles.contactTableTitle}>{openedPlot?.plotId}</h4>
      <table className={styles.contactTable}>
        <thead>
          <tr>
            <th colSpan={2} />
            <th>Nom</th>
            <th>Prenom</th>
            <th>Rôle</th>
            <th>Valide</th>
            <th colSpan={2} />
          </tr>
        </thead>
        <tbody>
          {contacts?.list.map((m, i) => (
            <MailshotContactRow
              key={nanoid()}
              studyContact={m}
              isSubfolder={
                selectType ===
                MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER
              }
              isDuplicate={contacts.duplicateContacts.includes(m.contact.idIri)}
              index={i}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MailshotContactTable;
