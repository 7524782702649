import { format, subMonths } from 'date-fns';

export function groupBy<T>(objectArray: T[], property: keyof T) {
  const dateArr: string[] = [];
  for (let index = 0; index < 12; index++) {
    dateArr.push(format(subMonths(new Date(), index), 'yyyy-MM'));
  }
  const response = objectArray.reduce((acc, obj) => {
    const d: unknown = obj[property];
    const key = format(new Date(d as string), 'yyyy-MM');

    if (!acc[key]) {
      acc[key] = 0;
    }
    acc[key]++;
    return acc;
  }, {} as { [x: string]: number });

  const res = dateArr.map((elt) => {
    return response[elt] ?? 0;
  });

  return res;
}

export function statusGroupBy(objectArray: PlotStudies) {
  return objectArray.reduce((acc, obj) => {
    const key = obj.status?.idIri ?? 'indefini';

    if (!acc[key]) {
      acc[key] = { count: 0, status: obj.status ?? null };
    }
    // Add object to list for given key's value
    acc[key].count++;
    return acc;
  }, {} as { [x: string]: { count: number; status: StudyStatus | null } });
}

export const fillColors = [
  '#4fd211',
  '#d29c11',
  '#d20000',
  '#455065',
  '#4e55a2',
  '#1564db',
  '#11b2f7',
  '#e2da12',
  '#944bf2',
];
