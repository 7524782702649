import Point from '@mapbox/point-geometry';
// @ts-ignore
import polylabel from 'polylabel';
import { FontAttr, NumberAttr, StringAttr, TextAttr } from './attribute';
import { linebreak } from './text';
// https://bugs.webkit.org/show_bug.cgi?id=230751
export var Justify;
(function (Justify) {
  Justify[(Justify['Left'] = 1)] = 'Left';
  Justify[(Justify['Center'] = 2)] = 'Center';
  Justify[(Justify['Right'] = 3)] = 'Right';
})(Justify || (Justify = {}));

export class TextSymbolizer {
  constructor(options) {
    this.font = new FontAttr(options);
    this.text = new TextAttr(options);
    this.fill = new StringAttr(options.fill, 'black');
    this.stroke = new StringAttr(options.stroke, 'black');
    this.width = new NumberAttr(options.width, 0);
    this.lineHeight = new NumberAttr(options.lineHeight, 1);
    this.letterSpacing = new NumberAttr(options.letterSpacing, 0);
    this.maxLineCodeUnits = new NumberAttr(options.maxLineChars, 15);
    this.justify = options.justify;
  }
  place(layout, geom, feature) {
    let property = this.text.get(layout.zoom, feature);
    if (!property) return undefined;
    let font = this.font.get(layout.zoom, feature);
    layout.scratch.font = font;
    let letterSpacing = this.letterSpacing.get(layout.zoom, feature);
    // line breaking
    let lines = linebreak(property, this.maxLineCodeUnits.get(layout.zoom, feature));
    var longestLine = '';
    var longestLineLen = 0;
    for (let line of lines) {
      if (line.length > longestLineLen) {
        longestLineLen = line.length;
        longestLine = line;
      }
    }
    let metrics = layout.scratch.measureText(longestLine);
    let width = metrics.width + letterSpacing * (longestLineLen - 1);
    let ascent = metrics.actualBoundingBoxAscent;
    let descent = metrics.actualBoundingBoxDescent;
    let lineHeight = (ascent + descent) * this.lineHeight.get(layout.zoom, feature);
    let a = new Point(geom[0][0].x, geom[0][0].y);
    let bbox = {
      minX: a.x,
      minY: a.y - ascent,
      maxX: a.x + width,
      maxY: a.y + descent + (lines.length - 1) * lineHeight,
    };
    // inside draw, the origin is the anchor
    // and the anchor is the typographic baseline of the first line
    let draw = (ctx, extra) => {
      ctx.globalAlpha = 1;
      ctx.font = font;
      ctx.fillStyle = this.fill.get(layout.zoom, feature);
      let textStrokeWidth = this.width.get(layout.zoom, feature);
      var y = 0;
      for (let line of lines) {
        var startX = 0;
        if (
          this.justify == Justify.Center ||
          (extra && extra.justify == Justify.Center)
        ) {
          startX = (width - ctx.measureText(line).width) / 2;
        } else if (
          this.justify == Justify.Right ||
          (extra && extra.justify == Justify.Right)
        ) {
          startX = width - ctx.measureText(line).width;
        }
        if (textStrokeWidth) {
          ctx.lineWidth = textStrokeWidth * 2; // centered stroke
          ctx.strokeStyle = this.stroke.get(layout.zoom, feature);
          if (letterSpacing > 0) {
            let xPos = startX;
            for (var letter of line) {
              ctx.strokeText(letter, xPos, y);
              xPos += ctx.measureText(letter).width + letterSpacing;
            }
          } else {
            ctx.strokeText(line, startX, y);
          }
        }
        if (letterSpacing > 0) {
          let xPos = startX;
          for (let letter of line) {
            ctx.fillText(letter, xPos, y);
            xPos += ctx.measureText(letter).width + letterSpacing;
          }
        } else {
          ctx.fillText(line, startX, y);
        }
        y += lineHeight;
      }
    };
    return [{ anchor: a, bboxes: [bbox], draw: draw }];
  }
}

export class PolygonLabelSymbolizer {
  constructor(options) {
    this.symbolizer = new TextSymbolizer(options);
  }
  place(layout, geom, feature) {
    let placed = this.symbolizer.place(layout, [[new Point(0, 0)]], feature);
    if (!placed || placed.length == 0) return undefined;
    let first_label = placed[0];
    let fb = first_label.bboxes[0];
    const geomParsed = geom.map((array) => array.map((point) => [point.x, point.y]));
    let found = polylabel(geomParsed);
    let a = new Point(found[0], found[1]);
    let bbox = {
      minX: a.x - (fb.maxX - fb.minX) / 2,
      minY: a.y - (fb.maxY - fb.minY) / 2,
      maxX: a.x + (fb.maxX - fb.minX) / 2,
      maxY: a.y + (fb.maxY - fb.minY) / 2,
    };
    let draw = (ctx) => {
      ctx.translate(
        first_label.anchor.x - (fb.maxX - fb.minX) / 2,
        first_label.anchor.y
      );
      first_label.draw(ctx);
    };
    return [{ anchor: a, bboxes: [bbox], draw: draw }];
  }
}
