import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchSocialCriteria from '../fetchSocialCriteria';

const fetchSocialCriteriaThunk = createAsyncThunk(
  'activePlot/fetchSocialCriteriaThunk',
  async (params: { inseeCode: string }) => {
    try {
      const result = await fetchSocialCriteria({ inseeCode: params.inseeCode });

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchSocialCriteriaThunk;
