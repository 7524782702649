import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchParkingPackage from '../fetchParkingPackage';

const fetchParkingPackageThunk = createAsyncThunk(
  'activePlot/fetchParkingPackageThunk',
  async (params: { inseeCode: string }) => {
    try {
      const result = await fetchParkingPackage({ inseeCode: params.inseeCode });

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchParkingPackageThunk;
