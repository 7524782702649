import { Page, Text, View } from '@react-pdf/renderer';
import AtlasAziList from '../../errialPDF/pdfFiles/components/AtlasAziList';
import AtlasList from '../../errialPDF/pdfFiles/components/AtlasList';
import ContaminatedAreas from '../../shared/components/pdf/ContaminatedAreas';

import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import GenericRisksTab from '../../errialPDF/pdfFiles/components/GenericRisksTab';
import EnvironmentalConstraintsLand from './components/EnvironmentalConstraintsLand';
import Footer from './components/Footer';
import Header from './components/Header';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  date: string;
  agency: PdfLandOrpiAgency | null;
  seismic: number;
  clay: number;
  radon: number;
  coastalErosion: boolean;
  contaminatedAreas: ContaminatedAreas;
  environmentRisksDetails: CityEnvironmentDatas;
  catNats: Catnat[];
  formDatas: IExternalPdfLandForm;
}
const GeoriskExpositionPage = ({
  agency,
  seismic,
  clay,
  radon,
  coastalErosion,
  contaminatedAreas,
  environmentRisksDetails,
  catNats,
  formDatas,
}: IProps) => {
  return (
    <>
      <Page size="A4" style={pageStyles.page}>
        <Header lines={['Votre terrain', 'Exposition au géorisques']} fixed />

        <Text
          style={[
            fontStyles.b700,
            fontStyles.fs20,
            fontStyles.cOrpi,
            { marginVertical: '10px' },
          ]}
        >
          L&#039;urbanisme de votre terrain
        </Text>
        <EnvironmentalConstraintsLand
          seismic={seismic}
          clay={clay}
          radon={radon}
          coastalErosion={coastalErosion}
        />

        <View>
          <Text style={[fontStyles.fs16, fontStyles.cOrpi]} break>
            &gt; Liste des Atlas et Documents de gestion des risques existants sur la
            commune :
          </Text>
          <AtlasList
            title="• Plan de Prévention des Risques Naturels (PPRN)"
            datas={environmentRisksDetails.pprn}
            borderColor="#000"
            isFirst
          />
          <AtlasList
            title="• Plan de prévention des risques environnementaux et technologiques ( PPRT)"
            datas={environmentRisksDetails.pprt}
            borderColor="#000"
            wrap
          />
          <AtlasList
            title="• Plan de prévention des risques Miniers (PPRM)"
            datas={environmentRisksDetails.pprm}
            borderColor="#000"
            wrap
          />
          <AtlasAziList
            title="• Atlas des zones inondables (AZI)"
            datas={environmentRisksDetails.azi}
            borderColor="#000"
            wrap
          />
        </View>

        <View wrap={false}>
          <Text style={[fontStyles.fs16, fontStyles.cOrpi]}>
            &gt; Sites et sols pollués – dans un périmètre de 500 m par rapport au
            bien étudiés
          </Text>
          <ContaminatedAreas areas={contaminatedAreas} />
        </View>

        <Footer agency={agency} formDatas={formDatas} />
      </Page>
      <Page size="A4" style={pageStyles.page}>
        <Header lines={['Votre terrain', 'Exposition au géorisques']} fixed />
        <Text style={[fontStyles.fs16, fontStyles.cOrpi]}>
          ANNEXE – Liste des arrêtés de Catégories Naturelles sur la Commune
        </Text>

        {isEmpty(catNats) ? (
          <Text>Aucune donnée recensée</Text>
        ) : (
          catNats.map((elt) => (
            <View key={nanoid()} wrap={false}>
              <Text>{`• Risque : ${elt.libRisqueJo}`}</Text>

              <GenericRisksTab risks={elt.catnatDatas} isLand />
            </View>
          ))
        )}

        <Footer agency={agency} formDatas={formDatas} />
      </Page>
    </>
  );
};

export default GeoriskExpositionPage;
