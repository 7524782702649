import { AxiosInstance } from 'axios';
import store from '../../App/store';
import { logoutThunk } from '../../features/auth/authThunks';
import { modalsActions } from '../../features/modals/modalsSlice';
import { authErrorMessages } from '../../shared/errorMessages';
import { onFullfilledRequest, timeoutError } from './axiosUtils';
import { refreshTokenProcess } from './methods';

export const onRejectedProtectedResponse = async (error: any) => {
  if (error.code === 'ECONNABORTED') {
    return Promise.reject(timeoutError(error));
  } else {
    const status = error?.response?.status;
    const message =
      error?.response?.data.message ??
      error?.response?.data.detail ??
      error?.response.data['hydra:description'] ??
      'aucun message';

    switch (status) {
      case 401:
        if (
          [
            authErrorMessages.expiredJwtToken.errMessage,
            authErrorMessages.invalidJwtToken.errMessage,
          ].includes(message)
        ) {
          try {
            const refresh = await refreshTokenProcess(error);
            return refresh;
          } catch (e: any) {
            switch (e.status) {
              case 401:
                switch (e.message) {
                  // if refresh_token error => logout() needed
                  case authErrorMessages.expiredSubscription.errMessage:
                    store.dispatch(logoutThunk());
                    store.dispatch(modalsActions.expiredSubscriptionModalOpen());
                    return Promise.reject({ status, message });
                  // if refresh_token is revoked or expired
                  case authErrorMessages.invalidJwtRefreshToken.errMessage:
                    store.dispatch(modalsActions.authErrorModalOpen());
                    return Promise.reject({ status, message });
                  default:
                    // others case, send first request error
                    return Promise.reject({
                      status: status ?? 500,
                      message: message ?? 'Inconnu',
                    });
                }
              case 403:
                // if subscription is expired when token is valid
                if (message === authErrorMessages.expiredSubscription.errMessage) {
                  store.dispatch(logoutThunk());
                  // this action close connection process modal and all login modals
                  store.dispatch(modalsActions.expiredSubscriptionModalOpen());
                }
                return Promise.reject({
                  status: status,
                  message: message ?? 'Inconnu',
                });

              default:
                return Promise.reject({
                  status: status ?? 500,
                  message: message ?? 'Inconnu',
                });
            }
          }
        } else {
          return Promise.reject({ status, message: 'Unauthorized access' });
        }
      case 403:
        // if subscription is expired when token is valid
        if (message === authErrorMessages.expiredSubscription.errMessage) {
          store.dispatch(logoutThunk());
          // this action close connection process modal and all login modals
          store.dispatch(modalsActions.expiredSubscriptionModalOpen());
        } else {
          return Promise.reject({ status, message: 'Forbidden acces' });
        }
        break;
      default:
        return Promise.reject({
          status: status ?? 500,
          message: message ?? 'Inconnu',
        });
    }
  }
};

export const setProtectedResponseInterceptor = (request: AxiosInstance) => {
  request.interceptors.response.use(
    onFullfilledRequest,
    onRejectedProtectedResponse
  );
};
