import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { memo, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../App/store';
import { contactsActions } from '../../../features/contacts/contactsSlice';
import ContactContainer from '../../../features/contacts/dashboardDirectory/ContactContainer';
import { contactHeaderCell } from '../../../features/contacts/dashboardDirectory/datas';
import useContacts from '../../../features/contacts/useContacts';
import FilterSortBloc from '../../filterSortBloc';
import './DirectoryTab.scss';

function DirectoryTab(): JSX.Element {
  const { contacts } = useContacts();
  const [searchPattern, setSearchPattern] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>('id');
  const [order, setOrder] = useState<Order>('asc');

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(contactsActions.contactForActionReset());
    };
  }, []);

  return (
    <div className="tab-directory">
      <h1 className="tab-inner">Annuaire</h1>
      <FilterSortBloc
        setSearchPattern={setSearchPattern}
        setSortKey={setSortKey}
        setOrder={setOrder}
        selectItems={[{ value: 'lastName', display: 'Nom' }]}
        selectPlaceHolder="Trier contacts par"
      />

      <div className="tab-body">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {contactHeaderCell?.map((elt) => (
                  <TableCell key={nanoid()}>{elt.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <ContactContainer
              contacts={contacts ? [...contacts] : []}
              order={order}
              sortKey={sortKey}
              pattern={searchPattern}
            />
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default memo(DirectoryTab);
