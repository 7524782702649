import FormButtonContainer from '../../../../../shared/components/buttons/formButtonContainer/FormButtonContainer';
import {
  MAILSHOT_MAX_CONTACT_EDITION_COUNT,
  MAILSHOT_MAX_PLOT_EDITION_COUNT,
} from '../../../../../shared/constants';
import { useMailshotResumeContext } from '../../../contexts/mailshotResumeContext';
import useMailshotsSelection from '../../../hooks/useMailshotsSelection';
import styles from '../../../styles/mailshotsModal.module.scss';
import { MailshotsSelectionTypeEnum } from '../../../types/mailshotsEnums';
import MailshotFolderContactList from './MailshotFolderContactList';
import MailshotModelSelect from './MailshotModelSelect';
import MailshotPlotList from './MailshotPlotList';

interface IMailshotResumeContentProps {
  onValid: () => void;
}

function MailshotResumeContent({ onValid }: IMailshotResumeContentProps) {
  const { invalidAddressCount } = useMailshotResumeContext();
  const {
    selectType,
    selectStaged,
    selectionValidation,
    outofRangePlotEdit,
    outofRangeContactEdit,
    selectedModel,
  } = useMailshotsSelection();

  const isNotSubfolder =
    selectType !== MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER;
  const disabledValid =
    outofRangeContactEdit ||
    (outofRangePlotEdit && isNotSubfolder) ||
    !selectedModel;

  return (
    <div className={styles.mailshotSelectionResumeModal}>
      <h3>Récapitulatif de la sélection</h3>
      <div className={styles.mailshotSelectionResumeContent}>
        <MailshotModelSelect />
        {selectType ===
          MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER && (
          <MailshotFolderContactList />
        )}
        <MailshotPlotList />
      </div>

      <FormButtonContainer
        validContent="Lancer l'édition"
        cancelContent="Retourner à la sélection"
        canCancel
        onCancel={() => selectionValidation(!selectStaged)}
        onValid={onValid}
        disableValid={disabledValid}
        disabledValidTooltipText={
          disabledValid && (
            <>
              {outofRangePlotEdit && isNotSubfolder && (
                <p>
                  Le nombre de parcelles est superieur à{' '}
                  {MAILSHOT_MAX_PLOT_EDITION_COUNT}
                </p>
              )}
              {outofRangeContactEdit && (
                <p>
                  Le nombre de contacts est superieur à{' '}
                  {MAILSHOT_MAX_CONTACT_EDITION_COUNT}
                </p>
              )}
              {!selectedModel && (
                <p>Aucun modèle est sélectionné pour l&lsquo;édition</p>
              )}
            </>
          )
        }
      />
    </div>
  );
}

export default MailshotResumeContent;
