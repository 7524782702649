import { useAppSelector } from '../../../../../../App/store';
import { getAuthState } from '../../../../../../features/auth/authSlice';
import { getMapState } from '../../../../../../features/map/mapSlice';
import { getPlotState } from '../../../../../../redux/plot/reducer';
import { emailContent } from '../../../constant';
import emailIcon from './../../../../../../assets/images/email_icon.png';

interface IOwnerLinksProps {
  ownersPlot?: IOwnersPlots;
}
const OwnerLinks = ({ ownersPlot }: IOwnerLinksProps) => {
  const { geolocDatas } = useAppSelector(getMapState);
  const { parcelle } = useAppSelector(getPlotState);
  const { user } = useAppSelector(getAuthState);

  const impotEmails = ownersPlot?.impotsMails ?? parcelle?.impotEmails;
  const plotId = ownersPlot?.plotId ?? parcelle?.parcelleId;
  const cityName = ownersPlot?.city ?? geolocDatas?.city;
  const addressValue = ownersPlot?.address ?? geolocDatas?.address;

  return (
    <>
      <div className="informations-links">
        <div className="email">
          <img src={emailIcon} alt="mail" />
          <a
            href={`mailto:${impotEmails?.join(',')}${emailContent(
              plotId || 'indéfini',
              cityName || 'indéfini',
              addressValue || 'indéfini',
              user?.firstName,
              user?.lastName
            )}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Demander coordonnées des propriétaires
          </a>
          &nbsp;(&nbsp;
          <a
            className="cerfa"
            href="https://urbanease.s3.eu-west-3.amazonaws.com/CERFA+demande+matrice+cadastrale.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            téléchargement Cerfa
          </a>
          &nbsp;si nécessaire )
        </div>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://www.pagesjaunes.fr/annuaire/chercherlespros?ou=${encodeURIComponent(
            addressValue || ''
          )}&univers=pagesjaunes`}
        >
          Coordonnées occupants professionnels
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={`https://www.pagesjaunes.fr/pagesblanches/recherche?ou=${encodeURIComponent(
            addressValue || ''
          )}&univers=pagesblanches`}
        >
          Coordonnées occupants personnes physiques
        </a>
      </div>
    </>
  );
};

export default OwnerLinks;
