import { TableCell, TableSortLabel } from '@mui/material';
import React, { MouseEvent } from 'react';

interface ITableCellHeaderProps {
  orderBy: string;
  order: Order;
  onRequestSort: (
    event: MouseEvent<unknown>,
    column: string,
    orderBy: string
  ) => void;
  title?: string;
  rowSpan?: number;
  sortName?: string;
}

function TableCellHeader({
  orderBy,
  order,
  onRequestSort,
  title,
  rowSpan,
  sortName,
}: ITableCellHeaderProps): JSX.Element {
  const createSortHandler =
    (column: string, orderBy: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, column, orderBy);
    };

  return (
    <TableCell rowSpan={rowSpan}>
      {sortName ? (
        <TableSortLabel
          active={orderBy === sortName}
          direction={orderBy === sortName ? order : 'asc'}
          onClick={createSortHandler(sortName, orderBy)}
        >
          {title}
        </TableSortLabel>
      ) : (
        `${title}`
      )}
    </TableCell>
  );
}

export default TableCellHeader;
