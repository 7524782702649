import { isEmpty } from 'lodash';
import { dateFormat } from '../../../../lib/formats/dataFormat';
import { changeApiKeyForStoreKey } from '../../../../services/common/parserCommon';
import { imageDataParse } from '../../../../utils/jsFunctions';
import {
  findUserDefinedFieldsBooleanValue,
  findUserDefinedFieldsNumberValue,
  findUserDefinedFieldsValue,
} from './utils';

export const catnatMapping = {
  cod_commune: 'city',
  lib_commune: 'postalCode',
  lib_risque_jo: 'libRisqueJo',
  catnat_datas: 'catnatDatas',
};
export const catnatDatasMapping = {
  code_nat_catnat: 'catnat',
  start: 'start',
  fin: 'end',
  stop: 'stop',
  jo: 'jo',
};

const environmentRisksDetailsParser = (
  data: any[],
  codeType: string
): EnvironmentDataTypes => {
  if (data && !isEmpty(data)) {
    const parsed = data.map((m: any) => {
      return {
        code: m['cod_nat_' + codeType] ?? 'Inconnu',
        datePrescription: m.dat_prescription
          ? dateFormat(m.dat_prescription)
          : 'Inconnu',
        dateApprobation: m.dat_approbation
          ? dateFormat(m.dat_approbation)
          : 'Inconnu',
        label: m.lib_risque ?? 'Inconnu',
      } as EnvironmentDataType;
    });
    return parsed;
  } else {
    return [];
  }
};
export const catnatApiToStoreParser = (data: any) => {
  try {
    const parsedData = changeApiKeyForStoreKey<any>(data, catnatMapping);

    parsedData.catnatDatas = parsedData.catnatDatas.map((data: any) =>
      changeApiKeyForStoreKey(data, catnatDatasMapping)
    );

    return parsedData;
  } catch (error: any) {
    throw new Error('Erreur parsing catnat - ' + error.message);
  }
};

const findPropertyType = (code: string) => {
  let propertyType = '';
  switch (code) {
    case 'land':
    case 'field':
      propertyType = 'field';
      break;
    case 'house':
      propertyType = 'house';
      break;
    case 'building':
      propertyType = 'building';
      break;
    case 'apartment':
      propertyType = 'apartment';
      break;
    default:
      propertyType = 'others';
      break;
  }

  return propertyType;
};
export const sweepbrightDataApiToStoreParser = (data: any): ISweepbrightData => {
  try {
    const { description, property, userDefinedFields, dealType, askingSalePrice } =
      data;
    const { propertyType, condition, landArea } = property;
    const { city, postCode, street, houseNumber } = property.location.address;
    const ownersValue = findUserDefinedFieldsValue(
      userDefinedFields,
      'Propriétaires'
    );
    const parsedOwners = ownersValue?.value
      ? ownersValue?.value.split(',').map((m) => ({ name: m.trim() }))
      : [];

    const parsedData: ISweepbrightData = {
      description: description,
      dealType,
      askingSalePrice,
      property: {
        location: {
          address: { postalCode: postCode, city, streetName: street, houseNumber },
        },
        propertyType: findPropertyType(propertyType.code),
        buildingYear: property.buildingYear,
        livingArea: property.livingArea,
        landArea: landArea ?? 0,
        numberOfRooms: property.numberOfRooms,
        numberOfBathrooms: property.numberOfBathrooms,
        floorNumbers: property.numberOfFloorsInBuilding ?? 0,
        condition: {
          bathrooms: condition.bathrooms,
          kitchen: condition.kitchen,
          flooring: condition.flooring,
          windows: condition.windows,
        },
        numberOfIndoorParkingSpaces: property.numberOfIndoorParkingSpaces,
        numberOfOutdoorParkingSpaces: property.numberOfOutdoorParkingSpaces,
        hasLift: property.hasLift,
        hasSauna: property.hasSauna,
        isFurnished: property.isFurnished,
        isNew: property.isNew,
        hasPool: property.hasPool,
      },
      userDefinedFields: {
        guardian: findUserDefinedFieldsBooleanValue(userDefinedFields, 'Gardien'),
        alarm: findUserDefinedFieldsBooleanValue(userDefinedFields, 'Alarme'),
        propertyTax: findUserDefinedFieldsNumberValue(
          userDefinedFields,
          'Taxe foncière'
        ),
        owners: parsedOwners,
        coproNumberLots: findUserDefinedFieldsNumberValue(
          userDefinedFields,
          'Nombre de lot copro'
        ),
        annualCharges: findUserDefinedFieldsNumberValue(
          userDefinedFields,
          'Charges annuelles'
        ),
        heating:
          findUserDefinedFieldsValue(userDefinedFields, 'Chauffage')?.value ??
          'Non communiqué',
        energy:
          findUserDefinedFieldsValue(userDefinedFields, 'Energie')?.value ??
          'Non communiqué',
        bedrooms: findUserDefinedFieldsNumberValue(userDefinedFields, 'Chambres'),
        currentLease: findUserDefinedFieldsBooleanValue(
          userDefinedFields,
          'Bail en cours'
        ),
        rentalValue: findUserDefinedFieldsNumberValue(
          userDefinedFields,
          'Valeur locative'
        ),
        cellar: findUserDefinedFieldsBooleanValue(userDefinedFields, 'Cave'),
        fence: userDefinedFields.find((f: any) => f.label === 'Clôturé')
          ? findUserDefinedFieldsBooleanValue(userDefinedFields, 'Clôturé')
          : findUserDefinedFieldsBooleanValue(userDefinedFields, 'Cloture'),
        drainage: findUserDefinedFieldsBooleanValue(userDefinedFields, 'Drainage'),

        sewerAccess: findUserDefinedFieldsBooleanValue(
          userDefinedFields,
          'Accès égout'
        ),
        gasAndElectricityAccess: findUserDefinedFieldsBooleanValue(
          userDefinedFields,
          'Accès gaz/électricité'
        ),
        waterAccess: findUserDefinedFieldsBooleanValue(
          userDefinedFields,
          'Accès eau courante'
        ),
        roadAccess: findUserDefinedFieldsBooleanValue(
          userDefinedFields,
          'Accès voirie'
        ),
        actualRent: findUserDefinedFieldsNumberValue(
          userDefinedFields,
          'Loyer actuel'
        ),
      },
    };

    return parsedData;
  } catch (error: any) {
    throw new Error('Erreur parsing external pdf errial - ' + error.message);
  }
};
export const erialDatasForPdfGenerationParser = (
  data: any
): DatasForPdfGeneration => {
  try {
    const {
      customer,
      parcels,
      pictures,
      city_environment_datas,
      parcels_environment_datas,
      parcels_max_buildings_height,
      parcels_predominant_plu_zone,
    } = data;
    const { polluted_sites_around_parcels } = parcels_environment_datas;

    const parsedData: DatasForPdfGeneration = {
      customer: customer,
      parcels: parcels,
      pictures: {
        parcelsOnCadastrePicture: imageDataParse(
          pictures.parcels_on_cadastre_picture
        ),
        parcelsOnZonePicture: imageDataParse(pictures.parcels_on_zone_picture),
        homePagePicture: imageDataParse(pictures.home_page_picture),
      },
      parcelsMaxBuildingsHeight: parcels_max_buildings_height,
      zonePlu: parcels_predominant_plu_zone
        ? `${
            parcels_predominant_plu_zone.zone
              ? `${parcels_predominant_plu_zone.zone}`
              : ''
          }${
            parcels_predominant_plu_zone.zone &&
            parcels_predominant_plu_zone.zone_child
              ? `-${parcels_predominant_plu_zone.zone_child}`
              : ''
          }`
        : '',
      parcelsEnvironmentDatas: {
        seismic: parcels_environment_datas['Données sismiques'].score,
        clay: parcels_environment_datas['Données ARGILES'].score,
        radon: parcels_environment_datas['indice RADON'].score,
        coastalErosion:
          parcels_environment_datas['Trait littoral - Érosion côtière'],
        envConstraints: {
          isMonument: parcels_environment_datas['Secteurs Batiments Historiques'],
          isRemarkableHeritage:
            parcels_environment_datas['Site patrimonial remarquable'],
          isArchaeologicalPrescriptionArea:
            parcels_environment_datas['Prescriptions Archéologiques'],
          isPEBImpact:
            parcels_environment_datas["PEB - Plan d'Exposition au Bruit"].bool,
          PEBValue:
            parcels_environment_datas["PEB - Plan d'Exposition au Bruit"].score,
        },
      },
      contaminatedAreas: polluted_sites_around_parcels.map((m: any) => ({
        count: m.count,
        libelle: m.libelle,
      })),

      cityEnvironmentDatas: {
        pprm: environmentRisksDetailsParser(city_environment_datas.pprm, 'pprm'),
        pprn: environmentRisksDetailsParser(city_environment_datas.pprn, 'pprn'),
        pprt: environmentRisksDetailsParser(city_environment_datas.pprt, 'pprt'),
        azi: city_environment_datas.azi.map((m: any) => {
          return { code: m.cod_nat_azi, label: m.lib_azi };
        }),
        catnat: city_environment_datas.catnat.map((data: any) =>
          catnatApiToStoreParser(data)
        ),
      },
    };

    return parsedData;
  } catch (error: any) {
    throw new Error('Erreur parsing external pdf errial - ' + error.message);
  }
};
