import { fetchDatas } from '../../services/axiosFiles/genericCrud';

interface IPagingTraitmentProps {
  endpoint: string;
  params: QueryParams;
}
export async function pagingTraitment({
  endpoint,
  params,
}: IPagingTraitmentProps): Promise<any[]> {
  const memberTabArray: any[][] = [];
  let isLoop = false;
  let page = 1;

  do {
    params.page = page;
    const response = await fetchDatas(endpoint, params);

    memberTabArray.push(response['hydra:member']);

    if (response['hydra:view'] && response['hydra:view']['hydra:next']) {
      isLoop = true;
      page += 1;
    } else {
      isLoop = false;
    }
  } while (isLoop);

  return memberTabArray.flat(1);
}
