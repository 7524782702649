import ButtonGeneric from '../../../../../shared/components/buttons/buttonGeneric/ButtonGeneric';
import styles from '../../../styles/mailshotAccountTabContent.module.scss';
import { MailshotTagEnum } from '../../../types/mailshotsEnums';
import { ITagBtnClickProps } from './TagButtonContainer';

function UserContent({ onBtnClick }: ITagBtnClickProps) {
  return (
    <div className={styles.btnContainer}>
      <ButtonGeneric
        content="Prénom utilisateur"
        bgColor="PRIMARY"
        onClick={() => onBtnClick(MailshotTagEnum.PRENOM_PROSPECTEUR, 'PRIMARY')}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Nom utilisateur"
        bgColor="PRIMARY"
        onClick={() => onBtnClick(MailshotTagEnum.NOM_PROSPECTEUR, 'PRIMARY')}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Email utilisateur"
        bgColor="PRIMARY"
        onClick={() => onBtnClick(MailshotTagEnum.EMAIL_PROSPECTEUR, 'PRIMARY')}
        containerClassName={styles.button}
      />
    </div>
  );
}

export default UserContent;
