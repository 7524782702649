import { fetchDatasNew } from '../../../services/axiosFiles/genericCrud';
import { contactsApiToStoreParser } from '../contactParser';

interface IfetchContactThunkParams {
  itemsPerPage?: number;
  page?: number;
  search?: string;
}

/**
 * Request users with filters or full
 * @param {FetchCriterias} params page, itemsPerPage and search pattern
 * @param {string|null} companyIdIri - company endpoint
 * @returns {Contact[]} contact list
 */
export const fetchContacts = async ({
  params,
  companyIdIri,
}: {
  params?: FetchCriterias;
  companyIdIri: string | null;
}) => {
  try {
    if (companyIdIri) {
      const result = await fetchDatasNew({
        endpoint: `${companyIdIri}/contacts`,
        config: {
          params,
        },
      });

      const parsedContacts = contactsApiToStoreParser(result['hydra:member']);

      return parsedContacts;
    } else {
      return Promise.reject({ status: 400, message: 'companyIdIri missing' });
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
