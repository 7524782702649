import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppSelector } from '../../../App/store';
import { getCompanyState } from '../../../features/company/companySlice';
import { getPlotState } from '../../../redux/plot/reducer';
import NavigationBanner from '../navigationBanner/NavigationBanner';
import styles from './viewPageContainer.module.scss';
function ViewPageContainer({ children }: IChildren) {
  const { parcelle } = useAppSelector(getPlotState);
  const { companyId } = useAppSelector(getCompanyState);
  const navigate = useNavigate();

  useEffect(() => {
    // if no plot in store or not company 7, return to home
    if (!parcelle) {
      navigate('/');
    }
  }, []);

  return (
    <div className={styles.viewPageContainer}>
      <NavigationBanner />
      {children}
    </div>
  );
}

export default ViewPageContainer;
