import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  fetchOwnerInfosFromSirenThunk,
  fetchOwnerPlotFromSirenThunk,
  fetchOwnersFromInseeCodeThunk,
} from './dashboardOwnerDatasThunk';

export enum OwnerDatasSearchTypeEnum {
  CITY = 'city',
  PERSON = 'person',
}

const initialState: DashboardOwnerDatasState = {
  searchType: OwnerDatasSearchTypeEnum.CITY,
  city: null,
  siren: null,
  ownersFromInseeCode: { apiStatus: APIStatus.IDLE, result: null },
  plotsFromSiren: { apiStatus: APIStatus.IDLE, result: null },
  ownerInfos: { apiStatus: APIStatus.IDLE, result: null },
};

const dashboardOwnerDatasSlice = createSlice({
  name: 'dashboardOwnerDatas',
  initialState,
  reducers: {
    setDatasFromPlotInfo: (state, action: PayloadAction<string>) => {
      state.searchType = OwnerDatasSearchTypeEnum.PERSON;
      state.siren = action.payload;
    },
    setSearchType: (state, action: PayloadAction<OwnerDatasSearchTypeEnum>) => {
      state.searchType = action.payload;
    },
    setCity: (state, action: PayloadAction<AutocompleteCity | null>) => {
      state.city = action.payload;
      state.ownersFromInseeCode = { apiStatus: APIStatus.IDLE, result: null };
    },
    setSiren: (state, action: PayloadAction<string | null>) => {
      state.siren = action.payload;
    },
    onOwnerSirenClick: (
      state,
      action: PayloadAction<{ siren: string; searchType: OwnerDatasSearchTypeEnum }>
    ) => {
      state.siren = action.payload.siren;
      state.searchType = action.payload.searchType;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // LOAD OWNERS FROM INSEE (PAGINATION)
    builder
      .addCase(fetchOwnersFromInseeCodeThunk.pending, (state) => {
        state.ownersFromInseeCode = { apiStatus: APIStatus.PENDING, result: null };
      })
      .addCase(
        fetchOwnersFromInseeCodeThunk.fulfilled,
        (state, action: PayloadAction<OwnersDataFromInseeCode | null>) => {
          state.ownersFromInseeCode = {
            apiStatus: APIStatus.FULFILLED,
            result: action.payload,
          };
        }
      );
    // LOAD OWNER INFORMATIONS FROM SIREN
    builder
      .addCase(fetchOwnerInfosFromSirenThunk.pending, (state) => {
        state.ownerInfos = { apiStatus: APIStatus.PENDING, result: null };
      })
      .addCase(
        fetchOwnerInfosFromSirenThunk.fulfilled,
        (state, action: PayloadAction<OwnerInfos | null>) => {
          state.ownerInfos = {
            apiStatus: APIStatus.FULFILLED,
            result: action.payload,
          };
          state.siren = action.payload?.siren ?? null;
        }
      );
    // LOAD OWNER PLOTS FROM SIREN (PAGINATION)
    builder
      .addCase(fetchOwnerPlotFromSirenThunk.pending, (state) => {
        state.plotsFromSiren = { apiStatus: APIStatus.PENDING, result: null };
      })
      .addCase(
        fetchOwnerPlotFromSirenThunk.fulfilled,
        (state, action: PayloadAction<PlotsFromSiren | null>) => {
          state.plotsFromSiren = {
            apiStatus: APIStatus.IDLE,
            result: action.payload,
          };
          state.searchType = OwnerDatasSearchTypeEnum.PERSON;
        }
      );
  },
});

export default dashboardOwnerDatasSlice.reducer;
export const dashboardOwnerDatasActions = dashboardOwnerDatasSlice.actions;
export const getDashboardOwnerDatasState = (state: RootState) => state.ownerDatas;
