import { LatLngTuple } from 'leaflet';
import { isEmpty } from 'lodash';

const calcEntitiesbounds = (entities: EntitiesDisplay) => {
  if (!entities || isEmpty(entities)) {
    return [];
  }

  let bounds: LatLngTuple[] = [];

  entities.forEach((e) => {
    if (e.polygon) {
      bounds = bounds.concat(e.polygon);
    } else {
      bounds.push([e.coordinates.lat, e.coordinates.lng]);
    }
  });

  return bounds;
};

export default calcEntitiesbounds;
