function addZonesToPlotStudies(
  plotStudies: PlotStudies,
  zones: PlotIdsPluZone,
  onUpdate?: (ps: IPlotStudy) => void
) {
  const plotStudiesParsed = plotStudies.map((m) => {
    const z = zones[m.fullPlotId]?.plu_zone.zone;
    const zc = zones[m.fullPlotId]?.plu_zone.zone_child;
    const newPs = {
      ...m,
      zone: z,
      zoneChild: zc,
    };
    if ((m.zone !== z || m.zoneChild !== zc) && onUpdate) onUpdate(newPs);
    return newPs;
  });

  return plotStudiesParsed;
}

export default addZonesToPlotStudies;
