import AccordionContainer from '../../../../../shared/components/accordion/AccordionContainer';
import AccordionElement from '../../../../../shared/components/accordion/AccordionElement';
import useMailshotsModels from '../../../hooks/useMailshotsModels';
import styles from '../../../styles/mailshotAccountTabContent.module.scss';
import { MailshotTagEnum } from '../../../types/mailshotsEnums';
import CompanyContent from './CompanyContent';
import ContactContent from './ContactContent';
import LogoUploadButton from './LogoUploadButton';
import PlotContent from './PlotContent';
import SubfolderContent from './SubfolderContent';
import UserContent from './UserContent';

interface ITagButtonContainerProps {
  onTagBtnClick: (tag: MailshotBadgeValue) => void;
}

export interface ITagBtnClickProps {
  onBtnClick: (title: string, color: ColorType) => void;
}

function TagButtonContainer({ onTagBtnClick }: ITagButtonContainerProps) {
  const { selectedModel } = useMailshotsModels({});

  const handleBtnClick = (title: string, color: ColorType) => {
    onTagBtnClick({
      title,
      color,
      marker: '',
      key: Object.keys(MailshotTagEnum).find(
        (k) => MailshotTagEnum[k as keyof typeof MailshotTagEnum] === title
      ) as string,
    });
  };

  return (
    <div className={styles.tagButtonContainer}>
      <LogoUploadButton className={styles.logoUploadBtn} />

      <p className={styles.logoHelp}>
        Cliquez sur l&apos;étiquette de couleur pour insérer la variable dans le
        texte.
      </p>

      <AccordionContainer className={styles.badgeBtnContainer}>
        <AccordionElement
          id="company"
          title="Étiquettes infos Compagnie"
          content={<CompanyContent onBtnClick={handleBtnClick} />}
        />
        <AccordionElement
          id="user"
          title="Étiquettes infos Utilisateur"
          content={<UserContent onBtnClick={handleBtnClick} />}
        />
        <AccordionElement
          id="contact"
          title="Étiquettes infos Contact"
          content={<ContactContent onBtnClick={handleBtnClick} />}
        />
        {selectedModel?.modelType === 'plot' && (
          <AccordionElement
            id="plot"
            title="Étiquettes infos Parcelle"
            content={<PlotContent onBtnClick={handleBtnClick} />}
          />
        )}
        {selectedModel?.modelType === 'subfolder' && (
          <AccordionElement
            id="subfolder"
            title="Étiquette Multi-parcelle"
            content={<SubfolderContent onBtnClick={handleBtnClick} />}
          />
        )}
      </AccordionContainer>
    </div>
  );
}

export default TagButtonContainer;
