import { Page, StyleSheet } from '@react-pdf/renderer';
import GenericBloc from '../../shared/components/pdf/GenericBloc';
import { pdfStyles } from '../../shared/styles/pdfStyle';
import EnvironmentalConstraints from './components/EnvironmentalConstraints';
import PageFooter from './components/PageFooter';
import PageHeader from './components/PageHeader';

export enum EnvIconTypeEnum {
  SEISMIC = 'SEISMIC',
  CLAY = 'CLAY',
  RADON = 'RADON',
  EROSION = 'EROSION',
}

export const GradualColors: {
  [key in EnvIconTypeEnum]: { index: number; label: string; color: string }[];
} = {
  [EnvIconTypeEnum.SEISMIC]: [
    { index: 1, label: '1 - trés faible', color: '#D9D9D9' },
    { index: 2, label: '2 - faible', color: '#FBEC65' },
    { index: 3, label: '3 - modéré', color: '#FEB700' },
    { index: 4, label: '4 - moyen', color: '#D11325' },
    { index: 5, label: '5 - fort', color: '#94040C' },
  ],
  [EnvIconTypeEnum.CLAY]: [
    { index: 1, label: '1 - exposition faible', color: '#FBEC65' },
    { index: 2, label: '2 - exposition moyenne', color: '#FEB700' },
    { index: 3, label: '3 - exposition forte', color: '#94040C' },
  ],
  [EnvIconTypeEnum.RADON]: [
    { index: 1, label: '1 - potentiel radon faible', color: '#FBEC65' },
    { index: 2, label: '2 - potentiel radon moyenne', color: '#FEB700' },
    { index: 3, label: '3 - potentiel radon significative', color: '#94040C' },
  ],
  [EnvIconTypeEnum.EROSION]: [],
};

const styles = StyleSheet.create({
  page: {
    fontFamily: 'MontserratFamily',
    fontSize: '12px',
    position: 'relative',
  },
});
interface IPageFourProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  seismic: number;
  clay: number;
  radon: number;
  coastalErosion: boolean;
  isOrpi: boolean;
}

function PageFour({
  date,
  address,
  seismic,
  clay,
  radon,
  coastalErosion,
  isOrpi,
}: IPageFourProps) {
  return (
    <Page size="A4" style={pdfStyles.page}>
      <PageHeader date={date} address={address} fixed isOrpi={isOrpi} />

      <GenericBloc title="Contraintes environnementales">
        <EnvironmentalConstraints
          seismic={seismic}
          clay={clay}
          radon={radon}
          coastalErosion={coastalErosion}
        />
      </GenericBloc>

      <PageFooter fixed isOrpi={isOrpi} />
    </Page>
  );
}

export default PageFour;
