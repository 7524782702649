import { LatLngBoundsExpression } from 'leaflet';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getMapState, mapActions } from '../../map/mapSlice';
import { getStudyState } from '../../study/slices/studySlice';

function FolderPlotStudiesFitbound() {
  const { studyPlotStudies } = useAppSelector(getStudyState);
  const { franceLayerDisplay } = useAppSelector(getMapState);
  const dispatch = useAppDispatch();
  const map = useMap();

  useEffect(() => {
    if (studyPlotStudies.result) {
      const bounds = studyPlotStudies.result.map((m) => [m.lat, m.lng]);
      if (!isEmpty(bounds)) {
        if (franceLayerDisplay) {
          dispatch(mapActions.franceLayerDisplaySet(false));
        }

        map.fitBounds(bounds as LatLngBoundsExpression, { maxZoom: 17 });
      }
    }
  }, [studyPlotStudies.result]);

  return null;
}

export default FolderPlotStudiesFitbound;
