import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';
import { useAppDispatch } from '../../../App/store';

import PaginationCustom from '../../../components/Common/PaginationCustom/PaginationCustom';
import GlassButton from '../../../components/Common/glassButton';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import { IModalProps } from '../../modals/ModalContainer';
import { modalsActions } from '../../modals/modalsSlice';
import { dashboardOwnerDatasActions } from '../dashboardOwnerDatasSlice';
import {
  fetchCompanyNameFromPattern,
  fetchOwnerInfosFromSirenThunk,
  fetchOwnerPlotFromSirenThunk,
} from '../dashboardOwnerDatasThunk';
import './searchCompanyNameModal.scss';
type PaginationType = {
  page: number;
  totalItems: number;
  itemsPerPage: number;
};

const paginationInit = {
  page: 1,
  totalItems: 0,
  itemsPerPage: 10,
};
export default function SearchCompanyNameModal({ open }: IModalProps) {
  const [pagination, setPagination] = useState<PaginationType>(paginationInit);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [search, setSearch] = useState('');
  const [datas, setDatas] = useState<CompanyNamesWithSiren | null>(null);
  const dispatch = useAppDispatch();

  const request = async (pagin: PaginationType) => {
    if (search.length >= 3) {
      try {
        setIsLoading(true);
        setErrorMessage('');
        const response = await fetchCompanyNameFromPattern({
          page: pagin.page,
          itemsPerPage: pagin.itemsPerPage,
          pattern: search,
        });

        if (pagination.totalItems !== response?.totalItems) {
          setPagination({ ...pagination, totalItems: response?.totalItems ?? 0 });
        }
        setDatas(response?.result ?? []);
        setIsLoading(false);
      } catch (error: any) {
        setIsLoading(false);
        setErrorMessage(error.message);
      }
    }
  };

  const handleCloseModal = () => {
    dispatch(modalsActions.companySearch(false));
  };

  //#region handles
  const handleSearchChange = (e: ChangeEventCustom) => {
    const value = e.target.value;
    setSearch(value);
  };

  const patternChange = () => {
    setPagination({ ...paginationInit, itemsPerPage: pagination.itemsPerPage });
    request(paginationInit);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      patternChange();
    }
  };
  const handleClick = () => {
    patternChange();
  };

  const handleItemsPerPageChange = (e: ChangeEventCustom) => {
    const newState = { ...pagination, itemsPerPage: e.target.value };
    setPagination(newState);
    request(newState);
  };

  const handleIndexChange = (newIndex: number): void => {
    const newPage = newIndex / pagination.itemsPerPage + 1;
    const newState = { ...pagination, page: newPage };
    setPagination(newState);

    if (!isLoading) request(newState);
  };

  const handleRowClick = (siren: string) => {
    dispatch(dashboardOwnerDatasActions.setSiren(siren));
    dispatch(fetchOwnerInfosFromSirenThunk({ siren }));
    dispatch(fetchOwnerPlotFromSirenThunk({ siren, page: 1, itemsPerPage: 15 }));

    handleCloseModal();
  };
  //#endregion

  return (
    <Dialog
      id="popup"
      className={`search-company-name-modal ${isLoading ? 'loading' : ''}`}
      fullWidth={true}
      maxWidth="md"
      open={open}
      aria-labelledby="edit-folder-modal"
      fullScreen={false}
      scroll="body"
    >
      <DialogContent
        className={`search-company-name-modal-container ${
          isLoading ? 'loading' : ''
        }`}
      >
        <div className="close-cross" onClick={handleCloseModal}>
          <CloseIcon className="closeicon" />
        </div>

        <h3 className="title">Recherche de personne morale</h3>
        <div className="search-engine">
          <TextField
            className="text-field"
            placeholder="Rechercher"
            type="text"
            variant="outlined"
            fullWidth={true}
            value={search}
            onChange={handleSearchChange}
            required
            onKeyPress={handleKeyPress}
          />
          <GlassButton onClick={handleClick} />
        </div>

        {errorMessage === '500' || errorMessage === 'Fatal Error' ? (
          <p className="to-many-results">
            Trop de résultats pour ces critères.
            <br />
            Veuillez affiner votre recherche
          </p>
        ) : (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>siren</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow className="loader-row">
                      <TableCell colSpan={2}>
                        <IndividualCircularLoader size={200} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    datas?.map((elt) => (
                      <TableRow
                        key={nanoid()}
                        onClick={() => handleRowClick(elt.siren)}
                      >
                        <TableCell>{elt.name}</TableCell>
                        <TableCell>{elt.siren}</TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {(datas?.length || 0) > 0 && (
              <PaginationCustom
                itemPerPageLabel="Contacts par page"
                elementsQty={pagination.totalItems || 0}
                itemsPerPage={pagination.itemsPerPage}
                onItemsPerPageChange={handleItemsPerPageChange}
                onMinIndexTab={handleIndexChange}
              />
            )}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}
