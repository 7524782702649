import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import SubmitButton from '../../../../../components/RightPanelModal/TabsContent/bodys/components/prospection/SubmitButton';
import createFeasibilityMatrix from '../../../services/createFeasibilityMatrix';
import { fetchFeasibilityMatrix } from '../../../services/fetchFeasibilityMatrix';
import updateFeasibilityMatrix from '../../../services/updateFeasibilityMatrix';
import { valuesCalculator } from '../../../utils/matrixValuesCalculator';
import GenericMatriceFormInput from './GenericMatriceFormInput';
import './matrice.scss';
import { matriceValidationSchema, matrixInitialValues } from './matriceValidations';

interface IFeasabilityMatrixProps {
  matrixIdIri: string | null;
  studyParams: StudyParamsState;
  updateAllowed: boolean;
}
export type StudyMatrixFormValues = Omit<
  IFeasibilityMatrix,
  'idIri' | 'folder' | 'plotStudy'
>;
export function FeasabilityMatrix({
  matrixIdIri,
  studyParams,
  updateAllowed,
}: IFeasabilityMatrixProps) {
  const [matrix, setMatrix] = useState<IFeasibilityMatrix | null>(null);
  const {
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<StudyMatrixFormValues>({
    defaultValues: matrixInitialValues,
    resolver: yupResolver(matriceValidationSchema),
  });

  const parsedFormForApi = (datas: StudyMatrixFormValues) => {
    const body = {
      areaField: datas.areaField ?? 0,
      builtFootprint: datas.buildingFootprint ?? 0,
      builtFreeOnGround: datas.builtFreeOnGround ?? 0,
      floors: datas.floors ?? 0,
      avgAreaHousing: datas.avgAreaHousing ?? 0,
      socialHousing: datas.socialHousing ?? 0,
      spShab: datas.spShab ?? 0,
      buildingFootprint: datas.buildingFootprint ?? 0,
      freeBuiltAreaMin: datas.freeBuiltAreaMin ?? 0,
      floorAreaMax: datas.floorAreaMax ?? 0,
      housingAreaMax: datas.housingAreaMax ?? 0,
      socialHousingAreaMax: datas.socialHousingAreaMax ?? 0,
      housingQty: datas.housingQty ?? 0,
      socialHousingQty: datas.socialHousingQty ?? 0,
      parkingQty: datas.parkingQty ?? 0,
      estimatedParkingArea: datas.estimatedParkingArea ?? 0,
      equivalentUnderGroundArea: datas.equivalentUnderGroundArea ?? 0,
    };
    return body;
  };

  const request = async (datas: StudyMatrixFormValues) => {
    let result: IFeasibilityMatrix | null = null;
    if (matrix) {
      const body: IFeasibilityMatrix = {
        idIri: matrix.idIri,
        ...parsedFormForApi(datas),
      };
      result = await updateFeasibilityMatrix(body);
      setMatrix(result);
    } else {
      const body = {
        ...parsedFormForApi(datas),
        [studyParams.type as string]: studyParams.idIri,
      };
      result = await createFeasibilityMatrix(body);
    }
    setMatrix(result);
  };

  const formInit = async () => {
    const result = await fetchFeasibilityMatrix(matrixIdIri);

    if (result) {
      setMatrix(result);
      const r = result as Omit<IFeasibilityMatrix, 'idIri' | 'folder' | 'plotStudy'>;
      type Key = keyof typeof r;
      const keys = Object.keys(result) as Key[];

      keys.forEach((e: keyof StudyMatrixFormValues) => {
        setValue(e, result[e]);
      });
    }
  };

  useEffect(() => {
    if (matrixIdIri) {
      formInit();
    } else {
      setMatrix(null);
    }
  }, []);

  const handleChangeValue = () => {
    valuesCalculator(setValue, getValues);
  };

  const handleFormSubmit = (values: StudyMatrixFormValues) => {
    if (isEmpty(errors)) {
      request(values);
    }
  };

  return (
    <form className="matrice-content" onSubmit={handleSubmit(handleFormSubmit)}>
      <div>
        <h3>Ratios généraux</h3>

        <GenericMatriceFormInput
          label="Surface terrain"
          control={control}
          onChange={handleChangeValue}
          name="areaField"
          unit="m²"
          disabled={!updateAllowed}
        />
        <GenericMatriceFormInput
          label="Emprise bâti"
          control={control}
          onChange={handleChangeValue}
          name="builtFootprint"
          disabled={!updateAllowed}
          unit="%"
        />
        <GenericMatriceFormInput
          label="Emprise libre au sol"
          control={control}
          onChange={handleChangeValue}
          name="builtFreeOnGround"
          disabled={!updateAllowed}
          unit="%"
        />
        <GenericMatriceFormInput
          label="Nombre d'étages"
          control={control}
          onChange={handleChangeValue}
          disabled={!updateAllowed}
          name="floors"
        />
        <GenericMatriceFormInput
          label="Surface moyenne logement"
          control={control}
          onChange={handleChangeValue}
          name="avgAreaHousing"
          disabled={!updateAllowed}
          unit="m²"
        />
        <GenericMatriceFormInput
          label="Logement social"
          control={control}
          onChange={handleChangeValue}
          name="socialHousing"
          disabled={!updateAllowed}
          unit="%"
        />
      </div>
      <div>
        <h3>Surfaces calculées</h3>
        <GenericMatriceFormInput
          label="Ratio SP/SHAB"
          control={control}
          onChange={handleChangeValue}
          name="spShab"
          disabled={!updateAllowed}
          unit="%"
        />
        <GenericMatriceFormInput
          label="Emprise bâtiment"
          control={control}
          name="buildingFootprint"
          unit="m²"
          className="calculated"
          disabled={!updateAllowed}
          readOnly
        />
        <GenericMatriceFormInput
          label="Surface libre de construction min"
          control={control}
          name="freeBuiltAreaMin"
          unit="m²"
          className="calculated"
          readOnly
        />
        <GenericMatriceFormInput
          label="Surface plancher max"
          control={control}
          name="floorAreaMax"
          unit="m²"
          className="calculated"
          readOnly
        />
        <GenericMatriceFormInput
          label="Surface habitable max"
          control={control}
          name="housingAreaMax"
          unit="m²"
          className="calculated"
          readOnly
        />
        <GenericMatriceFormInput
          label="Surface habitable social"
          control={control}
          name="socialHousingAreaMax"
          unit="m²"
          className="calculated"
          readOnly
        />
        <GenericMatriceFormInput
          label="Nombre de logements"
          control={control}
          name="housingQty"
          unit="logements moyens"
          className="calculated"
          readOnly
        />

        <GenericMatriceFormInput
          label="Nombre de logements sociaux"
          control={control}
          name="socialHousingQty"
          unit="logements moyens"
          className="calculated"
          readOnly
        />
      </div>
      <div>
        <h3>Stationnements</h3>
        <GenericMatriceFormInput
          label="Nombre de stationnments"
          control={control}
          onChange={handleChangeValue}
          name="parkingQty"
          disabled={!updateAllowed}
          unit="parkings"
        />
        <GenericMatriceFormInput
          label="Surface estimée stationnement + circulation"
          control={control}
          name="estimatedParkingArea"
          unit="m²"
          className="calculated"
          readOnly
        />
        <GenericMatriceFormInput
          label="Equivalent surface en niveau de SS"
          control={control}
          name="equivalentUnderGroundArea"
          className="calculated"
          readOnly
        />
      </div>
      <SubmitButton label="Sauvegarder" />
    </form>
  );
}
export default memo(FeasabilityMatrix);
