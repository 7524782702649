// imgUrl: the image origin url
// callback: when the image is converted to base64, will call this function
// we can wrap this function to Promise-based
//  function convertImageToBase64Async(imagUrl) {
//     return new Promise(resovle => convertImageToBase64(imgUrl, resolve))
//  }

// export async function convertImageToBase64(imgUrl: string) {
//   return new Promise<string | null>((resolve, reject) => {
//     const image = new Image();
//     image.crossOrigin = 'anonymous';
//     image.src = imgUrl;
//     image.onload = () => {
//       const canvas = document.createElement('canvas');
//       const ctx = canvas.getContext('2d');
//       canvas.height = image.naturalHeight;
//       canvas.width = image.naturalWidth;
//       if (ctx) {
//         ctx.drawImage(image, 0, 0);
//         const dataUrl = canvas.toDataURL();
//         resolve(dataUrl);
//       } else {
//         reject();
//       }
//     };
//     image.onerror = () => {
//       reject();
//     };
//   });
// }

export async function convertImageToBase64(imgUrl: string): Promise<string | null> {
  if (!imgUrl) return null;

  try {
    const response = await fetch(
      `https://images.weserv.nl/?url=${encodeURIComponent(imgUrl)}`
    );
    const blob = await response.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        // if not an image, reuturn null
        resolve(!base64Data.includes('data:image') ? null : base64Data);
      };
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    // console.error("Erreur lors de la conversion de l'image en base64:", error);
    return null;
    // return '';
  }
}
