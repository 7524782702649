import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { moreOrLessDistance } from '../../../shared/utils/utils';
import CarSvg from './svg/CarSvg';
import HighwaySvg from './svg/HighwaySvg';
import TrainSvg from './svg/TrainSvg';

interface IProps {
  source: NoiseSource;
  isColumn?: boolean;
}

function NoiseSourceEntity({ source, isColumn }: IProps) {
  const getIcon = (type: NoiseSourceCategory) => {
    switch (type) {
      case 'highway':
        return {
          text: 'Route la plus proche',
          icon: isColumn ? (
            <CarSvg width="16px" height="16px" />
          ) : (
            <HighwaySvg color="#fff" />
          ),
        };
      case 'railway':
        return {
          text: 'Voie ferrée la plus proche',
          icon: isColumn ? (
            <TrainSvg width="16px" height="16px" />
          ) : (
            <TrainSvg color="#fff" />
          ),
        };
    }
  };

  return (
    <>
      {isColumn ? (
        <View style={styles.row}>
          {getIcon(source.category).icon}
          <Text style={styles.streetNoiseRightText}>
            {getIcon(source.category).text}:{' '}
          </Text>
          <Text>{moreOrLessDistance(source.distance)}</Text>
        </View>
      ) : (
        <View style={styles.closestNoiseBloc}>
          <View style={styles.closestNoiseSvg}>{getIcon(source.category).icon}</View>
          <Text style={styles.closestNoiseText}>
            {getIcon(source.category).text}
          </Text>
          <Text style={styles.closestNoiseValue}>
            {moreOrLessDistance(source.distance)}
          </Text>
        </View>
      )}
    </>
  );
}

export default NoiseSourceEntity;

const styles = StyleSheet.create({
  closestNoiseBloc: {
    paddingHorizontal: '15px',
    paddingVertical: '4px',
    borderLeft: '2px',
    borderColor: '#cdcdcd',
    width: '50%',
  },
  closestNoiseSvg: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '25px',
    padding: '5px',
    width: '25px',
    height: '25px',
    backgroundColor: '#7d69f5',
  },
  closestNoiseText: {
    fontSize: '14px',
    marginTop: '12px',
  },
  closestNoiseValue: {
    fontSize: '14px',
    lineHeight: 1.5,
    fontWeight: 700,
  },
  streetNoiseRightText: {
    fontSize: '10px',
    fontWeight: 700,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
