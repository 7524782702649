import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import ButtonGeneric from '../../../../../shared/components/buttons/buttonGeneric/ButtonGeneric';
import { getAuthState } from '../../../../auth/authSlice';
import { getCompanyState } from '../../../../company/companySlice';
import { modalsActions } from '../../../../modals/modalsSlice';

interface IProps {
  className?: string;
}
function LogoUploadButton({ className }: IProps) {
  const { isManager } = useAppSelector(getAuthState);
  const { company } = useAppSelector(getCompanyState);
  const dispatch = useAppDispatch();

  if (!isManager) return null;

  const handleUploadClick = () =>
    dispatch(
      modalsActions.uploadModalOpen({
        type: 'companyLogo',
        existingUrl: company.result?.logoUrl ?? null,
      })
    );

  const hasLogoUrl = company.result?.logoUrl;

  return (
    <ButtonGeneric
      containerClassName={className}
      content={
        <p>
          {hasLogoUrl ? 'Modifiez' : 'Ajoutez'} votre logo <br />
          en cliquant ici
        </p>
      }
      onClick={handleUploadClick}
    />
  );
}

export default LogoUploadButton;
