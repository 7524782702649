export const isNumber = (value: string | number | null, type: 'float' | 'int') => {
  if (!value) {
    return 0;
  }

  return typeof value === 'number'
    ? value
    : type === 'float'
      ? parseFloat(value)
      : parseInt(value);
};
