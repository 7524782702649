import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchParkingPackageThunk from './fetchParkingPackageThunk';
import fetchSocialCriteriaThunk from './fetchSocialCriteriaThunk';

const fetchCityDatasLaucherThunk = createAsyncThunk(
  'activePlot/fetchCityDatasThunk',
  async (params: { inseeCode: string }, thunkOptions) => {
    const { inseeCode } = params;
    try {
      thunkOptions.dispatch(fetchParkingPackageThunk({ inseeCode }));
      thunkOptions.dispatch(fetchSocialCriteriaThunk({ inseeCode }));
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchCityDatasLaucherThunk;
