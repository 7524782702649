import { useAppSelector } from '../../../App/store';
import ButtonsOnMap from '../../../components/ButtonsOnMap';
import { getAuthState } from '../../auth/authSlice';
import LeafletMap from './LeafletMap';
import RightBottomToolbar from './plugins/toolbars/rightBottomToolbar/RightBottomToolbar';
import RightTopToolbar from './plugins/toolbars/RightTopToolbar/RightTopToolbar';
import UrbaToolbar from './plugins/toolbars/urbaToolbar/UrbaToolbar';

function LeafletMapContainer() {
  const { isLogged } = useAppSelector(getAuthState);
  return (
    <>
      <LeafletMap />
      {isLogged && (
        <>
          <ButtonsOnMap />
          <UrbaToolbar />
          <RightTopToolbar />
          <RightBottomToolbar />
        </>
      )}
    </>
  );
}

export default LeafletMapContainer;
