import getCookie from '../../../shared/utils/getCookie';

function fetchFolderFilterSortQuery(companyId: number) {
  const cookieName = 'inputValueFilterSortFolder' + companyId;
  const cookies = getCookie(cookieName);

  let query = {};
  if (typeof cookies?.priority === 'number') {
    query = {
      ['priority[]']: cookies?.priority,
    };
  }

  if (cookies?.sortKey && cookies?.order) {
    query = {
      ...query,
      [`orderBy[${cookies.sortKey}]`]: cookies.order,
    };
  }

  const responsableQuery = cookies?.user
    ?.map(
      (elt: ISelectItem) =>
        `&responsable[]=${(elt.value as string).split('/').pop()}`
    )
    .join('');
  const inseeCodeQuery = cookies?.city
    ?.map((elt: AutocompleteCity) => `&inseeCode[]=${elt.inseeCode}`)
    .join('');
  const statusQuery = cookies?.status
    ?.map(
      (elt: ISelectItem) => `&status[]=${(elt.value as string).split('/').pop()}`
    )
    .join('');

  const customQuery =
    (responsableQuery ?? '') + (statusQuery ?? '') + (inseeCodeQuery ?? '');

  return {
    query,
    customQuery: customQuery ? '?' + customQuery : '',
  };
}

export default fetchFolderFilterSortQuery;
