import { Dialog, DialogContent } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import Xion from '../../../assets/images/xicon.png';
import { IModalProps } from '../../../features/modals/ModalContainer';
import { modalsActions } from '../../../features/modals/modalsSlice';

import useRightPanel from '../../../App/hooks/useRightPanel';
import { deleteStudyThunk } from '../../../features/study/services/thunks/studyThunks';
import { getStudyState } from '../../../features/study/slices/studySlice';
import '../deleteModal.scss';

export default function DeleteStudyModal({ open }: IModalProps) {
  const { study } = useAppSelector(getStudyState);
  const dispatch = useAppDispatch();
  const { closeRightPanel } = useRightPanel();

  const handleCloseModal = () => {
    dispatch(modalsActions.plotDelete(false));
  };

  const handleClick = () => {
    if (study.result) {
      dispatch(deleteStudyThunk({ study: study.result }));
      closeRightPanel();
      handleCloseModal();
    }
  };

  return (
    <Dialog
      className="delete-modal"
      fullWidth
      maxWidth="xs"
      open={open}
      aria-labelledby="delete-modal"
      fullScreen={false}
      scroll="body"
    >
      <DialogContent>
        <div className="delete-modal-container">
          <div className="xicon-wrapper" onClick={handleCloseModal}>
            <img src={Xion} alt="xion" />
          </div>
          <div className="delete-modal-container-title">Suppresion de parcelle</div>
          <div className="delete-modal-container-content">
            <p>
              La suppression de la parcelle{' '}
              <span>{(study.result as IPlotStudy).fullPlotId.substring(5)}</span>{' '}
              sera définitive
            </p>
            <button type="button" className="warning-button" onClick={handleClick}>
              Supprimer
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
