import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchDataById, postEntity } from '../../services/axiosFiles/genericCrud';
import { companyApiToStoreParser } from './companyParser';

export const fetchCompanyThunk = createAsyncThunk(
  'company/fetchCompanyThunk',
  async (params: { companyIdIri: string }, { rejectWithValue, dispatch }) => {
    if (params.companyIdIri) {
      try {
        const result = await fetchDataById(params.companyIdIri);

        const parsed = companyApiToStoreParser(result);

        return parsed;
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      return rejectWithValue(new Error('no companyIdIri found'));
    }
  }
);

export const changeCompanyThunk = createAsyncThunk(
  'company/changeCompanyThunk',
  async (
    params: { company: TokenCompany | null },
    { dispatch, rejectWithValue }
  ) => {
    return new Promise<TokenCompany>((resolve, reject) => {
      try {
        if (params.company) {
          dispatch({ type: 'app/changeCompany' });

          return resolve(params.company);
        } else {
          throw rejectWithValue('no param');
        }
      } catch (error) {
        throw rejectWithValue(error);
      }
    });
  }
);

export const setAuthenticatedCompanyThunk = createAsyncThunk(
  'company/setAuthenticatedCompanyThunk',
  async ({
    companyId,
    userId,
  }: {
    companyId: number | null;
    userId: number | null;
  }) => {
    try {
      if (!companyId) throw new Error('company id missing');
      if (!userId) throw new Error('user id missing');

      await postEntity({
        endpoint: '/set_authenticated_company',
        body: {
          company_id: companyId,
          user_id: userId,
        },
      });
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
