import * as docx from 'docx';
import { AlignmentType, Packer, Paragraph, TextRun, UnderlineType } from 'docx';
import fetchCompanyLogoFromS3 from '../../../company/services/fetchCompanyLogoFromS3';
import { customNumberedLevels, defaultStyles } from './constants';

let numberedTracker = -1;
let styles = defaultStyles;
let levels: CustomLevels[] = customNumberedLevels;
let customBullets = false;

// main public function to generate docx document
/**
 * Parse parsedLetters to docx datas structure
 * @param {Letter[]} mails - array of delta, each delta is a mail
 */
export async function generateWord(
  parsedLetters: ParsedLetters,
  withLogo: string | null
): Promise<ExportObject> {
  // create a container for the docx doc sections
  // each section must be 1 page
  const pages: Paragraph[][] = [];

  // set up the docx document based on configuration
  //build docx sections
  parsedLetters.letters.forEach((e) => {
    const temp = buildSection(e);
    pages.push(temp);
  });

  const doc = await setupDoc(numberedTracker, pages, withLogo);

  // return the appropriate export object based on configuration
  return exportDoc(doc);
}

// set a style's paragraph and run properties
function setStyle(style: StyleProperties, styleId: string, index: number) {
  if (style.paragraph) {
    styles[index].paragraph = style.paragraph as any;
  }
  if (style.run) {
    styles[index].run = style.run as any;
  }
}

// sets up the docx document
async function setupDoc(
  numberedList: number,
  pages: Paragraph[][],
  withLogo: string | null
): Promise<docx.Document> {
  styles = defaultStyles; // reset back to original
  levels = customNumberedLevels; // reset back to original

  let numbering: NumberingConfig | undefined = undefined;
  // build the numbering property
  if (numberedList > 0) {
    numbering = buildNumbering(numberedList);
  }

  const pagesWithPageBreak = pages.map((m) =>
    m.concat(
      new Paragraph({
        children: [
          new TextRun({
            break: 1, // This adds a page break
          }),
        ],
      })
    )
  );

  const imageDatas = await fetchCompanyLogoFromS3({ url: withLogo, height: 50 });
  const doc = new docx.Document({
    sections: pagesWithPageBreak.map((m) => ({
      headers: imageDatas
        ? {
            default: new docx.Header({
              children: [
                new Paragraph({
                  children: [
                    new docx.ImageRun({
                      data: imageDatas.image as string,
                      transformation: {
                        height: imageDatas.height,
                        width: imageDatas.width,
                      },
                    }),
                  ],
                }),
              ],
            }),
          }
        : undefined,
      children: m,
      // footers: {
      //   default: new docx.Footer({
      //     children: [new Paragraph('Footer text')],
      //   }),
      // },
    })),
    styles: {
      paragraphStyles: styles,
    },
    numbering: numbering,
  });

  return doc;
}

// export the appropriate object based on configuration
async function exportDoc(doc: docx.Document): Promise<ExportObject> {
  return Packer.toBlob(doc);
}

// build docx numbering object from quill numbered lists
function buildNumbering(numberOfLists: number): NumberingConfig {
  let config: any[] = [];
  let numberTracker = 0;
  // create a new docx numbering object for each quill numbered list
  while (numberTracker < numberOfLists) {
    const newList = {
      reference: `numbered_${numberTracker}`,
      levels: levels,
    };
    config.push(newList);
    numberTracker++;
  }
  const numberConfig = {
    config: config,
  };
  return numberConfig;
}

// generate a section as an array of paragraphs
function buildSection(letterParagraphs: LetterParagraphs): Paragraph[] {
  let letterParagraphTracker = 0;
  // create a container to hold the docx paragraphs
  const paragraphs: Paragraph[] = [];

  // build a docx paragraph from each letter paragraph
  for (const paragraph of letterParagraphs) {
    // if (paragraph.embed?.image) {
    //   const image = Media.addImage(paragraph.embed.image);
    //   paragraphs.push(new Paragraph(image));
    // } else
    if (paragraph.textRuns) {
      // handle ordered list tracking
      if (
        letterParagraphTracker > 0 &&
        paragraph.attributes?.list === 'ordered' &&
        letterParagraphs[letterParagraphTracker - 1].attributes?.list !== 'ordered'
      ) {
        numberedTracker++;
      }

      paragraphs.push(buildParagraph(paragraph));
    }
    letterParagraphTracker++;
  }
  return paragraphs;
}

// generate a paragraph as an array of text runs
function buildParagraph(paragraph: LetterParagraph): Paragraph {
  // container to hold docx text runs
  const textRuns: TextRun[] = [];
  // build a docx run from each delta run
  for (const run of paragraph.textRuns!) {
    if ((run as QuillTextRun).text) {
      textRuns.push(buildTextRun(run as QuillTextRun));
    }
  }

  const docxParagraph = new Paragraph({
    children: textRuns,

    heading:
      paragraph.attributes?.header === 1
        ? docx.HeadingLevel.HEADING_1
        : paragraph.attributes?.header === 2
          ? docx.HeadingLevel.HEADING_2
          : undefined,

    bullet:
      paragraph.attributes?.list === 'bullet' && !customBullets
        ? { level: paragraph.attributes.indent ? paragraph.attributes.indent : 0 }
        : undefined,

    numbering:
      paragraph.attributes?.list === 'ordered'
        ? {
            reference: `numbered_${numberedTracker}`,
            level: paragraph.attributes.indent ? paragraph.attributes.indent : 0,
          }
        : paragraph.attributes?.list === 'bullet' && customBullets
          ? {
              reference: 'customBullets',
              level: paragraph.attributes.indent ? paragraph.attributes.indent : 0,
            }
          : undefined,

    alignment:
      paragraph.attributes?.align === 'left'
        ? AlignmentType.LEFT
        : paragraph.attributes?.align === 'center'
          ? AlignmentType.CENTER
          : paragraph.attributes?.align === 'right'
            ? AlignmentType.RIGHT
            : paragraph.attributes?.align === 'justify'
              ? AlignmentType.JUSTIFIED
              : undefined,

    style: paragraph.attributes?.['code-block']
      ? 'code_block'
      : paragraph.attributes?.blockquote
        ? 'block_quote'
        : (paragraph as ParagraphAlt).attributes?.citation
          ? 'citation'
          : undefined,
  });
  return docxParagraph;
}

// generate a docx text run from quill text run
function buildTextRun(run: QuillTextRun) {
  const textRun = new TextRun({
    text: run.text,
    bold: run.attributes?.bold ? true : false,
    italics: run.attributes?.italic ? true : false,
    subScript: run.attributes?.script === 'sub' ? true : false,
    superScript: run.attributes?.script === 'super' ? true : false,
    strike: run.attributes?.strike ? true : false,
    underline: run.attributes?.underline
      ? { type: UnderlineType.SINGLE, color: 'auto' }
      : undefined,
    color: run.attributes?.color ? run.attributes?.color.slice(1) : undefined,
    size:
      run.attributes?.size === 'huge'
        ? 36
        : run.attributes?.size === 'large'
          ? 32
          : run.attributes?.size === 'small'
            ? 20
            : undefined,
    highlight: run.attributes?.background,
  });

  return textRun;
}
