import store from '../../../App/store';
import { GenericModalEnum } from '../../../components/Modals/GenericModal';
import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { modalsActions } from '../../modals/modalsSlice';
import { mailshotModelParser } from '../utils/mailshotModelParser';

interface ICreateMailshotModelProps {
  model: CreateMailshotModelType;
  companyIdIri: string | null;
}
async function createMailshotModel({
  model,
  companyIdIri,
}: ICreateMailshotModelProps) {
  try {
    if (!model.name) throw new Error('Model name not found');
    if (!model.modelType) throw new Error('Model type not found');
    if (!companyIdIri) throw new Error('Company not found');

    const body = {
      name: model.name,
      isActive: model.isActive,
      isDefault: model.isDefault,
      modelType: model.modelType,
      content: JSON.stringify(model.content),
      company: companyIdIri,
    };

    const result = await postEntity({ endpoint: '/direct_mail_templates', body });
    const parsed = mailshotModelParser(result);
    return parsed;
  } catch (error) {
    store.dispatch(
      modalsActions.alert({
        status: true,
        context: 'createMailshotModelError',
        modalType: GenericModalEnum.ERROR,
      })
    );
    return Promise.reject(error);
  }
}

export default createMailshotModel;
