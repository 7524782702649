interface ICommunalTaxeProps {
  tax: ITaxes;
  isMultiple: boolean;
}
export default function CommunalTaxe({
  tax,
  isMultiple,
}: ICommunalTaxeProps): JSX.Element {
  return (
    <p className="tax-line">
      {isMultiple ? `N° ${tax.numero}: ` : ''}
      <span>
        {`${tax.taux} % `}
        {isMultiple ? `( ${tax.city} )` : ''}
      </span>
    </p>
  );
}
