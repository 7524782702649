import FormButtonContainer from '../../components/buttons/formButtonContainer/FormButtonContainer';
import CheckIcon from '../../components/icons/CheckIcon';
import styles from './uploadModal.module.scss';

interface IUploadSuccessContentProps {
  callback: () => void;
}

function UploadSuccessContent({ callback }: IUploadSuccessContentProps) {
  return (
    <>
      <div className={styles.successBody}>
        <CheckIcon
          isRoundIcon
          noTooltip
          className={styles.icon}
          bgColor="SUCCESS"
          width={50}
          height={50}
        />
        <p>La sauvegarde de l&lsquo;image a été exécutée avec succès</p>
      </div>
      <FormButtonContainer
        validContent="Ok"
        onValid={callback}
        className={styles.flexEnd}
      />
    </>
  );
}

export default UploadSuccessContent;
