import { postEntity, postEntityNew } from '../../../services/axiosFiles/genericCrud';
import { dpePointsParser, dpePopupContentParser, dpesParser } from './dpeParser';
import { dpeAdvancedSearchParamBuilder } from './utils';

const DATA_URL = process.env.REACT_APP_API_URL_DATAS;

/**
 * Get DPE total count for one city
 * @param {string|null} insee - insee of the city
 * @param {number} page - page index
 * @param {number} dpe_per_page - dpe count for 1 page
 * @param {number} builtAreaMin - area min
 * @param {number} builtAreaMax - area max
 * @param {string|null} dpeMin - DPE graduation min
 * @param {string|null} dpeMax - DPE graduation max
 * @param {string|null} gesMin - GSE graduation min
 * @param {string|null} gesMax - GSE graduation max
 * @param {string|null} buildingType - entity typologie
 * @param {string|null|undefined} createdAtBefore - formated start date
 * @param {string|null|undefined} createdAtAfter - formated start date
 * @returns {number} - DPE points quantity
 */
export async function fetchDpePointsRequest(
  props: any & { insee: string | null; page: number; dpe_per_page: number }
) {
  try {
    if (!props.insee) throw new Error('insee param is missing');
    if (!props.page) throw new Error('page param is missing');
    if (!props.dpe_per_page) throw new Error('dpePerPage param is missing');

    const response = await postEntityNew({
      endpoint: '/get_dpe_points_from_city/',
      body: props,
      hasPythonProxy: true,
    });

    const parsedResponse = dpePointsParser(response as any);

    return parsedResponse;
  } catch (error) {
    return Promise.reject(error);
  }
}
export const fetchPopupContent = async (
  dpePoint: DpePoint,
  dpeAdvancedSearch: IDpeAdvancedSearchForm | null
) => {
  try {
    if (!dpePoint) {
      return null;
    }
    const dpeBody = dpeAdvancedSearchParamBuilder({ dpeAdvancedSearch });

    const response = await postEntity({
      endpoint: `${DATA_URL}/get_dpe_datas_from_geo_adresse/`,
      body: {
        geo_adresse: dpePoint.address,
        latitude: dpePoint.lat.toString(),
        longitude: dpePoint.lng.toString(),
        num_dpe: dpePoint.numDpe,
        insee: dpePoint.insee,
        ...dpeBody,
      },
    });

    const parsedResponse = dpesParser(response as any);
    const popup = dpePopupContentParser(parsedResponse);
    return popup;
  } catch (error) {
    return Promise.reject(error);
  }
};
