import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchContacts } from '../contactRequests';

const fetchContactsThunk = createAsyncThunk(
  'contacts/fetchContactsThunk',
  async (params: { criterias?: FetchCriterias; companyIdIri: string | null }) => {
    try {
      const result = await fetchContacts({ ...params });

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchContactsThunk;
