import { isEmpty } from 'lodash';

export const layerEnvParser = (data: any) => {
  const parsedData: LayerEnv[] = [];
  const keys = Object.keys(data);

  keys.forEach((k) => {
    if (!isEmpty(data[k])) {
      const temp: LayerEnv = {
        name: k,
        cat: data[k][0].cat,
        url: data[k][0].mapbox_url,
        libelle: data[k][0].libelle,
        displayed: false,
        pmtilesFileUrl: data[k][0].pmtiles_file_url,
      };

      parsedData.push(temp);
    }
  });

  return parsedData;
};
