import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { plotDataFromCoordinatesParser } from '../plotGenericParser';

export interface IFetchPlotDataFromCoordinatesProps {
  lat: number;
  lng: number;
  townHall?: boolean;
  department: string;
  pdf?: boolean;
  companyId: number;
  // used for shift lat marker to 0.000015 or 0.000030
  gap?: CentroidGap;
}
export const fetchGetPlotDataFromCoordinates = async (
  params: IFetchPlotDataFromCoordinatesProps
) => {
  try {
    const response = await fetchDatas('/get_parcel_datas_from_coordinates', {
      lat: params.lat,
      lon: params.lng,
      town_hall: params.townHall ?? false,
      department: params.department,
      pdf: params.pdf ?? false,
      company_id: params.companyId,
    });

    const parsedResponse = plotDataFromCoordinatesParser(response, params.gap);

    return parsedResponse as Plot;
  } catch (error) {
    return Promise.reject(error);
  }
};
