import { nanoid } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { memo } from 'react';
import { useAppSelector } from '../../../App/store';
import FolderPlots from '../../../components/LeftPanelModal/MenuTabs/Prospection/FolderPlots';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import DashboardFolderPlots from '../dashboard/DashboardFolderPlots';
import { getFoldersState } from '../foldersSlice';

import styles from './folder.module.scss';
import FolderHeader from './FolderHeader';

interface IFolderProps {
  isSubfolder?: boolean;
  isDashboard?: boolean;
  folder: IFolder;
}

function Folder({ folder, isSubfolder, isDashboard }: IFolderProps): JSX.Element {
  const { deployedFolder, deployedSubFolder } = useAppSelector(getFoldersState);

  if (!folder) {
    return <IndividualCircularLoader size={50} />;
  }

  return (
    <div className={styles.folder} data-cy="folder-item">
      {/*  HEADER */}
      <FolderHeader
        folder={folder}
        isDashboard={isDashboard}
        deployedFolder={deployedFolder}
        deployedSubFolder={deployedSubFolder}
        isSubfolder={isSubfolder}
      />

      <div className={styles.folderContent}>
        {/*  display subfolders */}
        {!isSubfolder && folder.idIri === deployedFolder?.idIri && folder.subs
          ? [...folder.subs]?.map((f, i) => (
              <Folder
                isSubfolder={true}
                folder={f}
                key={nanoid()}
                isDashboard={isDashboard}
              />
            ))
          : null}

        {/*  display folderPlots */}
        {(folder.idIri === deployedFolder?.idIri ||
          folder.idIri === deployedSubFolder?.idIri) &&
          (isDashboard ? (
            <DashboardFolderPlots
              folder={folder}
              color={folder.markerColor}
              isDashboard={isDashboard}
              isSub={isSubfolder}
            />
          ) : (
            <FolderPlots folder={folder} color={folder.markerColor} />
          ))}
      </div>
    </div>
  );
}

const doRender = (p: Readonly<IFolderProps>, n: Readonly<IFolderProps>) => {
  return isEqual(p, n);
};
export default memo(Folder, doRender);
