import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { DrawActionEnum } from '../../map/leaflet/plugins/drawPlugin/types/drawActionEnum';
import {
  getMapPluginsDrawState,
  mapPluginsActions,
} from '../../map/leaflet/plugins/mapPluginSlice';
import { advancedPlotSearchActions } from '../advancedPlotSearchSlice';
import PrintButton from './PrintButton';
import styles from './advancedPlotSearchContainer.module.scss';
import { initialValues } from './formSchema';

interface IAdvancedSearchButtonsProps {
  hasFormData: boolean;
}

function AdvancedSearchButtons({
  hasFormData,
}: IAdvancedSearchButtonsProps): React.ReactElement {
  const {
    reset,
    formState: { isDirty },
  } = useFormContext();
  const { action } = useAppSelector(getMapPluginsDrawState);
  const dispatch = useAppDispatch();
  const disabled = hasFormData ? false : isDirty ? false : true;

  const handleErasePlotsClick = () => {
    reset(initialValues);
    dispatch(advancedPlotSearchActions.reset());
    if (action === DrawActionEnum.DRAW_ADVANCED_SEARCH) {
      dispatch(mapPluginsActions.resetDraw);
    }
  };

  return (
    <div className={`${styles.buttonsContainer} ${styles.noPrint}`}>
      <button
        type="reset"
        className={styles.resetBtn}
        onClick={handleErasePlotsClick}
      >
        Effacer recherche
      </button>

      <button
        type="submit"
        disabled={disabled}
        className={`${styles.submitBtn} ${disabled ? styles.btnDisabled : ''}`}
      >
        Rechercher
      </button>

      <PrintButton />
    </div>
  );
}

export default AdvancedSearchButtons;
