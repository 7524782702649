import { UseFormReturn } from 'react-hook-form';
import * as Yup from 'yup';
import {
  PDF_LAND_MAX_COMMENT_CHARACTERS,
  PDF_LAND_MAX_PRICE_COMMENT_CHARACTERS,
} from '../../../shared/constants';
import { transformNumber } from '../../../shared/utils/yupSchema';
import { addSpaceToNumber } from '../../../utils/jsFunctions';
import {
  MAX_FORM_TEXT_FIELD,
  MAX_FORM_TEXT_FIELD_MESSAGE,
} from '../shared/constants';
import { geolocDataCity, geolocDataStreet } from '../shared/utils/utils';

/**
 * Check for null value
 * @param {number|null|null} value - Value to check
 * @returns parsed value or "Non communiqué"
 */
export const checkForNullValue = (value?: number | null) =>
  value === null ? ' Non communiqué' : ` ${addSpaceToNumber(value ?? 0)} €`;

export const initialPdfLandForm = (
  getCompanyName: () => TokenCompany | null,
  user: User | null,
  type?: PdfType
): IExternalPdfLandForm => {
  return {
    description: '',
    priceStrategyDescription: '',
    addressCity: '',
    addressStreet: '',
    propertyType: type === 'ORPI_LAND' ? '' : 'field',
    landArea: 0,
    lastName: (user?.lastName as string) ?? '',
    firstName: (user?.firstName as string) ?? '',
    email: (user?.email as string) ?? '',
    phone: '',
    companyName: (getCompanyName()?.name as string) ?? '',
    owners: [],
    fence: false,
    drainage: false,
    roadAccess: false,
    servicedLand: false,
    sewerAccess: false,
    gasAndElectricityAccess: false,
    waterAccess: false,
    propertyTax: null,
    annualCharges: null,
    currentLease: false,
    actualRent: null,
    minSellPrice: 0,
    maxSellPrice: 0,
    avgSellPrice: 0,
    estimatedPrice: 0,
    priceComment: '',
    cheaperAds: [],
    equalAds: [],
    higherAds: [],
  };
};

export const priceComparisonsTabs: ComparisonTabTypes = [
  { qtyKey: 'cheaper', label: ' MOINS CHERS' },
  { qtyKey: 'equal', label: ' IDENTIQUES' },
  { qtyKey: 'higher', label: ' PLUS CHERS' },
];

export const landFormSbDatasParser = (
  sbDatas: ISweepbrightData | null,
  geolocDatas: GeolocPointInfo | null,
  theoricCapacity: number | null,
  hookFormMethods: UseFormReturn<IExternalPdfLandForm, any, IExternalPdfLandForm>
) => {
  const { setValue, getValues } = hookFormMethods;

  if (sbDatas) {
    const addr = sbDatas.property?.location?.address;
    if (addr) {
      if (!getValues().addressCity) {
        setValue(
          'addressCity',
          addr.city || addr.postalCode
            ? geolocDataCity(addr)
            : geolocDataCity(geolocDatas)
        );
      }
      if (!getValues().addressStreet) {
        setValue(
          'addressStreet',
          addr.streetName || addr.houseNumber
            ? geolocDataStreet(addr)
            : geolocDataStreet(geolocDatas)
        );
      }
    } else {
      setValue(
        'addressCity',
        geolocDatas?.city ? geolocDataCity(geolocDatas) : geolocDataCity(addr)
      );
      setValue(
        'addressStreet',
        geolocDatas?.streetName
          ? geolocDataStreet(geolocDatas) ?? ''
          : geolocDataStreet(geolocDatas)
      );
    }
    const property = sbDatas.property;
    const userFields = sbDatas.userDefinedFields;
    setValue('estimatedPrice', sbDatas.askingSalePrice);
    setValue('description', sbDatas.description);
    setValue('propertyType', 'field');
    setValue('landArea', property.landArea ?? 0);
    setValue('fence', userFields.fence);
    setValue('drainage', userFields.drainage);
    setValue('roadAccess', userFields.roadAccess);
    setValue('sewerAccess', userFields.sewerAccess);
    setValue('waterAccess', userFields.waterAccess);
    setValue('gasAndElectricityAccess', userFields.gasAndElectricityAccess);
    setValue('propertyTax', userFields.propertyTax);
    setValue('annualCharges', userFields.annualCharges);
    setValue('actualRent', userFields.actualRent);
    setValue('currentLease', userFields.currentLease);
    setValue('owners', userFields.owners);
  } else {
    setValue('addressCity', geolocDataCity(geolocDatas));
    setValue('addressStreet', geolocDataStreet(geolocDatas) ?? '');
    setValue('landArea', theoricCapacity ?? 0);
  }
};
export const validationSchemaPdfLandForm = Yup.object({
  description: Yup.string()
    .max(
      PDF_LAND_MAX_COMMENT_CHARACTERS,
      (value) => `Le commentaire ne doit pas dépasser ${value.max} caractères`
    )
    .default(''),
  addressCity: Yup.string().default(''),
  addressStreet: Yup.string().default(''),
  propertyType: Yup.string().default(''),
  priceStrategyDescription: Yup.string()
    .max(
      PDF_LAND_MAX_PRICE_COMMENT_CHARACTERS,
      (value) => `Le commentaire ne doit pas dépasser ${value.max} caractères`
    )
    .default(''),
  owners: Yup.array()
    .of(Yup.object({ name: Yup.string().required() }))
    .default([]),
  landArea: Yup.number()
    .transform((value) => (isNaN(value) ? null : value))
    .required('La surface est requis')
    .default(0)
    .moreThan(0, 'La surface doit etre supérieure à 0'),
  lastName: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
  firstName: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
  email: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
  phone: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
  companyName: Yup.string()
    .max(MAX_FORM_TEXT_FIELD, MAX_FORM_TEXT_FIELD_MESSAGE)
    .default(''),
  fence: Yup.boolean().default(false),
  drainage: Yup.boolean().default(false),
  roadAccess: Yup.boolean().default(false),
  servicedLand: Yup.boolean().default(false),
  sewerAccess: Yup.boolean().default(false),
  gasAndElectricityAccess: Yup.boolean().default(false),
  waterAccess: Yup.boolean().default(false),
  propertyTax: Yup.number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .default(null),
  annualCharges: Yup.number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .default(null),
  currentLease: Yup.boolean().default(false),
  actualRent: Yup.number()
    .transform((value) => (isNaN(value) ? null : value))
    .nullable()
    .default(null),
  minSellPrice: Yup.number().default(0).transform(transformNumber),
  maxSellPrice: Yup.number().default(0).transform(transformNumber),
  avgSellPrice: Yup.number().default(0).transform(transformNumber),
  estimatedPrice: Yup.number().default(0).transform(transformNumber),
  priceComment: Yup.string()
    .max(
      PDF_LAND_MAX_PRICE_COMMENT_CHARACTERS,
      (value) => `Le commentaire ne doit pas dépasser ${value.max} caractères`
    )
    .default(''),
  cheaperAds: Yup.array().of(Yup.string().default('')).default([]),
  equalAds: Yup.array().of(Yup.string().default('')).default([]),
  higherAds: Yup.array().of(Yup.string().default('')).default([]),
});
