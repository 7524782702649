import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getModalsState, modalsActions } from '../../../features/modals/modalsSlice';
import { getUsersState, userActions } from '../../../features/users/usersSlice';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import SupportEmailLink from '../../Common/SupportEmailLink';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../GenericModal';

function AddUserResultModal() {
  const { apiStatus } = useAppSelector(getUsersState);
  const { addUserResult } = useAppSelector(getModalsState);
  const dispatch = useAppDispatch();

  const ERROR = apiStatus === APIStatus.REJECTED;

  const handleCloseModal = () => {
    if (ERROR) {
      dispatch(userActions.resetApiError());
    }
    dispatch(userActions.setEditingUser(false));
    dispatch(
      modalsActions.addUserResult({
        isEdit: false,
        isOpen: false,
      })
    );
  };

  return (
    <>
      <GenericModal
        closeModal={handleCloseModal}
        modalType={ERROR ? GenericModalEnum.ERROR : GenericModalEnum.CONFIRMATION}
        actionType={GenericModalActionEnum.OK}
        okButtonContent="OK"
        okCallback={handleCloseModal}
        title={`${addUserResult.isEdit ? 'Modifier' : 'Ajouter'} un utilisateur`}
      >
        {!ERROR ? (
          <>
            <p>
              L&#039;utilisateur a été {addUserResult.isEdit ? 'modifié' : 'créé'}{' '}
              avec succès.
            </p>
          </>
        ) : (
          <>
            <p>
              Une erreur est survenue et l&#039;utilisateur n&#039;a pas pu être
              {addUserResult.isEdit
                ? ' modifié.'
                : ' créé. Il se peut que cette adresse email soit déjà utilisée.'}
            </p>
            <p>
              Veuillez réessayer ultérieurement ou contacter notre support{' '}
              <SupportEmailLink />
            </p>
          </>
        )}
      </GenericModal>
    </>
  );
}

export default AddUserResultModal;
