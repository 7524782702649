import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { addrServProcessAsync } from '../../../utils/addrServProcessAsync';

export async function ownerPlotsToCreatePlotStudiesParser(
  plots: OwnerDataPlots | null
): Promise<Omit<ICreatePlotStudy, 'folder' | 'responsable'>[]> {
  try {
    if (!plots) {
      return Promise.reject(paramsNotFound('ownerPlotsToCreatePlotStudiesParser'));
    }

    const parsed = await Promise.all(
      plots.map((m) => ownerPlotToCreatePlotStudyParser(m))
    );

    return parsed;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function ownerPlotToCreatePlotStudyParser(
  plot: OwnerDataPlot
): Promise<Omit<ICreatePlotStudy, 'folder' | 'responsable'>> {
  const location = await addrServProcessAsync(plot.lat, plot.lng);
  try {
    const cps: Omit<ICreatePlotStudy, 'folder' | 'responsable'> = {
      city: plot.city,
      fullPlotId: plot.fullPlotId,
      inseeCode: plot.inseeCode,
      lat: plot.lat,
      lng: plot.lng,
      area: plot.theoricCapacity,
      zone: null,
      zoneChild: null,
      address: location?.address ?? '',
      postalCode: location?.postalCode ?? '',
    };

    return cps;
  } catch (error) {
    return Promise.reject(error);
  }
}
