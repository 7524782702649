import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchProspecGetProspecCityId from './requests/fetchProspecGetProspecCityId';

const fetchProspecCityIdThunk = createAsyncThunk(
  'externalPdfErrial/fetchProspecCityIdThunk',
  async (params: Pick<GeolocPointInfo, 'inseeCode' | 'postalCode' | 'city'>) => {
    try {
      const { city, inseeCode, postalCode } = params;

      // get prospec city id
      const prospecCityId = await fetchProspecGetProspecCityId({
        city,
        insee: inseeCode,
        postalCode,
      });

      return prospecCityId;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchProspecCityIdThunk;
