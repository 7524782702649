import { useContext } from 'react';
import createCtx from '../../../App/contexts/GenenricStateContext';

const [ctx, Provider] = createCtx<string | null>(null);

function AccordionContextProvider({ children }: IChildren) {
  return <Provider>{children}</Provider>;
}

function useAccordionContext() {
  const context = useContext(ctx);

  if (!context) {
    throw new Error('useAccordeonContext must be used within a Provider');
  }

  return context;
}

export { AccordionContextProvider, useAccordionContext };
