import { deleteEntity } from '../../../services/axiosFiles/genericCrud';

async function deleteStudyProcedure(idIri: string | null) {
  try {
    if (!idIri) throw new Error('no procedure idIri found');

    await deleteEntity(idIri);

    return idIri;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default deleteStudyProcedure;
