import * as Cesium from 'cesium';

/**
 * Create handlers to Cesium Viewer
 * @param {Cesium.Viewer} viewer - viewer 3D cesium
 * @param {(values: StreetViewLatLngType)} callback - function with clic values (lng, lat, height)
 * @returns {void}
 */
async function createCesiumClickHandler(
  viewer: Cesium.Viewer,
  leftClickCallback: (viewer: Cesium.Viewer, pos: StreetViewLatLngType) => void
) {
  return new Promise((resolve, reject) => {
    try {
      const handler = viewer.screenSpaceEventHandler;

      // remove double click input action handler
      handler.removeInputAction(Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK);

      // left click handler
      handler.setInputAction((e: any) => {
        const scene = viewer.scene;
        const ellipsoid = scene.globe.ellipsoid;

        // Get the picked position in cartesian coordinates
        const pickedPosition = viewer.scene.pickPosition(e.position);
        if (Cesium.defined(pickedPosition)) {
          // Convert cartesian to geographic coordinates
          const cartographic = ellipsoid.cartesianToCartographic(pickedPosition);

          // Get longitude, latitude, and height
          const lng = Cesium.Math.toDegrees(cartographic.longitude);
          const lat = Cesium.Math.toDegrees(cartographic.latitude);
          const height = cartographic.height;

          leftClickCallback(viewer, { lng, lat, height });
        }
        // your code here
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  });
}

export default createCesiumClickHandler;
