import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { isEmpty } from 'lodash';
import { getUrbaGptState } from './urbaGptSlice';
import { urbaGptHistoryThunk } from './urbaGptThunk';
import { getAuthState } from '../auth/authSlice';

export default function useUrbaGpt() {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector(getAuthState);
  const { conversation } = useAppSelector(getUrbaGptState);

  useEffect(() => {
    if (isEmpty(conversation.result) && userId) {
      dispatch(urbaGptHistoryThunk());
    }
  }, [userId]);

  return {
    conversation,
  };
}
