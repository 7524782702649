import { postEntityNew } from '../../../../../../services/axiosFiles/genericCrud';
import pricehubbleGenericParser from '../../../../../../shared/parsers/pricehubbleGenericParser';
import { phDealParser } from '../../../../landPDF/orpiLandDataParser';

async function fetchFolderDatas({ entityId, phAccessToken }: IPHTokenAndPHId) {
  try {
    if (!entityId) throw new Error('ph folder id missing');
    if (!phAccessToken) throw new Error('phaccess token missing');

    const body = { dossierId: entityId, ph_access_token: phAccessToken };
    const result: any = await postEntityNew({
      endpoint: '/pdf_urbanease/pricehubble_get_folder_datas',
      body,
    });

    if (result.ph_status_code < 400) {
      const phPreParser = pricehubbleGenericParser(result);
      const parsed = phDealParser(phPreParser);

      return parsed;
    } else {
      throw new Error('ph folder datas load error');
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchFolderDatas;
