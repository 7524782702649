import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { getAppState } from '../../../../App/appSlice';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import TableRowFullColumn from '../../../../components/Common/TableRowFullColumn';
import IndividualCircularLoader from '../../../loaders/individualCircularLoader';

import '../../../../components/Dashboard/DirectoryTab/DirectoryTab.scss';
import entityListFromNestedEntities from '../../../../utils/entityListFromNestedEntities';
import { getContactsState } from '../../../contacts/contactsSlice';
import fetchContactPlotStudiesThunk from '../../../contacts/services/thunks/fetchContactPlotStudiesThunk';
import FilterSortBloc from '../../../../components/filterSortBloc';
import { folderPlotStudiesForSelect } from '../../../folders/dashboard/datas';
import useFolder from '../../../folders/useFolder';
import { getUsersState } from '../../../users/usersSlice';
import ContactPlotStudiesContainer from './ContactPlotStudiesContainer';
import { plotStudiesHeaderCell } from './datas';

interface IContactPlotStudyContent {
  contactIdIri: string;
}

function ContactPlotStudiesContent({ contactIdIri }: IContactPlotStudyContent) {
  const { contactPlotStudies } = useAppSelector(getContactsState);
  const [sortKey, setSortKey] = useState<string>('');
  const [order, setOrder] = useState<Order>('asc');
  const { users } = useAppSelector(getUsersState);
  const { plotStudyStatuses } = useAppSelector(getAppState);
  const { folders } = useFolder({});
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (users && plotStudyStatuses.result && folders && contactIdIri) {
      dispatch(
        fetchContactPlotStudiesThunk({
          urlBase: contactIdIri,
          isContactPlotStudies: true,
          users,
          statuses: plotStudyStatuses.result,
          folders: entityListFromNestedEntities(folders) as Folders,
        })
      );
    }
  }, [folders, plotStudyStatuses.result, contactIdIri, users]);

  return (
    <>
      {!contactPlotStudies.result ? (
        <TableRowFullColumn colSpan={6}>
          <IndividualCircularLoader size={50} className="loader" />
        </TableRowFullColumn>
      ) : isEmpty(contactPlotStudies.result) ? (
        <TableRowFullColumn colSpan={6}>
          <p className="no-plot-studies p-max">Aucune étude liée à ce contact</p>
        </TableRowFullColumn>
      ) : (
        <>
          <TableRowFullColumn colSpan={6} className="engine-row">
            <FilterSortBloc
              setSortKey={setSortKey}
              setOrder={setOrder}
              selectItems={folderPlotStudiesForSelect}
              selectPlaceHolder="Trier parcelles par"
            />
          </TableRowFullColumn>

          <TableRowFullColumn colSpan={6} className="content-table-row">
            <Table size="small">
              <TableHead>
                <TableRow>
                  {plotStudiesHeaderCell.map((elt) => (
                    <TableCell key={nanoid()}>{elt.title}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <ContactPlotStudiesContainer
                contactPlotStudies={contactPlotStudies.result ?? []}
                order={order}
                sortKey={sortKey}
              />
            </Table>
          </TableRowFullColumn>
        </>
      )}
    </>
  );
}

export default memo(ContactPlotStudiesContent);
