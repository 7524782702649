import { InputLabelProps, TextField } from '@mui/material';
import { FocusEvent, memo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';
import GenericTooltip from '../../../shared/components/tooltips/GenericTooltip';
import styles from './formComponents.module.scss';

interface IFieldInputFormCustom {
  name: string;
  onChange?: ChangeEventCustom;
  label?: nullable<string>;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  className?: string;
  readOnly?: boolean;
  required?: boolean;
  InputProps?: any;
  InputLabelProps?: Partial<InputLabelProps>;
  onFocus?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  errorTooltip?: boolean;
  dataCy?: string;
}
function TextFieldFormCustom({
  name,
  label,
  disabled,
  type = 'text',
  className,
  readOnly = false,
  onChange,
  onFocus,
  onBlur,
  required,
  placeholder,
  InputProps,
  InputLabelProps,
  errorTooltip = false,
  dataCy,
}: IFieldInputFormCustom) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const handleChange = (
    e: ChangeEventCustom,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    switch (type) {
      case 'number':
        field.onChange(parseFloat(e.target.value));
        break;
      case 'text':
      default:
        field.onChange(e.target.value);
        break;
    }

    onChange?.(e);
  };

  const handleFocus = (
    e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    e.target.select();
    if (onFocus) {
      onFocus(e);
    }
  };

  return (
    <GenericTooltip
      text={errorTooltip ? (errors[name]?.message as string) : ''}
      bgColor="ERROR"
      isOpen
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type={type}
            variant="outlined"
            disabled={disabled}
            label={label}
            value={field.value ?? ''}
            error={Boolean(errors[name])}
            helperText={!errorTooltip && (errors[name]?.message as string)}
            placeholder={
              placeholder ? `${placeholder}${required ? '*' : ''}` : undefined
            }
            className={`${className} ${errors[name] ? styles.error : ''}`}
            onFocus={handleFocus}
            onBlur={onBlur}
            InputProps={{ readOnly, ...InputProps }}
            InputLabelProps={InputLabelProps}
            onChange={(e) => handleChange(e, field)}
            required={required}
            data-cy={dataCy}
            autoComplete="new-password"
          />
        )}
      />
    </GenericTooltip>
  );
}

export default memo(TextFieldFormCustom);
