import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../App/store';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { getDpeState } from '../../builtCaracteristicsSlice';
import styles from '../dpe.module.scss';
import DpeAdvancedSearch from './DpeAdvancedSearch';
import DpeDisplayCheckbox from './DpeDisplayCheckbox';
import DpeLegendContainer from './DpeLegendContainer';
import DpeLoaderMessage from './DpeLoaderMessage';

const MIN_DPE_POINTS_FOR_MESSAGE = 20000;
function DpeMarkerLegend() {
  const { dpePoints, dpeAdvancedSearchPoints, generateDisplay } =
    useAppSelector(getDpeState);
  const [loader, setloader] = useState(false);

  useEffect(() => {
    if (!generateDisplay) {
      setloader(false);
    }
  }, [generateDisplay]);

  if (dpePoints.apiStatus === APIStatus.PENDING) {
    return <DpeLoaderMessage />;
  }

  if (
    dpePoints.apiStatus === APIStatus.REJECTED ||
    dpeAdvancedSearchPoints.apiStatus === APIStatus.REJECTED
  ) {
    return (
      <DpeLegendContainer className={`${styles.dpeLegend} ${styles.center}`}>
        <p className={styles.loadError}>Erreur lors du chargement des DPE</p>
      </DpeLegendContainer>
    );
  }

  if (
    (loader && (dpePoints.result?.length ?? 0) > MIN_DPE_POINTS_FOR_MESSAGE) ||
    (loader &&
      (dpeAdvancedSearchPoints.result?.length ?? 0) > MIN_DPE_POINTS_FOR_MESSAGE)
  ) {
    return (
      <DpeLegendContainer className={`${styles.dpeLegend} ${styles.center}`}>
        <div>
          <h4>Calcul de l&#0039;affichage ...</h4>
          <h5>
            Nous cartographions toute la donnée <br />
            pour cette commune.
          </h5>
        </div>
      </DpeLegendContainer>
    );
  }

  return (
    <DpeLegendContainer className={styles.dpeLegend}>
      <DpeDisplayCheckbox setloader={setloader} />
      <hr />
      <DpeAdvancedSearch setloader={setloader} />
    </DpeLegendContainer>
  );
}

export default DpeMarkerLegend;
