import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { loadersActions } from '../../../loaders/loaderSlice';
import fetchFolderAndSubsPlotStudiesForDisplay from '../fetchFolderAndSubsPlotStudiesForDisplay';

export const fetchFolderAndSubsPlotStudiesForDisplayThunk = createAsyncThunk(
  'displayManager/fetchFolderAndSubsPlotStudiesForDisplayThunk',
  async (params: { folder: IFolder | null }, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const { users } = state.users;
      const { plotStudyStatuses } = state.app;
      const { companyId } = state.company;

      if (params.folder) {
        dispatch(loadersActions.loaderShow());
        const plotStudiesTab = await fetchFolderAndSubsPlotStudiesForDisplay(
          params.folder,
          users ?? [],
          plotStudyStatuses.result ?? [],
          companyId
        );
        dispatch(loadersActions.loaderHide());
        return plotStudiesTab;
      } else {
        dispatch(loadersActions.loaderHide());
        throw new Error('folder param missing');
      }
    } catch (error) {
      dispatch(loadersActions.loaderHide());
      return Promise.reject(error);
    }
  }
);
