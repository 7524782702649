function genericObjectSort<T>(
  array: T[],
  key: keyof T,
  sort: 'asc' | 'desc' = 'asc'
): T[] {
  const newArray = [...array];
  newArray.sort((a: T, b: T) => {
    if (sort === 'asc') {
      if (a[key] < b[key]) {
        return -1;
      } else if (a[key] > a[key]) {
        return 1;
      } else {
        return 0;
      }
    } else {
      if (a[key] > b[key]) {
        return -1;
      } else if (a[key] < a[key]) {
        return 1;
      } else {
        return 0;
      }
    }
  });

  return newArray;
}

export default genericObjectSort;
