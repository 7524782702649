import { Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { pageStyles } from '../../shared/styles/pdfStyle';
import AccessibilityCard from './components/AccessibilityCard';
import Footer from './components/Footer';
import Header from './components/Header';
import BicycleSvg from './components/svg/BicycleSvg';
import CarSvg from './components/svg/CarSvg';
import PublicTransportSvg from './components/svg/PublicTransportSvg';
import WalkSvg from './components/svg/WalkSvg';
import { fontStyles } from './styles/pdfStyles';

const styles = StyleSheet.create({
  title: { fontSize: '16px' },
  accessibilityBloc: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
});

interface IProps {
  accessibilities: (PDFLandAccessibility | null)[];
  transport: (PDFLandTransport | null)[];
  agencyDatas: PHAgencyAndAgentDatas | null;
  formDatas: IExternalPdfLandForm;
}

function AccessibilityPage({
  accessibilities,
  transport,
  agencyDatas,
  formDatas,
}: IProps) {
  return (
    <Page size="A4" style={pageStyles.page}>
      <Header lines={['Votre bien', '... et son environnement.']} />

      <Text style={[fontStyles.b700, fontStyles.fs20, fontStyles.cOrpi]}>
        Accessibilité
      </Text>

      <View style={styles.accessibilityBloc}>
        <AccessibilityCard
          title="Voiture"
          titleSvg={<CarSvg />}
          data={accessibilities[0]}
          transports={transport}
        />
        <AccessibilityCard
          title="Transport public"
          titleSvg={<PublicTransportSvg />}
          data={accessibilities[1]}
          transports={transport}
        />
      </View>
      <View style={styles.accessibilityBloc}>
        <AccessibilityCard
          title="À pied"
          titleSvg={<WalkSvg />}
          data={accessibilities[2]}
          transports={transport}
        />
        <AccessibilityCard
          title="Vélo"
          titleSvg={<BicycleSvg />}
          data={accessibilities[3]}
          transports={transport}
        />
      </View>

      <Footer agency={agencyDatas?.agency ?? null} formDatas={formDatas} />
    </Page>
  );
}

export default AccessibilityPage;
