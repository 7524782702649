import styles from './genericTab.module.scss';

interface IAddTabProps {
  onAddTab?: () => void;
}
function AddTab({ onAddTab }: IAddTabProps) {
  return (
    <>
      <div className={styles.addTab} onClick={onAddTab}>
        <div>+</div>
      </div>
    </>
  );
}

export default AddTab;
