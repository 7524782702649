import { fetchDataById } from '../../../services/axiosFiles/genericCrud';
import { studyMatrixApiToStoreParser } from '../utils/parsers/studyMatrixParser';

export const fetchFeasibilityMatrix = async (idIri: string | null) => {
  try {
    if (idIri) {
      const matrix = await fetchDataById(idIri);

      const parsedStudy = studyMatrixApiToStoreParser(matrix);

      return parsedStudy;
    } else {
      return Promise.resolve(null);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
