import { exportExcel } from '../../../utils/exportExcel';

const worksheetColumns = [
  { header: 'Ville', key: 'city' },
  { header: 'Numéro de parcelle', key: 'fullPlotId' },
  { header: 'Taille parcelle', key: 'area' },
  { header: 'Zonage PLU', key: 'zone' },
  { header: 'Zonage PLU enfant', key: 'zoneChild' },
  { header: 'Responsable', key: 'responsableName' },
  { header: 'Etat', key: 'status' },
  { header: 'Adresse postale', key: 'address' },
];

export const handleExportExcel = (folderName: string, items: PlotStudies) => {
  try {
    if (items) {
      const rows = items.map((m) => {
        return {
          ...m,
          zone: m.zone ?? ' - ',
          zoneChild: m.zoneChild ?? ' - ',
          responsableName: m.responsable
            ? m.responsable.lastName || m.responsable.firstName
              ? `${m.responsable.firstName ?? ''} ${m.responsable.lastName ?? ''}`
              : ' - '
            : ' - ',
          status: m.status ? m.status?.label ?? 'Inconnu' : ' - ',
        };
      });

      exportExcel(folderName ?? 'indefini', worksheetColumns, rows);
    } else {
      throw new Error('parametres à éditer manquants');
    }
  } catch (error) {
    throw new Error("erreur d'édition");
  }
};
