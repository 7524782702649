import { useAppSelector } from '../../../../../App/store';
import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';
import { MAILSHOT_MAX_CONTACT_EDITION_COUNT } from '../../../../../shared/constants';
import { getStudyState } from '../../../../study/slices/studySlice';
import useMailshotsSelection from '../../../hooks/useMailshotsSelection';
import styles from '../../../styles/mailshotsModal.module.scss';
import { MailshotsSelectionTypeEnum } from '../../../types/mailshotsEnums';
import MailshotContactTable from './MailshotContactTable';

function MailshotFolderContactList() {
  const { study } = useAppSelector(getStudyState);
  const { selectType, folderContactsCount, outofRangeContactEdit } =
    useMailshotsSelection();

  return (
    <div className={styles.mailshotFolderContactList}>
      <h4>
        <span>Contacts du sous-dossier</span>{' '}
        <span>&quot;{(study.result as IFolderStudy)?.name}&quot;</span>
        {selectType ===
          MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER && (
          <GenericTooltip
            text={
              outofRangeContactEdit
                ? "Supprimez des parcelles afin de valider l'édition"
                : ''
            }
          >
            <span className={outofRangeContactEdit ? styles.outOfRange : ''}>
              &nbsp;
              {`(${folderContactsCount}/${MAILSHOT_MAX_CONTACT_EDITION_COUNT})`}
            </span>
          </GenericTooltip>
        )}
      </h4>
      <MailshotContactTable />
    </div>
  );
}

export default MailshotFolderContactList;
