import { G, Path, Svg } from '@react-pdf/renderer';

function UrbaLogoWhite({
  width = '99',
  height = '61',
}: {
  width?: string;
  height?: string;
}) {
  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 99 61"
      preserveAspectRatio="xMidYMid"
    >
      <G clip-path="url(#clip0_611_22)">
        <Path
          d="M21.2944 44.3787L19.0861 43.4961L21.2944 42.6075L21.4405 43.0058L20.2253 43.4961L21.4405 43.9803L21.2944 44.3787Z"
          fill="white"
        />
        <Path
          d="M93.3741 41.872L90.0966 40.5482L90.2427 40.1498L93.5201 41.4736L93.3741 41.872ZM24.7179 41.6881L24.5718 41.2898L27.8492 39.9721L27.9953 40.3705L24.7179 41.6881ZM86.8251 39.2305L83.5477 37.9067L83.6937 37.5083L86.9711 38.8321L86.8251 39.2305ZM31.2668 39.0528L31.1208 38.6544L34.3982 37.3367L34.5442 37.7351L31.2668 39.0528ZM80.2703 36.5829L76.9928 35.259L77.1389 34.8607L80.4163 36.1845L80.2703 36.5829ZM37.8217 36.4174L37.6756 36.019L40.953 34.7013L41.0991 35.0997L37.8217 36.4174ZM73.7213 33.9413L70.4439 32.6175L70.5899 32.2191L73.8673 33.543L73.7213 33.9413ZM44.3765 33.7759L44.2304 33.3775L47.5078 32.0598L47.6539 32.4582L44.3765 33.7759ZM67.1665 31.2998L63.889 29.976L64.0351 29.5776L67.3125 30.9014L67.1665 31.2998ZM50.9313 31.1405L50.7852 30.7421L54.0627 29.4244L54.2087 29.8228L50.9313 31.1405ZM60.6175 28.6522L58.8649 27.9474L57.4861 28.5051L57.3401 28.1067L58.8649 27.4938L60.7635 28.2599L60.6175 28.6522Z"
          fill="white"
        />
        <Path
          d="M96.7917 44.5625L96.6515 44.1642L97.8608 43.68L96.6515 43.1958L96.7975 42.7975L99 43.6861L96.7917 44.5625Z"
          fill="white"
        />
        <Path
          d="M59.2212 59.5046L57.3225 58.7508L57.4686 58.3524L59.2212 59.0511L60.6 58.4995L60.746 58.8978L59.2212 59.5046ZM64.0234 57.5924L63.8774 57.194L67.1548 55.8886L67.3008 56.287L64.0234 57.5924ZM54.051 57.4392L50.7736 56.1338L50.9196 55.7354L54.197 57.0408L54.051 57.4392ZM70.5782 54.9877L70.4322 54.5893L73.7096 53.2839L73.8556 53.6822L70.5782 54.9877ZM47.502 54.8283L44.2246 53.5229L44.3706 53.1245L47.648 54.4299L47.502 54.8283ZM77.1331 52.3829L76.987 51.9846L80.2644 50.6791L80.4105 51.0775L77.1331 52.3829ZM40.9472 52.2174L37.6698 50.912L37.8158 50.5136L41.0932 51.8191L40.9472 52.2174ZM83.6879 49.7782L83.5418 49.3737L86.8192 48.0682L86.9653 48.4666L83.6879 49.7782ZM34.3982 49.6004L31.1208 48.295L31.2668 47.8966L34.5442 49.2021L34.3982 49.6004ZM90.2427 47.1673L90.0966 46.7689L93.3741 45.4635L93.5201 45.8619L90.2427 47.1673ZM27.8434 46.9896L24.566 45.6841L24.712 45.2858L27.9894 46.5912L27.8434 46.9896Z"
          fill="white"
        />
        <Path
          d="M79.4056 42.5952L40.5324 27.0648C40.2052 26.9361 39.8489 26.9361 39.5217 27.0648L0.911366 42.5891C0.356367 42.8158 0 43.3613 0 43.9803C0 44.6054 0.362209 45.1509 0.911366 45.3715C40.03 60.9939 0.554996 45.2306 39.7846 60.9019C39.9482 60.9693 40.1176 61 40.2929 61C40.4623 61 40.6376 60.9632 40.8011 60.9019C79.6919 45.2673 40.5558 61 79.4115 45.3777C79.9665 45.157 80.3229 44.6054 80.3229 43.9864C80.3229 43.3613 79.9606 42.8158 79.4056 42.5952ZM40.2929 59.2901C11.6316 47.8476 28.673 54.6505 1.9571 43.9803L40.0241 28.6767L78.3541 43.9864C47.2333 56.5014 58.9992 51.77 40.2929 59.2901Z"
          fill="white"
        />
        <Path
          d="M86.124 31.6553L53.4434 18.6009C53.2798 19.1648 53.0695 19.6857 52.8125 20.1576L85.0783 33.0465C53.9517 45.5615 65.7176 40.8301 47.0113 48.3501C18.3442 36.9077 35.3855 43.7106 8.6755 33.0404L40.0124 20.4396C39.7262 19.9799 39.4867 19.4712 39.3056 18.9135L7.62392 31.6491C7.06892 31.8698 6.71255 32.4214 6.71255 33.0404C6.71255 33.6655 7.07476 34.211 7.62392 34.4316C46.7367 50.0539 7.26755 34.2907 46.4972 49.9559C46.6607 50.0233 46.8302 50.0539 47.0054 50.0539C47.1748 50.0539 47.3501 50.0172 47.5137 49.9559C86.4045 34.3213 47.2683 50.0539 86.124 34.4316C86.679 34.211 87.0354 33.6594 87.0354 33.0404C87.0354 32.4214 86.6732 31.8759 86.124 31.6553Z"
          fill="white"
        />
        <Path
          d="M46.3628 0C46.1875 0 46.0064 0.00612881 45.8312 0.0122576C37.6114 0.312569 31.1851 7.55069 31.4772 16.1739C31.5064 16.9707 31.5882 17.7674 31.7342 18.5519C31.7634 18.7419 31.7985 18.9258 31.8452 19.1096C32.0731 20.1638 32.4002 21.1873 32.8325 22.1679C34.3281 25.8881 38.365 33.9107 45.4748 40.0824C46.0765 40.6095 46.6666 40.6095 47.1807 40.1253C52.754 34.352 58.0353 26.5132 59.8872 22.1985C60.3312 21.2057 60.6642 20.1638 60.8921 19.0974C60.9622 18.7848 60.9914 18.6009 60.9914 18.6009C61.1725 17.6203 61.2601 16.6213 61.2601 15.6285C61.2543 6.99297 54.5885 0 46.3628 0ZM46.3278 23.4427C42.2149 23.4427 38.8791 19.9431 38.8791 15.6285C38.8791 11.3138 42.2149 7.81423 46.3278 7.81423C50.4406 7.81423 53.7764 11.3138 53.7764 15.6285C53.7764 19.9431 50.4406 23.4427 46.3278 23.4427Z"
          fill="white"
        />
        <Path
          d="M47.2412 19.1552C49.1346 18.6861 50.3071 16.6956 49.86 14.7093C49.4129 12.7229 47.5156 11.4929 45.6221 11.9619C43.7287 12.431 42.5562 14.4215 43.0033 16.4078C43.4504 18.3942 45.3478 19.6242 47.2412 19.1552Z"
          fill="white"
        />
      </G>
    </Svg>
  );
}

export default UrbaLogoWhite;
