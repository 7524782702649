import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../App/store';

import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import createStudyContactThunk from '../services/thunks/createStudyContactThunk';
import createStudyProceduresFromMailshotsThunk from '../services/thunks/createStudyProceduresFromMailshotsThunk';
import createStudyProcedureThunk from '../services/thunks/createStudyProcedureThunk';
import deleteStudyContactThunk from '../services/thunks/deleteStudyContactThunk';
import deleteStudyProcedureThunk from '../services/thunks/deleteStudyProcedureThunk';
import fetchStudyContactsThunk from '../services/thunks/fetchStudyContactsThunk';
import fetchStudyProceduresThunk from '../services/thunks/fetchStudyProceduresThunk';
import {
  constructStudyDatasThunk,
  createPlotStudyThunk,
  deleteStudyThunk,
  fetchStudyThunk,
  updateStudyThunk,
} from '../services/thunks/studyThunks';
import updateStudyContactThunk from '../services/thunks/updateStudyContactThunk';
import {
  RecursiveSubFolderStatusEnum,
  RecursiveSubFolderUpdatedElement,
} from '../utils/studyEnums';

const initialState: StudyState = {
  study: { apiStatus: APIStatus.IDLE, result: null },
  studyPlotStudies: { apiStatus: APIStatus.IDLE, result: null },
  studyProcedures: { apiStatus: APIStatus.IDLE, result: null },
  studyContacts: { apiStatus: APIStatus.IDLE, result: null },
  studyContactToUpdate: null,
  studyPlotStudiesDisplay: false,
  updateFolder: {
    updatedElement: RecursiveSubFolderUpdatedElement.NULL,
    recursiveSubFolderUpdateStatus: RecursiveSubFolderStatusEnum.NULL,
  },
  actionState: { status: APIStatus.IDLE, error: null },
};

const studySlice = createSlice({
  name: 'study',
  initialState,
  reducers: {
    studySet: (state, action: PayloadAction<IStudy>) => {
      state.study.result = action.payload;
    },
    studyPlotStudiesDisplay: (state, action: PayloadAction<boolean>) => {
      state.studyPlotStudiesDisplay = action.payload;
    },
    setStudyContactToUpdate: (
      state,
      action: PayloadAction<IStudyContact | null>
    ) => {
      state.studyContactToUpdate = action.payload;
    },
    updateContactInStudyContacts: (state, action: PayloadAction<IStudyContact>) => {
      state.studyContacts.result =
        state.studyContacts.result?.map((m) =>
          m.idIri === action.payload.idIri ? action.payload : m
        ) ?? null;
    },
    setRecursiveSubFolderUpdateStatus: (
      state,
      action: PayloadAction<RecursiveSubFolderStatusEnum>
    ) => {
      state.updateFolder.recursiveSubFolderUpdateStatus = action.payload;
    },
    setRecursiveSubFolderUpdatedElement: (
      state,
      action: PayloadAction<RecursiveSubFolderUpdatedElement>
    ) => {
      state.updateFolder.updatedElement = action.payload;
    },
    updateContact: (state, action: PayloadAction<Contact>) => {
      if (state.studyContacts.result) {
        state.studyContacts.result = state.studyContacts.result.map((m) =>
          m.contact.idIri === action.payload.idIri
            ? { ...m, contact: action.payload }
            : m
        );
      }
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    // construct study datas
    builder.addCase(constructStudyDatasThunk.pending, (state) => {
      state.study.apiStatus = APIStatus.PENDING;
      state.study.result = null;
    });
    builder.addCase(
      constructStudyDatasThunk.fulfilled,
      (state, action: PayloadAction<ConstructStudyRequestResult>) => {
        const ap = action.payload;
        state.study.apiStatus = APIStatus.IDLE;
        state.study.result = ap.study;
        state.studyPlotStudies.result = ap.studyPlotStudies;
      }
    );
    builder.addCase(
      constructStudyDatasThunk.rejected,
      (state, action: PayloadAction<any>) => {
        state.study.apiStatus = APIStatus.REJECTED;
        state.study.result = null;
        state.study.error = action.payload;
      }
    );
    // fetch study
    builder
      .addCase(fetchStudyThunk.pending, (state) => {
        state.study.apiStatus = APIStatus.PENDING;
        state.study.result = null;
        state.study.error = undefined;
      })
      .addCase(fetchStudyThunk.fulfilled, (state, action: PayloadAction<IStudy>) => {
        state.study.apiStatus = APIStatus.IDLE;
        state.study.result = action.payload;
        state.study.error = undefined;
      })
      .addCase(fetchStudyThunk.rejected, (state, action: PayloadAction<any>) => {
        state.study.apiStatus = APIStatus.REJECTED;
        state.study.result = null;
        state.study.error = action.payload;
      });
    // create plot study
    builder
      .addCase(createPlotStudyThunk.pending, (state) => {
        state.study.apiStatus = APIStatus.PENDING;
        state.study.result = null;
        state.study.error = undefined;
      })
      .addCase(
        createPlotStudyThunk.fulfilled,
        (state, action: PayloadAction<IStudy>) => {
          state.study.apiStatus = APIStatus.IDLE;
          state.study.result = action.payload;
          state.study.error = undefined;
        }
      )
      .addCase(
        createPlotStudyThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.study.apiStatus = APIStatus.REJECTED;
          state.study.result = null;
          state.study.error = action.payload;
        }
      );
    // update plot study
    builder
      .addCase(updateStudyThunk.pending, (state) => {
        state.study.apiStatus = APIStatus.PENDING;
        state.study.error = undefined;
      })
      .addCase(
        updateStudyThunk.fulfilled,
        (state, action: PayloadAction<IStudy | null>) => {
          state.study.apiStatus = APIStatus.IDLE;
          state.study.result = action.payload ?? state.study.result;
          state.updateFolder = {
            recursiveSubFolderUpdateStatus: RecursiveSubFolderStatusEnum.NULL,
            updatedElement: RecursiveSubFolderUpdatedElement.NULL,
          };
          state.study.error = undefined;
        }
      )
      .addCase(updateStudyThunk.rejected, (state, action: PayloadAction<any>) => {
        state.study.apiStatus = APIStatus.REJECTED;
        state.study.error = action.payload;
      });
    // delete study
    builder
      .addCase(deleteStudyThunk.pending, (state) => {
        state.study.apiStatus = APIStatus.PENDING;
        state.study.error = undefined;
      })
      .addCase(deleteStudyThunk.fulfilled, (state) => {
        state.study.apiStatus = APIStatus.IDLE;
        state.study.result = null;
        state.study.error = undefined;
      })
      .addCase(deleteStudyThunk.rejected, (state, action: PayloadAction<any>) => {
        state.study.apiStatus = APIStatus.REJECTED;
        state.study.error = action.payload;
      });
    // ******************************************
    // Study procedures extra reducers
    // ******************************************
    // fetch study procedures
    builder
      .addCase(fetchStudyProceduresThunk.pending, (state) => {
        state.studyProcedures.apiStatus = APIStatus.PENDING;
        state.studyProcedures.result = null;
        state.studyProcedures.error = undefined;
      })
      .addCase(
        fetchStudyProceduresThunk.fulfilled,
        (state, action: PayloadAction<StudyProcedures>) => {
          state.studyProcedures.apiStatus = APIStatus.IDLE;
          state.studyProcedures.result = action.payload;
          state.studyProcedures.error = undefined;
        }
      )
      .addCase(fetchStudyProceduresThunk.rejected, (state, action) => {
        state.studyProcedures.apiStatus = APIStatus.REJECTED;
        // if null, risk to infinite fetch
        state.studyProcedures.result = [];
        state.studyProcedures.error = action.error;
      });
    // create study procedure
    builder
      .addCase(createStudyProcedureThunk.pending, (state) => {
        state.studyProcedures.apiStatus = APIStatus.PENDING;
        state.studyProcedures.error = undefined;
      })
      .addCase(
        createStudyProcedureThunk.fulfilled,
        (state, action: PayloadAction<IStudyProcedure>) => {
          state.studyProcedures.apiStatus = APIStatus.IDLE;
          state.studyProcedures.result = state.studyProcedures.result?.concat(
            action.payload
          ) ?? [action.payload];
          state.studyProcedures.error = undefined;
        }
      )
      .addCase(createStudyProcedureThunk.rejected, (state, action) => {
        state.studyProcedures.apiStatus = APIStatus.REJECTED;
        state.studyProcedures.error = action.error;
      });
    // create multiple procedures from mailshots
    builder
      .addCase(createStudyProceduresFromMailshotsThunk.pending, (state) => {
        state.studyProcedures.apiStatus = APIStatus.PENDING;
        state.studyProcedures.error = undefined;
      })
      .addCase(
        createStudyProceduresFromMailshotsThunk.fulfilled,
        (state, action: PayloadAction<StudyProcedures>) => {
          state.studyProcedures.apiStatus = APIStatus.IDLE;
          state.studyProcedures.result =
            state.studyProcedures.result?.concat(action.payload) ?? action.payload;

          state.studyProcedures.error = undefined;
        }
      )
      .addCase(createStudyProceduresFromMailshotsThunk.rejected, (state, action) => {
        state.studyProcedures.apiStatus = APIStatus.REJECTED;
        state.studyProcedures.error = action.error;
      });
    // delete study procedure
    builder
      .addCase(deleteStudyProcedureThunk.pending, (state) => {
        state.studyProcedures.apiStatus = APIStatus.PENDING;
        state.studyProcedures.error = undefined;
      })
      .addCase(
        deleteStudyProcedureThunk.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.studyProcedures.apiStatus = APIStatus.IDLE;
          state.studyProcedures.result =
            state.studyProcedures.result?.filter(
              (f) => f.idIri !== action.payload
            ) ?? null;

          state.studyProcedures.error = undefined;
        }
      )
      .addCase(deleteStudyProcedureThunk.rejected, (state, action) => {
        state.studyProcedures.apiStatus = APIStatus.REJECTED;
        state.studyProcedures.error = action.error;
      });
    // ******************************************
    // Study contact extra reducers
    // ******************************************
    // fetch study contacts
    builder
      .addCase(fetchStudyContactsThunk.pending, (state) => {
        state.studyContacts.apiStatus = APIStatus.PENDING;
        state.studyContacts.result = null;
        state.studyContacts.error = undefined;
      })
      .addCase(
        fetchStudyContactsThunk.fulfilled,
        (state, action: PayloadAction<StudyContacts>) => {
          state.studyContacts.apiStatus = APIStatus.IDLE;
          state.studyContacts.result = [...action.payload];
          state.studyContacts.error = undefined;
        }
      )
      .addCase(fetchStudyContactsThunk.rejected, (state, action) => {
        state.studyContacts.apiStatus = APIStatus.REJECTED;
        // if null, risk to infinite fetch
        state.studyContacts.result = [];
        state.studyContacts.error = action.error;
      });
    // create study contact
    builder
      .addCase(createStudyContactThunk.pending, (state) => {
        state.studyContacts.apiStatus = APIStatus.PENDING;
        state.studyContacts.error = undefined;
      })
      .addCase(
        createStudyContactThunk.fulfilled,
        (state, action: PayloadAction<IStudyContact>) => {
          state.studyContacts.apiStatus = APIStatus.IDLE;
          state.studyContacts.result = state.studyContacts.result?.concat(
            action.payload
          ) ?? [action.payload];

          state.studyContacts.error = undefined;
        }
      )
      .addCase(createStudyContactThunk.rejected, (state, action) => {
        state.studyContacts.apiStatus = APIStatus.REJECTED;
        state.studyContacts.error = action.error;
      });
    // update study contact
    builder
      .addCase(updateStudyContactThunk.pending, (state) => {
        state.studyContacts.apiStatus = APIStatus.PENDING;
        state.studyContacts.error = undefined;
      })
      .addCase(
        updateStudyContactThunk.fulfilled,
        (state, action: PayloadAction<IStudyContact>) => {
          state.studyContacts.apiStatus = APIStatus.IDLE;
          state.studyContacts.result = state.studyContacts.result?.map((m) =>
            m.idIri === action.payload.idIri ? action.payload : m
          ) ?? [action.payload];
          state.studyContacts.error = undefined;
        }
      )
      .addCase(updateStudyContactThunk.rejected, (state, action) => {
        state.studyContacts.apiStatus = APIStatus.REJECTED;
        state.studyContacts.error = action.error;
      });
    // delete study contact
    builder
      .addCase(deleteStudyContactThunk.pending, (state) => {
        state.studyContacts.apiStatus = APIStatus.PENDING;
        state.studyContacts.error = undefined;
      })
      .addCase(
        deleteStudyContactThunk.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.studyContacts.apiStatus = APIStatus.IDLE;
          state.studyContacts.result =
            state.studyContacts.result?.filter((f) => f.idIri !== action.payload) ??
            null;

          state.studyContacts.error = undefined;
        }
      )
      .addCase(deleteStudyContactThunk.rejected, (state, action) => {
        state.studyContacts.apiStatus = APIStatus.REJECTED;
        state.studyContacts.error = action.error;
      });
  },
});

export default studySlice.reducer;
export const studyActions = studySlice.actions;
export const getStudyState = (state: RootState) => state.study;
