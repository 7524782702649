import getEntityFromIdIri from '../../../../lib/parsersTools/getEntityFromIdIri';
import getIdFromIdIri from '../../../../lib/parsersTools/getIdFromIdIri';
import { contactApiToStoreParser } from '../../../contacts/contactParser';

export const studyContactsApiToStoreParser = (
  datas: any[],
  contactRoles: ContactRoles
) => {
  const parsedDatas: StudyContacts = datas.map((m) =>
    studyContactApiToStoreParser(m, contactRoles)
  );

  return parsedDatas;
};

export const studyContactApiToStoreParser = (
  data: any,
  contactRoles: ContactRoles
) => {
  try {
    const d = data.contact;
    const parsedContact = contactApiToStoreParser(data.contact);
    const parsedData: IStudyContact = {
      idIri: data['@id'],
      id: getIdFromIdIri(data['@id']),
      contact: {
        ...parsedContact,
        sortValues: { lastName: d.lastName },
      },
      role: getEntityFromIdIri(data.contactRole, contactRoles) as ContactRole,
    };

    return parsedData;
  } catch (error) {
    throw new Error('parser error');
  }
};
