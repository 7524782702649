import { nanoid } from '@reduxjs/toolkit';
import UserCard from './userCard';

interface IUserCardContainerProps {
  users: User[];
  canUpdate?: boolean;
  authIdIri?: string;
}

function UserCardContainer({
  users,
  canUpdate,
  authIdIri,
}: IUserCardContainerProps) {
  return (
    <div className="tab-body">
      {users?.map((u) => {
        return (
          <UserCard
            key={nanoid()}
            user={u}
            canUpdate={canUpdate}
            authIdIri={authIdIri}
          />
        );
      })}
    </div>
  );
}

export default UserCardContainer;
