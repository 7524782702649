import { Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';
import { Controller } from 'react-hook-form';
import styles from './advancedPlotSearchContainer.module.scss';

interface IFormCheckboxProps {
  control: any;
  name: keyof AdvancedPlotSearchForm;
  label: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}
function FormCheckbox({ control, name, onChange, label }: IFormCheckboxProps) {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChangeInternal: (e: ChangeEvent<HTMLInputElement>) => void
  ) => {
    onChangeInternal(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={styles.checkContainer}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            label={label}
            className={styles.checkLabel}
            labelPlacement="end"
            control={
              <Checkbox
                onChange={(e) => handleChange(e, onChange)}
                checked={value}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            }
          />
        )}
      />
    </div>
  );
}

export default FormCheckbox;
