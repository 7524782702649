import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LatLngTuple } from 'leaflet';
import { RootState } from '../../App/store';

const initialState: MapState = {
  zoom: 6,
  mapCenter: [46.57323062540835, 1.9954296875000004],
  geolocDatas: null,
  cadastreDisplay: false,
  franceLayerDisplay: true,
  isPmTilesLoading: false,
  satelliteDisplay: true,
  townHall: { latLng: null, displayed: false },
  plotDatas: null,
  clicked3dPoint: null,
};
const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    reset: () => initialState,
    geolocSet: (state, action: PayloadAction<GeolocPointInfo>) => {
      state.geolocDatas = action.payload;
      state.mapCenter = action.payload.coordinates;
      state.franceLayerDisplay = false;
      state.zoom = 17;
    },
    setClicked3dPoint: (
      state,
      action: PayloadAction<StreetViewLatLngType | null>
    ) => {
      state.clicked3dPoint = action.payload;
    },

    viewSet: (
      state,
      action: PayloadAction<Pick<MapState, 'mapCenter' | 'zoom'>>
    ) => {
      state.mapCenter = action.payload.mapCenter;
      state.zoom = action.payload.zoom;
    },
    plotGeolocDataSet: (state, action: PayloadAction<IPlotStudy>) => {
      const ap = action.payload;
      const datas = {
        address: ap.address ?? '',
        city: ap.city,
        postalCode: ap.postalCode,
        department: ap.department,
        inseeCode: ap.inseeCode,
        coordinates: [ap.lat, ap.lng] as LatLngTuple,
      };
      state.zoom = 17;
      state.franceLayerDisplay = false;
      state.mapCenter = [ap.lat, ap.lng];
      state.geolocDatas = state.geolocDatas
        ? { ...state.geolocDatas, ...datas, houseNumber: null, streetName: null }
        : { ...datas, houseNumber: null, streetName: null };
    },
    plotDatasSet: (state, action: PayloadAction<PlotDatasTemp>) => {
      state.plotDatas = action.payload;
      state.zoom = 17;
    },

    townHallDisplaySet: (
      state,
      action: PayloadAction<{
        displayed: boolean;
        latLng?: LatLngTuple;
      }>
    ) => {
      state.townHall.displayed = action.payload.displayed;
      if (action.payload.latLng && state.plotDatas) {
        state.mapCenter = action.payload.displayed
          ? action.payload.latLng
          : state.plotDatas.markerLatLng;
      }
    },
    townHallSet: (state, action: PayloadAction<LatLngTuple>) => {
      state.townHall.latLng = action.payload;
    },
    townHallReset: (state) => {
      state.townHall = { latLng: null, displayed: false };
    },
    cadastreDisplaySet: (state, action: PayloadAction<boolean>) => {
      state.cadastreDisplay = action.payload;
    },
    satelliteDisplaySet: (state, action: PayloadAction<boolean>) => {
      state.satelliteDisplay = action.payload;
    },
    mapCenterSet: (
      state,
      action: PayloadAction<{ latLng: LatLngTuple; zoom: number }>
    ) => {
      state.mapCenter = action.payload.latLng;
      state.zoom = action.payload.zoom;
    },
    franceLayerDisplaySet: (state, action: PayloadAction<boolean>) => {
      state.franceLayerDisplay = action.payload;
    },
    isPmTilesLoadingSet: (state, action: PayloadAction<boolean>) => {
      state.isPmTilesLoading = action.payload;
    },
  },
});

export default mapSlice.reducer;
export const mapActions = mapSlice.actions;
export const getMapState = (state: RootState) => state.map;
