import { Font } from '@react-pdf/renderer';
import Montserrat500 from '../fonts/Montserrat/static/Montserrat-Bold.ttf';
import Montserrat700 from '../fonts/Montserrat/static/Montserrat-ExtraBold.ttf';
import MontserratLight from '../fonts/Montserrat/static/Montserrat-Light.ttf';
import MontserratRegular from '../fonts/Montserrat/static/Montserrat-Medium.ttf';
import OrpiMedium from '../fonts/orpi/Orpi-Medium.ttf';
import OrpiRegular from '../fonts/orpi/Orpi-Regular.ttf';

Font.register({
  family: 'MontserratFamily',
  fonts: [
    {
      src: MontserratLight,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: MontserratRegular,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: Montserrat500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: Montserrat700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: 'OrpiFamily',
  fonts: [
    {
      src: OrpiRegular,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: OrpiMedium,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
});
const hyphenationCallback = (word: string) => {
  return [word];
};

Font.registerHyphenationCallback(hyphenationCallback);
