interface Point {
  x: number;
  y: number;
}
type Callback = (p1: Point, p2: Point, i: number, array: Point[]) => number;

const pairwiseSumReduce = (array: Point[], func: Callback, init = 0) => {
  const n = array.length;
  return array.reduce((acc, p, i) => {
    if (i + 1 >= n) {
      return acc;
    }
    return acc + func(p, array[i + 1], i, array);
  }, init);
};

const polygonCentroid = (points: Point[]) => {
  const area = pairwiseSumReduce(points, (p1, p2) => p1.x * p2.y - p1.y * p2.x) / 2;

  // Calculate the x coordinate of the centroid.
  const cx =
    pairwiseSumReduce(
      points,
      (p1, p2) => (p1.x + p2.x) * (p1.x * p2.y - p1.y * p2.x)
    ) /
    (6 * area);

  // Calculate the y coordinate of the centroid.
  const cy =
    pairwiseSumReduce(
      points,
      (p1, p2) => (p1.y + p2.y) * (p1.x * p2.y - p1.y * p2.x)
    ) /
    (6 * area);

  return { x: cx, y: cy };
};

export default polygonCentroid;
