export function indexableEntitiesParser<T>(entities: T[] | null): IIndex<T>[] {
  if (!entities) {
    return [];
  }

  return entities.map((e, i) => indexableEntityParser<T>(i + 1, e));
}

export function indexableEntityParser<T>(index: number, entity: T): IIndex<T> {
  return { index, entity };
}
