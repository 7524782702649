import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { pluActions } from '../pluSlice';
import { useAppDispatch } from '../../../App/store';
import styles from './pluOther.module.scss';
import { nanoid } from '@reduxjs/toolkit';

interface ICategoryListProps {
  categories: PluOther[];
}

function Child({
  child,
  handleDisplayClick,
}: {
  child: PluOtherChild | null;
  handleDisplayClick: (props: { id: string; displayed: boolean }) => void;
}) {
  if (!child) return null;

  return (
    <div className={styles.childs}>
      <input
        type="checkbox"
        checked={child.displayed}
        onChange={() =>
          handleDisplayClick({ id: child.id, displayed: !child.displayed })
        }
      />
      <label>{child.libelle}</label>
    </div>
  );
}

function SubCategory({ subCategory }: { subCategory: PluOtherSubCategory }) {
  const dispatch = useAppDispatch();

  const handleOpenClick = (id: string) => {
    dispatch(pluActions.setPluOtherOpen(id));
  };
  const handleDisplayClick = (props: { id: string; displayed: boolean }) => {
    dispatch(pluActions.setPluOtherDisplay(props));
  };

  if (!subCategory.mapboxUrl && !subCategory.pmtilesFileUrl) return null;

  return (
    <div className={styles.subCategory}>
      <div
        className={`${!subCategory.mapboxUrl ? styles.header : styles.mapboxItem} ${!subCategory.mapboxUrl && subCategory.isOpen ? styles.openedHeader : ''}`}
      >
        <input
          type="checkbox"
          checked={subCategory.displayed}
          onChange={() =>
            handleDisplayClick({
              id: subCategory.id,
              displayed: !subCategory.displayed,
            })
          }
          data-cy="plu-others-sub-category-checkbox"
        />
        <label className={`${!subCategory.mapboxUrl ? styles.isTitle : ''}`}>
          {subCategory.libelle}
        </label>
        {!subCategory.mapboxUrl && (
          <ExpandMoreIcon
            className={`${styles.expandIcon} ${subCategory.isOpen ? styles.isOpen : ''}`}
            onClick={() => handleOpenClick(subCategory.id)}
          />
        )}
      </div>
      {subCategory.isOpen &&
        !subCategory.mapboxUrl &&
        subCategory.childs &&
        subCategory.childs.map((child) => (
          <Child
            key={nanoid()}
            child={child}
            handleDisplayClick={handleDisplayClick}
          />
        ))}
    </div>
  );
}

function CategoryList({ categories }: ICategoryListProps) {
  return (
    <>
      {categories.map((category) => (
        <div key={nanoid()}>
          {category.childs.map((subCategory) => (
            <SubCategory key={nanoid()} subCategory={subCategory} />
          ))}
        </div>
      ))}
    </>
  );
}

export default CategoryList;
