import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { ReactComponent as ArrowCloseMultipleSelectPlots } from '../../../../assets/images/arrow-close-multiple-select-plots.svg';
import { ReactComponent as ArrowOpenMultipleSelectPlots } from '../../../../assets/images/arrow-open-multiple-select-plots.svg';
import { getPlotState } from '../../../../redux/plot/reducer';
import IndividualCircularLoader from '../../../loaders/individualCircularLoader';
import {
  externalPdfErrialActions,
  getExternalPdfErrialState,
} from '../../externalPdfSlice';
import styles from '../styles/rightPanelModalExternalPdfErrial.module.scss';
import NoPlotContent from './NoPlotContent';
function RightPanelExternalModal({ children }: IChildren) {
  const { externalPdfErrialRightPanelDisplay } = useAppSelector(
    getExternalPdfErrialState
  );
  const { parcelle, isActivePlotLoading } = useAppSelector(getPlotState);
  const dispatch = useAppDispatch();
  const handlePanel = (): void => {
    dispatch(
      externalPdfErrialActions.setExternalPdfErrialRightPanelDisplay(
        !externalPdfErrialRightPanelDisplay
      )
    );
  };

  return (
    <div
      className={`${styles.rightPanelModal} ${
        externalPdfErrialRightPanelDisplay ? styles.displayed : ''
      }`}
    >
      <div className={styles.arrowWrapper} onClick={handlePanel}>
        {externalPdfErrialRightPanelDisplay ? (
          <ArrowCloseMultipleSelectPlots className={styles.arrow} />
        ) : (
          <ArrowOpenMultipleSelectPlots className={styles.arrow} />
        )}
      </div>
      <div className={styles.rightPanelModalContentHeader}>
        <h2>Synthèse Document</h2>
      </div>
      <div className={styles.rightPanelModalContent}>
        {isActivePlotLoading ? (
          <>
            <IndividualCircularLoader size={100} />
          </>
        ) : (
          <>
            {!parcelle && <NoPlotContent />}
            {Boolean(parcelle) && children}
          </>
        )}
      </div>
    </div>
  );
}

export default RightPanelExternalModal;
