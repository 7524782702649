import { delay } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getAuthState } from '../../features/auth/authSlice';
import { mapPluginsActions } from '../../features/map/leaflet/plugins/mapPluginSlice';
import { modalsActions } from '../../features/modals/modalsSlice';
import { multiPlotSelectActions } from '../../features/plot/multiPlotSelectFeature/multiPlotSelectSlice';
import LocalStorageService from '../../services/LocalStorageService';
import {
  NO_REDIRECT_TO_HOME_AFTER_LOGGED,
  NO_REDIRECT_TO_HOME_FOR_PUBLIC_ROUTES,
} from '../../shared/constants';
import useQuery from '../hooks/useQuery';
import { useAppDispatch, useAppSelector } from '../store';
import RouteContainer from './RouteContainer';

function RouteManager() {
  const { queries, location } = useQuery();
  const { isLogged, token } = useAppSelector(getAuthState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const externalConnexion = queries.token && queries.refresh_token;

  useEffect(() => {
    if (isLogged) {
      // for redirect, url when isLogged must not be "/"
      // in others case, redirection is handled by displayed connection page
      if (!NO_REDIRECT_TO_HOME_AFTER_LOGGED.includes(location.pathname)) {
        const { from } = (location.state as any) || { from: { pathname: '/' } };

        if (from) {
          navigate(from, { replace: true });
        }
      }
    } else {
      if (externalConnexion) {
        LocalStorageService.clear;
      } else {
        const lsToken = LocalStorageService.getToken();

        delay(
          () => {
            // modal connection appear if there is a token save and if it's not a public route
            if (
              (lsToken || token) &&
              !NO_REDIRECT_TO_HOME_FOR_PUBLIC_ROUTES.includes(location.pathname)
            ) {
              dispatch(modalsActions.urbaneaseIntro(false));
              dispatch(modalsActions.connectionProcess(true));
            } else if (
              !isLogged &&
              !NO_REDIRECT_TO_HOME_FOR_PUBLIC_ROUTES.includes(location.pathname)
            ) {
              navigate('/');
            }
          },
          50,
          lsToken
        );
      }
    }
  }, [isLogged]);

  // deactivate map tools when leaving the home page
  useEffect(() => {
    if (location.pathname !== '/') {
      dispatch(mapPluginsActions.reset());
      dispatch(multiPlotSelectActions.reset());
      dispatch(modalsActions.altimetryHelperModal(false));
    }
  }, [location.pathname]);

  return <RouteContainer />;
}

export default RouteManager;
