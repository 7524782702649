import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import SelectFormCustom from '../../../../../components/Common/FormComponents/SelectFormCustom';
import TextAreaFormCustom from '../../../../../components/Common/FormComponents/TextAreaFormCustom';
import SubmitButton from '../../../../../components/RightPanelModal/TabsContent/bodys/components/prospection/SubmitButton';

import DatePickerCustom from '../../../../../components/Common/DatePickerCustom';
import createStudyProcedureThunk from '../../../services/thunks/createStudyProcedureThunk';
import { getStudyParamsState } from '../../../slices/studyParamsSlice';
import { getStudyState } from '../../../slices/studySlice';
import ProcedureTypeSelect from './ProcedureTypeSelect';
import UserSelect from './UserSelect';
import {
  initalProcedureValues,
  procedureValidationSchema,
} from './utils/procedureValidation';

interface IProcedureFormProps {
  closeForm?: () => void;
}

function StudyProcedureForm({ closeForm }: IProcedureFormProps): JSX.Element {
  const { studyContacts } = useAppSelector(getStudyState);
  const { type, idIri } = useAppSelector(getStudyParamsState);
  const formOptions = useForm<ProcedureFormValues>({
    defaultValues: initalProcedureValues,
    resolver: yupResolver(procedureValidationSchema),
  });
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = formOptions;

  const contactsForSelect = (): ISelectItem[] => {
    return (
      studyContacts.result?.map((c) => ({
        value: c.idIri as string,
        display: `${c.contact.firstName} ${c.contact.lastName}`,
      })) ?? []
    );
  };

  useEffect(() => {
    if (idIri) {
      setValue('studyIdIri', idIri);
    }
  }, [idIri]);

  const handleCloseForm = () => {
    reset();
    closeForm?.();
  };

  const handleFormSubmit = (values: ProcedureFormValues) => {
    if (isEmpty(errors)) {
      const body: StudyProcedureExtended = {
        ...values,
        contact: null,
        contactRole: null,
      };
      if (values.studyContactIdIri) {
        const contact =
          (studyContacts.result?.find(
            (f) => f.idIri === values.studyContactIdIri
          ) as IStudyContact) ?? null;
        body.contact = contact?.contact.idIri;
        body.contactRole = contact.role.idIri;
      }
      delete body.studyContactIdIri;

      dispatch(
        createStudyProcedureThunk({ datas: body, callback: handleCloseForm })
      );
    }
  };

  return (
    <FormProvider {...formOptions}>
      <form className="procedure-form" onSubmit={handleSubmit(handleFormSubmit)}>
        <div className="top-block">
          <UserSelect name="responsable" />

          <ProcedureTypeSelect name="type" />

          {type !== 'sector' && (
            <SelectFormCustom
              name="studyContactIdIri"
              className=""
              displayEmpty
              noValue={
                isEmpty(studyContacts.result)
                  ? 'Ajouter un contact'
                  : 'Choisir un contact'
              }
              items={contactsForSelect()}
            />
          )}

          <Controller
            control={control}
            name="eventDate"
            render={({ field: { onChange, value } }) => (
              <DatePickerCustom
                value={new Date(value)}
                onChange={(e: Date | null) => onChange(e?.toISOString() as string)}
                format="d MMM yyyy"
                hasDay
              />
            )}
          />
        </div>
        <div className="bottom-block">
          <TextAreaFormCustom name="comment" className="procedure-comment" />
        </div>

        <SubmitButton label="Ajouter une démarche" />
      </form>
    </FormProvider>
  );
}

export default StudyProcedureForm;
