import { Button } from '@mui/material';
import './rightBottom.scss';

const ZoomButtons = () => {
  return (
    <div className="zoom-buttons">
      <div className="buttons">
        <Button
          className="left-btn"
          variant="contained"
          id="zoom-in-button"
          // color="default"
        >
          +
        </Button>
        <Button
          className="right-btn"
          variant="contained"
          id="zoom-out-button"
          // color="primary"
        >
          −
        </Button>
      </div>
    </div>
  );
};

export default ZoomButtons;
