import {
  changeApiKeyForStoreKey,
  mapObjectParser,
} from '../../services/common/parserCommon';

export const userMapping = {
  '@id': 'idIri',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  plotStudies: 'plotStudies',
  procedures: 'procedures',
};

export function companyUserApiToStoreParser(cu: any) {
  const companyUser: CompanyUser = {
    companyIdIri: cu.company,
    userIdIri: cu.user,
    roles: cu.roles as UserRoleTypes,
    userIsActive: cu.userIsActive,
  };

  return companyUser;
}

/**
 * Parse api users datas to front data types
 * @param {any[]} user - users api values
 * @returns {Users} - parsed user
 */
export const usersApiToStoreParser = (users: any) => {
  const parsedUsers: Users = mapObjectParser(users, userApiToStoreParser);

  return parsedUsers;
};

/**
 * Parse api user data to front datas types
 * @param {any} users - user api values
 * @returns {User} - parsed users
 */
export const userApiToStoreParser = (user: any): User => {
  try {
    const parsedData = changeApiKeyForStoreKey<User>(user, userMapping);

    const roles = user?.currentCompanyUser?.roles ?? [];
    const isManager = roles?.some((f: string) => f === 'ROLE_MANAGER') ?? false;
    const isAdmin = roles?.some((f: string) => f === 'ROLE_ADMIN') ?? false;

    const u = {
      ...parsedData,
      id: parseInt(user['@id'].split('/')[2]),
      fullName: user.firstName + ' ' + user.lastName,
      roles: roles,
      isManager,
      isAdmin,
      isActive: user.currentCompanyUser?.userIsActive ?? false,
      plotStudyCount: parsedData?.plotStudies?.length ?? 0,
      procedureCount: parsedData?.procedures?.length ?? 0,
    };

    return u;
  } catch (error: any) {
    throw new Error('Erreur parsing user - ' + error.message);
  }
};

export const authUserApiToStoreParser = (user: any): User => {
  try {
    const parsedData = changeApiKeyForStoreKey<User>(user, userMapping);

    const roles = user?.roles ?? [];
    const isManager = roles?.some((f: string) => f === 'ROLE_MANAGER') ?? false;
    const isAdmin = roles?.some((f: string) => f === 'ROLE_ADMIN') ?? false;

    const u = {
      ...parsedData,
      id: parseInt(user['@id'].split('/')[2]),
      fullName: user.firstName + ' ' + user.lastName,
      roles: roles,
      isManager,
      isAdmin,
      isActive: user.currentCompanyUser?.userIsActive ?? false,
      plotStudyCount: parsedData?.plotStudies?.length ?? 0,
      procedureCount: parsedData?.procedures?.length ?? 0,
    };

    return u;
  } catch (error: any) {
    throw new Error('Erreur parsing user - ' + error.message);
  }
};
