import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { plotStudyParser } from '../utils/parsers/studyParser';

export const createPlotStudy = async (
  params: ICreatePlotStudy,
  users: Users | null,
  statuses: StudyStatuses | null,
  fromRightPanel?: boolean
) => {
  try {
    if (
      params.city &&
      params.folder &&
      params.responsable &&
      params.lat &&
      params.lng &&
      params.fullPlotId &&
      params.postalCode &&
      params.inseeCode &&
      users &&
      statuses
    ) {
      const body = {
        cityName: params.city,
        folder: params.folder.idIri,
        latitude: params.lat,
        longitude: params.lng,
        inseeCode: params.inseeCode,
        mapAddress: params.address,
        responsable: params.responsable,
        surface: params.area,
        uniquePlotId: params.fullPlotId,
        zipCode: params.postalCode,
        zone: params.zone,
        zoneChild: params.zoneChild,
      };
      const result = await postEntity({ endpoint: '/plot_studies', body });

      const parsed = plotStudyParser(result, users, statuses, params.folder);

      return parsed;
    } else {
      return Promise.reject(paramsNotFound('createPlotStudy'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
