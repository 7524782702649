import { fetchInseeFromLatLngDep } from '../../../shared/services/fetchInseeCode';
import { addrServProcessAsync } from '../../../utils/addrServProcessAsync';
import { updatePlotStudyDatas } from './updatePlotStudyDatas';

export const checkAndUpdatePlotStudyDatas = async (params: {
  ps: IPlotStudy;
  zones?: { zone: string | null; zoneChild: string | null };

  surface?: number | null;
}) => {
  const { ps, zones, surface } = params;
  try {
    const body: any = {};
    if (
      !ps.address ||
      !ps.postalCode ||
      !ps.inseeCode ||
      !ps.city ||
      ps.address.includes('+') // wrong google address
    ) {
      const locationDatas = await addrServProcessAsync(ps.lat, ps.lng);
      if (locationDatas) {
        if (!ps.address || ps.address.includes('+'))
          body.address = locationDatas?.address;
        if (!ps.address) body.address = locationDatas?.address;
        if (!ps.postalCode) body.postalCode = locationDatas?.postalCode;
        if (!ps.city) body.city = locationDatas?.city;
        if (!ps.inseeCode && locationDatas?.inseeCode)
          body.inseeCode = locationDatas.inseeCode;

        // if location come from google
        if (!locationDatas?.inseeCode) {
          const result = await fetchInseeFromLatLngDep(
            ps.lat,
            ps.lng,
            ps.department
          );
          locationDatas.inseeCode = result.inseeCode;
        }
      }
    }

    if (zones && (zones.zone !== ps.zone || zones.zoneChild !== ps.zoneChild)) {
      body.zone = zones.zone;
      body.zoneChild = zones.zoneChild;
    }

    if (surface && !isNaN(surface) && ps.area !== surface) {
      body.surface = surface;
    }

    // update plot study
    await updatePlotStudyDatas(ps.idIri, body);

    const newPs: IPlotStudy = {
      ...ps,
      address: body.address ?? ps.address,
      postalCode: body.postalCode ?? ps.postalCode,
      department: body.postalCode?.substring(0, 2) ?? ps.department,
      inseeCode: body.inseeCode ?? ps.inseeCode,
      city: body.city ?? ps.city,
      zone: body.zone ?? ps.zone,
      zoneChild: body.zoneChild ?? ps.zoneChild,
      area: surface ?? ps.area,
    };

    return newPs;
  } catch (error) {
    return Promise.reject(error);
  }
};
