import { StyleSheet } from '@react-pdf/renderer';

import { ORPI_PDF_COLOR_PRIMARY } from '../constants';
import './fonts';

export const pageStyles = StyleSheet.create({
  page: {
    position: 'relative',
    padding: '0 15px',
    fontFamily: 'OrpiFamily',
    fontSize: '12px',
    paddingBottom: '65px',
  },
});

export const pdfStyles = StyleSheet.create({
  pageHome: {
    position: 'relative',
    padding: '0 15px',
    fontFamily: 'MontserratFamily',
    fontSize: '12px',
  },
  page: {
    position: 'relative',
    padding: '0 15px',
    fontFamily: 'MontserratFamily',
    fontSize: '12px',
    paddingBottom: '65px',
  },
  // PAGE 1
  // Header and logo
  homeLogoAndTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logoImage: {
    marginLeft: '15px',
    width: '20%',
  },
  homeTopTitleSection: {
    paddingTop: '40px',
    paddingRight: '20px',
    display: 'flex',
    justifyContent: 'center',
    color: ORPI_PDF_COLOR_PRIMARY,
  },
  homeTopTitle: { fontSize: '24px', fontWeight: 700, zIndex: 10 },
  homeTopSubTitle: { fontSize: '14px', fontWeight: 700, zIndex: 10 },

  letterO: {
    position: 'absolute',
    right: -15,
    top: 0,
    zIndex: 0,
  },
  homeGenericInfos: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '20px',
    color: ORPI_PDF_COLOR_PRIMARY,
    fontSize: '14px',
    height: '135px',
  },

  date: { fontSize: '12px' },
  homePlotIdsSection: { marginTop: '120px', fontSize: '12px' },
  homePlotIdsTitle: { fontWeight: 700, color: '#FE0000' },
  homePlotIdsText: { maxWidth: '250px', color: ORPI_PDF_COLOR_PRIMARY },
  homeMapImage: {
    position: 'absolute',
    left: 15,
    top: '280px',
    width: '100%',
    height: '320px',
    zIndex: -1,
    borderRadius: '15px',
  },
  homeOwnerAndAddressBloc: {
    marginTop: '340px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: ORPI_PDF_COLOR_PRIMARY,
  },
  homeOwnerSection: {},
  homeOwnerLabel: {},
  homeOwnerData: { fontSize: '14px', fontWeight: 700 },
  homeAddressSection: { fontWeight: 700, color: '#FE0000', fontSize: '14px' },
  homeTitleBloc: { marginTop: '45px', color: ORPI_PDF_COLOR_PRIMARY },
  homeTitle: { marginTopfontSize: '20px', fontWeight: 700 },
  byUrbanease: { fontSize: '12px' },

  pageHeader: { position: 'relative' },
  pageHeaderBackground: { width: '448px' },
  pageHeaderText: {
    position: 'absolute',
    top: '10px',
    left: '40px',
    display: 'flex',

    alignItems: 'center',
    flexDirection: 'row',
  },
  pageHeaderOrpiLogoBloc: {
    width: '100px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  pageHeaderOrpiLogo: { width: '80%' },
  pageHeaderInfos: {
    color: '#ffffff',
    fontFamily: 'MontserratFamily',
    fontSize: '14px',
  },

  pageFooter: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '6px',
    color: ORPI_PDF_COLOR_PRIMARY,
  },
  pageFooterTextContainer: {
    paddingLeft: '15px',
    paddingBottom: '10px',
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pageFooterRedZone: {
    position: 'relative',
    width: '183px',
  },
  pageFooterLogoContainer: {
    position: 'absolute',
    width: '183px',
    height: '64px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    // top: '5px',
    fontSize: '10px',
    color: '#FFFFFF',
  },

  pageFooterLogoUrbaIsOrpi: { width: '50px', height: '31px', marginTop: '10px' },
  Container: {
    position: 'absolute',
    width: '183px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    top: '5px',
    fontSize: '10px',
    color: '#FFFFFF',
  },
  plotDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plotDetailsInfos: {
    width: '352px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  plotInfosImage: {
    objectFit: 'contain',
    width: '215px',
  },
  genericBloc: {
    margin: '20px 0 0 0',
    fontFamily: 'MontserratFamily',
    width: '100%',
    color: ORPI_PDF_COLOR_PRIMARY,
  },
  genericBlocTitle: { fontSize: '16px', marginBottom: '10px' },
});
