import createCtx from '../../../../../App/contexts/GenenricStateContext';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import AddFormDisplay from '../../../../../components/RightPanelModal/TabsContent/bodys/components/prospection/AddFormDisplay';
import ContactSearchModal from '../../../../contacts/contactSearchModal';
import createContactCtx from '../../../../contacts/contactSearchModal/contactContext';
import { contactsActions } from '../../../../contacts/contactsSlice';
import { getModalsState } from '../../../../modals/modalsSlice';
import { studyActions } from '../../../slices/studySlice';
import useStudy from '../../../useStudy';
import StudyContactForm from './StudyContactForm';
import StudyContactList from './StudyContactList';
import styles from './contact.module.scss';

interface IContactContentProps {
  studyIdIri: string | null;
  updateAllowed: boolean;
  onScroll: (offset: number) => void;
}
const [ctx, OpenFormProvider] = createCtx(false);
export const ForceOpenContext = ctx;

const [contactCtx, ContactProvider] = createContactCtx();
export const contactContext = contactCtx;

function StudyContactContent({
  studyIdIri,
  updateAllowed,
  onScroll,
}: IContactContentProps): JSX.Element {
  const { studyContacts } = useStudy({ loadStudyContacts: true });
  const { contactSearch } = useAppSelector(getModalsState);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(studyActions.setStudyContactToUpdate(null));
    dispatch(contactsActions.contactForActionReset());
  };

  return (
    <div className={styles.contactContent}>
      <OpenFormProvider>
        <ContactProvider>
          <StudyContactList
            studyContactList={studyContacts}
            updateAllowed={updateAllowed}
          />

          {updateAllowed && (
            <>
              <AddFormDisplay
                labelOpen="Ajouter un contact"
                labelClose="Masquer le formulaire"
                ForceOpenContext={ForceOpenContext}
                scroll={onScroll}
                onClick={handleClick}
              >
                <StudyContactForm />
              </AddFormDisplay>
              {contactSearch && <ContactSearchModal />}
            </>
          )}
        </ContactProvider>
      </OpenFormProvider>
    </div>
  );
}

export default StudyContactContent;
