import React, { createContext, useState } from 'react';

export default function createProspectionTabCtx() {
  const defaultTabValue = '';
  type UpdateSelectedTab = React.Dispatch<React.SetStateAction<string>>;
  const setTabValue: UpdateSelectedTab = () => defaultTabValue;

  const ctx = createContext({
    tabValue: defaultTabValue,
    setTabValue,
  });

  const Provider = (props: React.PropsWithChildren<{}>) => {
    const [tabValue, setTabValue] = useState(defaultTabValue);
    return <ctx.Provider value={{ tabValue, setTabValue }} {...props} />;
  };

  return [ctx, Provider] as const; // alternatively, [typeof ctx, typeof Provider]
}
