import styles from './contactSearchModal.module.scss';
interface IContactRowProps {
  contact: Contact;
  onClick?: (contact: Contact) => void;
}

const ContactRow = ({ contact, onClick }: IContactRowProps): React.ReactElement => {
  const { firstName, lastName, phone, email, addressOld } = contact;
  const handleclick = () => {
    if (onClick) {
      onClick(contact);
    }
  };

  return (
    <tr className={styles.row} onClick={handleclick}>
      <td className={styles.name}>
        {lastName && firstName ? `${lastName ?? ''} ${firstName ?? ''}` : 'indefini'}
      </td>
      <td className={styles.phone}>{phone ?? '-'}</td>
      <td className={styles.mail}>
        {email ? (
          <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
            {email}
          </a>
        ) : (
          '-'
        )}
      </td>
      <td className={styles.address}>{addressOld ?? '-'}</td>
    </tr>
  );
};

export default ContactRow;
