import { Polygon } from 'react-leaflet';
import { getWkbGeoJson } from '../utils';

interface IPolygonBaseProps {
  wkb: nullable<string>;
  className: string;
  color: 'blue' | 'red';
}
export default function PolygonBase({ wkb, color, className }: IPolygonBaseProps) {
  if (!wkb) {
    return null;
  }
  return (
    <Polygon className={className} positions={getWkbGeoJson(wkb)} color={color} />
  );
}
