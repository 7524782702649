import * as Yup from 'yup';
import { validationMessages } from '../../../../../constants/validations';
import {
  contactValidationSchema,
  initialContactForm,
} from '../../../../contacts/contactForm/contactFormValidation';

export const initialStudyContactForm: StudyContactForm = {
  ...initialContactForm,
  roleIdIri: '',
};

export const studyContactValidationSchema = contactValidationSchema.shape({
  roleIdIri: Yup.string().required(validationMessages.contactRole.requis),
});
