import { isEmpty } from 'lodash';
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { useAppDispatch } from '../../../App/store';
import { getDuplicatesFromArray } from '../../../shared/utils/arrayTools';
import { mailshotsActions } from '../mailshotsSlice';

interface MailshotResumeContextType {
  openedPlot: MailshotPlot | null;
  setOpenedPlot: Dispatch<SetStateAction<MailshotPlot | null>>;
  contactToUpdate: IStudyContact | null;
  setContactToUpdate: Dispatch<SetStateAction<IStudyContact | null>>;
  handleOpenPlot: (plot: MailshotPlot) => void;
  handleRemoveContact: (contact: IStudyContact, isDuplicate: boolean) => void;
  isOpen: (plot: MailshotPlot) => boolean;
  invalidAddressCount: (contacts: StudyContacts) => number;
  updateOpenedPlotContacts: (contact: IStudyContact) => void;
  invalidContactAddress: (contact: IStudyContact) => boolean;
}
const MailshotResumeContext = createContext<MailshotResumeContextType | undefined>(
  undefined
);

const MailshotResumeContextProvider: React.FC<IChildren> = ({ children }) => {
  const [openedPlot, setOpenedPlot] = useState<MailshotPlot | null>(null);
  const [contactToUpdate, setContactToUpdate] = useState<IStudyContact | null>(null);
  const dispatch = useAppDispatch();

  const invalidContactAddress = (studyContact: IStudyContact) => {
    const a = studyContact.contact.address;
    return !(a && a.addressStreet && a.city && a.postalCode);
  };

  const invalidAddressCount = (contacts: StudyContacts) =>
    contacts.filter((f) => {
      return invalidContactAddress(f);
    }).length;

  const handleOpenPlot = (plot: MailshotPlot) => {
    let value: MailshotPlot | null = openedPlot;
    if (!isEmpty(plot.contacts)) value = plot;

    if (openedPlot && openedPlot?.idIri === plot.idIri) {
      value = null;
    }

    setOpenedPlot(value);
  };

  const isOpen = (plot: MailshotPlot) => plot.idIri === openedPlot?.idIri;

  const updateOpenedPlotContacts = (studyContact: IStudyContact) => {
    if (openedPlot) {
      const newOpenedPlot: MailshotPlot = {
        ...openedPlot,
        contacts: {
          list: openedPlot.contacts.list.map((m) =>
            m.idIri === studyContact.idIri ? studyContact : m
          ),
          duplicateContacts: openedPlot.contacts.duplicateContacts,
        },
      };

      setOpenedPlot(
        invalidAddressCount(newOpenedPlot.contacts.list) === 0 ? null : newOpenedPlot
      );
    }
  };

  const handleRemoveContact = (contact: IStudyContact) => {
    if (openedPlot) {
      const newContacts = openedPlot.contacts.list.filter(
        (f) => f.idIri !== contact.idIri
      );
      const newOpenedPlot = {
        ...openedPlot,
        contacts: {
          list: newContacts,
          duplicateContacts: getDuplicatesFromArray(
            newContacts.map((m) => m.contact.idIri)
          ),
        },
      };
      dispatch(mailshotsActions.updatePlotInPlotList(newOpenedPlot));
      setOpenedPlot(isEmpty(newContacts) ? null : newOpenedPlot);
    } else {
      dispatch(mailshotsActions.removeContactFromFolderContacts(contact));
    }
  };

  const contextValue: MailshotResumeContextType = {
    openedPlot,
    setOpenedPlot,
    contactToUpdate,
    setContactToUpdate,
    handleOpenPlot,
    handleRemoveContact,
    isOpen,
    invalidAddressCount,
    updateOpenedPlotContacts,
    invalidContactAddress,
  };

  return (
    <MailshotResumeContext.Provider value={contextValue}>
      {children}
    </MailshotResumeContext.Provider>
  );
};

const useMailshotResumeContext = () => {
  const context = useContext(MailshotResumeContext);

  if (!context) {
    throw new Error(
      'MailshotResumeContext should be used inside of MailshotResumeContextProvider'
    );
  }

  return context;
};

export { MailshotResumeContextProvider, useMailshotResumeContext };
