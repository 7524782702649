import { LatLngTuple } from 'leaflet';
import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { ReactComponent as LogoIA } from '../../../../assets/images/logo-ai-black.svg';
import IndividualCircularLoader from '../../../../features/loaders/individualCircularLoader';
import { getMapState } from '../../../../features/map/mapSlice';
import {
  getUrbaGptState,
  urbaGptActions,
} from '../../../../features/urbaGpt/urbaGptSlice';
import { urbaGptQueryThunk } from '../../../../features/urbaGpt/urbaGptThunk';
import { getPlotState } from '../../../../redux/plot/reducer';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import CadastreLink from './components/geography/CadastreLink';
import BuildingInfoContainer from './components/geography/buildingContainer';
import styles from './components/geography/geography.module.scss';

export default function Geography(): JSX.Element {
  const dispatch = useAppDispatch();
  const { parcelle } = useAppSelector(getPlotState);
  const { plotDatas, geolocDatas } = useAppSelector(getMapState);
  const { urbaGptIsVisible, conversation } = useAppSelector(getUrbaGptState);
  const [isSent, setIsSent] = useState(false);
  const question = `Pourrais tu me donner les principaux services (écoles, administrations et super marchés) accessibles à pieds ou en voiture à moins de 15 minutes de l'adresse suivante: ${geolocDatas?.address}. Par ailleurs, merci de me préciser les typologies de moyens de transports publiques et les noms des arrêts accessibles dans le même délai.`;

  const handleUrbaGpt = () => {
    if (!urbaGptIsVisible && !isSent) {
      dispatch(
        urbaGptActions.setConversation({
          conversationUuid: '',
          datelog: new Date().toLocaleDateString(),
          id: 0,
          question: question,
          response: '',
          isAnim: false,
        })
      );
      dispatch(urbaGptQueryThunk({ question }));
    }
    dispatch(urbaGptActions.setUrbaGptIsVisible(!urbaGptIsVisible));
    setIsSent(true);
  };

  return (
    <div className={styles.geography}>
      <div className={styles.title}>
        <h2>Morphologie parcellaire</h2>
        <div>
          <LogoIA />
          <button
            onClick={() => handleUrbaGpt()}
            className={`${styles.urbaGptBtn} ${
              conversation.apiStatus === APIStatus.PENDING
                ? styles.urbaGptBtnDisabled
                : ''
            }`}
            disabled={conversation.apiStatus === APIStatus.PENDING}
          >
            Demander des infos pratiques
          </button>
        </div>
      </div>
      {!parcelle || !plotDatas?.markerLatLng ? (
        <IndividualCircularLoader size={200} />
      ) : (
        <>
          <ul>
            <li>
              {`Surface parcelle cadastre : `}
              <span>{parcelle?.theoricCapacity} m²</span>
            </li>
            <li>
              {`Emprise au sol du bâti: `}
              <span>{parcelle?.areaBuilt} m²</span>
            </li>
            <li>
              {`Emprise au sol libre de construction : `}
              <span>{parcelle?.areaFree} m²</span>
            </li>
            <li>
              {`Surface parcelle recalculée : `}
              <span>{parcelle?.realCapacity} m²</span>
            </li>
          </ul>

          <BuildingInfoContainer buildings={parcelle.buildings} />

          <ul>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`http://maps.google.com/maps?q=&layer=c&cbll=${plotDatas?.markerLatLng[0]},${plotDatas?.markerLatLng[1]}`}
              >
                Vue Google Street
              </a>
            </li>
            <li>
              <CadastreLink
                latlng={plotDatas?.markerLatLng as LatLngTuple}
                parcelleId={parcelle?.parcelleId}
              />
            </li>
          </ul>
        </>
      )}
    </div>
  );
}
