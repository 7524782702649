import { createAsyncThunk } from '@reduxjs/toolkit';
import { axiosTokenInstance } from '../../services/AxiosProtectedInstance';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import {
  multiPlotDetailParser,
  ownerInfosfromSirenParser,
  ownerListFromInseeCodeParser,
} from './dashboardOwnerDatasParser';
import { multiPlotSelectActions } from '../plot/multiPlotSelectFeature/multiPlotSelectSlice';

interface IFetchCompanyNameFromPatternProps extends IPagination {
  pattern: string;
}
export async function fetchCompanyNameFromPattern({
  itemsPerPage,
  page,
  pattern,
}: IFetchCompanyNameFromPatternProps): Promise<FetchCompanyNameFromPatternResultType> {
  try {
    const response = await axiosTokenInstance().get(
      '/find_siren_from_company_name',
      {
        params: {
          page,
          str_search: pattern,
          items_per_page: itemsPerPage,
        },
        timeout: 10000,
      }
    );
    const result = response.data;

    const parsedData: CompanyNamesWithSiren = [];
    if (typeof result === 'string') {
      throw new Error('Fatal Error');
    }

    for (const [key, value] of Object.entries(result.result || {})) {
      parsedData.push({ name: value as string, siren: key as string });
    }

    return { result: parsedData, totalItems: result.total };
  } catch (error: any) {
    if (error.message === 'Fatal Error') {
      throw new Error('Fatal Error');
    } else {
      throw new Error('500');
    }
  }
}

export const fetchOwnerInfosFromSirenThunk = createAsyncThunk(
  'dashboardOwnerDatas/fetchOwnerInfosFromSiren',
  async (
    params: {
      siren: string | null;
    },
    { dispatch }
  ) => {
    // dispatch(dashboardOwnerDatasActions.)
    const { siren } = params;
    dispatch(multiPlotSelectActions.ownerPlotsReset());

    if (siren) {
      const query: { [x: string]: number | string } = {
        siren,
      };
      const response = fetchDatas(
        `/get_dashboard_owner_informations_from_owner`,
        query
      ).then(
        (response) => {
          const parsedResponse = ownerInfosfromSirenParser(response);

          return parsedResponse;
        },
        (err) => {
          return null;
        }
      );

      return response.then((response) => response);
    } else return null;
  }
);

export const fetchOwnersFromInseeCodeThunk = createAsyncThunk(
  'dashboardOwnerDatas/fetchOwnersFromInseeCode',
  async (params: {
    insee: string | null;
    page: number | null;
    itemsPerPage: number | null;
    sort?: string | null;
    order?: OwnerOrder;
  }) => {
    const { insee, page, itemsPerPage, sort, order } = params;

    if (insee && page && itemsPerPage) {
      let query: { [x: string]: number | string } = {
        insee,
        page,
        items_per_page: itemsPerPage,
      };

      if (sort && order) {
        query = { ...query, sort: `${sort}:${order}` };
      }

      const response = fetchDatas(`/get_dashboard_owner_list_from_city`, query).then(
        (response) => {
          const parsedResponse = ownerListFromInseeCodeParser(
            response,
            insee,
            page,
            itemsPerPage,
            sort,
            order
          );

          return parsedResponse;
        }
      );

      return response
        .then((response) => {
          return response;
        })
        .catch((err) => {
          return null;
        });
    } else {
      return null;
    }
  }
);

export const fetchOwnerPlotFromSirenThunk = createAsyncThunk(
  'dashboardOwnerDatas/fetchOwnerPlotFromSiren',
  async (params: {
    siren: string | null;
    page: number | null;
    itemsPerPage: number | null;
    sort?: string | null;
    order?: OwnerOrder;
  }) => {
    const { siren, page, itemsPerPage, sort, order } = params;
    if (siren && page && itemsPerPage) {
      let query: { [x: string]: number | string } = {
        siren,
        page,
        items_per_page: itemsPerPage,
      };

      if (sort && order) {
        query = { ...query, sort: `${sort}:${order}` };
      }

      const response = fetchDatas('/get_dashboard_owner_parcels_from_siren', query);
      return response
        .then((response) => {
          const result: PlotsFromSiren = {
            plots: null,
            page,
            itemsPerPage,
            sort: sort ?? null,
            order: order ?? null,
          };
          result.plots = multiPlotDetailParser(response);

          return result;
        })
        .catch((err) => {
          return null;
        });
    } else return null;
  }
);
