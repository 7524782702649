import { Page, Text } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import GenericBloc from '../../shared/components/pdf/GenericBloc';
import SubTitleGenericBloc from '../../shared/components/pdf/SubTitleGenericBloc';
import { pdfStyles } from '../../shared/styles/pdfStyle';
import GenericRisksTab from './components/GenericRisksTab';
import PageFooter from './components/PageFooter';
import PageHeader from './components/PageHeader';

interface IPageSixProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  catnat: Catnat[];
  isOrpi: boolean;
}

function PageSix({ date, address, catnat, isOrpi }: IPageSixProps) {
  return (
    <Page size="A4" style={pdfStyles.page}>
      <PageHeader date={date} address={address} fixed isOrpi={isOrpi} />
      <GenericBloc title="ANNEXE – Liste des arrêtés de Catégories Naturelles sur la Commune">
        {isEmpty(catnat) ? (
          <Text>Aucune donnée recensée</Text>
        ) : (
          catnat.map((elt) => (
            <SubTitleGenericBloc
              subtitle={`• Risque : ${elt.libRisqueJo}`}
              key={nanoid()}
              wrap
            >
              <GenericRisksTab risks={elt.catnatDatas} />
            </SubTitleGenericBloc>
          ))
        )}
      </GenericBloc>
      <PageFooter fixed isOrpi={isOrpi} />
    </Page>
  );
}

export default PageSix;
