import { useAppSelector } from '../../App/store';
import { getPluState } from '../../features/plu/pluSlice';

const PLU_INCONNU =
  'Cette commune est à priori soumise au RNU ou couverte par un document d’urbanisme qui n’est plus opposable selon la loi (CC ou POS)';

function usePLU() {
  const { pluOthers, pluUrls, pluOthersPmtilesUrl } = useAppSelector(getPluState);

  const getPluStatusText = () =>
    // check if result is known
    ['INCONNU', null].includes(pluUrls.result.pluStatus)
      ? PLU_INCONNU
      : pluUrls.result.pluStatus;

  return {
    pluStatus: pluUrls.result.pluStatus,
    pluUrlApiStatus: pluUrls.apiStatus,
    noPluUrl: pluUrls.result.noUrl,
    pluDisplayed: pluUrls.result.displayed,
    pluPmtiles: pluUrls.result.pmtilesUrl,
    pluMapbox: pluUrls.result.mapboxUrl,
    pluBotIntegrationDate: pluUrls.result.botIntegrationDate,
    pluLastHumanCheck: pluUrls.result.lastHumanCheck,
    getPluStatusText,
    pluOthers: pluOthers.result,
    pluOthersApiStatus: pluOthers.apiStatus,
    pluOthersPmtilesUrl: pluOthersPmtilesUrl,
  };
}

export default usePLU;
