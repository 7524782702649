import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../../App/store';
import { IMailshotResumeSuccessContentProps } from '../../../mailshots/components/modals/mailshotSelectionResumeModal/MailshotResumeSuccessContent';
import { mailshotsActions } from '../../../mailshots/mailshotsSlice';
import createStudyProcedure from '../createStudyProcedure';

const createStudyProceduresFromMailshotsThunk = createAsyncThunk(
  'study/createStudyProceduresFromMailshotsThunk',
  async (
    params: IMailshotResumeSuccessContentProps & { isPlots: boolean },
    thunkOptions
  ) => {
    try {
      const { datas, isPlots } = params;
      const state = thunkOptions.getState() as RootState;
      const { userIdIri } = state.auth;
      const { procedureTypes, contactRoles } = state.app;
      const { users } = state.users;

      if (!params.datas) throw new Error('No data param found');
      if (isEmpty(params.datas)) return [];

      const bodies: any[] = [];
      let body: any = {
        type: '/procedure_types/1',
        responsable: userIdIri,
        eventDate: new Date().toISOString(),
        comment: 'Courrier de publipostage édité',
      };

      if (isPlots) {
        datas.forEach((e) => {
          const b = {
            ...body,
            plotStudy: e.plot?.idIri,
            contactRole: e.contact.role.idIri,
            contact: e.contact.contact.idIri,
          };
          bodies.push(b);
        });
      } else {
        datas.forEach((e) => {
          const b = {
            ...body,
            folder: e.subfolder?.idIri,
            contactRole: e.contact.role.idIri,
            contact: e.contact.contact.idIri,
          };
          bodies.push(b);
        });
      }

      const promises = await Promise.all(
        bodies.map((m) =>
          createStudyProcedure({
            body: m,
            contactRoles: contactRoles.result,
            procedureTypes: procedureTypes.result,
            users,
          })
        )
      );

      thunkOptions.dispatch(mailshotsActions.resetWithoutModels());

      return promises;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default createStudyProceduresFromMailshotsThunk;
