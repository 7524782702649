import L, { MarkerClusterGroupOptions, PopupEvent } from 'leaflet';
import 'leaflet.markercluster';
import { delay, isEmpty } from 'lodash';
import { useCallback, useLayoutEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import ReactDOMServer from 'react-dom/server';
import { useMap } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import ErrorNoDatas from '../../../../components/errors/NoDatasError';
import { asyncMap } from '../../../../lib/arrayFilter';
import IndividualCircularLoader from '../../../loaders/individualCircularLoader';
import { dpeActions, getDpeState } from '../../builtCaracteristicsSlice';
import { fetchPopupContent } from '../dpeRequests';

import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import styles from '../dpe.module.scss';
import DpeMarkerPopupContainer from './DpeMarkerPopupContainer';
import './clusterMarker.scss';

function DpeMarkersClusterGroup() {
  const {
    dpePoints,
    dpeAdvancedSearchPoints,
    displayedDpeTypes,
    dpeVisible,
    dpeGFVisible,
    isRecentOnly,
    dpeAdvancedSearchFormData,
  } = useAppSelector(getDpeState);
  const [displayedDpePoints, setdisplayedDpePoints] = useState<DpePoints>([]);
  const map = useMap();
  const dispatch = useAppDispatch();

  const pEvent = useCallback(
    async (e: PopupEvent) => {
      const c = e.popup;
      if ((c.options as any).popupType && (c.options as any).popupType === 'DPE') {
        const popupContent = document.createElement('div');
        c.setContent(popupContent);
        // create a root
        const root = createRoot(popupContent as HTMLElement);

        try {
          // assign loader to content
          root.render(<IndividualCircularLoader size={100} />);

          // load datas
          const options = c.options as any;
          const datas: DpeForMap | null = await fetchPopupContent(
            options,
            dpeAdvancedSearchFormData
          );

          // assign datas to content
          root.render(<DpeMarkerPopupContainer datas={datas} />);
        } catch (error) {
          root.render(<ErrorNoDatas />);
        }
      }
    },
    [dpeAdvancedSearchFormData]
  );
  // reset layer + event popup
  const resetLayer = () => {
    map.removeEventListener('popupopen', pEvent);
    map.eachLayer((l: any) => {
      if (l.options.id === 'clusterMarker') {
        map.removeLayer(l);
      }
    });
  };

  const getDisplayedPoints = () => {
    let points;
    if (
      dpePoints.apiStatus !== APIStatus.PENDING &&
      dpeAdvancedSearchPoints.apiStatus !== APIStatus.PENDING
    ) {
      points = dpeAdvancedSearchPoints.result
        ? dpeAdvancedSearchPoints.result
        : dpePoints.result
            ?.filter((f) => displayedDpeTypes.includes(f.dpeClass))
            .filter((ir) => {
              let result = true;
              if (isRecentOnly) {
                result = ir.isRecent;
              }
              return result;
            });
    }
    return points ?? [];
  };

  useLayoutEffect(() => {
    let points: DpePoints = [];
    if (dpeVisible || dpeGFVisible) {
      points = getDisplayedPoints();
    } else {
      points = [];
    }

    setdisplayedDpePoints(points);
  }, [
    dpePoints,
    dpeAdvancedSearchPoints,
    displayedDpeTypes,
    dpeVisible,
    dpeGFVisible,
    isRecentOnly,
  ]);

  const operation = async (data: DpePoint) => {
    const dpeClass = data.dpeClass?.toLowerCase() + 'Class';
    const marker = L.marker([data.lat, data.lng], {
      icon: L.divIcon({
        className: styles.markerBase,
        html: ReactDOMServer.renderToString(
          <div
            className={`${styles.dpePoint} ${styles[dpeClass]} ${
              data.isRecent ? styles.isRecent : ''
            }`}
          ></div>
        ),
      }),
    });

    const popup = L.popup({ minWidth: 300, maxWidth: 400, maxHeight: 460 });
    popup.setContent('');

    marker.bindPopup(popup, {
      ...data,
      popupType: 'DPE',
      closeOnClick: false,
    } as any);
    return marker;
  };

  const mapGeneration = async () => {
    const markers = await asyncMap<DpePoint, any>(displayedDpePoints, operation);

    return markers;
  };

  async function calc() {
    const clusterProps: MarkerClusterGroupOptions = {};

    const markerCluster = new (L.markerClusterGroup as any)(clusterProps);
    markerCluster.options.id = 'clusterMarker';

    const markers = await mapGeneration();

    markerCluster.addLayers(markers);
    map.addLayer(markerCluster);
    dispatch(dpeActions.setGenerateDisplay(false));
  }

  useLayoutEffect(() => {
    resetLayer();

    if (!isEmpty(displayedDpePoints)) {
      map.getContainer().classList.add('loading-plot');

      delay(async () => {
        await calc();

        map.addEventListener('popupopen', pEvent);

        map.getContainer().classList.remove('loading-plot');
      }, 10);
    }
  }, [displayedDpePoints]);

  return null;
}

export default DpeMarkersClusterGroup;
