import { postEntityNew } from '../../../services/axiosFiles/genericCrud';
import { functionPluUrlParser } from '../pluParser';

interface IfetchPluUrlProps {
  department?: string;
  lat?: number;
  lng?: number;
}

/**
 *
 * @param {string} department Plot department
 * @param {number} lat Plot latitude
 * @param {number} lng Plot longitude
 * @returns
 */
async function fetchPluUrl({ department, lat, lng }: IfetchPluUrlProps) {
  try {
    // if one param missing
    if (!department || !lat || !lng)
      throw new Error('One or more params missing on fetchPluUrl request');

    // create request body
    const body = { department, lat, lon: lng };

    // request
    const result = await postEntityNew({
      hasPythonProxy: true,
      endpoint: '/get_plu_basic_informations/',
      body,
    });

    // parse data
    const parsed = functionPluUrlParser(result);

    return parsed;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchPluUrl;
