import { Autocomplete, TextField } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { ChangeEvent, SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

export type AutocompleteOptionType = { label: string; id: string };
export type AutocompleteOptionsType = AutocompleteOptionType[];

interface IAutocompleteFormCustomProps {
  list: AutocompleteOptionsType;
  name: string;
  placeholder?: string;
  dataCy?: string;
  className?: string;
  onInputChange?: (value: string | null) => void;
  onOptionChange?: (value: string | null) => void;
  required?: boolean;
}

const useStyles = makeStyles()((theme) => ({
  root: {
    width: 300,
    margin: '0 auto',
    '& .MuiFormHelperText-root.Mui-error': {
      fontStyle: 'italic',
    },
  },
  popper: { zIndex: 1000 },
  inputRoot: {
    padding: '0!important',
    '&.Mui-error': {
      input: {
        backgroundColor: '#ffecfc',
      },
    },
    input: {
      padding: '5px 0 5px 5px!important',
    },
  },
}));

function AutocompleteFormCustom({
  list,
  name,
  placeholder,
  dataCy,
  className,
  onInputChange,
  onOptionChange,
  required,
}: IAutocompleteFormCustomProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { classes } = useStyles();

  const handleOptionChange = (
    event: SyntheticEvent,
    value: AutocompleteOptionType | null
  ) => {
    onOptionChange?.(value?.id ?? null);
  };
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: any) => void
  ) => {
    const value = event.target.value;
    onChange(value);
    onInputChange?.(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, field: { onChange } }) => (
        <Autocomplete
          {...field}
          classes={classes}
          options={list}
          freeSolo
          disableClearable
          isOptionEqualToValue={(option, value) => option.id === value}
          noOptionsText="Aucune proposition"
          onChange={handleOptionChange}
          getOptionKey={() => nanoid()}
          renderInput={(params) => (
            <TextField
              {...params}
              onChange={(event) => handleInputChange(event, onChange)}
              data-cy={dataCy}
              placeholder={
                placeholder ? `${placeholder}${required ? '*' : ''}` : undefined
              }
              error={Boolean(errors[name])}
              helperText={errors[name]?.message as string}
              required={required}
              autoComplete="new-password"
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password',
              }}
            />
          )}
        />
      )}
    />
  );
}

export default AutocompleteFormCustom;
