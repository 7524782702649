import { StyleSheet, View } from '@react-pdf/renderer';
import ImageContainer from './ImageContainer';
import PlotDetailInfoBlocLand from './PlotDetailInfoBlocLand';
interface IPlotDetailsProps {
  parcelsOnCadastrePicture: string;
  plotIds: string[];
  area: number;
  freeGroundArea: number;
  builtArea: number;
  recalculatedArea: number;
  builtHeight: number;
}

function PlotDetailsLand(props: IPlotDetailsProps) {
  const isMultiPlot = props.plotIds.length > 1;
  return (
    <View style={styles.plotDetails}>
      <ImageContainer
        localImg={`data:image/jpeg;base64,${props.parcelsOnCadastrePicture}`}
        width="215px"
        height="185px"
        borderRadius="15px"
      />

      <View style={styles.plotDetailsInfos}>
        <PlotDetailInfoBlocLand
          data={props.plotIds}
          label={isMultiPlot ? ['N° de parcelles'] : ['N° de parcelle']}
          isMultiPlot={isMultiPlot}
        />

        <PlotDetailInfoBlocLand
          data={`${props.area} m²`}
          label={['Surface', 'au Cadastre']}
        />

        <PlotDetailInfoBlocLand
          data={`${props.freeGroundArea} m²`}
          label={['Surface libre', 'au sol estimée']}
        />

        <PlotDetailInfoBlocLand
          data={`${props.builtArea} m²`}
          label={['Surface de', "l'emprise bâti", 'au sol estimée']}
        />

        <PlotDetailInfoBlocLand
          data={`${props.recalculatedArea} m²`}
          label={['Surface', 'totale Parcelle', 'recalculée']}
        />

        <PlotDetailInfoBlocLand
          data={`${props.builtHeight} m`}
          label={['Hauteur', 'estimée', 'du bâtiment']}
        />
      </View>
    </View>
  );
}

export default PlotDetailsLand;

const styles = StyleSheet.create({
  plotDetails: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  plotDetailsInfos: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});
