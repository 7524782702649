import L, { LatLngExpression } from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import { Marker, Tooltip } from 'react-leaflet';
import SVGIconComponent from '../../../shared/components/map/SVGIconComponent';

interface IMarkerNumberProps {
  number: number;
  markerColor: string;
  children?: React.ReactNode;
  zIndexOffset?: number;
  onClick?: () => void;
  markerLatLng: LatLngExpression;
  hasTooltip?: boolean;
  className?: string;
  tooltipMessage?: React.ReactNode;
}

export default function MarkerNumber({
  number,
  markerColor,
  markerLatLng,
  children,
  zIndexOffset,
  onClick,
  hasTooltip,
  className,
  tooltipMessage,
}: IMarkerNumberProps) {
  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
  };

  const divIcon = (number: number, markerColor: string) => {
    return L.divIcon({
      className: 'marker-with-number',
      html: ReactDOMServer.renderToString(
        <div>{SVGIconComponent(number, markerColor)}</div>
      ),
    });
  };

  return (
    <Marker
      zIndexOffset={zIndexOffset}
      eventHandlers={{
        click: () => {
          handleClick();
        },
      }}
      position={markerLatLng}
      icon={divIcon(number, markerColor)}
    >
      {hasTooltip && (
        <Tooltip direction="top" sticky className={className}>
          {tooltipMessage}
        </Tooltip>
      )}
      {children}
    </Marker>
  );
}
