// File: ConnectionProcessContext.tsx
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../App/store';
import { logoutThunk } from '../authThunks';
import { ConnectionWorkFlowEnum } from './utils/connexionProcessEnums';

const initialProcessDataState: IConnectionProcessQueries = {
  isLand: false,
  urlType: 'classic' as ConnectionProcessUrlType,
  phId: null,
  sbId: null,
  phAccessToken: null,
  canLoadPlot: false,
  department: null,
  lat: null,
  lng: null,
  token: null,
  refreshToken: null,
};
// context type definition
interface ConnectionProcessContextType {
  // add properties you want to share here
  navigate: any;
  workflowState: ConnectionWorkFlowEnum;
  setWorkflowState: Dispatch<SetStateAction<ConnectionWorkFlowEnum>>;
  processDatas: IConnectionProcessQueries;
  setProcessDatas: Dispatch<SetStateAction<IConnectionProcessQueries>>;
  decodedToken: DecodedToken | null;
  setDecodedToken: Dispatch<SetStateAction<DecodedToken | null>>;
  handleErrorClick: (value?: string) => void;
}

// Create the context
const ConnectionProcessContext = createContext<
  ConnectionProcessContextType | undefined
>(undefined);

//Create a context provider to wrap the app
interface MyContextProviderProps {
  children: ReactNode;
}

export const ConnectionProcessContextProvider: React.FC<MyContextProviderProps> = ({
  children,
}: IChildren) => {
  const navigate = useNavigate();
  const [workflowState, setWorkflowState] = useState<ConnectionWorkFlowEnum>(
    ConnectionWorkFlowEnum.IDLE
  );
  const [processDatas, setProcessDatas] = useState<IConnectionProcessQueries>(
    initialProcessDataState
  );
  const [decodedToken, setDecodedToken] = useState<DecodedToken | null>(null);
  const dispatch = useAppDispatch();
  // methods
  const handleErrorClick = (value?: string) => {
    switch (value) {
      case 'Rafraichir':
        navigate('/');
        window.location.reload();
        break;

      default:
        navigate('/');
        dispatch(logoutThunk());
        break;
    }
  };
  // Provide context values to childrens
  const contextValue: ConnectionProcessContextType = {
    navigate,
    workflowState,
    setWorkflowState,
    processDatas,
    setProcessDatas,
    decodedToken,
    setDecodedToken,
    handleErrorClick,
  };

  return (
    <ConnectionProcessContext.Provider value={contextValue}>
      {children}
    </ConnectionProcessContext.Provider>
  );
};

// context hook
export const useConnectionProcessContext = () => {
  const context = useContext(ConnectionProcessContext);

  if (!context) {
    throw new Error(
      "ConnectionProcessContext doit être utilisé à l'intérieur de ConnectionProcessContextProvider"
    );
  }

  return context;
};
