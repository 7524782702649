import { createAsyncThunk } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { LatLngTuple } from 'leaflet';
import { isEmpty } from 'lodash';
import fetchAssietteSupS from '../../../../shared/services/servitudes/plot/fetchAssietteSupS';
import fetchInfoPCT from '../../../../shared/services/servitudes/plot/fetchInfoPCT';
import fetchInfoSurf from '../../../../shared/services/servitudes/plot/fetchInfoSurf';
import fetchPrescriptionLIN from '../../../../shared/services/servitudes/plot/fetchPrescriptionLIN';
import fetchPrescriptionPCT from '../../../../shared/services/servitudes/plot/fetchPrescriptionPCT';
import fetchSCOT from '../../../../shared/services/servitudes/plot/fetchSCOT';
import { distinctElementsFromArray } from '../../../../utils/jsFunctions';
import { loadersActions } from '../../../loaders/loaderSlice';

export const fetchPlotServitudesThunk = createAsyncThunk(
  'externalPdfErrial/fetchPlotServitudesThunk',
  async (
    params: { plotGeometry: string; coordinates: LatLngTuple },
    { dispatch }
  ) => {
    try {
      const { plotGeometry, coordinates } = params;

      const result = await Promise.all([
        fetchPrescriptionPCT({ plotGeometry }),
        fetchPrescriptionLIN({ plotGeometry }),
        fetchInfoSurf({ plotGeometry }),
        fetchInfoPCT({ plotGeometry }),
        fetchAssietteSupS({ plotGeometry }),
        fetchSCOT({ latLng: coordinates }),
      ]);

      const dataMapParser = (
        data: FeatureCollection,
        type: 'libelle' | 'other' | 'title'
      ) => {
        let multi: string[] = [];
        switch (type) {
          case 'libelle':
            multi = data.features
              .map((f) => f.properties?.libelle ?? f.properties?.nomsuplitt)
              .filter(distinctElementsFromArray);
            break;
          case 'other':
            multi = data.features
              .map((f) => f.properties?.typeass ?? f.properties?.nomsuplitt)
              .filter(distinctElementsFromArray);
            break;
          case 'title':
            multi = !isEmpty(data.features)
              ? [data.features[0]?.properties?.title]
              : [];
            break;
          default:
            break;
        }

        return multi;
      };

      const parsed: OrpiServitudes = {
        ponctPresc: result[0] ? dataMapParser(result[0], 'libelle') : null,
        linPresc: result[1] ? dataMapParser(result[1], 'libelle') : null,
        infoSurf: result[2] ? dataMapParser(result[2], 'libelle') : null,
        infoPonc: result[3] ? dataMapParser(result[3], 'libelle') : null,
        servOth: result[4] ? dataMapParser(result[4], 'other') : null,
        scot: result[5] ? dataMapParser(result[5], 'title') : null,
      };

      dispatch(loadersActions.currentStepSet('Servitudes chargées'));
      return parsed;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
