import MapPluginHelperModal from '../mapPluginHelperModal/MapPluginHelperModal';

const AltimetryHelperModal = (): JSX.Element => {
  return (
    <MapPluginHelperModal>
      <ul>
        <li>cliquez une première fois pour commencer à tracer une ligne</li>
        <li>cliquez une deuxième fois pour continuer à tracer une ligne</li>
        <li>cliquez sur le dernier point pour terminer la ligne</li>
      </ul>
    </MapPluginHelperModal>
  );
};

export default AltimetryHelperModal;
