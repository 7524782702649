import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import entityListFromNestedEntities from '../../../../utils/entityListFromNestedEntities';
import {
  IFechPlotStudiesProps,
  fetchPlotStudies,
} from '../../../study/services/fetchPlotStudies';

function removeDuplicatesById(array: PlotStudies) {
  const arrayUnique: PlotStudies = [];
  array.forEach((element) => {
    let isUnique = true;
    arrayUnique.forEach((elementUnique) => {
      if (element.idIri === elementUnique.idIri) {
        isUnique = false;
        return;
      }
    });
    if (isUnique) {
      arrayUnique.push(element);
    }
  });
  return arrayUnique;
}

const fetchContactPlotStudiesThunk = createAsyncThunk(
  'contacts/fetchContactPlotStudiesThunk',
  async (params: IFechPlotStudiesProps, thunkOptions) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const users = state.users.users;
      const plotStudyStatuses = state.app.plotStudyStatuses.result;
      const folders = state.folders.folders.result;

      const response = await fetchPlotStudies({
        urlBase: params.urlBase,
        isContactPlotStudies: true,
        users,
        statuses: plotStudyStatuses,
        folders: entityListFromNestedEntities(folders ?? []) as Folders,
      });

      const filteredPlotStudies = removeDuplicatesById(response);
      const sortableplotStudies = filteredPlotStudies.map((m) => ({
        sortValues: {
          city: m.city,
          area: m.area,
          responsable: m.responsable?.fullName ?? null,
          status: m.status?.label ?? null,
        },
        entity: m,
      }));

      return sortableplotStudies;
    } catch (e) {
      return Promise.reject(e);
    }
  }
);

export default fetchContactPlotStudiesThunk;
