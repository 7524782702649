import { useAppDispatch } from '../../App/store';
import { modalsActions } from '../../features/modals/modalsSlice';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from './GenericModal';

const getContext = (context: string | null) => {
  switch (context) {
    case 'deleteFolder':
      return {
        title: 'Suppression de dossier',
        content:
          'Impossible de supprimer un dossier qui contient des parcelles en etude, ou des sous dossiers non vide.',
      };
    case 'deleteSector':
      return {
        title: 'Suppression de secteur',
        content:
          'Impossible de supprimer un secteur qui contient des sous secteurs.',
      };
    case 'noPlotFounded':
      return {
        title: 'Parcelle',
        content: 'Aucune parcelle a été trouvée.',
      };
    case 'noUserAuth':
      return {
        title: 'Authentification utilisateur',
        content:
          'Une erreur est survenue lors du chargement des données utilisateur, veuillez contacter urbanease.',
      };
    case 'notIncludedInSubscription':
      return {
        title: 'Parcelle',
        content:
          'Cette parcelle se situe dans une zone non couverte par votre abonnement. Veuillez contacter urbanease pour étendre votre souscription.',
      };
    case 'updateStudyError':
      return {
        title: 'Parcelle',
        content: "La modification n'a pas pu être effectuée.",
      };
    case 'updateCompanyUserError':
      return {
        title: 'Utilisateur',
        content: "La modification n'a pas pu être effectuée.",
      };
    case 'createMailshotModelError':
      return {
        title: 'Publipostage',
        content: 'Une erreur est survenue lors de la création de votre modèle.',
      };
    case 'deleteMailshotModelError':
      return {
        title: 'Publipostage',
        content: "Une erreur s'est produite lors de la suppression de votre modèle.",
      };
    case 'fetchMailshotModelError':
      return {
        title: 'Publipostage',
        content: "La récupération des modèles n'a pas pu être effectuée.",
      };
    case 'fetchMailshotPlotsContactsError':
      return {
        title: 'Publipostage',
        content: "Une erreur s'est produite lors du chargement des contacts.",
      };
    case 'updateMailshotModelError':
      return {
        title: 'Publipostage',
        content: "La modification du modèle n'a pas pu être effectuée.",
      };
    case 'hydrateMailshotModelError':
      return {
        title: 'Publipostage',
        content: "Une erreur est survenue lors de l'édition du modèle.",
      };

    default:
      return { title: '', content: '' };
  }
};
interface IAlertProps {
  modalType: GenericModalEnum;
  context: AlertContextType;
}

function Alert({ modalType, context }: IAlertProps) {
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(modalsActions.alertClose());
  };

  const display = getContext(context);
  return (
    <GenericModal
      actionType={GenericModalActionEnum.OK}
      modalType={modalType}
      title={display.title}
      closeModal={handleCloseModal}
      okButtonContent="OK"
      okCallback={handleCloseModal}
    >
      <div>{display.content}</div>
    </GenericModal>
  );
}

export default Alert;
