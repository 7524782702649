import { nanoid } from '@reduxjs/toolkit';
import {
  changeApiKeyForStoreKey,
  mapObjectParser,
} from '../../services/common/parserCommon';

const pluOtherMapping = {
  cat: 'cat',
  sub_cat: 'subCat',
  master_cat: 'masterCat',
  libelle: 'libelle',
  mapbox_url: 'mapboxUrl',
  plu_others_group: 'pluOthersGroup',
  cat_subcat_libelle: 'catSubcatLibelle',
  pmtiles_file_url: 'pmtilesFileUrl',
};

export const zonesAndZoneChildsApiToStore = (datas: any) => {
  const filteredDatas = { ...datas };

  delete filteredDatas.city_name;
  delete filteredDatas.city_zipcode;

  const zones = Object.keys(filteredDatas);
  const zoneChilds: { [x: string]: string[] } = filteredDatas;

  return {
    zones,
    zoneChilds: zoneChilds,
    city: datas.city_name,
    postalCode: datas.city_zipCode,
  };
};

function transformData(data: PluOtherMapping[]): PluOthers {
  const categories: { [key: number | string]: PluOther } = {};

  data.forEach((item) => {
    const {
      cat,
      mapboxUrl,
      libelle,
      masterCat,
      subCat,
      catSubcatLibelle,
      pmtilesFileUrl,
    } = item;

    // Create the master category if it doesn't exist
    if (!categories[cat]) {
      categories[cat] = {
        id: nanoid(),
        cat: cat,
        libelle: masterCat,
        childs: [],
      };
    }

    // Check if the subcategory exists in the master category
    let subCategory = categories[cat].childs!.find(
      (child) => child.subCat === subCat
    );

    // If the subcategory doesn't exist, create it
    if (!subCategory) {
      subCategory = {
        id: nanoid(),
        subCat: subCat,
        libelle: libelle,
        mapboxUrl,
        pmtilesFileUrl,
        displayed: false,
        isOpen: false,
        childs: [],
      };
      categories[cat].childs!.push(subCategory);
    }

    // Check if the specific libelle already exists in the subcategory
    let existingItem = subCategory.childs!.find(
      (child) => child.libelle === catSubcatLibelle
    );

    // If it doesn't exist, create and add it to the subcategory
    if (!existingItem) {
      existingItem = {
        id: nanoid(),
        libelle: catSubcatLibelle,
        displayed: false,
      };
      subCategory.childs!.push(existingItem);
    }
  });

  return Object.values(categories);
}

export function pluOthersApiToStoreParser(data: any): PluOthers {
  const parsedData = mapObjectParser(data, pluOtherApiToStoreParser);

  const outputData: PluOthers = transformData(parsedData);

  return outputData;
}
export function pluOtherApiToStoreParser(data: any): PluOtherMapping {
  const temp = changeApiKeyForStoreKey<PluOtherMapping>(data, pluOtherMapping);
  temp.displayed = false;

  return temp;
}

export function functionPluUrlParser(datas: any): PluUrls {
  try {
    if (!datas) throw new Error('No data to parse');

    const status =
      datas.plu_status?.status === 'CC'
        ? 'Carte Communale'
        : datas.plu_status.status;

    const parsed: PluUrls = {
      mapboxUrl: datas.mapbox_url ?? null,
      pmtilesUrl: datas.pmtiles_url ?? null,
      lastHumanCheck: datas.last_human_check,
      botIntegrationDate: datas.bot_integration_date,
      noUrl: !datas.mapbox_url && !datas.pmtiles_url,
      displayed: false,
      pluStatus: status ?? null,
    };

    return parsed;
  } catch (error: any) {
    throw new Error(error);
  }
}
