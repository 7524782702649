import { useLayoutEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getMapState } from '../../features/map/mapSlice';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { getAuthState } from '../auth/authSlice';
import styles from './builtCaracteristics.module.scss';
import { dpeActions, getDpeState } from './builtCaracteristicsSlice';
import DpeMarkerLegend from './dpe/dpeLegend';
import { fetchDpePointsThunk } from './dpe/dpeThunk';

function BuiltCaracteristicsLegend() {
  const { deniedZone } = useAppSelector(getAuthState);

  const { geolocDatas } = useAppSelector(getMapState);
  const dispatch = useAppDispatch();
  const { inseeCode, postalCode, dpePoints } = useAppSelector(getDpeState);

  useLayoutEffect(() => {
    if (
      (geolocDatas?.inseeCode && inseeCode !== geolocDatas?.inseeCode) ||
      (geolocDatas?.postalCode &&
        postalCode !== geolocDatas?.postalCode &&
        !deniedZone)
    ) {
      dispatch(
        dpeActions.setBaseData({
          inseeCode: geolocDatas?.inseeCode ?? null,
          postalCode: geolocDatas?.postalCode ?? null,
        })
      );

      if (dpePoints.apiStatus !== APIStatus.PENDING) {
        dispatch(
          fetchDpePointsThunk({
            insee: geolocDatas?.inseeCode as string,
            dpeAdvancedSearch: null,
          })
        );
      }
    }
  }, [geolocDatas?.inseeCode, geolocDatas?.postalCode]);

  return (
    <div className={styles.buildCaracteristicsLegend}>
      <DpeMarkerLegend />
    </div>
  );
}

export default BuiltCaracteristicsLegend;
