import { ChangeEvent, useState } from 'react';
import { useAppSelector } from '../../../../../../App/store';
import IndividualCircularLoader from '../../../../../../features/loaders/individualCircularLoader';
import { getStudyParamsState } from '../../../../../../features/study/slices/studyParamsSlice';
import AccordionBase from '../AccordionBase';
import BalanceBankAccounts from './BalanceBankAccounts';
import CommunalTaxes from './CommunalTaxes';
import EnvRisks from './EnvRisks';
import FiscalZone from './fiscalZone/FiscalZone';
import MultiEnvRisks from './MultiEnvRisks';
import MultiPlotOwnerAccordion from './MultiPlotOwnerAccordion';
import OwnerInfosAccordion from './OwnerInfosAccordion';
import ParkingPackages from './parkingPackage/ParkingPackages';
import TownHallAccordion from './TownHallAccordion';
import TransactionAccordion from './TransactionAccordion';

interface IPlotInfosContentProps {
  plot: nullable<Partial<IPlot>>;
  ownersPlots?: nullable<IOwnersPlots[]>;
  envRiskDatas?: IEnvRiskDatas[];
}
export default function TabInfosContent({
  plot,
  ownersPlots,
  envRiskDatas,
}: IPlotInfosContentProps) {
  const [expanded, setExpanded] = useState<string>('');
  const { type } = useAppSelector(getStudyParamsState);
  const handleChange =
    (panel: string) => (event: ChangeEvent, expanded: boolean) => {
      setExpanded(expanded ? panel : '');
    };

  const isPlotStudy = type === 'plotStudy';
  return (
    <div className="parcelle-infos-body">
      <h2>Infos Parcelle</h2>
      {!plot ? (
        <IndividualCircularLoader size={200} />
      ) : (
        <>
          {isPlotStudy && (
            <AccordionBase
              className="parcelle-infos"
              id={1}
              expanded={expanded}
              title="Transactions survenues"
              onChange={handleChange}
            >
              <TransactionAccordion transactions={plot?.transactions} />
            </AccordionBase>
          )}

          <AccordionBase
            className="parcelle-infos"
            id={2}
            expanded={expanded}
            title="Informations propriétaires"
            onChange={handleChange}
          >
            {type === 'plotStudy' ? (
              <OwnerInfosAccordion owners={plot?.ownerList?.list || []} />
            ) : (
              <MultiPlotOwnerAccordion ownersPlots={ownersPlots} />
            )}
          </AccordionBase>

          <AccordionBase
            className="parcelle-infos"
            id={3}
            expanded={expanded}
            title="Risques environnementaux"
            onChange={handleChange}
          >
            {isPlotStudy ? (
              <EnvRisks
                envs={plot?.parcelEnv || []}
                inseeCode={plot?.inseeCode || ''}
                number={plot?.number || ''}
                prefix={plot?.prefix || ''}
                section={plot?.section || ''}
              />
            ) : (
              <MultiEnvRisks envRiskDatas={envRiskDatas} />
            )}
          </AccordionBase>

          <AccordionBase
            className="parcelle-infos"
            id={4}
            expanded={expanded}
            title="Zonage fiscal"
            onChange={handleChange}
          >
            <FiscalZone zone={plot?.fiscalLaw} />
          </AccordionBase>

          <AccordionBase
            className="parcelle-infos"
            id={5}
            expanded={expanded}
            title="Taxe d'aménagement Communale"
            onChange={handleChange}
          >
            <CommunalTaxes taxes={plot?.taxes} />
            <ParkingPackages />
          </AccordionBase>

          {isPlotStudy && (
            <>
              <AccordionBase
                className="parcelle-infos"
                id={6}
                expanded={expanded}
                title="Informations mairie"
                onChange={handleChange}
              >
                <TownHallAccordion datas={plot?.townHall} />
              </AccordionBase>

              <AccordionBase
                className="parcelle-infos"
                id={7}
                expanded={expanded}
                title="Commune : balance comptable et données financières "
                onChange={handleChange}
              >
                <BalanceBankAccounts datas={plot?.balanceBankAccount} />
              </AccordionBase>
            </>
          )}
        </>
      )}
    </div>
  );
}
