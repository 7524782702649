import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import CommunalTaxe from './CommunalTaxe';

interface ICommunalTaxesProps {
  taxes: nullable<ITaxes[]>;
}
export default function CommunalTaxes({ taxes }: ICommunalTaxesProps): JSX.Element {
  const [isMultiple, setIsMultiple] = useState<boolean>(false);

  useEffect(() => {
    if (taxes) {
      setIsMultiple(taxes.length > 1);
    }
  }, [taxes]);

  return (
    <div className="communal-taxes">
      {isEmpty(taxes) ? (
        <p>Aucune</p>
      ) : (
        taxes?.map((t) => (
          <CommunalTaxe key={uuidv4()} tax={t} isMultiple={isMultiple} />
        ))
      )}
    </div>
  );
}
