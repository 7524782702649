import RightPanelModal from './RightPanelModal';

const PanelContainer = () => {
  return (
    <>
      <RightPanelModal />
    </>
  );
};

export default PanelContainer;
