import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../../App/store';
import { GenericModalEnum } from '../../../../components/Modals/GenericModal';
import { APIStatus } from '../../../../services/axiosFiles/apiTypes';
import { getDuplicatesFromArray } from '../../../../shared/utils/arrayTools';
import { modalsActions } from '../../../modals/modalsSlice';
import { fetchStudyContacts } from '../../../study/services/fetchStudyContacts';
import { mailshotsActions } from '../../mailshotsSlice';
import { MailshotsSelectionTypeEnum } from '../../types/mailshotsEnums';

const fetchMailshotPlotsContactsThunk = createAsyncThunk(
  'mailshot/fetchPlotSelectionContactsThunk',
  async (params: void, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const companyId = state.company.companyId;
      const contactRoles = state.app.contactRoles.result;
      const { studyPlotStudies } = state.study;
      const { selectedList, selectType } = state.mailshots;

      let plotList: MailshotPlots = [];

      if (
        selectType === MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER
      ) {
        plotList =
          studyPlotStudies.result?.map((m) => ({
            ...m,
            contacts: { list: [], duplicateContacts: [] },
          })) ?? [];
        dispatch(mailshotsActions.setPlotList(plotList));
      } else {
        plotList = [...selectedList];
      }

      if (isEmpty(plotList)) return selectedList;

      dispatch(
        mailshotsActions.setApiActionStatus({
          status: APIStatus.PENDING,
          error: null,
        })
      );
      const promises = Promise.all(
        plotList.map((m) => fetchStudyContacts(m.idIri, companyId, contactRoles))
      );

      return promises.then(
        (res) => {
          // const allContactsIdIri = res.flat(1).map((m) => m.contact.idIri);
          const duplicates = res.map((m) =>
            getDuplicatesFromArray(m.map((m) => m.contact.idIri))
          );

          const updatedPlotList = plotList.map((m, i) => {
            return {
              ...m,
              contacts: { list: res[i], duplicateContacts: duplicates[i] },
            };
          });

          return updatedPlotList as MailshotPlots;
        },
        (err) => {
          throw new Error(err);
        }
      );
    } catch (error) {
      dispatch(
        modalsActions.alert({
          status: true,
          context: 'fetchMailshotPlotsContactsError',
          modalType: GenericModalEnum.ERROR,
        })
      );
      return Promise.reject(error);
    }
  }
);

export default fetchMailshotPlotsContactsThunk;
