import { useAppDispatch, useAppSelector } from '../../../App/store';
import {
  getPlotServitudesState,
  plotServitudesActions,
} from '../plotServitudesSlice';
import styles from './plotServitudesContent.module.scss';

interface IPlotServitudesSectionRowProps {
  libelle: string;
  featureId: string;
  clickable?: boolean;
}

function PlotServitudesSectionRow({
  libelle,
  featureId,
  clickable = true,
}: IPlotServitudesSectionRowProps) {
  const { displayedLayers } = useAppSelector(getPlotServitudesState);
  const dispatch = useAppDispatch();

  const isChecked = displayedLayers.includes(featureId);

  const handleChange = () => {
    dispatch(plotServitudesActions.setDisplayedLayer(featureId));
  };

  return (
    <div className={styles.sectionRow}>
      {clickable ? (
        <>
          <input
            type="checkbox"
            name={featureId.toString()}
            onChange={handleChange}
            checked={isChecked}
          />
          <label htmlFor={featureId.toString()}>
            {libelle?.replaceAll('\\n', ' - ') ?? 'Aucun libellé'}
          </label>
        </>
      ) : (
        <p>{libelle}</p>
      )}
    </div>
  );
}

export default PlotServitudesSectionRow;
