import { nanoid } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { LatLngTuple } from 'leaflet';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../../../services/axiosFiles/genericCrud';

async function fetchSCOT({ latLng }: { latLng: LatLngTuple }) {
  try {
    if (latLng) {
      const result = await fetchDatas(
        `/geo_portail_urbanisme_gouv_api/feature-info/scot`,
        {
          lat: latLng[0],
          lon: latLng[1],
          typeName: 'scot',
          type: 'scot',
        }
      );

      const parsed: FeatureCollection = {
        ...result,
        features: (result as FeatureCollection).features.map((f) => ({
          ...f,
          id: nanoid(),
        })),
      };
      return parsed;
    } else {
      return Promise.reject(paramsNotFound('fetchSCOT'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchSCOT;
