import { changeApiKeyForStoreKey, mapObjectParser } from '../common/parserCommon';

export const plotStudyStatusMapping = {
  '@id': 'idIri',
  label: 'label',
  class: 'class',
};

export const contactRoleMapping = {
  '@id': 'idIri',
  id: 'id',
  label: 'label',
};

const sectorActionMapping = {
  '@id': 'idIri',
  id: 'id',
  label: 'label',
};

export const procedureTypeMapping = {
  '@id': 'idIri',
  id: 'id',
  label: 'label',
  description: 'description',
  icon: 'icon',
  isSectorType: 'isSectorType',
};

export const plotStudyStatusesParser = (data: any) =>
  mapObjectParser(data, plotStudyStatusParser);
export const plotStudyStatusParser = (data: any): StudyStatus =>
  changeApiKeyForStoreKey(data, plotStudyStatusMapping);

export const contactRolesParser = (data: any) =>
  mapObjectParser(data, contactRoleParser);
export const contactRoleParser = (data: any): ContactRole =>
  changeApiKeyForStoreKey(data, contactRoleMapping);

export const procedureTypesParser = (data: any) =>
  mapObjectParser(data, procedureTypeParser);
export const procedureTypeParser = (data: any): ProcedureType =>
  changeApiKeyForStoreKey(data, procedureTypeMapping);

export const sectorActionsParser = (data: any) =>
  mapObjectParser(data, sectorActionParser);
export const sectorActionParser = (data: any): ProcedureType =>
  changeApiKeyForStoreKey(data, sectorActionMapping);
