import { useFormContext } from 'react-hook-form';
import TextInputFormPdfErrialCustom from './TextInputFormPdfErrialCustom';

import styles from '../../styles/rightPanelModalExternalPdfErrial.module.scss';

function OwnerAndAddressSection() {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <div className={styles.columnInput}>
        <p>Identité des propriétaires à indiquer sur le document</p>
        <TextInputFormPdfErrialCustom
          name="IdentityOwnersOnDocument"
          error={Boolean(errors.IdentityOwnersOnDocument)}
          errorMsg={errors.IdentityOwnersOnDocument?.message as string}
        />
      </div>
      <div className={styles.columnInput}>
        <p>Adresse du bien à indiquer sur le document</p>
        <TextInputFormPdfErrialCustom
          name="addressCity"
          error={Boolean(errors.addressCity)}
          errorMsg={errors.addressCity?.message as string}
          className={styles.spacing}
          placeholder="Ville, code postal"
        />
        <TextInputFormPdfErrialCustom
          name="addressStreet"
          error={Boolean(errors.addressStreet)}
          errorMsg={errors.addressStreet?.message as string}
          placeholder="Rue, numéro d'adresse"
        />
      </div>
    </>
  );
}

export default OwnerAndAddressSection;
