import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import { getAuthState } from '../../../../../../features/auth/authSlice';
import { updateStudyThunk } from '../../../../../../features/study/services/thunks/studyThunks';
import ResponsableSelect from './ResponsableSelect';

interface ISectorResponsableContentProps {
  study: ISectorStudy;
  updateAllowed: boolean;
}
export default function SectorResponsableContent({
  study,
  updateAllowed,
}: ISectorResponsableContentProps) {
  const { user } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  const handleUpdate = useCallback(
    (data: UpdateStudyManagmentProps): void => {
      dispatch(
        updateStudyThunk({
          body: data,
        })
      );
    },
    [study]
  );
  return (
    <div className="sector-responsable-content">
      <ResponsableSelect
        onUpdate={handleUpdate}
        responsable={study?.responsable ?? (user as User)}
        disabled={!updateAllowed}
      />
    </div>
  );
}
