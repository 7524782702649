import IndividualCircularLoader from '../../../loaders/individualCircularLoader';
import styles from '../styles/prospecForm.module.scss';

interface IProps {
  ActiveTab: boolean;
  tab: SimilarTabType;
  count: number;
  onChange: () => void;
  isLoading: boolean;
}
const ProspecSimilatTypeTab = ({
  count,
  ActiveTab,
  tab,
  onChange,
  isLoading,
}: IProps) => {
  return (
    <div
      className={`${styles.tab} ${ActiveTab ? styles.active : ''}`}
      onClick={() => onChange()}
    >
      {isLoading ? (
        <IndividualCircularLoader size={15} className={styles.tabLoader} />
      ) : (
        <span>{count}</span>
      )}
      <span className={styles.adsTabLabel}> {tab.label}</span>
    </div>
  );
};

export default ProspecSimilatTypeTab;
