import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';

const initialState: DvfState = {
  dvfVisible: false,
};
const dvfSlice = createSlice({
  name: 'dvf',
  initialState,
  reducers: {
    setDvfOnMap: (state, action: PayloadAction<boolean>) => {
      state.dvfVisible = action.payload;
    },
    reset: () => initialState,
  },
});

export default dvfSlice.reducer;
export const dvfActions = dvfSlice.actions;
export const getDvfState = (state: RootState) => state.dvf;
