import { isEmpty } from 'lodash';
import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { updateEntity } from '../../../services/axiosFiles/genericCrud';

export const updatePlotStudyDatas = async (
  idIri: string,
  geolocDatas: GeolocPointInfoWithZoneAndSurface | null
) => {
  try {
    if (geolocDatas && idIri) {
      const {
        postalCode,
        inseeCode,
        address,
        city,
        zone,
        zoneChild,
        surface,
      } = geolocDatas;

      const body: any = {
        zipCode: postalCode ?? undefined,
        mapAddress: address ?? undefined,
        inseeCode: inseeCode ?? undefined,
        cityName: city ?? undefined,
        zone: zone ?? undefined,
        zoneChild: zoneChild ?? undefined,
        surface: surface ?? undefined,
      };

      const keys = Object.keys(body);
      const filtered = keys.reduce((p, c) => {
        if (body[c] !== undefined) p[c] = body[c];

        return p;
      }, {} as any);

      if (!isEmpty(filtered)) {
        await updateEntity({
          idIri,
          body: filtered,
        });
      }
    } else {
      return Promise.reject(paramsNotFound());
    }
  } catch (error: any) {
    return Promise.reject(error);
  }
};
