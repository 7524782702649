import { StyleSheet, View } from '@react-pdf/renderer';
import { ORPI_RED_COLOR } from '../../../shared/constants';
import EstimationPriceDisplay from './EstimationPriceDisplay';

const MAX_WIDTH = 491;

interface IProps {
  minPrice: number;
  maxPrice: number;
  price: number;
  area: number;
}
function EstimationPriceGraduation({ minPrice, maxPrice, price, area }: IProps) {
  const delta = maxPrice - minPrice;
  const gradianValue = price - minPrice;
  let leftMargin = Math.floor((MAX_WIDTH / delta) * gradianValue);
  if (maxPrice < price) leftMargin = MAX_WIDTH - 16;
  if (minPrice > price) leftMargin = 0;

  return (
    <View style={styles.estimationPriceGraduation}>
      <View style={styles.priceContainer}>
        <EstimationPriceDisplay price={price} sqrPrice={Math.floor(price / area)} />
      </View>
      <View style={styles.graduationContainer}>
        <View style={styles.graduationLine} />
        <View style={[styles.graduationCursor, { left: leftMargin }]}></View>
      </View>
      <View style={styles.forksContainer}>
        <EstimationPriceDisplay
          label="Fourchette basse"
          price={minPrice}
          sqrPrice={Math.floor(minPrice / area)}
        />
        <EstimationPriceDisplay
          label="Fourchette haute"
          price={maxPrice}
          sqrPrice={Math.floor(maxPrice / area)}
        />
      </View>
    </View>
  );
}

export default EstimationPriceGraduation;

const styles = StyleSheet.create({
  estimationPriceGraduation: {
    marginTop: '20px',
    borderWidth: '2px',
    borderColor: ORPI_RED_COLOR,
    borderRadius: '15px',
    paddingVertical: '20px',
    paddingHorizontal: '20px',
  },
  priceContainer: {
    color: '#727679',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
    fontSize: '16px',
  },
  forksContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5px',
    color: '#727679',
    fontSize: '10px',
  },
  graduationContainer: { position: 'relative' },
  graduationLine: {
    marginTop: '2px',
    height: '12px',
    backgroundColor: '#1d7ad0',
    borderRadius: '12px',
  },
  graduationCursor: {
    position: 'absolute',
    top: 0,
    borderColor: 'black',
    borderWidth: '2px',
    borderRadius: '50%',
    width: '16px',
    height: '16px',
  },
});
