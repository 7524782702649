import { Page } from '@react-pdf/renderer';
import GenericBloc from '../../shared/components/pdf/GenericBloc';
import { pdfStyles } from '../../shared/styles/pdfStyle';
import EnvConstraints from './components/EnvConstraints';
import LocalServitudes from './components/LocalServitudes';
import PageFooter from './components/PageFooter';
import PageHeader from './components/PageHeader';

interface IPageThreeProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  envConstraints: {
    isMonument: boolean;
    isRemarkableHeritage: boolean;
    isArchaeologicalPrescriptionArea: boolean;
    isPEBImpact: boolean;
    PEBValue: string;
  };
  localServitudes: OrpiServitudes;
  isOrpi: boolean;
}

function PageThree(props: IPageThreeProps) {
  return (
    <Page size="A4" style={pdfStyles.page}>
      <PageHeader
        date={props.date}
        address={props.address}
        fixed
        isOrpi={props.isOrpi}
      />

      <GenericBloc title="Contraintes non environnementales">
        <EnvConstraints {...props.envConstraints} />
      </GenericBloc>

      <GenericBloc
        title="Servitudes locales présentes sur le Géoportail de l’urbanisme"
        wrap={false}
      >
        <LocalServitudes {...props.localServitudes} />
      </GenericBloc>

      <PageFooter fixed isOrpi={props.isOrpi} />
    </Page>
  );
}

export default PageThree;
