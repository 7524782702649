import { Delta } from 'quill/core';
import { dateFormat } from '../../../lib/formats/dataFormat';

export const mailshotModelParser = (data: any): MailshotModelType => {
  const parsed: MailshotModelType = {
    id: data['@id'],
    name: data.name,
    content: JSON.parse(data.content) as Delta,
    modelType: data.modelType,
    isDefault: data.isDefault,
    isActive: data.isDefault ? true : data.isActive,
    createdAt: dateFormat(data.createdAt),
    updatedAt: dateFormat(data.updatedAt),
    companyIdIri: data.company,
    // values for generic tab display
    // canDelete: !data.isDefault,
    // canUpdate: true,
  };

  return parsed;
};
export const mailshotModelsParser = (datas: any[]): MailshotModelsType => {
  return datas.map((m) => mailshotModelParser(m));
};
