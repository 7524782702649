import ErrorNoDatas from '../../../components/errors/NoDatasError';
import styles from './pluLegend.module.scss';

function PluError() {
  return (
    <div className={styles.pluLegend}>
      <ErrorNoDatas />
    </div>
  );
}

export default PluError;
