import { paramsNotFound } from '../../../../../../services/axiosFiles/axiosUtils';
import { postEntity } from '../../../../../../services/axiosFiles/genericCrud';
import { prospecGetAdsStatsParser } from '../../../../landPDF/orpiLandDataParser';

interface IProps {
  prospecCityId: string | null;
  area: number | null;
}

async function fetchProspecGetAdsStats({ prospecCityId, area }: IProps) {
  try {
    if (prospecCityId && area) {
      const body = {
        prospec_city_id: prospecCityId,
        land_area: parseInt(area.toString(), 10),
      };
      const result: any = await postEntity({
        endpoint: '/pdf_urbanease/prospec_get_ads_stats',
        body,
      });

      if (result.status === 'OK' && result.ads_stats) {
        const parsed = prospecGetAdsStatsParser(result.ads_stats);

        return parsed;
      } else {
        throw new Error(result.status);
      }
    } else {
      return Promise.reject(paramsNotFound('fetchProspecDatasThunk'));
    }
  } catch (error) {
    return null;
  }
}

export default fetchProspecGetAdsStats;
