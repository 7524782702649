import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import { memo, useEffect, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import ChangeView from '../../../components/Map/ChangeView';
import ControlTooltips from '../../../components/Map/ControlTooltips';
import EnvLayers from '../../../components/Map/EnvLayers';
import EventLayer from '../../../components/Map/EventLayer';
import FrancePolygon from '../../../components/Map/FrancePolygon';
import ActivePlotBuildingMarkers from '../../../components/Map/activePlot/ActivePlotBuildingMarkers';
import ActivePlotGeo from '../../../components/Map/activePlot/ActivePlotGeo';
import ActivePlotMarker from '../../../components/Map/activePlot/ActivePlotMarker';
import AdvancedSearchMarkers from '../../advancedPlotSearch/map/AdvancedSearchMarkers';
import AdvancedSearchZone from '../../advancedPlotSearch/map/AdvancedSearchZone';
import BrownfieldsOnMap from '../../brownfields/components/BrownfieldsOnMap';
import BuildPermitsOnMap from '../../buildPermits/buildPermitsOnMap';
import DpeMarkersClusterGroup from '../../builtCaracteristics/dpe/dpeOnMap/DpeMarkersClusterGroup';
import DvfMarker from '../../dvf/DvfMarker';
import GeorisqueLayer from '../../georisques/GeorisqueLayer';
import NationalServitudesLayer from '../../nationalServitudes/NationalServitudesLayer';
import MultiPlotSelectLayer from '../../plot/multiplotSelectLayer';
import PlotServitudesLayers from '../../plotServitudes/plotServitudesLayers';
import './Map.scss';

import { useLocation } from 'react-router';
import { useAppSelector } from '../../../App/store';
import PluLayer from '../../../components/Map/PluLayer';
import SatelliteLayer from '../../../components/Map/SatelliteLayer';
import TownHallMarker from '../../../components/Map/TownHallMarker';
import ZoomButtonsMap from '../../../components/Map/ZoomButtonsMap';
import PmTilesCadastre from '../../../components/Map/pmTilesCadastre';
import { PDF_URLS } from '../../../shared/constants';
import EntitiesDisplayLayer from '../../displayManager/components/DisplayManagerLayer';
import { getExternalPdfErrialState } from '../../externalPdf/externalPdfSlice';
import FolderPlotStudiesFitbound from '../../folders/map/FolderPlotStudiesFitbound';
import AltimetryLayer from './plugins/altimetryPlugin/AltimetryLayer';
import DrawPlugin from './plugins/drawPlugin/DrawPlugin';
import MeasureLayer from './plugins/measurePlugin/components/MeasureLayer';

const Map = () => {
  const { externalPdfErrialRightPanelDisplay, isMultiPlotSelectorPdf } =
    useAppSelector(getExternalPdfErrialState);
  const [externalPdfErrial, setExternalPdfErrial] = useState<boolean>(
    externalPdfErrialRightPanelDisplay
  );
  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);

  useEffect(() => {
    setExternalPdfErrial(externalPdfErrialRightPanelDisplay);
  }, [externalPdfErrialRightPanelDisplay]);

  return (
    <MapContainer
      id="leafletMap"
      scrollWheelZoom={true}
      className={`urbn-map ${pdfPath && externalPdfErrial && 'external-pdf-errial'}`}
      zoomControl={false}
      doubleClickZoom={false}
    >
      {path === '/' && (
        <>
          <DrawPlugin />
          <MeasureLayer />
          <AltimetryLayer />
          <EnvLayers />
          <BuildPermitsOnMap />
          <TownHallMarker />
          <DpeMarkersClusterGroup />
          <BrownfieldsOnMap />
          <AdvancedSearchMarkers />
          <AdvancedSearchZone />
          <NationalServitudesLayer />
          <PlotServitudesLayers />
          <GeorisqueLayer />
          <DvfMarker />
          <PluLayer />
          <FolderPlotStudiesFitbound />
          <ActivePlotBuildingMarkers />
          <EntitiesDisplayLayer />
        </>
      )}

      <ChangeView />
      <EventLayer />
      <FrancePolygon />
      <SatelliteLayer />
      <PmTilesCadastre />
      {!isMultiPlotSelectorPdf && (
        <>
          <ActivePlotGeo />
          <ActivePlotMarker />
        </>
      )}
      <MultiPlotSelectLayer />
      <ControlTooltips />
      <ZoomButtonsMap />
    </MapContainer>
  );
};

export default memo(Map);
