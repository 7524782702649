import { Font, StyleSheet } from '@react-pdf/renderer';
import { ORPI_RED_COLOR } from '../../../shared/constants';
import Montserrat500 from '../../../shared/fonts/Montserrat/static/Montserrat-Bold.ttf';
import Montserrat700 from '../../../shared/fonts/Montserrat/static/Montserrat-ExtraBold.ttf';
import MontserratLight from '../../../shared/fonts/Montserrat/static/Montserrat-Light.ttf';
import MontserratRegular from '../../../shared/fonts/Montserrat/static/Montserrat-Medium.ttf';
import OrpiMedium from '../../../shared/fonts/orpi/Orpi-Medium.ttf';
import OrpiRegular from '../../../shared/fonts/orpi/Orpi-Regular.ttf';

Font.register({
  family: 'MontserratFamily',
  fonts: [
    {
      src: MontserratLight,
      fontStyle: 'normal',
      fontWeight: 300,
    },
    {
      src: MontserratRegular,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: Montserrat500,
      fontStyle: 'normal',
      fontWeight: 500,
    },
    {
      src: Montserrat700,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
});

Font.register({
  family: 'OrpiFamily',
  fonts: [
    {
      src: OrpiRegular,
      fontStyle: 'normal',
      fontWeight: 400,
    },
    {
      src: OrpiMedium,
      fontStyle: 'normal',
      fontWeight: 700,
    },
  ],
});
const hyphenationCallback = (word: string) => {
  return [word];
};

Font.registerHyphenationCallback(hyphenationCallback);

export const pageStyles = StyleSheet.create({
  page: {
    position: 'relative',
    padding: '0 30px',
    fontFamily: 'OrpiFamily',
    fontSize: '12px',
    paddingBottom: '55px',
  },
});

export const flexStyles = StyleSheet.create({
  flexCenterCenterRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexBetweenCenterRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexBetweenStartRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  flexCenterCenterColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexStartCenterRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  flexStartStartRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  flexStartCenterColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  flexStartStartColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  flexStartBetweenColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
});

export const fontStyles = StyleSheet.create({
  fs8: {
    fontSize: '8px',
  },
  fs10: {
    fontSize: '10px',
  },
  fs11: {
    fontSize: '11px',
  },
  fs12: {
    fontSize: '12px',
  },
  fs14: {
    fontSize: '14px',
  },
  fs15: {
    fontSize: '15px',
  },
  fs16: {
    fontSize: '16px',
  },
  fs18: {
    fontSize: '18px',
  },
  fs20: {
    fontSize: '20px',
  },
  fs21: {
    fontSize: '21px',
  },
  fs24: {
    fontSize: '24px',
  },
  fs30: {
    fontSize: '30px',
  },
  fs36: {
    fontSize: '36px',
  },
  fs48: {
    fontSize: '48px',
  },
  b700: {
    fontWeight: 700,
  },
  cOrpi: { color: ORPI_RED_COLOR },
  bcOrpi: { backgroundColor: ORPI_RED_COLOR },
});
