export enum MailshotsSelectionTypeEnum {
  NONE = 'NONE',
  DASHBOARD_FOLDER = 'DASHBOARD_FOLDER',
  RIGHT_PANEL_PROSPECTION_PLOT = 'RIGHT_PANEL_PROSPECTION_PLOT',
  RIGHT_PANEL_PROSPECTION_SUBFOLDER = 'RIGHT_PANEL_PROSPECTION_SUBFOLDER',
}

export enum MailshotTagEnum {
  // plot
  NUMERO_PARCELLE = 'NUMERO_PARCELLE',
  SURFACE_PARCELLE = 'SURFACE_PARCELLE',
  ADRESSE_PARCELLE = 'ADRESSE_COMPLETE_PARCELLE',
  ZONE_PARCELLE = 'ZONE_PLU_PARCELLE',

  // contact
  NOM_CONTACT = 'NOM_CONTACT',
  PRENOM_CONTACT = 'PRENOM_CONTACT',
  CONTACT_ADRESSE_1 = 'CONTACT_NUMERO_DE_RUE',
  CONTACT_ADRESSE_2 = 'CONTACT_ENTREE_BATIMENT',
  CONTACT_ADRESSE_3 = 'CONTACT_RUE_DE_L_ADRESSE',
  CONTACT_ADRESSE_4 = 'CONTACT_BP_OU_LIEU_DIT',
  CONTACT_CODE_POSTAL = 'CONTACT_CODE_POSTAL',
  CONTACT_VILLE = 'CONTACT_VILLE',
  CONTACT_PAYS = 'CONTACT_PAYS',

  // user
  NOM_PROSPECTEUR = 'NOM_UTILISATEUR',
  PRENOM_PROSPECTEUR = 'PRENOM_UTILISATEUR',
  EMAIL_PROSPECTEUR = 'EMAIL_UTILISATEUR',

  // company
  NOM_COMPAGNIE = 'NOM_DE_LA_SOCIETE',
  ADRESSE_COMPAGNIE = 'ADRESSE_DE_LA_SOCIETE',
  TELEPHONE_COMPAGNIE = 'TELEPHONE_DE_LA_SOCIETE',

  // subfolder
  MULTIPLOT_TO_STRING = 'LISTE_DES_PARCELLES',
}
