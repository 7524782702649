import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchProspecGetSimilarAds from './requests/fetchProspecGetSimilarAds';

const fetchProspecSimilariesThunk = createAsyncThunk(
  'externalPdfErrial/fetchProspecSimilariesThunk',
  async (params: {
    prospecCityId: string | null;
    area: number | null;
    price: number | null;
  }) => {
    try {
      const { area, price, prospecCityId } = params;

      const result = await fetchProspecGetSimilarAds({
        area,
        price,
        prospecCityId,
      });

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchProspecSimilariesThunk;
