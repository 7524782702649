import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { getAppState } from '../App/appSlice';
import { useAppDispatch, useAppSelector } from '../App/store';
import Logo from '../assets/images/logo_urbanease_horizontal.svg';
import AccountTabs from '../components/Account/AccountTabs';
import PasswordTab from '../components/Account/PasswordTab/PasswordTab';
import SubscriptionTab from '../components/Account/SubscriptionTab';
import TeamTab from '../components/Account/TeamTab/TeamTab';
import UrbaneaseAcademyTab from '../components/Account/UrbaneaseAcademyTab/UrbaneaseAcademyTab';
import WarningRDVTab from '../components/Account/WarningRDVTab';
import { getAuthState } from '../features/auth/authSlice';
import { logoutThunk } from '../features/auth/authThunks';
import useCompany from '../features/company/useCompany';
import MailshotAccountTab from '../features/mailshots/components/MailshotAccountTabs';
import {
  getMailshotsState,
  mailshotsActions,
} from '../features/mailshots/mailshotsSlice';
import MyAccountModalContainer from '../features/modals/MyAccountModalContainer';
import { modalsActions } from '../features/modals/modalsSlice';
import './styles/account.scss';

const MyAccountPage = (): JSX.Element => {
  const { user, isManager, isLogged } = useAppSelector(getAuthState);
  const { companies, getCompanyName } = useCompany();
  const { accountTab } = useAppSelector(getAppState);
  const { updatingQuill } = useAppSelector(getMailshotsState);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleQuilConfirm = (value: AccountBannerActionsType) =>
    dispatch(
      mailshotsActions.setQuillDatasBeforeNavigation({
        content: null,
        bannerTab: value,
      })
    );

  const handleLogout = () => {
    if (updatingQuill) {
      handleQuilConfirm('logout');
    } else {
      dispatch(logoutThunk());
      navigate('/');
    }
  };

  const handleRedirection = () =>
    updatingQuill ? handleQuilConfirm('goToHome') : navigate('/');

  const handleChangeCompany = () =>
    updatingQuill
      ? handleQuilConfirm('companyChange')
      : dispatch(modalsActions.companyChange(true));

  return (
    <div className="main-wrapper-account">
      <div>
        <div className="header-wrapper">
          <div className="header-col-left">
            <img src={Logo} alt="logo" onClick={handleRedirection} />
          </div>
          <div className="header-col-right">
            <div>
              <p>{user?.email}</p>
              <p>
                {`${getCompanyName()?.name} ${
                  getCompanyName()?.postalCode
                    ? '(' + getCompanyName()?.postalCode + ')'
                    : ''
                }`}
              </p>
            </div>
            <div className="para-head">MON COMPTE</div>
            {(companies?.length ?? 0) > 1 && (
              <button onClick={handleChangeCompany} className="change-company">
                Changer de compte
              </button>
            )}

            <button onClick={handleRedirection}>retour vers Urbanease</button>

            <Tooltip
              title="Déconnexion"
              arrow
              placement="top"
              onClick={handleLogout}
            >
              <p className="logout">Déconnexion</p>
            </Tooltip>
          </div>
        </div>

        <AccountTabs selectedTab={accountTab} />

        {isLogged && (
          <div className="body-wrapper">
            {accountTab === 'abonnement' && <SubscriptionTab />}
            {isManager && accountTab === 'team' && <TeamTab />}
            {isManager && accountTab === 'mailshots' && <MailshotAccountTab />}
            {accountTab === 'pwd_change' && <PasswordTab />}
            {accountTab === 'faq' && <UrbaneaseAcademyTab />}
            {accountTab === 'rdv' && <WarningRDVTab />}
          </div>
        )}
      </div>
      <div className="footer-wrapper">
        <div className="inner-footer">
          <h3>Une question? Besoin d’aide?</h3>
          <div className="inner-para">Notre équipe est là pour vous aider</div>
          <a href="mailto:support@urbanease.io">
            <button>CONTACTEZ-NOUS</button>
          </a>
          <a
            className="legal-mentions-button"
            href="https://urbanease.s3.amazonaws.com/CGV%20et%20RGPD%20Urbanease.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            CGU et mentions légales
          </a>
        </div>
      </div>

      <MyAccountModalContainer />
    </div>
  );
};

export default MyAccountPage;
