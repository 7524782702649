import { Button } from '@mui/material';
import { memo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import {
  dashboardReportingActions,
  DashboardReportingSearchTypeEnum,
  getDashboardReportingState,
} from '../../../features/dashboardReporting/dashboardReportingSlice';
import ReportingIndividual from '../../../features/dashboardReporting/reportingIndividual';
import ReportingOverall from '../../../features/dashboardReporting/reportingOverall';
import './ReportingTab.scss';

function ReportingTab(): JSX.Element {
  const { searchType } = useAppSelector(getDashboardReportingState);
  const dispatch = useAppDispatch();

  const handleSearchModeClick = (value: DashboardReportingSearchTypeEnum): void => {
    dispatch(dashboardReportingActions.setSearchType(value));
  };

  return (
    <div className="tab-reporting">
      <div className="tab-inner">
        <div>
          <h1>Reporting</h1>
        </div>
        <div className="search-mode">
          <Button
            className={
              searchType === DashboardReportingSearchTypeEnum.OVERALL
                ? 'selected'
                : ''
            }
            onClick={() =>
              handleSearchModeClick(DashboardReportingSearchTypeEnum.OVERALL)
            }
          >
            Statistiques Globales
          </Button>
          <p>ou</p>
          <Button
            className={
              searchType === DashboardReportingSearchTypeEnum.INDIVIDUAL
                ? 'selected'
                : ''
            }
            onClick={() =>
              handleSearchModeClick(DashboardReportingSearchTypeEnum.INDIVIDUAL)
            }
          >
            Statistiques individuelles
          </Button>
        </div>
      </div>

      <div className="tab-body">
        {searchType === 'overall' ? <ReportingOverall /> : <ReportingIndividual />}
      </div>
    </div>
  );
}

export default memo(ReportingTab);
