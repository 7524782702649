import { nanoid } from '@reduxjs/toolkit';
import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';
import { MAILSHOT_MAX_PLOT_EDITION_COUNT } from '../../../../../shared/constants';
import useMailshotsSelection from '../../../hooks/useMailshotsSelection';
import styles from '../../../styles/mailshotsModal.module.scss';
import { MailshotsSelectionTypeEnum } from '../../../types/mailshotsEnums';
import MailshotPlotRow from './MailshotPlotRow';

function MailshotPlotList() {
  const {
    selectedList,
    selectType,
    folderContactsCount,
    selectedListCount,
    outofRangePlotEdit,
  } = useMailshotsSelection();

  return (
    <div className={styles.plotList}>
      <h4 className={styles.plotListTitle}>
        Parcelles sélectionnées
        {selectType !==
          MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER && (
          <GenericTooltip
            text={
              outofRangePlotEdit
                ? "Supprimez des parcelles afin de valider l'édition"
                : ''
            }
          >
            <span className={outofRangePlotEdit ? styles.outOfRange : ''}>
              &nbsp;
              {`(${selectedListCount}/${MAILSHOT_MAX_PLOT_EDITION_COUNT})`}
            </span>
          </GenericTooltip>
        )}
      </h4>
      <table className={styles.plotTable}>
        <thead>
          <tr>
            <th colSpan={2} />
            <th>Parcelle id</th>
            <th>Surface</th>
            <th>Zone</th>
            <th>Dossier</th>
            {selectType !==
              MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER && (
              <th className={styles.contactInfoColumn}>Contacts</th>
            )}
            <th colSpan={3} />
          </tr>
        </thead>
        <tbody>
          {selectedList.map((m, i) => (
            <MailshotPlotRow
              row={m}
              key={nanoid()}
              index={i}
              folderContactsCount={folderContactsCount}
              hasDuplicateContact={m.contacts.list.some((contact) =>
                m.contacts.duplicateContacts.includes(contact.contact.idIri)
              )}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default MailshotPlotList;
