import * as Cesium from 'cesium';
import entityPin from '../../../../assets/images/pin-urbanease.svg';

/**
 * Add leaflet selected plot
 * @param {Cesium.Viewer} viewer - viewer 3D cesium
 * @param {any} plot - plots datas from python
 * @param {StreetViewLatLngType} location - location to place Street View point
 * @returns {void}
 */
async function insertPlotEntityToCesium(
  viewer: Cesium.Viewer,
  plot: any,
  location: StreetViewLatLngType
) {
  return new Promise((resolve, reject) => {
    try {
      const points = JSON.parse(plot).coordinates[0].flat(2);

      // entity creation
      const iconPosition = Cesium.Cartesian3.fromDegrees(
        location.lng,
        location.lat,
        location.height ? location.height + 200 : 200
      );

      // add entity to cesium viewer
      const entity = viewer.entities.add({ position: iconPosition });

      // polygon
      const polygonPositions = Cesium.Cartesian3.fromDegreesArray(points);
      entity.polygon = new Cesium.PolygonGraphics({
        hierarchy: polygonPositions,
        material: Cesium.Color.RED.withAlpha(0.5),
      });

      // polyline
      const polylineStart = Cesium.Cartesian3.fromDegrees(
        location.lng,
        location.lat,
        location.height
      );
      entity.polyline = new Cesium.PolylineGraphics({
        positions: [polylineStart, iconPosition],
        width: 1,
        material: Cesium.Color.WHITE,
      });

      // pin
      entity.billboard = new Cesium.BillboardGraphics({
        image: entityPin,
        show: true,
        eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        width: 30,
        height: 46,
      });
      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  });
}

export default insertPlotEntityToCesium;
