import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalError } from '../../../../../services/axiosFiles/axiosUtils';

import { isEmpty } from 'lodash';
import { fetchPlotServitudesThunk } from '../fetchPlotServitudesThunk';
import { fetchDatasForPdfGenerationThunk } from './fetchDatasForPdfGenerationThunk';
const errialPdfRequestLauncherThunk = createAsyncThunk(
  'externalPdfErrial/pdfRequestLauncherThunk',
  async (
    params: { geo?: string; lat: number; lng: number; plots: string[] },
    thunkOptions
  ) => {
    try {
      const { lat, lng, plots, geo } = params;

      if (lat && lng && !isEmpty(plots) && geo) {
        thunkOptions.dispatch(
          fetchPlotServitudesThunk({
            plotGeometry: geo,
            coordinates: [lat, lng],
          })
        );

        thunkOptions.dispatch(
          fetchDatasForPdfGenerationThunk({
            plots: plots,
          })
        );
      }
    } catch (error) {
      return Promise.reject(InternalError(error));
    }
  }
);

export default errialPdfRequestLauncherThunk;
