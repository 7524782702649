import { Button } from '@mui/material';
import React, { MouseEventHandler, ReactNode } from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100px',
    fontWeight: 700,
    '&:hover': { backgroundColor: theme.palette.primary.main },
  },
}));

const AddButton = ({
  children,
  onClick,
  activeToolbar,
}: {
  children: ReactNode;
  onClick: MouseEventHandler;
  activeToolbar?: boolean;
}) => {
  const { classes } = useStyles();

  return (
    <Button
      className={classes.root}
      onClick={onClick}
      disabled={activeToolbar}
      data-cy="add-button"
    >
      {children}
    </Button>
  );
};

export default AddButton;
