import { G, Path, Svg } from '@react-pdf/renderer';
function AreaIconSvg() {
  return (
    <Svg width="23" height="23" viewBox="0 0 23 23">
      <G clip-path="url(#clip0_6_186)">
        <Path
          d="M0.00399344 16.6239C0.133642 16.3031 0.354179 16.084 0.707038 16.0358C1.20692 15.9677 1.62661 16.3566 1.63062 16.8992C1.6373 17.9002 1.63196 18.9025 1.63463 19.9048C1.63463 19.9837 1.61191 20.0665 1.65735 20.1454C1.74824 20.1253 1.78967 20.0492 1.84447 19.993C3.50585 18.3345 5.16456 16.6747 6.82326 15.0135C7.0585 14.7783 7.32047 14.6353 7.66665 14.7181C8.25876 14.8598 8.50202 15.5521 8.12376 16.0278C8.06362 16.104 7.99278 16.1708 7.92327 16.239C6.28462 17.8788 4.64596 19.5173 3.00864 21.1571C2.95384 21.2132 2.87632 21.2546 2.85627 21.3442C2.93513 21.3896 3.018 21.3669 3.09686 21.3669C4.0993 21.3696 5.1004 21.3629 6.10284 21.3709C6.75376 21.3762 7.16142 21.9977 6.88876 22.5496C6.78049 22.7701 6.59872 22.9051 6.37684 22.9947C4.30513 22.9947 2.23342 22.9933 0.160374 22.9987C0.0267154 22.9987 -0.00135291 22.9719 -1.63267e-05 22.8383C0.00533002 20.7668 0.00399344 18.6953 0.00399344 16.6239Z"
          fill="#5555A0"
        />
        <Path
          d="M16.6098 22.996C16.281 22.8623 16.0591 22.6365 16.0203 22.2716C15.9682 21.7772 16.3518 21.3736 16.8851 21.3709C17.8875 21.3642 18.8886 21.3682 19.8911 21.3682C19.9699 21.3682 20.0501 21.3682 20.1384 21.3682C20.1116 21.2533 20.0354 21.2119 19.9793 21.1557C18.3152 19.4892 16.6485 17.8253 14.9845 16.1588C14.6396 15.814 14.5822 15.4104 14.8174 15.0656C15.1021 14.6486 15.6434 14.5698 16.0337 14.8919C16.1085 14.9533 16.1754 15.0242 16.2449 15.0937C17.8782 16.7268 19.5115 18.3612 21.1461 19.993C21.2009 20.0478 21.2424 20.1253 21.3333 20.144C21.3774 20.0639 21.356 19.981 21.356 19.9021C21.3573 18.9159 21.3587 17.9282 21.356 16.942C21.356 16.6666 21.4268 16.4194 21.6407 16.239C21.8946 16.0252 22.1873 15.9597 22.4988 16.096C22.8115 16.2336 22.9973 16.4795 22.9973 16.8297C23 18.6245 23.0013 20.418 22.996 22.2128C22.9946 22.6138 22.7621 22.8597 22.3999 22.996C20.4698 22.996 18.5398 22.996 16.6098 22.996Z"
          fill="#5555A0"
        />
        <Path
          d="M0.00372314 0.60273C0.16144 0.176408 0.468856 -0.00267393 0.921959 -1.06344e-06C2.65685 0.00801753 4.39308 -1.0632e-06 6.12797 0.00534467C6.76686 0.0066811 7.15714 0.618767 6.88314 1.17873C6.72008 1.5115 6.43538 1.64515 6.07851 1.64515C5.08409 1.64648 4.08833 1.64515 3.09391 1.64648C3.01505 1.64648 2.93219 1.62109 2.85466 1.67188C2.88006 1.76008 2.95491 1.80285 3.01104 1.85898C4.62163 3.47472 6.23623 5.08646 7.84816 6.70087C8.23042 7.08443 8.24245 7.59093 7.88692 7.93172C7.5942 8.21237 7.13575 8.23643 6.81898 7.98385C6.73745 7.91836 6.66528 7.84218 6.59043 7.76868C5.00924 6.18768 3.42806 4.60534 1.84554 3.02568C1.7894 2.96955 1.74663 2.89471 1.65842 2.86932C1.60763 2.94683 1.63302 3.02969 1.63302 3.10854C1.63035 4.11086 1.63703 5.11319 1.62901 6.11551C1.625 6.73829 1.05695 7.14724 0.511626 6.93207C0.259011 6.83184 0.101294 6.64073 0.00372314 6.39349C0.00372314 4.46235 0.00372314 2.53254 0.00372314 0.60273Z"
          fill="#5555A0"
        />
        <Path
          d="M20.1223 1.64648C19.5997 1.64648 19.0771 1.64648 18.5558 1.64648C17.9798 1.64648 17.4037 1.6545 16.8276 1.64114C16.3879 1.63178 16.0578 1.31104 16.0177 0.88739C15.9816 0.501161 16.2449 0.125624 16.6285 0.0294005C16.7207 0.00668117 16.821 0.00400831 16.9172 0.00400831C18.6454 0.00267187 20.3723 0.00267187 22.1005 0.00400831C22.6739 0.00400831 22.9973 0.322079 22.9987 0.886054C23 2.63544 23.0027 4.38617 22.9973 6.13556C22.996 6.77571 22.3905 7.16862 21.8291 6.9C21.495 6.73963 21.3573 6.4563 21.356 6.09814C21.3533 5.10383 21.356 4.10953 21.3533 3.11389C21.3533 3.0337 21.3734 2.95084 21.3319 2.87066C21.2397 2.88536 21.1996 2.96554 21.1448 3.02034C19.5329 4.63207 17.9196 6.24648 16.3064 7.85688C15.9134 8.24846 15.4028 8.25915 15.0593 7.88896C14.7907 7.60029 14.7693 7.14991 15.0139 6.83852C15.078 6.757 15.1542 6.6835 15.2291 6.60999C16.8103 5.02899 18.3914 3.44666 19.974 1.86833C20.0314 1.81086 20.1076 1.77211 20.1758 1.72533C20.1571 1.6986 20.1397 1.67321 20.1223 1.64648Z"
          fill="#5555A0"
        />
      </G>
    </Svg>
  );
}

export default AreaIconSvg;
