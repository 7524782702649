import { Page } from '@react-pdf/renderer';
import ContaminatedAreas from '../../shared/components/pdf/ContaminatedAreas';
import GenericBloc from '../../shared/components/pdf/GenericBloc';
import SubTitleGenericBloc from '../../shared/components/pdf/SubTitleGenericBloc';
import { pdfStyles } from '../../shared/styles/pdfStyle';
import AtlasAziList from './components/AtlasAziList';
import AtlasList from './components/AtlasList';
import PageFooter from './components/PageFooter';
import PageHeader from './components/PageHeader';

interface IPageFiveProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  contaminatedAreas: ContaminatedAreas;
  environmentRisksDetails: EvironmentRisksDetails;
  isOrpi: boolean;
}

function PageFive(props: IPageFiveProps) {
  return (
    <>
      <Page size="A4" style={pdfStyles.page}>
        <PageHeader
          date={props.date}
          address={props.address}
          fixed
          isOrpi={props.isOrpi}
        />
        <GenericBloc title="Contraintes environnementales">
          <SubTitleGenericBloc subtitle="> Liste des Atlas et Documents de gestion des risques existants sur la commune :">
            <AtlasList
              title="• Plan de Prévention des Risques Naturels (PPRN)"
              datas={props.environmentRisksDetails.pprn}
            />
            <AtlasList
              title="• Plan de prévention des risques environnementaux et technologiques ( PPRT)"
              datas={props.environmentRisksDetails.pprt}
            />
            <AtlasList
              title="• Plan de prévention des risques Miniers (PPRM)"
              datas={props.environmentRisksDetails.pprm}
              wrap
            />
            <AtlasAziList
              title="• Atlas des zones inondables (AZI)"
              datas={props.environmentRisksDetails.azi}
              wrap
            />
          </SubTitleGenericBloc>

          <SubTitleGenericBloc
            subtitle="> Sites et sols pollués – dans un périmètre de 500 m par rapport au bien étudiés"
            wrap
          >
            <ContaminatedAreas areas={props.contaminatedAreas} />
          </SubTitleGenericBloc>
        </GenericBloc>
        <PageFooter fixed isOrpi={props.isOrpi} />
      </Page>
    </>
  );
}

export default PageFive;
