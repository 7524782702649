import { useEffect, useState } from 'react';
import { GenericModalEnum } from '../../../../../components/Modals/GenericModal';
import GenericModalBackground from '../../../../../components/Modals/GenericModal/GenericModalBackground';
import {
  MailshotResumeContextProvider,
  useMailshotResumeContext,
} from '../../../contexts/mailshotResumeContext';
import useMailshotHydrate, {
  HydratedDatasType,
} from '../../../hooks/useMailshotHydrate';
import useMailshotsSelection from '../../../hooks/useMailshotsSelection';
import styles from '../../../styles/mailshotsModal.module.scss';
import MailshotContactFormModal from '../mailshotContactFormModal/MailshotContactFormModal';
import MailshotResumeContent from './MailshotResumeContent';
import MailshotResumeSuccessContent from './MailshotResumeSuccessContent';

type DisplayModalType = {
  type: 'contactForm' | 'default' | 'success' | 'error';
  datas?: HydratedDatasType;
};
export interface IMailshotSelectionResumeModalContentProps {
  studyContactList?: StudyContacts;
}

function MailshotSelectionResumeModalContent() {
  const { selectStaged, resetSelection, checkExistingContacts } =
    useMailshotsSelection();
  // default = resume
  const [displayType, setDisplayType] = useState<DisplayModalType>({
    type: 'default',
  });
  const { contactToUpdate } = useMailshotResumeContext();
  const { hydrateModel } = useMailshotHydrate();

  if (!selectStaged) return null;

  useEffect(() => {
    setDisplayType({ type: contactToUpdate ? 'contactForm' : 'default' });
  }, [contactToUpdate]);

  const TooltipTextComponent = () => {
    return (
      <div className={styles.centerTooltipContent}>
        Fermer la fenêtre
        <br />
        et annuler la sélection
      </div>
    );
  };

  const handleHydrateModel = async () => {
    const hydratedDatas = await hydrateModel();

    // if docx created, create procedures for each letter
    if (hydratedDatas) {
      setDisplayType({ type: 'success', datas: hydratedDatas });
    }
  };

  return (
    <GenericModalBackground
      modalType={
        displayType.type === 'success'
          ? GenericModalEnum.CONFIRMATION
          : displayType.type === 'error'
            ? GenericModalEnum.ERROR
            : GenericModalEnum.INFO
      }
      hasExtendWidth
      crossIcon={
        displayType.type === 'default'
          ? {
              onClose: resetSelection,
              tooltipText: <TooltipTextComponent />,
            }
          : undefined
      }
      className={displayType.type === 'success' ? styles.successContentWith : ''}
    >
      <div className={[styles.modalBase].join(' ')}>
        {displayType.type === 'default' && (
          <MailshotResumeContent onValid={handleHydrateModel} />
        )}
        {displayType.type === 'contactForm' && <MailshotContactFormModal />}
        {displayType.type === 'success' && displayType.datas && (
          <MailshotResumeSuccessContent datas={displayType.datas} />
        )}
      </div>
    </GenericModalBackground>
  );
}

function MailshotSelectionResumeModal() {
  return (
    <MailshotResumeContextProvider>
      <MailshotSelectionResumeModalContent />
    </MailshotResumeContextProvider>
  );
}

export default MailshotSelectionResumeModal;
