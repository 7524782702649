import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import { displayManagerActions } from '../../features/displayManager/displayManagerSlice';
import { sectorToEntityDisplay } from '../../features/displayManager/utils/entitiesDisplayParsers';
import { panelsActions } from '../../features/panels/panelsSlice';
import { setStudyParamsThunk } from '../../features/study/services/thunks/studyParamsThunks';
import EyeIcon from '../../shared/components/icons/EyeIcon';
import { IIconActionProps } from './ItemActions';
import styles from './itemActions.module.scss';

function EyeIconAction<T>({ type, item }: IIconActionProps<T>) {
  const dispatch = useAppDispatch();
  const { userIdIri } = useAppSelector(getAuthState);

  const handleDetails = () => {
    const studyParams: StudyParamsState = {
      type,
      idIri: item.idIri,
      noCheckExisting: true,
      managmentLoader: true,
    };

    if (type === 'sector') {
      dispatch(
        displayManagerActions.entitiesBackupAndReplace([
          sectorToEntityDisplay(item as ISector, userIdIri),
        ])
      );
    }

    dispatch(setStudyParamsThunk(studyParams));
    dispatch(panelsActions.studyContentShow('prospection'));
  };

  return (
    <EyeIcon
      tooltipText={
        type === 'folder'
          ? 'Détails sous-dossier'
          : type === 'sector' && !item.parent
            ? 'Détails secteur'
            : 'Détails sous-secteur'
      }
      width={37}
      height={37}
      color="WHITE"
      bgColor="PRIMARY"
      isRoundIcon
      onClick={handleDetails}
      className={styles.icon}
    />
  );
}

export default EyeIconAction;
