import { StyleSheet, View } from '@react-pdf/renderer';

interface IProps {
  marginTop?: string;
  marginBottom?: string;
}
function PdfSeparateLine({ marginBottom = '40px', marginTop = '40px' }: IProps) {
  return <View style={[styles.line, { marginBottom, marginTop }]} />;
}

export default PdfSeparateLine;

const styles = StyleSheet.create({
  line: { borderTop: '1px solid #D0D0D0', marginVertical: '40px' },
});
