import { ChangeEvent, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { GenericModalEnum } from '../../../components/Modals/GenericModal';
import GenericModalBackground from '../../../components/Modals/GenericModal/GenericModalBackground';
import postCompanyLogoThunk from '../../../features/company/services/thunks/uploadCompanyLogoThunk';
import IndividualCircularLoader from '../../../features/loaders/individualCircularLoader';
import { getModalsState, modalsActions } from '../../../features/modals/modalsSlice';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import ButtonGeneric from '../../components/buttons/buttonGeneric/ButtonGeneric';
import FormButtonContainer from '../../components/buttons/formButtonContainer/FormButtonContainer';
import {
  UPLOAD_IMAGE_AUTHORIZED_EXTENSIONS,
  UPLOAD_IMAGE_MAX_SIZE,
} from '../../constants';
import ExistingImageSection from './ExistingImageSection';
import ImageToUpload from './ImageToUpload';
import UploadFailContent from './UploadFailContent';
import styles from './uploadModal.module.scss';
import UploadSuccessContent from './UploadSuccessContent';

export type ImageToUploadType = {
  name: string;
  url: string;
  file: File;
};

export type UploadResultType = {
  state: APIStatus;
  message: string;
};

const IMAGE_HEIGHT_DISPLAY = 70;
const initialUploadResultState = { state: APIStatus.IDLE, message: '' };

function UploadImageModal() {
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploadImageModal } = useAppSelector(getModalsState);

  const [image, setImage] = useState<ImageToUploadType | null>(null);

  const [uploadResult, setUploadResult] = useState<UploadResultType>(
    initialUploadResultState
  );
  const dispatch = useAppDispatch();

  if (!uploadImageModal.isOpen) return null;

  const handleClose = () => dispatch(modalsActions.uploadModalClose());
  const handleChoiceClick = () => inputRef.current?.click();

  const handleResetClick = () => {
    setImage(null);
    setUploadResult(initialUploadResultState);
  };

  const uploadResponse = (isGood: boolean) => {
    setUploadResult(
      isGood
        ? { state: APIStatus.FULFILLED, message: '' }
        : {
            state: APIStatus.REJECTED,
            message: "L'image n'a pas pu être enregistrée",
          }
    );
  };

  const handleUploadImagToS3 = () => {
    switch (uploadImageModal.type) {
      case 'companyLogo':
        if (image?.file) {
          setUploadResult({
            state: APIStatus.PENDING,
            message: "Sauvegrde de l'image",
          });
          dispatch(
            postCompanyLogoThunk({ file: image.file, callback: uploadResponse })
          );
        }
        break;

      default:
        break;
    }
  };

  const handleImageDownloadFromDisk = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) {
      setUploadResult({
        state: APIStatus.REJECTED,
        message: 'Aucun fichier trouvé',
      });
      return;
    }

    if (!uploadImageModal.params!.validTypes?.includes(file.type)) {
      setUploadResult({
        state: APIStatus.REJECTED,
        message:
          'Type invalide. seuls les formats JPG, JPEG, PNG et WEBP sont autorisés.',
      });
      return;
    }

    if (
      uploadImageModal.params!.maxSize &&
      file.size > uploadImageModal.params!.maxSize
    ) {
      setUploadResult({
        state: APIStatus.REJECTED,
        message: 'La taille du fichier exède le maximum de 10Mo.',
      });
      return;
    }

    const imageUrl = URL.createObjectURL(file);
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      if (
        (uploadImageModal.params.maxWidth &&
          img.width > uploadImageModal.params.maxWidth) ||
        (uploadImageModal.params.maxHeight &&
          img.height > uploadImageModal.params.maxHeight)
      ) {
        setUploadResult({
          state: APIStatus.REJECTED,
          message: `Les dimensions autorisées sont de ${uploadImageModal.params.maxWidth}px de largeur et de ${uploadImageModal.params.maxHeight}px de hauteur`,
        });
        return;
      }

      setUploadResult(initialUploadResultState);
      setImage({ name: file.name, url: imageUrl, file });
    };
  };

  return (
    <div className={styles.uploadModal}>
      <GenericModalBackground
        modalType={GenericModalEnum.INFO}
        crossIcon={{ onClose: handleClose, tooltipText: 'Fermer la fenêtre' }}
      >
        <div className={styles.uploadModalContent}>
          <h3>Téléchargement d&lsquo;image</h3>

          <div className={styles.uploadContentBody}>
            {uploadResult.state === APIStatus.PENDING ? (
              <IndividualCircularLoader size={100} />
            ) : uploadResult.state === APIStatus.FULFILLED ? (
              <UploadSuccessContent callback={handleResetClick} />
            ) : uploadResult.state === APIStatus.REJECTED ? (
              <UploadFailContent result={uploadResult} callback={handleResetClick} />
            ) : (
              <>
                <div className={styles.imageContainer}>
                  <ExistingImageSection
                    isVisible={!image}
                    type={uploadImageModal.type}
                    url={uploadImageModal.existingUrl}
                    height={IMAGE_HEIGHT_DISPLAY}
                  />
                  <ImageToUpload
                    image={image}
                    uploadResult={uploadResult}
                    height={IMAGE_HEIGHT_DISPLAY}
                  />
                </div>
                {image ? (
                  <FormButtonContainer
                    canCancel
                    onCancel={handleResetClick}
                    onValid={handleUploadImagToS3}
                    validContent="Enregistrer"
                    cancelContent="Changer image"
                  />
                ) : (
                  <>
                    <p>
                      Taille maximum de l&lsquo;image :{' '}
                      {UPLOAD_IMAGE_MAX_SIZE / (1024 * 1024)} Mo
                      <br />
                      Extensions autorisées :{' '}
                      {UPLOAD_IMAGE_AUTHORIZED_EXTENSIONS.join(', ')}
                    </p>
                    <ButtonGeneric
                      content={`${uploadImageModal.existingUrl ? 'Modifiez' : 'Téléchargez'} votre logo`}
                      onClick={handleChoiceClick}
                      containerClassName={styles.button}
                    />
                  </>
                )}
                <input
                  ref={inputRef}
                  type="file"
                  accept=".jpg,.jpeg,.png,.webp"
                  onChange={handleImageDownloadFromDisk}
                />
              </>
            )}
          </div>
        </div>
      </GenericModalBackground>
    </div>
  );
}

export default UploadImageModal;
