import PLUButton from '../PluButton';
import styles from './pluLegend.module.scss';

function AskForPlu() {
  return (
    <div className={`${styles.pluLegend} ${styles.noPlu}`}>
      <p>PLU pas encore disponible.</p>
      <p>Demander la disponibilité du PLU pour cette commune :</p>
      <PLUButton />
    </div>
  );
}

export default AskForPlu;
