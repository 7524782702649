import RowContentTag from '../../../../components/markerPopupGeneric/RowContentTag';
import styles from '../dpe.module.scss';

interface IDpePopupContentPrps {
  content: DPE;
}

function DpePopupContent({ content }: IDpePopupContentPrps) {
  return (
    <div className={styles.dpePopupContent}>
      <RowContentTag label={'N° DPE'} value={content.dpeId} type="p" />
      <RowContentTag label={'Date DPE'} value={content.date} type="p" />
      <RowContentTag label={'Classe DPE'} value={content.dpeClass} type="p" />
      <RowContentTag label={'Classe GES'} value={content.gesClass} type="p" />
      <RowContentTag label={'Parcelle id'} value={content.plotId} type="p" />
      <RowContentTag label={'Adresse'} value={content.address} type="p" />
      <RowContentTag label={'Code postal'} value={content.postalCode} type="p" />
      <RowContentTag label={'Ville'} value={content.city} type="p" />
      <RowContentTag
        label={'Type installation ECS'}
        value={content.ecsInstallationType}
        type="p"
      />
      <RowContentTag label={'Typologie DPE'} value={content.dpeTypology} type="p" />
      <RowContentTag
        label={'Année construction'}
        value={content.builtYear}
        type="p"
      />
      <RowContentTag
        label={'Surface estimée'}
        value={content.area}
        type="p"
        unit="m²"
      />
      <RowContentTag label={'Bâtiment'} value={content.built} type="p" />
      <RowContentTag
        label={'Typologie bâti'}
        value={content.builtTypology}
        type="p"
      />
      <RowContentTag label={'Type chauffage'} value={content.heatingType} type="p" />
      <RowContentTag
        label={'Hauteur sous plafond'}
        value={content.heightUnderCeiling}
        type="p"
      />
      <RowContentTag
        label={"Nombre d'appartements"}
        value={content.appartmentCount}
        type="p"
      />
      <RowContentTag
        label={'Nombre niveaux logements'}
        value={content.builtLevels}
        type="p"
      />
      {/* <RowContentTag label={'Pièces'} value={content.roomCount} type="p" /> */}
      <RowContentTag label={'Etage'} value={content.floor} type="p" />
    </div>
  );
}

export default DpePopupContent;
