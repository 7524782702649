import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { postEntity } from '../../../../services/axiosFiles/genericCrud';
import { displayManagerActions } from '../../../displayManager/displayManagerSlice';
import { sectorToEntityDisplay } from '../../../displayManager/utils/entitiesDisplayParsers';

import { mapPluginsActions } from '../../../map/leaflet/plugins/mapPluginSlice';
import { modalsActions } from '../../../modals/modalsSlice';
import { sectorParser } from '../../sectorParser';
import sectorsFetchFilteredThunk from './sectorsFetchFilteredThunk';

const sectorCreateThunk = createAsyncThunk(
  'sectors/sectorCreateThunk',
  async (
    params: { name: string; wkbGeometry: any; mailBoxes: number },
    thunkOptions
  ) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { companyIdIri } = state.company;
      const { users } = state.users;
      const { userIdIri } = state.auth;
      const { subSectorParent, sectors } = state.sectors;
      const { name, wkbGeometry } = params;

      if (name && wkbGeometry && companyIdIri && userIdIri && users) {
        const result = await postEntity({
          endpoint: `/sectors`,
          body: {
            name,
            wkbGeometry: JSON.stringify(wkbGeometry),
            parentSector: subSectorParent?.idIri,
            company: companyIdIri,
            responsable: userIdIri,
          },
        });

        const parsed = sectorParser(result, users);
        const indexed = {
          ...parsed,
          indexId: subSectorParent
            ? subSectorParent.subCount + 1
            : (sectors.result && sectors.result?.length + 1) ?? 1,
        };

        thunkOptions.dispatch(mapPluginsActions.resetDraw());

        thunkOptions.dispatch(
          displayManagerActions.entitiesAdd([
            sectorToEntityDisplay(indexed, userIdIri),
          ])
        );
        thunkOptions.dispatch(modalsActions.sectorEdit(false));
        thunkOptions.dispatch(sectorsFetchFilteredThunk({}));
        return indexed;
      } else {
        return thunkOptions.rejectWithValue(paramsNotFound('sectorCreateThunk'));
      }
    } catch (error) {
      console.log(error);
      return thunkOptions.rejectWithValue(error);
    }
  }
);

export default sectorCreateThunk;
