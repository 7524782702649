import { createAsyncThunk } from '@reduxjs/toolkit';
import { delay } from 'lodash';
import { resetAppAction } from '../../App/appSlice';
import { RootState } from '../../App/store';
import LocalStorageService from '../../services/LocalStorageService';
import { postEntityNoToken } from '../../services/axiosFiles/genericCrud';
import { authErrorMessages } from '../../shared/errorMessages';
import { setAuthenticatedCompanyThunk } from '../company/companyThunks';
import { modalsActions } from '../modals/modalsSlice';
import companyUserFetch from '../users/services/companyUserFetch';
import userFetch from '../users/services/userFetch';
import { clearStorages } from './utils';

export const classicLoginThunk = createAsyncThunk(
  'auth/classicLoginThunk',
  async (params: ILoginParams, { dispatch }) => {
    try {
      const response: any = await postEntityNoToken({
        endpoint: '/authentication_token',
        body: params,
      });

      if (response) {
        const r = response;

        // set localStorage with token and refresh token
        LocalStorageService.setToken(r.token);
        LocalStorageService.setRefreshToken(r.refresh_token);

        // modal display handle
        dispatch(modalsActions.closeAllAuthModal());

        // launch connection process
        dispatch(modalsActions.connectionProcess(true));

        return { token: r.token, refreshToken: r.refresh_token };
      } else {
        throw new Error('No response');
      }
    } catch (error: any) {
      if (error?.status === 401) {
        // check for 401 error reason
        switch (error.message) {
          case authErrorMessages.badCredentials.errMessage:
            return Promise.reject(authErrorMessages.badCredentials.displayMessage);
          case authErrorMessages.companyNotFound.errMessage:
            return Promise.reject(authErrorMessages.companyNotFound.displayMessage);
          case authErrorMessages.subscriptionNotFound.errMessage:
            return Promise.reject(
              authErrorMessages.subscriptionNotFound.displayMessage
            );
          case authErrorMessages.expiredSubscription.errMessage:
            dispatch(modalsActions.expiredSubscriptionModalOpen());
            return Promise.reject(
              authErrorMessages.expiredSubscription.displayMessage
            );
          default:
            return Promise.reject(error);
        }
      } else {
        return Promise.reject(error);
      }
    }
  }
);

export const externalConnectLoginThunk = createAsyncThunk(
  'auth/externalConnectLoginThunk',
  async (params: ILoginResponse, { rejectWithValue, dispatch }) => {
    try {
      clearStorages();

      dispatch(resetAppAction());

      delay(() => {
        dispatch(modalsActions.signin(true));
        dispatch(modalsActions.signup(false));
      }, 1);

      delay(() => {
        LocalStorageService.setToken(params.token);
        LocalStorageService.setRefreshToken(params.refreshToken);
      }, 100);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logoutThunk = createAsyncThunk(
  'auth/logoutThunk',
  async (params: void, { rejectWithValue, dispatch }) => {
    try {
      clearStorages();

      // little timer before dispatch because clear storage is async
      dispatch(resetAppAction());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchAuthUserThunk = createAsyncThunk(
  'auth/fetchAuthUserTunk',
  async (params: { userIdIri?: string }, { getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const { companyId, companyIdIri } = state.company;

      if (!companyId) throw new Error('company id missing');

      // load user
      const user = await userFetch(params.userIdIri);

      // load companyUser for roles and userIsActive
      const companyUser = await companyUserFetch(user.id, companyId);

      if (
        user.idIri !== companyUser.userIdIri ||
        companyIdIri !== companyUser.companyIdIri
      ) {
        user.isActive = false;
        user.roles = ['ROLE_USER'];
      } else {
        user.isActive = companyUser.userIsActive;
        user.roles = companyUser.roles;
      }

      dispatch(setAuthenticatedCompanyThunk({ companyId, userId: user.id }));
      return user;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// request for receive reinit email
export const forgotPasswordThunk = createAsyncThunk(
  'auth/forgotPasswordThunk',
  async (params: { email: string | null }, { rejectWithValue }) => {
    try {
      if (params.email) {
        const promise = postEntityNoToken({
          endpoint: '/reset_password_request',
          body: { email: params.email },
        });

        promise.then(
          (response: any) => {
            return response;
          },
          (err) => {
            rejectWithValue(err);
          }
        );
      } else {
        rejectWithValue(new Error('email param not found'));
      }
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export const resetPasswordThunk = createAsyncThunk(
  'auth/resetPasswordThunk',
  async (
    params: {
      email: string | null;
      password: string | null;
      token: string | null;
      navigate: any;
    },
    { rejectWithValue, dispatch }
  ) => {
    const { email, password, token, navigate } = params;
    try {
      if (email && password && token) {
        const promise = postEntityNoToken({
          endpoint: '/reset_password',
          body: { email, token, password },
        });

        return promise.then(
          (response: any) => {
            dispatch(classicLoginThunk({ email, password }));
            navigate('/');
          },
          (err) => {
            return rejectWithValue(err);
          }
        );
      } else {
        return rejectWithValue(new Error('One param is missing'));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const firstPasswordThunk = createAsyncThunk(
  'auth/firstPasswordThunk',
  async (
    params: {
      email: string;
      password: string;
      token: string;
      navigate: any;
    },
    { rejectWithValue, dispatch }
  ) => {
    const { email, password, token, navigate } = params;

    try {
      if (email && password && token) {
        const promise = postEntityNoToken({
          endpoint: '/set_first_password',
          body: { email, token, password },
        });

        promise.then(
          (response: any) => {
            dispatch(classicLoginThunk({ email, password }));
            navigate('/');
          },
          (err) => {
            return rejectWithValue(err);
          }
        );
      } else {
        return rejectWithValue(new Error('One param is missing'));
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
