import { Image, Text, View } from '@react-pdf/renderer';
import NoImage from '../../../../../assets/images/orpiPdf/imageNotAvailable.png';
import { pdfStyles } from '../styles/pdfStylesCommon';

interface IPageOneProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  ownerName: string;
  plotIds: string[];
  user: {
    lastName: string;
    firstName: string;
    phone: string;
    email: string;
  };
  companyName: string;

  homePagePicture: string;
}

const PageOneCommon = (datas: IPageOneProps) => {
  return (
    <>
      <View style={pdfStyles.homePlotIdsSection}>
        <Text style={pdfStyles.homePlotIdsTitle}>Parcelle(s) étudiée(s)</Text>
        <Text style={pdfStyles.homePlotIdsText}>{datas.plotIds.join(', ')}</Text>
      </View>

      {datas.homePagePicture ? (
        <Image
          src={`data:image/jpeg;base64,${datas.homePagePicture}`}
          style={pdfStyles.homeMapImage}
        />
      ) : (
        <Image src={NoImage} style={pdfStyles.homeMapImage} />
      )}
      <View style={pdfStyles.homeOwnerAndAddressBloc}>
        <View style={pdfStyles.homeOwnerSection}>
          <Text>Étude réalisée pour:</Text>
          <Text style={pdfStyles.homeOwnerData}>{datas.ownerName}</Text>
        </View>
        <View style={pdfStyles.homeAddressSection}>
          <Text>{datas.address.addressLine2}</Text>
          <Text>{datas.address.addressLine1}</Text>
        </View>
      </View>
    </>
  );
};

export default PageOneCommon;
