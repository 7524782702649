import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { ReactComponent as Cross } from '../../assets/images/cross.svg';
import { getPanelState, panelsActions } from '../panels/panelsSlice';
import UrbaChatBox from './UrbaChatBox';
import UrbaGptForm from './UrbaGptForm';
import UrbaGptTutorial from './UrbaGptTutorial';
import styles from './urbaGpt.module.scss';
import { getUrbaGptState, urbaGptActions } from './urbaGptSlice';

function UrbaGpt(): JSX.Element {
  const dispatch = useAppDispatch();
  const { conversation, showTutorial, urbaGptIsVisible, urbaGptInitOpen } =
    useAppSelector(getUrbaGptState);
  const { rightPanelDisplay, leftPanelDisplay } = useAppSelector(getPanelState);
  const [open, setOpen] = useState(urbaGptIsVisible);

  const handleUrbaGpt = () => {
    if (!open) {
      if (rightPanelDisplay) dispatch(panelsActions.rightPanelClose());
      if (leftPanelDisplay) dispatch(panelsActions.leftPanelCollapse());
    }
    dispatch(urbaGptActions.setUrbaGptIsVisible(!open));
    setOpen(!open);
  };

  useEffect(() => {
    dispatch(urbaGptActions.setShowTutorial(isEmpty(conversation.result)));
  }, [conversation]);

  useEffect(() => {
    dispatch(urbaGptActions.setConversationIsAnimFalse());
    setOpen(urbaGptIsVisible);
  }, [urbaGptIsVisible]);

  return (
    <div className={styles.urbaGpt}>
      <div
        onClick={() => handleUrbaGpt()}
        className={`${styles.urbaGptBackdrop} ${
          !urbaGptIsVisible && styles.invisible
        }`}
      />
      <div
        className={`${urbaGptInitOpen && styles.urbaGptContainer} ${
          urbaGptIsVisible ? styles.visible : styles.invisible
        }`}
      >
        {open && (
          <>
            <div className={styles.urbaGptHeader}>
              <h4>
                UrbaGPT: l&apos;intelligence artificielle au service de votre
                prospection
              </h4>
              <div onClick={() => handleUrbaGpt()}>
                <Cross />
              </div>
            </div>
            <div className={styles.urbaGptContent}>
              {showTutorial ? (
                <UrbaGptTutorial />
              ) : (
                <UrbaChatBox conversation={conversation.result} />
              )}
              <UrbaGptForm />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default UrbaGpt;
