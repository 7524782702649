import { updateEntity } from '../../../services/axiosFiles/genericCrud';
import { studyContactApiToStoreParser } from '../utils/parsers/studyContactParser';

interface IUpdateStudyContactProps {
  body: Partial<StudyContactForm>;
  studyContactToUpdate: IStudyContact | null;
  contactRoles: ContactRoles | null;
  type: StudyParamsType | null;
  studyIdIri: string | null;
}
async function updateStudyContact({
  body,
  studyContactToUpdate,
  contactRoles,
  studyIdIri,
  type,
}: IUpdateStudyContactProps) {
  try {
    //check params
    if (!studyContactToUpdate) throw new Error('no studyContact to update found');
    if (!body) throw new Error('no form body found');
    if (!type) throw new Error('no study type found');
    if (!contactRoles) throw new Error('no contactRoles found');
    if (!studyIdIri) throw new Error('no study idIri body found');

    // update study contact
    const studyContactResult = await updateEntity({
      idIri: studyContactToUpdate.idIri,
      body: {
        contact: studyContactToUpdate.contact.idIri,
        [type]: studyIdIri,
        contactRole: body.roleIdIri,
      },
    });

    // parse
    const parsedStudyContact = studyContactApiToStoreParser(
      studyContactResult,
      contactRoles
    );

    // return updated study contact
    return parsedStudyContact;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default updateStudyContact;
