import { format } from 'date-fns';

/**
 * Dpe points request params parser
 * @param {string|null} - isee code of city
 * @param {IDpeAdvancedSearchForm|null} - advanced search parameters
 * @returns {any} - Parsed dpePoints request parameters
 */
export function dpeRequestParamBuilder({
  insee,
  dpeAdvancedSearch,
}: {
  insee: string | null;
  dpeAdvancedSearch: IDpeAdvancedSearchForm | null;
}) {
  try {
    let body: any = { insee };

    const advancedSearchParams = dpeAdvancedSearchParamBuilder({
      dpeAdvancedSearch,
    });

    return { ...body, ...advancedSearchParams };
  } catch (error) {
    throw new Error('dpeRequestParamBuilder error');
  }
}

/**
 * Dpe points request advanced search params parser
 * @param {IDpeAdvancedSearchForm|null} - advanced search parameters
 * @returns {any} - parsed dpePoints advanced search parameters
 */
export function dpeAdvancedSearchParamBuilder({
  dpeAdvancedSearch,
}: {
  dpeAdvancedSearch: IDpeAdvancedSearchForm | null;
}) {
  try {
    if (dpeAdvancedSearch) {
      // create advanced search criterias
      let body: any = {
        date_min: dpeAdvancedSearch.createdAtAfter
          ? format(new Date(dpeAdvancedSearch.createdAtAfter), 'yyyy-MM-dd')
          : undefined,
        date_max: dpeAdvancedSearch.createdAtBefore
          ? format(new Date(dpeAdvancedSearch.createdAtBefore), 'yyyy-MM-dd')
          : undefined,
        built_area_min: dpeAdvancedSearch.builtAreaMin,
        built_area_max: dpeAdvancedSearch.builtAreaMax,
        dpe_min: dpeAdvancedSearch.dpeMin,
        dpe_max: dpeAdvancedSearch.dpeMax,
        ges_min: dpeAdvancedSearch.gesMin,
        ges_max: dpeAdvancedSearch.gesMax,
        building_type: dpeAdvancedSearch.buildingType,
      };

      // new object excluding keys with false values
      body = Object.fromEntries(
        Object.entries(body).filter(([key, value]) => value)
      );

      return body;
    } else {
      return {};
    }
  } catch (error) {
    throw new Error('dpeAdvancedSearchParamBuilder error');
  }
}
