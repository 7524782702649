import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { addSpaceToNumber } from '../../../../utils/jsFunctions';
import Footer from './components/Footer';
import Header from './components/Header';
import { fontStyles, pageStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  prospecDatas: FormatedProspecDatasType;
  avgPriceImg: string;
  avgPriceAreaImg: string;
  formDatas: IExternalPdfLandForm;
}

function PriceAnalysisPage({
  agency,
  prospecDatas,
  avgPriceImg,
  avgPriceAreaImg,
  formDatas,
}: IProps) {
  const stats = prospecDatas.adsStats;
  return (
    <Page style={pageStyles.page}>
      <Header
        lines={['L’analyse des prix', 'Abordez la vente avec toutes les clés']}
        fixed
      />

      <Text style={styles.title}>Abordez la vente avec toutes les clés</Text>

      <Text>
        Pour la typologie des terrains similaires au vôtre (
        <Text style={fontStyles.b700}>{prospecDatas.type?.text}</Text>
        ), sur la base de(s){' '}
        <Text style={fontStyles.b700}>{stats?.soldExplotableStock}</Text>{' '}
        transaction(s) et de(s){' '}
        <Text style={fontStyles.b700}>{stats?.sellExplotableStock} terrain(s)</Text>{' '}
        à la vente observé(s) au cours des{' '}
        <Text style={fontStyles.b700}>12 derniers mois</Text>, nous constatons les
        données suivantes:
      </Text>

      <View style={styles.list}>
        <Text style={styles.listLine}>
          - le prix moyen des biens en vente est de{' '}
          <Text style={fontStyles.b700}>
            {stats && addSpaceToNumber(stats.sellAveragePrice)} €
          </Text>{' '}
          ( mini :{' '}
          <Text style={fontStyles.b700}>
            {stats && addSpaceToNumber(stats.sellMinPrice)} €
          </Text>{' '}
          / Maxi :{' '}
          <Text style={fontStyles.b700}>
            {stats && addSpaceToNumber(stats.sellMaxPrice)} €
          </Text>
          )
        </Text>
        <Text style={styles.listLine}>
          - la surface moyenne des biens à la vente est de{' '}
          <Text style={fontStyles.b700}>
            {(stats && addSpaceToNumber(stats?.sellAverageArea)) ?? '-'} m²
          </Text>
        </Text>
        <Text style={styles.listLine}>
          - le prix moyen au m² des biens à la vente est de{' '}
          <Text style={fontStyles.b700}>
            {stats && addSpaceToNumber(stats.sellAveragePriceArea)} €/m²
          </Text>
        </Text>
      </View>

      <View style={styles.chartContainer}>
        <View>
          <Text style={styles.graphTitle}>
            Prix moyen des terrains sur les 12 derniers mois
          </Text>
          <Image src={avgPriceImg} style={styles.graph} />
        </View>
        <View>
          <Text style={styles.graphTitle}>
            Prix moyen au m² des terrains sur les 12 derniers mois
          </Text>
          <Image src={avgPriceAreaImg} style={styles.graph} />
        </View>
      </View>

      <Footer agency={agency} formDatas={formDatas} />
    </Page>
  );
}

export default PriceAnalysisPage;

const styles = StyleSheet.create({
  title: {
    marginBottom: '15px',
    ...fontStyles.b700,
    ...fontStyles.fs20,
    ...fontStyles.cOrpi,
    width: '100%',
    textAlign: 'center',
  },
  chartContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '40px',
  },
  graphTitle: { fontWeight: 700 },
  graph: {
    width: '300px',
    height: '142px',
    marginVertical: '40px',
  },
  list: {
    marginTop: '20px',
  },
  listLine: { paddingVertical: '5px' },
});
