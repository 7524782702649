import { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);

interface IPieGraphProps {
  labels: any[];
  datasets: any;
}

function PieGraph({ labels, datasets }: IPieGraphProps): JSX.Element {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChartRef = (chartRef as any)?.current.getContext('2d');

    const chart = new Chart(myChartRef, {
      type: 'pie',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              generateLabels: () => {
                const labels = datasets[0].data.map(
                  (data: ReportingUserIndividualPlotsStatus) => ({
                    text: `${data.label} - ${data.plots} opportunités`,
                    fillStyle: data.color,
                  })
                );
                return [
                  {
                    text: `TOTAL: ${datasets[0].data.reduce(
                      (acc: number, elt: ReportingUserIndividualPlotsStatus) =>
                        acc + elt.plots,
                      0
                    )} opportunités`,
                    fillStyle: '#ffffff',
                    lineWidth: 0,
                  },
                  ...labels,
                ];
              },
            },
          },
          datalabels: {
            labels: {
              value: {
                borderRadius: 5,
                backgroundColor: '#ffffff',
                color: '#000000',
              },
            },
            formatter: (value, ctx) => {
              let sum = 0;
              const dataArr = ctx.chart.data.datasets[0].data;
              dataArr.map((data: any) => {
                sum += data.plots;
              });
              const percentage = ((value.plots * 100) / sum).toFixed(2) + '%';
              return percentage;
            },
          },
        },
        animation: false,
        parsing: {
          key: 'plots',
        },
      },
      plugins: [ChartDataLabels],
    });
    return () => chart.destroy();
  }, [labels, datasets]);

  return (
    <div className="graph-container">
      <canvas id="myChart" ref={chartRef} />
    </div>
  );
}

export default PieGraph;
