import { nanoid } from '@reduxjs/toolkit';
import { LatLngBoundsExpression } from 'leaflet';
import { isEmpty } from 'lodash';
import { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import MarkerBase from '../../../components/Map/Common/MarkerBase';
import {
  advancedPlotSearchActions,
  getAdvancedPlotSearchState,
} from '../advancedPlotSearchSlice';
import MarkerTooltip from './MarkerTooltip';
import styles from './markerTooltip.module.scss';

const AdvancedSearchMarkers = () => {
  const { plots, isCentered } = useAppSelector(getAdvancedPlotSearchState);
  const map = useMap();
  const dispatch = useAppDispatch();
  const calcBounds = () => {
    const coords = plots.result?.map((f) => [f.lat, f.lng]);

    return coords;
  };

  useEffect(() => {
    if (!isEmpty(calcBounds()) && !isCentered) {
      dispatch(advancedPlotSearchActions.setIsCentered(true));
      map.fitBounds(calcBounds() as LatLngBoundsExpression, { maxZoom: 18 });
    }
  }, [plots.result]);

  return (
    <>
      {plots.result?.map((parcel) => (
        <MarkerBase
          markerLatLng={[parcel.lat, parcel.lng]}
          key={nanoid()}
          markerUrl="jaune"
          hasTooltip
          className={styles.markerBaseTooltip}
          tooltipMessage={<MarkerTooltip />}
        />
      ))}
    </>
  );
};

export default memo(AdvancedSearchMarkers);
