import commonFetch from './commonFetch';

/**
 * Fetch assiette sup S informations datas
 * @param {string}}plotGeometry stringified geometry
 * @param {boolean}}parseForPDF unused
 * @returns {object} servitudes
 */
async function fetchAssietteSupS({
  plotGeometry,
}: {
  plotGeometry: string;
  parseForPDF?: boolean;
}) {
  try {
    // force stringify value
    let pg: string =
      typeof plotGeometry === 'string' ? plotGeometry : JSON.stringify(plotGeometry);

    const result = await commonFetch({
      url: 'https://apicarto.ign.fr/api/gpu/assiette-sup-s?',
      plotGeometry: pg,
    });

    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchAssietteSupS;
