import TypingAnimation from './TypingAnimation';
import ScrollButton from './ScrollButton';
import styles from './urbaGpt.module.scss';
import { useEffect, useRef } from 'react';
import { nanoid } from '@reduxjs/toolkit';

interface IUrbaChatBoxProps {
  conversation: UrbaGptHistory[];
}
function UrbaChatBox({ conversation }: IUrbaChatBoxProps): JSX.Element {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const loadingMessages = [
    'Je réfléchis pour apporter une réponse à votre question, merci de me laisser quelques secondes...',
    'Je synthétise mes connaissances pour vous apporter la réponse le plus claire possible ...',
    'Je prépare une réponse qui, j’espère, vous conviendra ! Un instant je vous prie ...',
    'Bien reçu, je génère une réponse intelligente, un instant ...',
    '... génération de votre réponse en cours, merci de me laisser synthétiser, un petit instant :)',
    '... recherche dans mon réseau neuronal ... votre réponse est en cours de synthétisation ...',
    '[UrbaGPT] Requête reçu ! Je génère une réponse sur mesure pour vous, un instant !',
    'Votre réponse arrive ! je synthétise le résultat de mes recherches, un instant je vous prie :)',
    '... un petit instant, la réponse arrive !',
    'UrbaGPT, à votre service ! Je vous génère une réponse intelligente, un petit instant',
    'Génération de la réponse intelligente en cours ... un petit instant :)',
  ];

  function selectRandomString() {
    const randomIndex = Math.floor(Math.random() * loadingMessages.length);
    return loadingMessages[randomIndex];
  }

  useEffect(() => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [conversation, messagesEndRef]);

  return (
    <>
      <div className={styles.urbaChatBox}>
        {conversation.map((elt) => (
          <>
            <div
              key={nanoid()}
              className={styles.question}
              dangerouslySetInnerHTML={{
                __html: elt.question,
              }}
            />
            {elt.response ? (
              <>
                {elt.isAnim ? (
                  <TypingAnimation text={elt.response} />
                ) : (
                  <div
                    className={styles.response}
                    dangerouslySetInnerHTML={{
                      __html: elt.response,
                    }}
                  />
                )}
              </>
            ) : (
              <TypingAnimation text={selectRandomString()} />
            )}
          </>
        ))}
        <div ref={messagesEndRef} />
        <ScrollButton messagesEndRef={messagesEndRef} />
      </div>
    </>
  );
}

export default UrbaChatBox;
