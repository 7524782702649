import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  envConstraint: {
    display: 'flex',
    flexDirection: 'row',
  },
  envConstraintActive: {
    display: 'flex',
    flexDirection: 'row',
  },
  active: {
    backgroundColor: '#fbe8ea',
  },
  valueContainer: {
    fontSize: '16px',
    width: '15%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  line: {
    margin: '0 10px',
    height: '100%',
    border: '1px solid #679CC6',
  },
  descriptionContainer: {
    width: '75%',
    paddingLeft: '20px',
    display: 'flex',
    justifyContent: 'center',
    borderLeft: '1px solid #679CC6',
  },

  title: { fontSize: '12px' },
  text: { fontSize: '10px', fontWeight: 300 },
});
interface IEnvConstraintProps {
  isActive: boolean;
  value?: string;
  title: string;
  text: string;
}
function EnvConstraint(props: IEnvConstraintProps) {
  const envStyle = props.isActive
    ? { ...styles.envConstraint, ...styles.active }
    : { ...styles.envConstraint };

  return (
    <View style={envStyle}>
      <View style={styles.valueContainer}>
        <Text>{props.isActive ? 'OUI' : 'NON'}</Text>
        {props.value && <Text>Plot</Text>}
      </View>

      <View style={styles.descriptionContainer}>
        <Text style={styles.title}>{props.title}</Text>
        <Text style={styles.text}>{props.text}</Text>
      </View>
    </View>
  );
}

export default EnvConstraint;
