import { call, put, take } from 'redux-saga/effects';
import {
  favoritePlaceApiToStoreParser,
  favoritePlacesApiToStoreParser,
  favoritePlaceStoreToApiParser,
} from '../../features/favoritePlaces/parser';
import {
  deleteEntity,
  fetchDatas,
  postEntity,
} from '../../services/axiosFiles/genericCrud';
import { REQUEST } from '../common/actions';
import {
  ADD_FAVORITE_SEARCH,
  DELETE_FAVORITE_SEARCH,
  favoritePlacesActions,
  FETCH_FAVORITE_SEARCH,
} from './actions';

export function* handleFetchFavoritePlaces(): any {
  while (true) {
    try {
      const { payload } = yield take(FETCH_FAVORITE_SEARCH[REQUEST]);

      const response = yield call(fetchDatas, `${payload}/favorite_searches`);
      const parsedResponse = favoritePlacesApiToStoreParser(
        response['hydra:member']
      );

      yield put(favoritePlacesActions.fetchFavoriteSearches.success(parsedResponse));
    } catch (error) {
      yield put(favoritePlacesActions.fetchFavoriteSearches.failure());
    }
  }
}

export function* handleAddFavoritePlaces(): any {
  while (true) {
    try {
      const { payload } = yield take(ADD_FAVORITE_SEARCH[REQUEST]);

      const parsedBody = favoritePlaceStoreToApiParser(payload);

      const response = yield call(postEntity, {
        endpoint: '/favorite_searches',
        body: parsedBody,
      });
      const parsedResponse = favoritePlaceApiToStoreParser(response);

      yield put(favoritePlacesActions.addFavoriteSearch.success(parsedResponse));
    } catch (error) {
      yield put(favoritePlacesActions.addFavoriteSearch.failure());
    }
  }
}

export function* handleDeleteFavoritePlaces(): any {
  while (true) {
    try {
      const { payload } = yield take(DELETE_FAVORITE_SEARCH[REQUEST]);

      const response = yield call(deleteEntity, `/favorite_searches/${payload}`);

      if (response === 204) {
        yield put(favoritePlacesActions.deleteFavoriteSearch.success(payload));
      } else {
        yield put(favoritePlacesActions.deleteFavoriteSearch.failure());
      }
    } catch (error) {
      yield put(favoritePlacesActions.deleteFavoriteSearch.failure());
    }
  }
}
