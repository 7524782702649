import { useEffect, useState } from 'react';

function useElementInDOM(elementIds: string[]) {
  const [obs, setObs] = useState<MutationObserver | null>(null);
  const [isInDOM, setIsInDOM] = useState<boolean>(false);

  useEffect(() => {
    // check if all elements are in dom
    const checkElements = () => {
      const entityTab: any = [];

      elementIds.forEach((e) => entityTab.push(document.getElementById(e)));

      if (entityTab.every((s: any) => s !== null)) {
        obs?.disconnect();
        setIsInDOM(true);
      }
    };

    checkElements();

    // MutationObserver to watch for changes in the DOM
    const observer = new MutationObserver(checkElements);
    observer.observe(document.body, { childList: true, subtree: true });
    setObs(observer);

    // Cleanup the observer on component unmount
    return () => {
      obs?.disconnect();
    };
  }, []);

  // external MutationObserver disconnect
  const interruptObserver = () => {
    if (obs) {
      obs.disconnect();
    }
  };

  return { isInDOM, interruptObserver };
}

export default useElementInDOM;
