import * as Yup from 'yup';

export interface IPasswordTabForm {
  password: string;
  verifyPassword: string;
}

export const initialPasswordForm: IPasswordTabForm = {
  password: '',
  verifyPassword: '',
};

export const passwordValidationSchema = Yup.object({
  password: Yup.string().required('Mot de passe requis'),
  verifyPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Les mots de passe ne correspondent pas')
    .required('Mot de passe requis'),
});
