import { changeApiKeyForStoreKey } from '../../services/common/parserCommon';
import { decodeText } from '../../utils/jsFunctions';

export const urbaGptQueryMapping = {
  conversation_uuid: 'conversationUuid',
  gpt_response: 'gptResponse',
  user_id: 'userId',
};

export const urbaGptHistoryMapping = {
  conversation_uuid: 'conversationUuid',
  datelog: 'datelog',
  id: 'id',
  question: 'question',
  response: 'response',
};

export const urbaGptQueryParser = (data: UrbaGptQueryResponse): UrbaGptQuery => {
  try {
    const parsedData: UrbaGptQuery = changeApiKeyForStoreKey(
      data,
      urbaGptQueryMapping
    );
    parsedData.gptResponse = decodeText(
      parsedData.gptResponse.replace(/\n/g, '<br />')
    );

    return parsedData;
  } catch (error) {
    throw new Error("the urba gpt query answer can't be parsed");
  }
};

export const urbaGptHistoryParser = (
  data: UrbaGptHistoryResponse[]
): UrbaGptHistory[] => {
  try {
    const parsedData: UrbaGptHistory[] = data.map((elt: UrbaGptHistoryResponse) =>
      changeApiKeyForStoreKey(elt, urbaGptHistoryMapping)
    );
    parsedData.forEach((e) => {
      e.question = decodeText(e.question.replace(/\n/g, '<br />'));
      e.response = decodeText(e.response.replace(/\n/g, '<br />'));
      e.isAnim = false;
    });

    return parsedData;
  } catch (error) {
    throw new Error("the urba gpt history answer can't be parsed");
  }
};
