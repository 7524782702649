import { updateEntity } from '../../../services/axiosFiles/genericCrud';
import { classicErrorMessage } from '../../../shared/errorMessages';

export type UserUpdateResponse = {
  company: string;
  user: string;
  roles: UserRoleType[];
  userIsActive?: boolean;
};

/**
 * Update user role or isActive datas for this company
 * @param {string} idIri  - user idIri
 * @param {string} companyIdIri - compny idIri
 * @param {?UserRoleTypes} roles -user role array
 * @param {?boolean} userIsActive - isActive user value
 * @returns {CompanyUser} - roles and isActive value for this user in that company
 */
async function companyUserUpdate(params: IUpdateCompanyUserProps) {
  try {
    const { companyIdIri, roles, userIdIri, userIsActive } = params;

    if (!userIdIri) throw new Error(classicErrorMessage.noUserId);
    if (!companyIdIri) throw new Error(classicErrorMessage.noCompanyId);

    const response = await updateEntity({
      idIri: '/company_users',
      body: { user: userIdIri, company: companyIdIri, roles, userIsActive },
    });

    return response as UserUpdateResponse;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default companyUserUpdate;
