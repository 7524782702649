import store from '../../../App/store';
import { GenericModalEnum } from '../../../components/Modals/GenericModal';
import { updateEntity } from '../../../services/axiosFiles/genericCrud';
import { modalsActions } from '../../modals/modalsSlice';
import { mailshotModelParser } from '../utils/mailshotModelParser';

async function updateMailshotModel(model: UpdateMailshotModelType) {
  try {
    if (!model) throw new Error('model is missing');
    if (!model.id) throw new Error('model id is missing');

    const body: any = {
      name: model.isDefault ? undefined : model.name,
      content: JSON.stringify(model.content),
      isActive: model.isDefault ? undefined : model.isActive,
      modelType: model.isDefault ? undefined : model.modelType,
    };

    const result = await updateEntity({ idIri: model.id, body });
    const parsed = mailshotModelParser(result);

    if (!parsed.id) parsed.id = model.id;

    return parsed;
  } catch (error) {
    store.dispatch(
      modalsActions.alert({
        status: true,
        context: 'updateMailshotModelError',
        modalType: GenericModalEnum.ERROR,
      })
    );
    return Promise.reject(error);
  }
}

export default updateMailshotModel;
