import { GenericModalEnum } from '.';
import CrossIcon from '../../../shared/components/icons/CrossIcon';
import styles from './genericModal.module.scss';
import { getCssTheme } from './utils';

interface IGenericModalBackgroundProps extends IChildren {
  hasExtendWidth?: boolean;
  modalType: GenericModalEnum;
  crossIcon?: { onClose?: () => void; tooltipText?: React.ReactNode };
  className?: string;
}

function GenericModalBackground({
  children,
  hasExtendWidth,
  modalType,
  crossIcon,
  className,
}: IGenericModalBackgroundProps) {
  return (
    <div className={styles.genericModalBackground}>
      <div
        className={[
          styles.genericModal,
          getCssTheme(modalType).styles,
          hasExtendWidth ? styles.extendWidth : '',
          className,
        ].join(' ')}
      >
        {crossIcon && (
          <div className={styles.closeIconContainer}>
            <CrossIcon
              className={styles.closeIcon}
              onClick={crossIcon.onClose}
              bgColor="TRANSPARENT"
              color="PRIMARY"
              width={26}
              height={26}
              tooltipText={crossIcon.tooltipText}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

export default GenericModalBackground;
