import { ChangeEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import NoCity from '../../../components/noRights/NoCity';
import { getMapState } from '../../../features/map/mapSlice';
import {
  getNationalServitudesState,
  servitudeActions,
} from '../nationalServitudesSlice';
import NationalServitudesContainer from './NationalServitudesContainer';
import styles from './nationalServitudesContent.module.scss';

function NationalServitudesLegend() {
  const { geolocDatas } = useAppSelector(getMapState);
  const { servitudesChecked } = useAppSelector(getNationalServitudesState);

  const dispatch = useAppDispatch();

  const handleCheckChange = (
    servitude: Servitude,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;

    dispatch(servitudeActions.setServitudesChecked({ servitude, checked }));
  };

  return (
    <div className={styles.contentContainer}>
      {geolocDatas?.inseeCode ? (
        <>
          <div className={styles.allServitudes}>
            <div className={styles.checkbox}>
              <input
                type="checkbox"
                name={servitudesChecked.layer}
                onChange={(e) => handleCheckChange({ ...servitudesChecked }, e)}
                checked={servitudesChecked.checked}
              />
              <label htmlFor={servitudesChecked.layer}>
                Afficher toutes les servitudes
              </label>
            </div>
          </div>
          <NationalServitudesContainer />
        </>
      ) : (
        <NoCity />
      )}
    </div>
  );
}

export default NationalServitudesLegend;
