import { postEntity } from '../../../../../../services/axiosFiles/genericCrud';
import pricehubbleGenericParser from '../../../../../../shared/parsers/pricehubbleGenericParser';
import { accessibilityParser } from '../../../../landPDF/orpiLandDataParser';

interface IProps extends IPHTokenAndPHId {
  index: number;
  lng: string | number;
  lat: string | number;
  department: string;
  mode: string;

  callback: (value: string) => void;
}

async function fetchAccessibilityDatas({
  index,
  entityId,
  lng,
  lat,
  department,
  mode,
  phAccessToken,
  callback,
}: IProps) {
  try {
    // **** REAL BODY
    const body = {
      dossierId: entityId,
      ph_access_token: phAccessToken,
      lon: lng.toString(),
      lat: lat.toString(),
      department,
      mode,
    };

    if (entityId && lng && lat && department && mode) {
      const result: any = await postEntity({
        endpoint: '/pdf_urbanease/pricehubble_get_accessibility_datas/',
        body,
      });
      const phPreParser = pricehubbleGenericParser(result);
      const parsed = accessibilityParser(phPreParser);
      callback(`Données d'accessibilité ${index} chargées`);

      return parsed;
    } else {
      throw new Error('fetchAccessibilityDatas missing params');
    }
  } catch (error) {
    return null;
  }
}

export default fetchAccessibilityDatas;
