// File: MyContext.tsx
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from 'react';
import { useAppDispatch } from '../../../../../../App/store';

// Définir le type de votre context
interface RightPanelProcedureContextType {
  // Ajoutez les propriétés que vous souhaitez partager à l'échelle globale ici
  deleteId: string | null;
  setDeleteId: Dispatch<SetStateAction<string | null>>;
}

// Créer le context
const RightPanelProcedureContext = createContext<
  RightPanelProcedureContextType | undefined
>(undefined);

// Créer un fournisseur de context qui enveloppe votre application
interface RightPanelProcedureContextProps {
  children: ReactNode;
}

export const RightPanelProcedureContextProvider: React.FC<
  RightPanelProcedureContextProps
> = ({ children }) => {
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  // Fournir la valeur du contexte aux composants enfants
  const contextValue: RightPanelProcedureContextType = {
    deleteId,
    setDeleteId,
  };

  return (
    <RightPanelProcedureContext.Provider value={contextValue}>
      {children}
    </RightPanelProcedureContext.Provider>
  );
};

// Utiliser un hook personnalisé pour accéder au contexte
export const useRightPanelProcedureContext = () => {
  const context = useContext(RightPanelProcedureContext);
  if (!context) {
    throw new Error(
      "useMyContext doit être utilisé à l'intérieur de MyContextProvider"
    );
  }

  return context;
};
