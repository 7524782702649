export const georisqueLegend: GeorisqueLegends = [
  {
    title: 'Argiles',
    layers: [
      {
        libelle: 'Exposition au retrait-gonflement des argiles',
        layer: '16/ALEARG',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=ALEARG&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=9028',
      },
    ],
  },
  {
    title: 'Avalanches',
    layers: [
      {
        libelle: "Temoignages d'avalanches",
        layer: '15/CLPA_temoignage',
        legend: '',
      },
      {
        libelle: 'Interprétation des phénomènes passés',
        layer: '15/CLPA_interpretation',
        legend: '',
      },
      {
        libelle: 'Zones sans enquete terrain',
        layer: '15/CLPA_limite_enquete',
        legend: '',
      },
    ],
  },
  {
    title: 'Cavités',
    layers: [
      {
        libelle: "Cavités souterraines abandonnées d'origine non minière",
        layer: '18/CAVITE_LOCALISEE',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=CAVITE_LOCALISEE&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=9028',
      },
      {
        libelle: 'Cavités souterraines non minières abandonnées non localisées',
        layer: '17/CAVITE_COMMUNE',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=CAVITE_COMMUNE&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=9028',
      },
    ],
  },
  {
    title: 'Innondations',
    layers: [
      {
        libelle: 'Ouvrages de protection',
        layer: '14/OUVPROTEC',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=OUVPROTEC&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: 'Zones de sur-aléa inondation',
        layer: '14/SURALEA',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=SURALEA&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: "Zones soustraites à l'aléa inondation",
        layer: '14/SOUSTINOND',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=SOUSTINOND&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: "Territoires à risques importants d'inondation",
        layer: '18/LIMITETRI',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=SOUSTINOND&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: "Aléa débordement de cours d'eau fréquent ou décennal",
        layer: '14/INOND_01_01',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_01_01&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: "Aléa débordement de cours d'eau moyen ou centennal",
        layer: '14/INOND_01_02',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_01_02&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: "Aléa débordement de cours d'eau rare ou millénial",
        layer: '14/INOND_01_04',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_01_04&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: 'Aléa submersion fréquent ou décennal',
        layer: '14/INOND_03_01',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_03_01&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=1155583',
      },
      {
        libelle: 'Aléa submersion moyen ou centennal',
        layer: '14/INOND_03_02',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_03_02&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle:
          'Aléa submersion moyen ou centennal avec prise en compte du changement climatique',
        layer: '14/INOND_03_03',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_03_03&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: 'Aléa submersion rare ou millénial',
        layer: '14/INOND_03_04',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_03_04&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: 'Aléa ruissellement fréquent ou décennal',
        layer: '14/INOND_02_01',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_02_01&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: 'Aléa ruissellement moyen ou centennal',
        layer: '14/INOND_02_02',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_02_02&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
      {
        libelle: 'Aléa ruissellement rare ou millénial',
        layer: '14/INOND_02_04',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INOND_02_04&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=577792',
      },
    ],
  },
  {
    title: 'Installations industrielles',
    layers: [
      {
        libelle: "Installations classées pour la protection de l'environnement",
        layer: '18/INSTALLATIONS_CLASSEES_SIMPLIFIE',
        legend: '',
      },
      {
        libelle: 'Etablissements déclarants des rejets et transferts de polluants',
        layer: '18/ETABLISSEMENTS_POLLUEURS',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=ETABLISSEMENTS_POLLUEURS&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=4622334',
      },
      {
        libelle: 'Installations nucléaires de base (INB)',
        layer: '15/INSTALLATIONS_NUCLEAIRES',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=INSTALLATIONS_NUCLEAIRES&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=4622334',
      },
    ],
  },
  {
    title: 'Mouvements de terrain',
    layers: [
      {
        libelle: 'Mouvements de Terrain',
        layer: '18/MVT_LOCALISE',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=MVT_LOCALISE&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=4622334',
      },
      {
        libelle: 'Mouvements de Terrain non localisés',
        layer: '18/MVT_COMMUNE',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=MVT_COMMUNE&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=4622334',
      },
    ],
  },
  {
    title: 'Plan de prévention des risques',
    layers: [
      {
        libelle: "Communes faisant l'objet d'un PPR Inondation",
        layer: '18/PPRN_COMMUNE_RISQINOND',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQINOND&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: "Communes faisant l'objet d'un PPR Inondation par sumersion marine",
        layer: '18/PPRN_COMMUNE_RISQSUBM',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQSUBM&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: "Communes faisant l'objet d'un PPR Mouvement de terrain",
        layer: '18/PPRN_COMMUNE_RISQMVT',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQMVT&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle:
          "Communes faisant l'objet d'un PPR Mouvement de terrain - Affaissements et effondrements",
        layer: '18/PPRN_COMMUNE_RISQCAV',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQCAV&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle:
          "Communes faisant l'objet d'un PPR Mouvement de terrain - Tassements différentiels",
        layer: '18/PPRN_COMMUNE_RISQTASD',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQTASD&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: "Communes faisant l'objet d'un PPR Eruption volcanique",
        layer: '18/PPRN_COMMUNE_RISQVOLC',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQVOLC&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: "Communes faisant l'objet d'un PPR Séisme",
        layer: '18/PPRN_COMMUNE_RISQSEIS',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQSEIS&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: "Communes faisant l'objet d'un PPR Feu de forêt",
        layer: '18/PPRN_COMMUNE_RISQFEU',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQFEU&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: "Communes faisant l'objet d'un PPR Phénomènes météorologiques",
        layer: '18/PPRN_COMMUNE_RISQATMO',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQATMO&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: "Communes faisant l'objet d'un PPR Avalanche",
        layer: '18/PPRN_COMMUNE_RISQAVAL',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_COMMUNE_RISQAVAL&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: "Communes faisant l'objet d'un PPR Risque industriel",
        layer: '18/PPRT_COMMUNE_RISQIND',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRT_COMMUNE_RISQIND&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: 'PPR Inondation',
        layer: '14/PPRN_INOND',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_INOND&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: 'PPR Littoraux',
        layer: '14/PPRN_SUBMAR',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_SUBMAR&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle:
          'PPR Mouvement de terrain - Affaissements et effondrements (Cavités souterraines)',
        layer: '14/PPRN_CAV',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_CAV&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: 'PPR Mouvement de terrain - Tassements différentiels (Argile)',
        layer: '14/PPRN_TSD',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_TSD&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: 'PPR Mouvement de terrain',
        layer: '14/PPRN_MVT',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_MVT&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: 'PPR Feu de forêt',
        layer: '14/PPRN_FEU',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_FEU&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: 'PPR Avalanche',
        layer: '14/PPRN_AVALANCHE',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_AVALANCHE&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: 'PPR Séisme',
        layer: '14/PPRN_SEISME',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRN_SEISME&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
      {
        libelle: 'PPR Risque industriel',
        layer: '14/PPRT_RISQIND',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=PPRT_RISQIND&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=2311167',
      },
    ],
  },
  {
    title: 'Radon',
    layers: [
      {
        libelle: 'Potentiel radon à la commune',
        layer: '18/RADON_COMMUNE',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=RADON_COMMUNE&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=9028',
      },
    ],
  },
  {
    title: 'Réseaux et canalisations',
    layers: [
      {
        libelle:
          'Canalisations de transport de matières dangereuses : Gaz, Hydrocarbures, Produits chimiques',
        layer: '14/CANALISATIONS',
        legend: '',
      },
    ],
  },
  {
    title: 'Sites et sols (potentiellement) pollués',
    layers: [
      {
        libelle:
          'Sites pollués ou potentiellement pollués appelant une action des pouvoirs publics, à titre préventif ou curatif (BASOL)',
        layer: '18/SSP_INSTRUCTION',
        legend: '',
      },
      {
        libelle: 'Anciens sites industriels et activités de service - Adresse',
        layer: '18/BASIAS_ADRESSE',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=BASIAS_ADRESSE&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=4622334',
      },
      {
        libelle: 'Anciens sites industriels et activités de service',
        layer: '18/BASIAS_LOCALISE',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=BASIAS_LOCALISE&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=4622334',
      },
      {
        libelle: "Secteurs d'information sur les sols",
        layer: '18/SSP_CLASSIFICATION_SIS',
        legend: '',
      },
      {
        libelle: "Servitudes d'utilité publique",
        layer: '18/SSP_CLASSIFICATION_SUP',
        legend: '',
      },
    ],
  },
  {
    title: 'Séismes',
    layers: [
      {
        libelle: 'Zonage sismique',
        layer: '18/risq_zonage_sismique',
        legend:
          'https://mapsref.brgm.fr/wxs/georisques/risques?service=WMS&request=GetLegendGraphic&format=image%2Fpng&height=12&width=12&layer=risq_zonage_sismique&style=&version=1.3.0&SLD_VERSION=1.1.0&LEGEND_OPTIONS=forceLabels%3Aon&SCALE=9028',
      },
    ],
  },
];
