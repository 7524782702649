import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getAuthState } from '../../../features/auth/authSlice';
import { modalsActions } from '../../../features/modals/modalsSlice';
import { userPasswordUpdateThunk } from '../../../features/users/usersThunks';
// Import style
import FieldInputPwdFormCustom from '../../Common/FormComponents/FieldInputPwdFormCustom';
import {
  IPasswordTabForm,
  initialPasswordForm,
  passwordValidationSchema,
} from '../passwordValidation';
import './PasswordTab.scss';

export default function PasswordTab(): JSX.Element {
  const dispatch = useAppDispatch();

  const { userIdIri } = useAppSelector(getAuthState);
  const formOptions = useForm<IPasswordTabForm>({
    defaultValues: initialPasswordForm,
    resolver: yupResolver(passwordValidationSchema),
  });

  const {
    getValues,
    handleSubmit,
    reset,
    formState: { errors },
  } = formOptions;

  const handleFormStateSubmit = () => {
    if (isEmpty(errors) && userIdIri) {
      const body: Partial<User> = {
        plainPassword: getValues('verifyPassword'),
      };
      dispatch(userPasswordUpdateThunk({ userIdIri, user: body }));
      reset();
      dispatch(modalsActions.passwordChangeResult(true));
    }
  };
  return (
    <div className="tab-password">
      <div className="tab-inner">
        <h1>Modifier mon mot de passe</h1>
      </div>
      <div className="tab-body">
        <FormProvider {...formOptions}>
          <form onSubmit={handleSubmit(handleFormStateSubmit)}>
            <div className="field">
              <FieldInputPwdFormCustom
                name="password"
                label="Nouveau mot de passe"
              />
            </div>
            <div className="field">
              <FieldInputPwdFormCustom
                name="verifyPassword"
                label="Confirmation du mot de passe"
              />
            </div>
            <button type="submit" className="password-submit-button">
              VALIDER
            </button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
}
