import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { fetchDataById } from '../../../services/axiosFiles/genericCrud';
import { genericStudyParser } from '../utils/parsers/studyParser';

export const fetchStudy = async (
  idIri: string | null,
  users: Users | null,
  folders: Folders | null,
  statuses: StudyStatuses | null,
  sectors: Sectors | null
) => {
  try {
    if (idIri && users && folders && statuses) {
      const study = await fetchDataById(idIri);
      const parsedStudy = genericStudyParser(
        study,
        users,
        folders,
        statuses,
        sectors ?? null
      );

      return parsedStudy;
    } else {
      return Promise.reject(paramsNotFound('fetchStudy'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
