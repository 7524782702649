import { Tooltip, TooltipProps, styled, tooltipClasses } from '@mui/material';

const StyledTextTooltip = styled(({ className, ...props }: TooltipProps) => {
  return <Tooltip {...props} classes={{ popper: className }} />;
})(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: '4px',
    padding: '4px 8px',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '14px',
    maxWidth: 'unset',
  },
}));

export default StyledTextTooltip;
