import SwitchFormCustom from '../../../components/Common/FormComponents/SwitchFormCustom';
import TextAreaFormCustom from '../../../components/Common/FormComponents/TextAreaFormCustom';
import TextFieldFormCustom from '../../../components/Common/FormComponents/TextFieldFormCustom';
import LastNameAutocomplete from '../contactFormAutocomplete/LastNameAutocomplete';
import styles from './contactFormComponents.module.scss';
interface IContactFromComponentsProps {
  addressOld?: string | null;
  onFocus?: () => void;
  extended?: boolean;
}

function ContactFormComponents({
  onFocus,
  addressOld,
  extended,
}: IContactFromComponentsProps) {
  return (
    <div className={styles.contactFromComponents}>
      <div className={styles.flexRow}>
        <LastNameAutocomplete
          name="lastName"
          placeholder="Nom"
          dataCy="study-contact-form-last-name"
          required
        />
        <TextFieldFormCustom
          name="firstName"
          onFocus={onFocus}
          placeholder="Prénom"
          dataCy="study-contact-form-first-name"
          required
        />
      </div>
      <div className={styles.addressLineContainer}>
        {addressOld && (
          <p className={styles.oldAddress}>
            Adresse non formatée :<span> {addressOld}</span>
          </p>
        )}
        <TextFieldFormCustom
          name="addressFloor"
          placeholder="n ̊ d’appartement, étage, couloir, escalier ..."
          onFocus={onFocus}
          dataCy="study-contact-form-address-floor"
        />
        <TextFieldFormCustom
          name="addressBuilding"
          placeholder="entrée, bâtiment, immeuble ..."
          onFocus={onFocus}
          dataCy="study-contact-form-address-building"
        />
        <TextFieldFormCustom
          name="addressStreet"
          placeholder="rue, avenue, hameau ..."
          onFocus={onFocus}
          dataCy="study-contact-form-address-street"
          required
        />
        <TextFieldFormCustom
          name="addressExtra"
          placeholder="poste restante, BP, lieu-dit ..."
          onFocus={onFocus}
          dataCy="study-contact-form-address-extra"
        />
      </div>
      <div className={styles.flexRow}>
        <TextFieldFormCustom
          name="postalCode"
          placeholder="Code postal"
          onFocus={onFocus}
          dataCy="study-contact-form-postal-code"
          required
        />
        <TextFieldFormCustom
          name="city"
          placeholder="Ville"
          onFocus={onFocus}
          dataCy="study-contact-form-city"
          required
        />
        <TextFieldFormCustom
          name="country"
          placeholder="Pays"
          onFocus={onFocus}
          dataCy="study-contact-form-country"
          required
        />
      </div>
      {extended && (
        <div className={styles.contactFromComponents}>
          <div className={styles.flexRow}>
            <TextFieldFormCustom
              name="phone"
              placeholder="Téléphone"
              onFocus={onFocus}
              dataCy="study-contact-form-phone"
            />
            <TextFieldFormCustom
              name="email"
              placeholder="email"
              onFocus={onFocus}
              dataCy="study-contact-form-email"
            />
          </div>
          <SwitchFormCustom
            name="allowedToBeContacted"
            label="Accepte d'être contacté"
          />
          <div className={styles.bottomBlock}>
            <TextAreaFormCustom
              name="description"
              className={styles.contactInfos}
              onFocus={onFocus}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContactFormComponents;
