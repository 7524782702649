import { TableCell, TableRow } from '@mui/material';
import React from 'react';

interface ITableRowFullColumnProps {
  colSpan: number;
  children: React.ReactNode;
  className?: string;
}
export default function TableRowFullColumn({
  colSpan,
  children,
  className,
}: ITableRowFullColumnProps) {
  return (
    <TableRow className={className}>
      <TableCell colSpan={colSpan} className="full-column-table-cell">
        {children}
      </TableCell>
    </TableRow>
  );
}
