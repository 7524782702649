import { useRef } from 'react';
import Slider from 'react-slick';
import { v4 as uuidv4 } from 'uuid';
import ReactPlayer from 'react-player';
import { videos } from './init';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useAppDispatch, useAppSelector } from '../../../App/store';

import { appActions, getAppState } from '../../../App/appSlice';

export default function UrbaneaseAcademySlider(): JSX.Element {
  const dispatch = useAppDispatch();
  const { accountFaqCurrentSlide } = useAppSelector(getAppState);
  const sliderRef: any = useRef();

  const handleSlideChange = (index: number) => {
    dispatch(appActions.setAccountFaqCurrentSlide(index));
  };

  const settings = {
    initialSlide: accountFaqCurrentSlide,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 898,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: handleSlideChange,
  };

  return (
    <>
      <Slider {...settings} ref={sliderRef} className="slider">
        {videos.map((e) => (
          <div key={uuidv4()} className="main-slides">
            <div className="col-1">
              <h4>{e.title}</h4>
              <div className="video">
                <ReactPlayer controls light width="100%" height="100%" url={e.url} />
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="slider-actions">
        <ArrowBackIcon onClick={() => sliderRef.current.slickPrev()} />
        <ArrowForwardIcon onClick={() => sliderRef.current.slickNext()} />
      </div>
    </>
  );
}
