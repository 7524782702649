import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../App/store';
import PaginationCustom from '../../../../components/Common/PaginationCustom/PaginationCustom';
import { fetchSubscriptionCitiesThunk } from '../subscriptionThunks';
import ListRow from './ListRow';

interface IListContainer {
  rows: string[];
  subscriptionIdIri: string | null;
  totalItems?: number;
  itemPerPageLabel?: string;
  hasPagination: boolean;
}

function ListContainer({
  rows,
  totalItems,
  subscriptionIdIri,
  itemPerPageLabel,
  hasPagination,
}: IListContainer) {
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const dispatch = useAppDispatch();

  const request = (p: number, ipp: number) => {
    dispatch(
      fetchSubscriptionCitiesThunk({
        subscriptionIdIri: subscriptionIdIri,
        page: p,
        itemsPerPage: ipp,
      })
    );
  };

  useEffect(() => {
    if (hasPagination) {
      request(page, itemsPerPage);
    }
  }, []);

  const handleItemPerPageChange = (e: ChangeEventCustom) => {
    const value = e.target.value;
    setItemsPerPage(value);
    request(page, value);
  };

  const handlePageChange = (e: number) => {
    const newPage = Math.floor(e / itemsPerPage) + 1;
    if (newPage !== page) {
      setPage(newPage);
      request(newPage, itemsPerPage);
    }
  };
  return (
    <>
      <ul className="data-list">
        {rows.map((r) => (
          <ListRow key={nanoid()} row={r} />
        ))}
      </ul>
      {hasPagination && (
        <PaginationCustom
          elementsQty={totalItems ?? 0}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={handleItemPerPageChange}
          page={page}
          onMinIndexTab={handlePageChange}
          itemPerPageLabel={itemPerPageLabel}
        />
      )}
    </>
  );
}

export default ListContainer;
