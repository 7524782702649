import styles from './noRights.module.scss';

function NoCity() {
  return (
    <p className={styles.noCity}>
      Choisissez une parcelle <br />
      ou saisissez une ville
    </p>
  );
}

export default NoCity;
