import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { TransportModeEnum } from '../../../shared/types/enums';
import { fontStyles } from '../styles/pdfStyles';
import AirportSvg from './svg/AirportSvg';
import BusSvg from './svg/BusSvg';
import HighwaySvg from './svg/HighwaySvg';
import SubwaySvg from './svg/SubwaySvg';
import TrainSvg from './svg/TrainSvg';
import TramSvg from './svg/TramSvg';

interface IProps {
  data: PDFLandTransport | null;
}

const getIcon = (type: TransportModeEnum) => {
  switch (type) {
    case TransportModeEnum.highway_ramp:
      return <HighwaySvg />;
    case TransportModeEnum.bus:
      return <BusSvg />;
    case TransportModeEnum.train:
      return <TrainSvg />;
    case TransportModeEnum.airport:
      return <AirportSvg />;
    case TransportModeEnum.subway:
      return <SubwaySvg />;
    case TransportModeEnum.tram:
      return <TramSvg />;
    default:
      break;
  }
};

function TransportCard({ data }: IProps) {
  return (
    <View style={styles.container}>
      {data && data.distance && (
        <View style={styles.row}>
          <View style={styles.closestSvg}>{getIcon(data.subcategory)}</View>

          <Text>
            <Text style={fontStyles.b700}>{data.subcategory}</Text>
            {` ${data.name ?? ''} ${data.distance}`}
          </Text>
        </View>
      )}
    </View>
  );
}

export default TransportCard;

const styles = StyleSheet.create({
  container: {},
  closestSvg: {
    width: '14px',
    height: '14px',
    marginRight: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '2px',
    marginRight: '5px',
    fontSize: '10px',
  },
});
