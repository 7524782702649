export const excelCityBankAccountColums = [
  { header: 'Libellé', key: 'libellé' },
  { header: 'INSEE', key: 'insee' },
  { header: 'N° SIREN', key: 'no_siren' },
  { header: 'N° Compte', key: 'no_compte' },
  { header: 'Type de budget', key: 'type_de_budget' },
  { header: "Type d'établissement", key: 'type_etablissement' },
  { header: "Sous type d'établissement", key: 'sous_type_etablissement' },
  { header: 'Nomenclature comptable', key: 'nomenclature_comptable' },
  { header: 'code activité CACTI', key: 'code_activité_CACTI' },
  { header: 'Secteur', key: 'secteur' },
  { header: 'Code budget', key: 'code_budget' },
  { header: 'Catégorie', key: 'catégorie' },
  { header: 'Balance entrée débit', key: 'balance_entrée_débit' },
  { header: 'Balance entree crédit', key: 'balance_entrée_crédit' },
  {
    header: 'Opération budgétaire débit nette des annulations',
    key: 'opération_budgétaire_débit_nette_des_annulations',
  },
  {
    header: 'Opération budgétaire crédit nette des annulations',
    key: 'opération_budgétaire_crédit_nette_des_annulations',
  },
  {
    header: 'Opération non budgétaire débit',
    key: 'opération_non_budgétaire_débit',
  },
  {
    header: 'Opération non budgétaire crédit',
    key: 'opération_non_budgétaire_crédit',
  },
  {
    header: 'Opération ordre budgétaire débit',
    key: 'opération_ordre_budgétaire_débit',
  },
  {
    header: 'Opération ordre bdgétaire crédit',
    key: 'opération_ordre_bdgétaire_crédit',
  },
  { header: 'Solde débiteur', key: 'solde_débiteur' },
  { header: 'Solde créditeur', key: 'solde_créditeur' },
];
