import { StyleSheet, View } from '@react-pdf/renderer';
import { EnvIconTypeEnum, GradualColors } from '../../../shared/constants';
import EnvironmentGradualRow from './EnvironmentGradualRow';

const styles = StyleSheet.create({
  gradualScore: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px',
    width: '465px',
  },
});
interface IEnvironmentGradualScoreProps {
  value: number;
  type: EnvIconTypeEnum;
  max?: number;
}

function EnvironmentGradualScore(props: IEnvironmentGradualScoreProps) {
  return (
    <View style={styles.gradualScore}>
      {GradualColors[props.type].map((m) => {
        return (
          <EnvironmentGradualRow
            key={m.label}
            label={m.label}
            value={props.value}
            color={m.color}
            isValue={m.index === props.value}
            width={props.max === 5 ? 93 : 155}
          />
        );
      })}
    </View>
  );
}

export default EnvironmentGradualScore;
