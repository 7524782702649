import { useAppSelector } from '../../App/store';
import LoginModal from '../auth/loginModal/LoginModal';
import SignUpModal from '../auth/signUpModal';
import UrbaneaseIntroModal from '../auth/urbaneaseIntroModal';
import { getModalsState } from './modalsSlice';

function AuthModalContainer() {
  const { signup, signin, urbaneaseIntro } = useAppSelector(getModalsState);
  return (
    <>
      {signup && <SignUpModal open={signup} />}
      {signin && <LoginModal />}
      {urbaneaseIntro && <UrbaneaseIntroModal />}
    </>
  );
}

export default AuthModalContainer;
