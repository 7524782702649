import Quill from 'quill';
import Embed from 'quill/blots/embed';

class TagBadge extends Embed {
  static blotName = 'TagBadge';
  static tagName = 'div';

  static create(value: MailshotBadgeValue) {
    let node = super.create(value) as HTMLElement;
    node.setAttribute('class', 'badge badge-' + value.color);
    node.setAttribute('data-marker', value.marker);
    node.setAttribute('data-key', value.key);
    node.setAttribute('data-title', value.title);
    node.innerHTML = value.title;
    return node;
  }

  static value(node: HTMLElement): MailshotBadgeValue {
    return {
      marker: node.getAttribute('data-marker') || '',
      title: node.getAttribute('data-title') || '',
      key: node.getAttribute('data-key') || '',
      color: node.getAttribute('class')?.split('badge-')[1] || '',
    };
  }
}

Quill.register({
  'formats/TagBadge': TagBadge,
});
