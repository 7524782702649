import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { modalsActions } from '../../../../modals/modalsSlice';
import { getMapPluginsAltimetryState, mapPluginsActions } from '../mapPluginSlice';
import CreateToolbarBtn from '../toolbars/urbaToolbar/CreateToolbarBtn';
import ToolbarCancelBtn from '../toolbars/urbaToolbar/ToolbarCancelBtn';
import ToolbarTooltip from '../toolbars/urbaToolbar/ToolbarTooltip';
import AltimetryActionEnum from './types/altimetryActionEnum';

function AltimetryToolbar() {
  const { action } = useAppSelector(getMapPluginsAltimetryState);
  const dispatch = useAppDispatch();
  const handleClick = () => {
    dispatch(mapPluginsActions.altimetryClick());
    if (action === AltimetryActionEnum.ALTIMETRY_START) {
      dispatch(modalsActions.toolbarHelperModalReset());
    }
  };

  return (
    <div>
      {action === null ? (
        <CreateToolbarBtn
          icon={AltimetryActionEnum.ALTIMETRY_START}
          onClick={handleClick}
          isAltimetryIcon
          tooltip={<ToolbarTooltip text="Altimétrie" />}
        />
      ) : (
        <ToolbarCancelBtn
          type={AltimetryActionEnum.ALTIMETRY_STOP}
          onClick={handleClick}
          tooltip={<ToolbarTooltip text="Fermeture de l &#39;outil altimétrie" />}
        />
      )}
    </div>
  );
}

export default AltimetryToolbar;
