import { StyleSheet, View } from '@react-pdf/renderer';
import EnvConstraintLand from './EnvConstraintLand';

const styles = StyleSheet.create({
  envConstraints: {},
});

interface IEnvConstraintsProps {
  isMonument: boolean;
  isRemarkableHeritage: boolean;
  isArchaeologicalPrescriptionArea: boolean;
  isPEBImpact: boolean;
  PEBValue: string;
}
function EnvConstraintsLand(props: IEnvConstraintsProps) {
  return (
    <View style={styles.envConstraints}>
      <EnvConstraintLand
        isActive={props.isMonument || props.isRemarkableHeritage}
        title="> En abord d'un monument historique"
        text="Périmètre de 500 mètres – désigne aussi le périmètre d’intervention des architectes des bâtiments de France dans le cadre d’un processus d’instruction d’autorisation d’urbanisme"
      />
      <EnvConstraintLand
        isActive={props.isRemarkableHeritage}
        title="> Situé au sein d’un Site Patrimoine Remarquable : "
        text="Désigne aussi le périmètre d’intervention des architectes des bâtiments de France dans le cadre d’un processus d’instruction d’autorisation d’urbanisme. Et l’application de règles de constructibilité plus strictes et contraignantes"
      />
      <EnvConstraintLand
        isActive={props.isArchaeologicalPrescriptionArea}
        title="> Situé au sein d’une Zone de Prescription Archéologique : "
        text="Les zones de présomption de prescription archéologique (ZPPA) sont des zones dans lesquelles les projets d'aménagement affectant le sous-sol sont présumés faire l'objet de prescriptions archéologiques préalablement à leur réalisation."
      />
      <EnvConstraintLand
        isActive={props.isPEBImpact}
        title="> Impacté par un PEB d’un aérodrome :"
        text="Dans les zones définies par le plan d'exposition au bruit, l'extension de l'urbanisation et la création ou l'extension d'équipements publics sont interdites lorsqu'elles conduisent à exposer immédiatement ou à terme de nouvelles populations aux nuisances de bruit. Il convient de consulter l’administration. Local pour connaître l’impact sur votre projet."
        value={props.PEBValue}
      />
    </View>
  );
}

export default EnvConstraintsLand;
