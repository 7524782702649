import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { getDuplicatesFromArray } from '../../shared/utils/arrayTools';
import createMailshotModelThunk from './services/thunks/createMailshotModelThunk';
import deleteMailshotModelThunk from './services/thunks/deleteMailshotModelThunk';
import fetchMailshotModelsThunk from './services/thunks/fetchMailshotModelsThunk';
import fetchMailshotPlotsContactsThunk from './services/thunks/fetchMailshotPlotsContactsThunk';
import updateMailshotsModelsThunk from './services/thunks/updateMailshotsModelThunk';
import { MailshotsSelectionTypeEnum } from './types/mailshotsEnums';

const initialState: MailshotsInitialState = {
  models: { apiStatus: APIStatus.IDLE, result: null },
  selectedModel: null,
  actionModalDatas: null,
  selectType: MailshotsSelectionTypeEnum.NONE,
  selectedList: [],
  dragWindowDisplay: false,
  selectStaged: false,
  updatingQuill: false,
  quillDatasBeforeNavigation: null,
  folderContacts: null,
  apiActionStatus: { status: APIStatus.IDLE, error: null },
  mailshotModelTabIndex: 0,
};
const mailshotsSlice = createSlice({
  name: 'mailshots',
  initialState,
  reducers: {
    reset: () => initialState,
    resetWithoutModels: (state) => ({ ...initialState, models: state.models }),
    setSelectType: (state, action: PayloadAction<MailshotsSelectionTypeEnum>) => {
      state.selectType = action.payload;
    },
    setQuillDatasBeforeNavigation: (
      state,
      action: PayloadAction<QuillDatasBeforeNavigationType | null>
    ) => {
      state.quillDatasBeforeNavigation = action.payload;
      if (action.payload && action.payload.content === null) {
        state.actionModalDatas = { type: 'confirm', model: null };
      }
    },
    setPlotList: (state, action: PayloadAction<MailshotPlots>) => {
      state.selectedList = action.payload;
    },
    setMailshotModelTabs: (state, action: PayloadAction<number>) => {
      state.mailshotModelTabIndex = action.payload;
    },
    initFolderMailshot: (
      state,
      action: PayloadAction<{
        mailshotList: MailshotPlots;
        studyContacts: StudyContacts;
      }>
    ) => {
      state.selectedList = action.payload.mailshotList;
      (state.folderContacts = {
        list: action.payload.studyContacts,
        duplicateContacts: getDuplicatesFromArray(
          action.payload.studyContacts.map((m) => m.contact.idIri)
        ),
      }),
        (state.selectStaged = true);
    },
    setUpdatingQuill: (state, action: PayloadAction<boolean>) => {
      state.updatingQuill = action.payload;
    },

    setApiActionStatus: (
      state,
      action: PayloadAction<{ status: APIStatus; error: null }>
    ) => {
      state.apiActionStatus = action.payload;
    },
    setSelectedModel: (state, action: PayloadAction<MailshotModelType | null>) => {
      state.selectedModel = action.payload;
    },
    setSelectStaged: (state, action: PayloadAction<boolean>) => {
      state.selectStaged = action.payload;
    },
    setDragWindowDisplay: (state, action: PayloadAction<boolean>) => {
      state.dragWindowDisplay = action.payload;
    },
    addPlotStudyToSelectList: (state, action: PayloadAction<IPlotStudy>) => {
      const ap = action.payload;
      const allreadySelected = state.selectedList
        .map((m) => m.idIri)
        .includes(ap.idIri);

      if (allreadySelected) {
        state.selectedList =
          state.selectedList.filter((f) => f.idIri !== ap.idIri) ?? [];
      } else if (state.selectedList.length < 10) {
        state.selectedList = state.selectedList.concat({
          ...ap,
          contacts: { list: [], duplicateContacts: [] },
        });
      }
    },
    removePlotStudyFromList: (state, action: PayloadAction<IPlotStudy>) => {
      const ap = action.payload;
      state.selectedList =
        state.selectedList.filter((f) => f.idIri !== ap.idIri) ?? [];
    },
    addStudyContactToSinglePlot: (state, action: PayloadAction<IStudyContact>) => {
      if (state.selectedList.length === 1) {
        const ap = action.payload;
        let contacts = state.selectedList[0].contacts.list;
        const allreadyExists = contacts.find((f) => f.idIri === ap.contact.idIri);

        if (allreadyExists) {
          contacts =
            contacts.filter((f) => f.contact.idIri !== ap.contact.idIri) ?? [];
          state.selectedList[0].contacts.list = contacts;
        } else if (state.selectedList.length < 10) {
          const c = ap.contact;
          state.selectedList[0].contacts.list = contacts.concat(ap);
        }
      }
    },
    updatePlotInPlotList: (state, action: PayloadAction<MailshotPlot>) => {
      state.selectedList = state.selectedList.map((m) =>
        m.idIri === action.payload.idIri ? action.payload : m
      );
    },
    removeContactFromFolderContacts: (
      state,
      action: PayloadAction<IStudyContact>
    ) => {
      if (state.folderContacts?.list) {
        const newList =
          state.folderContacts?.list.filter(
            (f) => f.idIri !== action.payload.idIri
          ) ?? [];
        state.folderContacts = {
          list: newList,
          duplicateContacts: getDuplicatesFromArray(
            newList.map((m) => m.contact.idIri)
          ),
        };
      }
    },
    updateContactInActiveList: (state, action: PayloadAction<IStudyContact>) => {
      if (state.folderContacts?.list) {
        state.folderContacts.list = state.folderContacts.list.map((m) =>
          m.idIri === action.payload.idIri ? action.payload : m
        );
      } else {
        state.selectedList = state.selectedList.map((m) => ({
          ...m,
          contacts: {
            list: m.contacts.list.map((mc) =>
              mc.idIri === action.payload.idIri
                ? { ...mc, contact: action.payload.contact }
                : mc
            ),
            duplicateContacts: m.contacts.duplicateContacts,
          },
        }));
      }
    },
    setModalData: (state, action: PayloadAction<ActionModalDatasType | null>) => {
      state.actionModalDatas = action.payload;

      if (action.payload === null && state.quillDatasBeforeNavigation) {
        state.quillDatasBeforeNavigation = null;
        state.updatingQuill = false;
      }
    },
  },
  extraReducers: (builder) => {
    // FETCH MODELS
    builder
      .addCase(fetchMailshotModelsThunk.pending, (state) => {
        state.models.apiStatus = APIStatus.PENDING;
        state.models.result = null;
        state.models.error = undefined;
      })
      .addCase(
        fetchMailshotModelsThunk.fulfilled,
        (state, action: PayloadAction<MailshotModelsType>) => {
          state.models.apiStatus = APIStatus.IDLE;
          state.models.result = action.payload;
          state.models.error = undefined;
        }
      )
      .addCase(fetchMailshotModelsThunk.rejected, (state, action) => {
        state.models.apiStatus = APIStatus.REJECTED;
        state.models.result = [];
        state.models.error = action.error;
      });
    // CREATE MODEL
    builder
      .addCase(createMailshotModelThunk.pending, (state) => {
        state.apiActionStatus = { status: APIStatus.PENDING, error: null };
      })
      .addCase(
        createMailshotModelThunk.fulfilled,
        (state, action: PayloadAction<MailshotModelType>) => {
          if (state.models.result) {
            state.apiActionStatus.status = APIStatus.IDLE;
            state.models.result = state.models.result.concat(action.payload);
            //select the new model
            state.mailshotModelTabIndex = state.models.result.length - 1;
            //close modal
            state.quillDatasBeforeNavigation = null;
            state.updatingQuill = false;
          } else {
            state.models.result = [action.payload];
            state.mailshotModelTabIndex = 0;
          }
          state.actionModalDatas = null;
          state.updatingQuill = false;
        }
      )
      .addCase(createMailshotModelThunk.rejected, (state, action) => {
        state.apiActionStatus = { status: APIStatus.REJECTED, error: action.error };
      });
    // UPDATE MODEL
    builder
      .addCase(updateMailshotsModelsThunk.pending, (state) => {
        state.apiActionStatus = { status: APIStatus.PENDING, error: null };
      })
      .addCase(
        updateMailshotsModelsThunk.fulfilled,
        (state, action: PayloadAction<MailshotModelType>) => {
          if (state.models.result) {
            state.apiActionStatus.status = APIStatus.IDLE;
            const newList = state.models.result.map((m) =>
              m.id === action.payload.id ? action.payload : m
            );
            state.selectedModel = action.payload;
            state.models.result = newList;
            state.updatingQuill = false;
            state.actionModalDatas = {
              type: 'updateSuccess',
              model: null,
            };
          }
        }
      )
      .addCase(updateMailshotsModelsThunk.rejected, (state, action) => {
        state.apiActionStatus = { status: APIStatus.REJECTED, error: action.error };
      });
    // DELETE MODEL
    builder
      .addCase(deleteMailshotModelThunk.pending, (state) => {
        state.apiActionStatus.status = APIStatus.PENDING;
        state.apiActionStatus.error = null;
      })
      .addCase(
        deleteMailshotModelThunk.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.apiActionStatus.status = APIStatus.IDLE;
          if (state.models.result) {
            state.apiActionStatus.status = APIStatus.IDLE;
            state.models.result = state.models.result?.filter(
              (m) => m.id !== action.payload
            );
            state.models.result.length > 0
              ? (state.selectedModel = state.models.result[0])
              : (state.selectedModel = null);
            //close modal
            state.actionModalDatas = null;
          }
        }
      )
      .addCase(deleteMailshotModelThunk.rejected, (state, action) => {
        state.apiActionStatus.status = APIStatus.REJECTED;
        state.apiActionStatus.error = action.error;
      });
    // FETCH CONTACT FROM PLOT STUDY ID
    builder
      .addCase(fetchMailshotPlotsContactsThunk.pending, (state) => {
        state.apiActionStatus = { status: APIStatus.PENDING, error: null };
      })
      .addCase(
        fetchMailshotPlotsContactsThunk.fulfilled,
        (state, action: PayloadAction<MailshotPlots>) => {
          state.selectedList = action.payload;
          state.apiActionStatus.status = APIStatus.IDLE;
          state.apiActionStatus.error = null;
        }
      )
      .addCase(fetchMailshotPlotsContactsThunk.rejected, (state, action) => {
        state.apiActionStatus.status = APIStatus.REJECTED;
        state.apiActionStatus.error = action.error;
      });
  },
});

export default mailshotsSlice.reducer;
export const mailshotsActions = mailshotsSlice.actions;
export const getMailshotsState = (state: RootState) => state.mailshots;
