import BellAlertIcon from '../../../../../../assets/images/toolbar/bellAlert.svg';
import useProcedures from '../../../../../procedures/useProcedures';
import styles from '../toolbars.module.scss';
function BellAlert() {
  const { proceduresCount } = useProcedures();

  return (
    <>
      {proceduresCount > 0 && (
        <img src={BellAlertIcon} className={styles.bellAlert} />
      )}
    </>
  );
}

export default BellAlert;
