import { Checkbox } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getAuthState } from '../../auth/authSlice';
import useMailshotsSelection from '../hooks/useMailshotsSelection';
import { mailshotsActions } from '../mailshotsSlice';
import styles from '../styles/mailshots.module.scss';
import { MailshotsSelectionTypeEnum } from '../types/mailshotsEnums';

interface IMailshotCheckboxProps {
  plotStudy?: IPlotStudy;
  studyContact?: IStudyContact;
  subBg?: boolean;
}
const useStyles = makeStyles()({
  root: {
    padding: '9px 3px 9px 0',
  },
});

function MailshotCheckbox({
  plotStudy,
  studyContact,
  subBg,
}: IMailshotCheckboxProps) {
  const { classes } = useStyles();
  const { selectType, selectedList } = useMailshotsSelection();
  const { isManager, userIdIri } = useAppSelector(getAuthState);
  const dispatch = useAppDispatch();

  const isDbFolder = selectType === MailshotsSelectionTypeEnum.DASHBOARD_FOLDER;
  const isRpPlotContact =
    selectType === MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_PLOT;

  const isChecked = () => {
    switch (selectType) {
      case MailshotsSelectionTypeEnum.DASHBOARD_FOLDER:
        return Boolean(
          selectedList.find((f) => f.fullPlotId === plotStudy?.fullPlotId)
        );

      case MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_PLOT:
        return Boolean(
          selectedList[0]?.contacts.list.find(
            (f) => f.idIri === studyContact?.contact.idIri
          )
        );

      case MailshotsSelectionTypeEnum.NONE:
      default:
        return false;
    }
  };

  const handleChange = () => {
    switch (selectType) {
      case MailshotsSelectionTypeEnum.DASHBOARD_FOLDER:
        if (plotStudy) {
          dispatch(mailshotsActions.addPlotStudyToSelectList(plotStudy));
        }
        break;
      case MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_PLOT:
        if (studyContact) {
          dispatch(mailshotsActions.addStudyContactToSinglePlot(studyContact));
        }
        break;
      case MailshotsSelectionTypeEnum.NONE:
      default:
        break;
    }
  };

  if (selectType === MailshotsSelectionTypeEnum.NONE) {
    return null;
  }

  return (
    <div
      className={[
        styles.checkboxContainer,
        isDbFolder ? styles.dashboardFolder : '',
        subBg ? styles.folderBg : '',
        isRpPlotContact ? styles.rightPanelPlotContact : '',
      ].join(' ')}
    >
      {(plotStudy?.contactCount ?? 0) > 0 && (
        <Checkbox
          color="primary"
          classes={classes}
          onChange={handleChange}
          checked={isChecked()}
          disabled={!(isManager || userIdIri === plotStudy?.responsable?.idIri)}
        />
      )}
    </div>
  );
}

export default MailshotCheckbox;
