import { postEntityNew } from '../../../../../../services/axiosFiles/genericCrud';
import pricehubbleGenericParser from '../../../../../../shared/parsers/pricehubbleGenericParser';
import { noiseParser } from '../../../../landPDF/orpiLandDataParser';

interface IProps extends IPHTokenAndPHId {
  lat: number | string;
  lng: number | string;
  callback: (value: string) => void;
}

async function fetchNoiseDatas({
  entityId,
  lat,
  lng,
  phAccessToken,
  callback,
}: IProps) {
  try {
    if (entityId && lat && lng) {
      // **** REAL BODY
      const body = {
        dossierId: entityId,
        lon: lng.toString(),
        lat: lat.toString(),
        ph_access_token: phAccessToken,
      };

      const result: any = await postEntityNew({
        endpoint: '/pdf_urbanease/pricehubble_get_noise_datas',
        body,
      });

      if (result) {
        const phPreParser = pricehubbleGenericParser(result);
        const parsed = noiseParser(phPreParser);
        callback('Données de bruit chargées');

        return parsed;
      } else {
        throw new Error('no results');
      }
    } else {
      throw new Error('missing fetchDossierDatas params');
    }
  } catch (error) {
    return null;
  }
}

export default fetchNoiseDatas;
