import { Page } from '@react-pdf/renderer';
import ComparativeStudiesTable from './components/ComparativeStudiesTable';
import Footer from './components/Footer';
import Header from './components/Header';
import { pageStyles } from './styles/pdfStyles';

interface IProps {
  agency: PdfLandOrpiAgency | null;
  prospecDatas: FormatedProspecDatasType;
  formDatas: IExternalPdfLandForm;
}

function ComparativeStudiesPage({ agency, prospecDatas, formDatas }: IProps) {
  if (prospecDatas.similariesError) return null;
  return (
    <Page style={pageStyles.page}>
      <Header
        lines={[
          'L’analyse des biens comparables',
          'Abordez la vente avec toutes les clés',
        ]}
        fixed
      />

      <ComparativeStudiesTable
        ads={prospecDatas.equalAds ?? []}
        title="Biens comparables prix similaires"
      />

      <ComparativeStudiesTable
        ads={prospecDatas.cheaperAds ?? []}
        title="Biens comparables prix inférieurs"
        cheap
      />

      <ComparativeStudiesTable
        ads={prospecDatas.higherAds ?? []}
        title="Biens comparables prix supérieurs"
        high
      />

      <Footer agency={agency} formDatas={formDatas} />
    </Page>
  );
}

export default ComparativeStudiesPage;
