import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { modalsActions } from '../../../../modals/modalsSlice';
import { ownerPlotsToCreatePlotStudiesParser } from '../../../utils/ownerPlotToCreatePlotStudyParser';
import { getMultiPlotSelectState } from '../../multiPlotSelectSlice';

function MultiPlotOwnerBtn(): React.ReactElement {
  const { ownerPlots } = useAppSelector(getMultiPlotSelectState);

  const dispatch = useAppDispatch();

  const handleAddInFolderClick = async () => {
    const parsedPlots = await ownerPlotsToCreatePlotStudiesParser(ownerPlots);
    dispatch(
      modalsActions.multiPlotAdd({
        status: true,
        plots: parsedPlots,
      })
    );
  };

  return (
    <Button disabled={isEmpty(ownerPlots)} onClick={handleAddInFolderClick}>
      Ajouter à un dossier
    </Button>
  );
}

export default MultiPlotOwnerBtn;
