import { memo, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../../App/store';
import { getAuthState } from '../../../../../../../features/auth/authSlice';
import { foldersActions } from '../../../../../../../features/folders/foldersSlice';
import useFolder from '../../../../../../../features/folders/useFolder';
import { modalsActions } from '../../../../../../../features/modals/modalsSlice';

import IndividualCircularLoader from '../../../../../../../features/loaders/individualCircularLoader';
import { updateStudyThunk } from '../../../../../../../features/study/services/thunks/studyThunks';
import { getStudyParamsState } from '../../../../../../../features/study/slices/studyParamsSlice';
import { getStudyState } from '../../../../../../../features/study/slices/studySlice';
import { RecursiveSubFolderStatusEnum } from '../../../../../../../features/study/utils/studyEnums';
import { APIStatus } from '../../../../../../../services/axiosFiles/apiTypes';
import ResponsableSelect from '../ResponsableSelect';
import PlotStatusSelect from './PlotStatusSelect';
import styles from './management.module.scss';
import PrioritySelect from './prioritySelect/PrioritySelect';
import FolderAutocomplete from '../../../../../../../features/folders/components/FolderAutocomplete';

interface IManagementContentProps {
  study: IPlotFolderStudy | null;
  updateAllowed: boolean;
}
function ManagementContent({ study, updateAllowed }: IManagementContentProps) {
  const [updatedData, setUpdatedData] = useState<UpdateStudyManagmentProps | null>(
    null
  );
  const { user } = useAppSelector(getAuthState);
  const { managmentLoader, type } = useAppSelector(getStudyParamsState);

  const {
    updateFolder: { recursiveSubFolderUpdateStatus },
  } = useAppSelector(getStudyState);
  const { isSubFolder, foldersApiStatus } = useFolder({ forceLoad: true });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (updatedData) {
      const isRecursive =
        recursiveSubFolderUpdateStatus ===
        RecursiveSubFolderStatusEnum.RECURSIVE_STATUS
          ? 'status'
          : recursiveSubFolderUpdateStatus ===
              RecursiveSubFolderStatusEnum.RECURSIVE_RESPONSABLE
            ? 'responsable'
            : recursiveSubFolderUpdateStatus ===
                RecursiveSubFolderStatusEnum.RECURSIVE_PRIORITY
              ? 'priority'
              : null;

      dispatch(
        updateStudyThunk({
          body: updatedData,
          isRecursive,
        })
      );
      setUpdatedData(null);
    }
  }, [recursiveSubFolderUpdateStatus]);

  const handleUpdate = useCallback(
    (data: UpdateStudyManagmentProps): void => {
      if (data) {
        dispatch(foldersActions.deployedFolderReset());

        if (study) {
          if (isSubFolder(study.idIri)) {
            setUpdatedData({ ...data });
            dispatch(modalsActions.recusiveAssignForSubfolder(true));
          } else {
            dispatch(updateStudyThunk({ body: data }));
          }
        }
      }
    },
    [study, isSubFolder]
  );

  if (managmentLoader) {
    return <IndividualCircularLoader size={200} />;
  }

  return (
    <div className={styles.managementContent}>
      <div className={styles.leftBlock}>
        {foldersApiStatus === APIStatus.PENDING ? (
          <IndividualCircularLoader size={75} />
        ) : (
          <>
            <div className={styles.folderInput}>
              <FolderAutocomplete
                isPlotStudy={type === 'plotStudy'}
                parent={(study?.parent as IFolder) ?? null}
                onUpdate={handleUpdate}
                disabled={!study ? false : !updateAllowed}
              />
            </div>
            <ResponsableSelect
              onUpdate={handleUpdate}
              disabled={!updateAllowed || !study?.parent}
              responsable={study?.responsable ?? (user as User)}
              className={styles.responsableSelect}
            />
          </>
        )}
      </div>

      <div className={styles.rightBlock}>
        {type !== 'sector' && (
          <>
            <PlotStatusSelect
              studyType={type ?? null}
              onUpdate={handleUpdate}
              disabled={!updateAllowed || !study?.parent?.idIri}
              status={study?.status || null}
              className={styles.statusSelect}
            />
            <PrioritySelect
              value={study?.priority ?? 0}
              onUpdate={handleUpdate}
              dataCy="priority"
              className={styles.prioritySelect}
              disabled={!updateAllowed || !study?.parent?.idIri}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default memo(ManagementContent);
