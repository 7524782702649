export function brownfieldsApiToStoreParser(datas: {
  insee: string;
  result: any[];
}): Brownfields {
  try {
    const parsedDatas = datas.result.map((m: any) => brownfieldApiToStoreParser(m));
    return parsedDatas;
  } catch (error) {
    throw new Error('global parser error');
  }
}

export function brownfieldApiToStoreParser(data: any): Brownfield {
  const parsedData: Brownfield = {
    bfActivity: data.bf_activity,
    builtCount: data.built_count ?? 0,
    lastMutationDate: data.last_mutation_date,
    catOwner: data.cat_owner?.map((f: string) =>
      f === '_X_' ? 'Particulier' : f
    ) ?? ['Inconnu'],
    lat: data.latitude,
    lng: data.longitude,
    ownerNames: data.owner_names?.map((f: string) =>
      f === '_X_' ? 'Particulier' : f
    ) ?? ['Inconnu'],
    OwnerTypology: data.Owner_typology,
    bfIdentity: data.bf_identity,
    bfName: data.bf_name,
    bfInfoUrl: data.bf_info_url,
    bfStatus: data.bf_status,
    bfUrl: data.bf_url,
    bfSource: data.bf_source,
    geometry: JSON.parse(data.wkb_geometry),
  };

  return parsedData;
}
