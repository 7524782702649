import { Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './formComponents.module.scss';

interface ISwitchFormCustomProps {
  name: string;
  label: string;
}

function SwitchFormCustom({ name, label }: ISwitchFormCustomProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles.switchContainer}>
      <div>
        <p>{label}</p>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Switch
              {...field}
              checked={field.value ?? false}
              color="primary"
              data-cy="study-contact-form-switch-allowed-to-be-contacted"
            />
          )}
        />
      </div>

      {Boolean(errors.allowedToBeContacted) && (
        <p className={styles.errorSwitch}>
          {errors.allowedToBeContacted?.message as string}
        </p>
      )}
    </div>
  );
}

export default SwitchFormCustom;
