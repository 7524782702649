import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import TextFieldFormCustom from '../../../components/Common/FormComponents/TextFieldFormCustom';
import { authActions, getAuthState } from '../authSlice';

import { classicLoginThunk } from '../authThunks';

import { Checkbox, FormControlLabel } from '@mui/material';
import FieldInputPwdFormCustom from '../../../components/Common/FormComponents/FieldInputPwdFormCustom';
import { initialLoginForm, validationSchemaLoginForm } from './formValidation';
import styles from './loginModal.module.scss';

function LoginForm() {
  const { error } = useAppSelector(getAuthState);

  const dispatch = useAppDispatch();

  const userFormOptions = useForm<ILoginParams>({
    defaultValues: initialLoginForm,
    resolver: yupResolver(validationSchemaLoginForm),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = userFormOptions;

  const submitForm = (data: ILoginParams): void => {
    dispatch(classicLoginThunk(data));
  };

  const handleInputChange = () => {
    if (error) {
      dispatch(authActions.resetError());
    }
  };

  return (
    <FormProvider {...userFormOptions}>
      <form
        className={styles.loginForm}
        onSubmit={handleSubmit(submitForm)}
        autoComplete="off"
      >
        <TextFieldFormCustom
          type="email"
          name="email"
          placeholder="Email"
          required
          onChange={handleInputChange}
          dataCy="email-input"
        />

        <FieldInputPwdFormCustom
          isLoginForm
          name="password"
          placeholder="Mot de passe"
          onChange={handleInputChange}
          dataCy="password-input"
        />

        {error && <p className={styles.error}>{error.message}</p>}

        <FormControlLabel
          className={styles.rememberMe}
          control={<Checkbox defaultChecked color="default" />}
          label="Se souvenir de moi"
        />
        <button type="submit" className={styles.submitButton}>
          SE CONNECTER
        </button>
      </form>
    </FormProvider>
  );
}

export default LoginForm;
