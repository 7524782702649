import { call } from '@redux-saga/core/effects';
import axios from 'axios';
import store from '../../App/store';
import { authActions } from '../../features/auth/authSlice';
import { fetchRefreshToken } from '../../features/auth/requests';
import { setTokensIntoLocalStorage } from '../../features/auth/utils';

export const refreshTokenProcess = async (error: any) => {
  const { refreshToken } = store.getState().auth;
  const config = error.config;

  // config._retry for retry only 1 time
  if (!config._retry) {
    try {
      if (
        refreshToken &&
        (refreshToken === 'null' || refreshToken === 'undefined')
      ) {
        return Promise.reject(error);
      }
      // refreshToken request
      config._retry = true;
      const tokens = await fetchRefreshToken(refreshToken);

      if (tokens) {
        setTokensIntoLocalStorage(tokens.token, tokens.refreshToken);
        store.dispatch(
          authActions.setTokens({
            token: tokens.token,
            refreshToken: tokens.refreshToken,
          })
        );

        // if new token, fech previous rejected datas
        config.headers['Authorization'] = `Bearer ${tokens.token}`;
        return axios(config).then(
          (res) => res,
          (err) => err
        );
      } else {
        return Promise.reject(error);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }
};
// use pagination of jsonId
export function* genericPagingTraitment(
  request: any,
  endpoint: any,
  params: any = {}
): any {
  const memberTabArray = [];
  let isLoop = false;
  let page = 1;

  do {
    params.page = page;
    const response = yield call(request, endpoint, params);
    memberTabArray.push(response['hydra:member']);
    if (response['hydra:view'] && response['hydra:view']['hydra:next']) {
      isLoop = true;
      page += 1;
    } else {
      isLoop = false;
    }
  } while (isLoop);

  return memberTabArray.flat(1);
}
