import { useFormContext } from 'react-hook-form';
import CheckboxFormPdfErrialCustom from '../../shared/components/form/CheckboxFormPdfErrialCustom';
import NumberInputFormPdfErrialCustom from '../../shared/components/rightPanelModalExternalPdfErrial/NumberInputFormPdfErrialCustom';
import styles from '../../shared/styles/rightPanelModalExternalPdfErrial.module.scss';

function LocationInformation() {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div>
      <h3>Bien en location sur le terrain</h3>
      <div className={styles.rowInput}>
        <CheckboxFormPdfErrialCustom name="currentLease" label="Bail en cours" />
      </div>

      <div className={styles.rowInput}>
        <p>Loyer</p>
        <NumberInputFormPdfErrialCustom
          name="actualRent"
          error={Boolean(errors.rent)}
          errorMsg={errors.rent?.message as string}
          onFocus={(e) => e.target.select()}
          hasValueZeroNotNull
        />
      </div>
    </div>
  );
}

export default LocationInformation;
