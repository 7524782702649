import styles from './multiselectPlotModal.module.scss';

interface IModalBtnContainerProps {
  onCancel: () => void;
  onValid: () => void;
}

function ModalBtnContainer({ onCancel, onValid }: IModalBtnContainerProps) {
  return (
    <div className={styles.buttonContainer}>
      <button className={styles.greenSubmitButton} onClick={onValid}>
        VALIDER
      </button>
      <button className={styles.cancelBtn} onClick={onCancel}>
        ANNULER
      </button>
    </div>
  );
}

export default ModalBtnContainer;
