import { useAppDispatch } from '../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../components/Modals/GenericModal';
import { modalsActions } from '../../../modals/modalsSlice';

function AreaChangeConfirmationModal() {
  const dispatch = useAppDispatch();
  const handleCloseModal = () => {};
  const handleOkModal = () => {
    dispatch(
      modalsActions.loadProspecDataConfirm({ display: false, result: 'LOAD' })
    );
  };
  const handleCancelModal = () => {
    dispatch(
      modalsActions.loadProspecDataConfirm({ display: false, result: 'CANCEL' })
    );
  };
  return (
    <GenericModal
      actionType={GenericModalActionEnum.OKCANCEL}
      modalType={GenericModalEnum.INFO}
      closeModal={handleCloseModal}
      okCallback={handleOkModal}
      cancelCallback={handleCancelModal}
      okButtonContent="Recharger les données"
      cancelButtonContent="Conserver les données"
      title="Modification de surface"
    >
      <p>
        La modification de la surface du terrain entraîne la modification des
        paramètres de l&apos;étude comparable de prix.
      </p>
      <p>
        Confirmez-vous la modification, et souhaitez vous recharger les données de
        l&apos;étude comparable ?
      </p>
    </GenericModal>
  );
}

export default AreaChangeConfirmationModal;
