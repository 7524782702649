import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { fetchDatas } from '../../../../services/axiosFiles/genericCrud';
import { sectorsParser } from '../../sectorParser';
import getCookie from '../../../../shared/utils/getCookie';

const sectorsFetchFilteredThunk = createAsyncThunk(
  'sectors/sectorsFetchFilteredThunk',
  async (params: {}, thunkOptions) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { companyIdIri, companyId } = state.company;
      const { users } = state.users;
      const cookieName = 'inputValueFilterSector' + companyId;
      const cookies = getCookie(cookieName);

      const responsableQuery = cookies?.user
        ?.map(
          (elt: ISelectItem) =>
            `&responsable[]=${(elt.value as string).split('/').pop()}`
        )
        .join('');

      if (users && companyIdIri) {
        const result = await fetchDatas(
          `${companyIdIri}/sectors${responsableQuery ? '?' + responsableQuery : ''}`
        );

        const parsed = sectorsParser(result['hydra:member'], users);

        return parsed;
      } else {
        return thunkOptions.rejectWithValue(
          paramsNotFound('sectorsFetchFilteredThunk')
        );
      }
    } catch (error) {
      return thunkOptions.rejectWithValue(error);
    }
  }
);

export default sectorsFetchFilteredThunk;
