import { nanoid } from '@reduxjs/toolkit';
import { useContext, useEffect } from 'react';
import { useAppSelector } from '../../App/store';
import { getPanelState } from '../../features/panels/panelsSlice';
import { getStudyParamsState } from '../../features/study/slices/studyParamsSlice';
import { ProspectionTabContext } from './TabsContent/TabContentContainer';
import { tabs } from './constant';

export default function HeaderTabs(): JSX.Element {
  const { tabValue, setTabValue } = useContext(ProspectionTabContext);

  const {
    rightPanelContent: { selectedTab },
  } = useAppSelector(getPanelState);
  const { type } = useAppSelector(getStudyParamsState);

  const handleSelectTab = (tab: string): void => {
    setTabValue(tab);
  };

  useEffect(() => {
    handleSelectTab(selectedTab);
  }, [selectedTab]);

  return (
    <div className="panel-header-tabs">
      {tabs
        .filter((f) => (type ? f.usedFor.includes(type) : f))
        .map((t) => (
          <div
            key={nanoid()}
            className={`panel-header-tab ${
              tabValue === t.code.toLowerCase() ? 'selected' : ''
            }`}
            onClick={() => handleSelectTab(t.code.toLowerCase())}
            data-cy="right-panel-header-tab"
          >
            {t.name}
          </div>
        ))}
    </div>
  );
}
