import { getZoneAndZoneChildString } from '../../../lib/formats/zoneAndZoneChildFormat';
import { excelCityBankAccountColums } from '../../../redux/plot/constants';
import { exportExcel } from '../../../utils/exportExcel';

export const advancedPlotSearchExportExcel = (items: AdvancedSearchPlots): void => {
  const worksheetColumns = [
    { header: 'Numéro de parcelle', key: 'plotId' },
    { header: 'Taille parcelle', key: 'surface' },
    { header: 'Emprise au sol du bati', key: 'buildArea' },
    { header: 'Zonage PLU', key: 'zone' },
  ];

  const worksheetRow = items.map((m: AdvancedSearchPlot) => ({
    plotId: m.fullPlotId,
    surface: m.area,
    buildArea: m.buildArea,
    zone: getZoneAndZoneChildString({ zone: m.zone, zoneChild: m.zoneChild }),
  }));

  exportExcel(`rapport`, worksheetColumns, worksheetRow);
};

export const handleExportCityBankAccounts = (
  city: string,
  datas: { result: { [x: string]: string | number }[] }
) => {
  exportExcel(`details_comptes_${city}`, excelCityBankAccountColums, datas.result);
};
