import { useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import { getPanelState } from '../../features/panels/panelsSlice';
import DashboarButton from '../Dashboard/dashboardButton/DashboardButton';
import RightBottomButtons from './RightBottomButtons';

export default function ButtonsOnMap(): JSX.Element {
  const { rightButtons } = useAppSelector(getPanelState);
  const { isLogged } = useAppSelector(getAuthState);

  return (
    <>
      {rightButtons && isLogged && <DashboarButton />}
      <RightBottomButtons />
    </>
  );
}
