import { LatLngTuple } from 'leaflet';
import { useEffect, useState } from 'react';
import { addrServProcessAsync } from '../../../../../../utils/addrServProcessAsync';

interface IEnvRisksContentProps {
  latlng: LatLngTuple;
  postalCode: nullable<string>;
  city: nullable<string>;
  inseeCode: string;
  address?: string | null;
  prefix: string;
  section: string;
  number: string;
  envs: IParcelleEnv[];
  id?: string;
  loadAddress?: string;
}

const getEnv = (envs: IParcelleEnv[], id: number) =>
  envs.find((e) => e.cat === id)?.state.join(' - ');
export default function EnvRiskContent({
  address,
  envs,
  latlng,
  id,
  loadAddress,
}: IEnvRisksContentProps) {
  const [loadedAddress, setLoadedAddress] = useState<string | null>(null);

  const fetchAddress = async (lat: number, lng: number) => {
    const result = await addrServProcessAsync(lat, lng);
    if (result?.address) {
      setLoadedAddress(result.address);
    }
  };

  useEffect(() => {
    if (latlng && id && id === loadAddress) {
      fetchAddress(latlng[0], latlng[1]);
    } else {
      setLoadedAddress(address ?? null);
    }
  }, [address, latlng]);

  return (
    <div className="env-risks">
      <p>
        Sismicité : <span>{getEnv(envs, 1)}</span>
      </p>
      <p>
        Argiles : <span>{getEnv(envs, 5)}</span>
      </p>
      {loadedAddress && (
        <p className="georisque-link">
          <a
            href={`https://errial.georisques.gouv.fr/#/?adresse=${loadedAddress}#/`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Obtenir l’Etat des Risques Réglementés pour l’Information des Acquéreurs
            et Locataires
          </a>

          <br />
          <span>
            Une fois sur la plateforme, confirmer l’adresse renseignée, puis
            sélectionnez la/les parcelles concernées sur la carte puis cliquez sur «
            Afficher le résultat » et finalisez le formulaire pour obtenir votre Etat
            des Risques.
          </span>
        </p>
      )}
    </div>
  );
}
