import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';

const initialState: LoaderState = {
  loaderSpinner: false,
  addressLoader: false,
  mapCursorLoader: false,
  currentStep: { step: 0, legend: null },
};
const loaderSlice = createSlice({
  name: 'loaders',
  initialState,
  reducers: {
    loaderShow: (state) => {
      state.loaderSpinner = true;
    },
    loaderHide: (state) => {
      state.loaderSpinner = false;
    },
    addressLoaderSet: (state, action: PayloadAction<boolean>) => {
      state.addressLoader = action.payload;
    },
    currentStepSet: (state, action: PayloadAction<string>) => {
      const step = state.currentStep.step + 1;
      state.currentStep = { step, legend: action.payload };
    },
    currentStepReset: (state) => {
      state.currentStep = { step: 0, legend: null };
    },
  },
});

export default loaderSlice.reducer;
export const loadersActions = loaderSlice.actions;
export const getLoadersState = (state: RootState) => state.loaders;
