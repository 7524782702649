import { Checkbox, FormControlLabel } from '@mui/material';
import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styles from './formComponents.module.scss';

interface ICheckboxFormCustomProps {
  name: string;
  label: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

function CheckboxFormCustom({
  name,
  onChange,
  label,
  className,
  disabled,
}: ICheckboxFormCustomProps) {
  const { control } = useFormContext();
  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    onChangeInternal: (e: ChangeEvent<HTMLInputElement>) => void
  ) => {
    onChangeInternal(e);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={[styles.checkContainer, className].join(' ')}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            label={label}
            className={styles.checkLabel}
            labelPlacement="end"
            control={
              <Checkbox
                className={className}
                onChange={(e) => handleChange(e, onChange)}
                checked={value}
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                disabled={disabled}
              />
            }
          />
        )}
      />
    </div>
  );
}

export default CheckboxFormCustom;
