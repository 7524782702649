import { TextField } from '@mui/material';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../../components/Modals/GenericModal';
import { displayManagerActions } from '../../../displayManager/displayManagerSlice';
import {
  getMapPluginsDrawState,
  mapPluginsActions,
} from '../../../map/leaflet/plugins/mapPluginSlice';
import { modalsActions } from '../../../modals/modalsSlice';
import { getSectorState } from '../../sectorSlice';
import sectorCreateThunk from '../../services/thunks/sectorCreateThunk';
import sectorUpdateThunk from '../../services/thunks/sectorUpdateThunk';
import styles from './editSectorModal.module.scss';

function EditSectorModal() {
  const [sectorName, setSectorName] = useState('');
  const { currentSector } = useAppSelector(getSectorState);
  const { geometry } = useAppSelector(getMapPluginsDrawState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentSector) {
      setSectorName(currentSector.name);
    }
  }, [currentSector]);

  const onChangeSectorName = (e: ChangeEvent<HTMLInputElement>) => {
    setSectorName(e.target.value);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (geometry) {
      const payload = {
        name: sectorName,
        wkbGeometry: geometry,
      };

      if (currentSector) {
        dispatch(
          sectorUpdateThunk({
            ...payload,
            idIri: currentSector.idIri,
            indexId: currentSector.indexId ?? 0,
          })
        );
      } else {
        dispatch(sectorCreateThunk({ ...payload, mailBoxes: 0 }));
      }
    }
  };

  const handleCloseModal = () => {
    dispatch(displayManagerActions.backupReplace());
    dispatch(mapPluginsActions.resetDraw());
    dispatch(modalsActions.sectorEdit(false));
  };

  return (
    <GenericModal
      actionType={GenericModalActionEnum.NONE}
      modalType={GenericModalEnum.INFO}
      hasIcon={false}
      title={currentSector ? 'Edition de secteur' : 'Création de secteur'}
      closeModal={handleCloseModal}
      okButtonContent="Enregistrer"
    >
      <form
        className={styles.formContainer}
        autoComplete="off"
        onSubmit={handleSubmit}
      >
        <TextField
          className={styles.input}
          name="sectorName"
          value={sectorName}
          placeholder="Nom du secteur"
          type="text"
          variant="outlined"
          fullWidth={true}
          onChange={onChangeSectorName}
          required
          data-cy="edit-sector-name-input"
        />

        <button type="submit" className={styles.submitButton}>
          Valider
        </button>
      </form>
    </GenericModal>
  );
}

export default EditSectorModal;
