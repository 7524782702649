import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { plotStudiesParser, plotStudyParser } from '../utils/parsers/studyParser';

export interface IFechPlotStudiesProps {
  urlBase: string;
  params?: QueryParams;
  isContactPlotStudies?: boolean;
  users: Users | null;
  statuses: StudyStatuses | null;
  folders: Folders | null;
}

export const fetchPlotStudies = async ({
  urlBase,
  params,
  isContactPlotStudies,
  folders,
  statuses,
  users,
}: IFechPlotStudiesProps) => {
  try {
    if (urlBase && statuses && users && folders) {
      const plotStudies: any = await fetchDatas(`${urlBase}/plot_studies`, params);
      let parsedPlotStudies: PlotStudies = [];

      if (isContactPlotStudies) {
        parsedPlotStudies = plotStudies['hydra:member'].map((m: any) =>
          plotStudyParser(m.plotStudy, users, statuses, folders)
        );
      } else {
        parsedPlotStudies = plotStudiesParser(
          plotStudies['hydra:member'],
          users,
          statuses,
          folders
        );
      }

      return parsedPlotStudies;
    } else {
      return Promise.reject('fetchPlotStudies');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};
