import { useContext, useEffect, useState } from 'react';
import '../styles/menuTabs.scss';
import Environment from './Environment';
import ConnectComponent from './MenuTabsComponents/ConnectComponent';
import MenuTabsHeader from './MenuTabsComponents/MenuTabsHeader';
import Prospection from './Prospection';
//Import Components
import { InputTabsContext } from '../../../App/Layout';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import AdvancedPlotSearchContainer from '../../../features/advancedPlotSearch/advancedPlotSearchContainer';
import { advancedPlotSearchActions } from '../../../features/advancedPlotSearch/advancedPlotSearchSlice';
import { getAuthState } from '../../../features/auth/authSlice';
import { mapPluginsActions } from '../../../features/map/leaflet/plugins/mapPluginSlice';
import { panelsActions } from '../../../features/panels/panelsSlice';
import Urbanisme from './Urbanisme';

interface IMenuTabsProps {
  isCollapsed: boolean;
}

const MenuTabs = ({ isCollapsed }: IMenuTabsProps) => {
  const { menuTab, setMenuTab } = useContext(InputTabsContext);
  const [selectedTab, setSelectedTab] = useState<string>(menuTab);
  const { isLogged } = useAppSelector(getAuthState);

  const dispatch = useAppDispatch();

  const handleSelectedTab = (tab: string) => {
    if (isCollapsed && tab !== '') {
      dispatch(panelsActions.leftPanelUnCollapse());
    }
    setSelectedTab(tab);
    setMenuTab(tab);
  };

  useEffect(() => {
    if (selectedTab !== 'RECHERCHE & CALCULS') {
      dispatch(mapPluginsActions.drawStop());
      dispatch(advancedPlotSearchActions.setGeoFromDraw(null));
    }
  }, [selectedTab]);

  return (
    <div className="menu-tabs-wrapper">
      <div className={`tabs-buttons ${isCollapsed && 'collapse-tabs'}`}>
        <MenuTabsHeader
          isCollapsed={isCollapsed}
          onSelectedTab={handleSelectedTab}
          menuTab={menuTab}
        />
      </div>
      {isLogged && !isCollapsed ? (
        <div>
          {selectedTab === 'URBANISME' && <Urbanisme />}
          {selectedTab === 'RECHERCHE & CALCULS' && <AdvancedPlotSearchContainer />}
          {selectedTab === 'ENVIRONNEMENT' && <Environment />}
          {selectedTab === 'MA PROSPECTION' && <Prospection />}
        </div>
      ) : (
        !isCollapsed && !isLogged && <ConnectComponent />
      )}
    </div>
  );
};

export default MenuTabs;
