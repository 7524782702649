import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  classicLoginThunk,
  externalConnectLoginThunk,
  fetchAuthUserThunk,
  firstPasswordThunk,
  forgotPasswordThunk,
  logoutThunk,
  resetPasswordThunk,
} from './authThunks';

export enum UserRoleEnum {
  ROLE_USER = 'ROLE_USER',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

const initialState: AuthState = {
  token: null,
  refreshToken: null,
  refreshTokenFail: false,
  userIdIri: null,
  userId: null,
  isLogged: false,
  isManager: false,
  isAdmin: false,
  user: null,
  apiStatus: APIStatus.IDLE,
  error: null,
  deniedZone: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<ILoginResponse>) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    refreshTokenFail: (state, action: PayloadAction<boolean>) => {
      state.refreshTokenFail = action.payload;
    },
    setIsLogged: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload;
    },
    setDeniedZone: (state, action: PayloadAction<boolean>) => {
      state.deniedZone = action.payload;
    },
    updateRightAccess: (state, action: PayloadAction<CompanyUser>) => {
      state.isManager = action.payload.roles.includes(UserRoleEnum.ROLE_MANAGER);
      state.isAdmin = action.payload.roles.includes(UserRoleEnum.ROLE_ADMIN);
      if (state.user) {
        state.user.isActive = action.payload.userIsActive;
        state.user.isManager = action.payload.roles.includes(
          UserRoleEnum.ROLE_MANAGER
        );
        state.user.isAdmin = action.payload.roles.includes(UserRoleEnum.ROLE_ADMIN);
      }
    },
    resetError: (state) => {
      state.apiStatus = APIStatus.IDLE;
      state.error = null;
    },
  },
  extraReducers(builder) {
    // LOGIN REQUEST
    builder
      .addCase(classicLoginThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
        state.error = null;
      })
      .addCase(
        classicLoginThunk.fulfilled,
        (state, action: PayloadAction<ILoginResponse>) => {
          state.apiStatus = APIStatus.IDLE;
          state.error = null;
          state.token = action.payload.token;
          state.refreshToken = action.payload.refreshToken;
        }
      )
      .addCase(classicLoginThunk.rejected, (state, action) => {
        state.apiStatus = APIStatus.REJECTED;
        state.error = action.error;
      });
    // FETCH AUTH USER REQUEST
    builder
      .addCase(fetchAuthUserThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchAuthUserThunk.fulfilled,
        (state, action: PayloadAction<User>) => {
          const isAdmin = action.payload.roles.includes(UserRoleEnum.ROLE_ADMIN);
          const isManager = action.payload.roles.includes(UserRoleEnum.ROLE_MANAGER);

          state.apiStatus = APIStatus.IDLE;
          state.error = null;
          state.user = action.payload;
          state.userId = action.payload.id;
          state.userIdIri = action.payload.idIri;
          state.isManager = isManager || isAdmin;
          state.isAdmin = isAdmin;
        }
      )
      .addCase(fetchAuthUserThunk.rejected, (state, action) => {
        state.apiStatus = APIStatus.REJECTED;
        state.error = action.error;
      })
      .addCase(logoutThunk.pending, (state) => {})
      .addCase(logoutThunk.fulfilled, (state) => {})
      .addCase(logoutThunk.rejected, (state) => {})
      .addCase(externalConnectLoginThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(externalConnectLoginThunk.fulfilled, (state) => {
        state.apiStatus = APIStatus.IDLE;
        state.error = null;
      })
      .addCase(
        externalConnectLoginThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.apiStatus = APIStatus.REJECTED;
          state.error = action.payload;
        }
      )
      .addCase(forgotPasswordThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(forgotPasswordThunk.fulfilled, (state) => {
        state.apiStatus = APIStatus.IDLE;
        state.error = null;
      })
      .addCase(forgotPasswordThunk.rejected, (state, action: PayloadAction<any>) => {
        state.apiStatus = APIStatus.REJECTED;
        state.error = action.payload;
      })

      .addCase(resetPasswordThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(resetPasswordThunk.fulfilled, (state) => {
        state.apiStatus = APIStatus.IDLE;
        state.error = null;
      })
      .addCase(resetPasswordThunk.rejected, (state, action: PayloadAction<any>) => {
        state.apiStatus = APIStatus.REJECTED;
        state.error = action.payload;
      })
      .addCase(firstPasswordThunk.pending, (state) => {
        state.apiStatus = APIStatus.PENDING;
      })
      .addCase(firstPasswordThunk.fulfilled, (state) => {
        state.apiStatus = APIStatus.IDLE;
        state.error = null;
      })
      .addCase(firstPasswordThunk.rejected, (state, action: PayloadAction<any>) => {
        state.apiStatus = APIStatus.REJECTED;
        state.error = action.payload;
      });
  },
});

export default authSlice.reducer;
export const authActions = authSlice.actions;
export const getAuthState = (state: RootState) => state.auth;
