import { memo } from 'react';
import SelectCustom from '../../../components/Common/FormComponents/SelectCustom';
import styles from './filterSortBloc.module.scss';

interface IGenericSelectSortProps {
  items: ISelectItem[];
  noValue?: string;
  sort?: string | null;
  onSortChange: (value: string, type: string) => void;
  disabled?: boolean;
}
function GenericSelectSortOwnerDatas({
  items,
  noValue,
  sort,
  onSortChange,
  disabled,
}: IGenericSelectSortProps) {
  const handleChange = (e: ChangeEventCustom) => {
    const value = e.target.value;

    onSortChange(value, 'sort');
  };

  return (
    <div className={styles.sortSelect}>
      <SelectCustom
        items={items}
        value={sort ?? ''}
        name="sort"
        noValue={noValue ?? 'Trier par'}
        displayEmpty
        onChange={handleChange}
        disabled={disabled}
      />
    </div>
  );
}

export default memo(GenericSelectSortOwnerDatas);
