import styles from '../toolbars.module.scss';
import CreateToolbarBtn from '../urbaToolbar/CreateToolbarBtn';
import useMapCenterBtn from './hooks/useMapCenterBtn';

function StreetViewMapBtn() {
  const { handleClick } = useMapCenterBtn();

  return (
    <CreateToolbarBtn
      icon="STREET_VIEW_MAP"
      onClick={() => handleClick('/street-view')}
      isFontAwesomeIcon
      tooltip={
        <p className={[styles.btnTooltip, styles.left].join(' ')}>
          Afficher Street View
        </p>
      }
    />
  );
}

export default StreetViewMapBtn;
