import { postEntity } from '../../../../../../services/axiosFiles/genericCrud';
import { prospecGetSimilarAdsParser } from '../../../../landPDF/orpiLandDataParser';

interface IProps {
  prospecCityId: string | null;
  area: number | null;
  price: number | null;
}

async function fetchProspecGetSimilarAds({ area, price, prospecCityId }: IProps) {
  try {
    if (!price || price === 0) {
      throw new Error('price error');
    }
    if (!area || area === 0) {
      throw new Error('area error');
    }

    if (prospecCityId) {
      const body = {
        prospec_city_id: prospecCityId,
        ads_limit: 40,
        land_area: parseInt(area.toString(), 10),
        price,
      };

      const result: any = await postEntity({
        endpoint: '/pdf_urbanease/prospec_get_similar_ads',
        body,
      });

      const parsed = prospecGetSimilarAdsParser(result);

      return parsed;
    } else {
      throw new Error('cityId is null');
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default fetchProspecGetSimilarAds;
