import {
  action,
  createRequestTypes,
  FAILURE,
  REQUEST,
  RESET,
  SET,
  SUCCESS,
} from '../common/actions';

interface IUsersPlotFetch {
  userIdIri: string;
  plotStudies: IPlotStudy[];
}

export const FETCH_PLOT = createRequestTypes('FETCH_PLOT');
export const DISPLAY_BUILDING_CENTROID = createRequestTypes(
  'DISPLAY_BUILDING_CENTROID'
);
export const FETCH_PLOTS_BY_USER_ID = createRequestTypes('FETCH_PLOTS_BY_USER_ID');
export const GET_PARCELLE = createRequestTypes('GET_PARCELLE');
export const FETCH_PLOT_BY_PLOT_ID = createRequestTypes('FETCH_PLOT_BY_PLOT_ID');
export const ADD_PLOT_FOR_FOLDER = createRequestTypes('ADD_PLOT_FOR_FOLDER');
export const PLOT_IS_LOADING = createRequestTypes('PLOT_IS_LOADING');
export const FETCH_ADVANCED_SEARCH_PARCELLES = createRequestTypes(
  'FETCH_ADVANCED_SEARCH_PARCELLES'
);
export const FETCH_ADVANCED_SEARCH_PARCELLES_EXPORT_EXCEL = createRequestTypes(
  'FETCH_ADVANCED_SEARCH_PARCELLES_EXPORT_EXCEL'
);
export const FETCH_CITY_BANK_ACCOUNTS_DATAS = createRequestTypes(
  'FETCH_CITY_BANK_ACCOUNTS_DATAS'
);

export const IS_PARCELLE_SELECTOR = createRequestTypes('IS_PARCELLE_SELECTOR');
export const FETCH_CITY_PARCELLE_OWNERS = createRequestTypes(
  'FETCH_CITY_PARCELLE_OWNERS'
);
export const POLYLINE_MEASURER_ENABLE = createRequestTypes(
  'POLYLINE_MEASURER_ENABLE'
);
export const PLOT_RESET = 'PLOT_RESET';

const plotActions = {
  fetchPlot: {
    request: (data: IFetchPlotRequest) =>
      action(FETCH_PLOT[REQUEST], { payload: data }),
    success: (data: nullable<IPlot>) => {
      return action(FETCH_PLOT[SUCCESS], { payload: { parcelle: data } });
    },
    failure: (error: any) => {
      return action(FETCH_PLOT[FAILURE], { payload: error });
    },
  },
  plotIsLoading: (value: Boolean) => {
    return action(PLOT_IS_LOADING[SET], { payload: value });
  },
  fetchPlotByPlotId: {
    request: (data: IParcelleByIdPayload) =>
      action(FETCH_PLOT_BY_PLOT_ID[REQUEST], { payload: data }),
    success: (data: nullable<IPlot>) => {
      return action(FETCH_PLOT_BY_PLOT_ID[SUCCESS], { payload: { parcelle: data } });
    },
    failure: (error: any) => {
      return action(FETCH_PLOT_BY_PLOT_ID[FAILURE], { payload: error });
    },
  },
  fetchSearchParcellesExportExcel: {
    request: () => action(FETCH_ADVANCED_SEARCH_PARCELLES_EXPORT_EXCEL[REQUEST], {}),
    failure: () => action(FETCH_ADVANCED_SEARCH_PARCELLES_EXPORT_EXCEL[FAILURE], {}),
  },
  fetchCityBankAccountDatas: {
    request: () => action(FETCH_CITY_BANK_ACCOUNTS_DATAS[REQUEST], {}),
    failure: () => action(FETCH_CITY_BANK_ACCOUNTS_DATAS[FAILURE], {}),
  },
  plotForFolder: {
    add: (data: Partial<IPlotStudy>) =>
      action(ADD_PLOT_FOR_FOLDER[SET], { payload: data }),
    reset: () => action(ADD_PLOT_FOR_FOLDER[RESET]),
  },
  getParcelle: {
    request: (data: any) => action(GET_PARCELLE[REQUEST], { payload: data }),
    success: (data: any) => {
      return action(GET_PARCELLE[SUCCESS], { payload: data });
    },
    failure: (error: any) => {
      return action(GET_PARCELLE[FAILURE], { payload: error });
    },
  },
  fetchPlotsByUserId: {
    request: (
      data: string,
      callback: (value: IPlotStudy[]) => void,
      createdAtAfter: string,
      createdAtBefore: string
    ) =>
      action(FETCH_PLOTS_BY_USER_ID[REQUEST], {
        payload: { data, callback, createdAtAfter, createdAtBefore },
      }),
    success: (data: IUsersPlotFetch) => {
      return action(FETCH_PLOTS_BY_USER_ID[SUCCESS], { payload: data });
    },
    failure: (error: any) => {
      return action(FETCH_PLOTS_BY_USER_ID[FAILURE], { payload: error });
    },
  },
  polylineMeasurerEnable: {
    request: (data: boolean) =>
      action(POLYLINE_MEASURER_ENABLE[REQUEST], {
        payload: { polylineMeasurerEnable: data },
      }),
  },
  buildingCentroid: {
    set: (data: boolean) =>
      action(DISPLAY_BUILDING_CENTROID[SET], { payload: data }),
  },
  reset: () => action(PLOT_RESET),
};

export default plotActions;
