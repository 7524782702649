import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import orpiLogo from '../../../../../assets/images/orpiPdf/orpiLogo.png';

const styles = StyleSheet.create({
  homeFooter: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    backgroundColor: '#FE0000',
    height: '100px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#ffffff',
  },
  homeFooterImageSection: {
    width: '40%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  homeFooterImage: { width: '40%' },
  homeFooterImageLine: {
    height: '20%',
    margin: '0 10px',
    borderLeft: '1px solid #fff',
  },
  homeFooterImageTextSection: { fontSize: '9px' },
  homeFooterLine: { height: '80%', borderLeft: '1px solid #fff' },
  homeFooterTextSection: {
    fontFamily: 'OrpiFamily',
    fontStyle: 'normal',
    paddingLeft: '20px',
    flexGrow: 1,
    color: '#ffffff',
    fontSize: '12px',
  },
  homeFooterNameText: { padding: '2px 0 2px 5px', fontWeight: 700 },
  homeFooterNameSection: { display: 'flex', flexDirection: 'row' },
  homeFooterText: { padding: '2px 0 2px 5px' },
});

interface IHomeFooterProps {
  user: {
    lastName: string;
    firstName: string;
    phone: string;
    email: string;
  };
  companyName: string;
}

function HomeFooter({ user, companyName }: IHomeFooterProps) {
  return (
    <View style={styles.homeFooter}>
      <View style={styles.homeFooterImageSection}>
        <Image src={orpiLogo} style={styles.homeFooterImage} />
        <View style={styles.homeFooterImageLine} />
        <View style={styles.homeFooterImageTextSection}>
          <Text>L&#39;esprit coopératif,</Text>
          <Text>ça change l&#39;immobilier</Text>
        </View>
      </View>

      <View style={styles.homeFooterLine} />
      <View style={styles.homeFooterTextSection}>
        <Text style={styles.homeFooterText}>Dossier suivi par :</Text>
        <View style={styles.homeFooterNameSection}>
          <Text
            style={styles.homeFooterNameText}
          >{`${user.lastName} ${user.firstName}`}</Text>
          <Text style={styles.homeFooterText}>{`  -  ${companyName}`}</Text>
        </View>
        <Text style={styles.homeFooterText}>{user.phone}</Text>
        <Text style={styles.homeFooterText}>{user.email}</Text>
      </View>
    </View>
  );
}

export default HomeFooter;
