import ButtonGeneric from '../../../../../shared/components/buttons/buttonGeneric/ButtonGeneric';
import styles from '../../../styles/mailshotAccountTabContent.module.scss';
import { MailshotTagEnum } from '../../../types/mailshotsEnums';
import { ITagBtnClickProps } from './TagButtonContainer';

function PlotContent({ onBtnClick }: ITagBtnClickProps) {
  const color: ColorType = 'ORANGE';
  const handleClick = (tag: MailshotTagEnum) => onBtnClick(tag, color);

  return (
    <div className={styles.btnContainer}>
      <ButtonGeneric
        content="N° parcelle"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.NUMERO_PARCELLE)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Surface parcelle"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.SURFACE_PARCELLE)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Adresse complète parcelle"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.ADRESSE_PARCELLE)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Zone PLU parcelle"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.ZONE_PARCELLE)}
        containerClassName={styles.button}
      />
    </div>
  );
}

export default PlotContent;
