import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { checkForNullValue } from '../../utils';
import { fontStyles } from '../styles/pdfStyles';
import InformationLine from './InformationLine';

interface IProps {
  formDatas: IExternalPdfLandForm;
}
function LocationInformationPdf({ formDatas }: IProps) {
  return (
    <View>
      <View style={styles.title}>
        <Text style={[fontStyles.b700, fontStyles.fs20, fontStyles.cOrpi]}>
          Bien en location
        </Text>
      </View>

      <InformationLine label="Bail en cours" value={formDatas.currentLease} />

      <InformationLine
        label="Loyer"
        value={checkForNullValue(formDatas.actualRent)}
      />
    </View>
  );
}

export default LocationInformationPdf;

const styles = StyleSheet.create({ title: { marginBottom: '20px' } });
