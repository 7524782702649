export const getStudyTypeFromIdIri = (idIri: string): PanelParamsType => {
  const tempType = idIri.split('/')[1];

  switch (tempType) {
    case 'folders':
      return 'folder';
    case 'sectors':
      return 'sector';
    default:
    case 'plot_studies':
      return 'plotStudy';
  }
};
