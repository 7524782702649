import { changeApiKeyForStoreKey, mapObjectParser } from '../common/parserCommon';

interface ISubPluOthers {
  masterCat: string;
  cat: number;
  subCat: number;
  libelle: string;
  mapboxUrl: string;
  displayed: boolean;
}

interface IParser extends ISubPluOthers {
  subCat: number;
}

export interface IPluOthers extends ISubPluOthers {
  subCats?: ISubPluOthers[];
}

const pluOtherMapping = {
  cat: 'cat',
  sub_cat: 'subCat',
  master_cat: 'masterCat',
  libelle: 'libelle',
  mapbox_url: 'mapboxUrl',
};

export function pluOthersApiToStoreParser(data: any): IPluOthers[] {
  const parsedData = mapObjectParser(data, pluOtherApiToStoreParser);

  return parsedData;
}

export function pluOtherApiToStoreParser(data: any): IParser {
  const temp = changeApiKeyForStoreKey<IParser>(data, pluOtherMapping);
  temp.displayed = false;

  return temp;
}
