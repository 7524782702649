interface IProps {
  onClick: () => void;
  disabled: boolean;
  click: boolean;
}
import { MouseEvent } from 'react';
import { ReactComponent as RightArrowSmall } from '../../../../assets/images/right-arrow-small.svg';
import styles from '../styles/orpiButton.module.scss';

function OrpiButton({ onClick, disabled, click }: IProps) {
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    // disable form validation before launch click
    // e.preventDefault();
    onClick();
  };
  return (
    <button
      className={`${styles.btnPdf} ${disabled && styles.disabled} ${
        click && styles.hidden
      }`}
      onClick={handleClick}
      disabled={disabled}
    >
      <>
        Générer mon PDF
        <RightArrowSmall />
      </>
    </button>
  );
}

export default OrpiButton;
