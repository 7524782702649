import styles from './advancedPlotSearchModal.module.scss';

function FulfilledContent({ plotCount }: { plotCount: number }) {
  return (
    <div className={styles.fulfilledContent}>
      {plotCount === 0 && <p>Aucune parcelle a été trouvée.</p>}
      {plotCount === 200 && (
        <p className="align-left">
          Attention votre recherche dépasse les 200 résultats possibles à
          l’affichage.
          <br />
          Nous vous conseillons d’affiner vos critères.
        </p>
      )}
      {plotCount < 200 && plotCount > 0 && (
        <p>{`${plotCount} parcelles correspondent à votre recherche.`}</p>
      )}
    </div>
  );
}

export default FulfilledContent;
