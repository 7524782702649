import { Checkbox } from '@mui/material';
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../App/store';
import { dpeActions, getDpeState } from '../../builtCaracteristicsSlice';

interface IDpeDisplayCheckbox {
  setloader: Dispatch<SetStateAction<boolean>>;
}

function DpeDisplayCheckbox({ setloader }: IDpeDisplayCheckbox) {
  const {
    isRecentOnly,
    dpeGFVisible,
    dpeVisible,
    generateDisplay,
    dpeAdvancedSearchFormData,
  } = useAppSelector(getDpeState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!generateDisplay) {
      setloader(false);
    }
  }, [generateDisplay]);

  const handleChangeCheckbox = (e: ChangeEvent<HTMLInputElement>, type: string) => {
    const value = e.target.checked;

    if (dpeAdvancedSearchFormData) {
      dispatch(dpeActions.setDpeAdvancedSearchFormData(null));
      dispatch(dpeActions.resetDpeAdvancedSearchPoints());
    }
    if (value) {
      setloader(true);
      dispatch(dpeActions.setGenerateDisplay(true));
    }
    switch (type) {
      case 'dpeGF':
        dispatch(value ? dpeActions.showDpeGF() : dpeActions.hideDpeGF());
        break;
      case 'dpe':
      default:
        dispatch(value ? dpeActions.showDpe() : dpeActions.hideDpe());
        break;
    }
  };

  const handleRecentclick = () => {
    if (dpeAdvancedSearchFormData) {
      dispatch(dpeActions.setDpeAdvancedSearchFormData(null));
      dispatch(dpeActions.resetDpeAdvancedSearchPoints());
    }
    dispatch(isRecentOnly ? dpeActions.isRecentHide() : dpeActions.isRecentShow());
  };

  return (
    <ul>
      <li>
        <Checkbox
          color="primary"
          name="dp"
          size={'small'}
          inputProps={{ 'aria-label': 'secondary checkbox' }}
          onChange={(e) => handleChangeCheckbox(e, 'dpe')}
          checked={dpeVisible}
        />

        <p>Afficher tous les DPE</p>
      </li>
      <li>
        <ul>
          <li>
            <Checkbox
              color="primary"
              name="dp"
              size={'small'}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={(e) => handleChangeCheckbox(e, 'dpeGF')}
              checked={dpeGFVisible}
            />

            <p>
              Afficher uniquement les DPE de logements énergivores
              <span> (G et F)</span>
            </p>
          </li>
          <li>
            <Checkbox
              color="primary"
              name="dp"
              size={'small'}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
              onChange={handleRecentclick}
              checked={isRecentOnly}
            />
            <p>
              DPE récents uniquement <span>(moins de 3 mois)</span>
            </p>
          </li>
        </ul>
      </li>
    </ul>
  );
}

export default DpeDisplayCheckbox;
