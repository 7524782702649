import GearIcon from '../../shared/components/icons/GearIcon';
import styles from './itemActions.module.scss';

function OptionsIcon() {
  return (
    <GearIcon
      dataCy="options-icon"
      width={37}
      height={37}
      color="WHITE"
      bgColor="PRIMARY"
      isRoundIcon
      tooltipDisabled
      className={styles.icon}
    />
  );
}

export default OptionsIcon;
