import React, { createContext, useContext, useState } from 'react';

export default function createCtx<A>(defaultValue: A) {
  type UpdateType = React.Dispatch<React.SetStateAction<typeof defaultValue>>;
  const defaultUpdate: UpdateType = () => defaultValue;
  const ctx = createContext({
    state: defaultValue,
    update: defaultUpdate,
  });

  const Provider = (props: React.PropsWithChildren<{}>) => {
    const [state, update] = useState(defaultValue);
    return <ctx.Provider value={{ state, update }} {...props} />;
  };

  return [ctx, Provider] as const; // alternatively, [typeof ctx, typeof Provider]
}

export const useGenericStateContext = <A,>(
  ctx: React.Context<{ state: A; update: React.Dispatch<React.SetStateAction<A>> }>
) => {
  const context = useContext(ctx);

  if (!context) {
    throw new Error('useGenericStateContext must be used within a Provider');
  }

  return context;
};
