import { createAsyncThunk } from '@reduxjs/toolkit';
import { deleteEntity } from '../../../../services/axiosFiles/genericCrud';
import { modalsActions } from '../../../modals/modalsSlice';
import { GenericModalEnum } from '../../../../components/Modals/GenericModal';

const deleteMailshotModelThunk = createAsyncThunk(
  'mailshot/deleteMailshotModelThunk',
  async (props: { model: MailshotModelType | null }, { dispatch }) => {
    try {
      if (!props.model) throw new Error('Model is missing');
      if (!props.model.id) throw new Error('Model id is missing');
      if (props.model.isDefault)
        throw new Error("It is a default model, it can't be deleted");

      await deleteEntity(props.model.id);

      return props.model.id;
    } catch (error) {
      dispatch(
        modalsActions.alert({
          status: true,
          context: 'deleteMailshotModelError',
          modalType: GenericModalEnum.ERROR,
        })
      );
      return Promise.reject(error);
    }
  }
);

export default deleteMailshotModelThunk;
