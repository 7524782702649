import GenericInputResearchOwnerDatas from './GenericInputResearchOwnerDatas';
import SortComponent from './SortComponent';
import styles from './filterSortBloc.module.scss';

interface IFilterSortBlocProps {
  selectItems: ISelectItem[];
  selectPlaceHolder: string;
  inputPlaceHolder?: string;
  sortValue: string | null;
  orderValue: OwnerOrder;
  onSortChange: (value: SortComponentState) => void;
}

export default function FilterSortBlocOwnerData({
  selectItems,
  inputPlaceHolder,
  selectPlaceHolder,
  sortValue,
  orderValue,
  onSortChange,
}: IFilterSortBlocProps) {
  return (
    <div className={styles.filterSortBloc}>
      <GenericInputResearchOwnerDatas placeHolder={inputPlaceHolder} />

      <SortComponent
        sortValue={sortValue}
        orderValue={orderValue}
        sortItems={selectItems ?? []}
        placeHolder={selectPlaceHolder}
        onSortChange={onSortChange}
      />
    </div>
  );
}
