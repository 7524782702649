import { GeoJSON, useMap } from 'react-leaflet';
import { useAppSelector } from '../../../App/store';
import { getAdvancedPlotSearchState } from '../advancedPlotSearchSlice';

const jsonStyle = {
  fillColor: 'purple',
  weight: 2,
  color: 'purple',
  fillOpacity: 0.4,
};

function AdvancedSearchZone() {
  const map = useMap();

  const { geoFromDraw } = useAppSelector(getAdvancedPlotSearchState);

  // geoformDrow is used only for display zone
  if (!geoFromDraw) return null;

  return <GeoJSON style={jsonStyle} data={geoFromDraw} />;
}

export default AdvancedSearchZone;
