export const userPlotsHeaderCell = [
  { title: 'Commune' },
  { title: 'Parcelle' },
  { title: 'Dossier' },
  { title: 'Zonage PLU' },
  { title: 'Surface parcelle' },
  { title: 'Prix de vente souhaité' },
  { title: "CA prévu pour l'opération" },
  { title: 'Surface constructible projet' },
  { title: 'Statut de l’opportunité' },
  { title: 'Priorité' },
  { title: 'Date de création' },
];

export const userPlotTableSortItems = [
  { value: 'city', display: 'Commune' },
  { value: 'plotId', display: 'Parcelle' },
  { value: 'folderName', display: 'Dossier' },
  { value: 'zone', display: 'Zonage PLU' },
  { value: 'area', display: 'Surface parcelle' },
  { value: 'askingPrice', display: 'Prix de vente souhaité' },
  { value: 'expectedRevenue', display: "CA prévu pour l'opération" },
  { value: 'buildableArea', display: 'Surface constructible projet' },
  { value: 'status', display: 'Statut de l’opportunité' },
  { value: 'priority', display: 'Priorité' },
  { value: 'createdAt', display: 'Date de création' },
];
