import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import DeleteWithConfirmationModal from '../../../shared/components/deleteWithConfirmationModal';
import checkUserCanDelete from '../../../shared/utils/checkUserCanDelete';
import { getAuthState } from '../../auth/authSlice';
import { modalsActions } from '../../modals/modalsSlice';
import { foldersActions, getFoldersState } from '../foldersSlice';
import { folderDeleteThunk } from '../services/thunks/foldersThunk';

const DeleteFolderModal = () => {
  const { user } = useAppSelector(getAuthState);
  const { folderForAction } = useAppSelector(getFoldersState);
  const [hasUndeletableSubfolder, setHasUndeletableSubfolder] = useState<
    string[] | null
  >(null);

  const dispatch = useAppDispatch();

  const isEmptyFolder =
    folderForAction?.plotStudiesCount === 0 && folderForAction.subCount === 0;

  useEffect(() => {
    if (folderForAction && user) {
      const result = checkUserCanDelete(folderForAction.subs, user);

      if (!isEmpty(result)) {
        setHasUndeletableSubfolder(result as string[]);
      }
    }
  }, [folderForAction, user]);

  const handleCloseModal = () => {
    dispatch(foldersActions.folderForActionReset());
    dispatch(modalsActions.folderDelete(false));
  };

  const handleConfirmationValid = () => {
    dispatch(folderDeleteThunk({ folder: folderForAction as IFolder }));
    handleCloseModal();
  };

  return (
    <DeleteWithConfirmationModal
      onDeleteClick={handleConfirmationValid}
      isEmpty={isEmptyFolder}
      hasUndeletableSubs={hasUndeletableSubfolder}
      name={folderForAction?.name ?? ''}
      onCloseModal={handleCloseModal}
      type="dossier"
    />
  );
};

export default DeleteFolderModal;
