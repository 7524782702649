import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import AreaFieldIcon from '../../../../../assets/images/areaFieldIcon.svg';
import { getMapState } from '../../../../map/mapSlice';
import { getModalsState, modalsActions } from '../../../../modals/modalsSlice';
import { getLandAreaTypeFromNumber } from '../../../landPDF/orpiLandDataParser';
import fetchProspecAdsStatsThunk from '../../../services/thunks/landPDF/fetchProspecAdsStatsThunk';
import fetchProspecSimilariesThunk from '../../../services/thunks/landPDF/fetchProspecSimilariesThunk';
import useRightPanelPDF from '../../hooks/useRightPanelPdf';
import styles from '../../styles/rightPanelModalExternalPdfErrial.module.scss';
import NumberInputFormPdfErrialCustom from '../rightPanelModalExternalPdfErrial/NumberInputFormPdfErrialCustom';

function AreaTextInputForPDF({ type }: { type: PdfType }) {
  const {
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<IExternalPdfLandForm>();
  const [savedValue, setSavedValue] = useState<number | null>(watch('landArea'));
  const { loadProspecDataConfirm } = useAppSelector(getModalsState);
  const { geolocDatas } = useAppSelector(getMapState);
  const { prospecDatas } = useRightPanelPDF();

  const dispatch = useAppDispatch();
  const watchPropertyType = watch('propertyType');
  const displayLandArea = watchPropertyType !== 'apartment' || type === 'ORPI_LAND';

  // save area form value to state
  const setNewValue = () => {
    const v = watch('landArea');
    setSavedValue(v);
  };

  const cheackNeedReload = () => {
    // reload if
    // - prospec data exists and area is changing
    // - old and new landTypes are differents
    if (prospecDatas.landType) {
      const v = watch('landArea');
      const newType = getLandAreaTypeFromNumber(v);
      return prospecDatas.landType?.type !== newType?.type;
    } else {
      return false;
    }
  };

  const reloadStats = () => {
    //reset fields
    setValue('minSellPrice', 0);
    setValue('maxSellPrice', 0);
    setValue('avgSellPrice', 0);
    //fetch datas
    dispatch(
      fetchProspecAdsStatsThunk({
        area: watch('landArea'),
        cityId: prospecDatas.prospecCityId.result,
      })
    );
  };
  const reloadSimilaries = () => {
    //reset fields
    setValue('cheaperAds', []);
    setValue('equalAds', []);
    setValue('higherAds', []);
    //fetch datas
    dispatch(
      fetchProspecSimilariesThunk({
        area: watch('landArea'),
        price: watch('estimatedPrice') ?? 0,
        prospecCityId: prospecDatas.prospecCityId.result,
      })
    );
  };

  useEffect(() => {
    switch (loadProspecDataConfirm.result) {
      case 'LOAD':
        //reset old form data and load prospecDatas
        // checkNeedReload is true only if groundType is different between last and new value
        if (savedValue && geolocDatas && cheackNeedReload()) {
          reloadStats();
          reloadSimilaries();

          // close modal
          dispatch(
            modalsActions.loadProspecDataConfirm({ display: false, result: null })
          );
        }
        setNewValue();
        break;
      case 'CANCEL':
        // set form value with saved state
        if (savedValue) {
          setValue('landArea', savedValue);
        }
        break;
      default:
        break;
    }
  }, [loadProspecDataConfirm.result]);

  const handleBlur = () => {
    const area = getValues().landArea;

    // ask for confirm only if prospec datas allready exists and savedArea !== new area
    // reload only if type change (useEffect with loadProspecDataConfirm.result dependency)
    // but modal open all time area change
    if (prospecDatas.adsStats.result && area !== savedValue) {
      dispatch(
        modalsActions.loadProspecDataConfirm({ display: true, result: null })
      );
    } else {
      setNewValue();
    }
  };

  return (
    <>
      {displayLandArea && (
        <div className={styles.rowIcon}>
          <img src={AreaFieldIcon} alt="area field icon" />
          <div className={styles.rowInput}>
            <p>Surface du terrain</p>
            <NumberInputFormPdfErrialCustom
              name="landArea"
              error={Boolean(errors.landArea)}
              errorMsg={errors.landArea?.message as string}
              onBlur={handleBlur}
              hasValueZeroNotNull
            />
          </div>
        </div>
      )}
    </>
  );
}

export default AreaTextInputForPDF;
