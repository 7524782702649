import { isEmpty } from 'lodash';
import { useLocation } from 'react-router';
import GenericTooltip from '../../../shared/components/tooltips/GenericTooltip';
import './statusElement.scss';

interface IPlotStateBadgeProps {
  status: nullable<StudyStatus>;
  limitLength?: boolean;
}
const PlotStateBadge = ({ status, limitLength = false }: IPlotStateBadgeProps) => {
  const path = useLocation();

  const maxLengthLimit = 9;
  const pathAllowed = path.pathname === '/';

  function limitCharacters(inputString: string) {
    if (inputString.length <= maxLengthLimit) {
      return inputString;
    } else {
      return `${inputString.substring(0, maxLengthLimit)}...`;
    }
  }

  if (isEmpty(status) || status?.id === 0) return null;

  return (
    <GenericTooltip
      text={limitLength && pathAllowed ? status?.label : ''}
      disableHoverListener={
        limitLength &&
        pathAllowed &&
        Boolean((status?.label as string).length <= maxLengthLimit)
      }
    >
      <div className={`plot-state-badge ${status?.class}`}>
        {limitLength && pathAllowed
          ? limitCharacters(status?.label as string)
          : status?.label}
      </div>
    </GenericTooltip>
  );
};

export default PlotStateBadge;
