import { FormControl, TextareaAutosize } from '@mui/material';
import { ChangeEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';

interface ITextAreaFormCustomProps {
  name: string;
  className?: string;
  onFocus?: () => void;
  rowsMax?: number;
  rowsMin?: number;
  placeholder?: string;
}

function TextAreaInputFormPdfErrial({
  name,
  onFocus,
  className,
  rowsMax = 8,
  rowsMin = 8,
  placeholder = 'Message ...',
}: ITextAreaFormCustomProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const errorMessage = errors[name]?.message as string | undefined;

  const handleFocus = () => {
    if (onFocus) {
      onFocus();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>, onChange: any) => {
    const value = e.target.value;

    onChange(value);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControl
          variant="outlined"
          className={errorMessage ? 'error-control' : ''}
        >
          <GenericTooltip text={errorMessage ?? ''} bgColor="ERROR">
            <TextareaAutosize
              value={value}
              onChange={(e) => handleChange(e, onChange)}
              placeholder={placeholder}
              maxRows={rowsMax}
              minRows={rowsMin}
              className={className}
              onFocus={handleFocus}
            />
          </GenericTooltip>
        </FormControl>
      )}
    />
  );
}

export default TextAreaInputFormPdfErrial;
