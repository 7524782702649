import IconContainer from './IconContainer';

function GraphIcon(props: ISharedIconsProps) {
  const { tooltipText } = props;

  return (
    <IconContainer {...props} tooltipText={tooltipText}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0  24 24">
        <path d="M4 9h4v11H4zm12 4h4v7h-4zm-6-9h4v16h-4z" />
      </svg>
    </IconContainer>
  );
}

export default GraphIcon;
