import { useAppDispatch, useAppSelector } from '../../App/store';
import { modalsActions } from '../../features/modals/modalsSlice';
import { getStudyState, studyActions } from '../../features/study/slices/studySlice';
import {
  RecursiveSubFolderStatusEnum,
  RecursiveSubFolderUpdatedElement,
} from '../../features/study/utils/studyEnums';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from './GenericModal';

function RecursiveFolderStatusModal() {
  const {
    updateFolder: { updatedElement },
  } = useAppSelector(getStudyState);
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    dispatch(modalsActions.recusiveAssignForSubfolder(false));
  };

  const handleOk = () => {
    if (updatedElement === RecursiveSubFolderUpdatedElement.RESPONSABLE) {
      dispatch(
        studyActions.setRecursiveSubFolderUpdateStatus(
          RecursiveSubFolderStatusEnum.RECURSIVE_RESPONSABLE
        )
      );
    } else if (updatedElement === RecursiveSubFolderUpdatedElement.STATUS) {
      dispatch(
        studyActions.setRecursiveSubFolderUpdateStatus(
          RecursiveSubFolderStatusEnum.RECURSIVE_STATUS
        )
      );
    } else if (updatedElement === RecursiveSubFolderUpdatedElement.PRIORITY) {
      dispatch(
        studyActions.setRecursiveSubFolderUpdateStatus(
          RecursiveSubFolderStatusEnum.RECURSIVE_PRIORITY
        )
      );
    } else {
      dispatch(
        studyActions.setRecursiveSubFolderUpdateStatus(
          RecursiveSubFolderStatusEnum.NULL
        )
      );
    }

    handleCloseModal();
  };

  const handleCancel = () => {
    dispatch(
      studyActions.setRecursiveSubFolderUpdateStatus(
        RecursiveSubFolderStatusEnum.FOLDER_ONLY
      )
    );
    handleCloseModal();
  };

  return (
    <GenericModal
      modalType={GenericModalEnum.INFO}
      actionType={GenericModalActionEnum.OKCANCEL}
      okButtonContent="OUI"
      cancelButtonContent="NON"
      title="Parcelles du dossier"
      okCallback={handleOk}
      cancelCallback={handleCancel}
      closeModal={handleCloseModal}
    >
      <p>
        Voulez vous assigner
        {updatedElement === RecursiveSubFolderUpdatedElement.STATUS &&
          ' le même statut '}
        {updatedElement === RecursiveSubFolderUpdatedElement.RESPONSABLE &&
          ' le même responsable '}
        {updatedElement === RecursiveSubFolderUpdatedElement.PRIORITY &&
          ' la même priorité '}
        à toutes les parcelles contenues dans ce sous dossier?
      </p>
    </GenericModal>
  );
}

export default RecursiveFolderStatusModal;
