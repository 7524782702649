import { useAppDispatch, useAppSelector } from '../../../../App/store';
import {
  getMapPluginsUrbaToolbarState,
  mapPluginsActions,
} from '../../../map/leaflet/plugins/mapPluginSlice';
import CreateToolbarBtn from '../../../map/leaflet/plugins/toolbars/urbaToolbar/CreateToolbarBtn';
import ToolbarCancelBtn from '../../../map/leaflet/plugins/toolbars/urbaToolbar/ToolbarCancelBtn';
import ToolbarTooltip from '../../../map/leaflet/plugins/toolbars/urbaToolbar/ToolbarTooltip';
import { ToolbarTypeEnum } from '../../../map/leaflet/plugins/toolbars/urbaToolbar/types/ToolbarBtnEnums';
import {
  getMultiPlotSelectState,
  multiPlotSelectActions,
} from '../multiPlotSelectSlice';
import { MultiselectToolbarActionEnum } from '../types/multiselectToolbarEnums';
import AddPlotsToolbarBtn from './buttons/AddPlotsToolbarBtn';
import styles from './multiPlotSelect.module.scss';

function MultiSelectToolbar() {
  const { isMultiPlotSelector } = useAppSelector(getMultiPlotSelectState);
  const { activeToolbar } = useAppSelector(getMapPluginsUrbaToolbarState);
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (activeToolbar === null) {
      dispatch(mapPluginsActions.activeToolbarSet(ToolbarTypeEnum.MULTISELECT));
      dispatch(multiPlotSelectActions.setIsMultiPlotSelector(true));
    }
    if (activeToolbar === ToolbarTypeEnum.MULTISELECT) {
      dispatch(multiPlotSelectActions.setIsMultiPlotSelector(false));
      dispatch(mapPluginsActions.resetToolbar());
    }
  };

  return (
    <div className={styles.multiSelectToolbar}>
      {isMultiPlotSelector ? (
        <ToolbarCancelBtn
          type={MultiselectToolbarActionEnum.MULTISELECT_STOP}
          onClick={handleClick}
          tooltip={<ToolbarTooltip text="Multi-sélection parcelles" />}
        />
      ) : (
        <CreateToolbarBtn
          icon={MultiselectToolbarActionEnum.MULTISELECT_START}
          onClick={handleClick}
          isMultiplotStart
          tooltip={<ToolbarTooltip text="Multi-sélection parcelles" />}
        />
      )}
      <AddPlotsToolbarBtn />
    </div>
  );
}

export default MultiSelectToolbar;
