import { postEntity } from '../../../services/axiosFiles/genericCrud';

interface IPostCompanyLogoProps {
  file: File;
  companyIdIri: string | null;
}

async function postCompanyLogo({ file, companyIdIri }: IPostCompanyLogoProps) {
  try {
    if (!file) throw new Error('no file to upload found');
    if (!companyIdIri) throw new Error('no companiIdIri found');

    const formData = new FormData();
    formData.append('file', file);

    const response = postEntity({
      endpoint: `${companyIdIri}/upload_logo`,
      body: formData,
      params: { header: { 'Content-Type': 'multipart/form-data' } },
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default postCompanyLogo;
