import L from 'leaflet';
import ReactDOMServer from 'react-dom/server';
import styles from '../../../features/map/styles/mapStyles.module.scss';
import SVGIconComponent from './SVGIconComponent';

const MarkerIcon = (indexId: number, color: string) => {
  return L.divIcon({
    className: styles.markerIconWithNumber,
    html: ReactDOMServer.renderToString(
      <div>{SVGIconComponent(indexId, color)}</div>
    ),
  });
};

export default MarkerIcon;
