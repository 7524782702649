import { nanoid } from '@reduxjs/toolkit';
import styles from './georisqueLegend.module.scss';
import SectionCheckboxRow from './SectionCheckboxRow';
interface ISectionCheckboxContainerProps {
  checkboxs: GeorisqueLegend;
}

function SectionCheckboxContainer({ checkboxs }: ISectionCheckboxContainerProps) {
  return (
    <div className={styles.sectionCheckboxContainer}>
      {checkboxs.layers.map((c) => (
        <SectionCheckboxRow key={nanoid()} checkbox={c} title={checkboxs.title} />
      ))}
    </div>
  );
}

export default SectionCheckboxContainer;
