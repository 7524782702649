import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { checkForNullValue } from '../../utils';
import { fontStyles } from '../styles/pdfStyles';
import InformationLine from './InformationLine';

interface IProps {
  formDatas: IExternalPdfLandForm;
}
function GeneralInformations({ formDatas }: IProps) {
  return (
    <View>
      <View style={styles.title}>
        <Text style={[fontStyles.b700, fontStyles.fs20, fontStyles.cOrpi]}>
          Informations générales
        </Text>
      </View>
      <InformationLine label="Drainage" value={formDatas.drainage} />
      <InformationLine label="Clôturé" value={formDatas.fence} />
      <InformationLine label="Accès voirie" value={formDatas.roadAccess} />
      <InformationLine
        label="Accès gaz/électricité"
        value={formDatas.gasAndElectricityAccess}
      />
      <InformationLine label="Accès eau courante" value={formDatas.waterAccess} />
      <InformationLine label="Accès égouts" value={formDatas.sewerAccess} />
      <InformationLine
        label="Taxe foncière"
        value={checkForNullValue(formDatas.propertyTax)}
      />
      <InformationLine
        label="Charges annuelles"
        value={checkForNullValue(formDatas.annualCharges)}
      />
    </View>
  );
}

export default GeneralInformations;

const styles = StyleSheet.create({ title: { marginBottom: '20px' } });
