import { Chart } from 'chart.js';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

const colorPrimary = '#E30513';

function BarGraph({
  data,
  handleGraph,
}: {
  title: string;
  data: any;
  handleGraph: (...args: any[]) => any;
}) {
  const dispatch = useDispatch();
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = (chartRef as any)?.current.getContext('2d');

    const chartInstance = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: ['A la vente', 'Vendus'],
        datasets: [
          {
            data: data,
            barThickness: 30,
            backgroundColor: [colorPrimary],
          },
        ],
      },
      options: {
        scales: {
          x: {
            grid: {
              display: true,
              // drawBorder: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              padding: 10,
              font: { size: 18 },
            },
          },

          y: {
            grid: {
              display: true,
              // drawBorder: true,
              drawOnChartArea: false,
              drawTicks: false,
            },
            ticks: {
              padding: 10,
              font: { size: 16 },
              maxTicksLimit: 5,
              callback: function (value) {
                return `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} €`;
              },
            },
          },
        },
        animation: false,
        plugins: {
          legend: {
            display: false,
          },
        },
      },
    });

    // Convert the chart to a base64 image
    dispatch(handleGraph(chartInstance.toBase64Image()));
  }, []);

  return (
    <div style={{ position: 'absolute', top: -1000 }}>
      <canvas id="myChart" width={514} height={260} ref={chartRef} />
    </div>
  );
}

export default BarGraph;
