import { usePDF } from '@react-pdf/renderer';
import { delay, isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import genericObjectSort from '../../../utils/genericObjectSort';
import { loadersActions } from '../../loaders/loaderSlice';
import { getMapState } from '../../map/mapSlice';
import { externalPdfErrialActions } from '../externalPdfSlice';
import landPdfRequestLauncherThunk from '../services/thunks/landPDF/landPdfRequestLauncherThunk';
import OrpiButton from '../shared/components/OrpiButton';

import useRightPanelPDF from '../shared/hooks/useRightPanelPdf';
import { SocioEconomicModeEnum } from '../shared/types/enums';
import OrpiLandPdfContainer from './OrpiLandPdfContainer';
import orpiLandPdfBuilder from './orpiLandPdfBuilder';
import BarGraph from './pdfFiles/components/BarGraph';
import LineChart from './pdfFiles/components/LineChart';
import useProspecDatas from './useProspecDatas';
/* 
steps
  - launch all requests
  - agency datas loaded
  - deal datas loaded
  - accessibility x 4
  - transport x 6
  - buildPermits
  - servitudes loaded
  - plot datas loaded
  - parse datas
  - generate pdf
*/

const STEP_DELAY = 300;

interface IProps {
  disabled: boolean;
}

const OrpiLandButton = ({ disabled }: IProps) => {
  // ***** HOOKS *****
  const {
    // store
    parcelle,
    isMultiPlotSelectorPdf,
    externalPdfErrialFormData,
    multiPlotsPdf,
    datasForPdfGeneration,
    servitudes,
    forceGenerate,
    noPHDatas,
    pricehubbleFolderDatas,
    pricehubbleDatas,
    unemploymentRateChart,
    activePopulationChart,
    avgPriceChart,
    avgPriceAreaChart,
    //state and functions
    adjacentPlotVerification,
    performStep1,
    click,
    setClick,
    disabledBtn,
  } = useRightPanelPDF();
  const { comparableAds, adsStats, landType } = useProspecDatas();

  // ***** LOCAL STATES *****
  const link = useRef<HTMLAnchorElement>(null);
  const [datas, setDatas] = useState<PDFLandDataType | null>(null);

  // **** redux selectors and dispatch ****
  const { plotDatas, geolocDatas } = useAppSelector(getMapState);
  const [instance, updateInstance] = usePDF({
    document: <OrpiLandPdfContainer datas={datas ?? null} />,
  });

  const dispatch = useAppDispatch();
  // **** constants ****
  const plotsPdfs = multiPlotsPdf;
  const unemployment = pricehubbleDatas.result?.socioEconomic.find(
    (elt) => elt?.subcategory === SocioEconomicModeEnum['CHOMAGE']
  );
  const population = pricehubbleDatas.result?.socioEconomic.find(
    (elt) => elt?.subcategory === SocioEconomicModeEnum['POPULATION']
  );
  const orpiFileName = `synthese-terrain-${geolocDatas?.city.replaceAll(' ', '-') ?? 'ville'}-Orpi`;

  // launch all requests
  const performStep2 = async () => {
    if (parcelle && geolocDatas?.department && geolocDatas?.inseeCode) {
      if (!isMultiPlotSelectorPdf) {
        if (plotDatas?.parcelleLayer) {
          dispatch(
            landPdfRequestLauncherThunk({
              geo: plotDatas.parcelleLayer.parcelle,
              lat: parcelle.lat,
              lng: parcelle.lng,
              plots: [parcelle.parcelleId],
              department: geolocDatas?.department,
              insee: geolocDatas?.inseeCode,
            })
          );
        }
      } else {
        if (plotsPdfs && !isEmpty(plotsPdfs)) {
          const plot = genericObjectSort(
            plotsPdfs,
            'theoricCapacity',
            'desc'
          )[0] as any;

          dispatch(
            landPdfRequestLauncherThunk({
              geo: plot.plotGeometry,
              lat: plot.lat,
              lng: plot.lng,
              plots: plotsPdfs.map((item) => item.fullPlotId),
              department: geolocDatas?.department,
              insee: geolocDatas?.inseeCode,
            })
          );
        }
      }
    }
  };

  const launchPdfDataBuilder = () => {
    dispatch(loadersActions.currentStepSet('Traitement des données'));
    orpiLandPdfBuilder({
      plot: parcelle!,
      pythonDatas: datasForPdfGeneration.result!,
      plotsPdfs,
      servitudes: servitudes.result!,
      formDatas: externalPdfErrialFormData as IExternalPdfLandForm,
      phFolder: pricehubbleFolderDatas,
      phDatas: pricehubbleDatas.result,
      unemploymentRateChart,
      activePopulationChart,
      prospecComparableAds: comparableAds.result,
      prospecAdsStats: adsStats.result,
      prospecLandType: landType,
      avgPriceChart,
      avgPriceAreaChart,
    }).then(
      (res) => {
        setDatas(res);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  // forceGenerate effect if no agacent plot => launch performStep1
  useEffect(() => {
    if (forceGenerate) {
      dispatch(externalPdfErrialActions.resetInfoModalDisplay());
      // start process
      performStep1(performStep2);
    }
  }, [forceGenerate]);
  // all data are fetched effect => launch performStep3
  useEffect(() => {
    // wait for request datas launched on step 2
    delay(() => {
      if (
        parcelle &&
        servitudes.result &&
        datasForPdfGeneration.result &&
        ((!isMultiPlotSelectorPdf && plotDatas?.parcelleLayer?.parcelle) ||
          (isMultiPlotSelectorPdf && multiPlotsPdf))
      ) {
        // if base data is useLoaderData, we check for PH data
        if (noPHDatas) {
          // if no PH datas, create minimal PDF
          launchPdfDataBuilder();
        } else if (
          //if PH data, check all part of them
          pricehubbleDatas.result &&
          unemploymentRateChart &&
          activePopulationChart &&
          pricehubbleFolderDatas
        ) {
          // parse datas
          launchPdfDataBuilder();
        }
      }
    }, STEP_DELAY);

    if (datasForPdfGeneration.apiStatus === APIStatus.REJECTED) {
      setClick(false);
    }
  }, [
    servitudes.result,
    plotDatas?.parcelleLayer?.parcelle,
    multiPlotsPdf,
    datasForPdfGeneration.result,
    pricehubbleDatas.result,
    unemploymentRateChart,
    activePopulationChart,
    comparableAds.result,
    adsStats.result,
    pricehubbleFolderDatas,
  ]);
  // update PDF instance effect
  useEffect(() => {
    // if datas and clisk are ok, launch react-pdf updateInstance
    if (datas && click) {
      // generate PDF
      delay(() => {
        dispatch(loadersActions.currentStepSet('Génération du PDF'));
        updateInstance(<OrpiLandPdfContainer datas={datas ?? null} />);
      }, STEP_DELAY);
    }
  }, [datas]);
  // generate final PDF and reset params effect
  useEffect(() => {
    // if updateInstance ended, launch real click for edit pdf
    // need delay with 1ms for nex js process step
    if (!instance.loading && click && datas) {
      delay(() => {
        link.current?.click();
        setClick(false);
        dispatch(loadersActions.currentStepReset());
        dispatch(externalPdfErrialActions.pdfGenerationReset());
      }, 1);
    }
  }, [instance.loading]);

  return (
    <>
      <OrpiButton
        click={click}
        disabled={disabled || disabledBtn}
        onClick={() => adjacentPlotVerification(performStep2)}
      />
      {unemployment && (
        <LineChart
          data={
            (
              unemployment?.items as {
                labels: string[];
                data: number[];
              }
            ).data
          }
          labels={
            (
              unemployment?.items as {
                labels: string[];
                data: number[];
              }
            ).labels
          }
          handleGraph={externalPdfErrialActions.setUnemploymentRateChart}
          valueIsPercentage
        />
      )}
      {population && (
        <LineChart
          data={
            (
              population?.items as {
                labels: string[];
                data: number[];
              }
            ).data
          }
          labels={
            (
              population?.items as {
                labels: string[];
                data: number[];
              }
            ).labels
          }
          handleGraph={externalPdfErrialActions.setActivePopulationChart}
        />
      )}
      {adsStats.result && (
        <>
          <BarGraph
            title="Prix moyen"
            data={[
              adsStats.result?.sellAveragePrice,
              adsStats.result?.soldAveragePrice,
            ]}
            handleGraph={externalPdfErrialActions.setAvgPriceChart}
          />
          <BarGraph
            title="Prix moyen / m²"
            data={[
              adsStats.result?.sellAveragePriceArea,
              adsStats.result?.soldAveragePriceArea,
            ]}
            handleGraph={externalPdfErrialActions.setAvgPriceAreaChart}
          />
        </>
      )}

      <a
        ref={link}
        hidden={true}
        href={instance.url ?? undefined}
        download={`${orpiFileName}.pdf`}
      >
        link
      </a>
    </>
  );
};

export default OrpiLandButton;
