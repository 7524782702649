export function stringCut(value: string, maxLength: number) {
  if (!value) return '';
  return value.length > maxLength
    ? value.substring(0, maxLength).concat(' ...')
    : value;
}

export function snakeCamel(value: string) {
  return value.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
}
export function snakeToPascal(value: string): string {
  const v = value
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');

  return v;
}
