import { LatLngExpression } from 'leaflet';
import { Marker, Tooltip } from 'react-leaflet';
import bleu from '../../../assets/images/map-marker-bleu_1.png';
import bleuFonce from '../../../assets/images/map-marker-bleu_fonce_1.png';
import bleuGris from '../../../assets/images/map-marker-bleu_gris_1.png';
import cyan from '../../../assets/images/map-marker-cyan_1.png';
import jaune from '../../../assets/images/map-marker-jaune_1.png';
import orange from '../../../assets/images/map-marker-orange_1.png';
import rose from '../../../assets/images/map-marker-rose_1.png';
import vert from '../../../assets/images/map-marker-vert_1.png';
import violet from '../../../assets/images/map-marker-violet_1.png';
import rouge from '../../../assets/images/pin-urbanease.svg';
import { customIcon } from '../../../shared/components/map/customIcon';

interface IMarkerBaseProps {
  onClick?: () => void;
  markerLatLng: LatLngExpression;
  markerUrl:
    | 'bleu'
    | 'bleuFonce'
    | 'bleuGris'
    | 'cyan'
    | 'jaune'
    | 'orange'
    | 'rose'
    | 'vert'
    | 'violet'
    | 'rouge';
  zIndexOffset?: number;
  children?: React.ReactNode;
  hasTooltip?: boolean;
  tooltipMessage?: React.ReactNode;
  className?: string;
}

export default function MarkerBase({
  onClick,
  markerLatLng,
  markerUrl,
  zIndexOffset,
  children,
  hasTooltip,
  tooltipMessage,
  className = '',
}: IMarkerBaseProps) {
  const handleClick = (): void => {
    if (onClick) {
      onClick();
    }
  };

  const getIconUrl = (): string => {
    switch (markerUrl) {
      case 'bleu':
        return bleu;
      case 'bleuFonce':
        return bleuFonce;
      case 'bleuGris':
        return bleuGris;
      case 'cyan':
        return cyan;
      case 'jaune':
        return jaune;
      case 'orange':
        return orange;
      case 'rose':
        return rose;
      case 'vert':
        return vert;
      case 'violet':
        return violet;
      case 'rouge':
      default:
        return rouge;
    }
  };
  return (
    <>
      <Marker
        zIndexOffset={zIndexOffset ? zIndexOffset : 700}
        eventHandlers={{
          click: () => {
            handleClick();
          },
        }}
        position={markerLatLng}
        icon={customIcon(getIconUrl())}
      >
        {hasTooltip && (
          <Tooltip direction="top" sticky className={className}>
            {tooltipMessage}
          </Tooltip>
        )}
        {children}
      </Marker>
    </>
  );
}
