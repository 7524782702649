/**
 * Parse api contact to store contact
 * @param {Contact[]} contacts Api contact array
 * @returns {any} parsed contacts
 */
export const contactsApiToStoreParser = (datas: any): Contacts => {
  return datas.map((data: any) => contactApiToStoreParser(data));
};
/**
 * Parse api contact to store contact
 * @param {any} data Api contact
 * @returns {Contact} parsed contact
 */
export const contactApiToStoreParser = (data: any): Contact => {
  const address = {
    addressFloor: data.addressPart1,
    addressBuilding: data.addressPart2,
    addressStreet: data.addressPart3 ?? data.address,
    addressExtra: data.addressPart4,
    postalCode: data.zipCode,
    city: data.city,
    country: data.country ?? null,
  };

  const parsedData: Contact = {
    sortValues: { lastName: data.lastName },
    idIri: data['@id'],
    id: parseInt(data['@id'].split('/')[2], 10),
    firstName: data.firstName ?? null,
    lastName: data.lastName ?? null,
    name: null,
    address: address,
    phone: data.phone ?? null,
    email: data.email ?? null,
    addressOld: data.address ?? null,
    description: data.description ?? null,
    allowedToBeContacted: data.allowedToBeContacted,
  };

  if (!parsedData.firstName && !parsedData.lastName && data.name) {
    parsedData.lastName = data.name;
  } else {
    parsedData.name = `${parsedData.firstName} ${parsedData.lastName}`;
  }

  return parsedData;
};

/**
 * Parse store contact to api contact before reuest
 * @param {ContactFormType} contact Store contact
 * @returns {any} parsed contact
 */
export const contactStoreToApiParser = (contact: Partial<ContactFormType>) => {
  const {
    firstName,
    lastName,
    addressBuilding,
    addressExtra,
    addressFloor,
    addressStreet,
    city,
    postalCode,
    country,
    email,
    phone,
    description,
    allowedToBeContacted,
  } = contact;

  const parsedData = {
    firstName,
    lastName,
    addressPart1: addressFloor,
    addressPart2: addressBuilding,
    addressPart3: addressStreet,
    addressPart4: addressExtra,
    zipCode: postalCode,
    city,
    country,
    email,
    phone,
    description,
    allowedToBeContacted,
  };

  return parsedData;
};
