import { nanoid } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../App/store';
import NoCity from '../../../components/noRights/NoCity';
import { getMapState } from '../../../features/map/mapSlice';
import { georisqueLegend } from '../constant';
import LegendSection from './LegendSection';
import styles from './georisqueLegend.module.scss';
function GeorisqueLegend() {
  const { geolocDatas } = useAppSelector(getMapState);

  return (
    <div className={styles.georisqueLegend}>
      {geolocDatas?.inseeCode ? (
        georisqueLegend.map((l) => <LegendSection key={nanoid()} section={l} />)
      ) : (
        <NoCity />
      )}
    </div>
  );
}

export default GeorisqueLegend;
