import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { nanoid } from '@reduxjs/toolkit';
import noiseGraph from '../../../../assets/images/orpiPdf/land-pdf-noise-graph.png';
import { pageStyles } from '../../shared/styles/pdfStyle';
import Footer from './components/Footer';
import Header from './components/Header';
import ImageContainer from './components/ImageContainer';
import NoiseSourceEntity from './components/NoiseSourceEntity';
import PdfSeparateLine from './components/PdfSeparateLine';
import { fontStyles } from './styles/pdfStyles';

const dBToPixel = (dBValue: number) => {
  // Make sure the dB value is within the specified range
  if (dBValue < 10) {
    return 0;
  } else if (dBValue > 110) {
    return 550;
  }

  // Set minimum and maximum values for conversion
  const dBMin = 10;
  const dBMax = 110;
  const pixelMin = 0;
  const pixelMax = 552;

  // Convert dB value to pixels using rule of three
  const pixelValue =
    ((dBValue - dBMin) / (dBMax - dBMin)) * (pixelMax - pixelMin) + pixelMin;

  return pixelValue;
};

interface IProps {
  noise: PDFLandNoise | null;
  agencyDatas: PHAgencyAndAgentDatas | null;
  formDatas: IExternalPdfLandForm;
}

function NoisePage({ noise, agencyDatas, formDatas }: IProps) {
  return (
    <Page size="A4" style={pageStyles.page}>
      <Header lines={['Votre bien', '... et son environnement.']} />

      <Text style={[fontStyles.b700, fontStyles.fs20, fontStyles.cOrpi]}>Bruit</Text>

      <View style={styles.streetNoiseContainer}>
        <View style={styles.row}>
          <View>
            <Text style={styles.streetNoiseLeftText}>Quartier calme</Text>
            <View style={styles.row}>
              <Text style={styles.streetNoiseLeftSubText}>
                A cette adresse, le niveau de bruit est estimé à:{' '}
              </Text>
              <Text style={styles.streetNoiseLeftSubTextValue}>
                {noise?.level} dB
              </Text>
            </View>
          </View>
        </View>

        {/* Column map  for distances */}
        <View>
          {noise?.sources.map((m) => (
            <NoiseSourceEntity key={nanoid()} isColumn source={m} />
          ))}
        </View>
      </View>

      <ImageContainer
        localImg={noise?.picture ? `data:image/jpeg;base64,${noise.picture}` : ''}
        width="100%"
        height="215px"
        borderRadius="15px"
      />

      <View style={styles.bloc}>
        <Text
          style={[
            fontStyles.b700,
            fontStyles.fs18,
            fontStyles.cOrpi,
            { marginTop: '10px' },
          ]}
        >
          Niveaux de bruit
        </Text>
        <Image src={noiseGraph} style={styles.graph} />
        <View
          style={{
            ...styles.graphPoint,
            left: dBToPixel(noise?.level ?? 0),
          }}
        />
      </View>

      <PdfSeparateLine marginBottom="10px" marginTop="0" />

      <View style={styles.bloc}>
        <Text
          style={[
            fontStyles.b700,
            fontStyles.fs18,
            fontStyles.cOrpi,
            { marginVertical: '10px' },
          ]}
        >
          Sources de bruit les plus proches
        </Text>

        {/* Row map for distances */}
        <View style={styles.closestNoiseContainer}>
          {noise?.sources.map((m) => (
            <NoiseSourceEntity source={m} key={nanoid()} />
          ))}
        </View>
      </View>
      <Footer agency={agencyDatas?.agency ?? null} formDatas={formDatas} />
    </Page>
  );
}

export default NoisePage;

const styles = StyleSheet.create({
  title: { fontSize: '18px', fontWeight: 700, marginBottom: '10px' },
  map: { width: '100%', height: '200px', marginBottom: '15px' },
  graph: {
    width: '550px',
    height: '180px',
    paddingBottom: '15px',
    marginLeft: '2px',
    objectFit: 'contain',
  },
  graphPoint: {
    width: '20px',
    height: '20px',
    backgroundColor: '#fff',
    borderColor: '#39454f',
    border: '4px',
    borderRadius: '12px',
    position: 'absolute',
    top: '69px',
  },
  bloc: {
    marginBottom: '10px',
  },
  streetNoiseContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
    padding: '10px',
    backgroundColor: '#f7f7f7',
    justifyContent: 'space-between',
  },
  streetNoiseLeftText: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 1.5,
  },
  streetNoiseLeftSubText: {
    fontSize: '10px',
  },
  streetNoiseLeftSubTextValue: {
    fontSize: '10px',
    fontWeight: 700,
  },

  streetNoiseRightValue: {
    fontSize: '10px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  closestNoiseContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});
