import { G, Path, Svg } from '@react-pdf/renderer';

function LandAreaIconSvg() {
  return (
    <Svg width="25" height="16" viewBox="0 0 25 16">
      <G clip-path="url(#clip0_332_186)">
        <Path
          d="M0.320068 0.497914C0.511889 0.132993 0.811689 -0.000640163 1.21883 2.30418e-06C8.73606 0.00642697 16.2539 0.00449957 23.7718 0.00449957C23.8194 0.00449957 23.8671 0.00385711 23.9141 0.00514204C24.3847 0.0199188 24.6782 0.320593 24.6788 0.800516C24.6807 2.8275 24.6801 4.85513 24.6801 6.88211C24.6801 9.62223 24.6801 12.363 24.6801 15.1031C24.6801 15.7212 24.4095 15.9955 23.7959 15.9955C16.2622 15.9961 8.72907 15.9942 1.19533 16C0.795175 16 0.508078 15.8561 0.320068 15.5021C0.320068 10.5011 0.320068 5.49952 0.320068 0.497914ZM12.5159 1.51687C9.05364 1.51687 5.5907 1.51687 2.12839 1.51687C1.79366 1.51687 1.79048 1.51944 1.79048 1.84453C1.78985 5.94796 1.78985 10.0514 1.79048 14.1548C1.79048 14.4825 1.79303 14.4844 2.12649 14.4844C9.03586 14.4844 15.9446 14.4844 22.854 14.4844C23.1912 14.4844 23.1919 14.4831 23.1919 14.133C23.1919 10.0456 23.1919 5.95824 23.1919 1.87087C23.1919 1.51815 23.1906 1.51751 22.8317 1.51751C19.3936 1.51622 15.9548 1.51687 12.5159 1.51687Z"
          fill="#514E9A"
        />
        <Path
          d="M5.77057 7.24894C10.251 7.24894 14.7315 7.24894 19.2133 7.24894C19.2374 7.15899 19.1656 7.12944 19.1263 7.08896C18.8938 6.84804 18.6518 6.61675 18.4238 6.37197C18.1176 6.04303 18.1208 5.58816 18.4193 5.29327C18.7071 5.0093 19.1625 5.00608 19.4693 5.31061C20.187 6.02375 20.8984 6.74332 21.6034 7.4693C21.9058 7.7809 21.9064 8.21842 21.606 8.52809C20.9009 9.25408 20.1889 9.97364 19.4718 10.6874C19.1644 10.9932 18.7115 10.9926 18.4219 10.7099C18.1214 10.4163 18.1138 9.95758 18.4225 9.63249C18.6899 9.35045 18.9764 9.08639 19.2539 8.81463C19.2438 8.79728 19.2336 8.77994 19.2234 8.76259C14.7398 8.76259 10.2561 8.76259 5.76866 8.76259C5.74706 8.84097 5.81947 8.87052 5.85885 8.91164C6.09133 9.15257 6.33333 9.38386 6.56135 9.62928C6.8675 9.95951 6.86433 10.4118 6.56516 10.708C6.2787 10.992 5.82201 10.9952 5.51523 10.69C4.79749 9.97685 4.0861 9.25665 3.38106 8.5313C3.07999 8.22163 3.07872 7.7809 3.37915 7.47187C4.08419 6.74589 4.79622 6.02632 5.51332 5.31318C5.82074 5.00737 6.27489 5.00801 6.56326 5.2907C6.86306 5.58559 6.86623 6.0366 6.56453 6.37004C6.43051 6.51781 6.28759 6.65658 6.14595 6.796C6.01066 6.92899 5.87092 7.05812 5.73373 7.18855C5.74452 7.2091 5.75723 7.22902 5.77057 7.24894Z"
          fill="#514E9A"
        />
      </G>
    </Svg>
  );
}

export default LandAreaIconSvg;
