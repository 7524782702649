import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { InternalError } from '../../services/axiosFiles/axiosUtils';
import { deleteEntity, fetchDatas } from '../../services/axiosFiles/genericCrud';
import { multiProcedureParser } from './proceduresParser';

export const fetchProceduresThunk = createAsyncThunk(
  'procedures/fetchProceduresThunk',
  async (params: void, { rejectWithValue, getState }) => {
    // const { userIdIri, companyId } = params;
    const state = getState() as RootState;
    try {
      const { procedureTypes, plotStudyStatuses, contactRoles } = state.app;
      const { companyId } = state.company;
      const { userIdIri } = state.auth;

      if (
        userIdIri &&
        companyId &&
        procedureTypes.result &&
        plotStudyStatuses.result &&
        contactRoles.result
      ) {
        try {
          const endpoint = userIdIri + '/upcoming_procedures';
          const procedures = await fetchDatas(endpoint, { company_id: companyId });

          const parsedProcedures = multiProcedureParser(
            procedures['hydra:member'],
            procedureTypes.result,
            plotStudyStatuses.result,
            contactRoles.result
          );

          return parsedProcedures;
        } catch (error) {
          return Promise.reject(error);
        }
      } else {
        return rejectWithValue({
          status: 0,
          message: 'userIdIri or companyId params is missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

export const deleteProcedureThunk = createAsyncThunk(
  'procedures/deleteProcedureThunk',
  async (params: void, { rejectWithValue, getState, dispatch }) => {
    const { procedureForAction } = (getState() as RootState).procedures;
    const { userIdIri } = (getState() as RootState).auth;
    const { companyId } = (getState() as RootState).company;
    try {
      if (procedureForAction?.idIri) {
        const promise = deleteEntity(procedureForAction.idIri);

        return promise
          .then(
            () => {
              dispatch(fetchProceduresThunk());
            },
            (err) => {
              return rejectWithValue(err);
            }
          )
          .catch((error) => {
            return rejectWithValue(InternalError(error));
          });
      } else {
        return rejectWithValue({
          status: 0,
          message: 'procedureIdIri param is missing',
        });
      }
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);
