import { Path, Svg } from '@react-pdf/renderer';

const TrainSvg = ({
  color = 'currentColor',
  width = '24px',
  height = '24px',
}: {
  color?: string;
  height?: string;
  width?: string;
}) => (
  <Svg
    height={width}
    width={height}
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
  >
    <Path
      d="M6.83749 19.9884C6.70619 19.9884 6.59869 19.9465 6.51499 19.8628C6.43129 19.7791 6.38944 19.6716 6.38944 19.5403C6.38944 19.4718 6.4101 19.3927 6.45141 19.3029C6.49273 19.2132 6.54835 19.1472 6.61826 19.1048L7.77309 18.3182C6.9082 18.3182 6.17676 18.0193 5.57876 17.4215C4.98076 16.8236 4.68176 16.0921 4.68176 15.2269V6.05871C4.68176 4.87213 5.25852 4.01266 6.41204 3.48031C7.56555 2.94795 9.42745 2.68176 11.9977 2.68176C14.6276 2.68176 16.5051 2.94229 17.6303 3.46334C18.7556 3.9844 19.3182 4.85015 19.3182 6.06056V15.2269C19.3182 16.0921 19.0193 16.8236 18.4215 17.4215C17.8236 18.0193 17.0921 18.3182 16.2269 18.3182L17.3868 19.1048C17.4568 19.1483 17.5116 19.2105 17.5512 19.2913C17.5907 19.3721 17.6105 19.4513 17.6105 19.5292C17.6105 19.6609 17.5669 19.7704 17.4795 19.8576C17.3922 19.9448 17.2847 19.9884 17.1571 19.9884H6.83749ZM6.08076 10.5808H17.9192V6.76921H6.08076V10.5808ZM16.2269 11.9798H6.08076H17.9192H16.2269ZM11.9976 15.7269C12.3511 15.7269 12.6511 15.6044 12.8976 15.3596C13.144 15.1147 13.2673 14.8155 13.2673 14.462C13.2673 14.1085 13.1448 13.8085 12.8999 13.562C12.6551 13.3155 12.3559 13.1923 12.0024 13.1923C11.6489 13.1923 11.3489 13.3148 11.1024 13.5596C10.8559 13.8045 10.7327 14.1037 10.7327 14.4572C10.7327 14.8107 10.8551 15.1107 11.1 15.3572C11.3449 15.6037 11.6441 15.7269 11.9976 15.7269ZM7.77309 16.9192H16.2269C16.7051 16.9192 17.1067 16.7567 17.4317 16.4317C17.7567 16.1067 17.9192 15.7051 17.9192 15.2269V11.9798H6.08076V15.2246C6.08076 15.7044 6.24326 16.1067 6.56826 16.4317C6.89326 16.7567 7.29487 16.9192 7.77309 16.9192ZM12 4.08076C10.3359 4.08076 9.04067 4.18621 8.11439 4.39711C7.1881 4.60801 6.58907 4.93237 6.31729 5.37019H17.7442C17.5154 4.93237 16.9332 4.60801 15.9976 4.39711C15.062 4.18621 13.7295 4.08076 12 4.08076ZM12 5.37019H17.7442H6.31729H12Z"
      fill={color}
    />
  </Svg>
);

export default TrainSvg;
