import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { InternalError } from '../../services/axiosFiles/axiosUtils';
import {
  deleteEntity,
  fetchDatas,
  postEntity,
} from '../../services/axiosFiles/genericCrud';
import { decodeText } from '../../utils/jsFunctions';
import { modalsActions } from '../modals/modalsSlice';
import { urbaGptHistoryParser, urbaGptQueryParser } from './urbaGptParser';

export const urbaGptQueryThunk = createAsyncThunk(
  'urbaGpt/urbaGptQueryThunk',
  async (
    {
      question,
    }: {
      question: string;
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const { userId, user } = (getState() as RootState).auth;
      const { currentConversationUuid } = (getState() as RootState).urbaGpt;
      const data: {
        question: string;
        user_id: number | null;
        user_name?: string;
        conversation_uuid?: string;
      } = {
        question: question,
        user_id: userId,
      };
      const maxTokenRegex = /Please reduce the length of the messages./i;

      if (currentConversationUuid) data.conversation_uuid = currentConversationUuid;
      if (user?.firstName) data.user_name = user?.firstName;

      const response: any = await postEntity({
        endpoint: '/ai_urbanease_api/query',
        body: data,
      });

      if (maxTokenRegex.test(response.error)) {
        dispatch(modalsActions.errorTokenUrbaGptModal(true));
      }

      if (response?.error) {
        return rejectWithValue({
          question: decodeText(question.replace(/\n/g, '<br />')),
          error: response?.error,
        });
      }

      const parsedResponse = urbaGptQueryParser(response as UrbaGptQueryResponse);

      return {
        conversationUuid: parsedResponse.conversationUuid,
        datelog: new Date().toLocaleDateString(),
        id: parsedResponse.userId,
        question: decodeText(question.replace(/\n/g, '<br />')),
        response: parsedResponse.gptResponse,
        isAnim: true,
      };
    } catch (error) {
      return rejectWithValue({
        question: decodeText(question.replace(/\n/g, '<br />')),
        error: error,
      });
    }
  }
);

export const urbaGptHistoryThunk = createAsyncThunk(
  'urbaGpt/urbaGptHistoryThunk',
  async (params, { rejectWithValue, getState }) => {
    try {
      const { userId } = (getState() as RootState).auth;

      const response: UrbaGptHistoryResponse[] = await fetchDatas(
        `/ai_urbanease_api/user_history/${userId}`
      );

      const parsedResponse = urbaGptHistoryParser(response);

      return parsedResponse;
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);

export const urbaGptDeleteThunk = createAsyncThunk(
  'urbaGpt/urbaGptDeleteThunk',
  async (params, { rejectWithValue, getState }) => {
    try {
      const { userId } = (getState() as RootState).auth;

      const response = await deleteEntity(
        `/ai_urbanease_api/user_history/${userId}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(InternalError(error));
    }
  }
);
