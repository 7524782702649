import {
  SECTOR_DEFAULT_COLOR,
  SECTOR_OWNER_COLOR,
  SUBSECTOR_DEFAULT_COLOR,
  SUBSECTOR_OWNER_COLOR,
} from '../../../shared/constants';

/**
 * concat a sector or folder with his subs in only one array
 * @param {IFolderSector<T>} e foder or sector
 * @returns {IFolderSector<T>} concatened array
 */
export const getEntityAndSubsUniqueTab = <T>(e: IFolderSector<T>) =>
  e.subs ? [e, ...e.subs] : [e];

export const getIdIriTabFromFolderAndSubs = (f: IFolder) =>
  getEntityAndSubsUniqueTab(f).map((m) => m.idIri);

export const isParentEntitiesDisplayed = (e: EntitiesDisplay, p: string) =>
  e.some((s) => s.parent === p);

export const isOnlyParentEntitiesDisplayed = (e: EntitiesDisplay, p: string) =>
  e.every((s) => s.parent === p);

export const isEntityDisplayed = (e: EntitiesDisplay, id: string) =>
  e.some((s) => s.idIri === id);

export function getParentsAndChildsIdIriTab(e: IFolderSector<IIdIri>[]): string[] {
  return e.map((m) => getParentAndChildIdIriTab(m)).flat(1);
}

export function getParentAndChildIdIriTab(e: IFolderSector<IIdIri>): string[] {
  let tab: string[] = [];
  tab.push(e.idIri);

  if (e.subs && (e.subs?.length ?? 0) > 0) {
    tab = tab.concat(e.subs?.map((sub) => sub.idIri));
  }

  return tab;
}

export const sectorColorDisplay = (s: ISector, userIdIri: string | null) => {
  if (!userIdIri) return SECTOR_DEFAULT_COLOR;

  const isOwner = s.responsable.idIri === userIdIri;
  const isSub = Boolean(s.parent);

  return isOwner && isSub
    ? SUBSECTOR_OWNER_COLOR
    : isOwner
      ? SECTOR_OWNER_COLOR
      : isSub
        ? SUBSECTOR_DEFAULT_COLOR
        : SECTOR_DEFAULT_COLOR;
};
