import { useAppSelector } from '../../../../../../App/store';
import { getPlotState } from '../../../../../../redux/plot/reducer';
import { getCompanyState } from '../../../../../company/companySlice';
import styles from '../toolbars.module.scss';
import CesiumMapBtn from './CesiumMapBtn';
import StreetViewMapBtn from './StreetViewMapBtn';

function RightBottomToolbar() {
  const { parcelle } = useAppSelector(getPlotState);
  const { companyId } = useAppSelector(getCompanyState);

  if (!parcelle) return null;
  return (
    <div className={[styles.rightToolbar, styles.bottom].join(' ')}>
      <CesiumMapBtn />
      <StreetViewMapBtn />
    </div>
  );
}

export default RightBottomToolbar;
