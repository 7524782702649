import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch } from '../../../../../App/store';
import TextFieldFormCustom from '../../../../../components/Common/FormComponents/TextFieldFormCustom';
import FormButtonContainer from '../../../../../shared/components/buttons/formButtonContainer/FormButtonContainer';
import CheckboxFormCustom from '../../../../../shared/components/formComponents/CheckboxFormCustom';
import RadioBtnFormCustom from '../../../../../shared/components/formComponents/RadioBtnFormCustom';
import useMailshotsModels from '../../../hooks/useMailshotsModels';
import { mailshotsActions } from '../../../mailshotsSlice';
import styles from '../../../styles/mailshotsModal.module.scss';
import {
  modelTypesForRadioBtns,
  renameMailshotFormSchema,
} from '../../../utils/renameFormUtils';

function MailshotRenameForm() {
  const { actionModalDatas, updateModel, createModel } = useMailshotsModels({});
  const formOptions = useForm<InitialRenameMailshotDatas>({
    defaultValues: {
      name: actionModalDatas?.model?.name ?? '',
      isActive: actionModalDatas?.model?.isActive ?? true,
      modelType: actionModalDatas?.model?.modelType,
    },
    resolver: yupResolver(renameMailshotFormSchema),
  });
  const {
    handleSubmit,
    formState: { isValid },
  } = formOptions;

  useEffect(() => {
    dispatch(mailshotsActions.setQuillDatasBeforeNavigation(null));
  }, []);

  const dispatch = useAppDispatch();

  const validButtonContent =
    actionModalDatas?.type === 'create'
      ? 'Créer'
      : actionModalDatas?.type === 'update'
        ? 'Modifier'
        : '';

  const formSubmit = (values: InitialRenameMailshotDatas) => {
    if (isValid) {
      if (actionModalDatas?.model) {
        updateModel({ ...values, id: actionModalDatas?.model?.id });
      } else {
        createModel(values);
      }
    }
  };

  const handleCancel = () => {
    dispatch(mailshotsActions.setModalData(null));
  };
  return (
    <FormProvider {...formOptions}>
      {actionModalDatas && (
        <div className={styles.body}>
          <form onSubmit={handleSubmit(formSubmit)}>
            <TextFieldFormCustom name="name" placeholder="Saisir un nom" />

            <RadioBtnFormCustom
              name="modelType"
              label="Type de modèle"
              radioBtnValues={modelTypesForRadioBtns}
              disabled={actionModalDatas?.model?.isDefault}
            />

            <CheckboxFormCustom
              name="isActive"
              label="Actif"
              disabled={actionModalDatas?.model?.isDefault}
            />

            <FormButtonContainer
              canCancel
              validContent={validButtonContent}
              onCancel={handleCancel}
            />
          </form>
        </div>
      )}
    </FormProvider>
  );
}
export default MailshotRenameForm;
