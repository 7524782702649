import { useMemo } from 'react';
import { useLocation } from 'react-router';

function useQuery() {
  const location = useLocation();
  const { search } = location;

  const queries = useMemo(() => {
    const queryObject: { [x: string]: string } = {};

    const splitedSearch = search.substring(1).split('&');

    splitedSearch.forEach((q) => {
      const split = q.split('=');
      queryObject[split[0]] = split[1];
    });

    return queryObject;
  }, [search]);

  return { queries, location };
}

export default useQuery;
