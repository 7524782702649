import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import { appActions } from '../../../../../../App/appSlice';
import useRightPanel from '../../../../../../App/hooks/useRightPanel';
import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import { getPlotState } from '../../../../../../redux/plot/reducer';

interface IOwnerListProps {
  owners: nullable<IOwner[]>;
}
const OwnerList = ({ owners }: IOwnerListProps) => {
  const { parcelle } = useAppSelector(getPlotState);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { closeRightPanel } = useRightPanel();

  const handleSirenclick = (siren: string) => {
    dispatch(appActions.setDashboardTab('dataowner'));
    closeRightPanel();
    navigate(`/dashboard?siren=${siren}`);
  };

  return (
    <div className="owner-list">
      {owners?.map((p) => {
        const {
          legalStatus,
          owner,
          ownerName,
          area,
          siren,
          bodaccDetails,
          bodaccTerminationDate,
          bodaccSituation,
        } = p;

        return (
          <div className="owner" key={uuidv4()}>
            <p>
              Typologie propriétaire : <span>{ownerName}</span>
            </p>
            <p>
              Nom propriétaire :{' '}
              <span>
                {legalStatus} {owner}
              </span>
            </p>
            {siren && (
              <p>
                SIREN :
                <span className="siren" onClick={() => handleSirenclick(siren)}>
                  {siren}
                </span>
              </p>
            )}
            <p>
              Propriétaire d’un ensemble :{' '}
              <span>{parcelle?.type || 'indéfini'}</span>
            </p>
            <p>
              Surface : <span>{area}</span>
            </p>
            {bodaccSituation && (
              <p>
                Difficultés financières : <span>{bodaccSituation}</span>
              </p>
            )}
            {bodaccSituation && (
              <p>
                Date mise en difficulté financière :{' '}
                <span>
                  {bodaccTerminationDate ? bodaccTerminationDate : 'non renseigné'}
                </span>
              </p>
            )}
            {bodaccSituation && (
              <p>
                Commentaire sur la situation financière :{' '}
                <span>{bodaccDetails ? bodaccDetails : 'non renseigné'}</span>
              </p>
            )}
            {siren && !siren.toLowerCase().includes('u') && (
              <a
                href={`https://www.pappers.fr/entreprise/${siren}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                plus d’infos sur la personne morale
              </a>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default OwnerList;
