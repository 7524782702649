import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';
import createContact from '../../../contacts/services/createContact';
import updateContact from '../../../contacts/services/updateContact';
import createStudyContact from '../createStudyContact';

const createStudyContactThunk = createAsyncThunk(
  'study/createStudyContactThunk',
  async (
    params: { body: StudyContactForm; contact: Contact | null },
    thunkOptions
  ) => {
    try {
      const { body, contact } = params;
      const state = thunkOptions.getState() as RootState;
      const contactRoles = state.app.contactRoles.result;
      const { idIri, type } = state.studyParams;
      const { companyIdIri } = state.company;

      if (!body) throw new Error('No body found');

      const baseUrl =
        type === 'folder' ? '/folder_contacts' : '/plot_study_contacts';

      let contactIdIri = contact?.idIri ?? null;
      if (contact) {
        await updateContact(contactIdIri, body);
      } else {
        const created = await createContact(body, companyIdIri);
        contactIdIri = created.idIri;
      }

      const result = await createStudyContact(
        baseUrl,
        contactIdIri,
        body.roleIdIri,
        type,
        idIri,
        contactRoles
      );

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default createStudyContactThunk;
