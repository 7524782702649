import _ from 'lodash';

export const REQUEST = 'REQUEST';
export const SUCCESS = 'SUCCESS';
export const FAILURE = 'FAILURE';
export const SET = 'SET';
export const RESET = 'RESET';
export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';
export const COLLAPSE = 'COLLAPSE';
export const UNCOLLAPSE = 'UNCOLLAPSE';
export const SHOW = 'SHOW';
export const HIDE = 'HIDE';
export const MODAL = 'MODAL';
export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const SET_MESSAGE = 'SET_MESSAGE';

export const createRequestTypes = (base: string): Record<string, string> => {
  const CONST = _.reduce(
    [
      REQUEST,
      SUCCESS,
      FAILURE,
      SET,
      RESET,
      OPEN,
      CLOSE,
      MODAL,
      SHOW,
      HIDE,
      COLLAPSE,
      UNCOLLAPSE,
    ],
    (acc: Record<string, string>, type: string) => {
      acc[type] = `${base}_${type}`;
      return acc;
    },
    {} as Record<string, string>
  );
  CONST.toString = () => base;
  return CONST;
};

export function action<T extends string, P>(type: T, payload?: Partial<P>) {
  return { type, ...payload };
}
