import styles from './noRights.module.scss';

function DeniedAccess() {
  return (
    <p className={styles.deniedAccess}>
      Cette commune se situe <br />
      en dehors de votre abonnement
    </p>
  );
}

export default DeniedAccess;
