import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import { memo, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import NoCity from '../../../components/noRights/NoCity';
import { getMapState } from '../../../features/map/mapSlice';
import { modalsActions } from '../../modals/modalsSlice';
import { fetchZonesAndZoneChildsThunk } from '../../plu/pluThunk';
import {
  advancedPlotSearchActions,
  getAdvancedPlotSearchState,
} from '../advancedPlotSearchSlice';
import AdvancedSearchButtons from './AdvancedSearchButtons';
import BlocContainer from './BlocContainer';
import Zonage from './Zonage';
import styles from './advancedPlotSearchContainer.module.scss';
import Building from './building';
import { formSchema, initialValues } from './formSchema';
import ResearchByOwner from './ownerSearch/ResearchByOwner';
import PlotCalculation from './plotCalculation';

function AdvancedPlotSearchContainer() {
  const { geolocDatas } = useAppSelector(getMapState);
  const { formData, activeInsee, geoFromDraw } = useAppSelector(
    getAdvancedPlotSearchState
  );
  const dispatch = useAppDispatch();

  const formOptions = useForm<AdvancedPlotSearchForm>({
    defaultValues: formData ?? initialValues,
    resolver: yupResolver(formSchema),
  });
  const {
    handleSubmit,
    formState: { isDirty, dirtyFields },
    setValue,
    getValues,
    reset,
    watch,
  } = formOptions;

  const resetTempGeo = () => {
    dispatch(advancedPlotSearchActions.setGeoFromDraw(null));
  };

  useEffect(() => {
    //if composant is destructed, reset temp draw
    return () => {
      resetTempGeo();
    };
  }, []);

  useEffect(() => {
    // get polygon value
    const polygon = watch('polygon');

    // if polygon is juste created, add it to formData
    // but not in store (need to submit form)
    if (!polygon && geoFromDraw) {
      setValue('polygon', JSON.stringify(geoFromDraw));
    }

    // if user navigate between left panel tab,
    // need to asign polygon to geoFormDrow for display
    if (polygon && !geoFromDraw) {
      dispatch(advancedPlotSearchActions.setGeoFromDraw(JSON.parse(polygon)));
    }
  }, [geoFromDraw, watch('polygon')]);

  useEffect(() => {
    // if city change, reset slice (without plots) and set new insee
    if (activeInsee !== geolocDatas?.inseeCode) {
      dispatch(advancedPlotSearchActions.resetForm());
      dispatch(
        advancedPlotSearchActions.setActiveInsee(geolocDatas?.inseeCode ?? null)
      );
      reset(initialValues);
      dispatch(
        fetchZonesAndZoneChildsThunk({
          city: geolocDatas?.city ?? null,
          postalCode: geolocDatas?.postalCode ?? null,
          inseeCode: geolocDatas?.inseeCode ?? null,
        })
      );
    }
  }, [geolocDatas?.inseeCode, activeInsee]);

  const onSubmit = (data: AdvancedPlotSearchForm) => {
    if (isDirty || formData) {
      dispatch(advancedPlotSearchActions.formDataSet(data));
      // Checks if only 'isBodacc' field has been modified
      if (dirtyFields.isBodacc && Object.keys(dirtyFields).length === 1) {
        dispatch(modalsActions.bodaccModal(true));
      } else {
        dispatch(
          modalsActions.advancedSearchResult({ status: true, context: data })
        );
      }
    }
  };

  if (!geolocDatas?.city) {
    return <NoCity />;
  }

  return (
    <div className={styles.advancedPlotSearchContainer}>
      <FormProvider {...formOptions}>
        <form className={styles.formContainer} onSubmit={handleSubmit(onSubmit)}>
          <BlocContainer title="Zonage PLU" blocKey={'zonage'}>
            <Zonage
              city={geolocDatas?.city ?? null}
              postalCode={geolocDatas?.postalCode ?? null}
              inseeCode={geolocDatas?.inseeCode ?? null}
            />
          </BlocContainer>

          <BlocContainer title="Calculs des parcelles" blocKey="plotCalc">
            <PlotCalculation />
          </BlocContainer>

          <BlocContainer title="Calculs des bâtiments" blocKey="builtCalc">
            <Building />
          </BlocContainer>

          <BlocContainer title="Propriétaires et occupants" blocKey="owner">
            <ResearchByOwner
              isOwnerEmpty={isEmpty(getValues('ownerName'))}
              isSirenNumberEmpty={isEmpty(getValues('sirenNumber'))}
            />
          </BlocContainer>

          <AdvancedSearchButtons hasFormData={Boolean(formData)} />
        </form>{' '}
      </FormProvider>
    </div>
  );
}

export default memo(AdvancedPlotSearchContainer);
