import { Page } from '@react-pdf/renderer';
import GenericBloc from '../../shared/components/pdf/GenericBloc';
import { pdfStyles } from '../../shared/styles/pdfStyle';
import GoodDetails from './components/GoodDetails';
import PageFooter from './components/PageFooter';
import PageHeader from './components/PageHeader';
import PlotDetails from './components/PlotDetails';
import UrbanismeInfos from './components/UrbanismeInfos';

interface IPageTwoProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  plotIds: string[];
  zonePlu: string;
  plotDatas: {
    parcelsOnCadastrePicture: string;
    parcelsOnZonePicture: string;
    area: number;
    freeGroundArea: number;
    builtArea: number;
    recalculatedArea: number;
    builtHeight: number;
  };
  goodDetails: {
    roomCount: number;
    floorCount: number;
    goodTypology: string;
    homeArea: number;
    landArea: number;
    comment: string;
  };
  isOrpi: boolean;
}

function PageTwo({
  date,
  address,
  plotIds,
  zonePlu,
  plotDatas,
  goodDetails,
  isOrpi,
}: IPageTwoProps) {
  return (
    <Page size="A4" style={pdfStyles.page}>
      <PageHeader date={date} address={address} fixed isOrpi={isOrpi} />

      <GenericBloc title="Détail de la parcelle">
        <PlotDetails {...plotDatas} plotIds={plotIds} />
      </GenericBloc>

      <GenericBloc title="Détail du bien">
        <GoodDetails {...goodDetails} />
      </GenericBloc>

      <GenericBloc title="Informations d'urbanisme">
        <UrbanismeInfos
          zone={zonePlu}
          parcelsOnZonePicture={plotDatas.parcelsOnZonePicture}
        />
      </GenericBloc>

      <PageFooter fixed isOrpi={isOrpi} />
    </Page>
  );
}

export default PageTwo;
