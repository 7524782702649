import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { nanoid } from '@reduxjs/toolkit';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { georisquesActions, getGeorisqueState } from '../georisqueSlice';
import SectionCheckboxContainer from './SectionCheckboxContainer';
import styles from './georisqueLegend.module.scss';
interface ILegendSectionProps {
  section: GeorisqueLegend;
}

function LegendSection({ section }: ILegendSectionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const { displayedLayers } = useAppSelector(getGeorisqueState);
  const dispatch = useAppDispatch();

  const handleChange = () => {
    const values = section.layers.map((m) => m.layer + '/' + section.title);
    dispatch(georisquesActions.multiRowCheck({ values, check: !checked }));
    setChecked(!checked);

    if (!isOpen && !checked) setIsOpen(!isOpen);
  };

  const handleOpenClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const finded = displayedLayers.find((f) => f.indexOf(section.title));
    const allChildChecked = section.layers
      .map((layer) => displayedLayers.some((elt) => elt.includes(layer.layer)))
      .every((value) => value === true);

    if (!finded) setChecked(false);
    if (allChildChecked) setChecked(true);
  }, [displayedLayers]);

  return (
    <div className={styles.legendSection}>
      <div className={styles.legendSectionHeader}>
        <div className={styles.headerCheckbox}>
          <input
            type="checkbox"
            name={section.title}
            checked={checked}
            onChange={handleChange}
          />
          <h3>{section.title}</h3>
        </div>

        <ExpandMoreIcon
          className={`${styles.expandIcon} ${isOpen ? styles.isOpen : ''}`}
          onClick={handleOpenClick}
        />
      </div>

      {isOpen && <SectionCheckboxContainer key={nanoid()} checkboxs={section} />}
    </div>
  );
}

export default LegendSection;
