import { createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../../../App/store';
import { GenericModalEnum } from '../../../../components/Modals/GenericModal';
import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import {
  deleteEntity,
  fetchDataById,
} from '../../../../services/axiosFiles/genericCrud';
import {
  SECTOR_DEFAULT_COLOR,
  SECTOR_OWNER_COLOR,
} from '../../../../shared/constants';
import entityListFromNestedEntities from '../../../../utils/entityListFromNestedEntities';
import { displayManagerActions } from '../../../displayManager/displayManagerSlice';
import { isParentEntitiesDisplayed } from '../../../displayManager/utils';
import { plotStudyToEntityDisplay } from '../../../displayManager/utils/entitiesDisplayParsers';
import { foldersActions } from '../../../folders/foldersSlice';
import { loadersActions } from '../../../loaders/loaderSlice';
import { mapActions } from '../../../map/mapSlice';
import { modalsActions } from '../../../modals/modalsSlice';
import { studyParamsActions } from '../../slices/studyParamsSlice';
import { studyActions } from '../../slices/studySlice';
import { getStudyTypeFromIdIri } from '../../utils/getStudyType';
import { genericStudyParser } from '../../utils/parsers/studyParser';
import { checkForExistingPlotStudy } from '../checkForExistingPlotStudy';
import { createPlotStudy } from '../createPlotStudy';
import { fetchPlotStudies } from '../fetchPlotStudies';
import { fetchStudy } from '../fetchStudy';
import updateStudy from '../updateStudy';

export const constructStudyDatasThunk = createAsyncThunk(
  'study/constructStudyDatasThunk',
  async (
    params: {
      type: StudyParamsType | null;
      idIri: string | null;
    },
    thunkOptions
  ) => {
    const { type, idIri } = params;
    const state = thunkOptions.getState() as RootState;

    const { plotStudyStatuses } = state.app;
    const { folders } = state.folders;
    const { sectors } = state.sectors;
    const { users } = state.users;
    try {
      if (type && idIri && folders.result) {
        thunkOptions.dispatch(loadersActions.loaderHide());
        thunkOptions.dispatch(studyParamsActions.setManagmentLoader(true));
        let studyPlotStudies: PlotStudies | null = null;

        const f = entityListFromNestedEntities(folders.result) as Folders;
        const study = await fetchStudy(
          idIri,
          users,
          f,
          plotStudyStatuses.result,
          sectors.result
        );

        if (type === 'folder') {
          studyPlotStudies = await fetchPlotStudies({
            urlBase: study.idIri,
            users,
            statuses: plotStudyStatuses.result,
            folders: f,
          });
        }

        if (type === 'plotStudy') {
          thunkOptions.dispatch(mapActions.plotGeolocDataSet(study as IPlotStudy));
        }
        thunkOptions.dispatch(studyParamsActions.setManagmentLoader(false));

        return {
          study,
          studyPlotStudies,
        };
      } else {
        thunkOptions.dispatch(studyParamsActions.setManagmentLoader(false));
        return thunkOptions.rejectWithValue(
          paramsNotFound('constructStudyDatasThunk')
        );
      }
    } catch (error) {
      thunkOptions.dispatch(studyParamsActions.setManagmentLoader(false));
      return thunkOptions.rejectWithValue(error);
    }
  }
);
export const fetchStudyThunk = createAsyncThunk(
  'study/fetchStudyThunk',
  async (params: { studyIdIri: string }, thunkOptions) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { users } = state.users;
      const { folders } = state.folders;
      const { sectors } = state.sectors;
      const { plotStudyStatuses } = state.app;
      if (
        params.studyIdIri &&
        users &&
        folders.result &&
        plotStudyStatuses.result &&
        sectors
      ) {
        const study: any = await fetchDataById(params.studyIdIri);

        const parsedStudy = genericStudyParser(
          study,
          users,
          folders.result,
          plotStudyStatuses.result,
          sectors.result
        );

        if (getStudyTypeFromIdIri(parsedStudy.idIri) === 'plotStudy') {
          thunkOptions.dispatch(
            mapActions.plotGeolocDataSet(parsedStudy as IPlotStudy)
          );
        }
        return parsedStudy as IStudy;
      } else {
        return Promise.reject(paramsNotFound());
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
export const checkExistingPlotStudyThunk = createAsyncThunk(
  'study/checkExistingPlotStudyThunk',
  async (params: { fullPlotId: string }, thunkOptions) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { companyIdIri } = state.company;
      const { users } = state.users;
      const { folders } = state.folders;
      const { plotStudyStatuses } = state.app;

      if (companyIdIri && params.fullPlotId && users && plotStudyStatuses.result) {
        const result = await checkForExistingPlotStudy(
          params.fullPlotId,
          companyIdIri,
          users,
          plotStudyStatuses.result,
          entityListFromNestedEntities(folders.result) as Folders
        );

        if (result) {
          thunkOptions.dispatch(studyActions.studySet(result));
          thunkOptions.dispatch(
            studyParamsActions.setParams({
              type: 'plotStudy',
              noCheckExisting: true,
              idIri: 'plot_studies/' + result.id,
              managmentLoader: false,
            })
          );

          return result;
        } else {
          return null;
        }
      } else {
        return Promise.reject(paramsNotFound());
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

// **********************************************************************************
// ********************************** CREATE STUDY **********************************
// *                 called only when select a folder on right panel                *
// **********************************************************************************
export const createPlotStudyThunk = createAsyncThunk(
  'study/createPlotStudyThunk',
  async (
    params: {
      folder: IFolder;
    },
    thunkOptions
  ) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { userIdIri } = state.auth;
      const { users } = state.users;
      const { geolocDatas, mapCenter } = state.map;
      const { parcelle } = state.plotReducer;
      const { entities, allFoldersDisplayed } = state.displayManager;
      const statuses = state.app.plotStudyStatuses.result;

      if (
        geolocDatas?.city &&
        params.folder.idIri &&
        geolocDatas?.inseeCode &&
        mapCenter &&
        parcelle &&
        userIdIri &&
        geolocDatas?.postalCode &&
        statuses &&
        users
      ) {
        const body: ICreatePlotStudy = {
          city: geolocDatas?.city,
          folder: params.folder,
          inseeCode: geolocDatas?.inseeCode,
          lat: mapCenter[0],
          lng: mapCenter[1],
          address: geolocDatas?.address,
          area: parseInt(parcelle?.theoricCapacity, 10) ?? 0,
          fullPlotId: parcelle.parcelleId,
          postalCode: geolocDatas?.postalCode,
          zone: parcelle.zone.zone,
          zoneChild: parcelle.zone.zoneChild,
          responsable: userIdIri,
        };

        const plotStudy = await createPlotStudy(body, users, statuses);
        thunkOptions.dispatch(
          studyParamsActions.setParams({
            type: 'plotStudy',
            noCheckExisting: true,
            idIri: plotStudy.idIri,
            managmentLoader: false,
          })
        );

        if (
          isParentEntitiesDisplayed(entities.result, params.folder.idIri) ||
          allFoldersDisplayed
        ) {
          thunkOptions.dispatch(
            displayManagerActions.entitiesAdd([
              plotStudyToEntityDisplay(
                plotStudy,
                params.folder.plotStudiesCount + 1,
                params.folder.markerColor
              ),
            ])
          );
        }
        return plotStudy;
      } else {
        return thunkOptions.rejectWithValue(paramsNotFound());
      }
    } catch (error) {
      return thunkOptions.rejectWithValue(error);
    }
  }
);

// **********************************************************************************
// ********************************** UPDATE STUDY **********************************
// **********************************************************************************
export const updateStudyThunk = createAsyncThunk(
  'study/updateStudyThunk',
  async (
    params: {
      body:
        | UpdateStudyManagmentProps
        | GeolocPointInfo
        | { mailBoxes: number }
        | { comment: string }
        | { zone: string | null; zoneChild: string | null; surface: number | null }
        | { parent: IIdIri }
        | ConstructFormType;

      isRecursive?: 'status' | 'responsable' | 'priority' | null;
    },
    thunkOptions
  ) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { idIri, type } = state.studyParams;
      const { plotStudyStatuses } = state.app;
      const { folders } = state.folders;
      const { sectors } = state.sectors;
      const { users } = state.users;
      const { userIdIri } = state.auth;

      if (type) {
        let urlQuery: QueryParams | undefined = undefined;

        if (type === 'folder') {
          // allow recursive update for plot_studies in a sub folder
          urlQuery =
            params.isRecursive === 'status'
              ? { update_plot_studies_status: true }
              : params.isRecursive === 'responsable'
                ? { update_plot_studies_responsable: true }
                : params.isRecursive === 'priority'
                  ? { update_plot_studies_priority: true }
                  : undefined;
        }

        //remove all null or undefined values
        const untypedBody = { ...params.body } as { [x: string]: any };
        Object.keys(untypedBody).forEach(
          (key) =>
            (untypedBody[key] === null || untypedBody[key] === undefined) &&
            delete untypedBody[key]
        );
        //change for good api keys
        untypedBody.status = untypedBody.status?.idIri;
        untypedBody.responsable = untypedBody.responsable?.idIri;
        untypedBody.folder = untypedBody.parent?.idIri;

        delete untypedBody.parent;

        if (untypedBody && !isEmpty(untypedBody)) {
          const result = await updateStudy(
            idIri,
            untypedBody,
            urlQuery ?? null,
            entityListFromNestedEntities(folders.result) as Folders,
            users,
            plotStudyStatuses.result,
            sectors.result
          );

          if (type === 'sector' && untypedBody.responsable) {
            thunkOptions.dispatch(
              displayManagerActions.entityResponsableColorUpdate({
                idIri: result?.idIri ?? '',
                color:
                  untypedBody.responsable === userIdIri
                    ? SECTOR_OWNER_COLOR
                    : SECTOR_DEFAULT_COLOR,
              })
            );
          }
          return result;
        } else {
          thunkOptions.dispatch(
            modalsActions.alert({
              status: true,
              context: 'updateStudyError',
              modalType: GenericModalEnum.ERROR,
            })
          );
          return Promise.reject({ status: '422', message: 'no study type params' });
        }
      } else {
        thunkOptions.dispatch(
          modalsActions.alert({
            status: true,
            context: 'updateStudyError',
            modalType: GenericModalEnum.ERROR,
          })
        );
        return Promise.reject(paramsNotFound());
      }
    } catch (error) {
      thunkOptions.dispatch(
        modalsActions.alert({
          status: true,
          context: 'updateStudyError',
          modalType: GenericModalEnum.ERROR,
        })
      );
      return Promise.reject(error);
    }
  }
);
export const deleteStudyThunk = createAsyncThunk(
  'study/deleteStudyThunk',
  async (params: { study: IStudy | null }, thunkOptions) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const { deployedFolder, deployedSubFolder } = state.folders;

      if (params.study) {
        const result = (await deleteEntity(params.study.idIri)) as number;

        if (result === 204) {
          if (
            deployedFolder &&
            deployedFolder.idIri === params.study?.parent?.idIri
          ) {
            thunkOptions.dispatch(foldersActions.allDeployedFoldersReset());
          }
          if (
            deployedSubFolder &&
            deployedSubFolder.idIri === params.study?.parent?.idIri
          ) {
            thunkOptions.dispatch(foldersActions.deployedSubFolderReset());
          }
          thunkOptions.dispatch(
            displayManagerActions.entitiesRemoveByIdIri([params.study.idIri])
          );
          return true;
        } else {
          // todo popup error
          return false;
        }
      } else {
        return Promise.reject(paramsNotFound());
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
);
