import { createAsyncThunk } from '@reduxjs/toolkit';
import fetchProspecGetAdsStats from './requests/fetchProspecGetAdsStats';

const fetchProspecAdsStatsThunk = createAsyncThunk(
  'externalPdfErrial/fetchProspecAdsThunk',
  async (params: { cityId: string | null; area: number | null }) => {
    try {
      const result = await fetchProspecGetAdsStats({
        prospecCityId: params.cityId,
        area: params.area,
      });

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchProspecAdsStatsThunk;
