import { useState } from 'react';
import { useAppDispatch } from '../../App/store';
import fetchCompanyLogoFromS3 from '../../features/company/services/fetchCompanyLogoFromS3';
import updateCompanyThunk from '../../features/company/services/thunks/updateCompanyThunk';

function useLoadImage() {
  const [loadedImage, setLoadedImage] = useState<LoadedImageType | null>(null);
  const dispatch = useAppDispatch();

  const getImageCallback = (value: LoadedImageType) => {
    setLoadedImage(value);
  };
  const getImage = async (url: string, height?: number) => {
    try {
      if (!url) return null;

      const response = await fetchCompanyLogoFromS3({
        url,
        height,
      });

      if (!response) throw new Error('No image loaded');

      setLoadedImage(response);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const deleteImage = () => {
    const callback = () => setLoadedImage(null);
    dispatch(updateCompanyThunk({ body: { logoUrl: null }, callback }));
    setLoadedImage(null);
  };
  return { getImage, loadedImage, deleteImage };
}

export default useLoadImage;
