import { changeApiKeyForStoreKey } from '../../services/common/parserCommon';

export const announcementMapping = {
  '@id': 'idIri',
  '@type': 'type',
  title: 'title',
  content: 'content',
  createdAt: 'createdAt',
  isActive: 'isActive',
};

export const announcementParser = (data: any): Announcement[] => {
  try {
    const parsedData: Announcement[] = data.map((elt: Announcement) =>
      changeApiKeyForStoreKey(elt, announcementMapping)
    );

    return parsedData;
  } catch (error) {
    throw new Error("the announcement can't be parsed");
  }
};
