import { createPlotStudy } from './createPlotStudy';

async function createMultiPlotStudy(
  plots: ICreatePlotStudies,
  users: Users | null,
  statuses: StudyStatuses | null
) {
  try {
    if (plots) {
      const result = await Promise.all(
        plots.map((m) => createPlotStudy(m, users, statuses))
      );

      return result;
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default createMultiPlotStudy;
