import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';
import * as Yup from 'yup';
import { validationMessages } from '../../../constants/validations';

export const initialContactForm: ContactFormType = {
  lastName: '',
  firstName: '',
  addressFloor: undefined,
  addressBuilding: undefined,
  addressStreet: '',
  addressExtra: undefined,
  postalCode: '',
  city: '',
  country: '',
  phone: undefined,
  email: undefined,
  description: undefined,
  allowedToBeContacted: undefined,
};

export const contactFormHydratation = <T extends FieldValues>(
  contact: Contact,
  setValue: UseFormSetValue<T>
) => {
  setValue('lastName' as Path<T>, contact.lastName as PathValue<T, Path<T>>);
  setValue('firstName' as Path<T>, contact.firstName as PathValue<T, Path<T>>);
  setValue(
    'addressFloor' as Path<T>,
    (contact.address.addressFloor as PathValue<T, Path<T>>) ??
      (undefined as PathValue<T, Path<T>>)
  );
  setValue(
    'addressBuilding' as Path<T>,
    (contact.address.addressBuilding as PathValue<T, Path<T>>) ??
      (undefined as PathValue<T, Path<T>>)
  );
  setValue(
    'addressStreet' as Path<T>,
    contact.address.addressStreet as PathValue<T, Path<T>>
  );
  setValue(
    'addressExtra' as Path<T>,
    (contact.address.addressExtra as PathValue<T, Path<T>>) ??
      (undefined as PathValue<T, Path<T>>)
  );
  setValue(
    'postalCode' as Path<T>,
    contact.address.postalCode as PathValue<T, Path<T>>
  );
  setValue('city' as Path<T>, contact.address.city as PathValue<T, Path<T>>);
  setValue('country' as Path<T>, contact.address.country as PathValue<T, Path<T>>);
  setValue(
    'email' as Path<T>,
    (contact.email as PathValue<T, Path<T>>) ?? (undefined as PathValue<T, Path<T>>)
  );
  setValue(
    'phone' as Path<T>,
    (contact.phone as PathValue<T, Path<T>>) ?? (undefined as PathValue<T, Path<T>>)
  );
  setValue(
    'description' as Path<T>,
    (contact.description as PathValue<T, Path<T>>) ??
      (undefined as PathValue<T, Path<T>>)
  );
  setValue(
    'allowedToBeContacted' as Path<T>,
    (contact.allowedToBeContacted as PathValue<T, Path<T>>) ??
      (undefined as PathValue<T, Path<T>>)
  );
};

export const contactValidationSchema = Yup.object().shape({
  lastName: Yup.string().required(validationMessages.contactLastName.requis),
  firstName: Yup.string().required(validationMessages.contactFirstName.requis),
  addressFloor: Yup.string(),
  addressBuilding: Yup.string(),
  addressStreet: Yup.string()
    .required(validationMessages.contactStreet.requis)
    .default(''),
  addressExtra: Yup.string(),
  postalCode: Yup.string().required(validationMessages.postalCode.requis),
  city: Yup.string().required(validationMessages.city.requis),
  country: Yup.string().required(validationMessages.country.requis),
  phone: Yup.string(),
  email: Yup.string().email(validationMessages.email.error),
  description: Yup.string(),
  allowedToBeContacted: Yup.boolean(),
});
