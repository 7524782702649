import { FavoriteSearch } from '../../redux/favoritePlaces/reducer';
import {
  changeApiKeyForStoreKey,
  changeStoreKeyForApiKey,
  mapObjectParser,
} from '../../services/common/parserCommon';

const favoritePlaceMapping = {
  id: 'idIri',
  address: 'address',
  city: 'city',
  department: 'department',
  inseeCode: 'inseeCode',
  isCitySearch: 'isCitySearch',
  zipCode: 'postalCode',
  latitude: 'lat',
  longitude: 'lng',
  predictionId: 'predictionId',
  owner: 'ownerId',
};

export const favoritePlaceApiToStoreParser = (data: any): any => {
  const parsedData = changeApiKeyForStoreKey<FavoriteSearch>(
    data,
    favoritePlaceMapping
  );
  // for address server only
  // const split = parsedData.predictionId.split('_').length;

  // if (split === 1) {
  //   parsedData.isCitySearch = true;
  // }
  return parsedData;
};

export const favoritePlacesApiToStoreParser = (data: any): FavoriteSearch[] => {
  return mapObjectParser(data, favoritePlaceApiToStoreParser);
};

export const favoritePlaceStoreToApiParser = (
  data: Partial<FavoriteSearch>
): any => {
  return changeStoreKeyForApiKey(data, favoritePlaceMapping);
};
