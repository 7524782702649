import { PDF_ACCESS_FIRM_LIST } from '../../../shared/constants';

// if not => standard plot_pdf_editor
const checkOrpiPdfAllow = (firm: string | null) => {
  if (!firm) return false;

  return PDF_ACCESS_FIRM_LIST.includes(firm);
};

export default checkOrpiPdfAllow;
