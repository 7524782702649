import styles from './priorityIcon.module.scss';

export function getColorClassName(level: number | string) {
  const lvl = typeof level === 'string' ? parseInt(level, 10) : level;
  let color: string = '';
  if (lvl === 0) return null;

  if (lvl === 1) color = styles.green;
  if (lvl === 2) color = styles.orange;
  if (lvl === 3) color = styles.red;

  return color;
}
