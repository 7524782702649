import { useAppDispatch, useAppSelector } from '../../../../../../App/store';
import { getMapPluginsMeasureState, mapPluginsActions } from '../../mapPluginSlice';
import styles from '../measureToolbar.module.scss';
import { MeasureActionEnum } from '../types/measureActionEnum';
import { IMeasureInterfaceProps } from './MeasurePluginInterface';
import StartSectionRow from './StartSectionRow';

function StartInterfaceSection({ onClick }: IMeasureInterfaceProps) {
  const { lineActivated } = useAppSelector(getMapPluginsMeasureState);
  const dispatch = useAppDispatch();
  const handleClick = (type: MeasureActionEnum) => {
    onClick(type);
  };
  return (
    <div className={styles.startSection}>
      <h3>Mesurer les distances et superficies</h3>
      <hr />
      <ul className={styles.tasks}>
        <StartSectionRow
          text="Créer une nouvelle mesure de surface"
          onClick={() => handleClick(MeasureActionEnum.START_DRAW_SURFACE)}
        />
        <StartSectionRow
          text="Supprimer les mesures de surface"
          onClick={() => handleClick(MeasureActionEnum.RESET_SURFACES)}
        />
      </ul>
      <hr />
      <ul className={styles.tasks}>
        {!lineActivated ? (
          <StartSectionRow
            text="Activer la mesure de distance"
            onClick={() => dispatch(mapPluginsActions.measureLineActivate(true))}
          />
        ) : (
          <StartSectionRow
            text="Désactiver la mesure de distance"
            onClick={() => dispatch(mapPluginsActions.measureLineActivate(false))}
            isRed
          />
        )}
        <StartSectionRow
          text="Supprimer les mesures de distance"
          onClick={() => handleClick(MeasureActionEnum.RESET_LINES)}
        />
      </ul>
    </div>
  );
}

export default StartInterfaceSection;
