import styles from '../styles/rightPanelModalExternalPdfErrial.module.scss';

function NoPlotContent() {
  return (
    <div className={styles.noPlotContent}>
      <p>Veuillez saisir une adresse</p>
      <p>Pour commencer l&apos;édition du PDF</p>
    </div>
  );
}

export default NoPlotContent;
