import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { RootState } from '../../App/store';
import arrayFilter from '../../lib/arrayFilter';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import {
  fetchAssietteSupSThunk,
  fetchInfoPctThunk,
  fetchInfoSurfThunk,
  fetchPrescriptionLinThunk,
  fetchPrescriptionPctThunk,
  fetchScotThunk,
} from './plotServitudesThunks';

const initialState: PlotServitudesState = {
  activePlotId: null,
  customPlotParam: null,
  infoPct: { apiStatus: APIStatus.IDLE, result: null },
  infoSurf: { apiStatus: APIStatus.IDLE, result: null },
  prescriptionLin: { apiStatus: APIStatus.IDLE, result: null },
  prescriptionPct: { apiStatus: APIStatus.IDLE, result: null },
  assietteSupS: { apiStatus: APIStatus.IDLE, result: null },
  scot: { apiStatus: APIStatus.IDLE, result: null },

  displayedLayers: [],
};

const plotServitudes = createSlice({
  name: 'plotServitudes',
  initialState,
  reducers: {
    reset: () => initialState,
    setActivePlotId: (state, action: PayloadAction<string>) => {
      state.activePlotId = action.payload;
    },
    setDisplayedLayer: (state, action: PayloadAction<string>) => {
      state.displayedLayers = arrayFilter<string>(
        state.displayedLayers,
        action.payload
      );
    },
    setDisplayedGroup: (
      state,
      action: PayloadAction<{ checked: boolean; idTab: string[] }>
    ) => {
      const ap = action.payload;
      const tempDisplayed = [...state.displayedLayers];
      if (ap.checked) {
        ap.idTab.forEach((f) => {
          if (!tempDisplayed.includes(f)) {
            tempDisplayed.push(f);
          }
        });
      } else {
        ap.idTab.forEach((f) => {
          if (tempDisplayed.includes(f)) {
            tempDisplayed.splice(tempDisplayed.indexOf(f), 1);
          }
        });
      }

      state.displayedLayers = tempDisplayed;
    },
    displayedLayerReset: (state) => {
      state.displayedLayers = [];
    },
  },
  extraReducers: (builder) => {
    // Plot
    builder
      .addCase(fetchPrescriptionPctThunk.pending, (state) => {
        state.prescriptionPct.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchPrescriptionPctThunk.fulfilled,
        (state, action: PayloadAction<FeatureCollection | null>) => {
          state.prescriptionPct.apiStatus = APIStatus.IDLE;
          state.prescriptionPct.error = undefined;
          state.prescriptionPct.result = action.payload;
        }
      )
      .addCase(
        fetchPrescriptionPctThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.prescriptionPct.apiStatus = APIStatus.REJECTED;
          state.prescriptionPct.error = action.payload;
        }
      )
      .addCase(fetchPrescriptionLinThunk.pending, (state) => {
        state.prescriptionLin.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchPrescriptionLinThunk.fulfilled,
        (state, action: PayloadAction<FeatureCollection | null>) => {
          state.prescriptionLin.apiStatus = APIStatus.IDLE;
          state.prescriptionLin.error = undefined;
          state.prescriptionLin.result = action.payload;
        }
      )
      .addCase(
        fetchPrescriptionLinThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.prescriptionLin.apiStatus = APIStatus.REJECTED;
          state.prescriptionLin.error = action.payload;
        }
      )
      .addCase(fetchInfoSurfThunk.pending, (state) => {
        state.infoSurf.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchInfoSurfThunk.fulfilled,
        (state, action: PayloadAction<FeatureCollection | null>) => {
          state.infoSurf.apiStatus = APIStatus.IDLE;
          state.infoSurf.error = undefined;
          state.infoSurf.result = action.payload;
        }
      )
      .addCase(fetchInfoSurfThunk.rejected, (state, action: PayloadAction<any>) => {
        state.infoSurf.apiStatus = APIStatus.REJECTED;
        state.infoSurf.error = action.payload;
      })
      .addCase(fetchInfoPctThunk.pending, (state) => {
        state.infoPct.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchInfoPctThunk.fulfilled,
        (state, action: PayloadAction<FeatureCollection | null>) => {
          state.infoPct.apiStatus = APIStatus.IDLE;
          state.infoPct.error = undefined;
          state.infoPct.result = action.payload;
        }
      )
      .addCase(fetchInfoPctThunk.rejected, (state, action: PayloadAction<any>) => {
        state.infoPct.apiStatus = APIStatus.REJECTED;
        state.infoPct.error = action.payload;
      })
      .addCase(fetchAssietteSupSThunk.pending, (state) => {
        state.assietteSupS.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchAssietteSupSThunk.fulfilled,
        (state, action: PayloadAction<FeatureCollection | null>) => {
          state.assietteSupS.apiStatus = APIStatus.IDLE;
          state.assietteSupS.error = undefined;
          state.assietteSupS.result = action.payload;
        }
      )
      .addCase(
        fetchAssietteSupSThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.assietteSupS.apiStatus = APIStatus.REJECTED;
          state.assietteSupS.error = action.payload;
        }
      )
      .addCase(fetchScotThunk.pending, (state) => {
        state.scot.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchScotThunk.fulfilled,
        (state, action: PayloadAction<FeatureCollection | null>) => {
          state.scot.apiStatus = APIStatus.IDLE;
          state.scot.error = undefined;
          state.scot.result = action.payload;
        }
      )
      .addCase(fetchScotThunk.rejected, (state, action: PayloadAction<any>) => {
        state.scot.apiStatus = APIStatus.REJECTED;
        state.scot.error = action.payload;
      });
  },
});

export default plotServitudes.reducer;
export const plotServitudesActions = plotServitudes.actions;
export const getPlotServitudesState = (state: RootState) => state.plotServitudes;
