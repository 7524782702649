import { useAppDispatch } from '../../../App/store';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../components/Modals/GenericModal';
import { modalsActions } from '../../modals/modalsSlice';
import { logoutThunk } from '../authThunks';

function AuthErrorModal() {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    // close this popup open the signin popup in same action
    dispatch(logoutThunk());
    dispatch(modalsActions.authErrorModalClose());
  };

  return (
    <GenericModal
      title="Erreur de connexion"
      modalType={GenericModalEnum.INFO}
      actionType={GenericModalActionEnum.CROSS}
      closeModal={handleClose}
      okButtonContent="Me reconnecter"
      okCallback={handleClose}
    >
      <>
        <p>Un problème est survenu lors de la tentative de connexion automatique.</p>
        <p>Vous devez vous reconnecter manuellement.</p>
      </>
    </GenericModal>
  );
}

export default AuthErrorModal;
