import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash';
import { fontStyles } from '../styles/pdfStyles';
import FloorNumberSvg from './svg/FloorNumberSvg';
import HouseSvg from './svg/HouseSvg';

const parsedAddress = (address: PdfLandAddressType) =>
  `${
    address.houseNumber && address.houseNumber !== '0' ? address.houseNumber : ''
  } ${address.streetName ?? ''} ${address.postalCode ?? ''} ${address.city ?? ''}`;

//check if the number is even
const isEven = (number: number) => number % 2 === 0;

const getStatusColor = (status: string) => {
  switch (status) {
    case 'Travaux commencés':
      return '#52B7D5';
    case 'Travaux terminés':
      return '#FFCC00';
    case 'Permis autorisé':
      return '#6cc139';
    case 'Abandonné':
      return '#FF471A';
    default:
      return '#000';
  }
};

const getConstructType = (isCommercial: boolean, isResidential: boolean) => {
  // if(isCommercial) return
  return isResidential ? 'Résidentiel' : 'Non résidentiel';
};

const getStatusDate = (bp: builtPermit) => {
  let text = '';
  if (bp.status === 'Permis autorisé' || bp.status === 'Travaux commencés')
    text = `Autorisé le ${bp.buildingPermitIssuedDate ?? ''}`;

  if (bp.status === 'Travaux terminés')
    text = `Terminés le ${bp.constructionEndDate ?? ''}`;

  return text;
};

function BuildPermitsItem(props: builtPermit) {
  const {
    status,
    index,
    type,
    location,
    isCommercial,
    isResidential,
    numberOfFloorsInBuilding,
  } = props;
  const permitColor = getStatusColor(status);
  const itemBackgroundColor = isEven(index) ? '#f7f7f7' : '#fff';
  const constructType = getConstructType(isCommercial, isResidential);

  return (
    <View style={{ ...styles.item, backgroundColor: itemBackgroundColor }}>
      <View style={{ ...styles.itemNumberView, backgroundColor: permitColor }}>
        <Text style={styles.itemNumberText}>{index}</Text>
      </View>

      <View style={styles.itemTypeView}>
        <Text style={styles.itemTypeText}>{type}</Text>
        {!isEmpty(location.address) ? (
          <Text style={[fontStyles.fs10]}>{parsedAddress(location.address)}</Text>
        ) : (
          <Text style={fontStyles.fs11}>L&apos;adresse exacte est inconnue</Text>
        )}
      </View>

      <View style={styles.itemPermitView}>
        <Text style={{ ...styles.itemPermitText, color: permitColor }}>
          {status}
        </Text>
        <Text style={[fontStyles.fs8]}>{getStatusDate(props)}</Text>
      </View>

      <View style={styles.itemBuildingView}>
        <View style={styles.svg}>
          <HouseSvg />
        </View>
        <Text style={fontStyles.fs11}>{constructType}</Text>
      </View>

      <View style={styles.itemFloorView}>
        {numberOfFloorsInBuilding > 0 && (
          <>
            <View
              style={{ marginRight: numberOfFloorsInBuilding < 10 ? '5px' : '3px' }}
            >
              <FloorNumberSvg />
            </View>
            <Text
              style={
                numberOfFloorsInBuilding < 10 ? fontStyles.fs11 : fontStyles.fs10
              }
            >
              {numberOfFloorsInBuilding} étage{numberOfFloorsInBuilding > 1 && 's'}
            </Text>
          </>
        )}
      </View>
    </View>
  );
}

export default BuildPermitsItem;

const styles = StyleSheet.create({
  item: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '2px',
    borderColor: '#D0D0D0',
    paddingVertical: '4px',
  },
  itemNumberView: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '25px',
    width: '14px',
    height: '14px',
    marginRight: '10px',
  },
  itemNumberText: {
    color: '#fff',
    fontSize: '10px',
  },
  itemTypeView: {
    flex: 1,
  },
  itemTypeText: {
    fontSize: '11px',
    lineHeight: 1.5,
    fontWeight: 700,
  },
  itemPermitView: {
    width: '150px',
  },
  itemPermitText: {
    fontSize: '11px',
  },
  itemBuildingView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100px',
  },
  itemFloorView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '60px',
  },
  svg: {
    marginRight: '5px',
  },
});
