export enum MeasureActionEnum {
  START_DRAW_SURFACE = 'START_DRAW_SURFACE',
  CANCEL_DRAW_SURFACE = 'CANCEL_DRAW_SURFACE',
  END_DRAW_SURFACE = 'END_DRAW_SURFACE',
  RESET_SURFACES = 'RESET_SURFACES',
  START_LINE = 'START_LINE',
  STOP_LINE = 'STOP_LINE',
  RESET_LINES = 'RESET_LINES',
}

export enum MeasureToolbarButtonEnum {
  MEASURE_START = 'MEASURE_START',
  MEASURE_STOP = 'MEASURE_STOP',
}
