import * as Yup from 'yup';
import { validationMessages } from '../../../constants/validations';

export interface IAddUserForm {
  lastName: string;
  firstName: string;
  email: string;
  password?: string;
  verifyPassword?: string;
}
export const initialForm: IAddUserForm= {
  lastName: '',
  firstName: '',
  email: '',
  password: '',
  verifyPassword: '',
};

const maxLength = 16;
const maxLengthMessage = 'Doit comporter au maximum 16 caractères';

export const validationAddUserSchema = Yup.object({
  lastName: Yup.string()
    .required(validationMessages.lastName.requis)
    .max(maxLength, maxLengthMessage),
  firstName: Yup.string()
    .required(validationMessages.firstName.requis)
    .max(maxLength, maxLengthMessage),
  email: Yup.string()
    .required(validationMessages.email.requis)
    .email(validationMessages.email.error),
  password: Yup.string(),
  verifyPassword: Yup.string().oneOf(
    [Yup.ref('password'), ''],
    'Les mots de passe ne correspondent pas'
  ),
});

export const validationEditUserSchema = Yup.object({
  lastName: Yup.string()
    .required(validationMessages.lastName.requis)
    .max(maxLength, maxLengthMessage),
  firstName: Yup.string()
    .required(validationMessages.firstName.requis)
    .max(maxLength, maxLengthMessage),
  email: Yup.string()
    .email(validationMessages.email.error)
    .required(validationMessages.email.requis),
});
