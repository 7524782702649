import * as Cesium from 'cesium';
import CesiumNavigation, { NavigationOptions } from 'cesium-navigation-es6';

/**
 * Compass initialisation
 * @param {Cesium.Viewer} viewer - viewer 3D cesium
 * @returns {void}
 */
async function compassInit(viewer: Cesium.Viewer) {
  return new Promise((resolve, reject) => {
    try {
      let options: NavigationOptions = {
        enableCompass: true,
        enableZoomControls: false,
        enableDistanceLegend: true,
        enableCompassOuterRing: true,
      };

      new CesiumNavigation(viewer, options);
      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  });
}

export default compassInit;
