import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';

const initialState: AutocompleteState = {
  inputValue: '',
};

const autocompleteSlice = createSlice({
  name: 'autocomplete',
  initialState,
  reducers: {
    setInputValue: (state, action: PayloadAction<string>) => {
      state.inputValue = action.payload;
    },
    reset: () => initialState,
  },
});

export const autocompleteActions = autocompleteSlice.actions;
export default autocompleteSlice.reducer;
export const getAutocompleteState = (state: RootState) => state.autocomplete;
