export enum GroundTypeEnum {
  GROUND_UNKNOWN = 'unknown',
  GROUND_600 = 'ground600',
  GROUND_1000 = 'ground1000',
  GROUND_MORE = 'ground_more',
}

export enum AccessibilityModeEnum {
  CAR = 'CAR',
  PUBLIC_TRANSPORT = 'PUBLIC_TRANSPORT',
  WALK = 'WALK',
  BICYCLE = 'BICYCLE',
}

export enum TransportModeEnum {
  train = 'Train',
  airport = 'Aéroport',
  subway = 'Metro',
  highway_ramp = 'Autoroute',
  tram = 'Tramway',
  bus = 'Bus',
}

export enum SocioEconomicModeEnum {
  AGE = 'AGE',
  POPULATION = 'POPULATION',
  PROFESSIONS = 'PROFESSIONS',
  CHOMAGE = 'CHOMAGE',
}
