import { createAsyncThunk } from '@reduxjs/toolkit';
import { InternalError } from '../../../../../services/axiosFiles/axiosUtils';

import { isEmpty } from 'lodash';
import { RootState } from '../../../../../App/store';
import { loadersActions } from '../../../../loaders/loaderSlice';
import { externalPdfErrialActions } from '../../../externalPdfSlice';
import {
  AccessibilityModeEnum,
  SocioEconomicModeEnum,
  TransportModeEnum,
} from '../../../shared/types/enums';
import { fetchDatasForPdfGenerationThunk } from '../errialPDF/fetchDatasForPdfGenerationThunk';
import { fetchPlotServitudesThunk } from '../fetchPlotServitudesThunk';
import fetchAccessibilityDatas from './requests/fetchAccessibilityDatas';
import fetchAgencyAndAgentDatas from './requests/fetchAgencyAndAgentDatas';
import fetchBuiltPermisDatas from './requests/fetchBuiltPermisDatas';
import fetchNoiseDatas from './requests/fetchNoiseDatas';
import fetchSocioEconomicDatas from './requests/fetchSocioEconomicDatas';
import fetchTransportsDatas from './requests/fetchTransportsDatas';

const landPdfRequestLauncherThunk = createAsyncThunk(
  'externalPdfErrial/pdfRequestLauncherThunk',
  async (
    params: {
      geo?: string;
      lat: number;
      lng: number;
      plots: string[];
      department: string;
      insee: string;
    },
    thunkOptions
  ) => {
    try {
      const state = thunkOptions.getState() as RootState;
      const folderDatas = state.externalPdfErrial.pricehubbleFolderDatas;
      const entityId = state.externalPdfErrial.pricehubbleId ?? '';
      //to be used when sweepbright send booleans who are missing
      const sbId = state.externalPdfErrial.sweepBrightExternalId;
      const phAccessToken = state.externalPdfErrial.phAccessToken;

      const { lat, lng, plots, geo, department, insee } = params;
      if (lat && lng && !isEmpty(plots) && geo) {
        const updateLoaderStep = (value: string) => {
          thunkOptions.dispatch(loadersActions.currentStepSet(value));
        };
        thunkOptions.dispatch(
          fetchPlotServitudesThunk({
            plotGeometry: geo,
            coordinates: [lat, lng],
          })
        );
        thunkOptions.dispatch(
          fetchDatasForPdfGenerationThunk({
            plots: plots,
          })
        );

        // if no folder id
        if (!folderDatas.noData && phAccessToken) {
          const lat = folderDatas.result?.property.location.coordinates[0] as number;
          const lng = folderDatas.result?.property.location.coordinates[1] as number;

          // pricehubble requests
          const phPromise = Promise.all([
            // agency, agent
            fetchAgencyAndAgentDatas({
              entityId,
              phAccessToken,
              callback: updateLoaderStep,
            }),
            // accessibility (REPLACE body when SB have data) (x4)
            Promise.all(
              Object.keys(AccessibilityModeEnum).map((m, i) =>
                fetchAccessibilityDatas({
                  index: i + 1,
                  entityId,
                  mode: m,
                  lat,
                  lng,
                  department,
                  phAccessToken,
                  callback: updateLoaderStep,
                })
              )
            ),
            // transports (REPLACE body when SB have data) (x6)
            Promise.all(
              Object.keys(TransportModeEnum).map((m, i) =>
                fetchTransportsDatas({
                  index: i + 1,
                  entityId,
                  lat,
                  lng,
                  subcategory: m,
                  phAccessToken,
                  callback: updateLoaderStep,
                })
              )
            ),
            // noise (REPLACE body when SB have data)
            fetchNoiseDatas({
              entityId,
              lat,
              lng,
              phAccessToken,
              callback: updateLoaderStep,
            }),
            // builtPermits (REPLACE body when SB have data)
            fetchBuiltPermisDatas({
              entityId,
              lat,
              lng,
              department,
              phAccessToken,
              callback: updateLoaderStep,
            }),
            // socioEconomic (REPLACE body when SB have data)
            Promise.all(
              Object.keys(SocioEconomicModeEnum).map((m, i) =>
                fetchSocioEconomicDatas({
                  index: i + 1,
                  entityId,
                  insee,
                  dataType: m,
                  phAccessToken,
                  callback: updateLoaderStep,
                })
              )
            ),
          ]);

          //general requests
          const datasPromise = Promise.all([phPromise]);
          datasPromise.then(
            (res) => {
              if (res[0]) {
                thunkOptions.dispatch(
                  externalPdfErrialActions.setPricehubbleDatas({
                    agencyDatas: res[0][0],
                    // deal: res[0][1],
                    accessibilities: res[0][1],
                    transport: res[0][2],
                    noise: res[0][3],
                    builtPermits: res[0][4],
                    socioEconomic: res[0][5],
                  })
                );
              }
            },
            (err) => {
              console.log('datasPromise', err);
            }
          );
        }
      }
    } catch (error) {
      return Promise.reject(InternalError(error));
    }
  }
);

export default landPdfRequestLauncherThunk;
