import ExpandIcon from '../icons/ExpandIcon';
import styles from './accordion.module.scss';
import { useAccordionContext } from './AccordionContextProvider';

interface IAccordionElementProps {
  id: string;
  title: React.ReactNode;
  content: React.ReactNode;
  disabled?: boolean;
}
function AccordionElement({ id, title, content, disabled }: IAccordionElementProps) {
  const { state, update } = useAccordionContext();

  const handleUpdate = () => {
    if (!disabled) {
      update(state === id ? null : id);
    }
  };
  return (
    <section className={styles.accordionElement}>
      <h3
        onClick={handleUpdate}
        className={[
          disabled ? styles.disabled : '',
          state === id ? styles.selected : '',
        ].join('')}
      >
        <div className={styles.title}>{title}</div>
        <div className={styles.iconContainer}>
          <ExpandIcon className={styles.expandIcon} width={20} height={20} />
        </div>
      </h3>
      {state === id && <div className={styles.content}>{content}</div>}
    </section>
  );
}

export default AccordionElement;
