import { StyleSheet, View } from '@react-pdf/renderer';
import Avatar from './svg/Avatar';

function AvatarComponent({ width, height }: { width: number; height: number }) {
  return (
    <View style={[styles.avatarContainer, { height, width }]}>
      <Avatar width={width - 15} height={height - 15} />
    </View>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    backgroundColor: '#CCC',
    width: '50px',
    height: '50px',
  },
});
export default AvatarComponent;
