/**
 * Add a new paragraph to parsedDatas (array mutation)
 * @param parsed
 */
export function startNewParagraph(parsed: ParsedLetter) {
  parsed.paragraphs.push({
    textRuns: [],
  });
}

/**
 * Split string into array with '/n' splitter
 * @param string - value to split
 * @returns {string[]} array of line text
 * @description splits strings on every /n - keeping the newline characters
 */
export const splitStrings = (string: string): string[] => string.split(/(\n)/);
