import { isEmpty } from 'lodash';
import RedDownloadButton from '../../../shared/components/buttons/redDownloadButton';
import { exportExcel } from '../../../utils/exportExcel';

interface IDownloadDatabuttonProps {
  text: string;
  className?: string;
  data: BuildPermits;
}

function DownloadBuildPermitsButton({
  text,
  className = '',
  data,
}: IDownloadDatabuttonProps): JSX.Element {
  const worksheetColumns = [
    { header: 'parcelle Id', key: 'plotId' },
    { header: 'address postal', key: 'postalAddress' },
    { header: 'autorisation type', key: 'autorisationType' },
    { header: 'autorisation numéro', key: 'autorisationNumero' },
    { header: 'autorisation date', key: 'autorisationDate' },
    { header: 'autorisation date trie', key: 'autorisationDateSort' },
    { header: 'état', key: 'state' },
    { header: 'date de réalisation', key: 'workAchievmentDate' },
    { header: 'applicant', key: 'applicant' },
    { header: 'applicant Siren', key: 'applicantSiren' },
    { header: 'typology', key: 'typology' },
    { header: 'surface', key: 'surface' },
    { header: 'total Lots', key: 'totalLots' },
    { header: 'total Social Lots', key: 'totalSocialLots' },
    { header: 'niveaux', key: 'levels' },
    { header: 'longitude', key: 'lng' },
    { header: 'latitude', key: 'lat' },
  ];
  const worksheetRow = data;

  return (
    <RedDownloadButton
      handleClick={() => exportExcel('data', worksheetColumns, worksheetRow ?? [])}
      alt="Dowload Permits"
    >
      <p>{isEmpty(data) ? 'Chargement ...' : text}</p>
    </RedDownloadButton>
  );
}

export default DownloadBuildPermitsButton;
