import { AccordionContextProvider } from './AccordionContextProvider';
import styles from './accordion.module.scss';

interface AccordeonContainer extends IChildren {
  className?: string;
}
function AccordionContainer({ children, className }: AccordeonContainer) {
  return (
    <AccordionContextProvider>
      <div className={[styles.accordionContainer, className].join(' ')}>
        {children}
      </div>
    </AccordionContextProvider>
  );
}

export default AccordionContainer;
