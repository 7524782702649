import { isEmpty } from 'lodash';
import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { updateEntity } from '../../../services/axiosFiles/genericCrud';
import { genericStudyParser } from '../utils/parsers/studyParser';

async function updateStudy(
  idIri: string | null,
  body: { [x: string]: string | number },
  urlQuery: QueryParams | null,
  folders: Folders | null,
  users: Users | null,
  statuses: StudyStatuses | null,
  sectors: Sectors | null,
  noReturn?: boolean
) {
  try {
    if (idIri && body && !isEmpty(body)) {
      const result = await updateEntity({
        idIri,
        body,
        params: urlQuery ?? undefined,
      });

      if (noReturn) {
        return null;
      }
      if (folders && users && statuses && sectors) {
        const parsed = genericStudyParser(result, users, folders, statuses, sectors);

        return parsed;
      } else {
        return null;
      }
    } else {
      return Promise.reject(paramsNotFound('updateStudy'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default updateStudy;
