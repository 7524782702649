import { fetchDataById, updateEntity } from '../../services/axiosFiles/genericCrud';
import { userApiToStoreParser } from './usersParser';

export async function fetchUserById(userIdIri: string) {
  return new Promise<User>((resolve, reject) => {
    const result = fetchDataById(userIdIri);

    return result.then(
      (response: any) => {
        try {
          // parse datas
          const parsedUser = userApiToStoreParser(response);

          return resolve(parsedUser);
        } catch (error) {
          return reject(error);
        }
      },
      (err) => {
        return reject(err);
      }
    );
  });
}

export async function userUpdate(userIdIri: string, user: Partial<User>) {
  return new Promise<User>((resolve, reject) => {
    const result = updateEntity({ idIri: userIdIri, body: user });

    result.then(
      (response: any) => {
        try {
          // parse datas
          const parsedUser = userApiToStoreParser(response);

          return resolve(parsedUser);
        } catch (error) {
          return reject(error);
        }
      },
      (err) => {
        return reject(err);
      }
    );
  });
}
