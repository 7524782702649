import { paramsNotFound } from '../../../services/axiosFiles/axiosUtils';
import { updateEntity } from '../../../services/axiosFiles/genericCrud';
import { studyMatrixApiToStoreParser } from '../utils/parsers/studyMatrixParser';

async function updateFeasibilityMatrix(datas: IFeasibilityMatrix) {
  try {
    if (datas && datas.idIri) {
      const idIri = datas.idIri;
      const body: any = { ...datas };
      delete body.idIri;
      const result = await updateEntity({ idIri, body });

      const parsed = studyMatrixApiToStoreParser(result);

      return parsed;
    } else {
      return Promise.reject(paramsNotFound('updateFeasibilityMatrix'));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default updateFeasibilityMatrix;
