import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './noDatasError.module.scss';
function ErrorNoDatas() {
  return (
    <div className={styles.noDatasError}>
      <FontAwesomeIcon icon={faXmark} className={styles.crossIcon} />

      <p>
        Erreur lors du chargement <br />
        des données
      </p>
    </div>
  );
}

export default ErrorNoDatas;
