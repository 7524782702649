import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { isEmpty } from 'lodash';
import { fontStyles } from '../styles/pdfStyles';
import ImageContainer from './ImageContainer';

const styles = StyleSheet.create({
  chart: {
    width: '47%',
  },
  chartTitle: {
    marginBottom: '10px',
    ...fontStyles.b700,
  },
  barContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '4px',
    height: '5px',
    width: '100%',
    borderRadius: '25px',
    backgroundColor: '#f2f2f2',
  },
  barLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '6px',
  },
  barLabel: {
    ...fontStyles.fs10,
  },
  bar: {
    height: '100%',
    backgroundColor: 'rgb(6, 106, 190)',
    borderRadius: '25px',
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '10px',
    ...fontStyles.fs10,
  },
  legendColor: {
    width: '10px',
    height: '10px',
    borderRadius: '25px',
    backgroundColor: 'rgb(6, 106, 190)',
    marginRight: '5px',
  },
});

const Bar = ({
  label,
  percentage,
  highestPercentage,
}: {
  label: string;
  percentage: number;
  highestPercentage: number;
}) => (
  <View>
    <View style={styles.barLabelContainer}>
      <Text style={styles.barLabel}>{label}</Text>
      <Text style={styles.barLabel}>{percentage}%</Text>
    </View>
    <View style={styles.barContainer}>
      <View
        style={[styles.bar, { width: `${(percentage / highestPercentage) * 100}%` }]}
      />
    </View>
  </View>
);

function HorizontalBarChart({
  data,
  title,
  city,
}: {
  data: {
    label: string;
    percentage: number;
  }[];
  title: string;
  city: string;
}) {
  const highestPercentage = data.reduce((maxWidth, bar) => {
    return bar.percentage > maxWidth ? bar.percentage : maxWidth;
  }, 0);

  return (
    <View style={styles.chart}>
      <Text style={styles.chartTitle}>{title}</Text>
      <View>
        {isEmpty(data) ? (
          <ImageContainer localImg="" width="275px" height="150px" />
        ) : (
          data.map((bar, i) => (
            <Bar key={i} {...bar} highestPercentage={highestPercentage} />
          ))
        )}
      </View>

      <View style={styles.legend}>
        <View style={styles.legendColor} />
        <Text>{city} (Commune)</Text>
      </View>
    </View>
  );
}

export default HorizontalBarChart;
