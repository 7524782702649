import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import { useState } from 'react';
import usePloStudyStatus from '../../../App/hooks/usePlotStudyStatus';

import AgendaTableBody from '../../../features/dashboardAgenda/AgendaTableBody';
import FilterSortBloc from '../../filterSortBloc';
import useProcedures from '../../../features/procedures/useProcedures';
import './agendaTab.scss';

const headerCell = [
  { title: 'Date', rowSpan: 2 },
  { title: 'Opportunité', rowSpan: 2 },
  { title: 'Ville', rowSpan: 2 },
  { title: 'Contact', rowSpan: 2 },
  { title: 'Action', rowSpan: 2 },
  { title: 'Statut', rowSpan: 2 },
  { title: '', rowSpan: 2 },
];

function AgendaTab(): JSX.Element {
  const { procedures } = useProcedures();
  usePloStudyStatus();
  const [searchPattern, setSearchPattern] = useState<string>('');
  const [sortKey, setSortKey] = useState<string>('eventDate');
  const [order, setOrder] = useState<Order>('asc');

  return (
    <div className="tab-agenda">
      <div className="tab-inner">
        <h1>Agenda</h1>
        <FilterSortBloc
          setSearchPattern={setSearchPattern}
          setSortKey={setSortKey}
          setOrder={setOrder}
          selectItems={[
            { value: 'eventDate', display: 'Date' },
            { value: 'city', display: 'Ville' },
            { value: 'type', display: 'Action' },
            { value: 'status', display: 'Statut' },
          ]}
          selectPlaceHolder="Trier rendez-vous par"
        />
      </div>
      <div className="tab-body">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {headerCell.map((elt) => (
                  <TableCell key={nanoid()}>{elt.title}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <AgendaTableBody
              procedures={procedures.result ? [...procedures.result] : []}
              order={order}
              sortKey={sortKey}
              pattern={searchPattern}
            />
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default AgendaTab;
