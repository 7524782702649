export const measureOptions = {
  class: 'measure-layer',
  position: 'topright',
  primaryLengthUnit: 'meters',
  secondaryLengthUnit: 'kilometers',
  primaryAreaUnit: 'sqmeters',
  secondaryAreaUnit: 'sqmKilometer',
  activeColor: '#db4a29',
  completedColor: '#9b2d14',
  captureZIndex: 10000,
  units: {
    sqmKilometer: {
      factor: 0.000001, // Required. Factor to apply when converting to this unit. Length in meters or area in sq meters will be multiplied by this factor.
      display: 'km²', // Required. How to display in results, like.. "300 Meters (0.3 My New Unit)".
      decimals: 4, // Number of decimals to round results when using this unit. `0` is the default value if not specified.
    },
  },
  popupOptions: {
    className: 'leaflet-measure-resultpopup',
  },
};
