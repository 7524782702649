import { faDownload, faSheetPlastic } from '@fortawesome/free-solid-svg-icons'; //faSheetPlastic
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './redDownloadButton.module.scss';
interface IRedDownloadButton {
  children?: React.ReactNode;
  alt: string;
  link?: boolean;
  className?: string;
  handleClick: () => void;
}

function RedDownloadButton({
  children,
  handleClick,
  alt,
  className,
  link,
}: IRedDownloadButton) {
  return (
    <button
      className={`${styles.redDownloadButton} ${className ?? ''}  `}
      onClick={handleClick}
    >
      {link ? (
        <FontAwesomeIcon icon={faSheetPlastic} />
      ) : (
        <FontAwesomeIcon icon={faDownload} />
      )}
      <div>{children}</div>
    </button>
  );
}

export default RedDownloadButton;
