import { nanoid } from '@reduxjs/toolkit';
import { useContext, useState } from 'react';
import styles from '../dpe.module.scss';
import { GenericPopupContext } from './DpeMarkerPopupContainer';
import DpePopupTabContent from './DpePopupTabContent';

interface IMarkerPopupGenericTabsProps {
  tabs: DpePopupTabs;
}

function DpePopupTabs({ tabs }: IMarkerPopupGenericTabsProps) {
  const { state, update } = useContext(GenericPopupContext);
  const [startIndexTab, setStartIndexTab] = useState(0);
  const [parsedTab] = useState(
    tabs?.map((f, i) => i + '_' + f.tab + '_' + f.dpeClass)
  );

  const handleRollTab = (value: number) => {
    const v = startIndexTab + value;

    if (v >= 0 && v < parsedTab.length - 2) {
      setStartIndexTab(startIndexTab + value);
    }
  };

  return (
    <div className={styles.header}>
      {parsedTab.length > 3 && (
        <div
          className={`${styles.buttons} ${
            startIndexTab === 0 ? styles.disabled : ''
          }`}
          // disabled={startIndexTab === 0}
          onClick={() => handleRollTab(-1)}
        >
          {'<'}
        </div>
      )}

      <div className={styles.tabContents}>
        {parsedTab
          .filter((f, i) => i >= startIndexTab && i < startIndexTab + 3)
          .map((t) => (
            <DpePopupTabContent
              key={nanoid()}
              content={t}
              parsedTab={parsedTab}
              state={state}
              update={update}
            />
          ))}
      </div>

      {parsedTab.length > 3 && (
        <div
          className={`${styles.buttons} ${
            startIndexTab === parsedTab.length - 3 ? styles.disabled : ''
          }`}
          // disabled={startIndexTab === parsedTab.length - 3}
          onClick={() => handleRollTab(1)}
        >
          {'>'}
        </div>
      )}
    </div>
  );
}

export default DpePopupTabs;
