import getIdFromIdIri from '../../lib/parsersTools/getIdFromIdIri';
import { APIStatus } from '../../services/axiosFiles/apiTypes';

export const companyApiToStoreParser = (data: any): Company | null => {
  try {
    const {
      name,
      address,
      city,
      country,
      firm,
      phone,
      siret,
      zipCode,
      subscriptions,
      userLimit,
    } = data;

    let subscription: Subscription | undefined = undefined;
    if (subscriptions) {
      const c = subscriptions[0];
      const { hasDomMapAccess, hasFullMapAccess, pricePerMonth, startAt, endAt } = c;

      subscription = {
        idIri: c['@id'],
        id: getIdFromIdIri(c['@id']),
        startAt,
        endAt,
        hasDomMapAccess,
        hasFullMapAccess,
        pricePerMonth,
        cities: { apiStatus: APIStatus.IDLE, result: null },
        cityCount: 0,
        departments: { apiStatus: APIStatus.IDLE, result: null },
        departmentCount: 0,
      };
    }

    const company: Company = {
      idIri: data['@id'],
      id: getIdFromIdIri(data['@id']),
      name,
      address,
      postalCode: zipCode,
      city,
      country,
      firm: firm?.name ?? null,
      phone,
      siret,
      subscriptionIdIri: subscription?.idIri,
      subscription,
      userLimit,
      logoUrl: data.logoUrl ?? null,
    };

    return company;
  } catch (error) {
    return null;
  }
};
