import { useAppDispatch } from '../../../../../App/store';
import FormButtonContainer from '../../../../../shared/components/buttons/formButtonContainer/FormButtonContainer';
import CheckIcon from '../../../../../shared/components/icons/CheckIcon';
import createStudyProceduresFromMailshotsThunk from '../../../../study/services/thunks/createStudyProceduresFromMailshotsThunk';

import { HydratedDatasType } from '../../../hooks/useMailshotHydrate';
import useMailshotsSelection from '../../../hooks/useMailshotsSelection';
import styles from '../../../styles/mailshotsModal.module.scss';

export interface IMailshotResumeSuccessContentProps {
  datas: HydratedDatasType;
}

function MailshotResumeSuccessContent({
  datas,
}: IMailshotResumeSuccessContentProps) {
  const { resetSelection } = useMailshotsSelection();
  const dispatch = useAppDispatch();

  const isPlots = Boolean(datas?.[0].plot);

  const onValid = () => {
    dispatch(createStudyProceduresFromMailshotsThunk({ datas, isPlots }));
  };
  const onCancel = () => {
    resetSelection();
  };

  return (
    <div className={styles.mailshotResumeSuccessContent}>
      <div className={styles.body}>
        <section>
          <CheckIcon
            bgColor="SUCCESS"
            isRoundIcon
            className={styles.checkIcon}
            width={50}
            height={50}
            noTooltip
          />
          <p>L&lsquo;édition du document word c&lsquo;est executée avec succès</p>
        </section>

        <p>
          Voulez vous créer une démarche pour{' '}
          {isPlots ? 'chaque parcelle concernée' : 'le groupe de parcelles concerné'}{' '}
          par le courrier édité?
        </p>
      </div>
      <FormButtonContainer
        validContent="Oui"
        cancelContent="Non"
        canCancel
        onCancel={onCancel}
        onValid={onValid}
      />
    </div>
  );
}

export default MailshotResumeSuccessContent;
