import { fetchDataById } from '../../../services/axiosFiles/genericCrud';
import { classicErrorMessage } from '../../../shared/errorMessages';
import { companyUserApiToStoreParser } from '../usersParser';

/**
 * fetch user datas for this company
 * @param {number} userId  - user id
 * @param {number} companyId - compny id
 * @returns {CompanyUser} - roles and isActive value for this user in that company
 */
async function companyUserFetch(userId: number, companyId: number) {
  try {
    if (!userId) throw new Error(classicErrorMessage.noUserId);
    if (!companyId) throw new Error(classicErrorMessage.noCompanyId);

    const url = `/company_users/company=${companyId};user=${userId}`;
    const response = await fetchDataById(url);

    const parsed = companyUserApiToStoreParser(response);
    return parsed;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default companyUserFetch;
