const getCookie = (name: string) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      const cookieValue = cookie.substring(name.length + 1, cookie.length);
      return JSON.parse(decodeURIComponent(cookieValue));
    }
  }
  return null;
};

export default getCookie;
