import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import store from './App/store';

import App from './App/App';
import './theme/base.scss';
import muiTheme from './theme/muiTheme';

const app = document.getElementById('root');

// create a root
const root = createRoot(app as HTMLElement);

if ((window as any).Cypress) {
  (window as any).store = store;
}
//render app to root
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={muiTheme}>
          <App />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </BrowserRouter>
);
