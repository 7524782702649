import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/store';

import updateContact from '../../../contacts/services/updateContact';
import updateStudyContact from '../updateStudyContact';

const updateStudyContactThunk = createAsyncThunk(
  'study/updateStudyContactThunk',
  async (
    params: { body: StudyContactForm; studyContactToUpdate: IStudyContact | null },
    thunkOptions
  ) => {
    try {
      const { body, studyContactToUpdate } = params;
      const state = thunkOptions.getState() as RootState;
      const contactRoles = state.app.contactRoles.result;
      const { idIri, type } = state.studyParams;

      await updateContact(studyContactToUpdate?.contact.idIri ?? null, body);

      const studyContactResult = await updateStudyContact({
        body,
        studyContactToUpdate,
        contactRoles,
        type,
        studyIdIri: idIri,
      });

      return studyContactResult;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default updateStudyContactThunk;
