import { paramsNotFound } from '../../../../services/axiosFiles/axiosUtils';
import { fetchDatasNew } from '../../../../services/axiosFiles/genericCrud';
async function commonFetch({
  url,
  plotGeometry,
}: {
  plotGeometry: string;
  url: string;
  parseForPDF?: boolean;
}) {
  try {
    if (url && plotGeometry) {
      const result = await fetchDatasNew({
        endpoint: url,
        config: {
          params: { geom: plotGeometry },
          timeout: 20000,
        },
      });

      return result;
    } else {
      return Promise.reject(paramsNotFound(url));
    }
  } catch (error) {
    return Promise.reject(error);
  }
}

export default commonFetch;
