import { isEmpty } from 'lodash';
import ButtonGeneric from '../../../shared/components/buttons/buttonGeneric/ButtonGeneric';
import MailshotIcon from '../../../shared/components/icons/MailshotIcon';
import useMailshotsModels from '../hooks/useMailshotsModels';
import useMailshotsSelection from '../hooks/useMailshotsSelection';
import styles from '../styles/mailshots.module.scss';
import { MailshotsSelectionTypeEnum } from '../types/mailshotsEnums';
import LauncherTooltipText from './LauncherTooltipText';
import MailshotDragWindow from './mailshotDragWindow/MailshotDragWindow';
import MailshotSelectionResumeModal from './modals/mailshotSelectionResumeModal/MailshotSelectionResumeModal';

interface IMailshotLauncherBtnProps {
  type: MailshotsSelectionTypeEnum;
  withRightMargin?: boolean;
  studyContacts?: StudyContacts | null;

  isFlexEnd?: boolean;
}

function MailshotLauncherBtn({
  type,
  withRightMargin,
  studyContacts = null,
  isFlexEnd,
}: IMailshotLauncherBtnProps) {
  const { models } = useMailshotsModels({ load: true });
  const { selectStaged, initListAndContacts, dragWindowDisplay, selectType } =
    useMailshotsSelection();

  const isDashboardFolder =
    selectType === MailshotsSelectionTypeEnum.DASHBOARD_FOLDER;

  const hasNoUseableModel = isEmpty(
    models?.filter(
      (m) =>
        m.isActive &&
        ((m.modelType === 'subfolder' &&
          type === MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER) ||
          (m.modelType === 'plot' &&
            (type === MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_PLOT ||
              type === MailshotsSelectionTypeEnum.DASHBOARD_FOLDER)))
    )
  );
  return (
    <>
      {!selectStaged && !dragWindowDisplay && (
        <ButtonGeneric
          containerClassName={[
            styles.launcherBtn,
            isDashboardFolder ? styles.marginBottom : '',
            withRightMargin ? styles.withRightMargin : '',
            isFlexEnd ? styles.flexEnd : '',
          ].join(' ')}
          className={[styles.mailshotBtn].join(' ')}
          onClick={() => initListAndContacts(type, studyContacts)}
          disabled={hasNoUseableModel}
          content={
            <div className={styles.buttonContent}>
              <p>Publipostage</p>
              <MailshotIcon noPadding bgColor="TRANSPARENT" />
            </div>
          }
          tooltipText={!models ? <LauncherTooltipText /> : ''}
        />
      )}

      {dragWindowDisplay && !selectStaged && <MailshotDragWindow />}

      {selectStaged && <MailshotSelectionResumeModal />}
    </>
  );
}

export default MailshotLauncherBtn;
