import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { getAuthState } from '../../auth/authSlice';
import { getMailshotsState, mailshotsActions } from '../mailshotsSlice';
import createMailshotModelThunk from '../services/thunks/createMailshotModelThunk';
import deleteMailshotModelThunk from '../services/thunks/deleteMailshotModelThunk';
import fetchMailshotModelsThunk from '../services/thunks/fetchMailshotModelsThunk';
import updateMailshotsModelsThunk from '../services/thunks/updateMailshotsModelThunk';

interface IUseMailshotsModelsProps {
  load?: boolean;
}
function useMailshotsModels({ load }: IUseMailshotsModelsProps) {
  const { isLogged } = useAppSelector(getAuthState);
  const {
    models,
    actionModalDatas,
    apiActionStatus,
    selectedModel,
    selectType,
    updatingQuill,
    quillDatasBeforeNavigation,
    mailshotModelTabIndex,
  } = useAppSelector(getMailshotsState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isLogged && load && !models.result && models.apiStatus === APIStatus.IDLE) {
      dispatch(fetchMailshotModelsThunk());
    }
  }, [models, isLogged]);

  const dispatchMailshotsModal = (
    selectedModel: MailshotModelType | null,
    entity: IGenericTabsLabelType | null,
    type: ActionModalActionType,
    force: boolean = false
  ) => {
    if (!updatingQuill || force) {
      if (type === 'create') {
        dispatch(mailshotsActions.setModalData({ type, model: null }));
      } else if (entity && selectedModel && entity?.id === selectedModel?.id) {
        dispatch(mailshotsActions.setModalData({ type, model: selectedModel }));
      }
    } else {
      dispatch(
        mailshotsActions.setQuillDatasBeforeNavigation({
          actionTab: { selectedModel, entity, action: type },
          content: null,
        })
      );
    }
  };

  const modelsForGenericTabs = (): GenericTabsLabelsType =>
    models.result?.map((m) => ({
      ...m,
      canDelete: !m.isDefault,
      canUpdate: !m.isDefault,
    })) ?? [];

  const createModel = (model: CreateMailshotModelType) =>
    dispatch(createMailshotModelThunk({ model }));

  const updateModel = (model: UpdateMailshotModelType) =>
    dispatch(updateMailshotsModelsThunk({ model }));

  const deleteModel = (model: MailshotModelType | null) =>
    dispatch(deleteMailshotModelThunk({ model }));
  const resetActionData = () => {
    dispatch(mailshotsActions.setModalData(null));
  };
  const setSelectedModel = (model: MailshotModelType | null) =>
    dispatch(mailshotsActions.setSelectedModel(model));

  return {
    models: models.result,
    modelsApiStatus: models.apiStatus,
    apiActionStatus,
    actionModalDatas,
    dispatchMailshotsModal,
    createModel,
    updateModel,
    deleteModel,
    selectedModel,
    setSelectedModel,
    modelsForGenericTabs,
    resetActionData,
    selectType,
    updatingQuill,
    quillDatasBeforeNavigation,
    mailshotModelTabIndex,
  };
}

export default useMailshotsModels;
