import { useAppDispatch } from '../../../App/store';
import { modalsActions } from '../../../features/modals/modalsSlice';
import { procedureActions } from '../../../features/procedures/proceduresSlice';
import TrashIcon from './TrashIcon';
import styles from './icons.module.scss';

interface IProcedureDeleteIconProps {
  procedure: Procedure;
}

function ProcedureDeleteIcon({ procedure }: IProcedureDeleteIconProps) {
  const dispatch = useAppDispatch();

  const handleDeleteProcedure = () => {
    dispatch(procedureActions.procedureForActionSet(procedure));
    dispatch(modalsActions.procedureDelete(true));
  };

  return (
    <TrashIcon
      onClick={() => handleDeleteProcedure()}
      height={32}
      width={32}
      tooltipText="Supprimer la procédure"
      className={styles.procedureDeleteIcon}
    />
  );
}

export default ProcedureDeleteIcon;
