import { fetchDatas } from '../../../services/axiosFiles/genericCrud';
import { plotStudyParser } from '../utils/parsers/studyParser';

export const checkForExistingPlotStudy = async (
  fullPlotId: string,
  companyIdIri: string | null,
  users: Users | null,
  statuses: StudyStatuses | null,
  folders: Folders
) => {
  try {
    if (fullPlotId && companyIdIri && users && statuses && folders) {
      const result: any = await fetchDatas(
        `${companyIdIri}/check_existing_plot_study`,
        {
          uniquePlotId: fullPlotId,
        }
      );

      if (result['hydra:member'] && result['hydra:member'][0]) {
        const parsed = plotStudyParser(
          result['hydra:member'][0],
          users,
          statuses,
          folders
        );
        return parsed;
      } else if (result.uniquePlotId) {
        // if exists, parse it and add it to store
        const parsed = plotStudyParser(result, users, statuses, folders);
        return parsed;
      } else {
        return null;
      }
    } else {
      return Promise.reject({
        status: 0,
        message: 'fullPlotId or companyIdIri are missing',
      });
    }
  } catch (error: any) {
    if (error.status === 404) {
      return null;
    }

    return Promise.reject(error);
  }
};
