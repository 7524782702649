import styles from './markerTooltip.module.scss';

function MarkerBaseTooltip() {
  return (
    <p className={styles.markerBaseTooltipContent}>
      Double-cliquez sur la parcelle <br />
      pour afficher les infos
    </p>
  );
}

export default MarkerBaseTooltip;
