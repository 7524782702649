type Entity = Pick<
  IFolderSector<Entity>,
  'idIri' | 'indexId' | 'parent' | 'subs' | 'subCount'
>;
type Entities = Entity[];

interface IEntityOrSubEntityProps<T> {
  entities: Entities | null;
  entity: T;
}

export const addEntityOrSubEntity = ({
  entities,
  entity,
}: IEntityOrSubEntityProps<Entity>): Entities => {
  if (!entities) {
    return [entity];
  }

  const tab = [...entities];
  return entity.parent
    ? tab.map((m) =>
        m.idIri === entity.parent
          ? {
              ...m,
              subs: m.subs ? m.subs.concat(entity) : [entity],
              subCount: m.subCount + 1,
            }
          : m
      )
    : tab.concat({ ...entity });
};

export const updateEntityOrSubEntity = ({
  entities,
  entity,
}: IEntityOrSubEntityProps<Entity>): Entities => {
  if (!entities) {
    return [entity];
  }

  const tab = [...entities];
  return entity.parent
    ? tab.map((m) =>
        m.idIri === entity.parent && m.subs
          ? {
              ...m,
              subs: m.subs.map((s) =>
                s.idIri === entity.idIri ? { ...entity, indexId: s.indexId } : s
              ),
            }
          : m
      )
    : tab.map((m) =>
        m.idIri === entity.idIri
          ? { ...entity, indexId: m.indexId, subs: m.subs }
          : m
      );
};

export const deleteEntityOrSubEntity = ({
  entities,
  entity,
}: IEntityOrSubEntityProps<Entity>) => {
  if (!entities) {
    return null;
  }

  const createNewTab = (tab: Entity[]) =>
    tab
      .filter((f) => f.idIri !== entity.idIri)
      .map((m, i) => ({
        ...m,
        indexId: i + 1,
      }));

  return !entity.parent
    ? createNewTab(entities)
    : entities.map((m) =>
        m.idIri === entity.parent && m.subs
          ? { ...m, subs: createNewTab(m.subs), subCount: m.subCount - 1 }
          : m
      );
};
