import { ChangeEvent, useState } from 'react';

import CheckboxFormCustom from '../../../../../shared/components/formComponents/CheckboxFormCustom';
import TickSquare from '../tickSquare/TickSquare';
import styles from './formComponents.module.scss';

interface IProps {
  name: string;
  label: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}
function CheckboxFormPdfErrialCustom({ name, label }: IProps) {
  const [checked, setChecked] = useState(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked;
    setChecked(value);
  };

  return (
    <div className={styles.checkboxCustom}>
      <CheckboxFormCustom
        name={name}
        onChange={handleChange}
        label={label}
        className={styles.checkbox}
      />
      <TickSquare color={checked ? 'Green' : 'Grey'} />
    </div>
  );
}

export default CheckboxFormPdfErrialCustom;
