import IconContainer from './IconContainer';

function WarningIcon(props: ISharedIconsProps) {
  const { tooltipText } = props;

  return (
    <IconContainer {...props} tooltipText={tooltipText}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M10 2 H14 V14 H10 Z" />
        <circle cx="12" cy="19" r="2.5" />
      </svg>
    </IconContainer>
  );
}

export default WarningIcon;
