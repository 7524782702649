import { G, Path, Svg } from '@react-pdf/renderer';

function Avatar({ width, height }: { width: number; height: number }) {
  return (
    <Svg width={width} height={height} viewBox="0 0 100 100">
      <G>
        <Path
          d="M50,10.221c-11.05,0-20.039,8.989-20.039,20.039S38.95,50.299,50,50.299S70.04,41.31,70.04,30.26S61.05,10.221,50,10.221   L50,10.221z"
          fill="#FFF"
        />
        <Path
          d="M49.999,55.055c-19.351,0-35.094,15.743-35.094,35.094h70.189C85.095,70.798,69.351,55.055,49.999,55.055z"
          fill="#FFF"
        />
      </G>
    </Svg>
  );
}

export default Avatar;
