import { postEntity } from '../../../services/axiosFiles/genericCrud';
import { studyContactApiToStoreParser } from '../utils/parsers/studyContactParser';

async function createStudyContact(
  baseUrl: string,
  contactIdIri: string | null,
  contactRoleIdIri: string | null,
  type: StudyParamsType | null,
  studyIdIri: string | null,
  contactRoles: ContactRoles | null
) {
  try {
    if (!baseUrl) throw new Error('No baseUrl found');
    if (!type) throw new Error('No studyType found');
    if (!studyIdIri) throw new Error('No studyIdIri found');
    if (!contactIdIri) throw new Error('No contact found');
    if (!contactRoleIdIri) throw new Error('No contactRoleIdIri found');
    if (!contactRoles) throw new Error('No contactRoles found');

    const studyContactResult = await postEntity({
      endpoint: baseUrl,
      body: {
        contact: contactIdIri,
        [type]: studyIdIri,
        contactRole: contactRoleIdIri,
      },
    });

    const parsedStudyContact = studyContactApiToStoreParser(
      studyContactResult,
      contactRoles
    );

    return parsedStudyContact;
  } catch (error) {
    return Promise.reject(error);
  }
}

export default createStudyContact;
