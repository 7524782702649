import { all, fork } from 'redux-saga/effects';

import {
  handleAddFavoritePlaces,
  handleDeleteFavoritePlaces,
  handleFetchFavoritePlaces,
} from '../../redux/favoritePlaces/saga';

import {
  handleFetchCityBankAccountsDatas,
  handleFetchPlotByPlotId,
  handleFetchPointConnected,
  handleFetchUsersPlots,
} from '../../redux/plot/saga';

export default function* rootSaga() {
  yield all([
    fork(handleFetchPlotByPlotId),
    fork(handleFetchPointConnected),
    fork(handleFetchUsersPlots),
    fork(handleFetchCityBankAccountsDatas),
    fork(handleFetchFavoritePlaces),
    fork(handleAddFavoritePlaces),
    fork(handleDeleteFavoritePlaces),
  ]);
}
