import { postEntityNew } from '../../../../services/axiosFiles/genericCrud';
import { parkingPackagesParser } from '../parsers/parkingPackageParser';

interface IProps {
  inseeCode: string;
}

/**
 * Fetch parking package datas from city insee code
 * @param {string} inseeCode - City insee code
 * @returns
 */
async function fetchParkingPackage({ inseeCode }: IProps): Promise<ParkingPackages> {
  try {
    const result = await postEntityNew({
      endpoint: '/get_taxes_forfait_stationnement/',
      body: { insee: inseeCode },
      hasPythonProxy: true,
    });

    const parsed = parkingPackagesParser(result);

    return parsed;
  } catch (error: any) {
    return Promise.reject(error);
  }
}

export default fetchParkingPackage;
