import { ReactComponent as TickSquareIcon } from '../../../../../assets/images/tick-square.svg';
import styles from './tickSquare.module.scss';
interface IProps {
  color: 'Green' | 'Grey';
}

function TickSquare({ color }: IProps) {
  let css = '';
  if (color === 'Green') css = styles.tickGreen;
  if (color === 'Grey') css = styles.tickGrey;

  return <TickSquareIcon className={css} />;
}

export default TickSquare;
