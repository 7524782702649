import IconContainer from './IconContainer';

function CheckIcon(props: ISharedIconsProps) {
  const { tooltipText } = props;

  return (
    <IconContainer {...props} tooltipText={tooltipText}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
      </svg>
    </IconContainer>
  );
}

export default CheckIcon;
