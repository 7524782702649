import { nanoid } from '@reduxjs/toolkit';

import { isEmpty } from 'lodash';
import { memo } from 'react';
import styles from '../styles/prospecForm.module.scss';
import SimilaryAdRow from './SimilaryAdRow';
interface IProps {
  selectedTab: SimilarTabType;
  contents: ProspecGetSimilars | null;
}

function SimilarAdsBody({ selectedTab, contents }: IProps) {
  const ads = contents ? contents[selectedTab.qtyKey]?.list ?? [] : [];

  return (
    <div className={styles.similaryAdsBody}>
      {isEmpty(ads) && (
        <p>
          Il n&apos;y a aucune annonce de bien similaire <br />
          dans cette catégorie
        </p>
      )}
      {ads?.map((m) => (
        <SimilaryAdRow key={nanoid()} row={m} adType={selectedTab.qtyKey} />
      ))}
    </div>
  );
}

export default memo(SimilarAdsBody);
