import { isEmpty } from 'lodash';
import { getZoneAndZoneChildString } from '../../../../../lib/formats/zoneAndZoneChildFormat';
import ContactsIcon from '../../../../../shared/components/icons/ContactsIcon';
import ExpandIcon from '../../../../../shared/components/icons/ExpandIcon';
import TrashIcon from '../../../../../shared/components/icons/TrashIcon';
import WarningIcon from '../../../../../shared/components/icons/WarningIcon';
import GenericTooltip from '../../../../../shared/components/tooltips/GenericTooltip';
import { useMailshotResumeContext } from '../../../contexts/mailshotResumeContext';
import useMailshotsSelection from '../../../hooks/useMailshotsSelection';
import styles from '../../../styles/mailshotsModal.module.scss';
import { MailshotsSelectionTypeEnum } from '../../../types/mailshotsEnums';
import ContactCountCell from './ContactCountCell';
import MailshotContactTable from './MailshotContactTable';
interface IMailshotPlotRowProps {
  row: MailshotPlot;
  index: number;
  folderContactsCount: number | null;
  hasDuplicateContact: boolean;
}

function MailshotPlotRow({
  row,
  index,
  folderContactsCount,
  hasDuplicateContact,
}: IMailshotPlotRowProps) {
  const { handleOpenPlot, isOpen, invalidAddressCount, openedPlot } =
    useMailshotResumeContext();
  const { removePlotFromList, selectType } = useMailshotsSelection();
  const c = row.contacts;

  const handleTrashAction = () => {
    removePlotFromList(row);

    if (openedPlot?.idIri === row.idIri) {
      handleOpenPlot(row);
    }
  };

  const inRange =
    folderContactsCount === null || folderContactsCount === 0
      ? index < 10
      : Math.floor((index + 1) * (folderContactsCount ?? 0)) <= 10;

  return (
    <>
      <tr
        className={[styles.mailshotPlotRow, inRange ? '' : styles.outOfRange].join(
          ' '
        )}
      >
        <td>
          {hasDuplicateContact && (
            <WarningIcon
              bgColor="ORANGE"
              color="WHITE"
              tooltipBgColor="ORANGE"
              tooltipColor="WHITE"
              isRoundIcon
              width={16}
              height={16}
              className={styles.warningIcon}
              tooltipText={
                <p className={styles.warningIconTooltip}>
                  Cette parcelle contient un ou plusieurs contacts <br />
                  utilisés dans d&lsquo;autres parcelles de la selection
                </p>
              }
            />
          )}
        </td>
        <td>
          <span className={styles.index}>{index + 1}</span>
        </td>
        <td>{row.plotId}</td>
        <td>{row.area.toString()} m²</td>
        <td>
          {getZoneAndZoneChildString({ zone: row.zone, zoneChild: row.zoneChild })}
        </td>
        <td>{row.parent?.name}</td>

        <ContactCountCell
          folderContactsCount={folderContactsCount}
          contactCount={row.contacts.list.length}
          index={index}
          invalidCount={invalidAddressCount(row.contacts.list)}
          hasDuplicate={hasDuplicateContact}
        />

        <td className={styles.iconCell}>
          <TrashIcon
            height={18}
            width={18}
            tooltipText={"Retirer cette parcelle de l'édition"}
            color="RED"
            bgColor="WHITE"
            tooltipBgColor="RED"
            className={styles.iconInCell}
            onClick={handleTrashAction}
          />
        </td>
        {selectType !==
          MailshotsSelectionTypeEnum.RIGHT_PANEL_PROSPECTION_SUBFOLDER && (
          <td className={styles.iconCell}>
            {!isEmpty(c) && (
              <GenericTooltip
                text={!isOpen(row) ? 'Voir contacts' : 'Cacher contacts'}
              >
                <div
                  className={styles.contactIconContainer}
                  onClick={() => handleOpenPlot(row)}
                >
                  <ContactsIcon
                    height={18}
                    width={18}
                    color="PRIMARY"
                    bgColor="WHITE"
                    className={[styles.iconInCell].join(' ')}
                  />
                  <ExpandIcon
                    height={18}
                    width={18}
                    color={isOpen(row) ? 'GREEN' : 'PRIMARY'}
                    bgColor="WHITE"
                    className={[styles.iconInCell].join(' ')}
                    isOpen={isOpen(row)}
                  />
                </div>
              </GenericTooltip>
            )}
          </td>
        )}
      </tr>
      {isOpen(row) && (
        <tr className={styles.mailshotPlotRowContactTable}>
          <td colSpan={9}>
            <MailshotContactTable />
          </td>
        </tr>
      )}
    </>
  );
}

export default MailshotPlotRow;
