import { TextField } from '@mui/material';
import { ChangeEvent, useEffect } from 'react';
import styles from './buildPermitsLegend.module.scss';
import { buildPermitsActions, getBuildPermitsState } from '../buildPermitsSlice';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { validationSchemaHouseForm, initialForm } from './formValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import NumericFormatFormCustom from '../../../components/Common/FormComponents/NumericFormatFormCustom';

function HousingFilter() {
  const dispatch = useAppDispatch();
  const { housingFilter } = useAppSelector(getBuildPermitsState);

  const {
    control,
    trigger,
    formState: { errors },
  } = useForm<IBuildPermitsSearchForm>({
    defaultValues: initialForm,
    resolver: yupResolver(validationSchemaHouseForm),
  });

  const handleChange = (floatValue: number, key: keyof HousingFilterType) => {
    const result = {
      ...housingFilter,
      [key]: floatValue ? floatValue : null,
    };
    dispatch(buildPermitsActions.setHouseFilter(result));
  };

  return (
    <div className={styles.housingFilterContainer}>
      <p>Nombre de logements autorisés</p>
      <div className={styles.housingFilter}>
        <span>mini</span>
        <NumericFormatFormCustom
          control={control}
          name="houseMin"
          onChange={(values: number) => handleChange(values, 'mini')}
          error={Boolean(errors.houseMin)}
          errorMsg={errors.houseMin?.message as string}
          onBlur={async () => await trigger(['houseMin', 'houseMax'])}
        />
        <span>maxi</span>
        <NumericFormatFormCustom
          control={control}
          name="houseMax"
          onChange={(values: number) => handleChange(values, 'maxi')}
          error={Boolean(errors.houseMax)}
          errorMsg={errors.houseMax?.message as string}
          onBlur={async () => await trigger(['houseMin', 'houseMax'])}
        />
      </div>
    </div>
  );
}

export default HousingFilter;
