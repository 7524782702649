function checkUrlType(
  queries: any
): Pick<IConnectionProcessQueries, 'urlType' | 'phId' | 'sbId'> {
  let urlType: ConnectionProcessUrlType = 'classic';
  let sbId: string | null = null;
  let phId: string | null = null;

  // orpi-errial redirection avec SB id
  if (queries.sweepBright_external_id) {
    urlType = 'orpi-errial';
    sbId = queries.sweepBright_external_id;
  }

  // orpi-terrain redirection with PH id
  if (queries.ph_id) {
    urlType = 'orpi-land';
    sbId = queries.sweepBright_external_id;
    phId = queries.ph_id;
  }

  return { urlType, sbId, phId };
}

function queriesParser(queries: any): IConnectionProcessQueries {
  try {
    const isLand = Boolean(queries.isALand);

    // check url redirection type
    const checkType = checkUrlType(queries);

    // if isLand, lat/lng/department are loaded with folder request
    // then they are parsed to null
    const department =
      queries.departement_code && queries.departement_code !== 'null' && !isLand
        ? queries.departement_code
        : null;
    const lat =
      queries.latitude && queries.latitude !== 'null' && !isLand
        ? parseFloat(queries.latitude)
        : null;
    const lng =
      queries.longitude && queries.longitude !== 'null' && !isLand
        ? parseFloat(queries.longitude)
        : null;

    const canLoadPlot = Boolean(department && lat && lng);

    const parsed: IConnectionProcessQueries = {
      token: queries.token ?? null,
      refreshToken: queries.refresh_token ?? null,
      canLoadPlot,
      department,
      lat,
      lng,
      phAccessToken: queries.ph_access_token ?? null,
      isLand,
      ...checkType,
    };

    return parsed;
  } catch (error) {
    throw new Error('QueriesParser error');
  }
}

export default queriesParser;
