import { MouseEvent, ReactElement, useState } from 'react';
import CrossIcon from '../icons/CrossIcon';
import styles from './dragWindow.module.scss';
interface Position {
  x: number;
  y: number;
}

interface IDragWindowProps extends IChildren {
  title: string;
  onClose: () => void;
}

function DragWindow({ children, title, onClose }: IDragWindowProps): ReactElement {
  const [position, setPosition] = useState<Position>({ x: 100, y: 100 });
  const [dragging, setDragging] = useState<boolean>(false);
  const [rel, setRel] = useState<Position | null>(null);

  const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    if (e.button !== 0) return;

    const pos = { x: e.pageX - position.x, y: e.pageY - position.y };
    setDragging(true);
    setRel(pos);
    e.stopPropagation();
    e.preventDefault();
  };

  const handleStopDragging = () => {
    setDragging(false);
  };

  const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return;

    // Get screen dimensions
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    // Get component dimensions
    const component = e.currentTarget;
    const componentWidth = component.offsetWidth;
    const componentHeight = component.offsetHeight;

    if (rel) {
      const newX = e.pageX - rel.x;
      const newY = e.pageY - rel.y;

      // Ensure the component stays within screen bounds
      const boundedX = Math.max(0, Math.min(newX, screenWidth - componentWidth));
      const boundedY = Math.max(0, Math.min(newY, screenHeight - componentHeight));

      setPosition({ x: boundedX, y: boundedY });
      e.stopPropagation();
      e.preventDefault();
    }
  };

  return (
    <div
      className={styles.dragWindow}
      style={{
        left: position.x,
        top: position.y,
      }}
    >
      <div className={styles.titleContainer}>
        <h4
          onMouseDown={onMouseDown}
          onMouseUp={handleStopDragging}
          onMouseMove={onMouseMove}
          onMouseLeave={handleStopDragging}
        >
          {title}
        </h4>

        <CrossIcon className={styles.closeBtn} onClick={onClose} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default DragWindow;
