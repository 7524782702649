import { Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import headerLogo from '../../../../assets/images/orpiPdf/header-mandate-orpi-logo.png';
import mandateSide from '../../../../assets/images/orpiPdf/mandate-left-side.png';
import PageFooter from '../../errialPDF/pdfFiles/components/PageFooter';
import { pageStyles } from '../../shared/styles/pdfStyle';
import ImageContainer from './components/ImageContainer';
import { fontStyles } from './styles/pdfStyles';

function MandatePage({ agent }: { agent: PdfLandAgent | null }) {
  return (
    <Page size="A4" style={pageStyles.page}>
      <View style={styles.header}>
        <Image src={headerLogo} style={styles.headerLogo} />
      </View>

      <View style={styles.titleContainer}>
        {agent?.photo && (
          <ImageContainer
            localImg={agent?.photo}
            width="60px"
            height="60px"
            borderRadius="50%"
          />
        )}
        <Text style={styles.title}>
          Je vous accompagne à toutes les étapes de votre projet.
        </Text>
      </View>

      <View style={styles.row}>
        <Image src={mandateSide} style={styles.sideImage} />
        <View style={styles.listContainer}>
          <View>
            <Text style={styles.subTitle}>De l’estimation…</Text>
          </View>

          <View>
            <Text style={styles.listTitle}>Pour vendre au meilleur prix</Text>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                <Text style={styles.redText}>Une estimation fiable </Text>
                de votre bien avec notre Méthode d’Estimation Professionnelle Orpi.
              </Text>
            </View>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Un Dossier d’Etude qui permet une analyse à la loupe de
                l’environnement de votre bien et des prix du marché pour définir le
                juste prix de votre bien.
              </Text>
            </View>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Une connaissance parfaite{' '}
                <Text style={styles.redText}>
                  des prix du marché de votre quartier
                </Text>
                , grâce à notre réseau de 1350 agences proches de chez vous!
              </Text>
            </View>
          </View>

          <View>
            <Text style={styles.listTitle}>
              Pour vendre dans les meilleurs délais
            </Text>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Une annonce unique avec des{' '}
                <Text style={styles.redText}>
                  photos de qualité professionnelle.
                </Text>
              </Text>
            </View>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Une diffusion de votre annonce sur orpi.com et les autres sites clés
                de l’immobilier, pour une{' '}
                <Text style={styles.redText}>visibilité optimale.</Text>
              </Text>
            </View>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Une mise en commun des fichiers entre les agences du secteur pour{' '}
                <Text style={styles.redText}>vendre rapidement</Text> votre bien.
              </Text>
            </View>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Une étude et une sélection systématique des dossiers acquéreurs pour{' '}
                <Text style={styles.redText}>optimiser les visites.</Text>
              </Text>
            </View>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                <Text>Une signature de votre compromis en 72H*.</Text>
              </Text>
            </View>
          </View>

          <View>
            <Text style={styles.listTitle}>Pour vendre en toute sérénité</Text>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Un conseiller dédié pour vous accompagner à chaque étape du plan
                d’action élaboré avec vous.
              </Text>
            </View>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Un accompagnement pour l’organisation et la réalisation des{' '}
                <Text style={styles.redText}>diagnostics et attestations</Text>{' '}
                nécessaires à la vente.
              </Text>
            </View>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Un <Text style={styles.redText}>espace client</Text> mis à votre
                disposition pour suivre toutes les étapes de votre projet 24/24, 7/7.
              </Text>
            </View>
            <View style={styles.rowText}>
              <Text style={styles.bulletPoint}>•</Text>
              <Text>
                Un <Text style={styles.redText}>service de conciergerie</Text> offert
                pour vous aider à organiser au mieux votre déménagement.
              </Text>
            </View>
          </View>

          <View>
            <Text style={styles.subTitle}>... aux cartons</Text>
          </View>
        </View>
      </View>
      <Text style={styles.bottomText}>
        *Signature du compromis dans les 72 h de la réception d’une offre acceptée
        sous réserve d’un dossier complet (conformément à la liste des pièces
        constitutives du dossier annexée aux présentes) et de la disponibilité des
        parties.
      </Text>
      <PageFooter fixed fontColorBlack />
    </Page>
  );
}

export default MandatePage;

const styles = StyleSheet.create({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '20px',
    marginBottom: '20px',
  },
  title: {
    marginLeft: '20px',
    paddingHorizontal: '16px',
    paddingVertical: '4px',
    borderRadius: '40px',
    backgroundColor: '#27856E',
    color: '#fff',
    ...fontStyles.b700,
    ...fontStyles.fs16,
  },
  subTitle: {
    ...fontStyles.cOrpi,
    ...fontStyles.b700,
    ...fontStyles.fs16,
  },
  listTitle: {
    ...fontStyles.cOrpi,
    ...fontStyles.b700,
    ...fontStyles.fs16,
    marginBottom: '15px',
  },
  listContainer: {
    height: '100%',
    width: '420px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    ...fontStyles.fs12,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  rowText: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    width: '100%',
    paddingBottom: '5px',
    borderBottomColor: '#D0D0D0',
    borderBottomWidth: 1,
    marginTop: '10px',
    marginBottom: '10px',
  },
  headerLogo: {
    width: '125px',
  },
  sideImage: {
    width: '70px',
    marginRight: '20px',
    marginLeft: '10px',
  },
  redText: {
    ...fontStyles.cOrpi,
    ...fontStyles.b700,
  },
  bottomText: {
    ...fontStyles.fs8,
    marginTop: '20px',
    marginHorizontal: '20px',
  },
  bulletPoint: { marginHorizontal: 8 },
});
