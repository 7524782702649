import { nanoid } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import styles from './multiPlotSelectSynthesis.module.scss';
import {
  getMultiPlotSelectState,
  multiPlotSelectActions,
} from '../../../features/plot/multiPlotSelectFeature/multiPlotSelectSlice';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import {
  externalPdfErrialActions,
  getExternalPdfErrialState,
} from '../../../features/externalPdf/externalPdfSlice';
import { getPlotState } from '../../../redux/plot/reducer';
import { useLocation } from 'react-router';
import { PDF_URLS } from '../../constants';
import { addSpaceToNumber } from '../../../utils/jsFunctions';

function MultiPlotSelectSynthesis() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const path = location.pathname;
  const pdfPath = PDF_URLS.includes(path);
  const { plots } = useAppSelector(getMultiPlotSelectState);
  const { parcelle } = useAppSelector(getPlotState);
  const { multiPlotsPdf, plotCountPdf, isMultiPlotSelectorPdf } = useAppSelector(
    getExternalPdfErrialState
  );

  const items = pdfPath
    ? multiPlotsPdf.result?.map((elt) => {
        return {
          index: elt.index,
          plotId: elt.plotId,
          area: elt.theoricCapacity,
        };
      })
    : plots.result?.map((elt) => {
        return {
          index: elt.index,
          plotId: elt.fullPlotId.substring(5),
          area: elt.area,
        };
      });

  const onClickDeletePlot = (index: number) => {
    if (pdfPath) {
      dispatch(externalPdfErrialActions.removeMultiPlotsPdfFromSelection(index));
    } else {
      dispatch(multiPlotSelectActions.removePlotFromSelection(index));
    }
  };

  return (
    <div
      className={
        pdfPath
          ? styles.multiPlotSelectSynthesisPdf
          : styles.multiPlotSelectSynthesis
      }
    >
      {(isMultiPlotSelectorPdf && pdfPath) || !pdfPath ? (
        <>
          <p>
            {pdfPath
              ? 'Vous avez séléctionné les parcelles suivantes, limite de 5 parcelles :'
              : 'Vous avez séléctionné les parcelles suivantes :'}
          </p>
          {pdfPath && (
            <p className={styles.warning}>
              Veuillez sélectionner des parcelles adjacentes uniquement pour un
              meilleur rendu
            </p>
          )}
          <div className={styles.content}>
            {items && !isEmpty(items) && (
              <ul>
                {items?.map((elt, i) => (
                  <li key={nanoid()}>
                    <span className={styles.number}>{i + 1}</span>
                    <p className={`${items?.length === 1 ? styles.oneItem : ''}`}>
                      {elt.plotId} - {addSpaceToNumber(elt.area)} m²
                    </p>
                    <span
                      onClick={() => onClickDeletePlot(elt.index)}
                      className={styles.close}
                    >
                      x
                    </span>
                  </li>
                ))}
              </ul>
            )}
            <div>
              <p className={styles.badge}>
                Nombre :{' '}
                {pdfPath
                  ? addSpaceToNumber(plotCountPdf)
                  : addSpaceToNumber(items?.length ?? 0)}
              </p>
              <p className={styles.badge}>
                Surface totale :{' '}
                {items
                  ? addSpaceToNumber(
                      items?.reduce(
                        (accumulator, elt) => accumulator + (elt?.area as number),
                        0
                      )
                    )
                  : 0}{' '}
                m²
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          {parcelle && (
            <>
              <p>Vous avez séléctionné la parcelle suivante:</p>
              <div className={styles.content}>
                <div>
                  <p className={styles.badge}>
                    {parcelle?.parcelleId.substring(5)} -{' '}
                    {addSpaceToNumber(parcelle?.theoricCapacity)} m²
                  </p>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default MultiPlotSelectSynthesis;
