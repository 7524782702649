import * as Cesium from 'cesium';
import streetViewIcon from '../../../../assets/images/street-view-map-icon.svg';

/**
 * Add or update street view position
 * @param {Cesium.Viewer} viewer - viewer 3D cesium
 * @param {StreetViewLatLngType} location - location to place Street View point
 * @returns {void}
 */
async function addOrUpdateStreetViewEntityToMap(
  viewer: Cesium.Viewer,
  location: StreetViewLatLngType
) {
  return new Promise((resolve, reject) => {
    try {
      const id = 'streetViewEntity';
      const entity = viewer.entities.getById(id);

      const cartesian3PositionStart = Cesium.Cartesian3.fromDegrees(
        location.lng,
        location.lat,
        location.height ? location.height : 0
      );
      const cartesian3PositionEnd = Cesium.Cartesian3.fromDegrees(
        location.lng,
        location.lat,
        location.height ? location.height + 100 : 100
      );

      if (entity) {
        entity.position = new Cesium.ConstantPositionProperty(cartesian3PositionEnd);
        entity.polyline = new Cesium.PolylineGraphics({
          positions: [cartesian3PositionStart, cartesian3PositionEnd],
          width: 1,
          material: Cesium.Color.YELLOW,
        });
      } else {
        const newEntity = {
          id,
          position: cartesian3PositionEnd,
          billboard: new Cesium.BillboardGraphics({
            image: streetViewIcon,
            show: true,
            eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            alignedAxis: Cesium.Cartesian3.ZERO,
            width: 50,
            height: 60,
          }),
          polyline: new Cesium.PolylineGraphics({
            positions: [cartesian3PositionStart, cartesian3PositionEnd],
            width: 1,
            material: Cesium.Color.YELLOW,
          }),
        };
        viewer.entities.add(newEntity);
      }
      return resolve(true);
    } catch (error) {
      return reject(error);
    }
  });
}

export default addOrUpdateStreetViewEntityToMap;
