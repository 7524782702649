import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { fetchDatas } from '../../services/axiosFiles/genericCrud';
import {
  advancedPlotSearchApiToStoreParser,
  advancedPlotSearchFormToApiParser,
} from './advancedPlotSearchParser';

export const fetchAdvancedPlotSearchThunk = createAsyncThunk(
  'advancedPlotSearch/fetchAdvancedPlotSearchThunk',
  async (params: AdvancedPlotSearchForm | null, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const { geolocDatas } = state.map;
      if (
        geolocDatas?.city &&
        geolocDatas?.postalCode &&
        geolocDatas?.inseeCode &&
        params
      ) {
        const body = await advancedPlotSearchFormToApiParser(
          params,
          geolocDatas.city,
          geolocDatas.postalCode,
          geolocDatas.inseeCode
        );

        const result = await fetchDatas('/advanced_search', body);

        const parsedResult = await advancedPlotSearchApiToStoreParser(
          result,
          params.zone,
          params.zoneChild
        );

        return parsedResult;
      } else {
        return rejectWithValue({ status: 0, message: 'some params are missing' });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
