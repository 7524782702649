import { StyleSheet, Text, View } from '@react-pdf/renderer';

import EnvironmentGradualScore from '../../../errialPDF/pdfFiles/components/EnvironmentGradualScore';
import ClayIconSvg from '../../../errialPDF/pdfFiles/components/svg/ClayIconSvg';
import ErosionIconSvg from '../../../errialPDF/pdfFiles/components/svg/ErosionIconSvg';
import RadonIconSvg from '../../../errialPDF/pdfFiles/components/svg/RadonIconSvg';
import SeismicIconSvg from '../../../errialPDF/pdfFiles/components/svg/SeismicIconSvg';
import { EnvIconTypeEnum } from '../../../shared/constants';
import { fontStyles } from '../styles/pdfStyles';

interface IEnvironmentalConstraintProps {
  type: EnvIconTypeEnum;
  title: string;
  value?: number;
  max?: number;
  yesNo?: boolean;
  description?: string;
  children?: React.ReactNode;
}

function EnvironmentalConstraintLand(props: IEnvironmentalConstraintProps) {
  return (
    <View style={styles.environmentalConstraint}>
      <View style={styles.logoContainer}>
        {props.type === EnvIconTypeEnum.SEISMIC && <SeismicIconSvg color="#000" />}
        {props.type === EnvIconTypeEnum.CLAY && <ClayIconSvg color="#000" />}
        {props.type === EnvIconTypeEnum.RADON && <RadonIconSvg color="#000" />}
        {props.type === EnvIconTypeEnum.EROSION && <ErosionIconSvg color="#000" />}
      </View>
      <View style={styles.infoContainer}>
        <View style={[styles.titleContainer, fontStyles.b700, fontStyles.fs14]}>
          <Text>{`${props.title} `} </Text>
          {props.value && (
            <Text style={styles.valueText}>
              {props.value}/{props.max}
            </Text>
          )}
        </View>

        {props.value ? (
          <View style={styles.infosTextContainer}>
            {props.description ? (
              <Text style={styles.description}>{props.description}</Text>
            ) : (
              props.children
            )}
            <EnvironmentGradualScore
              type={props.type}
              value={props.value}
              max={props.max}
            />
          </View>
        ) : (
          <Text>Absence de contraintes connues</Text>
        )}
      </View>
    </View>
  );
}

export default EnvironmentalConstraintLand;

const styles = StyleSheet.create({
  environmentalConstraint: {
    minHeight: '100px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '90px',
    borderRight: '1px solid #D0D0D0',
    marginRight: '15px',
  },
  logo: {
    width: '64px',
    height: '64px',
  },
  infoContainer: {
    paddingVertical: '10px',
    height: '100%',
    width: '100%',
    flexGrow: 1,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  valueText: {},
  infosTextContainer: { width: '82%' },
  description: { fontSize: '12px', fontWeight: 300 },
});
