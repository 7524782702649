import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { RootState } from '../../App/store';
import arrayFilter from '../../lib/arrayFilter';
import { APIStatus } from '../../services/axiosFiles/apiTypes';
import { fetchBuildPermitsThunk } from './buildPermitsThunk';

export enum DisplayedTypesEnum {
  PA = 'PA',
  DP = 'DP',
  PC = 'PC',
}

export enum DisplayedFiltersEnum {
  RECENT = 'recent',
  CANCELLED = 'cancelled',
}

const initialState: BuildPermitState = {
  postalCode: null,
  department: null,
  city: null,
  buildPermits: { apiStatus: APIStatus.IDLE, result: null },
  buildPermitsVisible: false,
  displayedTypes: [],
  displayedFilters: [],
  dateFilter: { start: null, end: null },
  housingFilter: { mini: null, maxi: null },
};

const buildPermitsSlice = createSlice({
  name: 'buildPermits',
  initialState,
  reducers: {
    setDisplayedFilter: (state, action: PayloadAction<DisplayedFiltersEnum>) => {
      if (action.payload === DisplayedFiltersEnum.RECENT) {
        state.dateFilter = { start: null, end: null };
      }
      const tab = arrayFilter<DisplayedFiltersEnum>(
        state.displayedFilters,
        action.payload
      );

      state.displayedFilters = tab;

      if (isEmpty(state.displayedFilters) && isEmpty(state.displayedTypes)) {
        state.buildPermitsVisible = false;
      } else if (isEmpty(state.displayedTypes)) {
        // display all types if 1 filter is checked but no type
        state.displayedTypes = [
          DisplayedTypesEnum.PA,
          DisplayedTypesEnum.DP,
          DisplayedTypesEnum.PC,
        ];
        state.buildPermitsVisible = true;
      }
    },
    setDateFilter: (state, action: PayloadAction<DateFilterType>) => {
      //uncheck recent if date added
      if (state.displayedFilters.includes(DisplayedFiltersEnum.RECENT)) {
        state.displayedFilters = state.displayedFilters.filter(
          (f) => f !== DisplayedFiltersEnum.RECENT
        );
      }
      //display all permits
      state.buildPermitsVisible = true;
      state.displayedTypes = [
        DisplayedTypesEnum.PA,
        DisplayedTypesEnum.DP,
        DisplayedTypesEnum.PC,
      ];

      //add dates for filter
      state.dateFilter = action.payload;
    },
    setHouseFilter: (state, action: PayloadAction<HousingFilterType>) => {
      state.housingFilter = action.payload;
    },
    setDisplayedType: (state, action: PayloadAction<DisplayedTypesEnum>) => {
      const tab = arrayFilter<DisplayedTypesEnum>(
        state.displayedTypes,
        action.payload
      );

      state.displayedTypes = tab;
      state.buildPermitsVisible = true;

      if (isEmpty(state.displayedTypes)) {
        state.displayedFilters = [];
        state.buildPermitsVisible = false;
      }
    },
    showBuildPermits: (state) => {
      state.buildPermitsVisible = true;
      state.displayedTypes = [
        DisplayedTypesEnum.PA,
        DisplayedTypesEnum.DP,
        DisplayedTypesEnum.PC,
      ];
    },
    hideBuildPermits: (state) => {
      state.buildPermitsVisible = false;
      state.displayedTypes = [];
      state.displayedFilters = [];
      state.dateFilter = { start: null, end: null };
      state.housingFilter = { mini: null, maxi: null };
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBuildPermitsThunk.pending, (state) => {
        state.buildPermits.apiStatus = APIStatus.PENDING;
      })
      .addCase(
        fetchBuildPermitsThunk.fulfilled,
        (state, action: PayloadAction<FetchBuildPermitsResponse>) => {
          state.buildPermits.apiStatus = APIStatus.IDLE;
          state.buildPermits.error = undefined;
          state.buildPermits.result = action.payload?.buildPermits;
          state.city = action.payload?.city;
          state.postalCode = action.payload?.postalCode;
        }
      )
      .addCase(
        fetchBuildPermitsThunk.rejected,
        (state, action: PayloadAction<any>) => {
          state.buildPermits.apiStatus = APIStatus.REJECTED;
          state.buildPermits.error = action.payload;
        }
      );
  },
});

export default buildPermitsSlice.reducer;
export const buildPermitsActions = buildPermitsSlice.actions;
export const getBuildPermitsState = (state: RootState) => state.buildPermits;
