import { useState } from 'react';
import GenericModal, {
  GenericModalActionEnum,
  GenericModalEnum,
} from '../../../components/Modals/GenericModal';
import DeleteConfirmation from '../deleteConfirmation';
import styles from './deleteWithConfirmationModal.module.scss';

interface IDeleteWithConfirmationModalProps {
  type: 'dossier' | 'secteur';
  name: string;
  hasUndeletableSubs: string[] | null;
  isEmpty: boolean;
  onCloseModal: () => void;
  onDeleteClick: () => void;
}
function DeleteWithConfirmationModal({
  type,
  name,
  isEmpty,
  onCloseModal,
  onDeleteClick,
  hasUndeletableSubs,
}: IDeleteWithConfirmationModalProps) {
  const [removeValidationDisplay, setRemoveValidationDisplay] = useState(false);

  const handleClick = () => {
    if (!hasUndeletableSubs) {
      if (!isEmpty) {
        setRemoveValidationDisplay(true);
      } else if (isEmpty) {
        onDeleteClick();
      } else {
        console.log('aucun élément à supprimer');
      }
    } else {
      onCloseModal();
    }
  };
  return (
    <GenericModal
      title={
        removeValidationDisplay
          ? 'Confirmation de suppression'
          : type === 'dossier'
            ? 'Suppression de dossier'
            : 'Suppression de secteur'
      }
      actionType={
        hasUndeletableSubs
          ? GenericModalActionEnum.OK
          : removeValidationDisplay
            ? GenericModalActionEnum.NONE
            : GenericModalActionEnum.OKCANCEL
      }
      modalType={GenericModalEnum.WARNING}
      closeModal={onCloseModal}
      okButtonContent={hasUndeletableSubs ? 'OK' : 'Supprimer'}
      okCallback={handleClick}
      cancelCallback={onCloseModal}
      hasIcon={!removeValidationDisplay}
      suppressionConfirm
    >
      <div className={styles.deleteWithConfirmationModal}>
        {hasUndeletableSubs ? (
          <>
            <p>
              Ce {type} ne peut pas être supprimé car
              {hasUndeletableSubs.length === 1
                ? ` le sous ${type} `
                : ` les sous ${type}s `}
              <span>{`${hasUndeletableSubs.join(', ')} `}</span>
              appartiennent à un manager, ou un autre responsable.
            </p>
            <p>
              Vous devez effacer vos sous {type}s{' '}
              {type === 'dossier' ? 'et vos parcelles' : ''} manuellement, ou
              contacter votre manager.
            </p>
          </>
        ) : removeValidationDisplay ? (
          <DeleteConfirmation
            onConfirmationValid={onDeleteClick}
            wordToWrite={name ?? null}
          />
        ) : isEmpty ? (
          <p>
            La suppression du {type} <span>{name}</span> sera définitive.
          </p>
        ) : (
          <>
            <p>Le {type} n&#039;est pas vide.</p>
            <p>
              Cette action entrainera la suppression definitive de tout son contenu.
            </p>
          </>
        )}
      </div>
    </GenericModal>
  );
}

export default DeleteWithConfirmationModal;
