import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import {
  advancedPlotSearchActions,
  getAdvancedPlotSearchState,
} from '../advancedPlotSearchSlice';
import styles from './advancedPlotSearchContainer.module.scss';
interface IBlocContainer extends IChildren {
  title: string;
  blocKey: keyof DeployedBlocType;
}
function BlocContainer({ children, title, blocKey }: IBlocContainer) {
  const { deployedBloc } = useAppSelector(getAdvancedPlotSearchState);
  const dispatch = useAppDispatch();

  const handleContentDisplay = () => {
    dispatch(advancedPlotSearchActions.setDisplayBlock(blocKey));
  };

  const key = deployedBloc[blocKey];

  return (
    <div className={styles.blocContainer}>
      <div className={styles.header}>
        <span>{title}</span>
        <ExpandMoreIcon
          className={`${styles.icon} ${key ? styles.down : ''}`}
          onClick={handleContentDisplay}
        />
      </div>
      <div className={key ? '' : styles.hidden}>{children}</div>
    </div>
  );
}

export default BlocContainer;
