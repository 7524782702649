import { Autocomplete } from '@mui/lab';
import { TextField } from '@mui/material';
// eslint-disable-next-line
import { ChangeEvent, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '../../../../App/store';
import { getMapState } from '../../../../features/map/mapSlice';
import styles from '../advancedPlotSearchContainer.module.scss';
import fetchOwnersFromPattern from './fetchOwnersFromPattern';

interface IOwnerSelectProps {
  isSirenNumberEmpty: boolean;
}

const useStyles = makeStyles()({
  option: { lineHeight: '1', fontSize: '14px' },
  inputRoot: { height: '26px', padding: '0 !important' },
  input: { padding: '4px 0 4px 5px !important', margin: 0 },
});

function OwnerSelect({ isSirenNumberEmpty }: IOwnerSelectProps) {
  const { control, setValue, trigger } = useFormContext();
  const { classes } = useStyles();
  const [previewOwnerLength, setPreviewOwnerLength] = useState(0);
  const { geolocDatas } = useAppSelector(getMapState);
  const [owners, setOwners] = useState<string[]>([]);

  const handleSearch = async (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (...event: any[]) => void
  ) => {
    const letters = e.target.value.toUpperCase();
    trigger('ownerName');
    onChange(letters);

    // if 3 letters after no correction, do request
    if (letters.length >= 3) {
      try {
        const result = await fetchOwnersFromPattern(
          geolocDatas?.city ?? null,
          geolocDatas?.postalCode ?? null,
          letters,
          geolocDatas?.inseeCode ?? null
        );

        setOwners(result);
      } catch (error) {
        setOwners([]);
      }
    }

    // if name update for new 3 letters, remove last data
    if (!letters || (letters.length < 3 && previewOwnerLength >= 3)) {
      setOwners([]);
      setValue('ownerName', '');
    }

    setPreviewOwnerLength(letters.length);
  };

  return (
    <>
      <div className={styles.label}>Propriétaires Personnes Morales</div>
      <Controller
        control={control}
        name="ownerName"
        render={({ field: { value, onChange } }) => (
          <Autocomplete
            value={value}
            classes={classes}
            options={owners}
            disableClearable
            freeSolo
            disabled={!isSirenNumberEmpty}
            onChange={(e, value) => onChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Saisir 3 lettres minimum"
                onChange={(e) => {
                  handleSearch(e, onChange);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            )}
          />
        )}
      />
    </>
  );
}

export default OwnerSelect;
