import styles from './legend.module.scss';

interface ILegendContainerProps {
  legend: Legend;
}
function LegendRow({ legend }: ILegendContainerProps) {
  return (
    <div className={styles.legendRow}>
      <img src={legend.url} alt="legend image" />
      <label>{legend.label}</label>
    </div>
  );
}

export default LegendRow;
