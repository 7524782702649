import { Checkbox } from '@mui/material';
import { useContext } from 'react';
import folderIcon from '../../../../assets/images/folder-open.svg';
import FolderContainer from './FolderContainer';

import styles from './multiselectPlotModal.module.scss';
import { MultiselectModalContext } from './MultiSelectPlotModalContent';

interface IFolderRowProps {
  folder: IFolder;
}

function FolderRow({ folder }: IFolderRowProps) {
  const { state, update } = useContext(MultiselectModalContext);
  return (
    <div className={styles.folderRow}>
      <div className={styles.folderHeader}>
        <Checkbox
          onChange={() => update(folder)}
          checked={folder.idIri === state?.idIri}
          className={styles.checkIcon}
          color="primary"
          size="small"
          value={folder.idIri}
        />
        <img src={folderIcon} alt="icon" />
        <div className={styles.name}>{folder.name}</div>
      </div>

      <FolderContainer folders={folder.subs} isSub />
    </div>
  );
}

export default FolderRow;
