import MapPluginHelperModal from '../../mapPluginHelperModal/MapPluginHelperModal';

function MeasureHelperModal() {
  return (
    <MapPluginHelperModal>
      <ul>
        <li>cliquez une première fois pour commencer à mesurer la distance</li>
        <li>cliquez une deuxième fois pour continuer à mesurer la distance</li>
        <li>cliquez sur le dernier point pour terminer la mesure</li>
        <li>
          <strong>
            désactivez l&apos;outil de mesurage pour pouvoir sélectionner des
            parcelles et les étudier
          </strong>
        </li>
      </ul>
    </MapPluginHelperModal>
  );
}

export default MeasureHelperModal;
