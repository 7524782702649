export const pluOtherRules: PluOtherRules[] = [
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 1,
    cat: 2,
    catChild: null,
    libelle: 'Stationnement',
    mainColor: '#00CCE0',
    pattern: null,
    borderColor: '#00CCE0',
    borderDash: 'Tirets',
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 2,
    cat: 3,
    catChild: null,
    libelle: 'Logement Social',
    mainColor: '#EB6E00',
    pattern: null,
    borderColor: '#EB6E00',
    borderDash: 'Tirets',
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 3,
    cat: 4,
    catChild: null,
    libelle: 'Emplacements Réservés',
    mainColor: '#000BB1',
    pattern: 'Rayures',
    borderColor: '#000BB1',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 8,
    cat: 4,
    catChild: 1,
    libelle: 'Emplacements réservés 2',
    mainColor: '#0010FF',
    pattern: 'Rayures',
    borderColor: '#0010FF',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 9,
    cat: 4,
    catChild: 3,
    libelle: 'Emplacements réservés 3',
    mainColor: '#00F3FF',
    pattern: 'Rayures',
    borderColor: '#00F3FF',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 21,
    cat: 4,
    catChild: 4,
    libelle: 'Droit de Preemption Urbain',
    mainColor: '#930000',
    pattern: null,
    borderColor: '#930000',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 22,
    cat: 4,
    catChild: 5,
    libelle: 'Servitude Utilité Publique',
    mainColor: '#8C006A',
    pattern: 'Rayures',
    borderColor: '#8C006A',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 23,
    cat: 4,
    catChild: 6,
    libelle: 'SUP - Divers',
    mainColor: '#D70000',
    pattern: 'Rayures',
    borderColor: '#D70000',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 24,
    cat: 4,
    catChild: 7,
    libelle: 'DROIT DE PREEMPTION PERIMETRE SENSIBLE (ZPSD)',
    mainColor: '#D70000',
    pattern: null,
    borderColor: '#D70000',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 25,
    cat: 4,
    catChild: 8,
    libelle: 'DPC - Droit de préemption commercial',
    mainColor: '#EB6E00',
    pattern: 'Petits Points',
    borderColor: '#EB6E00',
    borderDash: null,
  },
  {
    category: "Secteurs d'Aménagement",
    id: 4,
    cat: 5,
    catChild: null,
    libelle: 'OAP',
    mainColor: '#000BB1',
    pattern: null,
    borderColor: '#000BB1',
    borderDash: 'Tirets',
  },
  {
    category: "Secteurs d'Aménagement",
    id: 10,
    cat: 5,
    catChild: 2,
    libelle: 'OAP-Logement',
    mainColor: '#0010FF',
    pattern: null,
    borderColor: '#0010FF',
    borderDash: 'Tirets',
  },
  {
    category: "Secteurs d'Aménagement",
    id: 11,
    cat: 5,
    catChild: 3,
    libelle: 'Secteur-attente-projet - Divers - ZAC',
    mainColor: '#009EFF',
    pattern: null,
    borderColor: '#009EFF',
    borderDash: 'Tirets',
  },
  {
    category: "Secteurs d'Aménagement",
    id: 26,
    cat: 5,
    catChild: 4,
    libelle: 'PUP - Projet urbain partenarial',
    mainColor: '#8C006A',
    pattern: 'quadrillage',
    borderColor: '#8C006A',
    borderDash: 'Tirets',
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 5,
    cat: 6,
    catChild: null,
    libelle: 'Secteur de modification du droit à bâtir',
    mainColor: '#930000',
    pattern: null,
    borderColor: '#930000',
    borderDash: 'Tirets',
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 12,
    cat: 6,
    catChild: 1,
    libelle: 'Plan des hauteurs',
    mainColor: '#D70000',
    pattern: null,
    borderColor: '#D70000',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 13,
    cat: 6,
    catChild: 2,
    libelle: 'Zones non aedificandi',
    mainColor: '#EB6E00',
    pattern: null,
    borderColor: '#EB6E00',
    borderDash: 'Tirets',
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 14,
    cat: 6,
    catChild: 3,
    libelle: 'Limites implantation',
    mainColor: '#EB6E00',
    pattern: null,
    borderColor: '#EB9900',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 19,
    cat: 6,
    catChild: 4,
    libelle: 'Servitude Utilité Publique',
    mainColor: '#8C006A',
    pattern: 'Rayures',
    borderColor: '#8C006A',
    borderDash: null,
  },
  {
    category: 'Périmètres et contraintes réglementaires',
    id: 27,
    cat: 6,
    catChild: 5,
    libelle: 'SUP - Divers',
    mainColor: '#D70000',
    pattern: 'Rayures',
    borderColor: '#D70000',
    borderDash: null,
  },
  {
    category: 'Contraintes Archi & Environnement',
    id: 6,
    cat: 7,
    catChild: null,
    libelle: 'AVAP et patrimoine, prescription et environnement',
    mainColor: '#763C14',
    pattern: 'Rayures',
    borderColor: '#763C14',
    borderDash: null,
  },
  {
    category: 'Contraintes Archi & Environnement',
    id: 15,
    cat: 7,
    catChild: 1,
    libelle: 'Perimètre-intérêt-patrimonial',
    mainColor: '#763C14',
    pattern: 'quadrillage',
    borderColor: '#763C14',
    borderDash: null,
  },
  {
    category: 'Contraintes Archi & Environnement',
    id: 16,
    cat: 7,
    catChild: 2,
    libelle: 'Element-bati-patrimonal',
    mainColor: '#DFAF2',
    pattern: 'Petits Points',
    borderColor: '#DFAF2',
    borderDash: null,
  },
  {
    category: 'Contraintes Archi & Environnement',
    id: 17,
    cat: 7,
    catChild: 3,
    libelle: 'Arbre remarquable',
    mainColor: '#217A00',
    pattern: null,
    borderColor: '#217A00',
    borderDash: null,
  },
  {
    category: 'Contraintes Archi & Environnement',
    id: 18,
    cat: 7,
    catChild: 4,
    libelle: 'Espace boisé classé',
    mainColor: '#31B700',
    pattern: 'quadrillage',
    borderColor: '#31B700',
    borderDash: null,
  },
  {
    category: 'Contraintes Archi & Environnement',
    id: 20,
    cat: 7,
    catChild: 5,
    libelle: 'Zone Naturelle Protégée',
    mainColor: '#41EF01',
    pattern: 'Petits Points',
    borderColor: '#41EF01',
    borderDash: null,
  },
  {
    category: 'Autres',
    id: 7,
    cat: 8,
    catChild: null,
    libelle: 'Divers',
    mainColor: '#8C006A',
    pattern: null,
    borderColor: '#8C006A',
    borderDash: null,
  },
  {
    category: "Secteurs d'Aménagement",
    id: 28,
    cat: 8,
    catChild: 1,
    libelle: 'Divers - ZAC',
    mainColor: '#009EFF',
    pattern: null,
    borderColor: '#009EFF',
    borderDash: 'Tirets',
  },
  {
    category: 'Autres',
    id: 29,
    cat: 8,
    catChild: 2,
    libelle: 'Aléa inondation - Hors PPRI',
    mainColor: '#009EFF',
    pattern: null,
    borderColor: '#009EFF',
    borderDash: null,
  },
  {
    category: 'Autres',
    id: 30,
    cat: 8,
    catChild: 3,
    libelle: 'loi littoral ',
    mainColor: '#0010FF',
    pattern: null,
    borderColor: '#0010FF',
    borderDash: null,
  },
  {
    category: "Secteurs d'Aménagement",
    id: 31,
    cat: 9,
    catChild: null,
    libelle: 'PUP - Projet urbain partenarial',
    mainColor: '#8C006A',
    pattern: 'quadrillage',
    borderColor: '#8C006A',
    borderDash: 'Tirets',
  },
  {
    category: 'Autres',
    id: 32,
    cat: 10,
    catChild: null,
    libelle: 'Divers 2',
    mainColor: '#ffffff',
    pattern: null,
    borderColor: '#ffffff',
    borderDash: null,
  },
  {
    category: 'Autres',
    id: 33,
    cat: 11,
    catChild: null,
    libelle: 'Divers 3',
    mainColor: '#ffffff',
    pattern: null,
    borderColor: '#ffffff',
    borderDash: null,
  },
  {
    category: 'Autres',
    id: 34,
    cat: 12,
    catChild: null,
    libelle: 'Divers 4',
    mainColor: '#ffffff',
    pattern: null,
    borderColor: '#ffffff',
    borderDash: null,
  },
];

export const categoriesPluEnv: {
  cat: string;
  libelle: string;
  mainColor: string;
}[] = [
  { cat: '1', libelle: 'Données sismiques', mainColor: '#614e1a' },
  { cat: '2', libelle: 'Quartiers prioritaires', mainColor: '#B2B2B2' },
  {
    cat: '3',
    libelle: 'Secteurs Batiments Historiques',
    mainColor: '#FEBFD2',
  },
  { cat: '4', libelle: 'PPRI - Zones inondables', mainColor: '#0000FF' },
  { cat: '5', libelle: 'Données ARGILES', mainColor: '#568203' },
  { cat: '6', libelle: 'Secteur(s) PPRN', mainColor: '#00FFFF' },
  {
    cat: '7',
    libelle: "PEB - Plan d'Exposition au Bruit",
    mainColor: '#0000CD',
  },
  {
    cat: '8',
    libelle: 'Site patrimonial remarquable',
    mainColor: '#3A8EBA',
  },
  {
    cat: '9',
    libelle: 'Prescriptions Archéologiques',
    mainColor: '#cc5500',
  },
  {
    cat: '10',
    libelle: 'Trait littoral - Érosion côtière',
    mainColor: '#D881D8',
  },
  { cat: '11', libelle: 'indice RADON', mainColor: '#ff8c00' },
  {
    cat: '12',
    libelle: "SIS - Secteurs d'Informations sur les Sols",
    mainColor: '#fcb3d8',
  },
  {
    cat: '13',
    libelle: 'Anciens sites industriels et activités de service',
    mainColor: '#c72c59',
  },
  {
    cat: '14',
    libelle:
      'Sites pollués ou potentiellement pollués appelant une action des pouvoirs publics, à titre préventif ou curatif',
    mainColor: '#e9511a',
  },
];

export const pluRules = [
  { zone: '(Z)A', color: '#4FE504', mainColor: '#ffffff' },
  { zone: '(Z)AU', color: '#8440E5' },
  { zone: '(Z)N', color: '#0DCE0A' },
  { zone: '(Z)U', color: '#F22D2D' },
  { zone: '.', color: '#A9ADA9' },
  { zone: '0-AU', color: '#8440E5' },
  { zone: '0-AUE', color: '#8440E5' },
  { zone: '0AC', color: '#4FE504' },
  { zone: '0AU', color: '#8440E5' },
  { zone: '1-AU', color: '#8440E5' },
  { zone: '10AU-1', color: '#8440E5' },
  { zone: '10AU-2', color: '#8440E5' },
  { zone: '10AU-3', color: '#8440E5' },
  { zone: '10AU-4', color: '#8440E5' },
  { zone: '11AU', color: '#8440E5' },
  { zone: '12AU-1', color: '#8440E5' },
  { zone: '12AU-2', color: '#8440E5' },
  { zone: '12AU-3', color: '#8440E5' },
  { zone: '13AU-1', color: '#8440E5' },
  { zone: '13AU-2', color: '#8440E5' },
  { zone: '14AU-1', color: '#8440E5' },
  { zone: '14AU-2', color: '#8440E5' },
  { zone: '15AU', color: '#8440E5' },
  { zone: '1A', color: '#4FE504' },
  { zone: '1AU', color: '#8440E5' },
  { zone: '1AU-1', color: '#8440E5' },
  { zone: '1AU-2', color: '#8440E5' },
  { zone: '1AU-3', color: '#8440E5' },
  { zone: '1AU-4', color: '#8440E5' },
  { zone: '1AU-5', color: '#8440E5' },
  { zone: '1AU-6', color: '#8440E5' },
  { zone: '1AU.d', color: '#8440E5' },
  { zone: '1AU1', color: '#8440E5' },
  { zone: '1AU1*', color: '#8440E5' },
  { zone: '1AU2', color: '#8440E5' },
  { zone: '1AU2*', color: '#8440E5' },
  { zone: '1AU3', color: '#8440E5' },
  { zone: '1AU3*', color: '#8440E5' },
  { zone: '1AU4', color: '#8440E5' },
  { zone: '1AU5', color: '#8440E5' },
  { zone: '1AU6', color: '#8440E5' },
  { zone: '1AU7', color: '#8440E5' },
  { zone: '1AU8', color: '#8440E5' },
  { zone: '1AU9', color: '#8440E5' },
  { zone: '1AUA', color: '#8440E5' },
  { zone: '1AUAT', color: '#8440E5' },
  { zone: '1AUB', color: '#8440E5' },
  { zone: '1AUB1', color: '#8440E5' },
  { zone: '1AUC', color: '#8440E5' },
  { zone: '1AUD', color: '#8440E5' },
  { zone: '1AUE', color: '#8440E5' },
  { zone: '1AUEQ', color: '#8440E5' },
  { zone: '1AUF', color: '#8440E5' },
  { zone: '1AUG', color: '#8440E5' },
  { zone: '1AUH', color: '#8440E5' },
  { zone: '1AUH1', color: '#8440E5' },
  { zone: '1AUH3', color: '#8440E5' },
  { zone: '1AUI', color: '#8440E5' },
  { zone: '1AUL', color: '#8440E5' },
  { zone: '1AUM', color: '#8440E5' },
  { zone: '1AUN', color: '#8440E5' },
  { zone: '1AUP', color: '#8440E5' },
  { zone: '1AUR', color: '#8440E5' },
  { zone: '1AURP', color: '#8440E5' },
  { zone: '1AUS', color: '#8440E5' },
  { zone: '1AUT', color: '#8440E5' },
  { zone: '1AUV', color: '#8440E5' },
  { zone: '1AUW', color: '#8440E5' },
  { zone: '1AUX', color: '#8440E5' },
  { zone: '1AUXL', color: '#8440E5' },
  { zone: '1AUY', color: '#8440E5' },
  { zone: '1AUYC', color: '#8440E5' },
  { zone: '1AUZ', color: '#8440E5' },
  { zone: '1Au', color: '#8440E5' },
  { zone: '1N', color: '#0DCE0A' },
  { zone: '1NA', color: '#0DCE0A' },
  { zone: '1NB', color: '#0DCE0A' },
  { zone: '1NC', color: '#0DCE0A' },
  { zone: '1ND', color: '#0DCE0A' },
  { zone: '1NL', color: '#0DCE0A' },
  { zone: '1NP', color: '#0DCE0A' },
  { zone: '1NX', color: '#0DCE0A' },
  { zone: '1U', color: '#F22D2D' },
  { zone: '1U1-1', color: '#F22D2D' },
  { zone: '1U1-10', color: '#F22D2D' },
  { zone: '1U1-2', color: '#F22D2D' },
  { zone: '1U1-3', color: '#F22D2D' },
  { zone: '1U1-4', color: '#F22D2D' },
  { zone: '1U1-6', color: '#F22D2D' },
  { zone: '1U1-7', color: '#F22D2D' },
  { zone: '1U1-8', color: '#F22D2D' },
  { zone: '1U1-9', color: '#F22D2D' },
  { zone: '1U10', color: '#F22D2D' },
  { zone: '1U2-1', color: '#F22D2D' },
  { zone: '1U2-2', color: '#F22D2D' },
  { zone: '1U2-3', color: '#F22D2D' },
  { zone: '1U2-4', color: '#F22D2D' },
  { zone: '1U2-5', color: '#F22D2D' },
  { zone: '1U2-6', color: '#F22D2D' },
  { zone: '1U2-7', color: '#F22D2D' },
  { zone: '1U2-8', color: '#F22D2D' },
  { zone: '1U3', color: '#F22D2D' },
  { zone: '1U4-1', color: '#F22D2D' },
  { zone: '1U4-2', color: '#F22D2D' },
  { zone: '1U4-3', color: '#F22D2D' },
  { zone: '1U6', color: '#F22D2D' },
  { zone: '1U7-1', color: '#F22D2D' },
  { zone: '1U7-2', color: '#F22D2D' },
  { zone: '1U7-3', color: '#F22D2D' },
  { zone: '1U7-4', color: '#F22D2D' },
  { zone: '1U8-1', color: '#F22D2D' },
  { zone: '1U8-2', color: '#F22D2D' },
  { zone: '1U9', color: '#F22D2D' },
  { zone: '1UA', color: '#F22D2D' },
  { zone: '1UB', color: '#F22D2D' },
  { zone: '1UC', color: '#F22D2D' },
  { zone: '1UD', color: '#F22D2D' },
  { zone: '1UE', color: '#F22D2D' },
  { zone: '1UI', color: '#F22D2D' },
  { zone: '1UL', color: '#F22D2D' },
  { zone: '1UP', color: '#F22D2D' },
  { zone: '1UX', color: '#F22D2D' },
  { zone: '1UZ', color: '#F22D2D' },
  { zone: '1au', color: '#8440E5' },
  { zone: '2', color: '#2DC2F2' },
  { zone: '2-AU', color: '#8440E5' },
  { zone: '2A', color: '#4FE504' },
  { zone: '2AU', color: '#8440E5' },
  { zone: '2AU-1', color: '#8440E5' },
  { zone: '2AU-2', color: '#8440E5' },
  { zone: '2AU-4', color: '#8440E5' },
  { zone: '2AU1', color: '#8440E5' },
  { zone: '2AU2', color: '#8440E5' },
  { zone: '2AU3', color: '#8440E5' },
  { zone: '2AU4', color: '#8440E5' },
  { zone: '2AU6', color: '#8440E5' },
  { zone: '2AU8', color: '#8440E5' },
  { zone: '2AU9', color: '#8440E5' },
  { zone: '2AUA', color: '#8440E5' },
  { zone: '2AUB', color: '#8440E5' },
  { zone: '2AUC', color: '#8440E5' },
  { zone: '2AUD', color: '#8440E5' },
  { zone: '2AUE', color: '#8440E5' },
  { zone: '2AUF', color: '#8440E5' },
  { zone: '2AUH', color: '#8440E5' },
  { zone: '2AUL', color: '#8440E5' },
  { zone: '2AUP', color: '#8440E5' },
  { zone: '2AUR', color: '#8440E5' },
  { zone: '2AUS', color: '#8440E5' },
  { zone: '2AUT', color: '#8440E5' },
  { zone: '2AUX', color: '#8440E5' },
  { zone: '2AUY', color: '#8440E5' },
  { zone: '2AUZ', color: '#8440E5' },
  { zone: '2N', color: '#0DCE0A' },
  { zone: '2NA', color: '#0DCE0A' },
  { zone: '2NB', color: '#0DCE0A' },
  { zone: '2ND', color: '#0DCE0A' },
  { zone: '2NH', color: '#0DCE0A' },
  { zone: '2U', color: '#F22D2D' },
  { zone: '2U1-1', color: '#F22D2D' },
  { zone: '2U1-10', color: '#F22D2D' },
  { zone: '2U1-11', color: '#F22D2D' },
  { zone: '2U1-12', color: '#F22D2D' },
  { zone: '2U1-13', color: '#F22D2D' },
  { zone: '2U1-14', color: '#F22D2D' },
  { zone: '2U1-15', color: '#F22D2D' },
  { zone: '2U1-16', color: '#F22D2D' },
  { zone: '2U1-17', color: '#F22D2D' },
  { zone: '2U1-18', color: '#F22D2D' },
  { zone: '2U1-19', color: '#F22D2D' },
  { zone: '2U1-2', color: '#F22D2D' },
  { zone: '2U1-20', color: '#F22D2D' },
  { zone: '2U1-21', color: '#F22D2D' },
  { zone: '2U1-22', color: '#F22D2D' },
  { zone: '2U1-23', color: '#F22D2D' },
  { zone: '2U1-24', color: '#F22D2D' },
  { zone: '2U1-25', color: '#F22D2D' },
  { zone: '2U1-26', color: '#F22D2D' },
  { zone: '2U1-27', color: '#F22D2D' },
  { zone: '2U1-3', color: '#F22D2D' },
  { zone: '2U1-4', color: '#F22D2D' },
  { zone: '2U1-5', color: '#F22D2D' },
  { zone: '2U1-6', color: '#F22D2D' },
  { zone: '2U1-7', color: '#F22D2D' },
  { zone: '2U1-8', color: '#F22D2D' },
  { zone: '2U1-9', color: '#F22D2D' },
  { zone: '2U2-1', color: '#F22D2D' },
  { zone: '2U2-2', color: '#F22D2D' },
  { zone: '2U2-3', color: '#F22D2D' },
  { zone: '2U2-4', color: '#F22D2D' },
  { zone: '2U2-5', color: '#F22D2D' },
  { zone: '2U3', color: '#F22D2D' },
  { zone: '2U4', color: '#F22D2D' },
  { zone: '2U5', color: '#F22D2D' },
  { zone: '2U6-1', color: '#F22D2D' },
  { zone: '2U6-2', color: '#F22D2D' },
  { zone: '2U6-3', color: '#F22D2D' },
  { zone: '2U7', color: '#F22D2D' },
  { zone: '2U8', color: '#F22D2D' },
  { zone: '2U9-1', color: '#F22D2D' },
  { zone: '2U9-2', color: '#F22D2D' },
  { zone: '2U9-3', color: '#F22D2D' },
  { zone: '2U9-4', color: '#F22D2D' },
  { zone: '2U9-5', color: '#F22D2D' },
  { zone: '2UA', color: '#F22D2D' },
  { zone: '2UB', color: '#F22D2D' },
  { zone: '2UD', color: '#F22D2D' },
  { zone: '2UE', color: '#F22D2D' },
  { zone: '2UI', color: '#F22D2D' },
  { zone: '2UL', color: '#F22D2D' },
  { zone: '2UP', color: '#F22D2D' },
  { zone: '2UX', color: '#F73D8C' },
  { zone: '2UZ', color: '#F73D8C' },
  { zone: '2au', color: '#8440E5' },
  { zone: '3AU', color: '#8440E5' },
  { zone: '3AUE', color: '#8440E5' },
  { zone: '3AUI', color: '#8440E5' },
  { zone: '3N', color: '#0DCE0A' },
  { zone: '3U', color: '#F22D2D' },
  { zone: '3U1-1', color: '#F22D2D' },
  { zone: '3U1-2', color: '#F22D2D' },
  { zone: '3U1-3', color: '#F22D2D' },
  { zone: '3U1-4', color: '#F22D2D' },
  { zone: '3U1-5', color: '#F22D2D' },
  { zone: '3U1-6', color: '#F22D2D' },
  { zone: '3U1-7', color: '#F22D2D' },
  { zone: '3U1-8', color: '#F22D2D' },
  { zone: '3U1-9', color: '#F22D2D' },
  { zone: '3U2', color: '#F22D2D' },
  { zone: '3UA', color: '#F22D2D' },
  { zone: '3UB', color: '#F22D2D' },
  { zone: '3UE', color: '#F73D8C' },
  { zone: '3UI', color: '#F73D8C' },
  { zone: '3UL', color: '#2DC2F2' },
  { zone: '3UP', color: '#2DC2F2' },
  { zone: '4AU', color: '#8440E5' },
  { zone: '4AU1-1', color: '#8440E5' },
  { zone: '4AU1-2', color: '#8440E5' },
  { zone: '4AU1-3', color: '#8440E5' },
  { zone: '4AU1-5', color: '#8440E5' },
  { zone: '4AU1-6', color: '#8440E5' },
  { zone: '4AU1-7', color: '#8440E5' },
  { zone: '4AU3-1', color: '#8440E5' },
  { zone: '4AU3-2', color: '#8440E5' },
  { zone: '4AU3-3', color: '#8440E5' },
  { zone: '4AU3-4', color: '#8440E5' },
  { zone: '4AU3-5', color: '#8440E5' },
  { zone: '4AU4-1', color: '#8440E5' },
  { zone: '4AU4-2', color: '#8440E5' },
  { zone: '4AU4-3', color: '#8440E5' },
  { zone: '4AU4-4', color: '#8440E5' },
  { zone: '4AU4-5', color: '#8440E5' },
  { zone: '4AU4-6', color: '#8440E5' },
  { zone: '4AU5', color: '#8440E5' },
  { zone: '4AU6', color: '#8440E5' },
  { zone: '4N', color: '#0DCE0A' },
  { zone: '4U1-1', color: '#F22D2D' },
  { zone: '4U1-2', color: '#F22D2D' },
  { zone: '4U1-3', color: '#F22D2D' },
  { zone: '4U1-4', color: '#F22D2D' },
  { zone: '4U1-5', color: '#F22D2D' },
  { zone: '4U1-6', color: '#F22D2D' },
  { zone: '4U2-1', color: '#F22D2D' },
  { zone: '4U2-2', color: '#F22D2D' },
  { zone: '4U2-3', color: '#F22D2D' },
  { zone: '4U2-4', color: '#F22D2D' },
  { zone: '4U3-1', color: '#F22D2D' },
  { zone: '4U3-2', color: '#F22D2D' },
  { zone: '4U4-1', color: '#F22D2D' },
  { zone: '4U4-2', color: '#F22D2D' },
  { zone: '4UB', color: '#F22D2D' },
  { zone: '4UP', color: '#2DC2F2' },
  { zone: '5AU', color: '#8440E5' },
  { zone: '5AU-1', color: '#8440E5' },
  { zone: '5AU-2', color: '#8440E5' },
  { zone: '5N', color: '#0DCE0A' },
  { zone: '5UP', color: '#2DC2F2' },
  { zone: '6AU', color: '#8440E5' },
  { zone: '6AU-1', color: '#8440E5' },
  { zone: '6AU-2', color: '#8440E5' },
  { zone: '6U', color: '#F22D2D' },
  { zone: '6UP', color: '#2DC2F2' },
  { zone: '7AU', color: '#8440E5' },
  { zone: '7AU-1', color: '#8440E5' },
  { zone: '7AU-2', color: '#8440E5' },
  { zone: '7AU-3', color: '#8440E5' },
  { zone: '7AU-4', color: '#8440E5' },
  { zone: '7UP', color: '#2DC2F2' },
  { zone: '8AU', color: '#8440E5' },
  { zone: '8AU-1', color: '#8440E5' },
  { zone: '8AU-2', color: '#8440E5' },
  { zone: '8UP', color: '#2DC2F2' },
  { zone: '9AU', color: '#8440E5' },
  { zone: '9AU-1', color: '#8440E5' },
  { zone: '9AU-2', color: '#8440E5' },
  { zone: '9AU-3', color: '#8440E5' },
  { zone: '9AU-4', color: '#8440E5' },
  { zone: '9AU-5', color: '#8440E5' },
  { zone: '9UP', color: '#2DC2F2' },
  { zone: 'A', color: '#4FE504' },
  { zone: 'A ou N', color: '#0DCE0A' },
  { zone: 'A1', color: '#4FE504' },
  { zone: 'A2', color: '#4FE504' },
  { zone: 'AA', color: '#4FE504' },
  { zone: 'AB', color: '#4FE504' },
  { zone: 'AC', color: '#4FE504' },
  { zone: 'ACO', color: '#4FE504' },
  { zone: 'AE', color: '#4FE504' },
  { zone: 'AF', color: '#4FE504' },
  { zone: 'AG', color: '#4FE504' },
  { zone: 'AH', color: '#4FE504' },
  { zone: 'AI', color: '#4FE504' },
  { zone: 'AJ', color: '#4FE504' },
  { zone: 'AL', color: '#4FE504' },
  { zone: 'AO', color: '#4FE504' },
  { zone: 'AOR', color: '#4FE504' },
  { zone: 'AP', color: '#4FE504' },
  { zone: 'APS', color: '#4FE504' },
  { zone: 'AR', color: '#4FE504' },
  { zone: 'ARL', color: '#4FE504' },
  { zone: 'AS', color: '#4FE504' },
  { zone: 'AU', color: '#8440E5' },
  { zone: 'AU-A', color: '#8440E5' },
  { zone: 'AU0', color: '#8440E5' },
  { zone: 'AU0-1', color: '#8440E5' },
  { zone: 'AU0-2', color: '#8440E5' },
  { zone: 'AU0-4', color: '#8440E5' },
  { zone: 'AU0-5', color: '#8440E5' },
  { zone: 'AU0-6', color: '#8440E5' },
  { zone: 'AU0-8', color: '#8440E5' },
  { zone: 'AU0-9', color: '#8440E5' },
  { zone: 'AUA', color: '#8440E5' },
  { zone: 'AUAE', color: '#8440E5' },
  { zone: 'AUB', color: '#8440E5' },
  { zone: 'AUC', color: '#8440E5' },
  { zone: 'AUCM', color: '#8440E5' },
  { zone: 'AUD', color: '#8440E5' },
  { zone: 'AUDM', color: '#8440E5' },
  { zone: 'AUE', color: '#8440E5' },
  { zone: 'AUF', color: '#8440E5' },
  { zone: 'AUG', color: '#8440E5' },
  { zone: 'AUH', color: '#8440E5' },
  { zone: 'AUI', color: '#8440E5' },
  { zone: 'AUJSP', color: '#8440E5' },
  { zone: 'AUK', color: '#8440E5' },
  { zone: 'AUL', color: '#8440E5' },
  { zone: 'AUM', color: '#8440E5' },
  { zone: 'AUR', color: '#8440E5' },
  { zone: 'AUS', color: '#8440E5' },
  { zone: 'AUT', color: '#8440E5' },
  { zone: 'AUU', color: '#8440E5' },
  { zone: 'AUUA', color: '#8440E5' },
  { zone: 'AUX', color: '#8440E5' },
  { zone: 'AUY', color: '#8440E5' },
  { zone: 'AUZ', color: '#8440E5' },
  { zone: 'AU_I', color: '#8440E5' },
  { zone: 'AU_II', color: '#8440E5' },
  { zone: 'AUc', color: '#8440E5' },
  { zone: 'AUs', color: '#8440E5' },
  { zone: 'AUxb', color: '#8440E5' },
  { zone: 'AX', color: '#4FE504' },
  { zone: 'AY', color: '#4FE504' },
  { zone: 'Ah', color: '#4FE504' },
  { zone: 'Autoroute', color: '#A9ADA9' },
  { zone: 'Ax', color: '#4FE504' },
  { zone: 'B', color: '#2DC2F2' },
  { zone: 'C', color: '#2DC2F2' },
  { zone: 'CC', color: '#2DC2F2' },
  { zone: 'CH', color: '#2DC2F2' },
  { zone: 'CS', color: '#2DC2F2' },
  { zone: 'Carte communale', color: '#A9ADA9' },
  { zone: 'D', color: '#2DC2F2' },
  { zone: 'E', color: '#3D72F7' },
  { zone: 'EB', color: '#3AE142' },
  { zone: 'EBC', color: '#3AE142' },
  { zone: 'ER', color: '#3D72F7' },
  { zone: 'I', color: '#2DC2F2' },
  { zone: 'IAU', color: '#8440E5' },
  { zone: 'IAUX', color: '#8440E5' },
  { zone: 'II AU', color: '#8440E5' },
  { zone: 'IIAU', color: '#8440E5' },
  { zone: 'IIIAU', color: '#8440E5' },
  { zone: 'IIIUA', color: '#F22D2D' },
  { zone: 'IINA', color: '#0DCE0A' },
  { zone: 'IINAY', color: '#0DCE0A' },
  { zone: 'IIUA', color: '#F22D2D' },
  { zone: 'INA', color: '#0DCE0A' },
  { zone: 'INAY', color: '#0DCE0A' },
  { zone: 'INC', color: '#A9ADA9' },
  { zone: 'INCONNU', color: '#A9ADA9' },
  { zone: 'IUA', color: '#F22D2D' },
  { zone: 'IVAU', color: '#8440E5' },
  { zone: 'M', color: '#2DC2F2' },
  { zone: 'N', color: '#0DCE0A' },
  { zone: 'N-1', color: '#0DCE0A' },
  { zone: 'N-2', color: '#0DCE0A' },
  { zone: 'N-3', color: '#0DCE0A' },
  { zone: 'N-4', color: '#0DCE0A' },
  { zone: 'N-5', color: '#0DCE0A' },
  { zone: 'N-6', color: '#0DCE0A' },
  { zone: 'N1', color: '#0DCE0A' },
  { zone: 'N2', color: '#0DCE0A' },
  { zone: 'NA', color: '#0DCE0A' },
  { zone: 'NAV', color: '#0DCE0A' },
  { zone: 'NB', color: '#0DCE0A' },
  { zone: 'NC', color: '#0DCE0A' },
  { zone: 'NC1', color: '#0DCE0A' },
  { zone: 'ND', color: '#0DCE0A' },
  { zone: 'NDA', color: '#0DCE0A' },
  { zone: 'NDS', color: '#0DCE0A' },
  { zone: 'NDU', color: '#0DCE0A' },
  { zone: 'NE', color: '#0DCE0A' },
  { zone: 'NER', color: '#0DCE0A' },
  { zone: 'NF', color: '#0DCE0A' },
  { zone: 'NG', color: '#0DCE0A' },
  { zone: 'NGA', color: '#0DCE0A' },
  { zone: 'NGB', color: '#0DCE0A' },
  { zone: 'NGD', color: '#0DCE0A' },
  { zone: 'NH', color: '#0DCE0A' },
  { zone: 'NHD', color: '#0DCE0A' },
  { zone: 'NHL', color: '#0DCE0A' },
  { zone: 'NI', color: '#0DCE0A' },
  { zone: 'NJ', color: '#0DCE0A' },
  { zone: 'NK', color: '#0DCE0A' },
  { zone: 'NL', color: '#0DCE0A' },
  { zone: 'NLC', color: '#0DCE0A' },
  { zone: 'NLT', color: '#0DCE0A' },
  { zone: 'NM', color: '#0DCE0A' },
  { zone: 'NMP', color: '#0DCE0A' },
  { zone: 'NO', color: '#0DCE0A' },
  { zone: 'NOR', color: '#0DCE0A' },
  { zone: 'NP', color: '#0DCE0A' },
  { zone: 'NPL', color: '#0DCE0A' },
  { zone: 'NPSM', color: '#0DCE0A' },
  { zone: 'NR', color: '#0DCE0A' },
  { zone: 'NS', color: '#0DCE0A' },
  { zone: 'NSE', color: '#0DCE0A' },
  { zone: 'NT', color: '#0DCE0A' },
  { zone: 'NUA', color: '#0DCE0A' },
  { zone: 'NV', color: '#0DCE0A' },
  { zone: 'NX', color: '#0DCE0A' },
  { zone: 'NY', color: '#0DCE0A' },
  { zone: 'NZ', color: '#0DCE0A' },
  { zone: 'NZR', color: '#0DCE0A' },
  { zone: 'Nc', color: '#0DCE0A' },
  { zone: 'Nd', color: '#0DCE0A' },
  { zone: 'Nds', color: '#0DCE0A' },
  { zone: 'Nh', color: '#0DCE0A' },
  { zone: 'Ni', color: '#0DCE0A' },
  { zone: 'Nl', color: '#0DCE0A' },
  { zone: 'None', color: '#A9ADA9' },
  { zone: 'Np', color: '#0DCE0A' },
  { zone: 'O', color: '#3D72F7' },
  { zone: 'O-AU', color: '#8440E5' },
  { zone: 'OAP', color: '#3D72F7' },
  { zone: 'OAP1', color: '#3D72F7' },
  { zone: 'OAU', color: '#8440E5' },
  { zone: 'OAUEQ', color: '#8440E5' },
  { zone: 'P', color: '#3D72F7' },
  { zone: 'PAE', color: '#3D72F7' },
  { zone: 'PAPAG', color: '#3D72F7' },
  { zone: 'PM', color: '#3D72F7' },
  { zone: 'PSMV', color: '#F8A83C' },
  { zone: 'R', color: '#3D72F7' },
  { zone: 'RNU', color: '#A9ADA9' },
  { zone: 'Route', color: '#A9ADA9' },
  { zone: 'S', color: '#3D72F7' },
  { zone: 'SPECAL', color: '#3D72F7' },
  { zone: 'SPR', color: '#F8A83C' },
  { zone: 'STECAL', color: '#3D72F7' },
  { zone: 'U', color: '#F22D2D' },
  { zone: 'U B', color: '#F22D2D' },
  { zone: 'U1', color: '#F22D2D' },
  { zone: 'U2', color: '#F22D2D' },
  { zone: 'U3', color: '#F22D2D' },
  { zone: 'U4', color: '#F22D2D' },
  { zone: 'U5', color: '#F22D2D' },
  { zone: 'U6', color: '#F22D2D' },
  { zone: 'U7', color: '#F22D2D' },
  { zone: 'UA', color: '#F22D2D' },
  { zone: 'UA1', color: '#F22D2D' },
  { zone: 'UA2', color: '#F22D2D' },
  { zone: 'UA3', color: '#F22D2D' },
  { zone: 'UA4', color: '#F22D2D' },
  { zone: 'UAA', color: '#F22D2D' },
  { zone: 'UAB', color: '#F22D2D' },
  { zone: 'UAC', color: '#F22D2D' },
  { zone: 'UAE', color: '#F22D2D' },
  { zone: 'UAL', color: '#F22D2D' },
  { zone: 'UAM', color: '#F22D2D' },
  { zone: 'UAR1.1', color: '#F22D2D' },
  { zone: 'UAR4.2', color: '#F22D2D' },
  { zone: 'UAR6.1', color: '#F22D2D' },
  { zone: 'UAR6.2', color: '#F22D2D' },
  { zone: 'UAV', color: '#F22D2D' },
  { zone: 'UA_special', color: '#F22D2D' },
  { zone: 'UB', color: '#F22D2D' },
  { zone: 'UB1', color: '#F22D2D' },
  { zone: 'UB2', color: '#F22D2D' },
  { zone: 'UBA', color: '#F22D2D' },
  { zone: 'UBB', color: '#F22D2D' },
  { zone: 'UBC', color: '#F22D2D' },
  { zone: 'UBD', color: '#F22D2D' },
  { zone: 'UBE', color: '#F22D2D' },
  { zone: 'UBK', color: '#F22D2D' },
  { zone: 'UBL', color: '#F22D2D' },
  { zone: 'UBM', color: '#F22D2D' },
  { zone: 'UBV', color: '#F22D2D' },
  { zone: 'UC', color: '#F22D2D' },
  { zone: 'UC1', color: '#F22D2D' },
  { zone: 'UC2', color: '#F22D2D' },
  { zone: 'UCA', color: '#F22D2D' },
  { zone: 'UCB', color: '#F22D2D' },
  { zone: 'UCC', color: '#F22D2D' },
  { zone: 'UCD', color: '#F22D2D' },
  { zone: 'UCF', color: '#F22D2D' },
  { zone: 'UCH', color: '#F22D2D' },
  { zone: 'UCL', color: '#F22D2D' },
  { zone: 'UCV', color: '#2DC2F2' },
  { zone: 'UD', color: '#F22D2D' },
  { zone: 'UDI', color: '#F22D2D' },
  { zone: 'UDT', color: '#F22D2D' },
  { zone: 'UD_special', color: '#F22D2D' },
  { zone: 'UE', color: '#F22D2D' },
  { zone: 'UE.1', color: '#F73D8C' },
  { zone: 'UEA', color: '#F73D8C' },
  { zone: 'UEC', color: '#F73D8C' },
  { zone: 'UEC1', color: '#F73D8C' },
  { zone: 'UEC2', color: '#F73D8C' },
  { zone: 'UEJ', color: '#F73D8C' },
  { zone: 'UEK', color: '#F73D8C' },
  { zone: 'UEL', color: '#F73D8C' },
  { zone: 'UEP', color: '#F73D8C' },
  { zone: 'UEQ', color: '#F73D8C' },
  { zone: 'UER', color: '#F73D8C' },
  { zone: 'UEV', color: '#F73D8C' },
  { zone: 'UF', color: '#F22D2D' },
  { zone: 'UFB', color: '#F22D2D' },
  { zone: 'UG', color: '#F22D2D' },
  { zone: 'UGD', color: '#F22D2D' },
  { zone: 'UGSU', color: '#F22D2D' },
  { zone: 'UGV', color: '#F22D2D' },
  { zone: 'UH', color: '#F22D2D' },
  { zone: 'UH1', color: '#F22D2D' },
  { zone: 'UH2', color: '#F22D2D' },
  { zone: 'UH3', color: '#F22D2D' },
  { zone: 'UH4', color: '#F22D2D' },
  { zone: 'UHA', color: '#F22D2D' },
  { zone: 'UHB', color: '#F22D2D' },
  { zone: 'UHL', color: '#F22D2D' },
  { zone: 'UHM', color: '#F22D2D' },
  { zone: 'UI', color: '#F73D8C' },
  { zone: 'UI1', color: '#F73D8C' },
  { zone: 'UIA', color: '#F73D8C' },
  { zone: 'UIG', color: '#F73D8C' },
  { zone: 'UIL', color: '#F73D8C' },
  { zone: 'UIV', color: '#F73D8C' },
  { zone: 'UJ', color: '#F73D8C' },
  { zone: 'UK', color: '#2DC2F2' },
  { zone: 'UL', color: '#2DC2F2' },
  { zone: 'UL1', color: '#2DC2F2' },
  { zone: 'UL2', color: '#2DC2F2' },
  { zone: 'ULC', color: '#2DC2F2' },
  { zone: 'ULM', color: '#2DC2F2' },
  { zone: 'ULMH', color: '#2DC2F2' },
  { zone: 'UM', color: '#F22D2D' },
  { zone: 'UM2', color: '#F22D2D' },
  { zone: 'UM3', color: '#F22D2D' },
  { zone: 'UMD', color: '#F22D2D' },
  { zone: 'UMG', color: '#F22D2D' },
  { zone: 'UMH', color: '#F22D2D' },
  { zone: 'UN', color: '#0DCE0A' },
  { zone: 'UO', color: '#F22D2D' },
  { zone: 'UP', color: '#F22D2D' },
  { zone: 'UP1', color: '#F22D2D' },
  { zone: 'UP2', color: '#F22D2D' },
  { zone: 'UP3', color: '#F22D2D' },
  { zone: 'UP4', color: '#F22D2D' },
  { zone: 'UP5', color: '#F22D2D' },
  { zone: 'UP6', color: '#F22D2D' },
  { zone: 'UPM', color: '#F22D2D' },
  { zone: 'UR', color: '#F22D2D' },
  { zone: 'UR1', color: '#F22D2D' },
  { zone: 'UR1*', color: '#F22D2D' },
  { zone: 'UR2', color: '#F22D2D' },
  { zone: 'UR3', color: '#F22D2D' },
  { zone: 'URC', color: '#F22D2D' },
  { zone: 'URM1', color: '#F22D2D' },
  { zone: 'URM2', color: '#F22D2D' },
  { zone: 'URM2*', color: '#F22D2D' },
  { zone: 'URP', color: '#F22D2D' },
  { zone: 'US', color: '#F22D2D' },
  { zone: 'USM', color: '#F22D2D' },
  { zone: 'USP', color: '#F22D2D' },
  { zone: 'USV', color: '#F22D2D' },
  { zone: 'UT', color: '#F22D2D' },
  { zone: 'UT1', color: '#F22D2D' },
  { zone: 'UT2', color: '#F22D2D' },
  { zone: 'UT3', color: '#F22D2D' },
  { zone: 'UTL', color: '#F22D2D' },
  { zone: 'UTL2', color: '#F22D2D' },
  { zone: 'UTL3', color: '#F22D2D' },
  { zone: 'UV', color: '#F22D2D' },
  { zone: 'UV1', color: '#F22D2D' },
  { zone: 'UV2.1', color: '#F22D2D' },
  { zone: 'UVB', color: '#F22D2D' },
  { zone: 'UVK', color: '#F22D2D' },
  { zone: 'UVP', color: '#F22D2D' },
  { zone: 'UX', color: '#F22D2D' },
  { zone: 'UX.1', color: '#F22D2D' },
  { zone: 'UX.12', color: '#F22D2D' },
  { zone: 'UX1', color: '#F22D2D' },
  { zone: 'UX2', color: '#F22D2D' },
  { zone: 'UX3', color: '#F22D2D' },
  { zone: 'UXA', color: '#F22D2D' },
  { zone: 'UXB', color: '#F22D2D' },
  { zone: 'UXC', color: '#F22D2D' },
  { zone: 'UXD', color: '#F22D2D' },
  { zone: 'UXF', color: '#F22D2D' },
  { zone: 'UXZ', color: '#F22D2D' },
  { zone: 'UY', color: '#F22D2D' },
  { zone: 'UYA', color: '#F22D2D' },
  { zone: 'UYAR', color: '#F22D2D' },
  { zone: 'UYB', color: '#F22D2D' },
  { zone: 'UYC', color: '#F22D2D' },
  { zone: 'UYE', color: '#F22D2D' },
  { zone: 'UYM', color: '#F22D2D' },
  { zone: 'UYT', color: '#F22D2D' },
  { zone: 'UYZ', color: '#F22D2D' },
  { zone: 'UZ', color: '#F22D2D' },
  { zone: 'UZ2', color: '#F22D2D' },
  { zone: 'UZA', color: '#F22D2D' },
  { zone: 'UZC', color: '#F22D2D' },
  { zone: 'UZD', color: '#F22D2D' },
  { zone: 'UZFA', color: '#F22D2D' },
  { zone: 'UZFB', color: '#F22D2D' },
  { zone: 'UZK', color: '#F22D2D' },
  { zone: 'UZP', color: '#F22D2D' },
  { zone: 'UZPA', color: '#F22D2D' },
  { zone: 'UZR', color: '#F22D2D' },
  { zone: 'Ub', color: '#F22D2D' },
  { zone: 'Uh', color: '#F22D2D' },
  { zone: 'Ux', color: '#F22D2D' },
  { zone: 'V', color: '#3D72F7' },
  { zone: 'VAU', color: '#8440E5' },
  { zone: 'X', color: '#3D72F7' },
  { zone: 'YX', color: '#3D72F7' },
  { zone: 'Z', color: '#3D72F7' },
  { zone: 'ZA', color: '#3D72F7' },
  { zone: 'ZAC', color: '#3D72F7' },
  { zone: 'ZC', color: '#3D72F7' },
  { zone: 'ZNC', color: '#0DCE0A' },
  { zone: 'i', color: '#3D72F7' },
  { zone: 'inconnu', color: '#A9ADA9' },
  { zone: 'l', color: '#3D72F7' },
  { zone: 'n', color: '#0DCE0A' },
  { zone: 'other', color: '#A9ADA9' },
  { zone: 'ri', color: '#3D72F7' },
  { zone: 'uUA', color: '#F22D2D' },
  { zone: 'ua', color: '#F22D2D' },
  { zone: 'uc', color: '#F22D2D' },
];

export const pluGenericRules = [
  { zone: 'AU', color: '#8440E5' },
  { zone: 'UA', color: '#F22D2D' },
  { zone: 'UB', color: '#F22D2D' },
  { zone: 'UC', color: '#F22D2D' },
  { zone: 'UD', color: '#F22D2D' },
  { zone: 'UE', color: '#F73D8C' },
  { zone: 'UX', color: '#F73D8C' },
  { zone: 'UI', color: '#F73D8C' },
  { zone: 'N', color: '#0DCE0A' },
  { zone: 'A', color: '#4FE504' },
  { zone: 'EBC', color: '#3AE142' },
  { zone: 'SPR', color: '#F8A83C' },
  { zone: 'PSMV', color: '#F8A83C' },
  { zone: 'Inconnu', color: '#2DC2F2' },
  { zone: 'ZAC', color: '#3D72F7' },
  { zone: 'OAP', color: '#3D72F7' },
  { zone: 'STECAL', color: '#3D72F7' },
  { zone: 'PAPAG', color: '#3D72F7' },
];
