export const AccountTabsValues = [
  { id: 'abonnement', title: 'Abonnement', role: ['user'] },
  {
    id: 'team',
    title: `Gérer l'équipe de prospection`,
    role: ['admin', 'manager'],
  },
  {
    id: 'mailshots',
    title: `Modèles de publipostage`,
    role: ['admin', 'manager'],
  },
  {
    id: 'pwd_change',
    title: 'Changer de mot de passe',
    role: ['user'],
  },
  { id: 'faq', title: 'FAQ et Formation', role: ['user'] },
  {
    id: 'rdv',
    title: 'Mes rendez-vous à venir',
    role: ['user'],
  },
];
