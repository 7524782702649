import useProcedureTypes from '../../../../../App/hooks/useProcedureTypes';
import SelectFormCustom from '../../../../../components/Common/FormComponents/SelectFormCustom';

interface IProcedureTypeSelectProps {
  name: string;
  className?: string;
  label?: nullable<string>;
  disabled?: boolean;
}
export default function ProcedureTypeSelect({
  name,
  className,
  disabled,
  label,
}: IProcedureTypeSelectProps) {
  const { proceduresTypesForSelect } = useProcedureTypes();
  return (
    <SelectFormCustom
      name={name}
      className={className}
      displayEmpty
      label={label}
      noValue="Choisir une action"
      items={proceduresTypesForSelect()}
      disabled={disabled}
    />
  );
}
