import { nanoid } from '@reduxjs/toolkit';
import { FeatureCollection } from 'geojson';
import { isEmpty } from 'lodash';
import { memo, useEffect, useState } from 'react';
import { APIStatus } from '../../../services/axiosFiles/apiTypes';
import { distinctElementsFromArray } from '../../../utils/jsFunctions';
import IndividualCircularLoader from '../../loaders/individualCircularLoader';
import GroupRow from './GroupRow';
import LoadingError from './LoadingError';
import PlotServitudesSectionRow from './PlotServitudesSectionRow';
import styles from './plotServitudesContent.module.scss';

interface IPlotServitudesSectionProps {
  data: IApiState<FeatureCollection | null>;
  clickable?: boolean;
  group?: 'libelle' | 'typeass';
  type: 'ppct' | 'plin' | 'isurf' | 'ipct' | 'sups' | 'scot';
}

function PlotServitudesSection({
  clickable,
  group,
  data,
  type,
}: IPlotServitudesSectionProps) {
  const [groups, setGroups] = useState<string[]>([]);

  useEffect(() => {
    if (group && data.result) {
      let multi: string[] = [];

      if (group === 'libelle') {
        multi = data.result.features
          .map((f) => f.properties?.libelle ?? f.properties?.nomsuplitt)
          .filter((f, i, t) => t.filter((sf) => sf === f).length > 1)
          .filter(distinctElementsFromArray);
      } else {
        multi = data.result.features
          .map((f) => f.properties?.typeass)
          .filter((f, i, t) => {
            return t.filter((sf) => sf === f).length > 1;
          })
          .filter(distinctElementsFromArray);
      }

      if (!isEmpty(multi)) {
        setGroups(multi);
      }
    }
  }, [data.result]);

  if (data.apiStatus === APIStatus.REJECTED) {
    return <LoadingError type={type} />;
  }
  if (data.apiStatus === APIStatus.PENDING) {
    return <IndividualCircularLoader size={50} />;
  }

  if (data.apiStatus === APIStatus.IDLE && isEmpty(data.result?.features)) {
    return <p>Aucune donnée pour cette parcelle</p>;
  }

  return (
    <div className={styles.section}>
      {isEmpty(groups) ? (
        data.result?.features.map((fd) => (
          <PlotServitudesSectionRow
            key={nanoid()}
            featureId={fd.id as string}
            libelle={
              fd.properties?.libelle ??
              fd.properties?.nomsuplitt ??
              fd.properties?.nomass ??
              fd.properties?.title
            }
            clickable={clickable}
          />
        ))
      ) : (
        <>
          {groups.map((g) => {
            const filteredTab = data.result?.features.filter((f) => {
              if (f.properties?.libelle) {
                return f.properties?.libelle === g;
              } else {
                return f.properties?.typeass === g;
              }
            });
            const idTab = filteredTab?.map((m) => m.id as string) as string[];
            return (
              <GroupRow key={nanoid()} libelle={g} idTab={idTab}>
                {filteredTab?.map((fd) => (
                  <PlotServitudesSectionRow
                    key={nanoid()}
                    featureId={fd.id as string}
                    libelle={fd.properties?.libelle ?? fd.properties?.nomsuplitt}
                    clickable={clickable}
                  />
                ))}
              </GroupRow>
            );
          })}
          {data.result?.features
            .filter((f) => {
              if (group === 'libelle') {
                return !groups.includes(
                  f.properties?.libelle ?? f.properties?.nomsuplitt
                );
              } else {
                return !groups.includes(f.properties?.typeass);
              }
            })
            .map((m) => {
              return (
                <PlotServitudesSectionRow
                  key={nanoid()}
                  featureId={m.id as string}
                  libelle={
                    m.properties?.libelle ??
                    m.properties?.nomsuplitt ??
                    m.properties?.typeass
                  }
                  clickable={clickable}
                />
              );
            })}
        </>
      )}
    </div>
  );
}

export default memo(PlotServitudesSection);
