import { Button } from '@mui/material';
import { isEmpty } from 'lodash';
import { ReactNode } from 'react';
import { useAppDispatch } from '../../../../../../App/store';
import plotActions from '../../../../../../redux/plot/actions';
import { euroFormat } from '../../../../../../utils/jsFunctions';

interface IBalanceBankAccountsProps {
  datas: IBalanceBankAccount | undefined;
}

function BalanceBankAccounts({ datas }: IBalanceBankAccountsProps) {
  const dispatch = useAppDispatch();

  const handleDonwloadClick = () => {
    dispatch(plotActions.fetchCityBankAccountDatas.request());
  };

  const parseResult = (result: string | undefined): ReactNode => {
    if (!result) {
      return '';
    }
    // if no data, display came message frop python
    if (!result.includes(',')) {
      return result;
    }

    const splited = result.split(',');

    return (
      <span>
        {splited[0]}
        <br />
        {splited[1].trim()}
      </span>
    );
  };

  return (
    <div className="balance-bank-accounts">
      {!isEmpty(datas) ? (
        <>
          <p>
            Nombre de comptes étudiés :{' '}
            <span>
              {datas?.totalBankAccounts ? ` ${datas?.totalBankAccounts}` : ' -'}
            </span>
          </p>
          <p>
            Solde débiteur total calculé :
            <span>
              {datas?.totalDebit ? ` ${euroFormat(datas?.totalDebit)}` : ' -'}
            </span>
          </p>
          <p>
            Solde créditeur total calculé :
            <span>
              {datas?.totalCredit ? ` ${euroFormat(datas?.totalCredit)}` : ' -'}
            </span>
          </p>
          <p>
            Résultat : <span>{datas?.resultat}</span>
          </p>
          <br />
          <span>{parseResult(datas?.result)}</span>
          <Button className="form-btn" onClick={handleDonwloadClick}>
            Charger les détails
          </Button>
        </>
      ) : (
        <p>Aucune information trouvée</p>
      )}
    </div>
  );
}

export default BalanceBankAccounts;
