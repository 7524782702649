import { nanoid } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { appActions } from '../../App/appSlice';
import useRightPanel from '../../App/hooks/useRightPanel';
import { useAppDispatch, useAppSelector } from '../../App/store';
import { getAuthState } from '../../features/auth/authSlice';
import {
  getMailshotsState,
  mailshotsActions,
} from '../../features/mailshots/mailshotsSlice';
import styles from './accountTabs.module.scss';
import { AccountTabsValues } from './constant';

interface IAccountTabsProps {
  selectedTab: AccountTab;
}

const AccountTabs = ({ selectedTab }: IAccountTabsProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { isManager } = useAppSelector(getAuthState);
  const { updatingQuill } = useAppSelector(getMailshotsState);
  const { closeRightPanel, rightPanelDisplay } = useRightPanel();

  const handleTabChange = (value: AccountTab) => {
    if (rightPanelDisplay) {
      closeRightPanel();
    }
    if (selectedTab === 'mailshots' && updatingQuill) {
      dispatch(
        mailshotsActions.setQuillDatasBeforeNavigation({
          accountTab: value,
          content: null,
        })
      );
    } else {
      dispatch(appActions.setAccountTab(value));
    }
  };

  useEffect(() => {
    return () => {
      closeRightPanel();
    };
  }, []);

  return (
    <div className={styles.accountTabs}>
      {AccountTabsValues.filter((elt) => {
        let display: boolean = isManager ? Boolean(elt) : elt.role.includes('user');

        return display;
      }).map((el) => (
        <div
          key={nanoid()}
          onClick={() => handleTabChange(el.id as AccountTab)}
          className={[
            styles.accountTab,
            selectedTab === el.id ? styles.selected : '',
          ].join(' ')}
        >
          {el.title}
        </div>
      ))}
    </div>
  );
};

export default AccountTabs;
