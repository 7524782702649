import { Button } from '@mui/material';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../App/store';
import { getCompanyState } from '../../company/companySlice';
import { getUsersState } from '../../users/usersSlice';
import { getDashboardReportingState } from '../dashboardReportingSlice';
import { fetchUsersStatsThunk } from '../dashboardReportingThunks';

import DatePickerCustom from '../../../components/Common/DatePickerCustom';
import FilterSortBloc from '../../../components/filterSortBloc';
import ReportingTableContainer from './ReportingTableContainer';
import { handleExportExcelUsers } from './excel';

const defaultError = { hasError: false, message: '' };

function ReportingOverall(): JSX.Element {
  const dispatch = useAppDispatch();
  const { users } = useAppSelector(getUsersState);
  const { companyId } = useAppSelector(getCompanyState);
  const { inputValueReportingOverall, activeUsersStats } = useAppSelector(
    getDashboardReportingState
  );
  const [sortKey, setSortKey] = useState<string>('');
  const [order, setOrder] = useState<Order>('asc');
  const [createdAtAfter, setCreatedAtAfter] = useState<Date | null>(
    inputValueReportingOverall.createdAtAfter ?? new Date()
  );
  const [createdAtBefore, setCreatedAtBefore] = useState<Date | null>(
    inputValueReportingOverall.createdAtBefore ?? new Date()
  );

  const [dateError, setDateError] = useState<{
    hasError: boolean;
    message: string;
  }>(defaultError);

  const request = () => {
    dispatch(
      fetchUsersStatsThunk({
        createdAtAfter: format(startOfMonth(createdAtAfter as Date), 'yyyy-MM-dd'),
        createdAtBefore: format(endOfMonth(createdAtBefore as Date), 'yyyy-MM-dd'),
      })
    );
  };
  useEffect(() => {
    if (users && companyId) {
      request();
    }
  }, [users, companyId]);

  const onSubmit = () => {
    if (users && companyId) {
      request();
    }
  };

  const handleDateChange = (type: 'after' | 'before', e: Date | null) => {
    switch (type) {
      case 'after':
        if (e && createdAtBefore && e > createdAtBefore) {
          setDateError({
            hasError: true,
            message: 'La date de début ne doit pas être supérieure à la date de fin',
          });
        } else {
          setCreatedAtAfter(e);
          setDateError(defaultError);
        }
        break;
      case 'before':
        if (e && createdAtAfter && e < createdAtAfter) {
          setDateError({
            hasError: true,
            message: 'La date de fin ne doit pas être inférieure à la date de début',
          });
        } else {
          setCreatedAtBefore(e);
          setDateError(defaultError);
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div className="reporting-table-action">
        <div className="reporting-table-action-filter">
          <div className="reporting-table-action-filter-fields">
            <div>
              de:{' '}
              <DatePickerCustom
                value={createdAtAfter}
                onChange={(e: Date | null) => handleDateChange('after', e)}
                format="MMMM yyyy"
                setError={dateError.hasError}
                minDate={new Date('2022-09-01')}
                maxDate={new Date()}
                actions={['cancel', 'accept']}
              />{' '}
              à{' '}
              <DatePickerCustom
                value={createdAtBefore}
                onChange={(e: Date | null) => handleDateChange('before', e)}
                format="MMMM yyyy"
                setError={dateError.hasError}
                minDate={new Date('2022-09-01')}
                maxDate={new Date()}
                actions={['cancel', 'accept']}
              />
            </div>
            <p
              className={`error-between-dates ${
                dateError.hasError ? 'visible' : ''
              }`}
            >
              {dateError.message}
            </p>
          </div>
          <Button type="submit" onClick={() => onSubmit()}>
            Rechercher
          </Button>
        </div>
        <div>
          <Button onClick={() => handleExportExcelUsers(activeUsersStats.result)}>
            {' '}
            Télécharger rapport
          </Button>
          <FilterSortBloc
            setSortKey={setSortKey}
            setOrder={setOrder}
            selectItems={[
              { value: 'authenticationCounter', display: 'Connexions' },
              { value: 'plotStudiesCounter', display: 'Opportunités étudiées' },
              { value: 'plotStudiesValidCounter', display: 'Opportunités signées' },
              { value: 'proceduresCounter', display: 'Actions prospection' },
              { value: 'plotStudiesValidRate', display: 'Taux de succès' },
            ]}
            selectPlaceHolder="Trier membres par"
          />
        </div>
      </div>

      <ReportingTableContainer
        usersStats={activeUsersStats}
        order={order}
        sortKey={sortKey}
      />
    </>
  );
}

export default ReportingOverall;
