import L from 'leaflet';
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { modalsActions } from '../../../../modals/modalsSlice';
import '../lib/altimetry/ElevationPath';
import '../lib/altimetry/elevationPath.scss';
import '../lib/altimetry/urba-altimetry.module.scss';
import { getMapPluginsAltimetryState, mapPluginsActions } from '../mapPluginSlice';
import AltimetryActionEnum from './types/altimetryActionEnum';

function AltimetryLayer() {
  const [control, setControl] = useState<any | null>(null);
  const { action } = useAppSelector(getMapPluginsAltimetryState);
  const dispatch = useAppDispatch();

  const map = useMap();

  useEffect(() => {
    const elevation = new (L.Control as any).ElevationPath({
      options: {
        position: 'topright',
        active: false,
        elevationPathOptions: {},
        stylesOptions: {},
        displayProfileOptions: {
          greaterSlope: true,
          meanSlope: true,
          ascendingElevation: true,
          descendingElevation: true,
          currentSlope: true,
          apply: null,
          target: null,
        },
      },
    });

    elevation.addTo(map);
    setControl(elevation);
  }, []);

  useEffect(() => {
    if (control) {
      switch (action) {
        case AltimetryActionEnum.ALTIMETRY_START:
          control.onShowElevationPathClick();
          dispatch(modalsActions.altimetryHelperModal(true));
          break;
        case AltimetryActionEnum.ALTIMETRY_STOP:
          control.onShowElevationPathClick();
          dispatch(mapPluginsActions.resetAltimetry());
          break;
        default:
          break;
      }
    }
  }, [action]);

  return null;
}

export default AltimetryLayer;
