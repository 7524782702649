export default function zoneAndZoneChildParser(data: any): IZoneState {
  const result: IZoneState = {};

  delete data.city_name;
  delete data.city_zipcode;

  result.zones = Object.keys(data);
  result.zoneChilds = data;

  return result;
}
