import { nanoid } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';
import { memo } from 'react';
import ProcedureCard from './ProcedureCard';

interface IProps {
  studyProcedureList: StudyProcedures | null;
  updateAllowed: boolean;
}

function ProcedureCardMap({ studyProcedureList, updateAllowed }: IProps) {
  return (
    <>
      {studyProcedureList?.map((p) => (
        <ProcedureCard key={nanoid()} procedure={p} updateAllowed={updateAllowed} />
      ))}
    </>
  );
}

// check for no render if list not change (cancel on popup)
export default memo(ProcedureCardMap, (prev: IProps, next: IProps) =>
  isEqual(prev.studyProcedureList, next.studyProcedureList)
);
