import ButtonGeneric from '../../../../../shared/components/buttons/buttonGeneric/ButtonGeneric';
import styles from '../../../styles/mailshotAccountTabContent.module.scss';
import { MailshotTagEnum } from '../../../types/mailshotsEnums';
import { ITagBtnClickProps } from './TagButtonContainer';

function CompanyContent({ onBtnClick }: ITagBtnClickProps) {
  const color: ColorType = 'DARK_PRIMARY';
  const handleClick = (tag: MailshotTagEnum) => onBtnClick(tag, color);

  return (
    <div className={styles.btnContainer}>
      <ButtonGeneric
        content="Nom de la société"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.NOM_COMPAGNIE)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Adresse de la société"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.ADRESSE_COMPAGNIE)}
        containerClassName={styles.button}
      />
      <ButtonGeneric
        content="Téléphone de la société"
        bgColor={color}
        onClick={() => handleClick(MailshotTagEnum.TELEPHONE_COMPAGNIE)}
        containerClassName={styles.button}
      />
    </div>
  );
}

export default CompanyContent;
