import { mapObjectParser } from '../../../services/common/parserCommon';
import genericSort from '../../../utils/genericSort';
import { isRecentCalc } from '../../buildPermits/buildPermitsOnMap/utils';

export const dpePointsParser = (datas: any) => {
  try {
    const parsedData = datas.result.map((m: any) => {
      return {
        dpeClass: m.classe_dpe,
        lastDate: m.date_dpe?.split('-').reverse().join('-'),
        address: m.geo_adresse,
        insee: m.insee,
        lat: parseFloat(m.latitude),
        lng: parseFloat(m.longitude),
        numDpe: m.num_dpe,
        isRecent: isRecentCalc(m.date_dpe),
      };
    });

    return parsedData as DpePoints;
  } catch (error) {
    throw new Error('dpeParser error');
  }
};

export const dpesParser = (datas: any): DPES => {
  const parsedDatas = mapObjectParser(datas.result, dpeParser);

  return parsedDatas;
};

export const dpeParser = (dpe: any) => {
  const parsedData: DPE = {
    plotId: dpe.parcel_id?.substring(5) ?? 'Inconnu',
    dpeId: dpe.num_dpe,
    date: dpe.date_dpe?.split('-').reverse().join('-') ?? null,
    lng: parseFloat(dpe.longitude),
    lat: parseFloat(dpe.latitude),
    dpeClass: dpe.classe_dpe,
    gesClass: dpe.classe_ges,
    builtYear: parseFloat(dpe.annee_construction),
    dpeTypology: dpe.typo_dpe,
    dpeUtility: dpe.utilite_dpe,
    area: parseFloat(dpe.surface),
    address: dpe.geo_adresse,
    postalCode: dpe.code_postal,
    city: dpe.commune,
    builtTypology: dpe.typo_batiment,
    heatingType: dpe.typo_chauffage,
    ecsInstallationType: dpe.type_installation_ecs,
    heightUnderCeiling: parseFloat(dpe.hauteur_sous_plafond),
    appartmentCount: parseFloat(dpe.nb_appartements),
    builtLevels: parseFloat(dpe.nb_niveaux_logement),
    floor: parseFloat(dpe.etage),
    dateSort: parseInt(dpe.date?.replaceAll('-', '') ?? ''),
    built: dpe.batiment,
    // roomCount: parseInt(dpe.pieces, 10)
  };

  (Object.keys(parsedData) as (keyof typeof parsedData)[]).forEach((f) => {
    if (parsedData[f] === 'Non renseigné') {
      parsedData[f] = null;
    }
  });

  return parsedData;
};

export const dpePopupContentParser = (data: DPES) => {
  const sortedData: DPES = genericSort(data, 'desc', 'dateSort');
  const popupContent: DpeForMap = {
    plotId: sortedData[0].plotId,
    lat: sortedData[0].lat,
    lng: sortedData[0].lng,
    dpeClass: sortedData[0].dpeClass,
    content: sortedData,
    tabs: sortedData.map((m: DPE) => {
      return { tab: m.date ?? 'Indefinie', dpeClass: m.dpeClass };
    }),
  };

  return popupContent;
};
