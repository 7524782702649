import { createAsyncThunk } from '@reduxjs/toolkit';
import deleteStudyContact from '../deleteStudyContact';

const deleteStudyContactThunk = createAsyncThunk(
  'study/deleteStudyContactThunk',
  async (params: { studyContactIdIri: string | null }, thunkOptions) => {
    try {
      const result = await deleteStudyContact(params.studyContactIdIri);

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default deleteStudyContactThunk;
