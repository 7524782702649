import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface IProps {
  name: string;
  disabled?: boolean;
}
function PriceStatInput({ name, disabled }: IProps) {
  const { control, setValue } = useFormContext();

  const handleChange = (e?: number) => {
    setValue(name, e ?? 0);
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value } }) => (
        <fieldset>
          <NumericFormat
            value={value ?? 0}
            onValueChange={(values) => handleChange(values.floatValue)}
            onFocus={(e) => e.target.select()}
            disabled={disabled}
            thousandSeparator=" "
            suffix={' €'}
            autoComplete="off"
          />
        </fieldset>
      )}
    />
  );
}

export default PriceStatInput;
