import { MouseEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../App/store';
import { getMapPluginsDrawState, mapPluginsActions } from '../mapPluginSlice';
import CreateToolbarBtn from '../toolbars/urbaToolbar/CreateToolbarBtn';
import ToolbarCancelBtn from '../toolbars/urbaToolbar/ToolbarCancelBtn';

import ToolbarTooltip from '../toolbars/urbaToolbar/ToolbarTooltip';
import { DrawActionEnum, DrawIconEnum } from './types/drawActionEnum';
function DrawToolbar() {
  const { action, displayedIcons } = useAppSelector(getMapPluginsDrawState);
  const dispatch = useAppDispatch();

  const handleClick = (e: MouseEvent<HTMLDivElement>, type: IconType) => {
    switch (type) {
      case DrawIconEnum.DRAW_ICON_START:
        if (action === DrawActionEnum.DRAW_ADVANCED_SEARCH) {
          dispatch(
            mapPluginsActions.drawStart(DrawActionEnum.DRAW_ADVANCED_SEARCH_START)
          );
        }
        break;
      case DrawIconEnum.DRAW_ICON_STOP:
        if (action === DrawActionEnum.DRAW_ADVANCED_SEARCH_START) {
          dispatch(
            mapPluginsActions.drawActionSet(
              DrawActionEnum.DRAW_ADVANCED_SEARCH_CANCEL
            )
          );
        } else {
          dispatch(mapPluginsActions.drawStop());
        }

        return;
      case DrawIconEnum.DRAW_ICON_EDIT_SAVE:
        dispatch(mapPluginsActions.drawActionSet(DrawActionEnum.EDIT_SAVE));
        return;
      case DrawIconEnum.DRAW_ICON_EDIT_CANCEL:
        dispatch(mapPluginsActions.drawActionSet(DrawActionEnum.EDIT_CANCEL));
        return;

      default:
        return;
    }
  };

  return (
    <>
      {action === DrawActionEnum.DRAW_ADVANCED_SEARCH && (
        <CreateToolbarBtn
          icon={DrawIconEnum.DRAW_ICON_START}
          onClick={handleClick}
          displayed={displayedIcons}
          tooltip={<ToolbarTooltip text="Active le tracé" />}
        />
      )}
      {/* add specific icon  */}
      {(action === DrawActionEnum.DRAW_ADVANCED_SEARCH_START ||
        action === DrawActionEnum.DRAW_SECTOR) && (
        <ToolbarCancelBtn
          type={DrawIconEnum.DRAW_ICON_STOP}
          onClick={handleClick}
          tooltip={<ToolbarTooltip text="Annuler le tracé en cours" />}
        />
      )}
      {action === DrawActionEnum.EDIT && (
        <>
          <CreateToolbarBtn
            icon={DrawIconEnum.DRAW_ICON_EDIT_SAVE}
            isFontAwesomeIcon
            onClick={handleClick}
            tooltip={<ToolbarTooltip text="Enregistrer les modifications" />}
          />
          <ToolbarCancelBtn
            type={DrawIconEnum.DRAW_ICON_EDIT_CANCEL}
            onClick={handleClick}
            tooltip={<ToolbarTooltip text="Annuler l&#39;édition" />}
          />
        </>
      )}
    </>
  );
}

export default DrawToolbar;
