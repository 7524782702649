import { Image, Page, Text, View } from '@react-pdf/renderer';
import logo from '../../../../assets/images/logoicon.png';

import { pdfStyles } from '../../shared/styles/pdfStyle';
import HomeFooter from './components/HomeFooter';
import PageOneCommon from './components/PageOneCommon';
import BigO from './components/svg/BigO';

interface IPageOneProps {
  date: string;
  address: { addressLine1: string; addressLine2: string };
  ownerName: string;
  plotIds: string[];
  user: {
    lastName: string;
    firstName: string;
    phone: string;
    email: string;
  };
  companyName: string;

  homePagePicture: string;
}

function PageOneOrpi(datas: IPageOneProps) {
  return (
    <Page size="A4" style={pdfStyles.pageHome}>
      <View style={pdfStyles.homeLogoAndTitle}>
        <Image src={logo} style={pdfStyles.logoImage} />
        <View style={pdfStyles.homeTopTitleSection}>
          <Text style={pdfStyles.homeTopTitle}>Dossier Synthèse</Text>
          <Text style={pdfStyles.homeTopSubTitle}>Urbanisme et environnement</Text>
        </View>
      </View>

      <BigO width={420} />

      <PageOneCommon {...datas} />

      <View style={pdfStyles.homeTitleBloc}>
        <Text style={pdfStyles.homeTitle}>Dossier Estimation Orpi</Text>
        <Text style={pdfStyles.byUrbanease}>Par Urbanease</Text>
        <Text style={pdfStyles.date}>{`Edité le ${datas.date}`}</Text>
      </View>
      <HomeFooter {...datas} />
    </Page>
  );
}

export default PageOneOrpi;
