import { useDispatch } from 'react-redux';

import { useAppSelector } from '../../../../../App/store';
import { getAutocompleteState } from '../../../../autocomplete/autocompleteSlice';
import {
  externalPdfErrialActions,
  getExternalPdfErrialState,
} from '../../../externalPdfSlice';
import TickSquare from '../tickSquare/TickSquare';
import MultiPlotSelectSynthesis from '../../../../../shared/components/multiPlotSelectSynthesis/MultiPlotSelectSynthesis';
import { getPlotState } from '../../../../../redux/plot/reducer';

function LeftPanelErrialBloc() {
  const { isMultiPlotSelectorPdf } = useAppSelector(getExternalPdfErrialState);
  const { inputValue } = useAppSelector(getAutocompleteState);
  const { parcelle } = useAppSelector(getPlotState);
  const dispatch = useDispatch();
  const onClickParcelleSelector = () => {
    dispatch(
      externalPdfErrialActions.setIsMultiPlotSelectorPdf(!isMultiPlotSelectorPdf)
    );
  };

  return (
    <>
      <div className="left-panel-multi-plots">
        <div className={`${inputValue ? '' : 'left-panel-multi-plots-empty'}`}>
          <TickSquare color="Grey" />
          <p>
            Double-cliquez sur la ou les parcelles concernées pour pouvoir générer
            votre PDF d&apos;analyse
          </p>
        </div>
        <button
          onClick={onClickParcelleSelector}
          disabled={!parcelle}
          className={!parcelle ? 'disable' : ''}
        >
          {isMultiPlotSelectorPdf
            ? 'Annuler la multi-selection de parcelles'
            : 'Multi-selection de parcelles'}
        </button>
      </div>
      <MultiPlotSelectSynthesis />
    </>
  );
}

export default LeftPanelErrialBloc;
