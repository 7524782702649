import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../App/store';
import useApp from '../App/useApp';
import RightBottomButtons from '../components/ButtonsOnMap/RightBottomButtons';
import LeftPanelModal from '../components/LeftPanelModal';
import useCompany from '../features/company/useCompany';
import RightPanelModalExternalPdfErrial from '../features/externalPdf/errialPDF/RightPanelModalExternalPdfErrial';
import { getExternalPdfErrialState } from '../features/externalPdf/externalPdfSlice';
import RightPanelModalExternalPdfLand from '../features/externalPdf/landPDF/RightPanelModalExternalPdfLand';
import ErrialInfoModal from '../features/externalPdf/shared/components/errialInfoModal/ErrialInfoModal';
import MapComponent from '../features/map/leaflet/LeafletMap';
import { getMapState, mapActions } from '../features/map/mapSlice';
import NavigationBanner from '../shared/components/navigationBanner/NavigationBanner';
import styles from './styles/externalPdfErrial.module.scss';

export interface IExternalPdfErrialPageProps {
  type: PdfType;
}

const PdfLandingPage = ({ type }: IExternalPdfErrialPageProps): JSX.Element => {
  // **** hooks ****
  useApp();
  useCompany({ loadCompany: true });

  // **** redux selector & dispatch ****
  const { infoModalDisplay } = useAppSelector(getExternalPdfErrialState);
  const { mapCenter, franceLayerDisplay } = useAppSelector(getMapState);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      mapActions.mapCenterSet({
        latLng: mapCenter,
        zoom: franceLayerDisplay ? 6 : 17,
      })
    );
  }, []);

  return (
    <div className={styles.externalPdfErrialContainer}>
      <NavigationBanner />
      <LeftPanelModal />

      {type === 'ORPI_LAND' ? (
        // if land is ok and company is authorized
        <RightPanelModalExternalPdfLand />
      ) : (
        // else go to orpi or generic pdf page
        <RightPanelModalExternalPdfErrial type={type} />
      )}
      <RightBottomButtons />
      <MapComponent />
      {infoModalDisplay.display && <ErrialInfoModal />}
    </div>
  );
};

export default PdfLandingPage;
