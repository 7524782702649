export enum RecursiveSubFolderStatusEnum {
  NULL,
  RECURSIVE_RESPONSABLE,
  RECURSIVE_STATUS,
  RECURSIVE_PRIORITY,
  FOLDER_ONLY,
}

export enum RecursiveSubFolderUpdatedElement {
  NULL,
  RESPONSABLE,
  STATUS,
  PRIORITY,
}
