import { useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';

import styles from './loginModal.module.scss';

export default function LoginContent() {
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  return (
    <div className={styles.formsWrapper}>
      <h3>
        {showForgotPassword ? ' Réinitialisation du mot de passe ' : 'Se connecter'}
      </h3>

      <div className={styles.formsContent}>
        {showForgotPassword ? (
          <ForgotPasswordForm setShowForgotPassword={setShowForgotPassword} />
        ) : (
          <LoginForm />
        )}
      </div>
      <p
        className={styles.forgotPassword}
        onClick={() => setShowForgotPassword(!showForgotPassword)}
      >
        {showForgotPassword ? 'Annuler' : 'Mot de passe oublié?'}
      </p>
    </div>
  );
}
