import { useFormContext } from 'react-hook-form';
import { PDF_LAND_MAX_COMMENT_CHARACTERS } from '../../../../shared/constants';
import GoodDetailsSection from '../../shared/components/rightPanelModalExternalPdfErrial/GoodDetailsSection';
import MultiCustomerLand from '../../shared/components/rightPanelModalExternalPdfErrial/MultiCustomerLand';
import ProInfosSection from '../../shared/components/rightPanelModalExternalPdfErrial/ProInfosSection';
import TextAreaInputFormPdfErrial from '../../shared/components/rightPanelModalExternalPdfErrial/TextAreaInputFormPdfErrial';
import TextInputFormPdfErrialCustom from '../../shared/components/rightPanelModalExternalPdfErrial/TextInputFormPdfErrialCustom';
import styles from '../../shared/styles/rightPanelModalExternalPdfErrial.module.scss';
import LandInformations from './LandInformations';
import LocationInformation from './LocationInformation';

function PlotForm() {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div className={styles.plotForm}>
      <div>
        <h3>Détails du bien étudié</h3>

        <div className={styles.formSection}>
          <GoodDetailsSection type={'ORPI_LAND'} />

          <MultiCustomerLand />

          <div className={styles.columnInput}>
            <p>Adresse du bien à indiquer sur le document</p>
            <TextInputFormPdfErrialCustom
              name="addressStreet"
              error={Boolean(errors.addressStreet)}
              errorMsg={errors.addressStreet?.message as string}
              placeholder="Rue, numéro d'adresse"
            />

            <TextInputFormPdfErrialCustom
              name="addressCity"
              error={Boolean(errors.addressCity)}
              errorMsg={errors.addressCity?.message as string}
              className={styles.spacing}
              placeholder="Ville, code postal"
            />
          </div>

          <div
            className={`${styles.commentContainer} ${
              errors.description?.message ? styles.error : ''
            }`}
          >
            <p>
              Observations rédigées par l’utilisateur professionnel{' '}
              <span>({PDF_LAND_MAX_COMMENT_CHARACTERS} caractères max)</span>
            </p>
            <TextAreaInputFormPdfErrial
              name="description"
              rowsMax={6}
              rowsMin={3}
              placeholder="Commentaire ..."
              className={errors.description?.message ? styles.error : ''}
            />
          </div>
        </div>
      </div>
      <LandInformations />
      <LocationInformation />
      <ProInfosSection />
    </div>
  );
}

export default PlotForm;
