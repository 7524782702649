import { TableCell, TableRow } from '@mui/material';

interface IReportingTableRowProps {
  row: ReportingUserStats;
}

function ReportingTableRow({ row }: IReportingTableRowProps): JSX.Element {
  return (
    <TableRow className="row">
      <TableCell>{row.user?.fullName ?? '-'}</TableCell>
      <TableCell>{row?.authenticationCounter ?? 0}</TableCell>
      <TableCell>{row?.plotStudiesCounter ?? 0}</TableCell>
      <TableCell>{row?.plotStudiesValidCounter ?? 0}</TableCell>
      <TableCell>{row?.proceduresCounter ?? 0}</TableCell>
      <TableCell>{Math.round(row?.plotStudiesValidRate) ?? 0} %</TableCell>
    </TableRow>
  );
}

export default ReportingTableRow;
