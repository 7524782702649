import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../App/store';
import { getMapState } from '../mapSlice';

const viewUrls = ['/street-view', '/3d-view'];
function use3DMapCenter() {
  const { pathname } = useLocation();
  const { clicked3dPoint } = useAppSelector(getMapState);
  const navigate = useNavigate();

  useEffect(() => {
    // url need to be a viewPage url
    if (
      viewUrls.includes(pathname) &&
      (!clicked3dPoint?.lat || !clicked3dPoint?.lng)
    )
      navigate('/');
  }, []);

  return {
    center: clicked3dPoint,
  };
}

export default use3DMapCenter;
