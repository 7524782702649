import { nanoid } from '@reduxjs/toolkit';
import styles from './connectionProcess.module.scss';

interface IAuthErrorProps {
  title: string;
  lines: string[];
  hasSupportLink?: boolean;
  buttonContent: string;
  onClick: (value?: string) => void;
}

function AuthError({
  title,
  lines,
  hasSupportLink,
  buttonContent,
  onClick,
}: IAuthErrorProps) {
  return (
    <div className={styles.authError}>
      <h3>{title}</h3>
      {lines.map((l) => (
        <p key={nanoid()}>{l}</p>
      ))}
      {hasSupportLink && (
        <a href="mailto:support@urbanease.io">support@urbanease.io</a>
      )}
      <button onClick={() => onClick(buttonContent)}>{buttonContent}</button>
    </div>
  );
}

export default AuthError;
